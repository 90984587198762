import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  // Modal,
  // Table,
} from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import {
  getLocalbody,
  getDate,
  getFirstday,
  getArraySumByKey,
  formatMoney,
} from "../../../helpers/globalFunctions";
import "./monthlyshipment.scss";


const MonthwiseReport = (props) => {
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [total, setTotal] = useState(0);

  const [cargoTypeOptions, setCargoTypeOptions] = useState([]);
  const [selectedCargoType, setSelectedCargoType] = useState(null);

  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [cargoAgentOptions, setCargoAgentOptions] = useState([]);
  const [selectedCargoAgent, setselectedCargoAgent] = useState(null);

  const [dayObject, setDayObject] = useState({});

  const [order, setOrder] = useState([]);



  useEffect(() => {
    handleTableData();
    fetch_all_cargo_type();
    fetch_all_branch();
    fetch_all_country();
    fetch_all_cargo_agent();
  }, []);


  function fetch_all_cargo_type() {
    axios
      .get(`${API_URL}cargoType/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var cargo_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.cargo_Type,
              value: el._id,
            };
          });

        setCargoTypeOptions([
          {
            options: cargo_data,
          },
        ]);
      });
  }


  const fetch_all_branch = () => {
    axios
      .get(`${API_URL}reports/branch_box`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log("res", res);
        var branch_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.branch_name,
              value: el._id,
            };
          });

        setBranchOptions([
          {
            options: branch_data,
          },
        ]);
      });
  }



  const fetch_all_country = () => {
    axios
      .get(`${API_URL}newStates/countries`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var country_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setCountryOptions([
          {
            options: country_data,
          },
        ]);
      });
  };



  const fetch_all_cargo_agent = () => {
    axios
      .get(`${API_URL}cargoUser/agents`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        var agent_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: (el.firstName || '') + (el.lastName || ''),
              value: el._id,
            };
          });
        setCargoAgentOptions([
          {
            options: agent_data,
          },
        ]);
      });
  };



  
  function handleTableData(
    from_date = "",
    to_date = "",
  ) {
   
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }

  // // 
  //       let result = res.data.data;
  //       let dataToBeExported = [];
  //       result &&
  //         result.map((item, index) => {
  //           let exportItem = {};
  //           item.id = index + 1;

  //           item.date = moment(item?._id).format("DD-MM-YYYY");
  //           item.total_weight = item.totalweight.toFixed(2);

  //           //export data
  //           exportItem.id = item.id;
  //           exportItem.Date = item.date;
  //           exportItem.Visited_Houses = item.visited_houses;
  //           exportItem.Paid_Houses = item.paid_houses;
  //           exportItem.House_Amt = item.house_amt;
  //           exportItem.Visited_Shops = item.visited_shops;
  //           exportItem.Paid_Shops = item.paid_shops;
  //           exportItem.Shop_Amt = item.shop_amt;
  //           exportItem.Total = item.total_amount;
  //           exportItem.Weight = item.total_weight;

  //           dataToBeExported.push(exportItem);
  //         });
  //       // let test = getArraySumByKey(result,"total_amount")
  //       // result.push({
  //       //   id:"",
  //       //   date:"",
  //       //   visited_houses:"",
  //       //   paid_houses:"",
  //       //   house_amt:"",
  //       //   visited_shops:"",
  //       //   paid_shops:"",
  //       //   shop_amt:"Total",
  //       //   total_amount:test,
  //       //   total_weight:""
  //       // })
  //       setDetails(result);
  //       let total = getArraySumByKey(result, "total_amount");
  //       setTotal(total);
  //       setDataToBeExported(dataToBeExported);
  //     });
  }


   
          

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Month",
        field: "month",
        sort: "asc",
        width: 400,
      },
      {
        label: "Booked",
        field: "booked",
        sort: "asc",
        width: 200,
      },
      {
        label: "Dispatched",
        field: "dispatched",
        sort: "asc",
        width: 200,
      },
      {
        label: "Incustom",
        field: "incustom",
        sort: "asc",
        width: 200,
      },
      {
        label: "Out For Delivery",
        field: "out_for_delivery",
        sort: "asc",
        width: 200,
      },
      {
        label: "Delivered",
        field: "delivered",
        sort: "asc",
        width: 200,
      },
      
    ],
    rows: [ 
      ...order,
    {
        month: <span style={{fontWeight:"1000",float:"right"}}>Total</span>,
        booked: (
        <span style={{fontWeight:"1000"}}>
          {order &&
              order.reduce((acc, curr) => {
                acc = acc + curr.booked;
                return acc;
              }, 0)
          }
        </span>
        ),

        dispatched: (
        <span style={{fontWeight:"1000"}}>
          
        </span>
        ),

        incustom: (
        <span style={{fontWeight:"1000"}}>
          
        </span>
        ),

        out_for_delivery: (
        <span style={{fontWeight:"1000"}}>
          
        </span>
        ),

        delivered: (
        <span style={{fontWeight:"1000"}}>
          
        </span>
        )
      
      }
  ],
  };


  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }

  };


  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "cargo":
        setSelectedCargoType(selected);
        setDayObject({
          ...dayObject,
        });
        break;

      case "branch":
        setSelectedBranch(selected);
        setDayObject({
          ...dayObject,
        });
        break;

      case "country":
        setSelectedCountry(selected);
        setDayObject({
          ...dayObject,
        });
        break;

      case "agent":
        setselectedCargoAgent(selected);
        setDayObject({
          ...dayObject,
        });
        break;


      default:
        break;

    }
  };
  


  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    handleTableData();
    setSelectedCountry(null);
    setSelectedCargoType(null);
    setselectedCargoAgent(null);
    setSelectedBranch(null);
  };


  const handleSearch = () => {
    let country_id = searchData?.country_id ? searchData.country_id : "";
    let branch_id = searchData?.branch_id ? searchData.branch_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let branch_name = searchData?.branch_name ? searchData.branch_name : "";
    let cargo = searchData?.cargo ? searchData.cargo : "";
    let agent = searchData?.agent ? searchData.agent : "";


    handleTableData(date1, date2, branch_id, country_id, branch_name, cargo, agent);
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Month wise Shipment Report" />
          <Row>
            <Col className="col-12">
             
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>


                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
\                      </div>
                    </Col>


                    <Col md="2">
                      <div className="mb-3">
                        <Label>Cargo Type</Label>
                        <Select
                            name ="cargo"
                            value={selectedCargoType}
                            onChange={(selected) => {
                              handleSelectChange(selected, "cargo");
                            }}
                            options={cargoTypeOptions}
                            classNameprefix="select2-selection"
                        />
                      </div>
                    </Col>


                    <Col md="2">
                      <div className="mb-3">
                        <Label>Branch</Label>
                        <Select
                           name="branch"
                           value={selectedBranch}
                           onChange={(selected) => {
                             handleSelectChange(selected, "branch");
                           }}
                           options={branchOptions}
                           classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>


                    <Col md="2">
                      <div className="mb-3">
                        <Label>Country</Label>
                        <Select
                           name="country"
                           value={selectedCountry}
                           onChange={(selected) => {
                             handleSelectChange(selected, "country");
                           }}
                           options={countryOptions}
                           classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                    
                    <Col md="2">
                      <div className="mb-3">
                        <Label>Agent</Label>
                        <Select
                            name="agent"
                            value={selectedCargoAgent}
                            onChange={(selected) => {
                              handleSelectChange(selected, "agent");
                            }}
                            options={cargoAgentOptions}
                            classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>
                   </Row>


                   <Row>
                    <Col md="1" style={{ paddingTop: "5px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="primary"
                          type="submit"
                          onClick={() => handleSearch()}
                        >
                          Search
                        </Button>
                      </div>
                    </Col>


                    <Col md="1" style={{ paddingTop: "5px" }}>
                      <div className="mt-2 mb-3">
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={
                              "Month_wise_shipment_report_" + getDate(new Date()) + ".xls"
                            }
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>


                    <Col md="1" style={{ paddingTop: "5px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>


                  <MDBDataTable
                    id="MonthReportTableId"
                    responsive
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default MonthwiseReport;
