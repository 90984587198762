

import React, { useRef, useEffect, useState } from 'react';
// import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem, CardTitle, Collapse, Table } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Form } from 'react-bootstrap';
import Select from "react-select"
import "./Packing.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from "axios";
import FormControl from '@mui/material/FormControl';
import { result, set } from 'lodash';
import toastr, { error, options } from "toastr";
import ReactDOM from "react-dom";
import { NoBackpackSharp } from '@mui/icons-material';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { CTabPane } from '@coreui/bootstrap-react';
import { logDOM } from '@testing-library/react';
import boxicon from "../Packing/box.svg";
import accessToken from '../../helpers/jwt-token-access/accessToken';
import invoice from "../Packing/invoice.svg";






const Packing = (props) => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

    const [boxdetails, setboxdetails] = useState({});
    const [stateorder, setStateorder] = useState(-1);

    const [order, setOrder] = useState([]);
    const [orderObj, setOrderObj] = useState({});
    const [masterObject, setmasterObject] = useState({});

    const [orderBoxes, setorderBoxes] = useState(null);
    const [selectedOrder, setselectedOrder] = useState(null);
    console.log(selectedOrder,"selectedOrder");
    const [selectedBox, setselectedBox] = useState(null);
    const [orderColor, setorderColor] = useState("white");
    const [boxColor, setboxColor] = useState("white");
    const [searchKey, setsearchKey] = useState('');
    const [detailsBox, setDetailsBox] = useState([]);

    const [OrderObj_id, setOrderObj_id] = useState("641828ecb99cf8e5fe13be93");

    const [errors, setErrors] = useState({
        statusError: "",
    });

    const [values, setvalues] = useState([
        {
            packing_category: 0,
            item: "",
            unit: "",
            quantity: "",
        },
    ]);
    const [selectedItemType, setselectedItemType] = useState(null);
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [selectedItemcat, setselectedItemcat] = useState(null);
    const [displayaddmore, setdisplayaddmore] = useState("block");

    const formRef = useRef();


    const addMore = () => {
        setvalues([
            ...values,
            {
                packing_category: 0,
                item: "",
                unit: "",
                quantity: "",
            },
        ]);
    };


    useEffect(() => {
        fetch_all_itemCategory();
    }, []);

    function fetch_item(el) {
        setmasterObject({ box_id: el._id })
        axios
            .get(`${API_URL}order/packing-list?box_id=${el?._id}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let result = res.data.data;

                el.boxes = result;
            })

    }


    function fetch_all_itemCategory() {
        axios
            .get(`${API_URL}packingManage/packingslist`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let cat_data =
                    res.data.data &&
                    res.data.data.map((el) => {

                        return {
                            label: el?.category_name,
                            value: el?._id,
                            unit: el?.unit,
                        };
                    });

                    setCategoryOptions([{ options: cat_data }]);
                    console.log(cat_data,"categoryOptions");
            });

    };


    function searchOrder(e) {
        setStateorder(-1)
        setorderColor('white')
        setboxColor('white')

        if (e.target.value) {
            axios
                .get(`${API_URL}order/orderNumber?name=` + e.target.value, {
                    headers: {
                        "x-access-token": accessToken,
                    }
                })
                .then((res) => {

                    let result = res.data.data;

                    setOrder(result);

                    result.orders &&
                        result.orders.map((item, index) => {

                            setOrderObj(item);
                        })


                })
        } else {

            setOrder([]);
        }
    }


    function fetch_Box(el) {
        setboxColor('white')

        axios
            .get(`${API_URL}track/boxes?_id=${el?._id}&uniqueId=${el.order_number}`, {
                headers: {
                    "x-access-token": accessToken
                }

            }).then((res) => {

                let result = res.data;

                setDetailsBox(result);

                setorderBoxes(result.data);

                // setmasterObject(result);

            })
    }


    function handleValidSubmit() {
        masterObject.packing_list = values;

        axios
            .post(`${API_URL}order/packing-list`, masterObject, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                if (res.data.status === 200) {
                    //formRef.current.reset();
                    setOrder([]);
                    setorderBoxes([])
                    setselectedOrder(null);
                    setselectedBox(null);
                    setmasterObject({});
                    setsearchKey('')
                    setorderColor('white')
                    setboxColor('white')
                    toastr.success("Category updated successfully");
                } else toastr.error("Failed to update category");
            })
            .catch((err) => {
                console.log('new error' + err)
                toastr.error(err.response.data.message);
                return;
            });
    }


    function handleRemove(index) {
        var list = [...values]
        list.splice(index, 1);
        let valueArray = [];

        // let total_weight = 0;
        // let amount = 0;

        list && list.forEach((element) => { });
        setvalues(list);

        // list.map((el => {
        //     if (el.item_type != 2) {
        //         total_weight = total_weight + parseFloat(el?.quantity)
        //     }
        //     amount = amount + parseFloat(el.amount)
        // }))
        // let grand_total = masterObject?.handling_rate_total + amount

        // setmasterObject({ ...masterObject, total_weight: total_weight, amount: amount, grand_total: grand_total })
    }

    function handleSelectChange(selected, index) {
        const list = [...values];
        list[index]["packing_category"] = selected.value;
        list[index].unit = selected.unit;
        setvalues(list);
        setmasterObject({ ...masterObject, packing_list: list });
    }

    function handleDesc(e, index) {
        var list = [...values]
        var item = e.target.value;
        list[index].item = item;
        setvalues(list)
    }

    function handleUnit(e, index) {
        var list = [...values]
        console.log(values, "list");
        var unit = e.target.value;
        list[index].unit = unit;
        setvalues(list)
    }

    function handleQuantity(e, index) {
        var list = [...values]
        var quantity = e.target.value;
        list[index].quantity = quantity;
        setvalues(list)
    }


    return (
        <React.Fragment>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Row>
                        <Col md="6">
                            <h1 className='addpackinglist'>Add Packing</h1>

                            <Card className='trackingcard'>
                                <CardBody className='trackingcard-cardbody'>
                                    <Form className="app-search d-none d-lg-block " style={{ width: "100%" }} >
                                        <div className="position-relative">
                                            <input
                                                type="text"
                                                className="form-control"
                                                value={searchKey}
                                                placeholder='Search/Scan- OrderID , MobileNumber , QRcode'
                                                onChange={(e) => {
                                                    searchOrder(e)
                                                    setsearchKey(e.target.value)
                                                    setselectedOrder(null)
                                                    setselectedBox(null)
                                                }}
                                            />
                                            <span className="uil-search">
                                                <i class="fa fa-qrcode mx-1"></i></span>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>

                            <Row>
                                <Col md="6" className='mt-1'>
                                    <Card className='maindetailcard1'>
                                        {order &&
                                            order?.map((el, index) => {

                                                return (
                                                    <Card className='resultcard1'

                                                        onClick={() => {
                                                            fetch_Box(el)
                                                            setStateorder(index)
                                                            setOrder([el])
                                                            setorderBoxes([])
                                                            setselectedOrder(el)
                                                            setorderColor('#ffe0cf')
                                                        }}
                                                        style={{
                                                            background: orderColor
                                                        }} >
                                                        <CardBody className='resultcardbody1' >
                                                            <div className='resultmain'>
                                                                <img className='resulticon' src={invoice} alt="image" />
                                                                <p className='detailsp mx-3 mt-2'>Order-No: {el?.order_number}<br /><span style={{ fontSize: ".9rem", fontWeight: "600" }}>Destination <span style={{ color: "red" }}>{el?.country_to?.name}</span></span></p>
                                                                <h5 className='resultweight1' style={{ right: "1.5rem" }}>{el?.total_weight}KG</h5>
                                                            </div>

                                                        </CardBody>
                                                    </Card>

                                                )
                                            })
                                        }
                                    </Card>

                                </Col>

                            </Row>


                            <AvForm onValidSubmit={(e, v) => { handleValidSubmit(e, v); }}>
                                <Row className='m-0 '>

                                    {
                                        selectedBox &&
                                        <Col className="col-12">
                                            <div >
                                                <h2 className='trackingh1'>Item details</h2>
                                            </div>

                                            {/* <Card style={{ marginBottom: "-40px", width: "200%" }}>
                        <CardBody > */}
                                            <Table
                                                style={{ textAlign: "center", width: "200%" }}
                                                className="table table-bordered dataTable"
                                            >
                                                <thead>
                                                    <tr style={{ textAlign: "center" }}>

                                                        <th>  # </th>

                                                        <th style={{ width: "22%" }}> Item Category</th>

                                                        <th>  Description</th>

                                                        <th> Unit</th>

                                                        <th> Quantity</th>

                                                        {values.length !== 1 ? (
                                                            <th> Action</th>
                                                        ) : null}

                                                    </tr>
                                                </thead>

                                                <tbody>
                                                    {values &&
                                                        values.map((item, index) => (

                                                            <tr>
                                                                <td
                                                                    component="th"
                                                                    scope="row"
                                                                    style={{ textAlign: "center" }}
                                                                >
                                                                    {index + 1}
                                                                </td>

                                                                <td>
                                                                    <Select
                                                                        name='category_name'
                                                                        type="text"
                                                                        placeholder='Category'
                                                                        value={item?.category_name}
                                                                        // menuPosition='fixed'
                                                                        onChange={(e) => {
                                                                            handleSelectChange(e, index)
                                                                        }}
                                                                        options={categoryOptions}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    <AvField
                                                                        name='item'
                                                                        type="text"
                                                                        placeholder='Description'
                                                                        value={item?.item}
                                                                        onChange={(e) => {
                                                                            handleDesc(e, index)
                                                                        }}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    <AvField
                                                                        name="unit"
                                                                        type="text"
                                                                        placeholder='unit'
                                                                        readOnly={true}
                                                                        value={item?.unit}
                                                                        onWheel={(e) => e.target.blur()}
                                                                    />
                                                                </td>

                                                                <td>
                                                                    <AvField
                                                                        name="quantity"
                                                                        type="number"
                                                                        placeholder='Quantity'
                                                                        value={item?.quantity}
                                                                        onChange={(e) => {
                                                                            handleQuantity(e, index)
                                                                        }}
                                                                    />
                                                                </td>


                                                                {values.length !== 1 && (
                                                                    <td>
                                                                        <i
                                                                            className="uil uil-times"
                                                                            style={{
                                                                                fontSize: "20px",
                                                                                cursor: "pointer",
                                                                                paddingLeft: "5px",
                                                                                display: "flex",
                                                                                justifyContent: "center",
                                                                            }}
                                                                            onClick={() =>
                                                                                handleRemove(index)
                                                                            }
                                                                        ></i>
                                                                    </td>
                                                                )}

                                                            </tr>

                                                        ))
                                                    }

                                                </tbody>

                                            </Table>

                                            <div>
                                                <span
                                                    style={{ cursor: "pointer", paddingLeft: "435px" }}
                                                    onClick={() => addMore()}
                                                    className='addnw1'
                                                >
                                                    + Add New
                                                </span>
                                            </div>

                                            {/* </CardBody>

                      </Card> */}

                                        </Col>

                                    }

                                    {
                                        selectedBox &&
                                        <Col md="3">
                                            <Button className='mx-2'
                                                color='primary'
                                                type='submit'
                                            >
                                                Submit
                                            </Button>


                                        </Col>
                                    }


                                </Row>
                            </AvForm>

                            {/* </CardBody> */}
                            {/* </Card> */}

                        </Col>

                        {/* =================================================================================================== */}

                        {
                            selectedOrder && 
                            <Col md='2'>
                                <h2 className='trackingh1'>Box details</h2>
                                {
                                    orderBoxes &&
                                    orderBoxes.map((bx, index) => {
                                        // console.log("bx", bx);
                                        return (
                                            <Card className='resultcardtrackbox' 

                                                onClick={() => {
                                                    fetch_item(bx)
                                                    setselectedBox(bx)
                                                    setStateorder(index)
                                                    setDetailsBox([bx])
                                                    setorderBoxes([bx])
                                                    setboxColor('#ffe0cf')
                                                }}
                                                style={{ 
                                                    background: boxColor , width: "195%"
                                                }}
                                            >
                                                <Row >
                                                    <CardBody className='resultcardbodytrack' >
                                                        <div className='resultmain'>
                                                            <img className='resulticon' src={boxicon} alt="image" />
                                                            <p className='detailsp mx-3 mt-2'>Box-No: {bx?.box_number}<br /><span style={{ fontSize: ".9rem", fontWeight: "600" }}>Status: <span style={{ color: "red" }}>{bx?.shipment_status?.ship_status_name}</span></span></p>
                                                            <h5 className='resultweight'>{bx.box_weight}KG</h5>
                                                        </div>

                                                    </CardBody>
                                                </Row>
                                            </Card>
                                        )
                                    })
                                }
                            </Col>
                        }

                    </Row>

                </div>
            </div>
        </React.Fragment>
    )
}

export default Packing;