import React from "react";
import { Row, Col } from "reactstrap"
import "./style.scss";
import bookedIcon from "../../assets/images/Icons/book1.svg";
import dispatchedIcon from "../../assets/images/Icons/book2.svg";
import customIcon from "../../assets/images/Icons/book3.svg";
import outDeliveryIcon from "../../assets/images/Icons/book4.svg";
import deliveryIcon from "../../assets/images/Icons/book5.svg";
const Tracking = (props)=>{
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col md="4">
                        <div class="status">
          <div class="status12">
            <div class="paraa1">
              <div>
                <img src="../img/icons/book4.svg" alt="" />
              </div>
              <div class="head-details" >
                <p >TRACKING NUMBER : 3242323</p>
                <p className="delivered">STATUS : <span>DELIVERED</span></p>
              </div>             
            </div>
                <div class="status1">
             <div class="status-img">
               <img src={bookedIcon} alt="" />
             </div> 
             <div class="status-details">
               <p>SHIPMENT BOOKED</p>
               <p class="para-sub">Order recieved on july 18,2018</p>
               <span class="line"></span>
             </div>
           </div>
           <div class="status1">
             <div class="status-img">
               <img src={dispatchedIcon} alt="" />
             </div>
             <div class="status-details">
               <p>SHIPMENT DISPATCHED</p>
               <p class="para-sub">Yor item has been dispatched on july 18,2018</p>

             </div>
           </div>
           <div class="status1">
             <div class="status-img">
               <img src={customIcon} alt="" />
             </div>
             <div class="status-details">
               <p>IN CUSTOMS</p>
               <p class="para-sub">Your item in customs on july 18,2018</p>
               
             </div>
           </div>
           <div class="status1">
             <div class="status-img">
               <img src={outDeliveryIcon} alt="" />
             </div>
             <div class="status-details">
               <p>OUT FOR DELIVERY</p>
               <p class="para-sub">Your item is out for delivery on july 18,2018</p>
               
             </div>
           </div>
           <div class="status1">
             <div class="status-img">
               <img src={deliveryIcon} alt=""/>
             </div>
             <div class="status-details">
               <p>DELIVERED</p>
               <p class="para-sub">Your item has been delivered on july 18,2018</p>
               
             </div>
           </div>

         </div>
       </div>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
export default Tracking