import React, { useRef, useEffect, useState } from 'react';
import Select from 'react-select';
import { MDBDataTable } from "mdbreact";
import toastr from 'toastr';
import "./Newcity.scss"
import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from 'axios';
import accessToken from '../../../helpers/jwt-token-access/accessToken';
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";


function Newcity() {

    const [cityobj, setcityobj] = useState({});

    const [selectcountry, setselectcountry] = useState(null);
    const [countryoptions, setcountryoptions] = useState([]);

    const [selectstate, setselectstate] = useState(null);
    const [stateoptions, setstateoptions] = useState([]);

    const [selectedBranch, setSelectedBranch] = useState(null);
    const [branchOptions, setBranchOptions] = useState([]);

    const [itemsfortable, setitemsfortable] = useState([]);
    const [confirmDeleteAleret, setconfirmDeleteAleret] = useState(false);
    const [cityIdTobeDeleted, setcityIdTobeDeleted] = useState(null);
    const [cityIdToBeUpdated, setCityIdToBeUpdated] = useState(null);


    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";



    const [errors, seterrors] = useState({
        countryname: "",
        statename: "",
        branchname: "",
    })

    function OnTop() {

    }

    useEffect(() => {

        fetch_all_country()
        handleTableData()

    }, []);

    //  =================country fetc=============>

    function fetch_all_country() {
        axios
            .get(`${API_URL}newStates/countries`, {
                headers: {
                    "x-access-token": accessToken,
                }
            })
            .then((res) => {
                var country_data =
                    res.data.data &&
                    res.data.data.map((country) => {
                        return {
                            label: country?.name,
                            value: country?._id,

                        };
                    })
                setcountryoptions([
                    {
                        options: country_data,
                    },
                ]);
            });

    }

    //    ========================fetch state===============>

    function fetch_all_state(country_id) {
        axios
            .get(`${API_URL}newStates/list?country_id=${country_id}`, {
                headers: {
                    "x-access-token": accessToken
                }
            })
            .then((res) => {
                var state_data =
                    res.data.data &&
                    res.data.data.map((st) => {
                        return {
                            label: st.name,
                            value: st._id
                        }
                    })
                setstateoptions([{ options: state_data }])
            })

    }

    // =============================>fetch all brach==============>

    function fetch_all_branch(country_id) {
        axios
            .get(`${API_URL}cargo_Branch/?country_id=${country_id}`, {
                headers: {
                    "x-access-token": accessToken,
                }
            }).then((res) => {

                var branch_data =
                    res.data.data &&
                    res.data.data.map((brnh) => {

                        return {
                            label: brnh.branch_name,
                            value: brnh._id,
                        }
                    })

                setBranchOptions([{ options: branch_data }])
            })

    }


    // ===============================onchange================>


    function handleSelectChange(selected, name) {
        switch (name) {
            case "country":
                setselectcountry(selected);
                setselectstate(null);
                setSelectedBranch(null);
                fetch_all_state(selected.value)
                fetch_all_branch(selected.value)
                setcityobj({ ...cityobj, country_id: selected.value })
                seterrors({
                    ...errors,
                    countryname:"",
                })
                break;

            case "state":
                setselectstate(selected);
                setSelectedBranch(null);
                setcityobj({ ...cityobj, state_id: selected.value });
                seterrors({
                    ...errors,
                    statename: "",
                })
                break;

            case "nearest_branch":
                setSelectedBranch(selected);
                setcityobj({
                    ...cityobj,
                    nearest_branch: selected.value,
                })
                break;

            default:
                break;
        }

    }


    //  ====================handleinput============>
    function handleInput(e) {
        let name = e.target.name;
        let value = e.target.value;
        setcityobj({ ...cityobj, [name]: value })
    }

    const formRef = useRef();

    // ================Vlidsubmit================>


    const handleValidSubmit = () => {
        if (selectcountry === null || selectstate === null) {
            let errorVal = errors;
            if (selectcountry === null)
                errorVal.countryname = "please select Country"
            if (selectstate === null)
                errorVal.statename = "please select state"
            if (selectedBranch === null)
                errorVal.branchname = "Please select branch"
            seterrors({ ...errorVal })
        } else {
            if (cityIdToBeUpdated) {
                axios
                    .post(`${API_URL}city`, cityobj, {
                        headers: {
                            "x-access-token": accessToken,
                        }
                    }).then((res) => {
                        if (res.data.status === 200) {
                            toastr.success("City updated successfully");
                            formRef.current.reset();
                            handleTableData();
                            setcityobj({});
                            setselectcountry(null);
                            setselectstate(null);
                            setCityIdToBeUpdated(null);
                            setSelectedBranch(null);
                        }
                        else {
                            toastr.error("Faild to update city");
                            return;
                        }
                    }).catch((err) => {
                        toastr.error(err.response.data.message);
                    })
            }
            else {
                axios
                    .post(`${API_URL}city`, cityobj, {
                        headers: {
                            "x-access-token": accessToken
                        }
                    })
                    .then((res) => {
                        if (res.data.status === 200) {
                            toastr.success("City Addedd Successfully")
                            reset();
                            handleTableData();
                            formRef.current.reset();
                            setcityobj({});
                            setselectcountry(null);
                            setselectstate(null);
                            setSelectedBranch(null);
                            seterrors({
                                statename: "",
                                countryname: "",
                                branchname: "",
                            })
                        }
                        else {
                            toastr.error('Faild To Add City')
                            return
                        }
                    }).catch((err) => {
                        toastr.error(err.response.data.message)
                        return;
                    })
            }
        }
    }

    // ========================preupdate================>

    let preupdateitem = (item) => {
        setCityIdToBeUpdated(item._id)
        fetch_all_state(item?.country_id?._id)

        axios
            .get(`${API_URL}city/?city_id=` + item?._id, {
                headers: {
                    "x-access-token": accessToken,
                }
            })
            .then((res) => {

                let result = res.data.data;

                let city = result?.name;

                let country1 = {
                    label: result?.country_id?.name,
                    value: result?.country_id?._id,
                };
                setselectcountry(country1);

                let state1 = {
                    label: result?.state_id?.name,
                    value: result?.state_id?._id,
                };
                setselectstate(state1);

                let nearesetbranch1 = {
                    label: result?.nearest_branch?.branch_name,
                    value: result?.nearest_branch?._id,
                };
                setSelectedBranch(nearesetbranch1);

                setcityobj(result);

            })
        // let cty = item?.country_id
        // let ctyop = {
        //     label: cty?.name,
        //     value: cty?._id
        // };
        // setselectcountry(ctyop)

        // let ste = item?.state_id
        // let steop = {
        //     label: ste?.name,
        //     value: ste?.id
        // };
        // setselectstate(steop);

        // item.country = ctyop.value
        // item.state = steop.value

    }
    // ====================tabledatalist=========>

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150
            },
            {
                label: "City Name",
                field: "name",
                sort: "asc",
                width: 150
            },
            {
                label: "Country",
                field: "country",
                sort: "asc",
                width: 150
            },
            {
                label: "State",
                field: "state",
                sort: "asc",
                width: 150
            },
            {
                label: "Staff",
                field: "staff",
                sort: "asc",
                width: 150

            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150
            },
        ],
        rows: itemsfortable,
    }

    // =================list table============>

    function handleTableData() {
        axios
            .get(`${API_URL}city/list`, {
                headers: {
                    "x-access-token": accessToken,
                }
            })
            .then((res) => {
                var result = res.data.data

                let city_data = []
                result.map((item, index) => {
                    item.action = (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <i className="uil-edit-alt"
                                style={{
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                    marginLeft: "0.4rem",
                                    marginRight: "0.4rem"
                                }}
                                onClick={() => {
                                    OnTop();
                                    preupdateitem(item);
                                }}


                            ></i>
                            <i className="uil-trash-alt"
                                style={{ fontSize: "1rem", cursor: "pointer" }}
                                onClick={() => {
                                    setconfirmDeleteAleret(true)
                                    setcityIdTobeDeleted(item._id);
                                }}


                            ></i>
                        </div>
                    );
                    item.id = index + 1


                    // if(item?.state_id){
                    item.state = item?.state_id?.name
                    // }

                    item.country = item?.country_id?.name


                    if (item?.updatedBy) {
                        let values = item?.updatedBy || {};

                        if (values?.lastName)
                            item.staff = values.firstName + " " + values.lsatName;
                        else item.staff = values?.firstName
                    } else {
                        let values = item?.addedBy || {};
                        if (values?.lastName)
                            item.staff = values.firstName + " " + values.lastName;
                        else item.staff = values?.firstName
                    }

                    city_data.push(item)

                })
                setitemsfortable(city_data)
            })
    }


    const reset = () => {
        formRef.current.reset();
        setcityobj({})
        setCityIdToBeUpdated(null);
        setcityIdTobeDeleted();
        setselectcountry(null);
        setselectstate(null);
        setSelectedBranch(null);
        seterrors({
            countryname: "",
            statename: "",
            branchname: "",
        })
    }



    return (
        <React.Fragment>
            {
                confirmDeleteAleret ? (
                    <SweetAlert
                        title=""
                        showCancel
                        confirmBtnText="Delete"
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            axios
                                .delete(`${API_URL}city?city_id=${cityIdTobeDeleted}`, {
                                    headers: {
                                        "x-access-token": accessToken
                                    }
                                }).then((res) => {
                                    if (res.data.status === 200) {
                                        toastr.success("City Deleted Successfully");
                                        formRef.current.reset();
                                        handleTableData()
                                        setcityobj({});
                                        setCityIdToBeUpdated();
                                        setselectcountry(null);
                                        setselectstate(null);
                                        setSelectedBranch(null);
                                        // setconfirmDeleteAleret(false)
                                    } else {
                                        toastr.error("Faild to delete city");
                                        return
                                    }
                                })
                                .catch((err) => {
                                    toastr.error(err.response.data.message);
                                    return;
                                })
                            setconfirmDeleteAleret(false)

                        }}
                        onCancel={() => setconfirmDeleteAleret(false)}
                    >
                        Are you sure want to delete
                    </SweetAlert>

                ) : null
            }
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumb title="Home" breadcrumbItem="New City" />
                    <Row>
                        <Col xl="12">
                            {/* <Card> */}
                            {/* <CardBody> */}
                            <AvForm
                                className="needs-validation"
                                ref={formRef}
                                onValidSubmit={(e, v) => {
                                    handleValidSubmit(e, v);
                                }}

                            >
                                <Row>
                                    <Col md="2">
                                        <div className='mb-3'>
                                            <Label>City Name</Label>
                                            <AvField
                                                name="name"
                                                placeholder="City Name"
                                                type="text"
                                                value={cityobj?.name}
                                                onChange={handleInput}
                                                className="form-control"
                                                errorMessage="Enter City Name"
                                                validate={{ required: { value: true } }}
                                            />
                                        </div>
                                    </Col>
                                    <Col md="3">
                                        <div className=' mb-3'>
                                            <Label>Country</Label>
                                            <Select
                                                name="country_id"
                                                value={selectcountry}
                                                options={countryoptions}
                                                placeholder="Select Country"
                                                onChange={(selected) => {
                                                    handleSelectChange(selected, "country")
                                                }}
                                                classNamePrefix="select2-selection" />
                                            <p className='text-danger'
                                                style={{ fontSize: "11px" }}>
                                                {selectcountry === null ? errors.countryname : ""}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md="2">
                                        <div className=' mb-3'>
                                            <Label>State</Label>
                                            <Select
                                                name="state_id"
                                                value={selectstate}
                                                options={stateoptions}
                                                placeholder="Select State"
                                                onChange={(selected) => {
                                                    handleSelectChange(selected, "state")
                                                }}
                                                classNamePrefix="select2-selection" />
                                            <p className='text-danger'
                                                style={{ fontSize: "11px" }}>
                                                {selectstate === null ? errors.statename : ""}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md="3">
                                        <div className=' mb-3'>
                                            <Label>Nearest Branch</Label>
                                            <Select
                                                name="nearest_branch"
                                                value={selectedBranch}
                                                options={branchOptions}
                                                placeholder="Nearest Branch"
                                                onChange={(selected) => {
                                                    handleSelectChange(selected, "nearest_branch")
                                                }}
                                                classNamePrefix="select2-selection" />
                                            <p className='text-danger'
                                                style={{ fontSize: "11px" }}>
                                                {selectedBranch === null ? errors.branchname : ""}
                                            </p>
                                        </div>
                                    </Col>
                                    {/* </Row> */}
                                    {/* <Row> */}
                                    <Col md='2' style={{ marginTop: "27px" }}>
                                        {/* <div className='mt-2'> */}

                                        {cityIdToBeUpdated ? (
                                            <Button className='mx-2'
                                                color='primary'
                                                type='update'
                                            >
                                                Update
                                            </Button>
                                        )
                                            :
                                            <Button className='mx-2'
                                                color='primary'
                                                type='submit'
                                            >
                                                Submit
                                            </Button>
                                        }

                                        <Button
                                            color='danger'
                                            type='reset'
                                            onClick={() => reset()}
                                        >
                                            Reset
                                        </Button>
                                        {/* </div> */}

                                    </Col>
                                </Row>
                            </AvForm>
                            {/* </CardBody> */}
                            {/* </Card> */}

                        </Col>
                    </Row>
                    <Row className='mt-1'>
                        <Col md="12">
                            {/* <Card> */}
                            {/* <CardBody> */}
                            <MDBDataTable
                                bordered
                                responsive
                                id="city1table"
                                data={data}
                                entries={20}

                            />

                            {/* </CardBody> */}
                            {/* </Card> */}
                        </Col>

                    </Row>


                </div>
            </div>
        </React.Fragment>
    )
}

export default Newcity
