import React, { useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Label } from "reactstrap";
import { AvForm } from 'availity-reactstrap-validation';
import Select from "react-select";
import axios from 'axios';
import accessToken from '../../../helpers/jwt-token-access/accessToken';






const Coverage = () => {

    const formRef = useRef();
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [masterObject, setmasterObject] = useState({});

    const [countryOptions, setCountryOptions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const [stateOptions, setStateOptions] = useState([]);
    const [selectedState, setSelectedState] = useState(null);

    const [branchOptions, setBranchOptions] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);


    useEffect(() => {
        fetch_all_country();
    }, []);


    function fetch_all_country() {
        axios
            .get(`${API_URL}newStates/countries`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let country_data =
                    res.data.data &&
                    res.data.data.map((el) => {

                        return {
                            label: el?.name,
                            value: el?._id
                        }
                    })
                setCountryOptions([{ options: country_data }])
            })
    }


    function fetch_all_state(country_id) {
        axios
            .get(`${API_URL}newStates/list?country_id=${country_id}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                var state_data =
                    res.data.data &&
                    res.data.data.map((state) => {

                        return {
                            label: state?.name,
                            value: state?._id
                        }
                    })

                setStateOptions([{ options: state_data }])
            })
    }


    function fetch_all_branch(country_id) {
        axios
            .get(`${API_URL}cargo_Branch/?country_id=${country_id}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                var branch_data =
                    res.data.data &&
                    res.data.data.map((el) => {

                        return {
                            label: el?.branch_name,
                            value: el?._id
                        }
                    })

                setBranchOptions([{ options: branch_data }])
            })
    }


    const handleSelectChange = (selected, name) => {
        switch (name) {
            case "country":
                setSelectedCountry(selected);
                setSelectedState(null);
                setSelectedBranch(null);
                setmasterObject({
                    ...masterObject,
                    country: selected.value
                })
                fetch_all_state(selected.value);
                fetch_all_branch(selected.value);
                break;

            case "state":
                setSelectedState(selected);
                setSelectedBranch(null);
                setmasterObject({
                    ...masterObject,
                    state: selected.value,
                });
                break;

            case "branch":
                setSelectedBranch(selected);
                setmasterObject({
                    ...masterObject,
                    branch: selected.value,
                });
                break;


            default:
                break;
        }
    }


    return (
        <React.Fragment>

            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumb title="Home" breadcrumbItem="Coverage" />

                    <Row>
                        <Col xl="12">
                            <AvForm className="needs-validation"
                                ref={formRef}
                            >

                                <Row>
                                    <Col md="3">
                                        <Label>Country</Label>
                                        <Select
                                            name="country"
                                            value={selectedCountry}
                                            placeholder="Country"
                                            options={countryOptions}
                                            classNamePrefix="select2-selection"
                                            onChange={(selected) => {
                                                handleSelectChange(selected, "country")
                                            }}
                                        />
                                    </Col>

                                    <Col md="3">
                                        <Label>State</Label>
                                        <Select
                                            name="state"
                                            value={selectedState}
                                            placeholder="State"
                                            options={stateOptions}
                                            classNamePrefix="select2-selection"
                                            onChange={(selected) => {
                                                handleSelectChange(selected, "state")
                                            }}
                                        />
                                    </Col>

                                    <Col md="3">
                                        <Label>Branch</Label>
                                        <Select
                                            name="branch"
                                            value={selectedBranch}
                                            placeholder="Branch"
                                            options={branchOptions}
                                            classNamePrefix="select2-selection"
                                            onChange={(selected) => {
                                                handleSelectChange(selected, "branch")
                                            }}
                                        />
                                    </Col>

                                </Row>

                            </AvForm>
                        </Col>
                    </Row>

                </div>
            </div>
        </React.Fragment>
    )

}


export default Coverage;