export const GET_POPUP = 'GET_POPUP';
export const GET_POPUP_SUCCESS = 'GET_POPUP_SUCCESS';
export const GET_POPUP_FAIL = 'GET_POPUP_FAIL';

export const ADD_POPUP = 'ADD_POPUP';
export const ADD_POPUP_SUCCESS = 'ADD_POPUP_SUCCESS';
export const ADD_POPUP_FAIL = 'ADD_POPUP_FAIL';

export const UPDATE_POPUP = 'UPDATE_POPUP';
export const UPDATE_POPUP_SUCCESS = 'UPDATE_POPUP_SUCCESS';
export const UPDATE_POPUP_FAIL = 'UPDATE_POPUP_FAIL';

export const DELETE_POPUP = 'DELETE_POPUP';
export const DELETE_POPUP_SUCCESS = 'DELETE_POPUP_SUCCESS';
export const DELETE_POPUP_FAIL = 'DELETE_POPUP_FAIL';