import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

import {
  ADD_CHARTOFACC,
  DELETE_CHARTOFACC,
  UPDATE_CHARTOFACC,
  GET_CHARTOFACC,
} from "./actionTypes";

import {
  getAllChartOfAccSuccess,
  getAllChartOfAccFail,
  addChartOfAccFail,
  addChartOfAccSuccess,
  updateChartOfAccSuccess,
  updateChartOfAccFail,
  deleteChartOfAccSuccess,
  deleteChartOfAccFail
} from "./actions";

//Include Both Helper File with needed methods
import { addChartOfAcc, getAllChartOfAcc, updateChartOfAcc,
  deleteChartOfAcc } from "../../helpers/backend_helper";

function* fetchChartOfAccount({localbody_id: localbody_id}) {
  try {
    const response = yield call(getAllChartOfAcc,localbody_id);
    yield put(getAllChartOfAccSuccess(response));
  } catch (error) {
    yield put(getAllChartOfAccFail(error));
  }
}
// function* fetchAcctypeOptions() {

//   try {
//     const response = yield call(accountTypeOptions);
//     yield put(accountTypeOptionsSuccess(response.data));
//   } catch (error) {
//     yield put(accountTypeOptionsFail(error));
//   }
// }

function* onAddChartOfAccount({ payload: data }) {
  try {
    const response = yield call(addChartOfAcc, data);
    yield put(addChartOfAccSuccess(response));
  } catch (error) {
    yield put(addChartOfAccFail(error.response));
  }
}

function* onUpdateChartOfAccount({ payload: data }) {
  try {
    const response = yield call(updateChartOfAcc, data);
    yield put(updateChartOfAccSuccess(response));
  } catch (error) {
    yield put(updateChartOfAccFail(error.response));
  }
}

function* ondeleteChartOfAccount({ payload: chartId, auth_data: auth_user }) {
  try {
    const response = yield call(deleteChartOfAcc, chartId, auth_user);
    yield put(deleteChartOfAccSuccess(response));
  } catch (error) {
    yield put(deleteChartOfAccFail(error.response));
  }
}

function* chartOfAccSaga() {
  yield takeEvery(GET_CHARTOFACC, fetchChartOfAccount);
  yield takeEvery(ADD_CHARTOFACC, onAddChartOfAccount);
  yield takeEvery(UPDATE_CHARTOFACC, onUpdateChartOfAccount);
  yield takeEvery(DELETE_CHARTOFACC, ondeleteChartOfAccount);
}

export default chartOfAccSaga;
