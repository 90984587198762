import { takeEvery, put, call, takeLatest } from 'redux-saga/effects';

import {
  ADD_ACCTYPE,
  DELETE_ACCTYPE,
  UPDATE_ACCTYPE,
  GET_ACCTYPES,
  GET_ACCTYPE_OPTIONS
} from './actionTypes';

import {
  getAllAccountTypesSuccess,
  getAllAccountTypesFail,
  getAllAccountTypesuccess,
  addAccountTypeFail,
  addAccountTypeSuccess,
  updateAccountTypeFail,
  updateAccountTypeSuccess,
  deleteAccountTypeFail,
  deleteAccountTypeSuccess,
  accountTypeOptionsSuccess,
  accountTypeOptionsFail
} from './actions';

//Include Both Helper File with needed methods
import {
  getAllAccountTypes,
  addAccountType,
  updateAccountType,
  deleteAccountType,
  accountTypeOptions
} from '../../helpers/backend_helper';

function* fetchAcctype({localbody_id: localbody_id}) {
  try {
    const response = yield call(getAllAccountTypes,localbody_id);
    yield put(getAllAccountTypesSuccess(response));
  } catch (error) {
    yield put(getAllAccountTypesFail(error));
  }
}
function* fetchAcctypeOptions() {
  
  try {
    const response = yield call(accountTypeOptions);
    yield put(accountTypeOptionsSuccess(response.data));
  } catch (error) {
    yield put(accountTypeOptionsFail(error));
  }
}

function* onaddAccountType({ payload: acctype }) {
  try {
    const response = yield call(addAccountType, acctype);
    yield put(addAccountTypeSuccess(response));
  } catch (error) {
    yield put(addAccountTypeFail(error.response));
  }
}

function* onupdateAccountType({ payload: acctype }) {
  //console.log(area);
  try {
    const response = yield call(updateAccountType, acctype);
    yield put(updateAccountTypeSuccess(response));
  } catch (error) {
    yield put(updateAccountTypeFail(error.response));
  }
}

function* ondeleteAccountType({ payload: acctypeId, auth_data: auth_user }) {
  try {
    const response = yield call(deleteAccountType, acctypeId, auth_user);
    yield put(deleteAccountTypeSuccess(response));
  } catch (error) {
    yield put(deleteAccountTypeFail(error.response));
  }
}

function* acctypeSaga() {
  yield takeEvery(GET_ACCTYPES, fetchAcctype);
  yield takeEvery(ADD_ACCTYPE, onaddAccountType);
  yield takeEvery(UPDATE_ACCTYPE, onupdateAccountType);
  yield takeEvery(DELETE_ACCTYPE, ondeleteAccountType);
  yield takeEvery(GET_ACCTYPE_OPTIONS,fetchAcctypeOptions);
}

export default acctypeSaga;
