import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, CardBody, Card,CardTitle,Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import { getLocalbody } from "../../helpers/globalFunctions";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
//Import Components
import MiniWidget from "./mini-widget";
import SalesAnalyticsChart from "./salesanalytics-chart";
import WasteCollectItems from "./wasteItems";
//import io from "socket.io-client";
// import socketIOClient from "socket.io-client";
//Import Image
const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
function getDaysOfMonth(monthDate) {
  var daysInMonth = moment(monthDate,"YYYY-MM").daysInMonth();
  var arrDays = [];
  var arrDate = [];
  var arrDateBack = [];
  var arr = [];

  while (daysInMonth) {
    var current = moment(monthDate,"YYYY-MM").date(daysInMonth);
    arrDays.push(current.format("DD MMMM"));
    arrDate.push(current.format("YYYY-MM-DD"));
    arrDateBack.push({date:current.format("YYYY-MM-DD"),visit_count:0,collected_count:0,closed_count:0,no_waste_count:0});
    daysInMonth--;
  }
  arr.push(arrDays);
  arr.push(arrDate);
  arr.push(arrDateBack);
  return arr;
}
// const dateList = getDaysOfMonth();
// const chartDay = dateList[0];
// const monthdate = dateList[1];

const options1 = {
  fill: {
    colors: ["#34c38f"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};
const options2 = {
  fill: {
    colors: ["#5b73e8"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};

const options3 = {
  fill: {
    colors: ["#f1734f"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};

const options4 = {
  fill: {
    colors: ["#6f42c1"],
  },
  chart: {
    sparkline: {
      enabled: !0,
    },
  },
  dataLabels: {
    enabled: !1,
  },
  plotOptions: {
    radialBar: {
      hollow: {
        margin: 0,
        size: "60%",
      },
      track: {
        margin: 0,
      },
      dataLabels: {
        show: !1,
      },
    },
  },
};

const Dashboard = () => {
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [active_customer_count, setActiveCustomerCount] = useState(0);
  const [total_customer_count, setTotalCustomerCount] = useState(0);
  const [custPercentage, setCustPercentage] = useState([0]);
  const [currentMonth, setCurrentMonth] = useState(moment().format("YYYY-MM"));

  const [total_receipt_amount, settotal_receipt_amount] = useState(0);
  const [total_receipt_customer, settotal_receipt_customer] = useState(0);
  const [receiptPercentage, setReceiptPercentage] = useState([0]);

  const [total_visit, settotal_visit] = useState(0);
  const [total_pending, settotal_pending] = useState(0);
  const [visitPercentage, setVisitPercentage] = useState([0]);

  const [total_present, settotal_present] = useState(0);
  const [total_staff, settotal_staff] = useState(0);
  const [staffPercentage, setStaffPercentage] = useState([0]);
  const [wasteItems, setWasteItems] = useState([]);
  const [max_weight, setMaxWeight] = useState(0);
  const [graphData, setgraphData] = useState([]);
  const [isloading, setLoading] = useState('block');
  useEffect(() => {
    if (currentLocalbody) {
      var userId = "";
      if (localStorage.getItem("authUser")) {
        var data = localStorage.getItem("authUser");
        const obj = JSON.parse(data);
        userId = obj._id;
      }

      axios
        .get(
          `${API_URL}dashboard?localbody_id=` +
            currentLocalbody +
            `&user_id=` +
            userId,
          {
            headers: {
              "x-access-token": accessToken,
            },
          }
        )
        .then((res) => {
          setActiveCustomerCount(res.data.verified_customers);
          setTotalCustomerCount(res.data.total_customers);

          settotal_receipt_amount(res.data.total_receipt_amount);
          settotal_receipt_customer(res.data.total_receipt_customer);

          settotal_visit(res.data.total_visit);
          settotal_pending(res.data.total_pending);

          settotal_staff(res.data.total_staff);
          settotal_present(res.data.total_present);

          setCustPercentage([
            (res.data.verified_customers / res.data.total_customers) * 100,
          ]);
          setReceiptPercentage([
            (res.data.total_receipt_customer / res.data.verified_customers) *
              100,
          ]);
          setVisitPercentage([
            (res.data.total_visit / res.data.total_pending) * 100,
          ]);
          setStaffPercentage([
            (res.data.total_present / res.data.total_staff) * 100,
          ]);
        });

      fetch_graph_data1(currentLocalbody, userId,moment().format("YYYY-MM"));
      fetch_graph_data2(currentLocalbody, userId);
    }
  }, [currentLocalbody]);
  const fetch_graph_data1 = (localbody, userId,currentMonth) => {
    var days = getDaysOfMonth(currentMonth);
    axios
      .post(
        `${API_URL}dashboard/crm_dashboard_graph_new`,
          {
            localbody_id:localbody,
            user_id:userId,
            month:currentMonth,
            days:days[2]
          },
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        setLoading('none');
        setgraphData(res.data.graph_receipt);
      });
  };
  const fetch_graph_data2 = (localbody, userId) => {
    axios
      .get(
        `${API_URL}dashboard/crm_dashboard_graph_second?localbody_id=` +
          localbody +
          `&user_id=` +
          userId,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        setWasteItems(res.data.waste_items);
        setMaxWeight(res.data.max_weight);
      });
  };
  const handleChangeMonth = (e)=>{
    setLoading('block');
    setgraphData([]);
    setCurrentMonth(e.target.value);
    var userId = "";
      if (localStorage.getItem("authUser")) {
        var data = localStorage.getItem("authUser");
        const obj = JSON.parse(data);
        userId = obj._id;
      }
    fetch_graph_data1(currentLocalbody,userId,e.target.value)
  }
  const reports = [
    {
      id: 1,
      icon: "mdi mdi-account",
      title: "Active Customers",
      value: active_customer_count,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "",
      suffix: "",
      badgeValue: total_customer_count,
      color: "info",
      desc: "Total customers",
      series: custPercentage,
      options: options1,
    },
    {
      id: 2,
      icon: "mdi mdi-receipt",
      title: "Receipt",
      value: total_receipt_amount,
      decimal: 0,
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      prefix: "₹",
      suffix: "",
      badgeValue: total_receipt_customer,
      color: "danger",
      desc: "Paid customers",
      series: receiptPercentage,
      options: options2,
    },
    {
      id: 3,
      icon: "mdi mdi-transit-transfer",
      title: "Waste Collection",
      value: total_visit,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      badgeValue: total_pending,
      color: "primary",
      desc: "Pending Visit",
      series: visitPercentage,
      options: options3,
    },
    {
      id: 4,
      icon: "mdi mdi-human-male-female",
      title: "Staff Present",
      value: total_present,
      decimal: 0,
      prefix: "",
      suffix: "",
      charttype: "radialBar",
      chartheight: 45,
      chartwidth: 45,
      badgeValue: total_staff,
      color: "warning",
      desc: "Total staff",
      series: staffPercentage,
      options: options4,
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Al-Farha Cargo" breadcrumbItem="Dashboard" />
          <Row>
            <MiniWidget reports={reports} />
          </Row>

          <Row>
            <Col xl={7}>
             
            
            <Card>
                <CardBody>
                
                
                <Row>
                  <Col xl={7}>
                  <center><CardTitle className="mb-4 h4">Waste Collection & Payment Collection</CardTitle></center>
                  </Col>
                <Col xl={4}>
                      <input type="month" value={currentMonth}
                       onChange={handleChangeMonth}
                      className="form-control"></input> 
                      
                </Col>
                <Col xl={1}>
                <Spinner style={{ display:isloading}} className="m-1" color="dark" />
                  </Col>
              </Row>
                <SalesAnalyticsChart graphData={graphData} />
                </CardBody>
                </Card>
              
            </Col>
            <Col xl={5} >
              <WasteCollectItems
                wasteItems={wasteItems}
                maxWeight={max_weight}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
