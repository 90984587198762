/* USERS */
export const GET_COMPANIES_OPTIONS = "GET_COMPANIES_OPTIONS";
export const GET_COMPANIES_OPTIONS_SUCCESS = "GET_COMPANIES_OPTIONS_SUCCESS";
export const GET_COMPANIES_OPTIONS_FAIL = "GET_COMPANIES_OPTIONS_FAIL";

export const GET_COMPANIES_MASTER_OPTIONS = "GET_COMPANIES_OPTIONS";
export const GET_COMPANIES_MASTER_OPTIONS_SUCCESS = "GET_COMPANIES_MASTER_OPTIONS_SUCCESS";
export const GET_COMPANIES_MASTER_OPTIONS_FAIL = "GET_COMPANIES_MASTER_OPTIONS_FAIL";
export const SAVE_SELECTED_COMPANY="SAVE_SELECTED_COMPNAY";
export const SAVE_SELECTED_COMPANY_SUCCESS="SAVE_SELECTED_COMPANY";
export const SAVE_SELECTED_COMPANY_FAIL="SAVE_SELECTED_COMPANY";

export const GET_LOCALBODIES_MASTER_OPTIONS = "GET_LOCALBODIES_MASTER_OPTIONS";
export const GET_LOCALBODIES_MASTER_OPTIONS_SUCCESS = "GET_LOCALBODIES_MASTER_OPTIONS_SUCCESS";
export const GET_LOCALBODIES_MASTER_OPTIONS_FAIL = "GET_LOCALBODIES_MASTER_OPTIONS_FAIL";

export const ADD_COMPANY = 'ADD_COMPANY';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAIL = 'ADD_COMPANY_FAIL';

export const UPDATE_COMPANY = 'UPDATE_COMPANY';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANY_FAIL = 'UPDATE_COMPANY_FAIL';

export const DELETE_COMPANY = 'DELETE_COMPANY';
export const DELETE_COMPANY_SUCCESS = 'DELETE_COMPANY_SUCCESS';
export const DELETE_COMPANY_FAIL = 'DELETE_COMPANY_FAIL';


 