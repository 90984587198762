/*Groups*/
export const GET_GROUPS="GET_GROUPS";
export const GET_GROUPS_SUCCESS="GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAIL="GET_GROUPS_FAIL";


export const GET_GROUP = "GET_GROUP";
export const GET_GROUP_SUCCESS = "GET_GROUP_SUCCESS";
export const GET_GROUP_FAIL = "GET_GROUP_FAIL";

export const ADD_GROUP = "ADD_GROUP";
export const ADD_GROUP_SUCCESS = "ADD_GROUP_SUCCESS";
export const ADD_GROUP_FAIL = "ADD_GROUP_FAIL";

export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_GROUP_SUCCESS = "UPDATE_GROUP_SUCCESS";
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL";

export const DELETE_GROUP = "DELETE_GROUP";
export const DELETE_GROUP_SUCCESS = "DELETE_GROUP_SUCCESS";
export const DELETE_GROUP_FAIL = "DELETE_GROUP_FAIL";

export const GET_GROUP_OPTIONS = "GET_GROUP_OPTIONS";
export const GET_GROUP_OPTIONS_SUCCESS = "GET_GROUP_OPTIONS_SUCCESS";
export const GET_GROUP_OPTIONS_FAIL = "GET_GROUP_OPTIONS_FAIL";

export const GET_WARDS_GROUP_OPTIONS = "GET_WARDS_GROUP_OPTIONS";
export const GET_WARDS_GROUP_OPTIONS_SUCCESS = "GET_WARDS_GROUP_OPTIONS_SUCCESS";
export const GET_WARDS_GROUP_OPTIONS_FAIL = "GET_WARDS_GROUP_OPTIONS_FAIL";
