import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  // Modal,
  // Table,
} from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import {
  getLocalbody,
  getDate,
  getFirstday,
  getArraySumByKey,
  formatMoney,
} from "../../../helpers/globalFunctions";
import "./customerwise.scss";
import { getCustomerType } from "../../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";



const CustomerwiseReport = (props) => {

  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [master, setMaster] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });

  const [custForTable, setCustForTable] = useState([]); //table row

  const [searchData, setSearchData] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [custIdToBeDeleted, setcustIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  useEffect(() => {
    handleTableData();
  }, [currentLocalbody]);


  const handleTableData = (from_date = "", to_date = "") => {
    var url = `${API_URL}customer/customer_report?Datefrom=` + from_date;

    url =
      url +
      "&Dateto=" +
      to_date;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        let result = res.data.data;

        let Data = [];

        let dataToBeExported = [];

        result.map((item, index) => {

          item.id = index + 1;

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="far fa-trash-alt"
                style={{
                  cursor: "pointer", fontSize: "1em"
                }}
                onClick={() => {
                  setcustIdToBeDeleted(item?._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>

            </div>
          )


          var exportItem = {};

          exportItem.Slno = item.id;
          exportItem.Customer = item.name;
          exportItem.Mobile = item.mobile_number;
          exportItem.Email = item.email;
          exportItem.Address = item.address;
          exportItem.Post_Office = item.post_office;
          exportItem.Pincode = item.pin_code;
          exportItem.State = item.state;
          exportItem.Country = item.country;

          dataToBeExported.push(exportItem);

          Data.push(item);
        })

        setCustForTable(Data);

        setDataToBeExported([
          ...dataToBeExported,
          {
            State: "",

            Country: result.country,
          }
        ])

        setMaster(result);
      })
  }


  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setMaster({
        ...master,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setMaster({
        ...master,
        [e.target.name]: e.target.value,
      });
    }
    handleTableData(date1, date2);
  };


  const reset = () => {
    setMaster({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    handleTableData("", "", "");
  };


  const handleSearch = () => {
    let from_date = master?.from_date ? master.from_date : "";
    let to_date = master?.to_date ? master.to_date : "";
    handleTableData(from_date, to_date);
  };


  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Customer",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Mobile",
        field: "mobile_number",
        sort: "asc",
        width: 400,
      },
      {
        label: "House Name",
        field: "house_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Street",
        field: "street",
        sort: "asc",
        width: 400,
      },
      {
        label: "Post Office",
        field: "post_office",
        sort: "asc",
        width: 400,
      },
      {
        label: "Pincode",
        field: "pin_code",
        sort: "asc",
        width: 400,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 400,
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
        width: 400,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 400,
      },
    ],
    rows: custForTable,
  };

  return (
    <React.Fragment>

      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}customer/customer_reportDelete?_id=${custIdToBeDeleted}`, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Customer Deleted successfully");
                  handleTableData();
                  setMaster({});
                } else {
                  toastr.error("Failed to save customer");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              })
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}


      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Customer Report" />

          <Row>
            <Col className="col-12">

              <Row>
                <Col md="2">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">From</Label>
                    <input
                      className="form-control"
                      type="date"
                      id="from_date"
                      name="from_date"
                      value={master?.from_date}
                      onChange={handleDate}
                    />
                  </div>
                </Col>

                <Col md="2">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">To</Label>
                    <input
                      className="form-control"
                      type="date"
                      id="to_date"
                      name="to_date"
                      value={master?.to_date}
                      min={master?.from_date}
                      onChange={handleDate}
                    />
                  </div>
                </Col>

                <Col md="1" style={{ marginTop: "26px" }}>
                  <Button color="primary" type="search" onClick={() => handleSearch()}>Search</Button>
                </Col>

                <Col md="1" style={{ marginTop: "26px" }}>
                  <Button color="danger" type="reset" onClick={() => reset()}>Reset</Button>
                </Col>

                <Col md="2" style={{ marginTop: "26px" }}>
                  <Button color="success" type="button">
                    <i className="fas fa-file-excel"></i>
                    <CSVLink
                      data={dataToBeExported}
                      filename={
                        "Customer_report_" + getDate(new Date()) + ".xls"
                      }
                      style={{ color: "white" }}
                    >
                      Export
                    </CSVLink>

                  </Button>
                </Col>

              </Row>

              <MDBDataTable
                id="CustomerReportTableId"
                responsive
                bordered
                data={data}
                info={true}
                searching={true}
                disableRetreatAfterSorting={true}
                entries={20}
              />
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CustomerwiseReport;