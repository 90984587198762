import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Col, Label, Button, Card, CardBody, Row, ToastHeader } from 'reactstrap';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { MDBDataTable } from "mdbreact";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";

import "./complaint.scss"
import axios from "axios";

import SweetAlert from "react-bootstrap-sweetalert";



const Complaint = () => {
  const [complaintObject, setcomplaintObject] = useState({});
  const [complaintForTable, setfcomplaintForTable] = useState([]);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [complaintIdToBeUpdated, setcomplaintIdToBeUpdated] = useState(null);

  const [complaintIdToBeDeleted, setcomplaintIdToBeDeleted] = useState(null);

  const [uploadProgress, setUploadProgress] = useState();


  const reset = () => {
    formRef.current.reset();
    setcomplaintObject({});
    setcomplaintIdToBeUpdated(null);
  }


  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };


  const {
    districts,
    addingcomplaint,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();


  useEffect(() => {
    // fetch_modules();
    // fetch_branch();
    // fetch_privilege();
    handleTableData();
  }, []);


  function handleTableData() {
    var url = `${API_URL}complaintCategory/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let complaintData = [];

        result.map((item, index) => {

          if (typeof item.icon != "undefined" && item.icon != "") {
            item.icon = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={`${API_URL}uploads/complaint_icon/${item.icon}`}
                  style={{ height: "25px", cursor: "pointer" }}
                  onClick={() => window.open(`${API_URL}uploads/complaint_icon/${item.icon}`, "_blank")}
                />
              </div>
            );
          }
          else {
            item.icon = "";
          }
          // if(item.icon!="" && typeof item.icon!="undefined")
          // {
          //   item.icon = (
          //     <div
          //       style={{
          //         display: "flex",
          //         justifyContent: "space-evenly",
          //       }}
          //     >
          //       <img
          //         src={`${API_URL}uploads/faqCategory_icon/${item.icon}`} 
          //       ></img>
          //     </div>
          //   );
          // }
          // else{
          //   item.icon="";
          // }

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdatecomplaint(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setcomplaintIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;

          if (item?.updatedBy) item.staff = item.updatedBy.firstName;
          else item.staff = item?.addedby.firstName;
          // if (item?.lastName) {
          //   item.staff = item?.firstName + " " + item?.lastName;
          // } else {
          //   item.staff = item?.firstName;
          // }
          complaintData.push(item);
        });

        setfcomplaintForTable(complaintData);

      });

  }


  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        // sort: "asc",
        width: 150,
      },
      {
        label: "Category Name",
        field: "category_name",
        sort: "desc",
        width: 270,
      },
      {
        label: "Icon",
        field: "icon",
        // sort: "asc",
        width: 200,
      },
      // {
      // label: "Contact Person",
      // field: "pname",
      // sort: "asc",
      // width: 100,
      // },
      // {
      // label: "Mobile",
      // field: "mobile",
      // sort: "asc",
      // width: 10,
      // },
      // {
      // label: "City",
      // field: "city",
      // sort: "asc",
      // width: 100,
      // },
      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "desc",
        width: 150,
      },
    ],
    rows: complaintForTable,
  };


  let preUpdatecomplaint = (item) => {
    // setfaqCategoryValue(item?.name);

    setcomplaintIdToBeUpdated(item?._id);
    axios
      .get(`${API_URL}complaintCategory/single?complaint_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data && res.data.success === true) {
          var state = res.data.data
          setcomplaintObject(state);

        }
      });

  };


  //  let preUpdatecomplaint = (item) => {
  //   // item.from = moment(item?.from).format("YYYY-MM-DD");
  //   // settechValue(item);
  //   setcomplaintIdToBeUpdated(item?._id);
  //   setcomplaintObject(item);
  // };

  function handleChangeInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    // setfaqCategoryValue(value);
    setcomplaintObject({ ...complaintObject, [name]: value });
  }


  const handleValidSubmit = () => {

    if (complaintIdToBeUpdated) {
      axios
        .put(`${API_URL}complaintCategory/update?_id`, complaintObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Complaint Category updated successfully");
            handleTableData();
            setcomplaintObject({});
            formRef.current.reset();
            setcomplaintIdToBeUpdated(null);

          } else toastr.error("Failed to update Complaint Category");
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}complaintCategory/`, complaintObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success("Complaint Category added successfully");
            handleTableData();
            // setShowCompany(false);
            // setfaqCategoryValue("")
            setcomplaintObject({});
            formRef.current.reset();

          } else toastr.error("Failed to add Complaint Category");
        })
        .catch((err) => {
          console.log(err)
          toastr.error(err.response.data.message);
          return;
        });
    }
  };


  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("complaint_icon", e.target.files[0]);
    axios
      .post(`${API_URL}complaintCategory/icon/`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setcomplaintObject({
            ...complaintObject,
            [`icon`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };


  const deleteImage = () => {
    setcomplaintObject({
      ...complaintObject,
      [`icon`]: "",
    });
  };


  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}complaintCategory` + "/" + complaintIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Complaint Category deleted successfully");

                  if (complaintObject && complaintObject?._id === complaintIdToBeDeleted) {
                    formRef.current.reset();
                    setcomplaintObject({});
                    setcomplaintIdToBeUpdated(null);
                    //  setfaqIdToBeUpdated(null);

                  }
                  handleTableData();
                } else {
                  toastr.error("Failed to delete Complaint Category");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumb title="Home" breadcrumbItem="Complaint Category" />
          <Row>
            <Col xl="12">
              {/* <Card>
                <CardBody> */}
              <AvForm
                className="needs-validation"
                ref={formRef}
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <Row>
                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Category Name</Label>
                      <AvField
                        name="category_name"
                        placeholder="Category Name"
                        type="text"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        errorMessage="Enter Category Name"
                        onChange={handleChangeInput}
                        value={complaintObject?.category_name}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">Icon</Label>
                      {complaintObject?.icon ? (
                        <div div className="img-wraps">
                          {complaintObject.icon &&
                            complaintObject.icon.startsWith("http") ? (
                            <img
                              alt=""
                              width="150"
                              height="150"
                              src={`${complaintObject.icon}`}
                            />
                          ) : (
                            <img
                              alt=""
                              width="150"
                              height="150"
                              src={`${API_URL}uploads/complaint_icon/${complaintObject.icon}`}
                            />
                          )}
                          <button
                            className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                            onClick={deleteImage}
                            style={{ width: "150px" }}
                          >
                            Delete
                          </button>
                        </div>
                      ) : (
                        <AvField
                          name="icon"
                          type="file"
                          className="form-control"
                          id="validationCustom03"
                          onChange={uploadImage}
                          rows="1"
                        />
                      )}
                    </div>
                  </Col>

                  <Col md="3" style={{ paddingTop: "4px" }}>
                    <div className='mt-4'>
                      {complaintIdToBeUpdated ? (
                        <Button className='mx-2'
                          color='primary'
                          type='submit'
                          // style={{ marginRight: "5%" }}
                          disabled={addingcomplaint ? true : false}
                        >
                          {addingcomplaint ? "Updating" : "Update"}
                        </Button>
                      )
                        :
                        <Button className='mx-2'
                          color='primary'
                          type='submit'
                          disabled={addingcomplaint ? true : false}
                        >
                          {addingcomplaint ? "Adding" : "Submit"}
                        </Button>
                      }
                      <Button
                        color='danger'
                        type='reset'
                        onClick={() => reset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>

          <Row>
            <Col className='col-12'>
              {/* <Card>
                <CardBody> */}
              <MDBDataTable
                responsive
                id="Addcomplainttable"
                bordered
                data={data}
                searching={true}
                info={true}
                disableRetreatAfterSorting={true}
                entries={20} />
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>

        </div>
      </div>

    </React.Fragment>

  )
}

export default Complaint;