import React, { useRef, useEffect, useState } from 'react';
import Select from 'react-select';
import { MDBDataTable } from "mdbreact";
import toastr from 'toastr';
import { Modal } from "react-bootstrap";
import "./Addvat.scss";
import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from 'axios';
import accessToken from '../../../helpers/jwt-token-access/accessToken';

function AddVat() {


  useEffect(() => {

    handleTableData();

  }, [])


  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [addvat, setaddvat] = useState({});
  const [vattable, setvattable] = useState([]);
  const [vatIdToBeUpdated, setvatIdToBeUpdated] = useState(null);

  const formRef = useRef();
  const reset = () => {
    formRef.current.reset();
    setaddvat({});
    setvatIdToBeUpdated(null);
  }





  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150
      },
      {
        label: "Vat Amount",
        field: "vat",
        sort: "asc",
        width: 150
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150
      },
    ],
    rows: vattable,
  }

  function handleInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    setaddvat({ ...addvat, [name]: value })
  }


  const handleValidSubmit = () => {


    if (vatIdToBeUpdated) {
      axios
        .post(`${API_URL}vatadd/vatupdate`, addvat, {
          headers: {
            "x-access-token": accessToken,
          }
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Vat Updated Successfully")
            formRef.current.reset();
            handleTableData();
            setaddvat({});
            reset();
          }
          else {
            toastr.error("Failed To Update VAT")
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message)
          return;
        })
    }
    else {

      axios
        .post(`${API_URL}vatadd`, addvat, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Vat Added Successfully")
            formRef.current.reset();
            handleTableData();
            reset();
            setaddvat({})
          }
          else {
            toastr.error("Failed to Add Vat");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        })
    }
  }



  function handleTableData() {
    axios
      .get(`${API_URL}vatadd/vatlist`, {
        headers: {
          "x-access-token": accessToken
        },
      })
      .then((res) => {

        var result = res.data.data;
        let vatData = [];
        result.map((item, index) => {
          if (item?._id) {
            setvatIdToBeUpdated(item?._id)
            setaddvat(item)
          }

          item.id = index + 1
          if (item?.updatedBy) {
            let values = item?.updatedBy || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
          } else {
            let values = item?.createdBy || {}
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName
          }

          vatData.push(item)
        });
        setvattable(vatData)
      })
  }


  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumb title="Home" breadcrumbItem="Add Vat" />
          <Row>
            <Col xl="12">
              {/* <Card className='p-2'>
                 <CardBody> */}
              <AvForm
                ref={formRef}
                className="needs-validation"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}

              >
                <Row>
                  <Col md='3'>
                    <div className='mb-3'>
                      <Label>Add Vat</Label>
                      <AvField
                        name="vat"
                        placeholder="VAT"
                        onChange={handleInput}
                        value={addvat?.vat}
                        //type="number"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        errorMessage="Enter Vat"


                      />
                    </div>
                  </Col>
                  <Col md="3" style={{ paddingTop: "4px" }}>
                    <div className='mt-4'>
                      {vatIdToBeUpdated ? (
                        <Button className='mx-2'
                          color='primary'
                          type='Update'>
                          Update
                        </Button>
                      ) : <Button className='mx-2'
                        color='primary'
                        type='submit'>
                        Submit
                      </Button>}


                    </div>
                  </Col>
                </Row>
              </AvForm>
              {/* </CardBody>
               </Card> */}
            </Col>
          </Row>
          <Col className='col-12'>
            {/* <Card>
              <CardBody> */}
            <MDBDataTable
              bordered
              responsive
              id="vattable"
              data={data}
              entries={20} />
            {/* </CardBody>
             </Card> */}
          </Col>


        </div>
      </div>
    </React.Fragment>
  )
}

export default AddVat