import React from 'react'
import { Row, Col, Card, CardBody, Button, Label, CardTitle } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
// import { MDBDataTable } from "mdbreact";
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { useState, useEffect, useRef } from "react"
import axios from 'axios';
import toastr from 'toastr';
import { MDBDataTable } from "mdbreact";

import SweetAlert from 'react-bootstrap-sweetalert';
import accessToken from '../../../helpers/jwt-token-access/accessToken';
import "./addpack.scss";
import { MDBTable } from 'mdbreact';
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";


function AddPacking() {

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [packingObject, setpackingObject] = useState({});
  const [packingTable, setpackingTable] = useState([]);
  const [packingIdToBeUpDated, setpackingIdToBeUpDated] = useState(null);
  const [packingIdToBeUpDeleted, setpackingIdToBeUpDeleted] = useState(null);
  const [confirmDeletAlert, setconfirmDeletAlert] = useState(null)
  const [allbranchcoun, setallbranchcoun] = useState([]);
  const [values, setvalues] = useState([{
    rate: "",
    country: "",
  }])
  const [country, setCountry] = useState([])



  useEffect(() => {
    handleTableData();
    fetch_all_country();
  }, [])


  const formRef = useRef();
  const reset = () => {
    formRef.current.reset();
    handleTableData();
    setpackingObject({});
    setpackingIdToBeUpDated(null);
    // setallbranchcoun([])
    setvalues([{
      rate: "",
      country: "",
    }])
    fetch_all_country()

  }


  const fetch_all_country = () => {
    axios
      .get(`${API_URL}country/list_branch_countries`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var result = res.data.data

        setallbranchcoun(result)
      })
  }


  const OnTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }


  function handleInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    setpackingObject({ ...packingObject, [name]: value });
  }


  let preUpdatepacking = (item) => {

    setpackingIdToBeUpDated(item?._id)

    axios
      .get(`${API_URL}packingManage/singleList?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        setpackingObject(result);

        // var test =
        //   result.rate &&
        //   result.rate.map((el) => {
        //     return {
        //       name: el.country.name,
        //       rate: el.rate,
        //       _id: el.country._id
        //     };
        //   });


        // setallbranchcoun(test)

        // var test2 =
        //   result.rate &&
        //   result.rate.map((el) => {
        //     return {
        //       rate: el.rate,
        //       country: el.country._id
        //     };
        //   });
        // setvalues(test2)
      });
  }


  const onTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }


  let preupdateitem = (item) => {
    setpackingIdToBeUpDated(item._id);

    // setpackingObject(item);
  }


  function handleTableData() {
    axios
      .get(`${API_URL}packingManage/packingslist`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        let result = res.data.data;

        let catDat = [];

        result.map((item, index) => {

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i className="uil-edit-alt"
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  marginLeft: "0.4rem",
                  marginRight: "0.4rem"
                }}
                onClick={() => {
                  onTop();
                  preUpdatepacking(item);
                }}
              ></i>
              <i className="uil-trash-alt"
                style={{ fontSize: "1rem", cursor: "pointer" }}
                onClick={() => {
                  setpackingIdToBeUpDeleted(item._id)
                  setconfirmDeletAlert(true);
                }}
              ></i>
            </div>

          );
          item.id = index + 1;
          item.rate = parseFloat(
            item.rate
          ).toFixed(3);

          catDat.push(item);
        })
        setpackingTable(catDat);
      })



  }


  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: "150"
      },
      {
        label: "Category",
        field: "category_name",
        sort: "asc",
        width: "150"
      },
      {
        label: "Unit",
        field: "unit",
        sort: "asc",
        width: "150"
      },
      {
        label: "Rate",
        field: "rate",
        sort: "asc",
        width: "150"
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: "150"
      },
    ],
    rows: packingTable,
  }


  function handleInputrate(e, item, index) {
    let name = e.target.name
    let value = e.target.value
    setpackingObject({ ...packingObject, [name]: value })
  }


  const handleValidSubmit = () => {

    if (packingIdToBeUpDated) {
      axios
        .put(`${API_URL}packingManage/edit`, packingObject, {
          headers: {
            "x-access-token": accessToken,
          }
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Packing Category Updated Successfully");
            formRef.current.reset();
            handleTableData();
            setpackingObject({});
            setpackingIdToBeUpDated(null);
            // fetch_all_country();
            // setallbranchcoun([])
            setvalues([{
              rate: "",
              country: "",
            }])
          }
          else {
            toastr.error("Faild To Update Packing Category");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        })
    }
    else {
      axios
        .post(`${API_URL}packingManage`, packingObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {

          if (res.data.status === 200) {
            toastr.success(" Packing Category Added Successfully");
            formRef.current.reset();
            handleTableData();
            setpackingObject({});
            setpackingIdToBeUpDated(null);
            // fetch_all_country();
            // setallbranchcoun([])
            setvalues([{
              rate: "",
              country: "",
            }])
          }
          else {
            toastr.error("Failed to Add Packing Category");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  }


  return (
    <React.Fragment>
      {confirmDeletAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}packingManage/delete` + "/" + packingIdToBeUpDeleted, {
                headers: {
                  "x-access-token": accessToken,
                }
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Packing Category Deleted Successfully");
                  handleTableData();
                  setpackingIdToBeUpDeleted(null)
                  setpackingObject({});
                  reset();
                }
                else {
                  toastr.error("Failed to delete Packing Category")
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message)
                return;
              });
            setconfirmDeletAlert(false)
          }}
          onCancel={() => setconfirmDeletAlert(false)}
        >
          Are You Sure Want to Delete It
        </SweetAlert>
      ) : null}


      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumb title="Home" breadcrumbItem=" Packing Category" />
          <Row>
            <Col xl="12">
              {/* <Card>
                <CardBody> */}
              <AvForm
                ref={formRef}
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
                className="needs-validation">
                <Row>
                  <Col md="3">
                    <Label>Packing Category</Label>
                    <AvField
                      name="category_name"
                      type="text"
                      placeholder="Packing Category"
                      className="form-control"
                      onChange={handleInput}
                      value={packingObject?.category_name}
                      validate={{ required: { value: true } }}
                      errorMessage="Enter Packing Category"
                    />
                  </Col>
                  <Col md="3">
                    <Label>Unit</Label>
                    <AvField
                      name="unit"
                      type="text"
                      placeholder="Unit"
                      className="form-control"
                      onChange={handleInput}
                      value={packingObject?.unit}
                      validate={{ required: { value: true } }}
                      errorMessage="Enter Unit"

                    />
                  </Col>

                  <Col md="3">
                    <Label>Rate</Label>
                    <AvField
                      name="rate"
                      type="number"
                      placeholder="Rate"
                      className="form-control"
                      onChange={handleInput}
                      value={packingObject?.rate}
                      validate={{ required: { value: true } }}
                      errorMessage="Enter Rate"
                    />
                  </Col>

                  <Col md="3" style={{ paddingTop: "4px" }}>
                    <div className='mt-4'>
                      {packingIdToBeUpDated ? (
                        <Button className='mx-2'
                          color='primary'
                          type='update'>
                          Update
                        </Button>
                      ) : (
                        <Button className='mx-2'
                          color='primary'
                          type='submit'>
                          Submit
                        </Button>
                      )
                      }


                      <Button
                        color='danger'
                        type='reset'
                        onClick={() => reset()}

                      >
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>

              </AvForm>
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
          <Row>
            <Col className='col-12 pt-3'>
              {/* <Card>
                <CardBody> */}
              <MDBDataTable
                bordered
                responsive
                id="PackingTabless"
                data={data}
                entries={20}
              />

              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AddPacking