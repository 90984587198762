import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import Select from 'react-select';
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";

import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Col, Label, Button, Card, CardBody, Row, ToastHeader } from 'reactstrap';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { MDBDataTable } from "mdbreact";
import "./state.scss"
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import { logDOM } from "@testing-library/react";



function Statecargo() {

  const [stateObject, setstateObject] = useState({});
  const [country, setcountry] = useState([]);//options fetching 
  const [stateForTable, setstateForTable] = useState([]);

  const [stateIdToBeDeleted, setstateIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [stateIdToBeUpdated, setstateIdToBeUpdated] = useState(null);

  const [selectedCountry, setselectedCountry] = useState(null);//selecting option

  const [errors, setErrors] = useState({
    countryError: "",
  });


  const {
    districts,
    addingstate,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);


  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const formRef = useRef();


  const customStyles = {
    lineHeight: "1.8",
  };


  useEffect(() => {
    fetch_all_country();
    handleTableData();
  }, []);


  function fetch_all_country() {
    axios
      .get(`${API_URL}country/list_names`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        let country_data =
          res.data.data &&
          res.data.data.map((el) => {

            return {
              label: el?.name,
              value: el?._id,
            }
          })
        setcountry([{ options: country_data }])
      })
  }


  function handleTableData() {
    axios
      .get(`${API_URL}state/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        let result = res.data.data;

        let state_data = [];

        result.map((item, index) => {


          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1rem",
                  marginRight: "0.5rem"
                }}
                onClick={() => {
                  toTop();
                  preupdateState(item);
                }}
              ></i>

              <i className="far fa-trash-alt"
                style={{
                  fontSize: "1em",
                  cursor: "pointer"
                }}
                onClick={() => {
                  setConfirmDeleteAlert(true);
                  setstateIdToBeDeleted(item?._id);
                }}
              ></i>
            </div>
          )

          item.id = index + 1;
          item.country_name = item?.country?.name;
          if (item?.updatedBy) item.staff = item.updatedBy.firstName;
          else item.staff = item?.addedBy?.firstName;

          state_data.push(item);
        })

        setstateForTable(state_data);
      })
  }


  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "country":
        setselectedCountry(selected);
        setstateObject({
          ...stateObject,
          country: selected.value,
        });
        break;

      default:
        break;
    }
  }


  const handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setstateObject({ ...stateObject, [name]: value })
  }


  const reset = () => {
    formRef.current.reset();
    setselectedCountry(null);
    setstateIdToBeUpdated(null);
    setstateObject({});
    setErrors({
      ...errors,
      countryError: ""
    });
  }


  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Country",
        field: "country_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "State",
        field: "name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: stateForTable,
  }


  function handleValidSubmit() {
    if (selectedCountry === null) {
      let errorVal = errors;
      if (selectedCountry === null)
        errorVal.countryError = "Please select country"
      setErrors({ ...errorVal })
    } else {
      if (stateIdToBeUpdated) {

        let data = {
          _id: stateObject?._id,
          name: stateObject?.name ? stateObject?.name : "",
          country: stateObject?.country?._id ? stateObject?.country?._id : stateObject?.country,
        }

        axios
          .put(`${API_URL}state/`, data, {
            headers: {
              "x-access-token": accessToken,
            },
          }).then((res) => {
            if (res.data.status === 200) {
              toastr.success("State updated successfully");
              formRef.current.reset();
              setstateIdToBeUpdated(null);
              setselectedCountry(null);
              handleTableData();
              setstateObject({});
              setErrors({
                ...errors,
                countryError: ""
              })
            } else {
              toastr.error("Failed to update state");
              return;
            }
          }).catch((err) => {
            toastr.err(err.res.data.message);
            return;
          })
      } else {
        axios
          .post(`${API_URL}state/`, stateObject, {
            headers: {
              "x-access-token": accessToken,
            },
          }).then((res) => {
            if (res.data.status === 200) {
              toastr.success("State added successfully");
              formRef.current.reset();
              setstateObject({});
              setselectedCountry(null);
              handleTableData();
              setErrors({
                ...errors,
                countryError: ""
              })
            } else {
              toastr.error("Failed to add state");
              return;
            }
          })
      }
    }
  }


  let preupdateState = (item) => {
    setstateIdToBeUpdated(item?._id);

    let country1 = {
      label: item?.country?.name,
      value: item?.country?._id,
    }
    setselectedCountry(country1);

    setstateObject(item);
  }

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}state` + "/" + stateIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              }).then((res) => {
                if (res.data.status === 200) {
                  toastr.success("State deleted successfully");

                  if (stateObject && stateObject?._id === stateIdToBeDeleted) {
                    formRef.current.reset();
                    setstateIdToBeUpdated(null);
                    setselectedCountry(null)
                  }
                  handleTableData();
                } else {
                  toastr.error("Failed to delete state");
                  return;
                }
              })
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumb title="Home" breadcrumbItem="State" />

          <Row>
            <Col xl="12">
              <AvForm className="needs-validation"
                ref={formRef}
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v)
                }}
              >

                <Row>
                  <Col md="3">
                    <Label>Country</Label>
                    <Select
                      name="country"
                      placeholder="Country"
                      classNamePrefix="select2-selection"
                      value={selectedCountry}
                      options={country}
                      validate={{ required: { value: true } }}
                      onChange={(selected) => {
                        handleSelectChange(selected, "country")
                      }}
                    />
                    <p className="text-danger" style={{ fontSize: "11px" }}>
                      {selectedCountry === null ? errors.countryError : ""}
                    </p>
                  </Col>

                  <Col md="3">
                    <Label>State</Label>
                    <AvField
                      name="name"
                      placeholder="State"
                      className="form-control"
                      value={stateObject?.name}
                      type="text"
                      validate={{ required: { value: true } }}
                      onChange={handleChangeInput}
                      errorMessage="Enter State"
                    />
                  </Col>

                  <Col>
                    <div style={{ marginTop: "26px" }}>
                      {stateIdToBeUpdated ? (
                        <Button color="primary" type="update">
                          Update
                        </Button>
                      ) : (
                        <Button color="primary" type="submit">
                          Submit
                        </Button>
                      )}

                      <Button color="danger" type="reset" onClick={() => reset()} style={{ marginLeft: "10px" }}>
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>

              </AvForm>
            </Col>
          </Row>

          <Row>
            <Col xl="12" className="mt-3">
              <MDBDataTable
                responsive
                bordered
                id="Addstatetable"
                info={true}
                searching={true}
                data={data}
                entries={20}
              />
            </Col>
          </Row>


        </div>
      </div>

    </React.Fragment>

  )
}

export default Statecargo;