import React, { useEffect, useRef, useState } from 'react';
import {
    Row,
    Col,
} from "reactstrap";
import { Box } from "@mui/system";
import Barcode from 'react-barcode';
import moment from "moment";
import axios from "axios";
import LogoDark from "../../assets/images/logo-large.png";
import img from "../../assets/images/img.png";
import img2 from "../../assets/images/img2.png";
import accessToken from '../../helpers/jwt-token-access/accessToken';
export const PrintableComponentOfficeUse = React.forwardRef((props, ref) => {
    const SingleOrderList = props.data;
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const barcodeRef = useRef(null);

    const companyCheck = document.getElementById('companyCheck');
    const customerCheck = document.getElementById('customerCheck');

    if (SingleOrderList.cargo_packed_by === 0) {
        customerCheck.checked = true;
        companyCheck.checked = false;
    }
    if (SingleOrderList.cargo_packed_by === 1) {
        companyCheck.checked = true;
        customerCheck.checked = false;
    }




    return (
        <div ref={ref}>
            <div className="modal-content" style={{ display: "flex", justifyContent: "center" }}>
                <div className="ordersummary " style={{ width: "100%", padding: "18px" }}>
                    <Box className="summary text-center">
                        {/* <Row>
                    <h4 className="alfarha font-size-16">BOOKING NOTE # {SingleOrderList?.order_number}</h4>
                  </Row> */}
                        <Row style={{ margin: "0", padding: "0" }}>
                            <Col className="content" md="5" style={{ margin: "0" }}>
                                <img
                                    src={LogoDark}
                                    style={{ width: "90%", marginTop: "-21px" }}
                                    alt=""
                                />
                            </Col>
                            <Col md="3" style={{ margin: "0" }}><h4 className='alfarha2' style={{ fontFamily: 'Almarai', marginTop: "70px" }}><b>OFFICE COPY</b></h4></Col>
                            <Col md="4" style={{ margin: "0" }}>
                                <h3 className='alfarha2' style={{ fontSize: "3rem", fontFamily: "Almarai" }}><b>{SingleOrderList?.cargo_type?.cargo_Type?.toUpperCase()} | {SingleOrderList?.branch_id?.short_Code?.toUpperCase()}</b></h3> <br />
                                <h4 className='alfarha2' style={{ fontSize: "2rem", fontFamily: 'Almarai', marginTop: "-18px", }}><b>BOOKING NOTE</b></h4>
                            </Col>
                        </Row>
                    </Box>
                    <hr style={{ height: "1.3px", color: "#000", margin: "0px" }} />


                    <Row style={{ margin: "0px" }}>
                        <Col md="9" className="alfarha" style={{ fontFamily: "Almarai", fontSize: "2rem", textAlign: "left" }} >{(SingleOrderList?.booking_type == 0) ? SingleOrderList?.country_to?.name?.toUpperCase() : SingleOrderList?.city_to?.name?.toUpperCase()}</Col>
                        <Col md="3" style={{ margin: "1" }}>
                            <Barcode value={SingleOrderList?.order_number} format='CODE128'
                                displayValue={false}
                                // fontSize={16}
                                width={1.5}
                                height={30}
                                margin={1}
                                ref={barcodeRef}
                            />
                        </Col>

                    </Row>

                    <hr style={{ marginTop: "0px", marginBottom: "0px", height: "1.3px", color: "#000", margin: "0px" }} />

                    <Row>
                        <Col md="4" className='alfarha' style={{ fontFamily: 'Almarai' }}>Booking ID: <span className='alfarha2' style={{ fontSize: "17px" }}><b>{SingleOrderList?.cargo_type?.cargo_Type} {SingleOrderList?.order_number}</b></span></Col>
                        <Col md="4" ></Col>
                        <Col md="4" className="content1 alfarha" style={{ fontFamily: 'Almarai' }}>Date : {moment(SingleOrderList?.date + " " + SingleOrderList?.time).format("DD-MM-YYYY hh:mm a")}</Col>
                    </Row>
                    <hr style={{ marginTop: "0px", marginBottom: "0px", height: "1.3px", color: "#000" }} />

                    <Row>
                        <Col md="4" className="alfarha" style={{ fontFamily: 'Almarai' }}>Pickup / Offline Ref : <b>{SingleOrderList?.pickup}</b></Col>
                        <Col md="4" className="content1 alfarha toCapitalize" style={{ textAlign: "center", fontFamily: "Almarai" }}><b>{SingleOrderList?.payment_type}</b></Col>
                        <Col md="4" className="content1 alfarha toCapitalize" style={{ textAlign: "center", fontFamily: "Almarai" }}>Created By : <b>{SingleOrderList?.added_by?.firstName?.toUpperCase()} {SingleOrderList?.added_by?.lastName?.toUpperCase()}</b></Col>
                    </Row>
                    <hr className='hr-dashed' style={{ marginTop: "0px", marginBottom: "0px", }} />

                    <Row>
                        <Col>

                            <h6 className="alfarha" style={{ fontFamily: 'Almarai', margin: "0", padding: "0" }}><b>Consignor / Shipper Details :</b></h6>
                            <table id="senderAddTable" style={{ margin: "0", padding: "0" }}>
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Name</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}><b>{SingleOrderList?.sender?.name?.toUpperCase()}</b></th>
                                </tr>

                                {SingleOrderList?.sender?.id_number && (
                                    <tr>
                                        <th style={{ fontFamily: 'Almarai' }}>ID No</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}><b>{SingleOrderList?.sender?.id_number?.toUpperCase()}</b></th>
                                    </tr>
                                )}
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Mobile No</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}>{SingleOrderList?.sender?.mobile_number}
                                        <b>
                                            {
                                                SingleOrderList?.sender?.whattsapp_number ? (
                                                    <> / {SingleOrderList?.sender?.whattsapp_number}</>
                                                ) : null
                                            }
                                        </b>
                                    </th>
                                </tr>


                            </table>

                            {SingleOrderList?.credit_det && (
                                <>
                                    <h6 className="alfarha" style={{ fontFamily: 'Almarai' }}><b>Credit Customer Details :</b></h6>

                                    <tr>
                                        <th style={{ fontFamily: 'Almarai' }}>Name</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}><b>{SingleOrderList?.credit_det?.credit_cust_id?.name?.toUpperCase()}</b></th>
                                    </tr>

                                    <tr>
                                        <th style={{ fontFamily: 'Almarai' }}>Reference</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}><b>{SingleOrderList?.credit_det?.reference}</b></th>
                                    </tr>

                                </>
                            )}

                        </Col>

                        <Col className="mx-2" md-3>
                            <h6 className="alfarha" style={{ fontFamily: 'Almarai', margin: "0", padding: "0" }}><b>Consignee / Receiver Details :</b></h6>
                            <table id="receiverAddTable" style={{ margin: "0", padding: "0" }}>
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Name</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}><b>{SingleOrderList?.receiver?.name?.toUpperCase()}</b></th>
                                </tr>

                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Address</th><th> : </th><th style={{ fontSize: '9px' }}><b>{(SingleOrderList?.receiver?.house_name ? SingleOrderList?.receiver?.house_name?.toUpperCase() : "")},{(SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toUpperCase() : "")},
                                        {(SingleOrderList?.receiver?.landmark ? SingleOrderList?.receiver?.landmark?.toUpperCase() : "")}, {(SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toUpperCase() : "")}</b></th>
                                </tr>

                                {SingleOrderList?.receiver?.pin_code && (
                                    <tr>
                                        <th style={{ fontFamily: 'Almarai' }}>Pincode</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}>{SingleOrderList?.receiver?.pin_code?.toUpperCase()}</th>
                                    </tr>
                                )}

                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>District</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}>{SingleOrderList?.receiver?.district?.toUpperCase()}</th>
                                </tr>
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>State</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}>{SingleOrderList?.receiver?.rece_state?.name?.toUpperCase()}</th>
                                </tr>
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Mobile No</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}><b>{SingleOrderList?.receiver?.mobile_number} {SingleOrderList?.receiver?.telephone_number ? (
                                        <> / {SingleOrderList?.receiver?.telephone_number}</>
                                    ) : null}</b></th>
                                </tr>

                            </table>

                        </Col>

                    </Row>

                    <hr className='hr-dashed' style={{ marginTop: "0px", marginBottom: "0px", }} />

                    <Row >

                        <Col className="mt-2 with-dashed-border" md="8">
                            <table id="adddomestictableid">
                                <tbody>
                                    <tr>
                                        <th style={{ fontFamily: 'Almarai' }}>Sl No</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Item Category</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Unit</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Qnty</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Rate</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Amount</th>
                                    </tr>
                                </tbody>

                                <tbody>
                                    {
                                        SingleOrderList &&
                                        SingleOrderList?.item_details?.map((el, index) => {
                                            return (
                                                <tr>
                                                    <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>{index + 1}</td>
                                                    <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>{el?.item_category?.name?.toUpperCase()}</td>
                                                    <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>{el?.unit?.toUpperCase()}</td>
                                                    <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>{el?.quantity.toFixed(3)}</td>
                                                    <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>{el?.rate.toFixed(3)}</td>
                                                    <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>{el?.amount.toFixed(3)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>

                            </table>

                        </Col>

                        <Col md="4">

                            {/* <Row>

                                <Col md="2">
                                    <div className='mb-3 d-flex'>No of Boxes :</div>

                                </Col>

                                <Col md="2">
                                    <div>Total Weight :</div>

                                </Col>

                                <table>
                                    <tr>
                                        <th>Handling rate</th>
                                    </tr>
                                </table>
                            </Row> */}

                            <Row >
                                <Col className='col-cls-cls  with-dashed-border' >
                                    <div className='boxes-bx' style={{ fontFamily: 'Almarai', marginRight: "28px" }}><b>No of Boxes:</b></div>
                                    <div className='boxes-bx' style={{ fontWeight: "700", fontSize: "2rem", fontFamily: 'Almarai' }}><b>{SingleOrderList?.no_of_boxes}</b></div>
                                </Col>

                                {/* <div className='vertical-line'></div> */}
                                <Col className='col-cls-cls'>
                                    <div className='boxes-bx1' style={{ fontFamily: 'Almarai', marginRight: "20px" }}><b>Total Weight:</b></div>
                                    <div className='boxes-bx1' style={{ fontWeight: "700", fontSize: "2rem", fontFamily: "Almarai", marginRight: "20px" }}><b>{SingleOrderList?.total_weight?.toFixed(2)}</b></div>
                                </Col>

                                <hr className='hr-dashed' style={{ marginTop: "0px", marginBottom: "0px", }} />

                                <Col>
                                    <table >
                                        <tbody>
                                            <tr>
                                                <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>Handling Rate / Box</td>&nbsp;&nbsp;
                                                <td className="toCapitalize" style={{ textAlign: "right", fontFamily: "Almarai" }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <td className="toCapitalize" style={{ textAlign: "right", fontFamily: "Almarai" }}>{SingleOrderList?.handling_rate ? SingleOrderList?.handling_rate?.toFixed(3) : "0.000"}</td>
                                            </tr>

                                            <tr>
                                                <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>VAT %</td>&nbsp;
                                                <td className="toCapitalize" style={{ textAlign: "right", fontFamily: "Almarai" }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <td className="toCapitalize" style={{ textAlign: "right", fontFamily: "Almarai" }}>{SingleOrderList?.vat_percentage ? SingleOrderList?.vat_percentage?.toFixed(3) : "0.000"}</td>
                                            </tr>
                                        </tbody>

                                    </table>


                                </Col>

                                <hr className='hr-dashed' style={{ marginTop: "0px", marginBottom: "0px", }} />

                                <Col>
                                    <table>
                                        <tbody>
                                            {SingleOrderList?.charges &&
                                                SingleOrderList?.charges?.map((el, indx) => {

                                                    return (
                                                        <>
                                                            <tr>
                                                                <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>Total Freight Charge</td>&nbsp;
                                                                <td className="toCapitalize" style={{ textAlign: "right", fontFamily: "Almarai" }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <td className="toCapitalize" style={{ textAlign: "right", fontFamily: "Almarai" }}>{el?.sumOfitemandpackage ? el?.sumOfitemandpackage?.toFixed(3) : "0.000"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>Total Packing</td>&nbsp;
                                                                <td className="toCapitalize" style={{ textAlign: "right", fontFamily: "Almarai" }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <td className="toCapitalize" style={{ textAlign: "right", fontFamily: "Almarai" }}>{el?.packing ? el?.packing?.toFixed(3) : "0.000"}</td>
                                                            </tr>
                                                            <tr>
                                                                <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>Extra Charges</td>&nbsp;
                                                                <td className="toCapitalize" style={{ textAlign: "right", fontFamily: "Almarai" }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                                <td className="toCapitalize" style={{ textAlign: "right", fontFamily: "Almarai" }}>{el?.extra ? el?.extra?.toFixed(3) : "0.000"}</td>
                                                            </tr>

                                                        </>
                                                    )
                                                })
                                            }
                                            <tr>
                                                <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>Handling Charges</td>&nbsp;
                                                <td className="toCapitalize" style={{ textAlign: "right", fontFamily: "Almarai" }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <td className="toCapitalize" style={{ textAlign: "right", fontFamily: "Almarai" }}>{SingleOrderList?.handling_rate_total ? SingleOrderList?.handling_rate_total?.toFixed(3) : "0.000"}</td>
                                            </tr>
                                            <tr>
                                                <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>VAT</td>&nbsp;
                                                <td className="toCapitalize" style={{ textAlign: "right", fontFamily: "Almarai" }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <td className="toCapitalize" style={{ textAlign: "right", fontFamily: "Almarai" }}>{SingleOrderList?.vat_amount ? SingleOrderList?.vat_amount?.toFixed(3) : "0.000"}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                    <hr className='hr-dashed' style={{ marginTop: "0px", marginBottom: "0px", }} />

                    <Row>
                        <Col md="8">
                            <div style={{ fontWeight: "700", fontFamily: "Almarai" }}>Total Cargo Value(OMR) : {SingleOrderList?.total_value?.toFixed(3)}</div>
                        </Col>

                        <Col md="4">
                            <table>
                                <tbody>
                                    {SingleOrderList?.charges &&
                                        SingleOrderList?.charges?.map((el, index) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <b>
                                                            <td style={{ fontFamily: 'Almarai' }}>Grand Total</td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <td> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            {/* <td style={{ textAlign: "right", fontFamily: "Almarai" }}>{SingleOrderList?.grand_total?.toFixed(3)}</td> */}
                                                            <td style={{ textAlign: "right", fontFamily: "Almarai" }}>{el?.amount?.toFixed(3)}</td>
                                                        </b>
                                                    </tr>
                                                </>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </Col>
                    </Row>

                    <hr className='hr-dashed' style={{ marginTop: "0px", marginBottom: "0px", }} />

                    <Row style={{ marginBottom: "0" }}>
                        <Col md="12">
                            {SingleOrderList?.item_details &&
                                <div style={{ fontFamily: 'Almarai' }}><b>
                                    Item Description: </b>
                                    <ul style={{ listStyle: "dot", fontFamily: 'Almarai' }}>
                                        {SingleOrderList.item_details.map((el, indx) => (
                                            <li key={indx} style={{ display: "inline", fontFamily: "Almarai", fontSize: "10px" }}>
                                                <b>
                                                    {el.item_type === 1 || el.item_type === 3 || el.item_type === 4 ? el.description : null}
                                                </b>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            }
                        </Col>

                    </Row>

                    <Row style={{ margin: "0", padding: "0" }} >
                        <Col md="5" >
                            <div style={{ fontFamily: 'Almarai' }}><b>Expected Delivery: {SingleOrderList?.expected_delivery}</b></div>
                            <div style={{ fontFamily: 'Almarai' }}><b>Cargo Packed By: </b>&nbsp;
                                <input type='checkbox' id='companyCheck' style={{ fontFamily: 'Almarai' }} />&nbsp; <b>Company</b> &nbsp;
                                <input type='checkbox' id='customerCheck' style={{ fontFamily: 'Almarai' }} />&nbsp; <b>Customer</b>
                            </div>
                        </Col>

                        <Col md="7">
                            <div >
                                <img src={img2} alt="" style={{ width: "100%" }} />
                            </div>
                        </Col>

                    </Row>

                    <Row style={{ padding: "0", margin: "0" }}>
                        <Col md="12" style={{ paddingTop: "0", paddingBottom: "0", margin: "0" }}>
                            <div>
                                <img src={img} alt="" style={{ width: "100%" }} />
                            </div>
                        </Col>
                    </Row>

                    <Row >
                        <Col md="6" className='mt-5' style={{ fontWeight: "700", fontFamily: 'Almarai' }}>
                            Signature of Shipper
                        </Col>
                        <Col md="6" className="content1 mt-5" style={{ fontWeight: "700", fontFamily: 'Almarai' }}>
                            Payment Received By
                        </Col>
                    </Row>

                    <hr style={{ marginTop: "0px", marginBottom: "0px", height: "1px", color: "#000" }} />


                    <Row>
                        <Col md="6" >

                            <table id='weighttableid'>
                                <tbody>
                                    <tr>
                                        <th style={{ textAlign: "right", fontFamily: "Almarai" }}>Weight</th>
                                        <th style={{ textAlign: "left", fontFamily: "Almarai" }}>Unique Box ID</th>
                                        <th style={{ textAlign: "center", fontFamily: 'Almarai' }}>Barcode</th>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        SingleOrderList &&
                                        SingleOrderList?.box_details?.map((el, index) => {
                                            const boxNumber = el?.box_number;
                                            // if (boxNumber.endsWith('01') || boxNumber.endsWith('03') ) 
                                            if (parseInt(boxNumber.slice(-2)) % 2 === 1) {
                                                // Render table when box numbers ending with odd numbers
                                                return (
                                                    <tr>
                                                        <td style={{ textAlign: "left", fontFamily: "Almarai" }}><b>{el?.box_weight?.toFixed(2)}</b></td>
                                                        <td className="toCapitalize" style={{ textAlign: "left", fontFamily: 'Almarai' }}>{el?.box_number}</td>
                                                        <td><Barcode value={el?.box_number} format="CODE128"
                                                            displayValue={false}
                                                            fontSize={12}
                                                            width={1}
                                                            height={18}
                                                        /></td>
                                                    </tr>

                                                )

                                            } else {
                                                return null;
                                            }
                                        })
                                    }
                                </tbody>
                            </table>
                        </Col>

                        {SingleOrderList?.box_details?.some(el => el?.box_number.endsWith('02') || el?.box_number.endsWith('04')) && (

                            <Col md="6" className='with-line-border'>

                                <table id="weighttableid">
                                    <tbody>
                                        <tr>
                                            <th style={{ textAlign: "right", fontFamily: 'Almarai' }}>Weight</th>
                                            <th style={{ textAlign: "left", fontFamily: 'Almarai' }}>Unique Box ID</th>
                                            <th style={{ textAlign: "center", fontFamily: 'Almarai' }}>Barcode</th>
                                        </tr>
                                    </tbody>
                                    <tbody>
                                        {
                                            SingleOrderList &&
                                            SingleOrderList?.box_details?.map((el, index) => {
                                                const boxNumber = el?.box_number;
                                                // if (boxNumber.endsWith('02') || boxNumber.endsWith('04')) 
                                                if (parseInt(boxNumber.slice(-2)) % 2 === 0) {
                                                    // boxnumbers ending with even nos
                                                    return (
                                                        <tr>
                                                            <td style={{ textAlign: "left", fontFamily: "Almarai" }}><b>{el?.box_weight?.toFixed(2)}</b></td>
                                                            <td style={{ textAlign: "left", fontFamily: 'Almarai' }}>{el?.box_number}</td>
                                                            <td><Barcode value={el?.box_number} format="CODE128"
                                                                displayValue={false}
                                                                fontSize={12}
                                                                width={1}
                                                                height={18}
                                                            /></td>
                                                        </tr>

                                                    )

                                                } else {
                                                    return null;
                                                }
                                            })
                                        }
                                    </tbody>
                                </table>
                            </Col>
                        )}

                    </Row>
                </div>

            </div >
        </div >
    );
});