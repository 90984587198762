import React, { useState, useEffect } from "react";
import { Card, CardBody, Row, Col, Label, Button } from "reactstrap";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { MDBDataTable } from "mdbreact";

import $ from "jquery";
import moment from "moment";

import airtypeIcon from "../../../assets/icons/air.svg";
import seatypeIcon from "../../../assets/icons/sea.svg";
import roadtypeIcon from "../../../assets/icons/road.svg";
import d2dtypeIcon from "../../../assets/icons/d to d.svg";

import flag1 from "../../../assets/images/flags/india.svg";
import flag2 from "../../../assets/images/flags/pakistan.svg";
import flag3 from "../../../assets/images/flags/bangladesh.svg";
import {
    getFirstday,
    getDate
  
  } from "../../../helpers/globalFunctions";
import "./style.scss";
import Select from 'react-select';
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";


const StockReport = (props) => {

    const [searchData, setSearchData] = useState({
        from_date: getFirstday(new Date()),
        to_date: getDate(new Date()),
      });
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

    const [stockObject, setStockObject] = useState({});
    const [order, setOrder] = useState([]);

    const [branchOptions, setBranchOptions] = useState([]);
    const [selectedBranch, setSelectedBranch] = useState(null);
  
    const [cargoTypeOptions, setCargoTypeOptions] = useState([]);
    const [selectedCargoType, setSelectedCargoType] = useState(null);


    useEffect(() => {
        handleTableData();
        fetch_all_branch();
        fetch_all_cargotype();
      }, []);


      function fetch_all_branch() {
        axios
        .get(`${API_URL}reports/branch_wise` , {
            headers: {
                "x-access-token": accessToken,
            }
        })
        .then((res) => {
            // console.log("brn",res);

            var branch_data=
             res.data.data &&
             res.data.data.map((el) => {
                return {
                    label: el.branch_name,
                    value: el._id
                };
             });

             setBranchOptions([
                {
                    options: branch_data,
                },
             ]);
        });

      }

      const fetch_all_cargotype = () => {
        axios
        .get(`${API_URL}cargoType/list ` , {
            headers: {
                "x-access-token": accessToken,
            },
        })
        .then((res) => {
            // console.log("crgo",res);

            var cargo_data=
              res.data.data &&
              res.data.data.map((cr) => {
                return {
                    label: cr.cargo_Type,
                    value: cr._id
                };
              });

              setCargoTypeOptions([
                {
                    options: cargo_data,
                },
              ]);
        });

      }


      function handleTableData() {

      }



    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: "date",
                sort: "asc",
                width: 150,
            },
            {
                label: "Order",
                field: "order",
                sort: "asc",
                width: 270,
            },
            {
                label: "Box",
                field: "box",
                sort: "asc",
                width: 200,
            },
            {
                label: "Weight",
                field: "weight",
                sort: "asc",
                width: 100,
            },
            {
                label: "From",
                field: "from",
                sort: "disabled",
                width: 10,
            },
            {
                label: "Cargo",
                field: "cargo",
                sort: "disabled",
                width: 10,
            },
            {
                label: "To",
                field: "to",
                sort: "disabled",
                width: 10,
            },
            {
                label: "Status",
                field: "status",
                sort: "disabled",
                width: 100,
            },
        ],
        rows: [
            {
                id: "1",
                date: "11-06-2022",
                order: "311 0012",
                box: "1/1",
                weight: "23.9",
                from: (<img src={flag1} style={{ height: "35px" }} title="India" />),
                cargo: (<img src={airtypeIcon} title="Air" style={{ height: "25px" }} />),
                to: (<img src={flag2} style={{ height: "35px" }} title="Pakistan" />),
                status: (<span className="badge bg-primary">Dispatched</span>)
            },
            {
                id: "2",
                date: "28-05-2022",
                order: "011 0001",
                box: "4/4",
                weight: "75",
                from: (<img src={flag2} title="Pakistan" style={{ height: "35px" }} />),
                cargo: (<img src={airtypeIcon} title="Air" style={{ height: "25px" }} />),
                to: (<img src={flag1} title="India" style={{ height: "35px" }} />),
                status: (<span className="badge bg-success">Delivered</span>)
            },
            {
                id: "3",
                date: "19-02-2022",
                order: "311 0010",
                box: "1/1",
                weight: "2",
                from: (<img src={flag1} title="India" style={{ height: "35px" }} />),
                cargo: (<img src={roadtypeIcon} title="Road" style={{ height: "25px" }} />),
                to: (<img src={flag1} title="India" style={{ height: "35px" }} />),
                status: (<span className="badge bg-success">Delivered</span>)
            },
            {
                id: "4",
                date: "10-02-2022",
                order: "321 0008",
                box: "2/2",
                weight: "24.3",
                from: (<img src={flag3} title="Bangladesh" style={{ height: "35px" }} />),
                cargo: (<img src={seatypeIcon} title="Sea" style={{ height: "25px" }} />),
                to: (<img src={flag2} title="Pakistan" style={{ height: "35px" }} />),
                status: (<span className="badge bg-info">Out for Delivery</span>)
            },
            {
                id: "5",
                date: "14-01-2022",
                order: "321 0006",
                box: "1/1",
                weight: "17",
                from: (<img src={flag1} title="India" style={{ height: "35px" }} />),
                cargo: (<img src={seatypeIcon} title="Sea" style={{ height: "25px" }} />),
                to: (<img src={flag3} title="Bangladesh" style={{ height: "35px" }} />),
                status: (<span className="badge bg-danger">Pending</span>)
            },

        ],
    };


    const handleSelectChange = (selected,name) => {
        switch(name) {

            case "cargo_Type":
                setSelectedCargoType(selected);
                setStockObject({
                    ...stockObject,
                });
                break;

            case "branch":
                setSelectedBranch(selected);
                setStockObject({
                    ...stockObject,
                });
                break;

                default:
                    break;
        }

    }

    let handleDate = (e) => {
        var date1 = $("#from_date").val();
        var date2 = $("#to_date").val();
    
        //date validation
        let country_id = searchData?.country_id ? searchData.country_id : "";
        let toDate = moment(date1);
        let fromDate = moment(date2);
        let result = fromDate.diff(toDate, "days");
    
        if (result + 1 <= 0) {
          setSearchData({
            ...searchData,
            [e.target.name]: e.target.value,
            ["to_date"]: "",
          });
          date2 = "";
        } else {
          setSearchData({
            ...searchData,
            [e.target.name]: e.target.value,
          });
        }
        handleTableData(date1, date2, country_id);
    
      };
    


    const reset = () => {
        setSearchData({
            from_date: getFirstday(new Date()),
            to_date: getDate(new Date()),
          });
        setSelectedBranch(null);
        setSelectedCargoType(null);
        handleTableData();
    }


    return (
        <React.Fragment>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumb title="Home" breadcrumbItem="Stock Report" />
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md="2">
                                            <Label>From</Label>
                                            <input type="date"
                                             name="from_date" 
                                             id="from_date"
                                             className="form-control"
                                             />
                                        </Col>

                                        <Col md="2">
                                            <Label>To</Label>
                                            <input type="date"
                                             name="to_date" 
                                             id="to_date"
                                             value={searchData?.to_date}
                                             min={searchData?.from_date}
                                             onChange={handleDate}
                                             className="form-control" 
                                             />
                                        </Col>

                                        <Col md="2">
                                            <Label>Cargo Type</Label>
                                            <Select
                                                name='cargo_Type'
                                                placeholder='Select'
                                                value={selectedCargoType}
                                                onChange={(selected) => {
                                                    handleSelectChange(selected, "cargo_Type");
                                                }}
                                                options={cargoTypeOptions}
                                                classNamePrefix="select2-selection"
                                                // menuPosition="fixed"
                                            />
                                        </Col>

                                        <Col md="2">
                                            <Label>Status</Label>
                                            <Select
                                                name='status'
                                                placeholder='Status'
                                                options={[
                                                    { label: "Booked", value: 1 },
                                                    { label: "Dispatched", value: 2 },
                                                    { label: "Incustom", value: 3 },
                                                    { label: "Out for delivery", value: 4 },
                                                    { label: "Delivered", value: 5 },
                                                ]}
                                                classNamePrefix="select2-selection"
                                                // menuPosition="fixed"
                                            />
                                        </Col>


                                        <Col md="2">
                                            <Label>Branch</Label>
                                            <Select
                                                name='branch'
                                                value={selectedBranch}
                                                onChange={(selected) => {
                                                    handleSelectChange(selected, "branch");
                                                }}
                                                options={branchOptions}
                                                classNamePrefix="select2-selection"
                                                // menuPosition="fixed"
                                            />
                                        </Col>

                                        <Col md="2" style={{ paddingTop: "27px" }}>
                                            <Button color="primary" type="button" style={{ marginRight: "10px" }}>
                                                Filter
                                            </Button>

                                            <Button color="danger" type="reset" onClick={() => reset()}>
                                                Reset
                                            </Button>

                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>

                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <MDBDataTable
                                        responsive
                                        id="stockreportTable"
                                        bordered
                                        data={data}
                                        entries={20} />
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
export default StockReport;