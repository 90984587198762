import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { Paper, Box, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import Stack from "@mui/material/Stack";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import Select from "react-select";
import { apiError } from "../../../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";

import "./StaffDetails.scss";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import Slide from "@mui/material/Slide";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import axios from "axios";
import accessToken from "../../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import {
  formatMoney,
  getLocalbody,
  getRouteName,
  getDate,
  getFirstday,
} from "../../../../helpers/globalFunctions";
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import defaultProfile from "../../../../assets/images/person-icon.png";
import usericon from "../../../../assets/icons/user.jpg";
import road from "../../../../assets/images/Icons/book4.svg";
import transaction from "../../../../assets/images/Icons/transaction.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StaffDetails = (props) => {
  // const { userId } = props.match.params;
  const [details, setDetails] = useState({});
  const [visitLogData, setVisitLogData] = useState([]);
  const [receiptData, setReceiptData] = useState([]);
  const [accBalanceData, setAccBalanceData] = useState([]);
  const [incentiveData, setIncentiveData] = useState([]);

  const [routeName, setRouteName] = useState(getRouteName(props));
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [userIdTobeUpdated, setUserIdToBeUpdated] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [passwordObject, setPasswordObject] = useState({
    password: "",
    confirmPassword: "",
  });
  const [popupData, setPopupData] = useState({});
  const [wasteItemDetails, setWasteItemDetails] = useState([]);
  const [popupView, setPopupView] = useState(false);
  const [agentNumber, setAgentNumber] = useState("");
  // const [visitlogSearchData, setVisitlogSearchData] = useState({
  //   from_date: getFirstday(new Date()),
  //   to_date: getDate(new Date()),
  // });
  // const [receiptSearchData, setReceiptSearchData] = useState({
  //   from_date: getFirstday(new Date()),
  //   to_date: getDate(new Date()),
  // });
  // const [transactionlogSearchData, setTransactionlogSearchData] = useState({
  //   from_date: getFirstday(new Date()),
  //   to_date: getDate(new Date()),
  // });
  // const [chartId, setChartId] = useState("");

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  useEffect(() => {
    handleTableData();
      
    // if (userId) {
      // handleTableData(userId);
      // fetch_visitlog(userId);
      // fetch_all_receipt(userId);
      // fetch_user_details();
      // fetch_incentive(userId);
    // }
  }, []);
  const fetch_user_details = () => {
    let userid = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      userid = user_obj._id;
    }
    let Userdata = {
      user_id: userid,
    };
    axios
      .post(`${API_URL}api/staff/user_profile`, Userdata, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.result;
        if (result && result.length > 0) {
          setAgentNumber(result[0].mobile);
        }
      });
  };
  function handleTableData(id) {
    axios
      .get(`${API_URL}user/single?user_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        result &&
          result.forEach((element) => {
            element.company &&
              element.company.forEach((el) => {
                element.company_name = el?.company_name;
              });
            element.ward_name = "";
            element.ward &&
              element.ward.forEach((el) => {
                if (element.ward_name === "") element.ward_name = el?.ward_name;
                else
                  element.ward_name = element.ward_name + "," + el?.ward_name;
              });
            // element.user_group &&
            //   element.user_group.forEach((el) => {
            //     element.group_name = el?.group_name;
            //   });
            element.group_name = "";
            element.user_group &&
              element.user_group.forEach((el) => {
                if (element.group_name === "")
                  element.group_name = el?.group_name;
                else
                  element.group_name =
                    element.group_name + "," + el?.group_name;
              });
            element.district &&
              element.district.forEach((el) => {
                element.district_name = el?.district_name;
              });
            element.localbody_name = "";
            element.local_body &&
              element.local_body.forEach((el) => {
                if (element.localbody_name === "")
                  element.localbody_name = el?.localbody_name;
                else
                  element.localbody_name =
                    element.localbody_name + "," + el?.localbody_name;
              });
            if (element?.lastName)
              element.User_Name = element.firstName + " " + element.lastName;
            else element.User_Name = element.firstName;
            let user_privilege = element.privilage || {};
            element.privilege_name = user_privilege?.privilege_name;
            element.company &&
              element.company.map((company) => {
                element.companyName = company.company_name;
              });
            element.userAddedDate = moment(element.date).format("DD-MM-YYYY");
            if (element?.user_joining_date) {
              element.joiningDate = moment(element?.user_joining_date).format(
                "DD-MM-YYYY"
              );
            }
            if (element?.user_dob)
              element.Dob = moment(element?.user_dob).format("DD-MM-YYYY");

            // setChartId(element?.user_chart_id);
            setDetails(element);
            fetch_account_balance(currentLocalbody, element?.user_chart_id);
          });
      });
  }
  const fetch_visitlog = (id) => {
    axios
      .get(`${API_URL}addressbook/profile/visitlog?user_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            if (item?.waste_clt_delete_status === 1) {
              item.id = <span className="text-danger">{index + 1}</span>;
              item.time = (
                <span className="text-danger">
                  {moment(item.waste_clt_time, "HHmmss").format("hh:mm a")}
                </span>
              );
              item.amount = (
                <span className="text-danger">
                  {formatMoney(item?.waste_clt_total_amount)}
                </span>
              );
              item.collect_status = (
                <span className="text-danger">{item?.collect_status}</span>
              );
              item.customer_name = (
                <span className="text-danger">{item?.customer_name}</span>
              );
              item.waste_clt_unique_id = (
                <span className="text-danger">{item?.waste_clt_unique_id}</span>
              );
            } else {
              item.id = index + 1;
              item.time = moment(item.waste_clt_time, "HHmmss").format(
                "hh:mm a"
              );
              item.amount = formatMoney(item?.waste_clt_total_amount);
            }

            item.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "1em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.1em",
                  }}
                  onClick={() => {
                    handleClickOpenForm(item);
                  }}
                ></i>
              </div>
            );
          });
        setVisitLogData(result);
      });
  };
  const handleClickOpenForm = (row) => {
    let data = {
      collection_id: row._id,
    };
    axios
      .post(`${API_URL}waste/helper_collection_view`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item) => {
            if (item?.staff_lastname)
              item.staff = item.staff_firstname + " " + item.staff_lastname;
            else item.staff = item.staff_firstname;
            item.time = moment(item.waste_clt_time, "HHmmss").format("hh:mm a");

            let itemInfo = item.items_info;
            itemInfo &&
              itemInfo.map((item, index) => {
                item.id = index + 1;
              });
            setWasteItemDetails(itemInfo);
            setPopupData(item);
          });

        setPopupView(true);
      });
  };
  const fetch_all_receipt = (id) => {
    axios
      .get(`${API_URL}addressbook/profile/receiptstatement?user_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let receiptData = res.data.data;
        receiptData &&
          receiptData.map((item, index) => {
            if (item?.receipt_deleted_status === 1) {
              item.id = <span className="text-danger">{index + 1}</span>;
              item.amount = (
                <span className="text-danger">
                  {formatMoney(item?.reciept_amount)}
                </span>
              );
              item.dueAmount = (
                <span className="text-danger">
                  {formatMoney(item?.receipt_due_amt)}
                </span>
              );
              item.receipt_date = (
                <span className="text-danger">{item?.receipt_date}</span>
              );
              item.receipt_no = (
                <span className="text-danger">{item?.receipt_no}</span>
              );
            } else {
              item.id = index + 1;
              item.amount = formatMoney(item?.reciept_amount);
              item.dueAmount = formatMoney(item?.receipt_due_amt);
            }
          });
        setReceiptData(receiptData);
      });
  };
  const fetch_account_balance = (localbodyId, chartId) => {
    axios
      .get(
        `${API_URL}transaction_log?localbody_id=` +
          localbodyId +
          "&acc_chart_id=" +
          chartId,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.date = moment(item.trns_log_date).format("DD-MM-YYYY");
            if (item.transaction_log_type == 0) {
              item.n_Credit = formatMoney(item?.transaction_log_amount);
            } else item.n_Debit = formatMoney(item?.transaction_log_amount);
            item.amount_column = parseFloat(
              item.transaction_log_fundsource_crntamt
            );
            if (item?.amount_column)
              item.balance = item?.amount_column.toFixed(2);
            else item.balance = "0.00";
          });
        setAccBalanceData(result);
      });
  };
  const fetch_incentive = (id) => {
    axios
      .get(
        `${API_URL}incentive/staff_incentive/`+id,
        {
          headers: {
            "x-access-token": accessToken,
          },
        }
      )
      .then((res) => {
        let result = res.data.data;
        result &&
          result.map((item, index) => {
            item.id = index + 1;
            item.staff_inc_amount=item.staff_inc_amount.toFixed(2);
            if(item.incentive_type==1)
            {
              item.incentive_type="Daily";
            }
            else
            {
              item.incentive_type="Monthly";
            }
          });
        setIncentiveData(result);
      });
  };
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "waste_clt_date",
        sort: "asc",
        width: 200,
      },
      {
        label: "ID",
        field: "waste_clt_unique_id",
        sort: "asc",
        width: 200,
      },
      {
        label: "Name",
        field: "customer_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "collect_status",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: visitLogData,
  };
  const receiptTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "receipt_date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Receipt No",
        field: "receipt_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Due Amount",
        field: "dueAmount",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Staff",
      //   field: "staff",
      //   sort: "asc",
      //   width: 200,
      // },
    ],
    rows: receiptData,
  };
  const incentiveTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "No",
      //   field: "incentive_no",
      //   sort: "asc",
      //   width: 400,
      // },
      {
        label: "From",
        field: "incentive_date_from",
        sort: "asc",
        width: 200,
      },
      {
        label: "To",
        field: "incentive_date_to",
        sort: "asc",
        width: 200,
      },
      {
        label: "Type",
        field: "incentive_type",
        sort: "asc",
        width: 200,
      },
      {
        label: "Count",
        field: "total_visit_count",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "staff_inc_amount",
        sort: "asc",
        width: 200,
      },
      {
        label: "Incentive",
        field: "incentive_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Group",
        field: "group_name",
        sort: "asc",
        width: 200,
      },
    ],
    rows: incentiveData,
  };
  const accBalanceTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Type",
        field: "acc_type_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Account",
        field: "acc_chart_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Ref.ID",
        field: "log_unique_id",
        sort: "asc",
        width: 200,
      },
      {
        label: "Debit",
        field: "n_Debit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Credit",
        field: "n_Credit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Balance",
        field: "balance",
        sort: "asc",
        width: 200,
      },
    ],
    rows: accBalanceData,
  };
  const wasteCollectingItemDetails = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Item Name",
        field: "waste_clt_item_name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Price",
        field: "waste_clt_item_price",
        sort: "asc",
        width: 150,
      },
      {
        label: "Bags",
        field: "waste_clt_item_no_bag",
        sort: "asc",
        width: 150,
      },
      {
        label: "Weight",
        field: "waste_clt_item_no_kg",
        sort: "asc",
        width: 150,
      },
      {
        label: "Total",
        field: "waste_clt_item_amt",
        sort: "asc",
        width: 150,
      },
    ],
    rows: wasteItemDetails,
  };

  function createData1(si, date, receiptno, amount, dueamount, staff) {
    return { si, date, receiptno, amount, dueamount, staff };
  }

  const rows2 = [
    createData1(1, "24 - 11 - 2021", "RE2111000004", "50/-", "50/-", "Prabija"),
    createData1(
      2,
      "24 - 11 - 2021",
      "RE2111000005",
      "400/-",
      "-350/-",
      "Prabija"
    ),
  ];

  function createData3(
    si,
    date,
    time,
    referenceNo,
    customerId,
    customerName,
    amount,
    comment,
    due,
    staff
  ) {
    return {
      si,
      date,
      time,
      referenceNo,
      customerId,
      customerName,
      amount,
      comment,
      due,
      staff,
    };
  }

  const rows3 = [
    createData3(
      1,
      "26-11-2020",
      "11:11:24",
      123,
      123,
      "admin",
      700,
      "abc",
      100,
      "srv"
    ),
  ];

  const [tab3, setTab3] = React.useState("1");

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue);
  };

  const [date, setDate] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };

  function createData4(
    si,
    date,
    time,
    customerName,
    type,
    invoiceNo,
    debit,
    credit,
    balance
  ) {
    return {
      si,
      date,
      time,
      customerName,
      type,
      invoiceNo,
      debit,
      credit,
      balance,
    };
  }

  const rows4 = [
    createData4(
      1,
      "26-11-2020",
      "11:11:24",
      "admin",
      "Invoice",
      "SRV123",
      700,
      100,
      100
    ),
  ];
  let closeModal = () => {
    setShowModal(false);
    setUserIdToBeUpdated(null);
    setPasswordObject({
      password: "",
      confirmPassword: "",
    });
  };
  const handleValidSubmitPassword = () => {
    if (passwordObject.password == passwordObject.confirmPassword) {
      let item = {
        user_id: userIdTobeUpdated,
        password: passwordObject?.password,
      };
      axios
        .post(`${API_URL}user/reset_password`, item, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success(res.data.message);
            setPasswordObject({
              password: "",
              confirmPassword: "",
            });
            setUserIdToBeUpdated(null);
            closeModal();
          } else {
            toastr.error(res.data.message);
            return;
          }
        });
    } else {
      toastr.error("Passwords are not matching");
    }
  };
  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }
  const userCall = () => {
    let data = {
      customer_no: 0 + details?.mobile,
      agent_no: 0 + agentNumber,
      localbody: currentLocalbody,
    };
    axios
      .post(`${API_URL}api/staff/customer_call`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((response) => {
        let result = response.data;
        if (result && result.success === true) {
          toastr.success("Call Send successfully");
        } else {
          toastr.error(result.message);
        }
      });
  };
  // const handleVisitlogDate = (e) => {
  //   var date1 = $("#from_date").val();
  //   var date2 = $("#to_date").val();

  //   let toDate = moment(date1);
  //   let fromDate = moment(date2);
  //   let result = fromDate.diff(toDate, "days");

  //   if (result + 1 <= 0) {
  //     setVisitlogSearchData({
  //       ...visitlogSearchData,
  //       [e.target.name]: e.target.value,
  //       ["to_date"]: "",
  //     });
  //     date2 = "";
  //   } else {
  //     setVisitlogSearchData({
  //       ...visitlogSearchData,
  //       [e.target.name]: e.target.value,
  //     });
  //   }
  // };
  // const resetVisitlogFilter = () => {
  //   setVisitlogSearchData({
  //     from_date: getFirstday(new Date()),
  //     to_date: getDate(new Date()),
  //   });
  // };
  // const handleReceiptDate = (e) => {
  //   var date1 = $("#from_date").val();
  //   var date2 = $("#to_date").val();

  //   let toDate = moment(date1);
  //   let fromDate = moment(date2);
  //   let result = fromDate.diff(toDate, "days");

  //   if (result + 1 <= 0) {
  //     setReceiptSearchData({
  //       ...receiptSearchData,
  //       [e.target.name]: e.target.value,
  //       ["to_date"]: "",
  //     });
  //     date2 = "";
  //   } else {
  //     setReceiptSearchData({
  //       ...receiptSearchData,
  //       [e.target.name]: e.target.value,
  //     });
  //   }
  // };
  // const resetReceiptFilter = () => {
  //   setReceiptSearchData({
  //     from_date: getFirstday(new Date()),
  //     to_date: getDate(new Date()),
  //   });
  // };

  // const handleTransactionlogDate = (e) => {
  //   var date1 = $("#from_date").val();
  //   var date2 = $("#to_date").val();

  //   let toDate = moment(date1);
  //   let fromDate = moment(date2);
  //   let result = fromDate.diff(toDate, "days");

  //   if (result + 1 <= 0) {
  //     setTransactionlogSearchData({
  //       ...transactionlogSearchData,
  //       [e.target.name]: e.target.value,
  //       ["to_date"]: "",
  //     });
  //     date2 = "";
  //   } else {
  //     setTransactionlogSearchData({
  //       ...transactionlogSearchData,
  //       [e.target.name]: e.target.value,
  //     });
  //   }
  // };
  // const resetTransactionlogFilter = () => {
  //   setTransactionlogSearchData({
  //     from_date: getFirstday(new Date()),
  //     to_date: getDate(new Date()),
  //   });
  // };
  const walletTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 100,
      },
      {
        label: "Account",
        field: "acc",
        sort: "asc",
        width: 100,
      },
      {
        label: "Ref.ID",
        field: "ref",
        sort: "asc",
        width: 100,
      },
      {
        label: "Credit",
        field: "credit",
        sort: "asc",
        width: 100,
      },
      {
        label: "Debit",
        field: "debit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Balance",
        field: "wlog_balance",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Remarks",
      //   field: "remarks",
      //   sort: "asc",
      //   width: 150,
      // },
      // {
      //   label: "Message",
      //   field: "wlog_message",
      //   sort: "asc",
      //   width: 200,
      // },
    ],
    rows: [
      {
        id: "1",
        date: "30-11-2022",
        time: "09:00am",
        credit: "",
        debit:"1000",
        wlog_balance:"5000",
        remarks:"Rs 1000 debited from the account for booking 311 0015",
        type:"Expense",
        acc:"AdminCargo",
        ref:"ALF301122005"      
      },
      {
        id: "2",
        date: "22-11-2022",
        time: "11:00am",
        credit: "2000",
        debit:"",
        wlog_balance:"6000",
        remarks:"Rs 1000 credited to the account for booking 311 0019"   ,
        type:"Income",
        acc:"AdminCargo",
        ref:"ALF221122004"
      },

      {
        id: "3",
        date: "18-11-2022",
        time: "10:00am",
        credit: "",
        debit:"1000",
        wlog_balance:"8000",
        remarks:"Rs 1000 debited from the account for booking 311 0011",
        type:"Expense",
        acc:"AdminCargo",
        ref:"ALF181122003"
      },
      {
        id: "4",
        date: "15-11-2022",
        time: "04:00pm",
        credit: "",
        debit:"1000",
        wlog_balance:"9000",
        remarks:"Rs 1000 debited from the account for booking 311 0019",
        type:"Expense",
        acc:"AdminCargo",
        ref:"ALF151122002" 
      },
      {
        id: "5",
        date: "13-11-2022",
        time: "02:00pm",
        credit: "",
        debit:"1000",
        wlog_balance:"10000",
        remarks:"Rs 1000 debited from the account for booking 311 0017",
        type:"Expense",
        acc:"AdminCargo",
        ref:"ALF131122001"  
      },
    ],
  };
  const pickupData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "OrderID",
        field: "booking_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Location",
        field: "location",
        sort: "asc",
        width: 200,
      },
      {
        label: "Latitude",
        field: "latitude",
        sort: "asc",
        width: 200,
      },
      {
        label: "Longitude",
        field: "longitude",
        sort: "asc",
        width: 200,
      },
      {
        label: "Boxes",
        field: "box",
        sort: "asc",
        width: 200,
      },
      
      {
        label: "Weight",
        field: "weight",
        sort: "asc",
        width: 200,
      },
      
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Action",
      //   field: "action",
      //   sort: "asc",
      //   width: 200,
      // },
    ],
    rows: [
      {
        id: "1",
        date: "30-11-2022",
        booking_no: "311 0015",
        weight:"10.00",
        status:<div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="success"
          size="sm" 
          type="button"
          className="btn btn-sm" 
          fontSize= "0.8em"
        >
          PICK UP
        </Button>
      </div>  ,
        box:"2", 
        location:"Ibra",
        longitude:"58.527332",
        latitude:"22.726467",
        action:(
          <div style={{ display: "flex", justifyContent: "center" }}>
            <i className="uil-edit-alt"
              style={{
                fontSize: "1rem",
                cursor: "pointer",
                marginLeft: "0.4rem",
                marginRight: "0.4rem"
              }}
            ></i>
            <i className="uil-trash-alt"
              style={{ fontSize: "1rem", cursor: "pointer" }}        
            ></i>
          </div>)

      },
      {
        id: "2",
        date: "29-11-2022",
        booking_no: "311 0005",
        
        weight:"12.00",
        consignee:"SHAHIM VK",
        status:<div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="success"
          size="sm"
          fontSize= "0.8em"
        >
          PICK UP
        </Button>
      </div>  ,
       box:"3",  
       location:"Khasab",
       latitude:"26.164438",
       longitude:"56.242641",
       action:(
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i className="uil-edit-alt"
            style={{
              fontSize: "1rem",
              cursor: "pointer",
              marginLeft: "0.4rem",
              marginRight: "0.4rem"
            }}
          ></i>
          <i className="uil-trash-alt"
            style={{ fontSize: "1rem", cursor: "pointer" }}        
          ></i>
        </div>)    
      },

      {
        id: "3",
        date: "25-11-2022",
        booking_no: "310 0000",
  
        weight:"15.00",
        consignee:"M.M.AJOY",
        status:<div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="danger"
          size="sm"
          // fontSize= "0.8em !important"
        >
          DROPOFF
        </Button>
      </div> ,  
       box:"5", 
       location:"Saham",
       latitude:"24.172222"  ,     
       longitude:"56.888611",
       action:(
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i className="uil-edit-alt"
            style={{
              fontSize: "1rem",
              cursor: "pointer",
              marginLeft: "0.4rem",
              marginRight: "0.4rem"
            }}
          ></i>
          <i className="uil-trash-alt"
            style={{ fontSize: "1rem", cursor: "pointer" }}        
          ></i>
        </div>)
      },
      {
        id: "4",
        date: "20-11-2022",
        booking_no: "312 0010",
        
        weight:"10.00",
        consignee:"GANGADHAR",
        status:<div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="success"
          size="sm"
          fontSize= "0.8em"
        >
          PICK UP
        </Button>
      </div>  ,
       box:"1",
       location:"Ibri",
       latitude:"23.237146"  ,     
       longitude:"56.504295",
       action:(
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i className="uil-edit-alt"
            style={{
              fontSize: "1rem",
              cursor: "pointer",
              marginLeft: "0.4rem",
              marginRight: "0.4rem"
            }}
          ></i>
          <i className="uil-trash-alt"
            style={{ fontSize: "1rem", cursor: "pointer" }}        
          ></i>
        </div>)     
      },
      {
        id: "5",
        date: "10-11-2022",
        booking_no: "319 0015",
        
        weight:"10.00",
        consignee:"SANJAY",
        status:<div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          color="success"
          size="sm"
          fontSize= "0.8em"
        >
          PICK UP
        </Button>
      </div> ,
       box:"4",    
       location:"Muscat",
       latitude:"23.614328"  ,     
       longitude:"58.545284", 
       action:(
        <div style={{ display: "flex", justifyContent: "center" }}>
          <i className="uil-edit-alt"
            style={{
              fontSize: "1rem",
              cursor: "pointer",
              marginLeft: "0.4rem",
              marginRight: "0.4rem"
            }}
          ></i>
          <i className="uil-trash-alt"
            style={{ fontSize: "1rem", cursor: "pointer" }}        
          ></i>
        </div>)  
      },
    ],
  };

  return (
    <React.Fragment>
      <Modal
        show={showModal}
        toggle={() => {
          closeModal();
        }}
        centered={true}
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Confirmation</h5>
          <button
            type="button"
            onClick={() => {
              closeModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={(e, v) => {
            handleValidSubmitPassword(e, v);
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Password</Label>
                  <AvField
                    name="password"
                    placeholder="Password"
                    type="password"
                    errorMessage=" Please provide a valid password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.password}
                    onChange={handleChangePassword}
                  />
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Confirm Password</Label>
                  <AvField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type="password"
                    errorMessage="Please confirm the password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.confirmPassword}
                    onChange={handleChangePassword}
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-dark"
              style={{ marginRight: "1rem" }}
              onClick={closeModal}
            >
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Confirm
            </button>
          </div>
        </AvForm>
      </Modal>
      <Modal
        show={popupView}
        toggle={() => setPopupView(!popupView)}
        size="lg"
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Helper Collection Details </h5>
          <button
            type="button"
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => {}}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px" }}>Date :</td>
                <td>{popupData?.waste_clt_date}</td>
                <td>Time :</td>
                <td style={{ textAlign: "left" }}>{popupData?.time}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Customer ID :</td>
                <td>{popupData?.cust_id}</td>
                <td>Name :</td>
                <td style={{ textAlign: "left" }}>{popupData?.cust_name}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Group :</td>
                <td>{popupData?.group_name}</td>
                <td>Ward :</td>
                <td style={{ textAlign: "left" }}>{popupData?.ward_name}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Amount :</td>
                <td>{popupData?.waste_clt_total_amount}</td>
                <td>Staff :</td>
                <td style={{ textAlign: "left" }}>{popupData?.staff}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>Status :</td>
                <td>{popupData?.status_name}</td>
                <td></td>
                <td style={{ textAlign: "left" }}>{}</td>
              </tr>
            </table>
            {wasteItemDetails && wasteItemDetails.length > 0 ? (
              <Row className="mt-3 mb-2">
                <h6
                  className="modal-title mt-0"
                  style={{ textAlign: "center" }}
                >
                  Helper Collection Item Details
                </h6>
              </Row>
            ) : null}
            {wasteItemDetails && wasteItemDetails.length > 0 ? (
              <Row>
                <MDBDataTable
                  id="wasteCollectionItemDetailId"
                  responsive
                  bordered
                  data={wasteCollectingItemDetails}
                  searching={false} 
                  info={false}
                  paging={false}
                  disableRetreatAfterSorting={true}
                  //entries={10}
                />
              </Row>
            ) : null}
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            breadcrumbItem="User Details"
            // {
            //   routeName === "user" || routeName === "profile"
            //     ? "User" + " " + "-" + " " + details?.userId
            //     : "Staff" + " " + "-" + " " + details?.userId
            // }
          />
          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{
                        // width: "fit-content",
                        // width: "351px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown">
                          <div
                            tabindex="-1"
                            role="menu"
                            aria-hidden="true"
                            className="dropdown-menu-end dropdown-menu"
                          >
                            <a
                              to="/"
                              tabindex="0"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Edit
                            </a>
                            <a
                              to="/"
                              tabindex="1"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Action
                            </a>
                            <a
                              to="/"
                              tabindex="2"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Remove
                            </a>
                          </div>
                          {/* <div className="clearfix"></div> */}
                          {/* <div>
                                <img
                                  alt
                                  className="avatar-lg rounded-circle img-thumbnail"
                                  src="/static/media/avatar-4.b23e41d9.jpg"
                                />
                              </div> */}
                        </div>
                        {/* Customer Details Tab start */}

                        {/* {details?.user_image ? ( */}
                          <Stack
                            direction="row"
                            spacing={2}
                            style={{ justifyContent: "center" }}
                          >
                            <Avatar
                              alt="Remy Sharp"
                              src={usericon}
                              // src={`${API_URL}uploads/user_images/${details?.user_image}`}
                              sx={{ width: 56, height: 56 }}
                              style={{
                                width: "6rem",
                                height: "6rem",
                                marginBottom: "auto",
                              }}
                            />
                          </Stack>
                        {/* ) : (
                          <Stack
                            direction="row"
                            spacing={2}
                            style={{ justifyContent: "center" }}
                          >
                            <Avatar
                              alt="Remy Sharp"
                              src={defaultProfile}
                              sx={{ width: 56, height: 56 }}
                              style={{
                                width: "6rem",
                                height: "6rem",
                                marginBottom: "auto",
                              }}
                            />
                          </Stack>
                        )} */}
                        <a
                          aria-haspopup="true"
                          className="text-body font-size-16 ddropdown-toggle"
                          aria-expanded="false"
                        >
                          {/* <OverlayTrigger
                              placement="top"
                              //delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip
                                  id="button-tooltip-2"
                                  style={{ position: "absolute", top: "-30px" }}
                                >
                                  Reset password
                                </Tooltip>
                              }
                            > */}
                          <i
                            className="fas fa-key"
                            style={{
                              // fontSize: "1em",
                              // cursor: "pointer",
                              // marginLeft: "0.2em",
                              // marginRight: "0.5em",
                              fontSize: "1em",
                              position: "relative",
                              float: "right",
                              bottom: "83px",
                            }}
                            // onClick={() => {
                            //   setShowModal(true);
                            //   setUserIdToBeUpdated(userId);
                            // }}
                          ></i>
                          {/* </OverlayTrigger> */}
                        </a>
                        <h5
                          className="mt-3 mb-1"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          admin
                          {/* {details?.User_Name} */}
                        </h5>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            {/* <CallIcon style={{ width: "10px", height: "10px" }}/> */}
                            <OverlayTrigger
                              placement="top"
                              //delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip
                                  id="button-tooltip-2"
                                  style={{ position: "absolute", top: "-30px" }}
                                >
                                  Call Now
                                </Tooltip>
                              }
                            >
                              <button
                                class="btn btn-success"
                                style={{
                                  borderRadius: "40px",
                                  marginRight: "10px",
                                  padding: "0",
                                  width: "28px",
                                }}
                                // hidden={!details.mobile}
                                // onClick={() => userCall()}
                              >
                                <i
                                  className="uil-phone-alt"
                                  style={{ fontSize: "15px" }}
                                />
                              </button>
                            </OverlayTrigger>
                          </div>
                          9497717717
                          {/* {details?.mobile} */}
                        </div>
                <p
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "15px",
                            marginBottom: "0",
                          }}
                        >
                          Admin
                          {/* {details?.privilege_name} */}
                        </p>

                        <div
                          className="mt-1 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {/* <button
                            type="button"
                            className="btn btn-light btn-sm"
                          >
                            <i className="uil uil-envelope-alt me-2"></i>
                            Message
                          </button> */}
                        </div>
                        <Divider />

                        <div className="mt-2">
                          <p className="mb-1">Name:</p>
                          <h5 className="font-size-14">
                          Admin Cargo
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Email:</p>

                          <h5 className="font-size-14">
                            <MailOutlineIcon
                              style={{ fontSize: "15px" }}
                            
                            />
                            &nbsp;
                            support@srvinfotech.in
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">Whatsapp No.:</p>
                          <h5 className="font-size-14">9496717717</h5>
                        </div>
                        <div className="mt-2" >
                          <p className="mb-1">Branch:</p>
                          <h5 className="font-size-14">
                          AL-FARHA CARGO MAIN OFFICE
                          </h5>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1"> UserID:</p>
                          <h5 className="font-size-14">
                          ALF001
                          </h5>
                        </div>
                        <div className="mt-2" >
                          <p className="mb-1"> CreatedAt:</p>
                          <h5 className="font-size-14">01-11-2022 11:25am</h5>
                        </div>
                        <div className="mt-2" >
                          <p className="mb-1">CreatedBy:</p>
                          <h5 className="font-size-14">Admin Srv</h5>
                        </div>
                        <div className="mt-2" >
                          <p className="mb-1">UpdatedAt:</p>
                          <h5 className="font-size-14">
                          09-11-2022 2:15pm
                          </h5>
                        </div>
                      
                        <div className="mt-2" >
                          <p className="mb-1">UpdatedBy:</p>
                          <h5 className="font-size-14">
                          Admin Srv
                          </h5>
                        </div>

                        <div className="mt-2">
                          <p className="mb-1">User Type:</p>
                          {details?.app_user === 1 ? (
                            <h5 className="font-size-14">{"App"}</h5>
                          ) : (
                            <h5 className="font-size-14">{"CRM"}</h5>
                          )}
                        </div>
                        
                        {/* second paper */}

                        {/* Customer Details Tab end */}
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",
                      }}
                    >
                      <Box sx={{ width: "100%"}}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                                   <Tab
                              icon={
                              <div className="float-end mt-3">
                              <img src={road}  height="25px"></img>
                              </div>
                              }
                                label="Pickup/Dropoff Log"
                                value="1"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                   paddingBottom:"25px"
                                }}
                              />
                              <Tab
                                 icon={
                                  <div className="float-end mt-3">
                                  <img src={transaction}  height="25px"></img>
                                  </div>
                                 }
                                label="Transaction Log"
                                value="2"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                  paddingBottom:"25px"
                                }}
                              />
                    
                            </TabList>
                          </Box>
                          
                          <TabPanel value="1">
                            <Row>
                              <Col className="col-12">
                                <Card>
                                  <CardBody>
                                   
                                  <MDBDataTable
                                      id="pickupId"
                                      responsive
                                      bordered
                                      data={pickupData}
                                    />
                                    {/* </Row> */}
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel value="2">
                            <Row>
                              <Col className="col-12">
                               
                              <Card>
                                  <CardBody>  
                               <MDBDataTable
                                      id="userWalletId"
                                      responsive
                                      bordered
                                      data={walletTableData}
                                    />
                                     </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>
                         
                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default StaffDetails;
// const mapStateToProps = (state) => {};

// export default withRouter(connect(mapStateToProps, { apiError })(StaffDetails));

// StaffDetails.propTypes = {
//   error: PropTypes.any,
//   users: PropTypes.array,
// };
