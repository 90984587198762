import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import {
    GET_STAFF_SALARY,
} from "./actionTypes";
import {
    getStaffSalarySuccess,
    getStaffSalaryFail,
} from "./actions";
import {
    getStaffSalary,
} from "../../helpers/backend_helper";

function* fetchStaffSalary({localbody_id: localbody_id,month:month,days:days,work_days:work_days}) {
    try {
        const response = yield call(getStaffSalary,localbody_id,month,days,work_days);
        response.data.map((item, index) => {
            item.id = index+1;
            var halfday = item.total_half/2;
            item.total_present = item.total_present+halfday;
            var holiday_total_halfday=item.holiday_halfday/2;
            item.holiday_total_present=item.holiday_present+holiday_total_halfday;
            item.grand_present = item.total_present+item.holiday_total_present;
            item.extra_days = item.holiday_total_present;
            item.total_absent = item.total_absent+halfday;
            
            var totalday = parseFloat(item.total_present)+parseFloat(item.total_absent);
            if(totalday<work_days)
            {
              var diffrence = work_days - totalday;
              item.total_absent =item.total_absent +diffrence;
            }
            
            
           // item.total_absent = item.total_absent+halfday;
           // console.log(days)
            if(item.salary_type=="Day")
            {
              var day_salary = item.salary;
              var gross_salary = work_days*day_salary;
            }
            else
            {
              var day_salary = item.salary/days;
              var gross_salary = days*day_salary;
            }
           
           
            if(parseFloat(item.total_absent)>=parseFloat(item.total_present))
            {
              item.calc_salary = (parseFloat(item.total_present)*day_salary);
              item.lop = (gross_salary-parseFloat(item.calc_salary)).toFixed(2);
            }
            else{

          
            if(item.total_present>=work_days)
            {
              //console.log(gross_salary)
              item.calc_salary = gross_salary;
              //item.lop = (day_salary*item.total_absent).toFixed(2);
              item.lop = (gross_salary-parseFloat(item.calc_salary)).toFixed(2);
            }
            else
            {
              item.lop = (day_salary*item.total_absent).toFixed(2);
              //item.calc_salary = (item.total_present*day_salary).toFixed(2);
              console.log(item.lop)
              item.calc_salary = gross_salary-item.lop;
            }
          }
            item.actual_extra_payment = (item.holiday_total_present * day_salary).toFixed(2);
            item.extra_payment = item.actual_extra_payment;
            // item.extraPayment = (
            //   <>
            //     <input
            //       name="extra_pay[]"
            //       value={item?.extra_payment}
            //       style={{ textAlign: "right" }}
            //       className="form-control"
            //       onChange={(e) =>
            //         handleExtraPayment(e, index)
            //       }
            //       type="text"
            //     />
            //   </>
            // );
            if(item.monthly_incentive_amount>0)
            {
              item.actual_incentive = item.monthly_incentive_amount.toFixed(2);
              item.incentive = item.actual_incentive;
            }
            else
            {
              item.actual_incentive = item.daily_incentive_amount.toFixed(2);
              item.incentive = item.actual_incentive;
            }
            // item.incentiveAmount= (
            //   <>
            //     <input
            //       value={item.incentive}
            //       style={{ textAlign: "right" }}
            //       className="form-control"
            //       type="text"
            //     />
            //   </>
            // )
            item.net_salary = (parseFloat(item.calc_salary) + parseFloat(item.extra_payment) + parseFloat(item.incentive));
            item.net_salary = parseFloat(item.net_salary);
            if(item.net_salary>=parseFloat(item.advance_balance))
            {
              item.advance_due = 0;
              item.to_pay = parseFloat(item.net_salary) -parseFloat(item.advance_balance);
            }
            else
            {
              item.to_pay = 0;
              item.advance_due = parseFloat(item.advance_balance) -parseFloat(item.net_salary)
            }
            // item.checkbox = (
            //   <>
            //     <input
            //       type="checkbox"
            //       id="tableCheckbox"
            //       style={{ cursor: "pointer" }}
            //     ></input>
            //   </>
            // );
            // item.attendance = (
            //   <>
            //   <Row>
            //     <Col xl="12">
            //       <Row>
            //         <table>
            //           <thead>
            //             <tr>
            //               {/* <td>LOP</td> */}
            //               <td style={tableStyle}>{item.grand_present}</td>
            //               <td style={tableStyle}>{item.total_absent}</td>
            //             </tr>
            //           </thead>
            //         </table>
            //       </Row>
            //     </Col>
            //   </Row>
            // </>
            // )
      
            item.salary_name= item.salary+"/"+item.salary_type;
            item.calc_salary=item.calc_salary.toFixed(2);
            item.to_pay=Math.round(item.to_pay);
            //console.log(item);
          });
        yield put(getStaffSalarySuccess(response));
    } catch (error) {
        yield put(getStaffSalaryFail(error));
    }
}
function* fetchStaffSalarySaga() {
    yield takeEvery(GET_STAFF_SALARY, fetchStaffSalary);
}

export default fetchStaffSalarySaga;