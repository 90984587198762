
//Customers
export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_FAIL = "GET_CUSTOMERS_FAIL";

export const GET_CUST_VISITLOG = "GET_CUST_VISITLOG";
export const GET_CUST_VISITLOG_SUCCESS = "GET_CUST_VISITLOG_SUCCESS";
export const GET_CUST_VISITLOG_FAIL = "GET_CUST_VISITLOG_FAIL";

export const GET_CUST_RECEIPTS = "GET_CUST_RECEIPTS";
export const GET_CUST_RECEIPTS_SUCCESS = "GET_CUST_RECEIPTS_SUCCESS";
export const GET_CUST_RECEIPTS_FAIL = "GET_CUST_RECEIPTS_FAIL";

export const GET_CUST_INVOICE = "GET_CUST_INVOICE";
export const GET_CUST_INVOICE_SUCCESS = "GET_CUST_INVOICE_SUCCESS";
export const GET_CUST_INVOICE_FAIL = "GET_CUST_INVOICE_FAIL";


export const GET_CUST_STATEMENT = "GET_CUST_STATEMENT";
export const GET_CUST_STATEMENT_SUCCESS = "GET_CUST_STATEMENT_SUCCESS";
export const GET_CUST_STATEMENT_FAIL = "GET_CUST_STATEMENT_FAIL";

export const ADD_CUSTOMERS = "ADD_CUSTOMERS";
export const ADD_CUSTOMERS_SUCCESS = "ADD_CUSTOMERS_SUCCESS";
export const ADD_CUSTOMERS_FAIL = "ADD_CUSTOMERS_FAIL";

export const UPDATE_CUSTOMERS = "UPDATE_CUSTOMERS";
export const UPDATE_CUSTOMERS_SUCCESS = "UPDATE_CUSTOMERS_SUCCESS";
export const UPDATE_CUSTOMERS_FAIL = "UPDATE_CUSTOMERS_FAIL";

export const DELETE_CUSTOMERS = "DELETE_CUSTOMERS";
export const DELETE_CUSTOMERS_SUCCESS = "DELETE_CUSTOMERS_SUCCESS";
export const DELETE_CUSTOMERS_FAIL = "DELETE_CUSTOMERS_FAIL";

export const GET_CUSTOMER_TYPE = "GET_CUSTOMER_TYPE";
export const GET_CUSTOMER_TYPE_SUCCESS = "GET_CUSTOMER_TYPE_SUCCESS";
export const GET_CUSTOMER_TYPE_FAIL = "GET_CUSTOMER_TYPE_FAIL";

