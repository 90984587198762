import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"
import { Row, Col, Card, CardBody, Label, Button, BreadcrumbItem, CardHeader } from "reactstrap";
import Select from 'react-select';
import { MDBDataTable } from "mdbreact";
import CountUp from 'react-countup';
import "./style.scss";
import bookedIcon from "../../../assets/images/Icons/book1.svg";
import dispatchedIcon from "../../../assets/images/Icons/book2.svg";
import customIcon from "../../../assets/images/Icons/book3.svg";
import outDeliveryIcon from "../../../assets/images/Icons/book4.svg";
import deliveryIcon from "../../../assets/images/Icons/book5.svg";
import flag1 from "../../../assets/images/flags/pakistan.svg";
import flag2 from "../../../assets/images/flags/india.svg";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";


const ManifestExport = (props) => {

    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [masterObject, setmasterObject] = useState({});

    const [shipmentOptions, setShipmentOptions] = useState(null);
    const [selectedShipment, setSelectedShipment] = useState([]);


    useEffect(() => {
        fetch_all_shipment();
    }, []);


    function fetch_all_shipment() {
        axios
            .get(`${API_URL}shipment/all-shipmentid`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                var ship_data =
                    res.data.data &&
                    res.data.data.map((el, indx) => {

                        return {
                            label: el.shipment_id,
                            value: el._id
                        }
                    })

                setShipmentOptions([{ options: ship_data }])
            })
    }


    function handleSelectChange(selected, name) {
        switch (name) {
            case "shipment_id":
                setSelectedShipment(selected);
                setmasterObject({
                    ...masterObject,
                    shipment_id: selected.value,
                });
                break;

            default:
                break;
        }
    }

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Order No.",
                field: "no",
                sort: "asc",
                width: 270,
            },
            {
                label: "No.of Boxes",
                field: "boxes",
                sort: "asc",
                width: 200,
            },
            {
                label: "Total Weight",
                field: "weight",
                sort: "asc",
                width: 100,
            },
            {
                label: "State/Province",
                field: "state",
                sort: "asc",
                width: 100,
            },
        ],
        rows: [
            {
                id: "1",
                no: "113 0010",
                boxes: "1",
                weight: "48.8",
                state: "CHITTAGONG DIVISION",
            },
            {
                id: "2",
                no: "113 0011",
                boxes: "1",
                weight: "29.6",
                state: "CHANDPUR",
            },
            {
                id: "3",
                no: "114 0022",
                boxes: "1",
                weight: "48.5",
                state: "DHAKA",
            },
            {
                id: "4",
                no: "113 0010",
                boxes: "1",
                weight: "48.8",
                state: "CHITTAGONG DIVISION",
            },
            {
                id: "5",
                no: "113 0011",
                boxes: "1",
                weight: "29.6",
                state: "CHANDPUR",
            },
            {
                id: "6",
                no: "114 0022",
                boxes: "1",
                weight: "48.5",
                state: "DHAKA",
            },
            {
                id: "7",
                no: "113 0010",
                boxes: "1",
                weight: "48.8",
                state: "CHITTAGONG DIVISION",
            },
            {
                id: "8",
                no: "113 0011",
                boxes: "1",
                weight: "29.6",
                state: "CHANDPUR",
            },
            {
                id: "9",
                no: "114 0022",
                boxes: "1",
                weight: "48.5",
                state: "DHAKA",
            },
            {
                id: "10",
                no: "114 0022",
                boxes: "1",
                weight: "48.5",
                state: "DHAKA",
            },
        ],
    };
    return (
        <React.Fragment>
            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col className="col-12">
                            <div className="page-title-box d-flex align-items-center justify-content-between">
                                <h4 className="mb-0">Manifesto Export</h4>

                                <div className="page-title-right">
                                    <ol className="breadcrumb m-0">
                                        <BreadcrumbItem>
                                            <Link to="/dashboard">Home</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            <Link to="/newshipment">Shipment</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem active>
                                            <Link to="#">Manifesto Export</Link>
                                        </BreadcrumbItem>
                                    </ol>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <Row>

                                        <Col md="2">
                                            <div>
                                                <Select
                                                    name='shipment_id'
                                                    value={selectedShipment || shipmentOptions[0]}
                                                    placeholder='Shipment'
                                                    options={shipmentOptions}
                                                    classNamePrefix="select2-selection"
                                                    validate={{ required: { value: true } }}
                                                    onChange={(selected) => {
                                                        handleSelectChange(selected, "shipment_id")
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col md="1">
                                            <Button color="danger" type="reset">
                                                Reset
                                            </Button>
                                        </Col>
                                        <Col md="2">
                                            <Button color="success" type="button">
                                                <i className="fas fa-file-excel"></i> Export Manifesto
                                            </Button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col md="12">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col md={2} xl={2}>
                                            <Card>
                                                <CardBody style={{ padding: "10px 10px" }}>
                                                    <div className="float-end mt-2">
                                                        <i className="fas fa-box-open" style={{ fontSize: "26px", color: "white", textShadow: "-1px 0 #f97222fa, 0 1px #f97222fa, 1px 0 #f97222fa, 0 -1px #f97222fa" }}></i>
                                                    </div>
                                                    <div>
                                                        <h4 className="mb-1 mt-1"><span><CountUp end="10" separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                                        <p className="text-muted mb-0">Total Boxes</p>
                                                    </div>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={2} xl={2}>
                                            <Card>
                                                <CardBody style={{ padding: "10px 10px" }}>
                                                    <div className="float-end mt-2">
                                                        <img src={bookedIcon} height="30px"></img>
                                                    </div>
                                                    <div>
                                                        <h4 className="mb-1 mt-1"><span><CountUp end="5" separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                                        <p className="text-muted mb-0">Booked</p>
                                                    </div>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={2} xl={2}>
                                            <Card>
                                                <CardBody style={{ padding: "10px 10px" }}>
                                                    <div className="float-end mt-2">
                                                        <img src={dispatchedIcon} height="30px"></img>
                                                    </div>
                                                    <div>
                                                        <h4 className="mb-1 mt-1"><span><CountUp end="1" separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                                        <p className="text-muted mb-0">Dispatched</p>
                                                    </div>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={2} xl={2}>
                                            <Card>
                                                <CardBody style={{ padding: "10px 10px" }}>
                                                    <div className="float-end mt-2">
                                                        <img src={customIcon} height="30px"></img>
                                                    </div>
                                                    <div>
                                                        <h4 className="mb-1 mt-1"><span><CountUp end="2" separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                                        <p className="text-muted mb-0">Incustom</p>
                                                    </div>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={2} xl={2}>
                                            <Card>
                                                <CardBody style={{ padding: "10px 10px" }}>
                                                    <div className="float-end mt-2">
                                                        <img src={outDeliveryIcon} height="30px"></img>
                                                    </div>
                                                    <div>
                                                        <h4 className="mb-1 mt-1"><span><CountUp end="1" separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                                        <p className="text-muted mb-0">Out for delivery</p>
                                                    </div>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md={2} xl={2}>
                                            <Card>
                                                <CardBody style={{ padding: "10px 10px" }}>
                                                    <div className="float-end mt-2">
                                                        <img src={deliveryIcon} height="30px"></img>
                                                    </div>
                                                    <div>
                                                        <h4 className="mb-1 mt-1"><span><CountUp end="1" separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                                        <p className="text-muted mb-0">Delivered</p>
                                                    </div>

                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                    <Row>

                                        <Col md="6">
                                            <Card>
                                                <CardHeader style={{ padding: "9px", fontWeight: "bolder" }}>Shipment Details</CardHeader>
                                                <CardBody style={{ padding: "5px" }}>
                                                    <table id="shipmentDashboardTB1">
                                                        <tr>
                                                            <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                Date
                                                            </td>
                                                            <td>: 01-11-2022</td>
                                                            <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                Time
                                                            </td>
                                                            <td>: 10:00 am</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                Shipment ID
                                                            </td>
                                                            <td>: SHI0001</td>
                                                            <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                Reference No
                                                            </td>
                                                            <td>: ALF134</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                From
                                                            </td>
                                                            <td>: Pakistan <img src={flag1} height="30px"></img></td>
                                                            <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                To
                                                            </td>
                                                            <td>: India <img src={flag2} height="30px"></img></td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                Cargo Type
                                                            </td>
                                                            <td>: Air</td>
                                                            <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                Destination Port
                                                            </td>
                                                            <td>: NHAVASHEVA</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                Dispatch Date
                                                            </td>
                                                            <td>: 20-11-2022</td>
                                                            <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                B/L No
                                                            </td>
                                                            <td>: MSCU1685657</td>
                                                        </tr>
                                                        <tr>
                                                            <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                Created By
                                                            </td>
                                                            <td>: Admin Cargo</td>
                                                            <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                Updated By
                                                            </td>
                                                            <td>: Admin Cargo</td>
                                                        </tr>
                                                    </table>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        <Col md="6">
                                            <Row>

                                                <Col md="12">
                                                    <Card style={{ marginBottom: "11px" }}>
                                                        <CardHeader style={{ padding: "9px", fontWeight: "bolder" }}>Carrier Details</CardHeader>
                                                        <CardBody style={{ padding: "5px" }}>
                                                            <table id="shipmentDashboardTB1">
                                                                <tr>
                                                                    <td style={{ padding: "10px", fontWeight: "500", width: "200px" }}>
                                                                        Carrier Name
                                                                    </td>
                                                                    <td>: NAVIO</td>
                                                                    <td style={{ padding: "10px", fontWeight: "500", width: "200px" }}>
                                                                        Carrier No
                                                                    </td>
                                                                    <td>: SIC83</td>
                                                                </tr>
                                                            </table>
                                                        </CardBody>

                                                    </Card>
                                                </Col>
                                                <Col md="12">
                                                    <Card style={{ marginBottom: "11px" }}>
                                                        <CardHeader style={{ padding: "9px", fontWeight: "bolder" }}>Cargo Details</CardHeader>
                                                        <CardBody style={{ padding: "5px" }}>
                                                            <table id="shipmentDashboardTB1">
                                                                <tr>
                                                                    <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                        Dispatched Boxes
                                                                    </td>
                                                                    <td>: 10</td>
                                                                    <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                        Dispatched Weight
                                                                    </td>
                                                                    <td>: 206</td>
                                                                </tr>
                                                            </table>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col md="12">
                                                    <Card>
                                                        <CardHeader style={{ padding: "9px", fontWeight: "bolder" }}>Agent Details</CardHeader>
                                                        <CardBody style={{ padding: "5px" }}>
                                                            <table id="shipmentDashboardTB1">
                                                                <tr>
                                                                    <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                        Name
                                                                    </td>
                                                                    <td>: SADIK</td>
                                                                    <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                        Mob
                                                                    </td>
                                                                    <td>: +91 9551111081</td>
                                                                    <td style={{ padding: "10px", fontWeight: "500", }}>
                                                                        Company
                                                                    </td>
                                                                    <td>: SNS LOGISTICS</td>
                                                                </tr>
                                                            </table>
                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                            </Row>



                                        </Col>
                                        <Col md="12">
                                            <Card>
                                                <CardHeader style={{ padding: "9px", fontWeight: "bolder" }}>Manifesto & Delivery Details</CardHeader>
                                                <CardBody>
                                                    <MDBDataTable
                                                        responsive
                                                        id="shipmentManifestoTB"
                                                        bordered
                                                        data={data}
                                                        entries={20} />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>

                                </CardBody>
                            </Card>

                        </Col>

                    </Row>
                </div>
            </div>
        </React.Fragment>
    )
}
export default ManifestExport