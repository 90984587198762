import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import { useHistory } from "react-router-dom";
import axios from "axios";
import toastr from "toastr";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { Modal } from "react-bootstrap";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    CardHeader,
    Collapse,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

import moment from "moment";
import $ from "jquery";

import {
    getLocalbody,
    getDate,
    getFirstday,
} from "../../../helpers/globalFunctions";
import FormControl from '@mui/material/FormControl';
import shipping1 from "../ShipmentUpdate/shipping1.svg";
import "./ShipmentUpdate.scss";
import { DeliveryDining } from "@mui/icons-material";




const ShipmentUpdate = () => {

    const [searchKey, setsearchKey] = useState('');

    const [col1, setcol1] = useState(-1);


    function searchShipment() {

    }

    return (
        <React.Fragment>

            <div className="page-content">
                <div className="container-fluid">
                    <Row>
                        <Col md="6">
                            <h1 className="update">Shipment Update</h1>

                            <FormControl style={{ width: "100%" }}>
                                <div className="searchcol">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={searchKey}
                                        placeholder="Please enter the Shipment ID"
                                        onChange={(e) => {
                                            setsearchKey(e.target.value);
                                            searchShipment(e);
                                        }}
                                    />
                                    <button className="search"><i class="fab fa-sistrix"></i></button>
                                </div>

                            </FormControl>


                            <Row>
                                <Col md="6" className="mt-1">
                                    {/* <Card className="detailcard"> */}

                                    <Card className="subcard">
                                        <CardBody className="subcardbody" style={{ padding: '0rem 1.25rem' }}>
                                            <div className="maindiv">
                                                {/* <img className="resulticon" src={shipping1} alt="image" /> */}
                                                <p className="detailsp mx-3 mt-2">Shipment ID: SHAL0190 <br /><span style={{ fontSize: ".9rem", fontWeight: "600" }}>Destination <span style={{ color: "red" }}>Oman</span></span></p>
                                                <h5 style={{ right: "1.5rem", top: "2.5rem" }}></h5>
                                            </div>

                                            <Collapse isOpen={col1}>

                                                <Row className="mt-2">
                                                    <hr class="new2" />
                                                </Row>

                                                <h6 style={{ fontWeight: "700" }}>Shipment details</h6>

                                                <Row>
                                                    <Col md="6">
                                                        <p>Order from: <span>Afganisthan</span></p>
                                                    </Col>

                                                    <Col md="6">
                                                        <p>Order to: <span>India</span></p>
                                                    </Col>
                                                </Row>
                                            </Collapse>

                                        </CardBody>

                                        <h5 className="arrowbutton">
                                            <i
                                              class="fa fa-angle-down mx-3 me-0" onClick={() => {
                                                setcol1()
                                              }}
                                            ></i>
                                        </h5>

                                    </Card>

                                    {/* </Card> */}
                                </Col>
                            </Row>

                            <Row>
                                <Col md="6" style={{ padding: "10px" }}>
                                    <Select 
                                       options={[
                                        {label: "Delivered", value: "0"}
                                       ]}
                                    />
                                </Col>

                                {/* <Col md="6">
                                    <AvField
                                      name='location'
                                      placeholder='Location'
                                      type='text'
                                    />
                                </Col>

                                <Col md="6">
                                    <AvField
                                      name='date'
                                      placeholder='Date'
                                      type='date'
                                    />
                                </Col>

                                <Col md="6">
                                    <AvField
                                      name='remarks'
                                      placeholder='Remark'
                                      type='text'
                                    />
                                </Col> */}

                                <Col md='4' style={{ top: "3.5rem" }}>
                                    <Button color="primary" type="submit">Submit</Button>
                                </Col>
                            </Row>
                        </Col>

                    </Row>


                </div>

            </div>
        </React.Fragment>
    )
}



export default ShipmentUpdate;