import React, { useState, useEffect } from "react";
import axios from "axios";
import accessToken from '../../../helpers/jwt-token-access/accessToken';
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap";
import $ from "jquery";
import moment from "moment";
import { CSVLink } from "react-csv";
import {
  getFirstday,
  getDate,
} from "../../../helpers/globalFunctions";
import "./style.scss";
import {
  getLocalbody,
} from "../../../helpers/globalFunctions";


const DayBook = (props) => {

  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [master, setMaster] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
    branch_id: currentLocalbody,
  });

  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [searchData, setSearchData] = useState([])
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    handleTableData();
  }, [currentLocalbody]);


  const handleSearch = () => {
    let from_date = master?.from_date ? master.from_date : "";
    let to_date = master?.to_date ? master.to_date : "";
    let branch_id = master?.branch_id ? master.branch_id : "";
    handleTableData(from_date, to_date, branch_id);

    setSearchData();
  }


  //tabledata
  function handleTableData(from_date = "", to_date = "") {

    // axios
    //   .get(`${API_URL}dayBook/list?dateFrom=${from_date}&dateTo=${to_date}&branch_id=${branch_id}`, {
    //     headers: {
    //       "x-access-token": accessToken
    //     },
    //   })

    let url = `${API_URL}dayBook/list?branch_id=` + currentLocalbody;
    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }
    url = url + "&dateFrom=" + from_date + "&dateTo=" + to_date;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        var result = res.data.data;

        result.dayExpense = parseFloat(result.dayExpense).toFixed(3);
        result.dayIncome = parseFloat(result.dayIncome).toFixed(3);
        result.dayBalance = parseFloat(result.dayBalance).toFixed(3);

        result?.data.map((item, index) => {

          if (item?.createdAt) {
            item.createdAt = moment(item?.createdAt).format("DD-MM-YYYY");
          }

          if (item?.income_expense_head[0]?.acc_type_name === "EXPENSE") {
            item.income_expense_amounts = parseFloat(item?.income_expense_amount).toFixed(3);
            item.income_expense_amount = "00.00";
          }

          if (item?.income_expense_head[0]?.acc_type_name === "INCOME") {
            item.income_expense_amount = parseFloat(item.income_expense_amount).toFixed(3);
            item.income_expense_amounts = "00.00";
          }


        });

        setSearchData(result);

        // Export Data
        let dataToBeExported = [];

        result?.data &&
          result?.data.map((item, index) => {

            var exportItem = {};
            item.id = index + 1;

            exportItem.Slno = item.id;
            exportItem.Date = item.createdAt;
            exportItem.Id = item.income_expense_unqid;
            exportItem.Description = item.income_expense_subhead[0].acc_detail_name;
            exportItem.Debit = item.income_expense_amounts;
            exportItem.Credit = item.income_expense_amount;

            dataToBeExported.push(exportItem);
          });

        // const openingBalanceEntry = {
        //   Description: "Opening Balance",
        //   Debit: result?.dayExpense,
        //   Credit: result?.dayIncome,
        // };


        // // Insert the openingBalanceEntry right after the titles (at index 1)
        // dataToBeExported.splice(openingBalanceEntry);

        setDataToBeExported([
          ...dataToBeExported,
          {
            Description: "Total",

            Debit: result?.dayExpense,
            Credit: result?.dayIncome,

          },

          {
            Debit: "Today Balance",

            Credit: result?.dayBalance,
          },

          {
            Debit: "Today Closing Balance",

            Credit: result?.closingBalance,
          }
        ]);


      })
  }


  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    //date validation
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setMaster({
        ...master,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setMaster({
        ...master,
        [e.target.name]: e.target.value,
      });
    }
  };


  const resetSearch = () => {
    setMaster({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
      branch_id: currentLocalbody,
    });
    handleTableData("", "", "");
  }


  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Day Book" />
          <Row>
            <Col md="12">
              {/* <Card>
                <CardBody> */}
              <Row>
                <Col md="2">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">From</Label>
                    {/* <div className="col-md-10"> */}
                    <input
                      className="form-control"
                      type="date"
                      id="from_date"
                      name="from_date"
                      onChange={handleDate}
                      value={master?.from_date}
                    />
                    {/* </div> */}
                  </div>
                </Col>

                <Col md="2">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">To</Label>
                    {/* <div className="col-md-10"> */}
                    <input
                      className="form-control"
                      type="date"
                      id="to_date"
                      name="to_date"
                      onChange={handleDate}
                      value={master?.to_date}
                      min={master?.from_date}
                    />
                    {/* </div> */}
                  </div>
                </Col>

                <Col md="1" className="mt-4" style={{ paddingTop: "5px" }}>
                  <Button onClick={handleSearch}
                    color="primary"
                    type="submit"
                  >
                    Search
                  </Button>
                </Col>

                <Col md="1" className="mt-4" style={{ paddingTop: "5px", marginLeft: "-23px" }}>
                  <Button onClick={resetSearch} color="danger" type="reset">
                    Reset
                  </Button>
                </Col>
              </Row>
              {/* </CardBody>
              </Card> */}
            </Col>


            {searchData ? (
              <Col md="12">
                {/* <Card>
              <CardBody> */}
                <div>
                  <Button className="btn btn-success mb-3" type="submit" style={{ float: "right" }}><i class="fas fa-file-excel"></i>
                    <CSVLink
                      data={dataToBeExported}
                      filename={
                        "Day_book_report_" + getDate(new Date()) + ".xls"
                      }
                      style={{ color: "white" }}
                    >
                      Export
                    </CSVLink></Button>
                </div>

                <table className="table table-bordered" id="tableDayBook" style={{ background: "#fff" }}>
                  <thead>
                    <tr>
                      <th style={{ width: "5px" }}>#</th>
                      <th>Date</th>
                      <th>Income/Expense Id</th>
                      <th>Description</th>
                      <th>Debit</th>
                      <th>Credit</th>
                    </tr>
                  </thead>
                  <tbody>

                    <tr>
                      <td colSpan={5} style={{ textAlign: "right", fontWeight: 'bold' }}>Total Opening Balance </td>
                      <td style={{ textAlign: "right", fontWeight: 'bold' }}>{searchData?.openingBalance}</td>
                    </tr>
                    {searchData?.data && searchData?.data.map((item, index) => (

                      <>
                        <tr>
                          <td style={{ width: "5px" }}> {index + 1}</td>
                          <td>{item.createdAt}</td>
                          <td style={{ textAlign: "center" }}> {item.income_expense_unqid}</td>
                          <td style={{ textAlign: "left" }}>{item.income_expense_subhead[0].acc_detail_name}</td>
                          <td style={{ textAlign: "right" }}>{item.income_expense_amounts}</td>
                          <td style={{ textAlign: "right" }}>{item.income_expense_amount}</td>
                        </tr>
                      </>
                    ))}
                    <tr>
                      <td colSpan={4} style={{ textAlign: "right", fontWeight: 'bold' }}>Total</td>
                      <td style={{ textAlign: "right" }}>{searchData?.dayExpense}</td>
                      <td style={{ textAlign: "right" }}>{searchData?.dayIncome}</td>
                    </tr>


                    <tr>
                      <td colSpan={5} style={{ textAlign: "right", fontWeight: 'bold' }}>Today Balance</td>
                      <td style={{ textAlign: "right", fontWeight: 'bold' }}>{searchData?.dayBalance}</td>
                    </tr>
                    <tr>
                      <td colSpan={5} style={{ textAlign: "right", fontWeight: 'bold' }}>Today Closing Balance</td>
                      <td style={{ textAlign: "right", fontWeight: 'bold' }}>{searchData?.closingBalance}</td>
                    </tr>



                  </tbody>
                </table>
                {/* </CardBody>
            </Card> */}
              </Col>

            ) : ""}

          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}
export default DayBook;









