

import React, { useRef, useEffect, useState } from 'react';
// import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem, CardTitle, Collapse, Table } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Form } from 'react-bootstrap';
import Select from "react-select"
import "./InBound.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from "axios";
import accessToken from '../../../../helpers/jwt-token-access/accessToken';
import FormControl from '@mui/material/FormControl';
import { result, set } from 'lodash';
import toastr, { error, options } from "toastr";
import ReactDOM from "react-dom";
import { NoBackpackSharp } from '@mui/icons-material';
import boxicon from "../box.svg";
import invoice from "../invoice.svg";
import { useLocation } from 'react-router-dom';




const InBound = (props) => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

    const [boxdetails, setboxdetails] = useState({});
    const [stateorder, setStateorder] = useState(-1);

    const [one, setone] = useState("none");
    const [two, settwo] = useState("none");
    const [three, setthree] = useState("none");
    const [four, setfour] = useState("none");

    const [col5, setcol5] = useState(false)

    const [order, setOrder] = useState([]);
    const [boxes, setBoxes] = useState([]);
    const [orderObject, setOrderObject] = useState({});
    const [orderObj, setOrderObj] = useState({});
    const [masterObject, setmasterObject] = useState({});

    const [statusOptions, setStatusOptions] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [selectedOrder, setselectedOrder] = useState(null);
    const [showStatus, setshowStatus] = useState(false);
    const [showFields, setshowFields] = useState(false);
    const [orderColor, setorderColor] = useState("white");
    const [searchKey, setsearchKey] = useState('');

    const [errors, setErrors] = useState({
        statusError: "",
    });

    const formRef = useRef();

    const location = useLocation();
    const UniqueId = location?.state?.outboundID || null;
    const branchId = location?.state?.branchId || null;
    const outSts = location?.state?.outInsts || null;

    useEffect(() => {
        fetch_all_status();
    }, []);


    const fetch_all_status = () => {

        axios
            .get(`${API_URL}delivery/status`, {
                headers: {
                    "x-access-token": accessToken
                }
            }).then((res) => {

                let status_data = res.data.data;

                setStatusOptions([{

                    label: res.data.data.ship_status_name,
                    value: res.data.data._id
                }])
            })
    }



    function handleClickOpen(selected, name) {
        switch (name) {
            case "ship_status_name":
                setSelectedStatus(selected);
                setOrderObject({
                    ...orderObject,
                    ship_status_name: selected.value,
                });
                break;

            default:
                break;
        }
        if (selected.value === 0) {
            setone("block");
        }

    }


    function searchOrder(e) {
        setStateorder(-1)
        setorderColor('white')

        if (e.target.value) {
            axios
                .get(`${API_URL}process/search_outbound?outbound_id=` + e.target.value, {
                    headers: {
                        "x-access-token": accessToken,
                    }
                })
                .then((res) => {

                    let result = res.data.data;
                    setOrder(result);

                })
        } else {

            setOrder([]);
        }
    }



    function handleValidSubmit(e) {

        let data = {
            outBound_id: UniqueId,
            branch_id: branchId,
            outIn_status: outSts
        }
        axios
            .post(`${API_URL}process/in-bound`, data, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                if (res.data.status === 200) {
                    toastr.success("Status updated successfully");
                    // formRef.current.reset();
                    setOrder([]);
                    setshowFields(false);
                    setshowStatus(false);
                    setselectedOrder(null);
                    setmasterObject({});
                    setsearchKey('')
                    setorderColor('white')
                } else toastr.error("Failed to update status");
            })
            .catch((err) => {
                toastr.error(err.response.data.message);
                return;
            });
    }


    function handleInput(e) {
        let name = e.target.name;
        let value = e.target.value;
        setmasterObject({ ...masterObject, [name]: value })
    }





    return (
        <React.Fragment>
            <div className='page-content'>
                <div className='container-fluid'>
                    <Row>
                        <Col md="6">
                            <h1 className='singleorder'>Process InBound</h1>

                            <FormControl style={{ width: "100%" }} >
                                <div className="srchcol">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={searchKey}
                                        placeholder='Please enter the OrderId/Order-No'
                                        onChange={(e) => {
                                            searchOrder(e)
                                            setsearchKey(e.target.value)
                                        }}
                                    />
                                    <button class="srchbtn"><i class="fab fa-sistrix"></i></button>
                                </div>
                            </FormControl>

                            <Row>
                                <Col md="6" className='mt-1'>
                                    <Card className='maindetailcard1'>
                                        {order?.orders &&
                                            order?.orders?.map((el, index) => {

                                                return (
                                                    <Card className='resultcard1'

                                                        onClick={() => {
                                                            setStateorder(index)
                                                            setshowStatus(true);
                                                            // setOrder([el])
                                                            setselectedOrder(el)
                                                            settwo("block")
                                                            setthree("block")
                                                            setfour("block")
                                                            setorderColor('#ffe0cf')
                                                        }}
                                                        style={{
                                                            background: orderColor
                                                        }} >
                                                        <CardBody className='resultcardbody1' >
                                                            <div className='resultmain'>
                                                                <img className='resulticon' src={invoice} alt="image" />
                                                                <p className='detailsp mx-3 mt-2'>Order-No: {el?.order_number}<br /><span style={{ fontSize: ".9rem", fontWeight: "600" }}>Destination <span style={{ color: "red" }}>{el?.country_to?.name}</span></span></p>
                                                                <h5 className='resultweight' style={{ right: "1.5rem" }}>{el?.total_weight}KG</h5>
                                                            </div>

                                                        </CardBody>
                                                    </Card>

                                                )
                                            })
                                        }
                                    </Card>

                                </Col>

                            </Row>
                            <hr />

                            <AvForm onValidSubmit={(e, v) => { handleValidSubmit(e, v); }}>
                                <Row className='m-0 '>
                                    {
                                        showStatus ? (
                                            <Col md="6" className='fields'>

                                                <Select
                                                    name='status'
                                                    placeholder='Status'
                                                    onChange={(selected) => {
                                                        setmasterObject({ ...masterObject, status: selected.value })
                                                        setshowFields(true)
                                                    }}
                                                    options={statusOptions}
                                                    classNamePrefix="select2-selection"
                                                />

                                            </Col>
                                        ) : ""
                                    }

                                    {
                                        showFields &&
                                        <>
                                            <Col md="6" className='fields'>
                                                <AvField
                                                    name="location"
                                                    placeholder="Location"
                                                    onChange={(e) => { handleInput(e) }}
                                                    type="text"
                                                />
                                            </Col>

                                            <Col md="6" className='fields'>
                                                <AvField
                                                    name="date"
                                                    onChange={(e) => { handleInput(e) }}
                                                    placeholder="Date"
                                                    type="date"
                                                />
                                            </Col>


                                            <Col md="6" className='fields'>
                                                <AvField
                                                    name="remarks"
                                                    placeholder="Remark"
                                                    type="textarea"
                                                    onChange={(e) => { handleInput(e) }}
                                                />
                                            </Col>

                                            <Col md="4" className='fields'>
                                                <Button color='primary' type='submit' style={{}}>Submit</Button>

                                            </Col>
                                        </>
                                    }

                                </Row>
                            </AvForm>

                        </Col>

                        {/* ======================================================================================================================================== */}

                        {
                            selectedOrder && <Col md='4'>
                                <h2 className='trackingh1'>Box details</h2>
                                {
                                    order?.boxes &&
                                    order?.boxes?.map((bx, index) => {

                                        return (
                                            <Card className='resultcardtrackbox'>
                                                <Row >
                                                    <CardBody className='resultcardbodytrack' >
                                                        <div className='resultmain'>
                                                            <img className='resulticon' src={boxicon} alt="image" />
                                                            <p className='detailsp mx-3 mt-2'>Box-No: {bx?.box_number}<br /><span style={{ fontSize: ".9rem", fontWeight: "600" }}>Status: <span style={{ color: "red" }}>{bx?.shipment_status?.ship_status_name}</span></span></p>
                                                            <h5 className='resultweight'>{bx.box_weight}KG</h5>
                                                        </div>

                                                    </CardBody>
                                                </Row>
                                            </Card>
                                        )
                                    })
                                }
                            </Col>
                        }


                    </Row>

                </div>
            </div>
        </React.Fragment>
    )
}

export default InBound;