import React, { useState, useEffect } from 'react'
import "./Trackingnew.scss"
import { Row, Col, Card, CardBody, Button, Label, NavItem, CardTitle, Collapse, Table } from "reactstrap";

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import boxicon from "./box.svg"
import invoice from "./invoice.svg"
import { Link } from "react-router-dom"
import classnames from "classnames"
import axios from 'axios';
import accessToken from '../../../helpers/jwt-token-access/accessToken';
import { result } from 'lodash';
import { MoneyOffCsredSharp } from '@mui/icons-material';
import { style } from '@mui/system';
import moment from 'moment';



const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

function Tranckingnew() {



    // useEffect(() => {
    //  searchOrder()
    // }, [])

    const [col1, setcol1] = useState(-1);
    const [col2, setcol2] = useState(true);
    const [col5, setcol5] = useState(true);
    const [col6, setcol6] = useState(true);
    const [col7, setcol7] = useState(true);
    const [col8, setcol8] = useState(true);
    const [col9, setcol9] = useState(true);




    const [order, setorder] = useState([]);
    const [detailbox, setdetailbox] = useState([]);

    const [search, setsearch] = useState(null);
    const [stateorder, setStateorder] = useState(-1);
    const [stateship, setStateship] = useState(-1);
    const [statebox, setStatebox] = useState(-1);

    const [booked, setbooked] = useState(0);
    const [dispatched, setdispatched] = useState(0);
    const [incustom, setincustom] = useState(0);
    const [outdeliver, setoutdeliver] = useState(0);
    const [deliverd, setdeliverd] = useState(0);

    const [currentbooked, setcurrentbooked] = useState(false);
    const [currentdispatched, setcurrentdispatched] = useState(false);
    const [currentincustom, setcurrentincustom] = useState(false);
    const [currentoutdeliver, setcurrentoutdeliver] = useState(false);
    const [currentdeliverd, setcurrentdeliverd] = useState(false);








    const [deliverystatus, setdeliverystatus] = useState([]);
    function searchOrder(e) {
        setdetailbox([]);
        setdeliverystatus([]);
        setStatebox(-1)
        setStateorder(-1)
        setStateship(-1)


        // ==========================onchange======================>
        if (e.target.value) {
            axios
                .get(`${API_URL}track/search?value=` + e.target.value, {
                    headers: {
                        "x-access-token": accessToken
                    }
                })
                .then((res) => {
                    let result = res.data.data

                    setorder(result)
                })
        }
        else {
            setorder([]);

        }

    }


    // =========================>boxdetails

    function fetch_box(el) {
        setdeliverystatus([]);
        let uniqueId = ''
        if (el.shipment_id) uniqueId = el.shipment_id
        else if (el.order_number) uniqueId = el.order_number
        axios
            .get(`${API_URL}track/boxes?_id=${el?._id}&uniqueId=${uniqueId}`, {
                headers: {
                    "x-access-token": accessToken
                }

            }).then((res) => {
                let result = res.data
                setdetailbox(result)
            })
    }

    // ============================deliverystatus==========================>



    function fetch_deliverystatus(box) {
        setbooked(0);
        setdispatched(0);
        setincustom(0);
        setoutdeliver(0);
        setdeliverd(0);
        setcurrentbooked(false);
        setcurrentdispatched(false);
        setcurrentoutdeliver(false);
        setcurrentdeliverd(false);
        setcurrentincustom(false);

        axios
            .get(`${API_URL}track/?box_id=${box._id}`, {
                headers: {
                    "x-access-token": accessToken
                }
            })
            .then((res) => {
                let result = res.data.data
                setdeliverystatus(result)
                if (result?.track_data[0].details.length > 0) {
                    setbooked(1)
                }
                if (result?.track_data[1].details.length > 0) {
                    setdispatched(2)
                }
                if (result?.track_data[2].details.length > 0) {
                    setincustom(3)
                }
                if (result?.track_data[3].details.length > 0) {
                    setoutdeliver(4)
                }
                if (result?.track_data[4].details.length > 0) {
                    setdeliverd(5)
                }
                // ====================================highlight=================================>

                if (result?.current_status?.ship_status_name === "delivered") {
                    setcurrentdeliverd(true)
                }
                if (result?.current_status?.ship_status_name == "In-Customs") {
                    setcurrentincustom(true)
                }

                if (result?.current_status?.ship_status_name == "Booked") {
                    setcurrentbooked(true)
                }

                if (result?.current_status?.ship_status_name == "Dispatched") {
                    setcurrentdispatched(true)
                }

                if (result?.current_status?.ship_status_name == "Out For Delivery") {
                    setcurrentoutdeliver(true)
                }
                if (result?.current_status?.ship_status_name == "Delivered") {
                    setcurrentdeliverd(true)
                }

            })

    }



    return (
        <React.Fragment>
            <div className='page-content'>
                <div className='container-fluid'>
                    <h1 className='trackingh1'>Tracking</h1>
                    <Row>
                        <Col md="8">
                            <Card className='trackingcard'>
                                <CardBody>
                                    <FormControl style={{ width: "100%" }} >
                                        {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                                        {/* <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                        >

                                            <FormControlLabel value="OrderNumber" control={<Radio />} label="Order Number" color='#eb6517' />
                                            <FormControlLabel value="MobileNumber" control={<Radio />} label="Mobile Number" />
                                            <FormControlLabel value="ShipmentNumber" control={<Radio />} label="Shipment Number" />
                                        </RadioGroup> */}
                                        <div class="srchcol">
                                            <input type="text" placeholder="Please enter the Order No/Order ID" name=""

                                                onChange={searchOrder}
                                            />
                                            <button class="srchbtn"><i class="fab fa-sistrix"></i></button>
                                        </div>
                                    </FormControl>
                                </CardBody>
                            </Card>

                            <Row>
                                {/* <p className='srcresult'>Showing Results  </p> */}


                                <Col className='mt-1' md="6">
                                    <Card className='maindetailcard'>


                                        {order.orders &&
                                            order?.orders?.map((el, index) => {
                                                return (

                                                    <Card className='resultcardtrack'
                                                        // style={{ cursor: "pointer" }}


                                                        style={{
                                                            background: stateorder === index ? '#ffe0cf' : 'white'
                                                        }}
                                                    >
                                                        <CardBody className='resultcardbodytrack' onClick={() => {

                                                            fetch_box(el)
                                                            setStateorder(index)
                                                            setStatebox(-1)

                                                        }} >
                                                            <div className='resultmain'>
                                                                <img className='resulticon' src={invoice} alt="image" />
                                                                <p className='detailsp mx-3 mt-2'>Order-No: {el.order_number}<br /><span style={{ fontSize: ".9rem", fontWeight: "600" }}>Destination <span style={{ color: "red" }}>{el.country_to.name}</span></span></p>
                                                                <h5 className='resultweight'>{el.total_weight}KG </h5>
                                                            </div>
                                                            <Collapse isOpen={col1 === el._id ? true : false}

                                                            >
                                                                <Row className='mt-2'>
                                                                    <hr class="new2" />
                                                                </Row>
                                                                <h6 style={{ fontWeight: "700" }}>Order Details</h6>
                                                                <Row>
                                                                    <Col md="6">
                                                                        <p>No.0f Boxes: <span>{el?.no_of_boxes}</span></p>
                                                                        <p>Payment Status: <span>{el?.payment_status}</span></p>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <p>Booking Date: <span>{moment(el?.date).format("DD-MM-YYYY")}</span></p>
                                                                        {el?.cargo_type ? (
                                                                            <p>Cargotype: <span>{el?.cargo_type?.cargo_Type}</span></p>
                                                                        ) : null}


                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <p style={{ fontWeight: "700" }}>Sender Details</p>
                                                                    <Col md="12">
                                                                        <table className='w-100'>
                                                                            <tr>
                                                                                <td style={{ width: "10rem" }}>Name</td>
                                                                                <th>{el?.sender?.name}</th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Country</td>
                                                                                <th>{el?.country_from?.name}</th>
                                                                            </tr>
                                                                            {/* <tr>
                                                                                <td>House </td>
                                                                                <th></th>
                                                                            </tr> */}
                                                                            {el?.sender?.id_number ? (
                                                                                <tr>
                                                                                    <td>ID.No</td>
                                                                                    <th>{el?.sender?.id_number}</th>
                                                                                </tr>
                                                                            ) : ''}
                                                                            <tr>
                                                                                <td>Mobile.No</td>
                                                                                <th>{el?.sender?.mobile_number}</th>
                                                                            </tr>
                                                                        </table>
                                                                    </Col>

                                                                </Row>
                                                                {/* =========================================recever ==================== */}

                                                                <Row className='mt-3'>
                                                                    <p style={{ fontWeight: "700" }}>Receiver Details</p>
                                                                    <Col md="12">
                                                                        <table className='w-100'>
                                                                            <tr>
                                                                                <td style={{ width: "10rem" }}>Name</td>
                                                                                <th>{el?.receiver?.name}</th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Country</td>
                                                                                <th>{el?.country_to?.name}</th>
                                                                            </tr>
                                                                            {el?.receiver?.rece_state?.name ? (
                                                                                <tr>
                                                                                    <td>State</td>
                                                                                    <th>{el?.receiver?.rece_state?.name}</th>
                                                                                </tr>
                                                                            ) : ""}
                                                                            {el?.receiver?.house_name ? (
                                                                                <tr>
                                                                                    <td>House Name</td>
                                                                                    <th>{el?.receiver?.house_name}</th>
                                                                                </tr>
                                                                            ) : ""}
                                                                            {el?.receiver?.landmark ? (
                                                                                <tr>
                                                                                    <td>Landmark</td>
                                                                                    <th>{el?.receiver?.landmark}</th>
                                                                                </tr>
                                                                            ) : ""}
                                                                            {/* <tr>
                                                                                <td>Post Office</td>
                                                                                <th></th>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>PIN</td>
                                                                                <th></th>
                                                                            </tr> */}

                                                                            <tr>
                                                                                <td>Mobile.No</td>
                                                                                <th>{el?.receiver?.mobile_number}</th>
                                                                            </tr>

                                                                        </table>
                                                                    </Col>

                                                                </Row>


                                                            </Collapse>
                                                        </CardBody>
                                                        <h5 className='arrowbutton'>

                                                            <i class="fa fa-angle-down  mx-3 me-0" onClick={() => {
                                                                setcol1(el._id)
                                                                if (col1 === el._id) {
                                                                    setcol1(-1)
                                                                }
                                                            }}></i></h5>
                                                    </Card>

                                                )

                                            })
                                        }






                                        {order.shipments &&
                                            order.shipments.map((sh, index) => {
                                                return (
                                                    <Card className='resultcardtrack'

                                                        onClick={() => {
                                                            fetch_box(sh)
                                                            setStateship(index)
                                                            setStatebox(-1)
                                                        }}
                                                        style={{
                                                            background: stateship === index ? '#ffe0cf' : 'white'
                                                        }}
                                                    >
                                                        <CardBody className='resultcardbodytrack' >
                                                            <div className='resultmain'>
                                                                <img className='resulticon' src={invoice} alt="image" />
                                                                <p className='detailsp mx-3 mt-2'>Shipment-ID: {sh.shipment_id}<br /><span style={{ fontSize: ".9rem", fontWeight: "600" }}>Destination <span style={{ color: "red" }}>{sh.order_to.name}</span></span></p>
                                                                {/* <h5 className='resultweight'>56KG</h5> */}
                                                            </div>
                                                            {/* <Collapse isOpen={!col1}>
                                                    <Row className='mt-2'>
                                                        <hr class="new2" />
                                                    </Row>
    
                                                    Anim pariatur cliche reprehenderit, enim eiusmod high
                                                    life accusamus terry richardson ad squid. Nihil anim
                                                    keffiyeh helvetica, craft beer labore wes anderson cred
                                                    nesciunt sapiente ea proident.
    
                                                </Collapse> */}
                                                        </CardBody>
                                                    </Card>
                                                )


                                            })
                                        }


                                        {

                                            order.boxes &&
                                            order.boxes.map((bx, index) => {
                                                return (
                                                    <Card className='resultcardtrackbox'

                                                        onClick={() => {

                                                            fetch_deliverystatus(bx)
                                                            setStatebox(index)


                                                        }}
                                                        style={{
                                                            background: statebox === index ? '#ffe0cf' : 'white'
                                                        }}
                                                    >
                                                        <CardBody className='resultcardbodytrack' >
                                                            <div className='resultmain'>
                                                                <img className='resulticon' src={boxicon} alt="image" />
                                                                <p className='detailsp mx-3 mt-2'>Box-No: {bx?.box_number}<br /><span style={{ fontSize: ".9rem", fontWeight: "600" }}>Status <span style={{ color: "red" }}>{bx?.shipment_status?.ship_status_name}</span></span></p>
                                                                <h5 className='resultweight'>{bx?.box_weight}KG</h5>
                                                            </div>

                                                        </CardBody>
                                                    </Card>
                                                )
                                            })
                                        }

                                    </Card>
                                </Col>


                                {/* ================================BOXDETAILS======================================> */}

                                <Col style={{ marginTop: "-1,8" }} md="6">
                                    <Card className='maindetailcard'>


                                        {

                                            detailbox ? (
                                                detailbox?.data?.map((box, index) => {

                                                    return (
                                                        <Card className='resultcardtrackbox'

                                                            onClick={() => {

                                                                fetch_deliverystatus(box)
                                                                setStatebox(index)


                                                            }}
                                                            style={{
                                                                background: statebox === index ? '#ffe0cf' : 'white'
                                                            }}

                                                        >
                                                            <CardBody className='resultcardbodytrack' >
                                                                <div className='resultmain'>
                                                                    <img className='resulticon' src={boxicon} alt="image" />
                                                                    <p className='detailsp mx-3 mt-2'>Box-N0: {box?.box_number} <br /><span style={{ fontSize: ".9rem", fontWeight: "600" }}>Status: <span style={{ color: "red" }}>{box?.shipment_status?.ship_status_name}</span></span></p>
                                                                    <h5 className='resultweight'>{box?.box_weight}KG  </h5>
                                                                </div>

                                                            </CardBody>


                                                        </Card>

                                                    )
                                                })

                                            ) :
                                                <p>no details found</p>

                                        }


                                    </Card>





                                </Col>



                            </Row>
                        </Col>
                        {/* ==================================deliverstatus=========================> */}


                        <Col md="4">
                            {
                                deliverystatus?.track_data?.length > 0 ? (

                                    <Card className='col4tcard'>
                                        <CardBody className='col4trackingcard'>
                                            <div className='col4mainhead'>
                                                <img className='iconcol4 mx-3 mt-2 mb-1' src={boxicon} alt="boxicon" />
                                                <span>
                                                    <h6 className='mainheadh6 mt-3' >Box-No: {deliverystatus?.box_number}</h6>
                                                    <p className='statuscol4 mt-1'>Status: <span className='red'>{deliverystatus?.current_status?.ship_status_name}</span></p>
                                                </span>
                                                <h5 className='qtybox me-0'>{deliverystatus?.box_weight}KG</h5>
                                            </div>
                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setcol6(!col6)
                                                    setcol5(true)
                                                    setcol7(true)
                                                    setcol8(true)
                                                    setcol9(true)


                                                }}


                                                className="orderdetails">
                                                <i
                                                    style={{
                                                        backgroundColor: currentbooked ? "#282828" : "white"
                                                    }}
                                                    class="fa fa-box-open  iconship mt-4 mx-3"></i>
                                                <h6 style={{
                                                    color: booked === 0 ? "#999" : "black"
                                                }} className='shipmentbooked mt-4 '>{deliverystatus?.track_data[0]?.ship_status_name}</h6>
                                                <i
                                                    onClick={() => {
                                                        setcol6(!col6)
                                                        setcol5(true)
                                                        setcol7(true)
                                                        setcol8(true)
                                                        setcol9(true)


                                                    }}
                                                    style={{ display: booked === 0 ? "none" : "block", cursor: "pointer" }}
                                                    class="fa fa-angle-down mt-4 mx-4"></i>

                                            </div>
                                            <Collapse className='collapsedetails' isOpen={!col6}>

                                                <p>Booked On: {moment(deliverystatus?.track_data[0]?.details[0]?.date)?.format("DD-MM-YYYY")}</p>


                                            </Collapse>


                                            {/* accordian start====================================================================================> */}
                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setcol5(!col5)
                                                    setcol6(true)
                                                    setcol7(true)
                                                    setcol8(true)
                                                    setcol9(true)


                                                }}
                                                className='orderdetails' >

                                                <i
                                                    style={{ backgroundColor: currentdispatched ? "#282828" : "white" }}
                                                    class="fa fa-plane iconship mt-5 mx-3"></i>
                                                <h6
                                                    style={{
                                                        color: dispatched === 0 ? "#999" : "black"
                                                    }}
                                                    className='shipmentbooked mt-5 '>{deliverystatus?.track_data[1]?.ship_status_name} </h6>

                                                <i onClick={() => {
                                                    setcol5(!col5)
                                                    setcol6(true)
                                                    setcol7(true)
                                                    setcol8(true)
                                                    setcol9(true)


                                                }}

                                                    style={{
                                                        display: dispatched === 0 ? "none" : "block",
                                                        cursor: "pointer"
                                                    }}
                                                    class="fa fa-angle-down mt-5 mx-4"></i>

                                            </div>
                                            <Collapse className='collapsedetails' isOpen={!col5}>

                                                {
                                                    deliverystatus?.track_data[1]?.details?.map((item, index) => {
                                                        return (
                                                            <>
                                                                <p>{moment(item?.date).format("DD-MM-YYYY")}-{item?.remarks} -<span>{item?.location}</span> </p>

                                                            </>
                                                        )

                                                    })
                                                }

                                            </Collapse>
                                            {/* accordian end========================================================================================> */}

                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setcol7(!col7)
                                                    setcol5(true)
                                                    setcol6(true)
                                                    setcol8(true)
                                                    setcol9(true)

                                                }}

                                                className='orderdetails'>
                                                <i style={{
                                                    backgroundColor: currentincustom ? "#282828" : "white"
                                                }} class="fa fa-user-tie iconship mt-5 mx-3"></i>
                                                <h6 style={{
                                                    color: incustom === 0 ? "#999" : "black"
                                                }}
                                                    className='shipmentbooked mt-5 '>{deliverystatus?.track_data[2]?.ship_status_name}</h6>
                                                <i onClick={() => {
                                                    setcol7(!col7)
                                                    setcol5(true)
                                                    setcol6(true)
                                                    setcol8(true)
                                                    setcol9(true)

                                                }}

                                                    style={{
                                                        display: incustom === 0 ? "none" : "block",
                                                        cursor: "pointer"
                                                    }}
                                                    class="fa fa-angle-down mt-5 mx-4"></i>
                                            </div>
                                            <Collapse className='collapsedetails' isOpen={!col7}>
                                                {
                                                    deliverystatus?.track_data[2]?.details?.map((item, index) => {

                                                        return (
                                                            <>

                                                                <p>{moment(item?.date).format("DD-MM-YYYY")}-{item?.remarks} In <span>{item?.location}</span> </p>


                                                            </>
                                                        )

                                                    })

                                                }



                                            </Collapse>


                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setcol8(!col8)
                                                    setcol7(true)
                                                    setcol5(true)
                                                    setcol6(true)
                                                    setcol9(true)
                                                }}

                                                className='orderdetails'>
                                                <i
                                                    style={{ backgroundColor: currentoutdeliver ? "#282828" : "white" }}

                                                    class="fa fa-truck  iconship mt-5 mx-3"></i>
                                                <h6 style={{
                                                    color: outdeliver === 0 ? "#999" : "black"
                                                }} className='shipmentbooked mt-5 '>{deliverystatus?.track_data[3]?.ship_status_name}</h6>
                                                <i onClick={() => {
                                                    setcol8(!col8)
                                                    setcol7(true)
                                                    setcol5(true)
                                                    setcol6(true)
                                                    setcol9(true)
                                                }}

                                                    style={{
                                                        display: outdeliver === 0 ? "none" : "block",
                                                        cursor: "pointer"
                                                    }}
                                                    class="fa fa-angle-down mt-5 mx-4"></i>
                                            </div>
                                            <Collapse className='collapsedetails' isOpen={!col8}>
                                                {
                                                    deliverystatus?.track_data[3]?.details?.map((item, index) => {

                                                        return (
                                                            <>

                                                                <p>{moment(item?.date).format("DD-MM-YYYY")}-{item?.remarks}</p>


                                                            </>
                                                        )

                                                    })

                                                }


                                            </Collapse>

                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setcol9(!col9)
                                                    setcol8(true)
                                                    setcol7(true)
                                                    setcol5(true)
                                                    setcol6(true)
                                                }}
                                                className='orderdetails'>
                                                <i
                                                    style={{ backgroundColor: currentdeliverd ? "#282828" : "white" }}
                                                    class="fa fa-home  iconship mt-5 mx-3"></i>
                                                <h6 style={{
                                                    color: deliverd === 0 ? "#999" : "black"
                                                }}
                                                    className='shipmentbooked mt-5 '>{deliverystatus?.track_data[4]?.ship_status_name}</h6>
                                                <i onClick={() => {
                                                    setcol9(!col9)
                                                    setcol8(true)
                                                    setcol7(true)
                                                    setcol5(true)
                                                    setcol6(true)
                                                }}

                                                    style={{
                                                        display: deliverd === 0 ? "none" : "block",
                                                        cursor: "pointer"
                                                    }}
                                                    class="fa fa-angle-down mt-5 mx-4"></i>
                                            </div>
                                            <Collapse className='collapsedetails' isOpen={!col9}>

                                                {
                                                    deliverystatus?.track_data[4]?.details?.map((item, index) => {

                                                        return (
                                                            <>

                                                                <p>{moment(item?.date).format("DD-MM-YYYY")}-{item?.remarks}</p>


                                                            </>
                                                        )

                                                    })

                                                }

                                            </Collapse>


                                        </CardBody>
                                    </Card>
                                ) : null
                            }




                        </Col>
                    </Row>

                </div>
            </div>
        </React.Fragment>
    )
}

export default Tranckingnew
