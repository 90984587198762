import {
  GET_CHARTOFACC,
  GET_CHARTOFACC_SUCCESS,
  GET_CHARTOFACC_FAIL,
  ADD_CHARTOFACC,
  ADD_CHARTOFACC_SUCCESS,
  ADD_CHARTOFACC_FAIL,
  UPDATE_CHARTOFACC,
  UPDATE_CHARTOFACC_SUCCESS,
  UPDATE_CHARTOFACC_FAIL,
  DELETE_CHARTOFACC,
  DELETE_CHARTOFACC_SUCCESS,
  DELETE_CHARTOFACC_FAIL
  } from './actionTypes';
  
  export const getAllChartOfAcc = (localbody_id) => ({
    type: GET_CHARTOFACC,
    localbody_id: localbody_id,
  });
  
  export const getAllChartOfAccSuccess = (data) => ({
    type: GET_CHARTOFACC_SUCCESS,
    payload: data,
  });
  
  export const getAllChartOfAccFail = (error) => ({
    type: GET_CHARTOFACC_FAIL,
    payload: error,
  });
  
  export const addChartOfAcc = (data) => ({
    type: ADD_CHARTOFACC,
    payload: data,
  });
  
  export const addChartOfAccSuccess = (data) => ({
    type: ADD_CHARTOFACC_SUCCESS,
    payload: data,
  });
  
  export const addChartOfAccFail = (error) => ({
    type: ADD_CHARTOFACC_FAIL,
    payload: error,
  });
  
  export const updateChartOfAcc = (data) => ({
    type: UPDATE_CHARTOFACC,
    payload: data,
  });
  
  export const updateChartOfAccSuccess = (data) => ({
    type: UPDATE_CHARTOFACC_SUCCESS,
    payload: data,
  });
  
  export const updateChartOfAccFail = (error) => ({
    type: UPDATE_CHARTOFACC_FAIL,
    payload: error,
  });
  
  export const deleteChartOfAcc = (data, auth_user) => ({
    type: DELETE_CHARTOFACC,
    payload: data,
    auth_data: auth_user,
  });
  
  export const deleteChartOfAccSuccess = (data) => ({
    type:DELETE_CHARTOFACC_SUCCESS,
    payload: data,
  });
  
  export const deleteChartOfAccFail = (error) => ({
    type: DELETE_CHARTOFACC_FAIL,
    payload: error,
  });
  
  
  