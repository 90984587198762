import React, { useRef, useEffect, useState } from 'react';
// import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem, CardTitle, Collapse, Table } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Form } from 'react-bootstrap';
import Select from "react-select"
import "./Processing.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from "axios";
import accessToken from '../../../helpers/jwt-token-access/accessToken';
import { result } from 'lodash';
import FormControl from "@mui/material/FormControl";




const Processing = (props) => {
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [divopen, setdivopen] = useState(false);
  const [boxdisplay, setboxdisplay] = useState("none");
  const [carddisplay, setcarddisplay] = useState("block");

  const [boxdetails, setboxdetails] = useState({});
  const [details1, setdetails1] = useState("none");
  const [details2, setdetails2] = useState("none");
  const [details3, setdetails3] = useState("none");
  const [details4, setdetails4] = useState("none");
  const [details5, setdetails5] = useState("none");
  const [details6, setdetails6] = useState("none");

  const [one, setone] = useState("none");
  const [two, settwo] = useState("none");
  const [three, setthree] = useState("none")
  const [four, setfour] = useState("none")

  const [col5, setcol5] = useState(false)
  const [col6, setcol6] = useState(false)
  const [col7, setcol7] = useState(false)
  const [col8, setcol8] = useState(false)

  const [searchKey, setSearchKey] = useState('');
  const [stateOrder, setStateOrder] = useState(-1);
  const [processColor, setProcessColor] = useState('white');
  const [details, setDetails] = useState([]);

  const [selectedProcess, setSelectedProcess] = useState(null);
  const [showStatus, setShowStatus] = useState(false);
  const [showFields, setShowFields] = useState(false);
  const [processOrder, setProcessOrders] = useState([]);


  //  SEARCHING
  function searchOrder(e) {
    setStateOrder(-1);
    setProcessColor('white');

    if (e.target.value) {
      axios
        .get(`${API_URL}dashboard/processing/search?key=` + e.target.value, {
          headers: {
            "x-access-token": accessToken,
          },
        }).then((res) => {

          let result = res.data.data;

          setDetails(result);
        })
    } else {

      setDetails([]);
    }
  }


  // ORDER DETAILS LISTING
  function fetch_Order(el) {
    axios
      .get(`${API_URL}dashboard/processing_order?_id=${el._id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        let result = res.data.data;

        setProcessOrders(result);

      })
  }


  // SUBMIT
  function handleValidSubmit() {

  }

  function handleoptions(selected) {

    if (selected.value === 1) {
      setone("block")
      settwo("none")
      setthree("none")
      setfour("none")
    }

    if (selected.value === 2) {
      setone("none")
      settwo("block")
      setthree("none")
      setfour("none")
    }

    if (selected.value === 3) {
      setone("none")
      settwo("none")
      setthree("block")
      setfour("none")
    }

    if (selected.value === 4) {
      setone("none")
      settwo("none")
      setthree("none")
      setfour("block")
    }

  }








  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='container-fluid'>
          <Row>
            <Col xl="12">
              <h1 className='singleorder'>Processing</h1>

              <Row>
                <Col md="6">
                  {/* <Card>
                    <CardBody style={{ height: "100vh", overflow: "auto" }}> */}
                  <FormControl style={{ width: "100%" }}>
                    <div className="srchcol">
                      <input
                        type="text"
                        className="form-control"
                        // placeholder=' Search/Scan- OrderID , MobileNumber , QRcode'
                        placeholder='Please enter OrderID/ Mobile number'
                        value={searchKey}
                        onChange={(e) => {
                          setSearchKey(e.target.value);
                          searchOrder(e)
                        }}
                      />

                      {/* <span className="uil-search">
                        <i class="fa fa-qrcode mx-1"></i></span>
 */}
                    </div>
                  </FormControl>


                  <Row>
                    {details &&
                      details?.map((el, index) => {

                        return (
                          <Col md="4" className='mt-2'>
                            <div class="card"
                              onClick={() => {
                                setStateOrder(index);
                                setDetails([el]);
                                setProcessColor("#ffe0cf");
                                fetch_Order(el);
                                setSelectedProcess(el);
                                setShowStatus(true);
                              }}
                              style={{
                                backgroundColor: processColor
                              }}
                            >
                              <div class="card-body">
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                  <h6 class="mb-0 mt-1">
                                    <span>
                                      <span>{el?.order_number}</span>
                                    </span>
                                  </h6>
                                  <p class="text-muted mb-0"><i style={{ color: "darkorange" }} class='fas fa-box-open mx-1'></i>{el?.total_weight}KG</p>


                                </div>
                                <p style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} class="text-muted mt-1 mb-0">
                                  <span class="text-success me-1">{el?.country_from?.name}</span>
                                  <i style={{ color: "darkorange", fontSize: "1rem" }} class="fa fa-plane mx-2"> </i>
                                  <span class="text-success me-1">{el?.country_to?.name}</span>
                                </p>
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                  <p class="text-muted mb-0">{el?.sender?.name}</p>
                                </div>

                              </div>
                            </div>
                          </Col>
                        )
                      })
                    }


                  </Row>
                  <hr />

                  <AvForm onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>
                    <Row>
                      {showStatus && (
                        <Col md="6">

                          <Select
                            name='itemType'
                            placeholder='Status'
                            onChange={(selected) => {
                              handleoptions(selected);
                              setShowFields(true);
                            }}
                            options={[
                              { label: "Packing", value: 1 },
                              { label: "Dispatch", value: 2 },
                              { label: "Warehouse", value: 3 },
                              { label: "Receive", value: 4 }
                            ]}
                            classNamePrefix="select2-selection"
                          />

                        </Col>
                      )}
                    </Row>
                  </AvForm>

                  <AvForm onValidSubmit={(e, v) => { handleValidSubmit(e, v) }}>
                    {showFields && (
                      <Row className='mt-3'>
                        <div style={{ display: `${one}` }}>
                          <AvForm>
                            <Row>
                              <Col md="4">
                                <AvField
                                  name="rate"
                                  placeholder="Remark"
                                  type="number"
                                />
                              </Col>

                              <Col md="4">
                                <AvField
                                  name="rate"
                                  placeholder="Rate"
                                  type="number"
                                />
                              </Col>

                              <Col md="4">
                                <AvField
                                  name="rate"
                                  placeholder="Date"
                                  type="date"
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col md="12">
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "right", cursor: "pointer" }} className='mt-2'>

                                  <Button
                                    color='primary'
                                    type='submit'
                                  >
                                    submit
                                  </Button>

                                </div>
                              </Col>

                            </Row>
                          </AvForm>
                        </div>

                        <div style={{ display: `${two}` }}>
                          <AvForm>
                            <Row>
                              <Col md="4">
                                <AvField
                                  name="rate"
                                  placeholder="Remark"
                                  type="number"
                                />
                              </Col>

                              <Col md="4">
                                <AvField
                                  name="rate"
                                  placeholder="Rate"
                                  type="number"
                                />
                              </Col>

                              <Col md="4">
                                <AvField
                                  name="rate"
                                  placeholder="Date"
                                  type="date"
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col md="12">
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "right", cursor: "pointer" }} className='mt-2'>

                                  <Button
                                    color='primary'
                                    type='submit'
                                  >
                                    submit
                                  </Button>

                                </div>
                              </Col>

                            </Row>
                          </AvForm>
                        </div>

                        <div style={{ display: `${three}` }}>
                          <AvForm>
                            <Row>
                              <Col md="4">
                                <AvField
                                  name="rate"
                                  placeholder="Remark"
                                  type="number"
                                />
                              </Col>

                              <Col md="4">
                                <AvField
                                  name="rate"
                                  placeholder="Rate"
                                  type="number"
                                />
                              </Col>

                            </Row>

                            <Row>
                              <Col md="12">
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "right", cursor: "pointer" }} className='mt-2'>

                                  <Button
                                    color='primary'
                                    type='submit'
                                  >
                                    submit
                                  </Button>

                                </div>
                              </Col>

                            </Row>
                          </AvForm>
                        </div>

                        <div style={{ display: `${four}` }}>
                          <AvForm>
                            <Row>
                              <Col md="4">
                                <AvField
                                  name="rate"
                                  placeholder="Remark"
                                  type="number"
                                />
                              </Col>

                              <Col md="4">
                                <AvField
                                  name="rate"
                                  placeholder="Rate"
                                  type="number"
                                />
                              </Col>

                            </Row>

                            <Row>
                              <Col md="12">
                                <div style={{ display: "flex", alignItems: "center", justifyContent: "right", cursor: "pointer" }} className='mt-2'>

                                  <Button
                                    color='primary'
                                    type='submit'
                                  >
                                    submit
                                  </Button>

                                </div>
                              </Col>

                            </Row>
                          </AvForm>
                        </div>

                      </Row>
                    )}
                  </AvForm>


                  {/* </CardBody>
                  </Card> */}
                </Col>

                {/* =================================================================================================== */}

                {selectedProcess &&
                  <Col md="6" style={{ verticalAlign: "right" }}>
                    <Card>
                      {/* <CardBody style={{ height: "100vh", overflow: "auto" }}> */}
                      <AvForm
                        className="needs-validation"
                      >

                        <div className="col-xl-9" style={{ marginBottom: "30px", paddingRight: "10px" }}>
                          <h6 style={{ fontSize: "16px", fontWeight: "600" }}>List Of Orders For Processing</h6>
                        </div>

                        {processOrder &&
                          processOrder?.map((ordr, index) => {
                            return (
                              <div className="table-responsive">
                                <Table className="table mb-0 borderd">
                                  <thead className="table-light">
                                    <tr>
                                      <th>Order No</th>
                                      <th>Name</th>
                                      <th style={{ textAlign: "center" }} >TO</th>
                                      <th>Boxes</th>
                                    </tr>
                                  </thead>

                                  <tbody>

                                    <tr>
                                      <td style={{ cursor: "pointer", paddingLeft: "0px" }}  >
                                        <IconButton
                                          aria-label="expand row"
                                          size="small"
                                          onClick={() => {
                                            setcol5(!col5)
                                          }}
                                        >
                                          {col5 ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
                                        </IconButton><span>{ordr?.order_number}</span>
                                      </td>

                                      {ordr?.Name &&
                                        ordr?.Name?.map((nme, index) => {
                                          return (
                                            <><td >{nme?.name}</td></>
                                          )
                                        })
                                      }

                                      {ordr?.country_to &&
                                        ordr?.country_to?.map((to, indx) => {
                                          return (
                                            <>
                                              <td style={{ textAlign: "center" }} >{ordr?.country_from?.name}
                                                {/* <i style={{ color: "darkorange", fontSize: "1rem" }} class="fa fa-plane mx-2"> </i> */}
                                                {to?.name}</td>
                                            </>
                                          )
                                        })
                                      }
                                      <td >{ordr?.no_of_boxes}</td>
                                      <td style={{ textAlign: "right", }}>
                                      </td>
                                    </tr>

                                    <td colSpan="5">
                                      <Collapse isOpen={col5}>

                                        <Card>
                                          <CardBody>

                                            <div className="table-responsive">
                                              <Table className="table table-sm m-0">
                                                <thead>
                                                  <tr>
                                                    <th>Box No.</th>
                                                    <th>Cargo Type</th>
                                                    <th>Weight</th>
                                                    <th>Status</th>
                                                  </tr>
                                                </thead>

                                                {ordr?.boxes &&
                                                  ordr?.boxes?.map((bx, index) => {

                                                    return (
                                                      <tbody>
                                                        <tr>
                                                          <th scope="row">{bx?.box_number}</th>
                                                          <td>Air Cargo</td>
                                                          <td>{bx?.box_weight}</td>
                                                          <td>Processing</td>
                                                        </tr>

                                                      </tbody>

                                                    )
                                                  })
                                                }


                                              </Table>
                                            </div>
                                          </CardBody>
                                        </Card>
                                      </Collapse>
                                    </td>

                                  </tbody>
                                </Table>
                              </div>

                            )
                          })
                        }

                      </AvForm>

                      {/* </CardBody> */}
                    </Card>
                  </Col>

                }

              </Row>

            </Col>
          </Row>

        </div>
      </div>
    </React.Fragment>
  )
}

export default Processing