import React, { useRef, useEffect, useState } from 'react';
// import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem, CardTitle, Collapse, Table } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Form } from 'react-bootstrap';
import Select from "react-select"
import "./BulkOrder.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from "axios";
import accessToken from '../../../helpers/jwt-token-access/accessToken';
import FormControl from '@mui/material/FormControl';
import { result, set } from 'lodash';
import toastr, { error, options } from "toastr";
import ReactDOM from "react-dom";
import { NoBackpackSharp } from '@mui/icons-material';
import { Box } from '@mui/material';
import SweetAlert from "react-bootstrap-sweetalert";
import invoice from "../invoice.svg"


const BulkOrder = (props) => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

    const [boxdetails, setboxdetails] = useState({});
    const [stateorder, setStateorder] = useState(-1);

    const [one, setone] = useState("none");
    const [two, settwo] = useState("none");
    const [three, setthree] = useState("none");
    const [four, setfour] = useState("none");

    const [col5, setcol5] = useState()

    const [order, setOrder] = useState([]);
    const [detailsBox, setDetailsBox] = useState([]);
    const [orderObject, setOrderObject] = useState({});
    const [orderObj, setOrderObj] = useState({});
    const [masterObject, setmasterObject] = useState({ orders: [], boxes: [] });

    const [statusOptions, setStatusOptions] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const [selectedOrders, setselectedOrders] = useState({ orders: [] });
    const [showStatus, setshowStatus] = useState(false);
    const [showFields, setshowFields] = useState(false);
    const [searchKey, setsearchKey] = useState('');

    const [errors, setErrors] = useState({
        statusError: "",
    });

    const formRef = useRef();


    useEffect(() => {
        fetch_all_status();
    }, []);


    const fetch_all_status = () => {
        axios
            .get(`${API_URL}delivery/status`, {
                headers: {
                    "x-access-token": accessToken
                }
            }).then((res) => {
                let status_data = res.data.data;
                // if (selectedOrders.or)
                setStatusOptions([{
                    label: status_data?.ship_status_name,
                    value: status_data?._id
                }])
            })
    }



    function handleClickOpen(selected, name) {
        switch (name) {
            case "ship_status_name":
                setSelectedStatus(selected);
                setOrderObject({
                    ...orderObject,
                    ship_status_name: selected.value,
                });
                break;

            default:
                break;
        }
        if (selected.value === 0) {
            setone("block");
        }

    }


    function searchOrder(e) {
        setStateorder(-1)

        if (e.target.value) {
            axios
                .get(`${API_URL}track/search?value=` + e.target.value, {
                    headers: {
                        "x-access-token": accessToken,
                    }
                })
                .then((res) => {

                    let result = res.data.data;

                    setOrder(result);

                    result.orders &&
                        result.orders.map((item, index) => {

                            setOrderObj(item);

                        })

                })
        } else {

            setOrder([]);
        }
    }


    function fetch_Box(el) {
        setOrderObject({ orders: el._id })
        axios
            .get(`${API_URL}track/boxes?_id=${el?._id}&uniqueId=${el.order_number}`, {
                headers: {
                    "x-access-token": accessToken
                }

            }).then((res) => {

                let result = res.data.data;
                el.boxes = result;
                // if (!selectedOrders.orders.includes(el)) {
                //     setselectedOrders({ orders: [...selectedOrders.orders, el] })
                //     let orderIds = masterObject.orders
                //     orderIds.push(el._id)
                //     setmasterObject({ boxes: orderIds })
                // }
                if (!selectedOrders.orders.includes(el)) {
                    const updatedSelectedOrders = { orders: [...selectedOrders.orders, el] };
                    setselectedOrders(updatedSelectedOrders);
                    
                    const updatedmasterObject = { ...masterObject}
                    updatedmasterObject.boxes.push(el._id);
                    setmasterObject(updatedmasterObject)
                }
                setDetailsBox(result);
            })
    }


    function handleValidSubmit() {
        if (masterObject.boxes.length <= 0) return toastr.error('Please select an order');
        axios
            .put(`${API_URL}delivery/bulk`, masterObject, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                if (res.data.status === 200) {
                    toastr.success("Status updated successfully");
                    //formRef.current.reset();
                    setshowFields(false);
                    setmasterObject({ orders: [] });
                    setselectedOrders({ orders: [] });
                    setshowStatus(false);
                    setOrder([]);
                    setsearchKey('');

                } else toastr.error("Failed to update status");
            })
            .catch((err) => {
                toastr.error(err.response.data.message);
                return;
            });



    }

    function deleteOrder(el) {
        let prevOrders = selectedOrders.orders;

        setselectedOrders({ orders: prevOrders.filter(item => item != el) });
        setmasterObject({ orders: masterObject.orders.filter(item => item !== el._id) });
    }


    return (
        <React.Fragment>


            <div className='page-content'>
                <div className='container-fluid'>
                    <Row>
                        <Col md="6">
                            <h1 className='bulkorder'>Bulk Order Delivery</h1>

                            <FormControl style={{ width: "100%" }} >
                                <div className="srchcol">
                                    <input
                                        type="text"
                                        className="form-control"
                                        value={searchKey}
                                        placeholder='Please enter the Order ID/Order-No'
                                        onChange={(e) => {
                                            setsearchKey(e.target.value)
                                            searchOrder(e)
                                        }}
                                    />
                                    <button class="srchbtn"><i class="fab fa-sistrix"></i></button>
                                </div>
                            </FormControl>

                            <Row>
                                <Col md="6" className='mt-1'>
                                    <Card className='maincard'>
                                        {order?.orders &&
                                            order?.orders?.map((el, index) => {

                                                return (
                                                    <Card className='resultcard'

                                                        onClick={() => {
                                                            fetch_Box(el)
                                                            setStateorder(index)
                                                            setshowStatus(true);
                                                            settwo("block")
                                                            setthree("block")
                                                            setfour("block")
                                                        }}
                                                        style={{
                                                            background: stateorder === index ? '#ffe0cf' : 'white'
                                                        }}
                                                    >
                                                        <CardBody className='resultcardbody' >
                                                            <div className='resultmain'>
                                                                <img className='resulticon' src={invoice} alt="image" />
                                                                <p className='detailsp mx-3 mt-2'>Order-No: {el?.order_number}<br /><span style={{ fontSize: ".9rem", fontWeight: "600" }}>Destination <span style={{ color: "red" }}>{el?.country_to?.name}</span></span></p>
                                                                <h5 className='resultweight' style={{ right: "1.5rem", top: "2.5rem" }}>{el?.total_weight}KG</h5>
                                                            </div>

                                                        </CardBody>
                                                    </Card>

                                                )
                                            })

                                        }
                                    </Card>
                                </Col>

                            </Row>
                            <hr />

                            <AvForm onValidSubmit={(e, v) => { handleValidSubmit(e, v); }}>
                                <Row className='m-0 '>
                                    {
                                        showStatus ? (
                                            <Col md="6" className='fields'>

                                                <Select
                                                    name='status'
                                                    placeholder='Status'
                                                    onChange={(selected) => {
                                                        setmasterObject({ ...masterObject, status: selected.value })
                                                        setshowFields(true)
                                                    }}
                                                    options={statusOptions}
                                                    classNamePrefix="select2-selection"
                                                />

                                            </Col>
                                        ) : ""
                                    }

                                    {
                                        showFields &&
                                        <>
                                            <Col md="6" className='fields'>
                                                <AvField
                                                    name="location"
                                                    placeholder="Location"
                                                    onChange={(e) => { setmasterObject({ ...masterObject, location: e.target.value }) }}
                                                    type="text"
                                                />
                                            </Col>

                                            <Col md="6" className='fields'>
                                                <AvField
                                                    name="date"
                                                    onChange={(e) => { setmasterObject({ ...masterObject, date: e.target.value }) }}
                                                    placeholder="Date"
                                                    type="date"
                                                />
                                            </Col>


                                            <Col md="6" className='fields'>
                                                <AvField
                                                    name="remarks"
                                                    placeholder="Remark"
                                                    type="textarea"
                                                    onChange={(e) => { setmasterObject({ ...masterObject, remarks: e.target.value }) }}
                                                />
                                            </Col>

                                            <Col md="4" className='fields'>
                                                <Button color='primary' type='submit' style={{}}>Submit</Button>

                                            </Col>
                                        </>
                                    }

                                </Row>
                            </AvForm>

                            {/* </CardBody> */}
                            {/* </Card> */}
                        </Col>

                        {/* ======================================================================================================================================== */}
                        {selectedOrders.orders.length > 0 ? (
                            <Col md="6" style={{ display: `${four}` }}>
                                <h1 className='bulkorder' >Box Details</h1>
                                {selectedOrders?.orders.map((ordr, index) => {

                                    return (
                                        <Table>
                                            <Box sx={{ width: "100", backgroundColor: "white", borderTopWidth: "0px" }}>
                                                <tr>
                                                    <td style={{ borderTopWidth: "0px" }}>Order No: {ordr?.order_number}</td>
                                                    <td style={{ borderTopWidth: "0px" }}>Destination: {ordr?.country_to?.name}</td>
                                                    <td style={{ borderTopWidth: "0px" }}>Cargo Type: {ordr?.cargo_type?.cargo_Type}</td>
                                                    <td style={{ borderTopWidth: "0px" }}><i class="fas fa-box-open mx-1" style={{ color: "darkorange" }}></i>{ordr?.no_of_boxes}</td>
                                                    <div
                                                        type="text"
                                                        onClick={() => {
                                                            if (col5 === ordr._id) setcol5(1)
                                                            else setcol5(ordr._id)
                                                        }}
                                                        style={{ color: "light", cursor: "pointer", position: "absolute", right: "3rem", top: "1.rem", borderTopWidth: "0px" }}
                                                    >
                                                        {!col5 ? <i class="fa fa-plus mx-2"></i> : <i class="fa fa-minus mx-2"></i>}
                                                    </div>
                                                    <td style={{ borderTopWidth: "0px" }}>
                                                        <div style={{ color: "light", cursor: "pointer", position: "absolute", right: "2rem", top: "1.rem" }}
                                                        >
                                                            <i
                                                                className="far fa-trash-alt"
                                                                style={{ fontSize: "1em", cursor: "pointer" }}
                                                                onClick={() => {
                                                                    deleteOrder(ordr)
                                                                }}
                                                            ></i>
                                                        </div>
                                                    </td>
                                                </tr>

                                                <Collapse isOpen={col5 === ordr._id ? true : false} >
                                                    <Table >
                                                        <thead className="table-light">
                                                            <tr>
                                                                <th>Box No.</th>
                                                                <th>Weight</th>
                                                                <th>Packed Items</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>

                                                        <tbody >

                                                            {ordr.boxes &&
                                                                ordr.boxes.map((bx, index) => {

                                                                    return (
                                                                        <tr>
                                                                            <td>{bx?.box_number}</td>
                                                                            <td>{bx?.box_weight}</td>
                                                                            <td>{bx?.packed_items}</td>
                                                                            <td style={{ cursor: "pointer" }}>{bx?.shipment_status?.ship_status_name}</td>
                                                                        </tr>

                                                                    )
                                                                })
                                                            }

                                                        </tbody>
                                                    </Table>
                                                </Collapse>
                                            </Box>
                                            {/* </tbody> */}
                                        </Table>
                                    )
                                })}
                            </Col>
                        ) : ''}

                    </Row>

                </div>
            </div>
        </React.Fragment >
    )
}

export default BulkOrder;

