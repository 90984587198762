import React, { useState, useEffect } from "react";
import $ from "jquery";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import Select from "react-select";
import { CSVLink } from "react-csv";
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Col, Label, Button, Card, CardBody, Row, ToastHeader } from 'reactstrap';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { MDBDataTable } from "mdbreact";
import {
  getFirstday,
  getDate

} from "../../../helpers/globalFunctions";
import "./style.scss";


const MonthlyReport = (props) => {

  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [total, setTotal] = useState(0);

  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [monthObject, setMonthObject] = useState({});
  const [order, setOrder] = useState([]);


  useEffect(() => {
    handleTableData();
    fetch_all_branch();
    fetch_all_country();
  }, []);


  const fetch_all_branch = () => {
    axios
      .get(`${API_URL}reports/branch_box`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log("res", res);
        var branch_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.branch_name,
              value: el._id,
            };
          });

        setBranchOptions([
          {
            options: branch_data,
          },
        ]);
      });
  }



  const fetch_all_country = () => {
    axios
      .get(`${API_URL}newStates/countries`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var country_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setCountryOptions([
          {
            options: country_data,
          },
        ]);
      });
  };


  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "country":
        setSelectedCountry(selected);
        setMonthObject({
          ...monthObject,
          country: selected.value,
        });
        break;

      case "branch":
        setSelectedBranch(selected);
        setMonthObject({
          ...monthObject,
          branch: selected.value,
        });
        break;


      default:
        break;
    }

  }


  function handleTableData() {

  }


  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    //date validation
    let country_id = searchData?.country_id ? searchData.country_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    handleTableData(date1, date2, country_id);

  };

  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedCountry(null);
    setSelectedBranch(null);
    handleTableData();
  };


  const handleSearch = () => {
    let country_id = searchData?.country_id ? searchData.country_id : "";
    let branch_id = searchData?.branch_id ? searchData.branch_id : "";
    let branch_name = searchData?.branch_name ? searchData.branch_name : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    handleTableData(date1, date2, country_id, branch_id, branch_name);
  };



 return (
    <React.Fragment>
    <div className='page-content'>
        <div className='container-fluid'>
        <Breadcrumb title="Home" breadcrumbItem="Monthly Reports" />
        <Row>
        <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                    }}
                  >
                    <Row>
                    <Col md="2">
<div className="mb-3">
<Label htmlFor="validationCustom05">From</Label>
<input
className="form-control"
type="date"
id="from_date"
name="from_date"


/>
</div>
</Col>

<Col md="2">
<div className="mb-3">
<Label htmlFor="validationCustom05">To</Label>
<input
className="form-control"
type="date"
id="to_date"
name="to_date"
value={searchData?.to_date}
min={searchData?.from_date}
onChange={handleDate}
/>
</div>
</Col>

                    <Col md="2">
                        <div className="mb-3" >
                          <Label>Branch</Label>
                          <Select
                            name='Branch'
                            placeholder='Select'
                            value={selectedBranch}
                            validate={{required:{value:true}}}
                            onChange={(selected) => {
                               handleSelectChange(selected, "branch")
                            }}
                          options={branchOptions}
                            classNamePrefix="select2-selection"
                            />
                            <p className='text-danger'
                            style={{fontSize:"11px"}}>
                            </p>
                        </div>
                        </Col>

                        <Col md="2">
                        <div className="mb-3" >
                          <Label>Country</Label>
                          <Select
                            name='Country'
                            placeholder='Select'
                            value={selectedCountry}
                            onChange={(selected) => {
                               handleSelectChange(selected, "country");
                            }}
                            options={countryOptions}
                            classNamePrefix="select2-selection"
                            />
                            <p className='text-danger'
                            style={{fontSize:"11px"}}>
                            </p>
                        </div>
                        </Col>

                        <Col md="3" className="mt-4" style={{ paddingTop: "5  px" }}>
                                    <Button className="mx-2"
                                    color="primary"
                                    type="submit"
                                    >
                                    Search
                                    </Button>

                                    <Button className="mx-2"
                                    color="success"
                                    type="submit"
                                    >
                                        Export
                                    </Button>

                                    <Button color="danger" type="reset" onClick={() => reset()}>
                                    Reset
                                    </Button>
                                </Col>
                </Row>
              </AvForm>
            </CardBody>
          </Card>
        </Col>
        </Row>

        <Row>
            <Col md="12">
              <Card>
                <CardBody>
               
                <table className="table table-bordered" id="tableReport"
                
                  >
                <thead>
                      <tr>
                        <th></th>
                        <th style={{textAlign:"center"}} ></th>
                        <th style={{textAlign:"center"}} colSpan="2" width="28%">Air</th>
                        <th style={{textAlign:"center"}} colSpan="2" width="25%">Sea</th>
                        <th style={{textAlign:"center"}} colSpan="2" width="25%">Road</th>
                      </tr>

                    <tr>
                    <td style={{textAlign:"center", fontWeight:'bold'}} width="10%">#</td>
                    <td style={{textAlign:"center", fontWeight:'bold'}}>Month</td>
                    <td style={{textAlign:"center", fontWeight:'bold'}}>Air Orders</td>
                    <td style={{textAlign:"center", fontWeight:'bold'}} >Air Amount</td>
                    <td style={{textAlign:"center", fontWeight:'bold'}}>Sea Orders</td>
                    <td style={{textAlign:"center", fontWeight:'bold'}}>Sea Amount</td>
                    <td style={{textAlign:"center", fontWeight:'bold'}}>Road Orders</td>
                    <td style={{textAlign:"center", fontWeight:'bold'}}>Road Amount</td>
                    </tr>
                    </thead>

                    <tbody>
                    <tr>
                    <td style={{textAlign:"center"}}>1</td>
                    <td style={{textAlign:"center"}}>01-05-2022</td>
                    <td style={{textAlign:"left"}}>100</td>
                    <td style={{textAlign:"left"}}>100</td>
                    <td style={{textAlign:"left"}}>1000</td>
                    <td style={{textAlign:"left"}}>20000</td>
                    <td style={{textAlign:"left"}}>3000</td>
                    <td style={{textAlign:"left"}}>100000</td>
                    </tr>

                    </tbody>
                    </table>
                </CardBody>
              </Card>
            </Col>
          </Row>  
        </div>
        </div>

    </React.Fragment>

  )
}
export default MonthlyReport;