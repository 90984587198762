import React, { useEffect, useRef, useState } from 'react';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
import axios from 'axios';
import accessToken from '../../../helpers/jwt-token-access/accessToken';
import toastr from 'toastr';
import { MDBDataTable } from "mdbreact";
import "./CargoType.scss";
import SweetAlert from 'react-bootstrap-sweetalert';







function CargoType() {

  const formRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [selectedCargoType, setSelectedCargoType] = useState(null);
  const [cargoObject, setCargoObject] = useState({});

  const [cargoIdToBeUpdated, setCargoIdToBeUpdated] = useState(null);
  const [cargoIdToBeDeleted, setCargoIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);

  const [errors, setErrors] = useState({ cargoname: "" })
  const [cargoForTable, setCargoForTable] = useState([]);

  useEffect(() => {
    handleTableData();
  }, []);


  // onChange
  function handleSelectChange(selected) {
    setCargoObject({ ...cargoObject, ["cargo_Type"]: selected.value });
    setSelectedCargoType({ label: selected.label, value: selected.label });
    setErrors({ ...errors, cargoname: "", })
  }


  // Input
  function handleInput(e, item) {
    let name = e.target.name;
    let value = e.target.value;
    setCargoObject({ ...cargoObject, [name]: value })
  }


  // upload image
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("cargo_type_icon", e.target.files[0]);
    axios
      .post(`${API_URL}cargoType/icon`, fd, {
        headers: {
          "x-access-token": accessToken,
        },

      })
      .then((response) => {
        if (response.data.status === "success") {
          setCargoObject({
            ...cargoObject,
            [`cargo_type_icon`]: response.data.file.filename
          })
          setErrors({
            ...errors,
            imageErrors: "",
          });
        }
        else {
          toastr.error(response.data.message)
        }

      });
  };


  // delete image
  const deleteImage = () => {
    setCargoObject({
      ...cargoObject,
      [`cargo_type_icon`]: "",
    });
  };


  // list 
  function handleTableData() {
    axios
      .get(`${API_URL}cargoType/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        var result = res.data.data;

        let cargoData = [];

        result.map((item, index) => {

          item.id = index + 1;
          item.rate = parseFloat(
            item.handling_rate
          ).toFixed(3);

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i className="uil-edit-alt"
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  marginLeft: "0.4rem",
                  marginRight: "0.4rem"
                }}
                onClick={() => {
                  onTop();
                  preUpdateCargo(item)
                }}
              ></i>
              <i className="uil-trash-alt"
                style={{ fontSize: "1rem", cursor: "pointer" }}
                onClick={() => {
                  setCargoIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true)
                }}

              ></i>
            </div>
          )

          cargoData.push(item);
        });
        setCargoForTable(cargoData);
      })
  }


  // TABLE
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150
      },
      {
        label: "Cargo Type",
        field: "cargo_Type",
        sort: "asc",
        width: 150
      },
      {
        label: "Cargo Sub Type",
        field: "cargo_sub_type",
        sort: "asc",
        width: 150
      },
      {
        label: "Handling Rate",
        field: "rate",
        sort: "asc",
        width: 150
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150
      },
    ],
    rows: cargoForTable,
  }


  // ontop
  const onTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }


  // update
  const preUpdateCargo = (item) => {
    setCargoIdToBeUpdated(item._id);

    let type = item.cargo_Type;
    let typeValue = {
      label: type
    }
    setSelectedCargoType(typeValue);

    setCargoObject(item);

  }


  // submit
  const handleValidSubmit = (e, v) => {

    if (selectedCargoType === null) {
      let errorVal = errors;
      if (selectedCargoType === null)
        errorVal.cargoname = "Please Select Cargo Type"
      setErrors({ ...errorVal })
    }
    else {
      if (cargoIdToBeUpdated) {
        axios
          .put(`${API_URL}cargoType/`, cargoObject, {
            headers: {
              "x-access-token": accessToken,
            }
          }).then((res) => {
            if (res.data.status === 200) {
              toastr.success("Cargo Type Updated Successfully");
              formRef.current.reset();
              handleTableData();
              setCargoObject({});
              setCargoIdToBeUpdated(null);
              setErrors({ cargoname: "" });
              setSelectedCargoType(null);
            }
            else {
              toastr.error("Failed to update cargo type");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          })
      }
      else {
        axios
          .post(`${API_URL}cargoType/`, cargoObject, {
            headers: {
              "x-access-token": accessToken,
            },
          }).then((res) => {
            if (res.data.status === 200) {
              toastr.success("Cargo Type added successfully");
              formRef.current.reset();
              handleTableData();
              setSelectedCargoType(null);
              setCargoObject({});
              setErrors({ cargoname: "" })
            }
            else {
              toastr.error("Failed to Add Cargo Type");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });

      };

    };
  };


  // reset
  const reset = () => {
    formRef.current.reset();
    setSelectedCargoType(null);
    setCargoIdToBeDeleted(null);
    setCargoIdToBeUpdated(null);
    setErrors({ cargoname: "" });
    setCargoObject({});
  }


  return (

    <React.Fragment>

      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle='success'
          cancelBtnBsStyle='danger'
          onConfirm={() => {
            axios
              .delete(`${API_URL}cargoType` + "/" + cargoIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              }).then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Cargo Type deleted successfully");
                  formRef.current.reset();
                  handleTableData();
                  setCargoObject({});
                  setCargoIdToBeDeleted(null);
                  setCargoIdToBeUpdated(null);
                  setSelectedCargoType(null);
                } else {
                  toastr.error("Failed to delete");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}


      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumb title="Home" breadcrumbItem="Cargo Type" />

          <Row>
            <Col xl="12">
              <AvForm
                className="needs-validation"
                ref={formRef}
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >

                <Row>

                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Cargo Type</Label>
                      <Select
                        name="cargo_Type"
                        value={selectedCargoType}
                        placeholder="Cargo Type"
                        validate={{ required: { value: true } }}
                        options={[
                          { label: "AIR", value: "AIR" },
                          { label: "D2D", value: "D2D" },
                          { label: "MAIL", value: "MAIL" },
                          { label: "ROAD", value: "ROAD" },
                          { label: "SEA", value: "SEA" },
                        ]}
                        classNamePrefix="select2-selection"
                        onChange={(selected) => {
                          handleSelectChange(selected)
                        }}
                      />
                      <p className='text-danger'
                        style={{ fontSize: "11px" }}
                      >
                        {selectedCargoType === null ? errors.cargoname : ""}
                      </p>
                    </div>

                  </Col>

                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Cargo Sub Type</Label>
                      <AvField
                        name="cargo_sub_type"
                        type="text"
                        placeholder="Cargo Sub Type"
                        errorMessage="Enter Cargo Sub Type"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        value={cargoObject?.cargo_sub_type}
                        onChange={handleInput}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Handling Rate</Label>
                      <AvField
                        name="handling_rate"
                        type="number"
                        placeholder="Rate"
                        errorMessage="Enter Rate"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        value={cargoObject?.rate}
                        onChange={handleInput}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">Image</Label>
                      {cargoObject?.cargo_type_icon ? (
                        <div className='img-wraps'>
                          {cargoObject.cargo_type_icon &&
                            cargoObject.cargo_type_icon.startsWith("http") ? (
                            <img
                              alt=''
                              width="150"
                              height="150"
                              src={`${cargoObject.cargo_type_icon}`}
                            />
                          ) : (
                            <img
                              alt=""
                              width="150"
                              height="150"
                              src={`${API_URL}uploads/cargo_type_icon/${cargoObject?.cargo_type_icon}`}
                            />
                          )
                          }
                          <br /><br />
                          <button
                            className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                            onClick={deleteImage}
                            style={{ width: "150px" }}
                          >
                            Delete
                          </button>

                        </div>
                      ) : (
                        <AvField
                          name="image"
                          type="file"
                          className="form-control"
                          id="validationCustom03"
                          onChange={uploadImage}
                          rows="1"
                        />
                      )}

                    </div>
                  </Col>

                  <Col md="3">
                    <div>
                      {cargoIdToBeUpdated ? (
                        <Button
                          color='primary'
                          type='update'
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          color='primary'
                          type='submit'
                        >
                          Submit
                        </Button>
                      )}

                      <Button
                        color='danger'
                        type='reset'
                        onClick={() => reset()}
                        style={{ marginLeft: "8px" }}
                      >
                        Reset
                      </Button>

                    </div>

                  </Col>

                </Row>

              </AvForm>


            </Col>
          </Row>

          <Row className='mt-1'>
            <Col md="12">
              {/* <Card> */}
              {/* <CardBody> */}
              <MDBDataTable
                bordered
                responsive
                id="citytable"
                data={data}
                entries={20}

              />

              {/* </CardBody> */}
              {/* </Card> */}
            </Col>

          </Row>


        </div>
      </div >

    </React.Fragment>
  )

}


export default CargoType;