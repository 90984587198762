import React from "react";
import { Redirect } from "react-router-dom";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
import DashboardNew from "../pages/Dashboard New/index";
import User from "../pages/User/indexold";
import Staff from "../pages/User/Staff";
import StaffDetails from "../pages/User/Staff/Staff Detail/StaffDetails";
import UserDashboard from "../pages/User/UserDashboard/UserDashboard";

import Company from "../pages/MasterSettings/Company";
import Branch from "../pages/MasterSettings/Manage Branch";
import Privilage from "../pages/MasterSettings/Privilage";
import Rules from "../pages/MasterSettings/Rules";
import Settings from "../pages/MasterSettings/Master_Setting";
import RulesApp from "../pages/MasterSettings/RulesApp";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiImageCropper from "../pages/Ui/ui-image-cropper";

// Forms
import BasicElements from "../pages/Forms/BasicElements";
import FormLayouts from "../pages/Forms/FormLayouts";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/charts-knob";

//Icons

import IconUnicons from "../pages/Icons/IconUnicons";
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

// Delivery
import SingleOrder from "../pages/Delivery/SingleOrder/SingleOrder";
import BulkOrder from "../pages/Delivery/BulkOrder/BulkOrder";

// Packing
import Packing from "../pages/Packing/Packing";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import VerifyOtp from "../pages/Authentication/otpPage";
import ResetPassword from "../pages/Authentication/resetPassword";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Register1 from "../pages/AuthenticationInner/Register";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

// Profile
import UserProfile from "../pages/Authentication/user-profile";




//security
import BlockedIP from "../pages/Security/Blocked IP/BlockedIP";
import UserBlock from "../pages/Security/User Block/UserBlock";
import UserActivityLog from "../pages/Security/User Activity Log/UserActivityLog";


//manage
import AddcrBranch from "../pages/Manage/branch/AddcrBranch";
import CargoType from "../pages/Manage/cargotype/CargoType";
import complaint from "../pages/Manage/complaint/complaint";
import Faq from "../pages/Manage/Faq/Faq";
import statecargo from "../pages/Manage/statecargo/statecargo";
import country from "../pages/Manage/country/country";
import Notification from "../pages/Manage/Notification/Notification";
import Complaintstatus from "../pages/Manage/Complaintstatus/Complaintstatus";
import Faqcategory from "../pages/Manage/Faqcategory/Faqcategory";
import dailyreport from "../pages/Manage/Reports/dailyreport";
import monthlyreport from "../pages/Manage/Reports/monthlyreport";
import ItemCategory from "../pages/Manage/itemcategory/ItemCategory";
import Offercode from "../pages/Manage/Offercode/Offercode";

import AddPacking from "../pages/Manage/packing/AddPacking";
import AddPort from "../pages/Manage/port/AddPort";
import AddVat from "../pages/Manage/vat/AddVat";
import Order from "../pages/Manage/order/Order";
import Processing from "../pages/Manage/Processing/Processing";
import ProcessingTest from "../pages/Manage/Addbook/test";
import Bookingdashboard from "../pages/Manage/Bookingdashboard/Bookingdashboard";
import Newcity from "../pages/Manage/City/Newcity";
import Quickorder from "../pages/Manage/QuickOder/Quickorder";
import Coverage from "../pages/Manage/Coverage/Coverage";




//user
import AddcargoUser from "../pages/User/AddUsers/index";

//customer
import AddCust from "../pages/Customers/Addcustomer/customer";
import ViewCustomer from "../pages/Customers/ViewCustomer/ViewCustomer";
import CustomerDashboard from "../pages/Customers/CustomerDashboard/CustomerDashboard"
import CreditCustomer from "../pages/Customers/Credit Customer/index";



//complaints
import Complaints from "../pages/Complaints/complaints"

//Shipment
//  import  NewShipment  from "../pages/Shipment/Newshipment/newshipment";
import NewShipment from "../pages/Shipment/Newshipment/newshipmentcopy";
import ShipmentUpdate from "../pages/Shipment/ShipmentUpdate/ShipmentUpdate";
import ManifestExport from "../pages/Shipment/ManifestoExport/index";
import PackinglistExport from "../pages/Shipment/PackinglistExport/index";



//Reports
import Daywiseshipment from "../pages/Reports/Dailyshipment/Dailyshipment";
import Monthwiseshipment from "../pages/Reports/Monthlyshipment/Monthlyshipment";
import Branchwisereport from "../pages/Reports/Branchwise/Branchwise";
import Staffwise from "../pages/Reports/Staffwise/Staffwise";
import BranchwiseBoxReport from "../pages/Reports/BranchwiseBoxReport/BranchwiseBoxReport";

import Customerwise from "../pages/Reports/Customerwise/Customerwise";
import OrderReport from "../pages/Reports/OrderReport/index";
import IncomeReport from "../pages/Reports/IncomeReport/index";
/*---------------------
|Account
|---------------------*/
import AccountType from "../pages/Accounts/Account-Type";
import AccountDetails from "../pages/Accounts/AccountDetails";
import Chart from "../pages/Accounts/Chart";
import FundTransfer from "../pages/Accounts/FundTransfer";
import IncomeExpenses from "../pages/Accounts/IncomeExpenses";
import TransactionLog from "../pages/Accounts/TransactionLog";
import ProfitAndLoss from "../pages/Accounts/ProfitAndLoss";
import DayBook from "../pages/Accounts/DayBook";

import Addbooking from "../pages/Manage/Addbook/Addbooking";

//all bookig
import Allbooking from "../pages/AllBooking/booking"
/*---------------------
|Tracking
|---------------------*/
import Tracking from "../pages/Track";

//booking enquiry
import Enquiry from "../pages/Bookingenquiry/bookingenquiry";


/*---------------------
|Reports
|---------------------*/
import StockReport from "../pages/Reports/StockReport";
import Domestic from "../pages/Manage/domestic/Domestic";
import Tranckingnew from "../pages/Manage/Tracking/Tranckingnew";



// Processing
import interNationalProcessing from '../pages/Processing/OrderProcess/International/international';
import interNational from '../pages/Processing/OrderProcess/International/internationalCopy';

import domesticProcessing from '../pages/Processing/OrderProcess/domestic/domestic';
import domestic from '../pages/Processing/OrderProcess/domestic/domesticCopy';

import inBoundProcessing from '../pages/Processing/ProcessBranch/InBound/InBound';
import outBoundProcessing from '../pages/Processing/ProcessBranch/OutBound/OutBound';
import InOutReport from "../pages/Processing/ProcessBranch/InOutReport/index";


const userRoutes = [
  { path: "/dashboard", component: Dashboard },


  // { path: "/user", component: User },
  { path: "/staff", component: Staff },
  // { path: "/staff/:userId", component: StaffDetails },
  // { path: "/user/:userId", component: StaffDetails },
  // { path: "/profile/:userId", component: StaffDetails },

  { path: "/addUser/userId", component: StaffDetails },

  { path: "/company", component: Company },
  // { path: "/branch", component: Branch },
  { path: "/privilage", component: Privilage },
  { path: "/rules", component: Rules },
  { path: "/settings", component: Settings },
  { path: "/rules_app", component: RulesApp },

  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-image-cropper", component: UiImageCropper },

  // Forms
  { path: "/basic-elements", component: BasicElements },
  { path: "/form-layouts", component: FormLayouts },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },
  { path: "/charts-knob", component: ChartsKnob },

  // Icons
  { path: "/icons-unicons", component: IconUnicons },
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },

  // //profile
  { path: "/profile", component: UserProfile },


  // Delivery
  { path: "/delivery/single-order", component: SingleOrder },
  { path: "/delivery/bulk-order", component: BulkOrder },


  // Packing
  { path: "/add-packing", component: Packing },

  // //manage
  { path: "/branches", component: AddcrBranch },
  { path: "/cargotype", component: CargoType },
  { path: "/complaint-category", component: complaint },
  { path: "/Faq", component: Faq },
  { path: "/state", component: statecargo },
  { path: "/country", component: country },
  { path: "/notification", component: Notification },
  { path: "/Faqcategory", component: Faqcategory },
  { path: "/Complaint_status", component: Complaintstatus },
  { path: "/complaint-status", component: Complaintstatus },
  { path: "/reports", component: dailyreport },
  { path: "/monthlyreports", component: monthlyreport },
  { path: "/itemcategory", component: ItemCategory },
  { path: "/offercode", component: Offercode },
  { path: "/packing", component: AddPacking },
  { path: "/port", component: AddPort },
  { path: "/vat", component: AddVat },
  { path: "/order", component: Order },
  { path: "/processing", component: Processing },
  { path: "/processing-test", component: ProcessingTest },
  { path: "/new-booking", component: Addbooking },
  { path: "/bookingdashboard", component: Bookingdashboard },
  { path: "/new-booking-domestic", component: Domestic },
  { path: "/new-tracking", component: Tranckingnew },
  { path: "/new-city", component: Newcity },
  { path: "/Quick-Orders", component: Quickorder },
  { path: "/coverage", component: Coverage},


  { path: "/blockedip", component: BlockedIP },
  { path: "/userblock", component: UserBlock },
  { path: "/UserActivityLog", component: UserActivityLog },


  //users
  { path: "/users", component: AddcargoUser },
  { path: "/addUser/:userId", component: UserDashboard },


  //customers
  { path: "/customers", component: AddCust },
  { path: "/view-customer", component: ViewCustomer },
  { path: "/Customer/:customerId", component: CustomerDashboard },
  { path: "/credit-customers", component: CreditCustomer },


  // shipment
  { path: "/newshipment", component: NewShipment },
  { path: "/shipment-update", component: ShipmentUpdate },
  { path: "/shipment/manifesto-export", component: ManifestExport },
  { path: "/packinglist-export", component: PackinglistExport },



  //all booking
  { path: "/all-booking", component: Allbooking },


  //reports
  { path: "/daywise-shipment-report", component: Daywiseshipment },
  { path: "/monthwise-shipment-report", component: Monthwiseshipment },
  { path: "/branchwise-report", component: Branchwisereport },
  { path: "/staffwise-report", component: Staffwise },
  { path: "/branchwisebox-report", component: BranchwiseBoxReport },

  /*---------------------
|Account
|---------------------*/
  { path: "/account-head", component: AccountType },
  { path: "/account-category", component: AccountDetails },
  { path: "/accounts-chart", component: Chart },
  { path: "/fund-transfer", component: FundTransfer },
  { path: "/income-expense", component: IncomeExpenses },
  { path: "/transaction-log", component: TransactionLog },
  { path: "/transaction-log/:chartId", component: TransactionLog },
  { path: "/profitAndLoss", component: ProfitAndLoss },
  { path: "/day-book", component: DayBook },

  //complaints
  { path: "/add-complaints", component: Complaints },
  /*---------------------

  /*---------------------
  |Tracking
  |---------------------*/
  { path: "/tracking", component: Tracking },

  //bookingenquiry
  { path: "/booking-enquiry", component: Enquiry },

  /*---------------------
  |Reports
  |---------------------*/
  { path: "/stock-report", component: StockReport },
  { path: "/order-report", component: OrderReport},
  { path: "/customer-report", component: Customerwise },
  { path: "/income-report", component: IncomeReport},
  // Proccessing
  { path: "/process-international", component: interNationalProcessing },
  { path: "/international", component: interNational },

  { path: '/process-domestic', component: domesticProcessing },
  { path: '/domestic', component: domestic },

  { path: '/process-outbound', component: outBoundProcessing },
  { path: '/process-inbound', component: inBoundProcessing },
  { path: '/in-out-report', component: InOutReport},



  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },

];

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/verify-otp", component: VerifyOtp },
  { path: "/reset-password", component: ResetPassword },

  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  // { path: "/qr-code/:qrcodeId", component: ViewQrCode },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-register", component: Register1 },
  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/auth-lock-screen", component: LockScreen },
];

export { userRoutes, authRoutes };
