import React, { useRef, useEffect, useState } from 'react';
// import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem, CardTitle, Collapse, Table } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Form } from 'react-bootstrap';
import Select from "react-select"
import "./domestic";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from "axios";
import accessToken from '../../../../helpers/jwt-token-access/accessToken';
import FormControl from '@mui/material/FormControl';
import { result, set } from 'lodash';
import toastr, { error, options } from "toastr";
import ReactDOM from "react-dom";
import { NoBackpackSharp } from '@mui/icons-material';
import { Box } from '@mui/material';
import SweetAlert from "react-bootstrap-sweetalert";
import invoice from "../invoice.svg"






const Domestic = (props) => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

    const [boxdetails, setboxdetails] = useState({});
    const [stateorder, setStateorder] = useState(-1);

    const [one, setone] = useState("none");
    const [two, settwo] = useState("none");
    const [three, setthree] = useState("none");
    const [four, setfour] = useState("none");

    const [col5, setcol5] = useState()

    const [order, setOrder] = useState([]);
    const [detailsBox, setDetailsBox] = useState([]);
    const [orderObject, setOrderObject] = useState({});
    const [orderObj, setOrderObj] = useState({});

    const [masterObject, setmasterObject] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState(null);

    const [selectedOrders, setselectedOrders] = useState({ orders: [] });
    const [showStatus, setshowStatus] = useState(false);
    const [showFields, setshowFields] = useState(false);
    const [searchKey, setsearchKey] = useState('');
    const [allStatus, setallStatus] = useState(null);

    const [errors, setErrors] = useState({
        statusError: "",
    });

    const formRef = useRef();




    useEffect(() => {
        fetch_all_status();
    }, []);


    const fetch_all_status = () => {
        axios
            .get(`${API_URL}process/all-status`, {
                headers: {
                    "x-access-token": accessToken
                }
            }).then((res) => {

                let result = res.data.data;

                result = result.map((el) => {

                    return {
                        label: el.ship_status_name,
                        value: el._id,
                    }
                })
                setallStatus(result);
            })
    }



    function handleClickOpen(selected, name) {
        switch (name) {
            case "ship_status_name":
                setSelectedStatus(selected);
                setOrderObject({
                    ...orderObject,
                    ship_status_name: selected.value,
                });
                break;

            default:
                break;
        }
        if (selected.value === 0) {
            setone("block");
        }

    }


    function handleoptions(selected) {
        if (selected) {
            setshowFields(true);
            setmasterObject({ ...masterObject, status: selected.value });
        }
        else setshowStatus(false);
    }


    function searchOrder(e) {
        setStateorder(-1)

        if (e.target.value) {
            axios
                .get(`${API_URL}track/search?value=` + e.target.value, {
                    headers: {
                        "x-access-token": accessToken,
                    }
                })
                .then((res) => {

                    let result = res.data.data;

                    setOrder(result);

                    result.orders &&
                        result.orders.map((item, index) => {

                            setOrderObj(item);

                        })

                })
        } else {

            setOrder([]);
        }
    }


    function fetch_Box(el) {
        setmasterObject({ ...masterObject, order_id: el._id })
        axios
            .get(`${API_URL}track/boxes?_id=${el?._id}&uniqueId=${el.order_number}`, {
                headers: {
                    "x-access-token": accessToken
                }

            }).then((res) => {

                let result = res.data.data;
                el.boxes = result;
                if (!selectedOrders.orders.includes(el)) {
                    setselectedOrders({ orders: [...selectedOrders.orders, el] })
                    // let orderIds = masterObject
                    // orderIds.push(el._id)
                    // setmasterObject({ orders: orderIds })
                }

                setDetailsBox(result);
            })
    }


    function handleValidSubmit() {
        let bodyData = masterObject;

        axios
            .put(`${API_URL}process/`, bodyData, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                //   if (res.data.status === 200) {
                setshowFields(false);
                setmasterObject({ orders: [] });
                setselectedOrders({ orders: [] });
                setshowStatus(false);
                setOrder([]);
                setsearchKey('');
                toastr.success('Status updated successfully')
                //   } else toastr.error("Failed to update status")
            })
            .catch((err) => console.log(err));

    }

    function deleteOrder(el) {
        let prevOrders = selectedOrders.orders;

        setselectedOrders({ orders: prevOrders.filter(item => item != el) });
        setmasterObject({ orders: masterObject.orders.filter(item => item !== el._id) });
    }

    function deleteOrders(el) {
        let prevBoxes = detailsBox;

        setDetailsBox({ orders: prevBoxes.filter(item => item != el) });
        setmasterObject({ orders: masterObject.boxes.filter(item => item !== el._id) });
    }


    function handleInput(e) {
        let name = e.target.name;
        let value = e.target.value;
        setOrderObject({ ...orderObject, [name]: value })
    }

    return (
        <React.Fragment>

            <div className='page-content'>
                <div className='container-fluid'>
                    <Row>
                        <Col md="6">
                            <h1 className='trackingh1'>Process Domestic</h1>

                            <Card className='trackingcard'>
                                <CardBody className='trackingcard-cardbody'>
                                    <Form className='app-search d-none d-lg-block'
                                        onChange={(e) => {
                                            setsearchKey(e.target.value)
                                            searchOrder(e)
                                        }}
                                        style={{ width: "100%" }}
                                    >
                                        <div className='position-relative'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                placeholder='Search/Scan OrderID , QRcode'
                                            />
                                            <span className='uil-search'>
                                                <i class="fas fa-qrcode mx-1"></i>
                                            </span>
                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>

                            <Row>
                                <Col md="4" className='mt-1'>
                                    <Card className='maincard'>
                                        {order?.orders &&
                                            order?.orders?.map((bx, index) => {

                                                return (
                                                    <Card

                                                        onClick={() => {
                                                            fetch_Box(bx)
                                                            setStateorder(index)
                                                            setshowStatus(true);
                                                            settwo("block")
                                                            setthree("block")
                                                            setfour("block")
                                                        }}
                                                    >
                                                        <CardBody  >
                                                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
                                                                <h6 class="mb-0 mt-1">
                                                                    <span style={{ fontSize: "1.1rem", fontWeight: "700", color: "#282828" }}>
                                                                        <span>{bx.order_number}</span>
                                                                    </span>
                                                                </h6>
                                                                <p class="text-muted mb-0" style={{ fontSize: "1.1rem", fontWeight: "700" }}>
                                                                    <i style={{ color: "darkorange" }} class='fas fa-box-open mx-1'></i>{bx.total_weight}</p>

                                                            </div>
                                                            <p style={{ display: "flex", alignItems: "center", justifyContent: "space-between", fontSize: "0.9rem", fontWeight: "600" }} class="text-muted mt-1 mb-0">
                                                                <span class="me-1">Destination</span>
                                                                <span class="text-danger me-1" style={{ paddingRight: "20px" }}> {bx.country_to.name}</span>
                                                            </p>

                                                        </CardBody>
                                                    </Card>

                                                )
                                            })

                                        }
                                    </Card>
                                </Col>

                            </Row>
                            <hr />

                            <AvForm onValidSubmit={(e, v) => { handleValidSubmit(e, v); }}>
                                <Row className='m-0 '>
                                    {
                                        showStatus ? (
                                            <Col md="6" className='fields'>

                                                <Select
                                                    name='status'
                                                    placeholder='Status'
                                                    // menuPosition='fixed'
                                                    onChange={(selected) => {
                                                        setmasterObject({ ...masterObject, status: selected.value })
                                                        setshowFields(true)
                                                        handleoptions(selected)
                                                    }}
                                                    options={allStatus}
                                                // classNamePrefix="select2-selection"
                                                />

                                            </Col>
                                        ) : ""
                                    }

                                    {
                                        showFields &&
                                        <>
                                            <Col md="6" className='fields'>
                                                <AvField
                                                    name="location"
                                                    placeholder="Location"
                                                    onChange={(e) => { setmasterObject({ ...masterObject, location: e.target.value }) }}
                                                    type="text"
                                                />
                                            </Col>

                                            <Col md="6" className='fields'>
                                                <AvField
                                                    name="date"
                                                    onChange={(e) => { setmasterObject({ ...masterObject, date: e.target.value }) }}
                                                    placeholder="Date"
                                                    type="date"
                                                />
                                            </Col>


                                            <Col md="6" className='fields'>
                                                <AvField
                                                    name="remarks"
                                                    placeholder="Remark"
                                                    type="textarea"
                                                    onChange={(e) => { setmasterObject({ ...masterObject, remarks: e.target.value }) }}
                                                />
                                            </Col>

                                            <Col md="4" className='fields'>
                                                <Button color='primary' type='submit' style={{}}>Submit</Button>

                                            </Col>
                                        </>
                                    }

                                </Row>
                            </AvForm>

                        </Col>

                        {/* ======================================================================================================================================== */}
                        {selectedOrders.orders.length > 0 ? (
                            <Col md="6" style={{ display: `${four}` }}>
                                <h1 className='trackingh1' >Box Details</h1>
                                {selectedOrders?.orders.map((ordr, index) => {

                                    return (
                                        <div className='table-responsive' style={{ backgroundColor: "white", padding: "10px", width: "100" }}>
                                            <Table className='table mb-0 borderd'>
                                                    <thead className='table-light'>
                                                        <tr>
                                                            <th>Order No.</th>
                                                            <th>Destination</th>
                                                            <th>Cargo Type</th>
                                                            <th>Boxes</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>
                                                        <tr>
                                                            <td style={{ cursor: "pointer", paddingLeft: "0px" }}>
                                                                <IconButton
                                                                    aria-label='expand row'
                                                                    size="small"
                                                                    onClick={() => {
                                                                        if (col5 === ordr._id) setcol5(1)
                                                                        else setcol5(ordr._id)
                                                                    }}>
                                                                    {!col5 ? <KeyboardArrowUpIcon /> : <KeyboardArrowRightIcon />}
                                                                </IconButton>
                                                                <span>{ordr?.order_number}</span>
                                                            </td>
                                                            <td>{ordr?.country_to?.name}</td>
                                                            <td>{ordr?.cargo_type?.cargo_Type}</td>
                                                            <td>{ordr?.no_of_boxes}</td>
                                                            <td style={{ borderTopWidth: "0px" }} >
                                                                <div style={{ color: 'light', cursor: "pointer", position: "absolute", right: "2rem", top: "1.rem" }}>
                                                                    <i
                                                                        className='far fa-trash-alt'
                                                                        style={{ fontSize: "1em", cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            deleteOrder(ordr)
                                                                        }}
                                                                    ></i>
                                                                </div>
                                                            </td>

                                                        </tr>

                                                        <td colSpan="5">
                                                            <Collapse isOpen={col5 === ordr._id ? true : false} >
                                                                <Table >
                                                                    <thead className="table-light">
                                                                        <tr>
                                                                            <th>Box No.</th>
                                                                            <th>Weight</th>
                                                                            <th>Packed Items</th>
                                                                            <th>Status</th>
                                                                            <th></th>
                                                                        </tr>
                                                                    </thead>

                                                                    <tbody >

                                                                        {ordr.boxes &&
                                                                            ordr.boxes.map((bx, index) => {

                                                                                return (
                                                                                    <tr>
                                                                                        <td>{bx?.box_number}</td>
                                                                                        <td>{bx?.box_weight}</td>
                                                                                        <td>{bx?.packed_items}</td>
                                                                                        <td>{bx?.shipment_status?.ship_status_name}</td>
                                                                                        <td style={{ borderTopWidth: "0px" }} >
                                                                                            <div style={{ color: 'light', cursor: "pointer", position: "absolute", right: "2rem", top: "1.rem" }}>
                                                                                                <i
                                                                                                    className='far fa-trash-alt'
                                                                                                    style={{ fontSize: "1em", cursor: "pointer" }}
                                                                                                    onClick={() => {
                                                                                                        deleteOrders(bx)
                                                                                                    }}
                                                                                                ></i>
                                                                                            </div>
                                                                                        </td>

                                                                                    </tr>

                                                                                )
                                                                            })
                                                                        }

                                                                    </tbody>
                                                                </Table>
                                                            </Collapse>

                                                        </td>


                                                    </tbody>

                                            </Table>
                                           

                                        </div> 
                                    )
                                })}
                                &nbsp;
                            </Col>
                        ) : ''}

                    </Row>

                </div>
            </div>
        </React.Fragment >
    )
}

export default Domestic;

