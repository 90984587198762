import React, { useState, useEffect } from 'react';
import { Row, Col, BreadcrumbItem, Label, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";
import { MDBDataTable } from 'mdbreact';
import "./style.scss";
import { Modal } from 'react-bootstrap';
import { AvForm } from 'availity-reactstrap-validation';
import axios from 'axios';
import accessToken from '../../../helpers/jwt-token-access/accessToken';
import { getDate, getFirstday } from '../../../helpers/globalFunctions';
import SweetAlert from 'react-bootstrap-sweetalert';
import toastr from "toastr";
import { CSVLink } from 'react-csv';


function PackinglistExport() {

    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

    const [popupData, setPopupData] = useState({});
    const [popupView, setPopupView] = useState(false);

    const [countryOptions, setCountryoptions] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(null);

    const [cargoOptions, setCargoOptions] = useState([]);
    const [selectedCargo, setSelectedCargo] = useState(null);

    const [searchData, setSearchData] = useState({});
    const [packingTable, setPackingTable] = useState([]);

    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false);
    const [packingIdToBeDeleted, setPackingIdToBeDeleted] = useState(null);
    const [dataToBeExported, setDataToBeExported] = useState([]);



    useEffect(() => {
        handleTableData();
        fetch_country();
        fetch_cargo_type();
    }, []);


    function fetch_country() {
        axios
            .get(`${API_URL}newStates/countries`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                var country_data =
                    res.data.data &&
                    res.data.data.map((el) => {

                        return {
                            label: el.name,
                            value: el._id,
                        };
                    });

                setCountryoptions([
                    {
                        options: country_data,
                    },
                ]);
            });
    }

    function fetch_cargo_type() {
        axios
            .get(`${API_URL}cargoType/list`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                var cargo_data =
                    res.data.data &&
                    res.data.data.map((crgo, indx) => {

                        return {
                            label: crgo.cargo_Type,
                            value: crgo._id
                        }
                    })

                setCargoOptions([{ options: cargo_data }])
            })
    }


    function handleTableData(cargo_type = "", country = "", shipmentid = "") {
        var url = `${API_URL}shipment/packinglist?cargotype=` + cargo_type;

        url =
            url +
            "&country=" +
            country +
            "&shipmentid=" +
            shipmentid;

        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let result = res.data.data;
                let Data = [];

                result &&
                    result?.map((item, index) => {

                        item.id = index + 1;

                        item.action = (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <i className='fas fa-trash-alt'
                                    style={{ cursor: "pointer", fontSize: "1em" }}
                                    onClick={() => {
                                        setConfirmDeleteAlert(true);
                                        setPackingIdToBeDeleted(item?._id);
                                    }}
                                ></i>
                            </div>
                        )

                        var exportItem = {};

                        exportItem.Slno = item.id;
                        exportItem.Shipment_ID = item.shipmentid;
                        exportItem.Order_No = item.order_number;
                        exportItem.Boxes = item.no_of_boxes;
                        exportItem.Country = item.recevier_country;
                        exportItem.Cargo_Type = item.cargo_type;

                        dataToBeExported.push(exportItem);

                    })

                setPackingTable(result);

                setDataToBeExported([
                    ...dataToBeExported,
                    {
                        Country: "",

                        Cargo_Type: result?.cargo_type
                    }
                ])

            })
    }


    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Shipment ID",
                field: "shipment_id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Order No",
                field: "order_number",
                sort: "asc",
                width: 150,
            },
            {
                label: "Box",
                field: "no_of_boxes",
                sort: "asc",
                width: 150,
            },
            {
                label: "Receiver Country",
                field: "recevier_country",
                sort: "asc",
                width: 150,
            },
            {
                label: "Cargo Type",
                field: "cargo_type",
                sort: "asc",
                width: 150,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150,
            },
        ],
        rows: packingTable,
    }


    const handleSelectChange = (selected, name) => {
        switch (name) {
            case "country":
                setSelectedCountry(selected);
                setSearchData({
                    ...searchData,
                    country: selected.label
                });
                break;

            case "cargo_type":
                setSelectedCargo(selected);
                setSearchData({
                    ...searchData,
                    cargo_type: selected.label
                });
                break;

            default:
                break;
        }
    }


    function handleSearch() {
        let cargo = searchData?.cargo_type ? searchData?.cargo_type : "";
        let country1 = searchData?.country ? searchData?.country : "";

        handleTableData(cargo, country1);
    }


    function reset() {
        setSelectedCargo(null);
        setSelectedCountry(null);
        setSearchData({});
        handleTableData();
    }


    return (
        <React.Fragment>

            {confirmDeleteAlert ? (
                <SweetAlert
                    title=''
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle='success'
                    cancelBtnBsStyle='danger'
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}shipment/delete_packinglist?_id=${packingIdToBeDeleted}`, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            }).then((res) => {

                                if (res.data.status === 200) {
                                    toastr.success("Data deleted successfully");
                                    handleTableData();
                                } else {
                                    toastr.error("Failed to delete");
                                    return;
                                }
                            })
                            .catch((err) => {
                                toastr.error(err.response.data.message);
                                return;
                            })
                        setConfirmDeleteAlert(false);
                    }}
                    onCancel={() => setConfirmDeleteAlert(false)}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : ""}
            {/* <Modal
                show={popupView}
                isOpen={popupView}
                toggle={() => setPopupView(!popupView)}
                size='small'
                centered={true}
            >

                <div className='modal-header'>
                    <h5 className='modal-title mt-0'>Packing list Details</h5>
                    <button
                        type='button'
                        onClick={() => {
                            setPopupView(false)
                        }}
                        className='close'
                        data-dismiss='modal'
                        aria-label='close'
                    ></button>
                </div>

                <AvForm className='needs-validation'>
                    <div className='modal-body'>
                        <table className='table table-bordered dataTable'>
                            <tr>
                                <td style={{ padding: "10px", textAlign: "left" }}>Order Number</td>
                                <td>: 311 1020</td>
                                <td style={{ paddingLeft: "25px" }}>Country :</td>
                                <td style={{ textAlign: "left" }}>Oman</td>
                            </tr>

                            <tr>
                                <td style={{ padding: "10px", textAlign: "left" }}>Cargo Type</td>
                                <td>: AIR</td>
                                <td style={{ paddingLeft: "25px" }}>No of boxes :</td>
                                <td style={{ textAlign: "left" }}>3</td>
                            </tr>

                            <tr>
                                <td style={{ padding: "10px", textAlign: "left" }}>Item Type :</td>
                                <td >Package</td>
                                <td style={{ padding: "25px" }}>Item Category</td>
                                <td style={{ textAlign: "left" }}>: AC</td>
                            </tr>

                            <tr>
                                <td style={{ padding: "10px", textAlign: "left" }}>Description :</td>
                                <td >Electronics</td>
                                <td style={{ paddingLeft: "25px" }}>Unit :</td>
                                <td style={{ textAlign: "left" }}>KG</td>
                            </tr>

                            <tr>
                                <td style={{ padding: "10px", textAlign: "left" }}>Quantity</td>
                                <td>: 1</td>
                                <td style={{ paddingLeft: "25px" }}>Rate :</td>
                                <td style={{ textAlign: "left" }}>1.5</td>
                            </tr>

                            <tr>
                                <td style={{ padding: "10px", textAlign: "left" }}>Amount :</td>
                                <td style={{ textAlign: "left" }}>200</td>
                            </tr>

                        </table>
                    </div>
                </AvForm>

            </Modal> */}

            <div className='page-content'>
                <div className='container-fluid'>

                    <Row>
                        <Col md="12">
                            <div className='page-titlebox d-flex align-items-center justify-content-between'>
                                {/* <h4 className='mb-0'>Packinglist Export</h4> */}
                                <h4 className='mb-0'></h4>

                                <div className='page-title-right'>
                                    <ol className='breadcrumb m-0'>
                                        <BreadcrumbItem>
                                            <Link to="/dashboard">Home</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <Link to="/newshipment">Shipment</Link>
                                        </BreadcrumbItem>
                                        <BreadcrumbItem>
                                            <Link to="#">Packinglist Export</Link>
                                        </BreadcrumbItem>
                                    </ol>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">

                            <Row>
                                <Col md="3">
                                    <Label>Cargo Type</Label>
                                    <Select
                                        name="cargo_type"
                                        placeholder="Select"
                                        value={selectedCargo}
                                        options={cargoOptions}
                                        onChange={(selected) => {
                                            handleSelectChange(selected, "cargo_type")
                                        }}
                                    />
                                </Col>

                                <Col md="3">
                                    <Label>Country</Label>
                                    <Select
                                        name='country'
                                        placeholder="Select"
                                        value={selectedCountry}
                                        options={countryOptions}
                                        onChange={(selected) => {
                                            handleSelectChange(selected, "country")
                                        }}
                                    />
                                </Col>

                                <Col md='1' style={{ marginTop: "27px" }}>
                                    <Button color="primary" type="submit" onClick={() => handleSearch()}>
                                        Search
                                    </Button>
                                </Col>

                                <Col md='1' style={{ marginTop: "27px" }}>
                                    <Button color="danger" type="reset" onClick={() => reset()}>
                                        Reset
                                    </Button>
                                </Col>

                                <Col md="1" style={{ marginTop: "27px" }}>
                                    <Button color='success' type='submit'>
                                        <i className='fas fa-file-excel'></i>
                                        <CSVLink
                                            data={dataToBeExported}
                                            filename={
                                                "PackingList_export_" + getDate(new Date())
                                            }
                                            style={{ color: "white" }}
                                        >
                                            Export
                                        </CSVLink>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12" className='mt-2'>
                            <MDBDataTable
                                id="shipmentpackingtableid"
                                responsive
                                bordered
                                data={data}
                                entries={20}
                            />
                        </Col>
                    </Row>

                </div>
            </div>
        </React.Fragment>
    )

}


export default PackinglistExport;