import React, { useState, useEffect, useRef } from "react";
import {
  Row, Col, Button, Table, Label, InputGroup, Card, CardBody
} from "reactstrap";
import { Modal } from "react-bootstrap";

import CreatableSelect from "react-select/creatable";
import toastr from 'toastr';
import moment from "moment";
import { useLocation } from 'react-router-dom';

import Barcode from 'react-barcode';
import { AvField, AvForm } from "availity-reactstrap-validation";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "react-select";
import "./Add-booking.scss";
import $ from "jquery";

import { Box } from "@mui/system";
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from "react-router-dom";
import {
  getLocalbody,
} from "../../../helpers/globalFunctions";
/** FOR TAB STYLE */
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import InventoryIcon from '@mui/icons-material/Inventory';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Loader from "react-spinner-loader";
import LogoDark from "../../../assets/images/logo-large.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";


const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 35,
  height: 35,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <PersonOutlineIcon />,
    2: <FactCheckIcon />,
    3: <InventoryIcon />,
    4: <ReceiptLongIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ['PERSONAL DETAILS', 'ITEM DETAILS', 'BOX DETAILS', 'SUMMARY'];
function Addbooking(props) {
  var search = props?.location?.search;
  if (search) {
    var search_data = search.split("=");
    var order_search_no = search_data[1];
  }
  else
    var order_search_no = "";
  const [loader, setLoader] = useState(false);
  const [searchOrder] = useState(order_search_no);
  const [activeStep, setActiveStep] = useState(0);
  const custStyle = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: state.isFocused && "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isFocused && "rgb(235 101 23 / 30%)",
      color: state.isFocused && "black",
    }),
  }
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [creditCustModal, setCreditCustModal] = useState(false);
  const [Tab1, setTab1] = useState(false);
  const [Tab2, setTab2] = useState(false);
  const [Tab3, setTab3] = useState(false);
  const [Tab4, setTab4] = useState(false);

  // =======================basicdetaisTAB1tate=================================================================>
  const barcodeRef = useRef(null);
  const [OrderObj_id, setOrderObj_id] = useState(null);
  const [masterobjbasic, setmasterobjbasic] = useState({ "payment_type": 2 });
  const [BoxdetailsObject, setBoxdetailsObject] = useState({ order_id: OrderObj_id, expected_delivery: "", cargo_packed_by: "", l: "", b: "", h: "" });
  const [OrderSummyery, setOrderSummyery] = useState({});
  const [selectcargo, setselectcargo] = useState(null);
  const [cargooptions, setcargooptions] = useState([]);
  const [selectedCreditCust, setselectedCreditCust] = useState(null);
  const [creditCustomerOptions, setcreditCustomerOptions] = useState([]);
  const [selectcashcredit, setselectcashcredit] = useState({ label: 'Cash', value: 2 });
  const [selectfromcountry, setselectfromcountry] = useState(null);
  const [fromcountryoptions, setfromcountryoptions] = useState([]);
  const [selectetocountry, setselectetocountry] = useState(null);
  const [selectedMobile, setselectedMobile] = useState(null);
  const [searchMobileListOptions, setsearchMobileListOptions] = useState([]);
  const [searchdata, setsearchdata] = useState([]);
  const [countryCode, setCountryCode] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState('');
  const [searchdataReceiver, setsearchdataReceiver] = useState([]);
  const [searchnumber, setsearchnumber] = useState([]);
  const [searchnumberReceiver, setsearchnumberReceiver] = useState([]);
  const [savedData, setsavedData] = useState([]);
  const [receiver, setreceiver] = useState(true);
  const [addnew, setaddnew] = useState(false);
  const [stateoptions, setstateoptions] = useState([]);
  const [selecetdstate, setselecetdstate] = useState(null);
  const [deliverhere, setdeliverhere] = useState(-1);
  const [tocountry_id, settocountry_id] = useState(null);
  const [addressIdToBeDeletedrec, setaddressIdToBeDeletedrec] = useState(null);
  const [confirmDeletAlert, setconfirmDeletAlert] = useState(null);
  const [addressIdTobeDeletedcust, setaddressIdTobeDeletedcust] = useState(null);

  const [BasicDetailsTobeUpdated, setBasicDetailsTobeUpdated] = useState(null);
  // =========================ITEMDETAILSSTATES==============================>

  const [ItemDetailsObject, setItemDetailsObject] = useState({});
  const [updatedDetailsObject, setupdatedDetailsObject] = useState({});
  const [selectedItemType, setselectedItemType] = useState(null);
  const [ItemcatOptions, setItemcatOptions] = useState([]);
  const [selectedItemcat, setselectedItemcat] = useState(null);
  const [Rating, setRating] = useState(null);
  const [Rate, setRate] = useState(null);
  // ===========================BOXDETAILS FUNCTIONS==========>

  const [TotalBoxWeight, setTotalBoxWeight] = useState(0);
  const [displayaddmore, setdisplayaddmore] = useState("block");
  const [totalENboxwieght, settotalENboxwieght] = useState(0);
  const [BoxNO, setBoxNO] = useState(0);

  const [SingleOrderList, setSingleOrderList] = useState({});
  const [Charges, setCharges] = useState({});
  const [SuccessPage, setSuccessPage] = useState(false);

  let history = useHistory();
  const location = useLocation();
  const objectId = location?.state?.objectId;
  const name = location?.state?.name;
  const cargoType = location?.state?.cargoType;
  const cargoId = location?.state?.cargoId;
  const cargoCode = location?.state?.cargoCode;
  /**  Prevent window reload */


  // HANDLING RATE
  const [handlingRate, setHandlingRate] = useState(0);

  // Function to update handlingRate based on cargoType
  const updateHandlingRate = () => {
    if (cargoType === 'AIR') {
      setHandlingRate(2);
    } else if (cargoType === 'SEA') {
      setHandlingRate(2.5);
      // } else if (cargoType === 'SEA EXP') {
      //   setHandlingRate(2.5);
      // } else if (cargoType === 'ROAD') {
      //   setHandlingRate(1);
      // } else if (cargoType === 'MAIL') {
      //   setHandlingRate(2);
      // } else if (cargoType === 'D2D') {
      //   setHandlingRate(2);
      // } else if (cargoType === 'AIR EXP') {
      //   setHandlingRate(2);
    }
  };

  // Call the updateHandlingRate function whenever cargoType or ItemDetailsObject changes
  useEffect(() => {
    updateHandlingRate();
  }, [cargoType, ItemDetailsObject]);

  // You can also set the handling_rate property in ItemDetailsObject
  // whenever handlingRate changes
  useEffect(() => {
    setItemDetailsObject({
      ...ItemDetailsObject,
      handling_rate: handlingRate,
    });
  }, [handlingRate]);


  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!searchOrder && OrderObj_id) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [OrderObj_id]);
  /** Prevent history change */


  useEffect(() => {
    const unblock = history.block((tx) => {
      if (!searchOrder && OrderObj_id) {
        const shouldBlock = window.confirm('There are unsaved changes. Are you sure you want to leave?');
        if (shouldBlock) {
          return true; // Allow navigation
        } else {
          return false; // Prevent navigation
        }
      }
      return true; // Allow navigation
    });

    return () => {
      unblock();
    };
  }, [OrderObj_id, history]);


  useEffect(() => {
    if (searchOrder) {
      setLoader(true);
      axios
        .get(`${API_URL}order/single-order?order_no=${searchOrder}`, {
          headers: {
            "x-access-token": accessToken
          }
        })
        .then((res) => {
          var orderInfo = res.data.data;
          if (orderInfo) {
            setOrderObj_id(orderInfo._id);
            settocountry_id(orderInfo?.country_to?._id);

            if (orderInfo?.payment_type == 1) {
              var pay = "Credit";
              setselectedCreditCust({ label: orderInfo?.credit_det?.credit_cust_id?.name, value: orderInfo?.credit_det?.credit_cust_id?._id })
              setselectedCreditCustObject({ credit_cust_id: orderInfo?.credit_det?.credit_cust_id?._id, reference: orderInfo?.credit_det?.reference })
            }
            if (orderInfo?.payment_type == 2) {
              var pay = "Cash";
            }
            if (orderInfo?.payment_type == 3) {
              var pay = "Card";
            }
            if (orderInfo?.payment_type == 4) {
              var pay = "Bank Transfer";
            }
            setselectcargo({ label: orderInfo?.cargo_type?.cargo_sub_type, value: orderInfo?.cargo_type?._id });
            setselectcashcredit({ label: pay, value: orderInfo?.payment_type });
            setselectfromcountry({ label: orderInfo?.country_from?.name, value: orderInfo?.country_from?._id });
            setselectetocountry({ label: orderInfo?.country_to?.name, value: orderInfo?.country_to?._id });
            setselecetdstate({ label: orderInfo?.receiver?.rece_state?.name, value: orderInfo?.receiver?.rece_state?._id });
            setsavedData(orderInfo?.sender?.saved_addresses);
            var address = orderInfo?.sender?.saved_addresses;
            if (address?.length > 0) {
              var index_no = address?.map((obj, index) => obj._id.toString() === orderInfo?.receiver?._id?.toString() ? index : -1)
                .filter(index => index !== -1)[0];
              setdeliverhere(index_no);
            }
            setaddnew(true);
            setmasterobjbasic({
              "order_id": orderInfo._id,
              "senderName": orderInfo?.sender?.name,
              "senderMobileNumber": orderInfo?.sender?.mobile_number,
              "senderWhattsappNumber": orderInfo?.sender?.whattsapp_number,
              "senderidNumber": orderInfo?.sender?.id_number,
              "sender_id": orderInfo?.sender?._id,
              "country_to": orderInfo?.country_to?._id,
              "receiver_country": orderInfo?.country_to?._id,
              "country_from": orderInfo?.country_from?._id,
              "branch_id": orderInfo?.branch_id?._id,
              "receiver_id": orderInfo?.receiver?._id,
              "receiverName": orderInfo?.receiver?.name || '',
              "receiveridNumber": orderInfo?.receiver?.id_number,
              "receiverHouseName": orderInfo?.receiver?.house_name,
              "receiverLandmark": orderInfo?.receiver?.landmark,
              "receiverStreet": orderInfo?.receiver?.street,
              "receiverPostOffice": orderInfo?.receiver?.post_office,
              'receiverPinCode': orderInfo?.receiver?.pin_code,
              "receiverDistrict": orderInfo?.receiver?.district,
              "receiverMobileNumber": orderInfo?.receiver?.mobile_number,
              "receiverTelephoneNumber": orderInfo?.receiver?.telephone_number,
              "receiverState": orderInfo?.receiver?.rece_state?._id,
              "cargo_type": orderInfo?.cargo_type?._id,
              "payment_type": orderInfo?.payment_type,
              "booking_type": orderInfo?.booking_type,
              "credit_det": { credit_cust_id: orderInfo?.credit_det?.credit_cust_id?._id, reference: orderInfo?.credit_det?.reference },
              "grand_total": orderInfo?.grand_total,
              "credit_balance": 0,
            });


            /** ORDER ITEMS */
            var item_details = [];
            orderInfo?.item_details?.map((itemD, index1) => {
              if (itemD?.item_type == 1) {
                var type_name = "Item";
              }
              else if (itemD?.item_type == 2) {
                var type_name = "Packing";
              }
              else if (itemD?.item_type == 3) {
                var type_name = "Extra";
              }
              else if (itemD?.item_type == 4) {
                var type_name = "Package";
              }
              item_details.push({
                item_typename: type_name,
                item_type: itemD?.item_type,
                item_categoryName: itemD?.item_category?.name,
                item_category: itemD?.item_category?._id,
                description: itemD?.description,
                unit: itemD?.unit,
                quantity: itemD?.quantity,
                rate: itemD?.rate,
                amount: itemD?.amount,
              })
            })

            setvalues(item_details);

            setItemDetailsObject({
              "order_id": orderInfo?._id,
              "grand_total": orderInfo?.grand_total,
              "received_cash": 0,
              "vat_amount": orderInfo?.vat_amount,
              "handling_rate_total": orderInfo?.handling_rate_total,
              "total_value": orderInfo?.total_value,
              "vat_percentage": orderInfo?.vat_percentage,
              "amount": orderInfo?.amount,
              "total_weight": orderInfo?.total_weight,
              "handling_rate": orderInfo?.handling_rate,
              "no_of_boxes": orderInfo?.no_of_boxes,
              "item_details": item_details,
              "credit_balance": 0,
              "discount_amount": "",
              "handling_rate": handlingRate
            });

            /** ORDER BOXS  */
            var boxes = [];

            orderInfo?.box_details?.map((boxItem, index2) => {
              boxes.push({ _id: boxItem?._id, box_no: index2 + 1, box_weight: boxItem?.box_weight, packed_items: boxItem?.packed_items, l: boxItem?.l, b: boxItem?.b, h: boxItem?.h })
            })
            setboxValue(boxes);

            setBoxdetailsObject({
              "order_id": orderInfo?._id,
              // "box_details": boxes,
              "expected_delivery": orderInfo?.expected_delivery,
              "box_details": boxValue,
            });
            setBoxNO(boxes.length);
            settotalENboxwieght(orderInfo?.total_weight);
            setTotalBoxWeight(orderInfo?.total_weight);
          }

          setLoader(false);

        });
    }
    else {
      setmasterobjbasic({
        branch_id: currentLocalbody, booking_type: 0, country_from: "634d29022bd8fbee01762b69", receiverName: '',
        cargo_type: selectcargo, country_to: selectetocountry, payment_type: selectcashcredit.value, senderMobileNumber: phoneNumber,
      });
      setItemDetailsObject({ received_cash: 0, credit_balance: 0, vat_amount: "", vat_percentage: 0, discount_amount: "", handling_rate: handlingRate });
    }

  }, [])


  // =============================click outside close function=======>

  const refname = useRef();
  const refnumber = useRef();
  const refnameReceiver = useRef();
  const refnumberReceiver = useRef()

  const formRefTab1 = useRef();
  const formRefTab2 = useRef()
  const formRefTab3 = useRef();
  const formRefTab4 = useRef()
  const formRef = useRef();
  const formRefCreditCust = useRef();

  function resetTab1() {
    formRefTab1.current.reset();
    setselectcargo(null);
    setselectcashcredit(null);
    setselectetocountry(null);
    setselectfromcountry({ label: "Oman", value: "634d29022bd8fbee01762b69" });
    setsavedData([])
    setaddnew(false)
    setreceiver(true)
    setselectedCreditCustObject(null);
    setselectedCreditCust(null);
    setselecetdstate(null);
    setmasterobjbasic({
      branch_id: currentLocalbody, booking_type: 0, country_from: "634d29022bd8fbee01762b69", receiver_id: null, credit_balance: 0,
      order_id: OrderObj_id,
      receiverName: "",
      receiveridNumber: "",
      receiverHouseName: "",
      receiverLandmark: "",
      receiverStreet: "",
      receiverPostOffice: "",
      receiverPinCode: "",
      receiverDistrict: "",
      receiverMobileNumber: "",
      receiverTelephoneNumber: ""
    })

  }


  function resetTab2() {
    setvalues([{
      item_type: "",
      item_category: "",
      description: "",
      unit: "",
      quantity: "",
      rate: "",
      amount: "",
      categoryOptions: []
    }]);
    //formRefTab2.current.reset();
    setItemDetailsObject({ item_details: [], no_of_boxes: "", total_weight: "", amount: "", vat_percentage: ItemDetailsObject?.vat_percentage, total_value: "", handling_rate_total: "", vat_amount: "", grand_total: "", order_id: OrderObj_id, credit_balance: 0 });
    //Itemcalculation();
  }


  function resetTab3() {
    formRefTab3.current.reset();
    setisChecked(false);
    setisChecked1(false);
    setboxValue([{
      box_no: "",
      box_weight: "",
      packed_items: "",
      l: "",
      b: "",
      h: "",
    }])
  }

  const onDocumentMouseDown = e => {
    if (refname.current && !refname.current.contains(e.target)) {
      setsearchdata([]);
    }
    if (refnumber.current && !refnumber.current.contains(e.target)) {
      setsearchnumber([]);
    }
    if (refnameReceiver.current && !refnameReceiver.current.contains(e.target)) {
      setsearchdataReceiver([]);
    }
    if (refnumberReceiver.current && !refnumberReceiver.current.contains(e.target)) {
      setsearchnumberReceiver([]);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', onDocumentMouseDown);
    return () => {
      document.removeEventListener('mousedown', onDocumentMouseDown);
    };
  }, []);

  // =======================================================================

  const [errors, seterrors] = useState({
    country_from: "",
    country_to: "",
    cargo_type: "",
    payment_type: "",
    credit_cust: "",
    state: ""
  })

  const [activeKey, setActiveKey] = useState(1);
  const [state, setState] = useState(false);
  const [show, setShow] = useState(false);
  const [values, setvalues] = useState([
    {
      item_type: "",
      item_category: "",
      description: "",
      unit: "",
      quantity: "",
      rate: "",
      amount: "",
    },
  ]);
  const [boxValue, setboxValue] = useState([
    {
      box_no: "",
      box_weight: "",
      packed_items: "",
      l: "",
      b: "",
      h: "",
    },
  ]);

  const addMore = () => {
    setvalues([
      ...values,
      {
        item_type: "",
        item_category: "",
        description: "",
        unit: "",
        quantity: "",
        rate: "",
        amount: "",
        categoryOptions: []
      },
    ]);
  };
  const addMoreRow = () => {
    setboxValue([
      ...boxValue,
      {
        box_no: "",
        box_weight: "",
        packed_items: "",
        l: "",
        b: "",
        h: "",
      },
    ]);

  };


  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setvalues(list);
    Itemcalculation(0, index);
  };

  const handleRemove1 = (index) => {
    const list = [...boxValue];
    list.splice(index, 1);
    let valueArray = [];
    let total_bweight = 0;
    list && list.forEach((element) => { });

    list.map((el) => {
      let box_weight = el.box_weight
      total_bweight = total_bweight + parseFloat(box_weight);
      var formatted_bweight = total_bweight?.toFixed(2);

      if (TotalBoxWeight === total_bweight) {
        setdisplayaddmore("none")
      } else {
        setdisplayaddmore("block")
      }

      if (total_bweight > TotalBoxWeight) {
        setdisplayaddmore("none")
      }

      if (total_bweight < TotalBoxWeight) {
        setdisplayaddmore("block")
      }

      // settotalENboxwieght(total_bweight);
      settotalENboxwieght(formatted_bweight);

    })

    setboxValue(list);
  };

  const wrapperRef = useRef(null);
  const wrRef = useRef(null);
  const wraaRef = useRef(null);
  const wrrRef = useRef(null);

  useEffect(() => {
    fetch_cargo_type();
    fetch_vat();
    fetch_credit_customers();
    fetch_all_country();
    fetch_country_code();
  }, [])


  useEffect(() => {
    fetch_all_state(objectId);
  }, [objectId]);



  // ============== CHECKBOX==================>
  const [isChecked, setisChecked] = useState(false);
  const [isChecked1, setisChecked1] = useState(false);

  // const handleChange = (e, field) => {
  //   const newValue = e.target.checked;
  //   if (field === 'cargo_packed_by') {
  //     setisChecked(newValue);
  //     setBoxdetailsObject((prevDetails) => ({
  //       ...prevDetails,
  //       cargo_packed_by: newValue ? 'Customer' : undefined,
  //     }));
  //   } else if (field === 'cargo_packed_by_company') {
  //     setisChecked1(newValue);
  //     setBoxdetailsObject((prevDetails) => ({
  //       ...prevDetails,
  //       cargo_packed_by: newValue ? 'Company' : undefined,
  //     }));
  //   }
  // };

  function handleChange(e, data) {
    if (data === 0) {
      setisChecked(e.target.checked);
      setBoxdetailsObject((prevDetails) => ({
        ...prevDetails,
        cargo_packed_by: "0",
      }));
    } else if (data === 1) {
      setisChecked1(e.target.checked);
      setBoxdetailsObject((prevDetails) => ({
        ...prevDetails,
        cargo_packed_by: "1",
      }));
    }
  }
  // ===============Expected delivery============>

  const [expectedDelivery, setExpectedDelivery] = useState('');
  const [calculatedDate, setCalculatedDate] = useState('');
  let timeoutId;

  // const handleExpectedDeliveryChange = (event) => {
  //   clearTimeout(timeoutId); // Clear any previous timeouts
  //   const days = parseInt(event.target.value);
  //   if (!isNaN(days)) {
  //     timeoutId = setTimeout(() => {
  //       const today = new Date();
  //       const calculatedDate = new Date(today.getTime() + days * 24 * 60 * 60 * 1000);
  //       const formattedDate = `${calculatedDate.getDate().toString().padStart(2, '0')}-${(calculatedDate.getMonth() + 1).toString().padStart(2, '0')}-${calculatedDate.getFullYear()}`;
  //       setCalculatedDate(formattedDate);
  //     }, 500); // Delay calculation by 500ms    
  //   } else {
  //     setCalculatedDate('');
  //   }
  //   setExpectedDelivery(event.target.value);
  // };

  const handleDelivery = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    clearTimeout(timeoutId); // Clear any previous timeouts
    const daysToAdd = parseInt(e.target.value);

    if (!isNaN(daysToAdd)) {
      timeoutId = setTimeout(() => {
        const today = new Date();
        const calculatedDate = new Date(today.getTime() + daysToAdd * 24 * 60 * 60 * 1000);

        // Define an array of month names for formatting
        const monthNames = [
          "Jan", "Feb", "Mar",
          "Apr", "May", "Jun",
          "Jul", "Aug", "Sep",
          "Oct", "Nov", "Dec"
        ];


        // Format the date
        const formattedDate = `${calculatedDate.getDate()} ${monthNames[calculatedDate.getMonth()]} ${calculatedDate.getFullYear()}`;
        // const formattedDate = `${calculatedDate.getDate().toString().padStart(2, '0')}-${(calculatedDate.getMonth() + 1).toString().padStart(2, '0')}-${calculatedDate.getFullYear()}`;
        setCalculatedDate(formattedDate);

        setBoxdetailsObject({
          ...BoxdetailsObject,
          [name]: value,
          expected_delivery: formattedDate, // Use formattedDate here
        });
      }, 500); // Adjust the delay here
    } else {
      setCalculatedDate('');

      setBoxdetailsObject({
        ...BoxdetailsObject,
        [name]: value,
        expected_delivery: '',
      });
    }
  };
  // ===============fetchcargotype===============>

  function fetch_cargo_type() {
    axios
      .get(`${API_URL}cargoType/list`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var cargoop =
          res.data.data &&
          res.data.data.map((cargo) => {

            if (cargoType) {
              setselectcargo({ label: cargoType, value: cargoType, cargo_type_code: cargoCode })
            }


            setmasterobjbasic(prevState => ({
              ...prevState,
              cargo_type: cargoId, // Add the cargo type value here
              cargo_type_code: cargoCode
            }));

            return {
              label: cargo?.cargo_Type,
              // label: cargo?.cargo_sub_type,
              value: cargo?._id,
              handling_rate: cargo?.handling_rate,
              cargo_type_code: cargo?.cargo_type_code,
            }
          })

        setcargooptions([{ options: cargoop }])
      })

  }


  function fetch_vat() {
    axios
      .get(`${API_URL}vatadd/vatlist`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data;
        if (data.length > 0) {
          var vat = data[0].vat;
          if (!searchOrder) {
            setItemDetailsObject({
              ...ItemDetailsObject,
              "vat_percentage": vat
            })
          }

        }
      })

  }


  function fetch_credit_customers() {
    axios
      .get(`${API_URL}customer/credit-customer/list`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var options =
          res.data.data &&
          res.data.data.map((cust) => {
            return {
              label: cust?.name + " " + cust?.mobile_number,
              value: cust?._id,
            }
          })
        setcreditCustomerOptions([{ options: options }])
      })

  }
  // ============================fetchcountry==========>

  function fetch_all_country() {
    axios
      .get(`${API_URL}newStates/countries`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var country_data = [];
        res.data.data &&
          res.data.data.map((country) => {
            if (country?.name?.toLowerCase() == "oman") {
              setselectfromcountry({ label: country?.name, value: country?._id });
              // setmasterobjbasic({
              //   ...masterobjbasic,
              //   "country_from": country?._id
              // });
            }

            if (name) {
              setselectetocountry({ label: name, value: objectId })
            }

            setmasterobjbasic(prevState => ({
              ...prevState,
              country_to: objectId
            }));


            country_data.push({
              label: country?.name,
              value: country?._id,

            });
          })
        setfromcountryoptions([
          {
            options: country_data,
          },
        ]);
      });
  }


  // =======================fetchcountrycode===================>
  function fetch_country_code() {
    axios
      .get(`${API_URL}country/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        let result = res.data.data;

        setCountryCode(result);
      })
  }
  // =======================fetchallstate======================>
  function fetch_all_state(country_id) {

    axios
      .get(`${API_URL}newStates/list?country_id=${country_id}`, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {
        var state_data =
          res.data.data &&
          res.data.data.map((st) => {
            return {
              label: st?.name,
              value: st?._id
            }
          })
        setstateoptions([{ options: state_data }])
      })

  }


  // =================selectechange==================
  async function handleSelectChange(selected, name, index = null) {
    switch (name) {
      case "cargo_type":
        setselectcargo(selected);
        setmasterobjbasic({ ...masterobjbasic, cargo_type: selected.value })
        setItemDetailsObject({ ...ItemDetailsObject, handling_rate: selected.handling_rate })
        setHandlingRate(selected.handling_rate);
        Itemcalculation("", null, selected.handling_rate);
        break;

      case "payment_type":
        //fetch_credit_customers();
        setselectcashcredit(selected);
        setmasterobjbasic({ ...masterobjbasic, payment_type: selected.value });
        break;

      case "credit_custmer":
        setselectedCreditCust(selected);
        setselectedCreditCustObject({
          ...selectedCreditCustObject,
          "credit_cust_id": selected?.value
        });
        break;

      case "country_from":
        setselectfromcountry(selected)
        setmasterobjbasic({ ...masterobjbasic, country_from: selected.value })
        break;

      case "country_to":
        setselectetocountry(selected)
        fetch_all_state(selected.value)
        settocountry_id(selected.value)
        setselecetdstate(null);

        if (masterobjbasic?.sender_id) {
          axios
            .get(`${API_URL}customer/saved-address?customer_id=${masterobjbasic?.sender_id}&rece_country=${selected.value}`, {
              headers: {
                "x-access-token": accessToken,
              }
            })
            .then((res) => {
              let result = res.data
              setsavedData(result.data);
              setdeliverhere(-1);
              setreceiver(true)
              setmasterobjbasic({
                ...masterobjbasic,
                country_to: selected.value,
                receiver_id: null,
                receiverName: "",
                receiveridNumber: "",
                receiverHouseName: "",
                receiverLandmark: "",
                receiverStreet: "",
                receiverPostOffice: "",
                receiverPinCode: "",
                receiverDistrict: "",
                receiverMobileNumber: "",
                receiverTelephoneNumber: ""

              })

            })
        }
        else {
          setsavedData([]);
          setdeliverhere(-1);
          setreceiver(true)
          setmasterobjbasic({
            ...masterobjbasic,
            country_to: selected.value,
            receiver_id: null,
            receiverName: "",
            receiveridNumber: "",
            receiverHouseName: "",
            receiverLandmark: "",
            receiverStreet: "",
            receiverPostOffice: "",
            receiverPinCode: "",
            receiverDistrict: "",
            receiverMobileNumber: "",
            receiverTelephoneNumber: ""

          })

        }

        break;
      case "receiverState":
        setselecetdstate(selected)
        setmasterobjbasic({ ...masterobjbasic, receiverState: selected.value })
        seterrors({
          ...errors,
          state: ""
        });
        break;
      case "item_type":
        //setselectedItemType(selected)
        var categoriesOptions = await fetch_all_itemCategory(selected.value, index);
        var list = [...values];
        // list[index].itemtypeObject = selected;
        list[index].item_type = selected.value;
        list[index].item_typename = selected.label
        list[index].item_category = "";
        list[index].item_categoryName = "";
        list[index].unit = "";
        list[index].description = "";
        list[index].rate = "";
        list[index].quantity = "";
        list[index].amount = "";
        list[index].categoryOptions = categoriesOptions;
        list[index].item_type_error = "";
        setvalues(list);
        Itemcalculation()
        break;
      case "item_category":
        setselectedItemcat(selected)
        var list = [...values];
        list[index].item_category = selected.value;
        list[index].item_categoryName = selected.label;
        list[index].unit = selected.unit;
        list[index].rate = selected.rate;
        list[index].quantity = (list[index]?.quantity) ? list[index]?.quantity : 1;
        list[index].item_category_error = "";
        list[index].quantity_error = "";
        var rate = parseFloat(list[index]?.rate)
        var quantity = parseFloat(list[index]?.quantity)
        if (list[index]?.item_type != 4)
          list[index].amount = rate * quantity;
        else
          list[index].amount = rate
        setvalues(list);
        Itemcalculation()
        break;
    }
  }
  function Itemcalculation(noBox = "", del_index = null, handling_rate = "") {
    var list = [...values];
    var amount = 0;
    var weight = 0;
    var grand_total = 0;
    list.map((item, index) => {
      if (del_index != index) {
        var qty = parseFloat(item?.quantity ? item?.quantity : 0);
        var rate = parseFloat(item?.rate ? item.rate : 0);
        if (item?.item_type != 4) {
          var amt = qty * rate;
        }
        else {
          var amt = rate;
        }

        if (item?.item_type != 2) {
          weight = weight + qty;
        }
        //item.amount = amt;
        amount = amount + amt;
      }

    });
    amount = parseFloat(amount.toFixed(2));
    var vat_perc = ItemDetailsObject?.vat_percentage ? ItemDetailsObject.vat_percentage : 0;
    if (vat_perc) {
      var vat_amount = (vat_perc / 100) * amount;
      vat_amount = parseFloat(vat_amount.toFixed(2));
    }
    else
      var vat_amount = 0;
    if (!handling_rate) {
      handling_rate = ItemDetailsObject?.handling_rate ? ItemDetailsObject.handling_rate : 0;
    }
    if (noBox) {
      var handling_rate_total = handling_rate * noBox;
      grand_total = amount + handling_rate_total + vat_amount;
      grand_total = parseFloat(grand_total.toFixed(2));
      setItemDetailsObject({
        ...ItemDetailsObject,
        "total_weight": weight,
        "amount": amount,
        "grand_total": grand_total,
        "handling_rate": handling_rate,
        "handling_rate_total": handling_rate_total,
        "no_of_boxes": noBox,
        "vat_amount": vat_amount
      });
    }
    else {
      noBox = ItemDetailsObject?.no_of_boxes ? ItemDetailsObject.no_of_boxes : 0;
      var handling_rate_total = handling_rate * noBox;
      // var handling_rate_total = ItemDetailsObject?.handling_rate_total ? ItemDetailsObject?.handling_rate_total : 0;
      grand_total = amount + handling_rate_total + vat_amount;
      setItemDetailsObject({
        ...ItemDetailsObject,
        "total_weight": weight,
        "amount": amount,
        "handling_rate": handling_rate,
        "handling_rate_total": handling_rate_total,
        "grand_total": grand_total,
        "no_of_boxes": noBox,
        "vat_amount": vat_amount
      });
    }

    //setvalues(list);

  }




  // ===================searchby name===================>

  function handleSelectChangesearchname(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterobjbasic({ ...masterobjbasic, [name]: value })

    axios
      .get(`${API_URL}customer/search?value=${e.target.value}`, {
        headers: {
          "x-access-token": accessToken,
        }
      }).then((res) => {

        let result = res.data
        setsearchdata(result)
      })
  }


  function handleSelectChangesearchnameReceiver(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterobjbasic({ ...masterobjbasic, [name]: value })

    axios
      .get(`${API_URL}receiver/search?name=${e.target.value}&rece_country=${masterobjbasic?.country_to}`, {
        headers: {
          "x-access-token": accessToken,
        }
      }).then((res) => {

        let result = res.data
        setsearchdataReceiver(result)
      })
  }


  // =================handlesearchnumber============>

  const [phoneNumber, setPhoneNumber] = React.useState('');

  // Define the onChange event handler
  // const handleSelectChangesearchnumber = (value) => {
  //   setPhoneNumber(value); // Update the phone number state with the new value
  //   // setmasterobjbasic.senderMobileNumber = value;

  //   setmasterobjbasic({ ...masterobjbasic, senderMobileNumber: value });
  // };


  function handleSelectChangesearchnumber(value) {
    // let name = e.target.name;
    // let value = e.target.value;

    setPhoneNumber(value);
    setmasterobjbasic({ ...masterobjbasic, senderMobileNumber: value })

    axios
      .get(`${API_URL}customer/search?value=${value}`, {
        headers: {
          "x-access-token": accessToken,
        }
      }).then((res) => {

        let result = res.data
        setsearchnumber(result);
      })
    setsearchdata([])
  }


  // ========================handleselectchangesearchnumberreceiver===================


  const [contactNumber, setContactNumber] = React.useState('');

  // function handleSelectChangesearchnumberReceiver(value) {
  //   setContactNumber(value);

  //   setmasterobjbasic({ ...masterobjbasic, receiverMobileNumber: value });

  // }

  function handleSelectChangesearchnumberReceiver(value) {
    // let name = e.target.name;
    // let value = e.target.value;

    setContactNumber(value);
    setmasterobjbasic({ ...masterobjbasic, receiverMobileNumber: value })

    axios
      .get(`${API_URL}receiver/search?name=${value}&rece_country=${masterobjbasic?.country_to}`, {
        headers: {
          "x-access-token": accessToken,
        }
      }).then((res) => {

        let result = res.data
        setsearchnumberReceiver(result);
      })
    setsearchdataReceiver([])
  }

  // ===============Telephone number====================

  const [telephoneNumber, setTelephoneNumber] = React.useState('')

  function handleTelephoneChange(value) {
    setTelephoneNumber(value);

    setmasterobjbasic({ ...masterobjbasic, receiverTelephoneNumber: value });
  }

  // ===============Whatsapp===========================>

  const [whatsapp, setWhatsapp] = React.useState('');

  function handleWhatsapp(value) {
    setWhatsapp(value);

    setmasterobjbasic({ ...masterobjbasic, senderWhattsappNumber: value })
  }


  // ===============settting matsr for sender ==========

  function settingmaster(el) {
    setmasterobjbasic({
      ...masterobjbasic,
      senderName: el?.name,
      sender_id: el?._id,
      senderidNumber: el?.id_number,
      senderMobileNumber: el?.mobile_number,
    })
    setsearchdata([])
    setsearchnumber([])
    // =============fetching saved adress from selectedoption=======>
    axios
      .get(`${API_URL}customer/saved-address?customer_id=${el?._id}&rece_country=${masterobjbasic?.country_to}`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        let result = res.data
        setsavedData(result.data)
        setdeliverhere(-1);
        setreceiver(true)
        setmasterobjbasic({
          ...masterobjbasic,
          senderName: el?.name,
          sender_id: el?._id,
          senderidNumber: el?.id_number,
          senderMobileNumber: el?.mobile_number,
          receiver_id: null,
          receiverName: "",
          receiveridNumber: "",
          receiverHouseName: "",
          receiverLandmark: "",
          receiverStreet: "",
          receiverPostOffice: "",
          receiverPinCode: "",
          receiverDistrict: "",
          receiverMobileNumber: "",
          receiverTelephoneNumber: ""

        })
        setselecetdstate(null);

      })
  }
  // ======================setting receviver adrress to creditcustmasterObject========>

  function settingmasterreceiver(el) {
    if (el?._id?.toString() !== masterobjbasic?.receiver_id?.toString()) {
      var address = [...savedData];
      if (address?.length > 0) {
        var index_no = address?.map((obj, index) => obj._id.toString() === el?._id?.toString() ? index : -1)
          .filter(index => index !== -1)[0];
        setdeliverhere(index_no);
      }

    }
    setmasterobjbasic({
      ...masterobjbasic,
      receiverName: el?.name,
      receiveridNumber: el?.id_number,
      receiverHouseName: el?.house_name,
      receiverLandmark: el?.landmark,
      receiverStreet: el?.street,
      receiverDistrict: el?.district,
      receiver_id: el?._id,
      receiverPostOffice: el?.post_office,
      receiverPinCode: el?.pin_code,
      receiverMobileNumber: el?.mobile_number,
      receiverState: el?.rece_state?._id,
      receiverTelephoneNumber: el?.telephone_number,
    });
    setselecetdstate({ label: el?.rece_state?.name, value: el?.rece_state?._id });
    setsearchdataReceiver([]);
    setsearchnumberReceiver([]);
  }
  // =============settingreceiverdata===================>
  function Settingreceiverdata() {
    setreceiver(true)
    //setsavedData([])
    setaddnew(true)
    setmasterobjbasic({
      ...masterobjbasic,
      receiver_id: null,
      receiverName: "",
      receiveridNumber: "",
      receiverHouseName: "",
      receiverLandmark: "",
      receiverStreet: "",
      receiverPostOffice: "",
      receiverPinCode: "",
      receiverDistrict: "",
      receiverMobileNumber: "",
      receiverTelephoneNumber: ""

    })
    setselecetdstate(null);
  }

  // ==================handleinput============>

  function handleInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "reference") {
      setselectedCreditCustObject({
        ...selectedCreditCustObject,
        [name]: value
      });
    }
    else {
      setmasterobjbasic({ ...masterobjbasic, [name]: value })
    }
    setsearchdata([])
  }
  // ===================SubmitTab1===============>
  function handleValidSubmitTab1(e) {

    setmasterobjbasic({
      ...masterobjbasic,
      country_to: selectetocountry ? selectetocountry?.value : objectId,
      cargo_type: selectcargo ? selectcargo?.value : cargoId,
      handling_rate: handlingRate,
      cargo_type_code: selectcargo ? selectcargo?.cargo_type_code : cargoCode
    })

    let data = {
      booking_type: masterobjbasic.booking_type,
      branch_id: masterobjbasic.branch_id,
      cargo_type: masterobjbasic.cargo_type,
      country_from: masterobjbasic.country_from,
      country_to: masterobjbasic.country_to,
      cargo_type_code: masterobjbasic.cargo_type_code,
      receiver_country: masterobjbasic.country_to,
      order_id: masterobjbasic.order_id,
      payment_type: masterobjbasic.payment_type,
      pickup: masterobjbasic.pickup,
      receiverDistrict: masterobjbasic.receiverDistrict,
      receiverHouseName: masterobjbasic.receiverHouseName,
      receiverLandmark: masterobjbasic.receiverLandmark,
      receiverMobileNumber: masterobjbasic.receiverMobileNumber,
      receiverName: masterobjbasic.receiverName,
      receiverPinCode: masterobjbasic.receiverPinCode,
      receiverPostOffice: masterobjbasic.receiverPostOffice,
      receiverState: masterobjbasic.receiverState,
      receiverStreet: masterobjbasic.receiverStreet,
      receiverTelephoneNumber: masterobjbasic.receiverTelephoneNumber,
      receiver_id: masterobjbasic.receiver_id,
      receiveridNumber: masterobjbasic.receiveridNumber,
      senderMobileNumber: masterobjbasic.senderMobileNumber,
      senderName: masterobjbasic.senderName,
      senderWhattsappNumber: masterobjbasic.senderWhattsappNumber,
      sender_id: masterobjbasic.sender_id,
      senderidNumber: masterobjbasic.senderidNumber,
    }

    if (selecetdstate === null || masterobjbasic?.receiverState == null || selectcargo === null || selectcashcredit === null || selectetocountry === null || selectfromcountry === null) {
      let errorVal = errors;
      if (selectcargo === null)
        errorVal.cargo_type = "please Select Cargo Type"
      if (selectcashcredit === null)
        errorVal.payment_type = "Please Select Payment Type"
      if (selectetocountry === null)
        errorVal.country_to = "Please Select Country"
      if (selectfromcountry === null)
        errorVal.country_from = "Please Select Country"
      if (selecetdstate === null || masterobjbasic?.receiverState == null)
        errorVal.state = "Please Select State"

      seterrors({
        ...errorVal
      });
      window.scrollTo(0, 0);
    }
    else if (selectcashcredit == 1 && selectedCreditCust == null) {
      let errorVal = errors;
      errorVal.credit_cust = "Please select Customer";
      seterrors({
        ...errorVal
      });
    }
    else {
      masterobjbasic.credit_det = selectedCreditCustObject;
      if (OrderObj_id) {

        axios
          .put(`${API_URL}order/edit/international`, data, {
            headers: {
              "x-access-token": accessToken,
            }
          })
          .then((res) => {

            if (res.data.status === 200) {
              // toastr.success("Basic Details Updated Successfully")

              //formRefTab1.current.reset()
              setActiveStep(1);
              window.scrollTo(0, 0);
              setmasterobjbasic({});
              // setItemDetailsObject({ ...ItemDetailsObject, handling_rate: handlingRate });
              // setselectcargo(null);
              // setselectcashcredit(null);
              // setselectetocountry(null);
              // setselectfromcountry(null);
              // setsavedData([])
              // setaddnew(false)
              // setreceiver(true)
            }
            else {
              toastr.error("faild to update")
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message)
            return;
          })
      }
      // else {
      //   axios
      //     .post(`${API_URL}order/international`, masterobjbasic, {
      //       headers: {
      //         "x-access-token": accessToken,
      //       }
      //     }).then((res) => {
      //       if (res.data.status === 200) {
      //         // toastr.success("Basics Details Saved");

      //         setOrderObj_id(res?.data?.data?._id);
      //         let order_id = res?.data?.data?._id
      //         setItemDetailsObject({
      //           ...ItemDetailsObject, order_id: order_id, payment_status: res?.data?.data?.payment_status,
      //           handling_rate: handlingRate
      //         })
      //         setBoxdetailsObject({ ...BoxdetailsObject, order_id: order_id })
      //         setmasterobjbasic({
      //           ...masterobjbasic,
      //           order_id: order_id,
      //           sender_id: res?.data?.data?.sender,
      //           receiver_id: res?.data?.data?.receiver,
      //         });
      //         //formRefTab1.current.reset()
      //         setActiveStep(1);
      //         window.scrollTo(0, 0);
      //         // setmasterobjbasic({});
      //         // setselectcargo(null);
      //         // setselectcashcredit(null);
      //         // setselectetocountry(null);
      //         // setselectfromcountry(null);
      //         // setsavedData([])
      //         // setaddnew(false)
      //         // setreceiver(true)

      //       }
      //       else {
      //         toastr.error("Faild To Save Basic Details")
      //         return;
      //       }
      //     }).catch((err) => {
      //       console.log(err);
      //       toastr.error(err.response.data.message)
      //       return;
      //     })
      // }
      else {
        axios
          .post(`${API_URL}order/international`, masterobjbasic, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              const responseData = res?.data?.data;

              if (responseData) {
                // Check if responseData is not null
                setOrderObj_id(responseData._id);
                let order_id = responseData._id;

                setItemDetailsObject({
                  ...ItemDetailsObject,
                  order_id: order_id,
                  payment_status: responseData.payment_status,
                  handling_rate: handlingRate,
                });

                setBoxdetailsObject({ ...BoxdetailsObject, order_id: order_id });

                setmasterobjbasic({
                  ...masterobjbasic,
                  order_id: order_id,
                  sender_id: responseData.sender,
                  receiver_id: responseData.receiver,
                });

                setActiveStep(1);
                window.scrollTo(0, 0);
              } else {
                toastr.error("Failed To Save Basic Details: Response data is null");
              }
            } else {
              toastr.error("Failed To Save Basic Details");
            }
          })
          .catch((err) => {
            console.error(err);
            if (err.response && err.response.data) {
              toastr.error(err.response.data.message);
            } else {
              toastr.error("An error occurred while processing your request.");
            }
          });
      }


    }
  }

  // ====================itemdetailFunction===================>

  async function fetch_all_itemCategory(id, index = null) {

    var res = await axios
      .get(`${API_URL}itemCategory/search_by_type?type=${id}`, {
        headers: {
          "x-access-token": accessToken,
        }
      });
    var Cate_data =
      res.data.data &&
      res.data.data.map((cat) => {
        return {
          label: cat?.name,
          value: cat?._id,
          unit: cat?.unit,
          rate: cat?.rate,
        }
      });
    return Cate_data;
  }

  function handleUnitchange(e, index) {
    var list = [...values];
    var rate = list[index]?.rate;
    var quantity = e.target.value;
    if (list[index]?.item_type != 5)
      var amount = (rate * quantity).toFixed(2);
    else
      var amount = rate;
    list[index].quantity = quantity;
    list[index].amount = parseFloat(amount);
    list[index].quantity_error = "";
    setvalues(list);
    Itemcalculation();
  }

  // ================description=====================>
  function handledescripchange(e, index) {
    var list = [...values]
    var description = e.target.value;
    list[index].description = description;
    setvalues(list)
  }

  // =================item details inputchange  handling rate total ,Grant total calculation===========>
  function handlenofBoxchanges(e) {
    let no_of_boxes = e.target.value
    setBoxNO(no_of_boxes)
    //let handling_rate_total = parseFloat(ItemDetailsObject.handling_rate) * parseInt(no_of_boxes)
    //setItemDetailsObject({ ...ItemDetailsObject, handling_rate_total: handling_rate_total, no_of_boxes: no_of_boxes });
    Itemcalculation(no_of_boxes);
  }

  // =============================SUBMIT ITEM Details================>
  function handleValidSubmitTab2() {

    let data = {
      amount: ItemDetailsObject.amount,
      credit_balance: ItemDetailsObject.credit_balance,
      discount_amount: ItemDetailsObject.discount_amount,
      grand_total: ItemDetailsObject.grand_total,
      handling_rate: ItemDetailsObject.handling_rate,
      handling_rate: ItemDetailsObject?.handling_rate ? ItemDetailsObject?.handling_rate : handlingRate,
      handling_rate_total: ItemDetailsObject.handling_rate_total,
      item_details: values,
      no_of_boxes: ItemDetailsObject.no_of_boxes,
      order_id: ItemDetailsObject.order_id,
      received_cash: ItemDetailsObject.received_cash,
      total_value: ItemDetailsObject.total_value,
      total_weight: ItemDetailsObject.total_weight,
      vat_amount: ItemDetailsObject.vat_amount,
      vat_percentage: ItemDetailsObject.vat_percentage,
    }

    setItemDetailsObject({
      ...ItemDetailsObject,
      handling_rate: handlingRate,
    });


    var list = [...values];
    var err = 0;
    var type_miss = list
      .map((item, index) => ({ item, index }))
      .filter(
        ({ item }) =>
          !item.hasOwnProperty("item_type") ||
          item.item_type == ""
      )
      .map(({ index }) => index);
    if (type_miss.length > 0) {
      type_miss.map((item) => {
        list[item]["item_type" + "_error"] = "Select Type";
      });
      err++;
    }

    var cat_miss = list
      .map((item, index) => ({ item, index }))
      .filter(
        ({ item }) =>
          !item.hasOwnProperty("item_category") ||
          item.item_category == ""
      )
      .map(({ index }) => index);
    if (cat_miss.length > 0) {
      cat_miss.map((item) => {
        list[item]["item_category" + "_error"] = "Select Category";
      });
      err++;
    }

    var qty_miss = list
      .map((item, index) => ({ item, index }))
      .filter(
        ({ item }) =>
          !item.hasOwnProperty("quantity") ||
          item.quantity == ""
      )
      .map(({ index }) => index);
    if (qty_miss.length > 0) {
      qty_miss.map((item) => {
        list[item]["quantity" + "_error"] = "Enter quantity";
      });
      err++;
    }

    if (err > 0) {
      setvalues(list);
      window.scrollTo(0, 0);
      return;
    }
    // else {
    //   ItemDetailsObject.item_details = values;
    //   ItemDetailsObject.handling_rate = handlingRate;

    //   axios
    //     .put(`${API_URL}order/editItemDetails`, data, {
    //       headers: {
    //         "x-access-token": accessToken,
    //       }
    //     }).then((res) => {
    //       if (res.data.status === 200) {
    //         //toastr.success("Item Details Saved")
    //         // setvalues([{
    //         //   item_type: "",
    //         //   item_category: "",
    //         //   description: "",
    //         //   unit: "",
    //         //   quantity: "",
    //         //   rate: "",
    //         //   amount: "",
    //         //   categoryOptions: []
    //         // }]);
    //         // formRefTab2.current.reset();
    //         // setItemDetailsObject({ item_details: [], no_of_boxes: "", handling_rate: ItemDetailsObject?.handling_rate, total_weight: "", amount: "", vat_percentage: ItemDetailsObject?.vat_percentage, total_value: "", handling_rate_total: "", vat_amount: "", grand_total: "", order_id: OrderObj_id });
    //         setActiveStep(2);
    //         window.scrollTo(0, 0);
    //         setTotalBoxWeight(res?.data?.data?.total_weight);
    //       }
    //       else {
    //         toastr.error("Faild To Save Item Details")
    //         return;
    //       }
    //     })
    //     .catch((err) => {
    //       toastr.error("Faild To Save Item Details")
    //       return;
    //     })
    // }
    else {

      if (OrderObj_id) {
        ItemDetailsObject.item_details = values;

        axios
          .put(`${API_URL}order/editItemDetails`, data, {
            headers: {
              "x-access-token": accessToken,
            },
          }).then((res) => {
            if (res.data.status === 200) {
              setActiveStep(2);
              window.scrollTo(0, 0);
              setTotalBoxWeight(res?.data?.data?.total_weight);
            }
            else {
              toastr.error("Failed to save item details");
              return;
            }
          })
          .catch((err) => {
            toastr.error("Failed to save item details");
            return;
          })
      } else {
        axios
          .post(`${API_URL}order/addItemDetails`, ItemDetailsObject, {
            headers: {
              "x-access-token": accessToken,
            },
          }).then((res) => {
            if (res.data.status === 200) {
              setActiveStep(2);
              window.scrollTo(0, 0);
              setItemDetailsObject({})
            } else {
              toastr.error("Failed to save Item details");
              return;
            }
          }).catch((err) => {
            toastr.error(err.response.data.message);
            return;
          })
      }
    }

  }

  // =============================================================
  function handleWeightchange(e, index) {
    var list = [...boxValue]
    var box_weight = e.target.value;
    list[index].box_weight = box_weight;
    list[index].box_no = index + 1;
    if (box_weight)
      list[index].box_weight_error = "";
    else
      list[index].box_weight_error = "Enter Weight";
    let total_bweight = 0;
    let reamin_weight = 0
    let totalbWeightresponse = TotalBoxWeight
    boxValue.map((el) => {
      let box_weight = el.box_weight
      if (box_weight === null || box_weight === NaN || box_weight === "") {
        box_weight = 0;
      }
      total_bweight = total_bweight + parseFloat(box_weight);
      var formatted_bweight = total_bweight?.toFixed(2);
      
      if (TotalBoxWeight === total_bweight) {
        setdisplayaddmore("none")
      } else {
        setdisplayaddmore("block")
      }
      if (total_bweight > TotalBoxWeight) {
        setdisplayaddmore("none")
      }
      if (total_bweight < TotalBoxWeight) {
        setdisplayaddmore("block")
      }
      settotalENboxwieght(formatted_bweight);

    })
    // settotalENboxwieght(total_bweight);
    
    setboxValue(list);
  }

  function handlePackedItemchange(e, index) {
    var list = [...boxValue]
    list[index].packed_items = e.target.value;
    // if (e.target.value)
    //   list[index].packed_items_error = "";
    // else
    //   // list[index].packed_items_error = "Enter Items";
    //   list[index].packed_items_error = "";

    setboxValue(list);
  }

  function handleDimensions(e, index) {
    var list = [...boxValue]
    list[index].l = e.target.value;
    setboxValue(list);
  }

  function handleDimensions1(e, index) {
    var list = [...boxValue]
    list[index].b = e.target.value;
    setboxValue(list);
  }

  function handleDimensions2(e, index) {
    var list = [...boxValue]
    list[index].h = e.target.value;
    setboxValue(list);
  }

  // ========================SUBMITbOXDETAILS==============>


  function handleValidSubmitTab3(e, v) {
    e.preventDefault();

    var list = [...boxValue];
    var err = 0;
    var weight_miss = list
      .map((item, index) => ({ item, index }))
      .filter(
        ({ item }) =>
          !item.hasOwnProperty("box_weight") ||
          item.box_weight == ""
      )
      .map(({ index }) => index);
    if (weight_miss.length > 0) {
      weight_miss.map((item) => {
        list[item]["box_weight" + "_error"] = "Enter Weight";
      });
      err++;
    }

    var item_miss = list
      .map((item, index) => ({ item, index }))
      .filter(
        ({ item }) =>
          !item.hasOwnProperty("packed_items") ||
          item.packed_items == ""
      )
      .map(({ index }) => index);
    // if (item_miss.length > 0) {
    //   item_miss.map((item) => {
    //     // list[item]["packed_items" + "_error"] = "Enter Items";
    //     list[item]["packed_items" + "_error"] = "";
    //   });
    //   err++;
    // }
    if (err > 0) {
      setboxValue(list);
      window.scrollTo(0, 0);
      return;
    }
    BoxdetailsObject.box_details = boxValue;

    if (TotalBoxWeight - totalENboxwieght === 0) {
      axios
        .put(`${API_URL}order/editBoxDetails`, BoxdetailsObject, {
          headers: {
            "x-access-token": accessToken
          }
        }).then((res) => {
          if (res.data.status === 200) {
            // setOrderSummyery(res.data.data)
            fetch_order_summary();
            // toastr.success("Box Detail saved")
            setActiveStep(3);
            window.scrollTo(0, 0);
            var data = res.data.data;
            var boxes = [];
            data.map((item, index) => {
              boxes.push({
                _id: item._id,
                box_no: index + 1,
                box_weight: item?.box_weight,
                packed_items: item?.packed_items,
                l: item?.l,
                b: item?.b,
                h: item?.h
              });
            })
            setboxValue(boxes);
          }
          else {
            toastr.error("Faild To Save Box Details")
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message)
          return;
        })
    } else {
      toastr.error("Total Box weight is not equal to the Total weight provided while creatimg the order")
    }
  }
  // =================================== Finalsubmit Order==================
  function finalSubmitOrder() {
    axios
      .get(`${API_URL}order/submit?order_id=${OrderObj_id}`, {
        headers: {
          "x-access-token": accessToken,
        }
      }).then((res) => {
        if (res.data.status === 200) {
          toastr.success("Order Created Successfully");
          setOrderObj_id(null);
          setSuccessPage(true);
        }
        else {
          toastr.error("Faild to create order")
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message)
        return;
      })
  }
  // =====================Order summmary==============
  function fetch_order_summary() {
    axios
      .get(`${API_URL}order/single-order?_id=${OrderObj_id}`, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {
        if (res.data.status === 200) {
          setSingleOrderList(res.data.data)

        } else {
          toastr.error("faild to get Summary")
          return;
        }
      })
  }

  // ======================Preupdatebasicdetails======================>
  // ==========================PreupdateItemDetailsupdate=============>
  /** Credit Customer  */
  const [selectedCreditCustObject, setselectedCreditCustObject] = useState(null);
  const [creditcustmasterObject, setcreditcustmasterObject] = useState({ branch_id: currentLocalbody });
  const [isCreditLoading, setisCreditLoading] = useState("");
  const handleValidSubmitCreditCust = () => {
    setisCreditLoading(true);
    axios
      .post(`${API_URL}customer/credit-customer`, creditcustmasterObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          fetch_credit_customers();
          var cust = res.data.data;
          setselectedCreditCust({
            label: cust?.name + " " + cust?.mobile_number,
            value: cust?._id
          })
          toastr.success("Customer Added Succesfully");
          formRefCreditCust.current.reset();
          setCreditCustModal(false);
          setselectedCreditCustObject({
            ...selectedCreditCustObject,
            "credit_cust_id": cust?._id
          })
          setcreditcustmasterObject({ branch_id: currentLocalbody });
        } else {
          toastr.error("Failed to add Customer");
          return;
        }
        setisCreditLoading("")
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        setisCreditLoading("")
        return;
      });
  };
  function handleChangecust(e) {
    let name = e.target.name;
    let value = e.target.value;
    setcreditcustmasterObject({ ...creditcustmasterObject, [name]: value });
  }
  function handleResetCust() {
    formRefCreditCust.current.reset();
    setcreditcustmasterObject({ name: "", address: "", mobile_number: "", email: "" });
  }


  // Rate
  function handleRateChange(e, index) {
    var list = [...values];
    var quantity = list[index]?.quantity;
    var rate = e.target.value;
    if (list[index]?.item_type != 5)
      var amount = (rate * quantity).toFixed(2);
    else
      var amount = rate;
    list[index].rate = rate;
    list[index].amount = parseFloat(amount);
    setvalues(list);
    Itemcalculation();
  }


  return (
    <React.Fragment>

      <Modal
        show={SuccessPage}
        isOpen={SuccessPage}
        toggle={() => setSuccessPage(!SuccessPage)}
        size="md"
        centered={true}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className='modal-header'>
          <h4 className='modal-title mt-0 ml-4'>Order Created Successfully</h4>

        </div>
        <div className="mt-2 mb-2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <h5>Please note your order ID: {SingleOrderList?.order_number}  </h5>
        </div>

        <div className="mt-2 mb-2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Button className="mx-2"

            onClick={() => {
              history.push("/dashboard");

            }}

            style={{ backgroundColor: "black", borderRadius: "2rem", color: "white", border: "none" }}>
            Home
          </Button>

          <Button className="mx-2"
            onClick={() => {
              history.push("/all-booking");

            }}
            style={{ backgroundColor: "#eb6517", borderRadius: "2rem", color: "white", border: "none" }}
          >

            View Order
          </Button>

        </div>

      </Modal>
      <Modal
        show={creditCustModal}
        isOpen={creditCustModal}
        toggle={() => setCreditCustModal(!creditCustModal)}
        size="md"
        centered={true}
      >
        <div class="modal-header">
          <h5 class="modal-title mt-0"> Create New Credit Customer</h5>
          <button type="button" class="close" data-dismiss="modal" onClick={() => { setCreditCustModal(!creditCustModal) }} aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <AvForm
            ref={formRefCreditCust}
            className="needs-validation"
            onValidSubmit={(e, v) => {
              handleValidSubmitCreditCust(e, v);
            }}
          >
            <Row>
              <Col md="6">
                <div >
                  <Label htmlFor="validationCustom01">
                    Name
                  </Label>
                  <AvField
                    name="name"
                    placeholder="Name"
                    type="text"
                    errorMessage="Enter Name"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom01"
                    value={creditcustmasterObject?.name}
                    onChange={handleChangecust}
                  />
                </div>
              </Col>
              <Col md="6">
                <div >
                  <Label htmlFor="validationCustom01">
                    Mobile
                  </Label>
                  <AvField
                    name="mobile_number"
                    placeholder="Mobile "
                    type="number"
                    errorMessage="Enter Mobile"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom01"
                    value={creditcustmasterObject?.mobile_number}
                    onChange={handleChangecust}
                  />
                </div>
              </Col>
              <Col md="6" className="mt-1">
                <div >
                  <Label htmlFor="validationCustom02">
                    Email
                  </Label>
                  <AvField
                    name="email"
                    placeholder="Email "
                    type="email"
                    errorMessage="Enter valid Email"
                    className="form-control"
                    validate={{ required: { value: false } }}
                    id="validationCustom02"
                    value={(creditcustmasterObject?.email) ? creditcustmasterObject?.email : ""}
                    onChange={handleChangecust}
                  />
                </div>
              </Col>

              <Col md="6" className="mt-1">
                <div >
                  <Label htmlFor="validationCustom01">
                    Address
                  </Label>
                  <AvField
                    style={{ lineHeight: "1.3" }}
                    name="address"
                    placeholder="Address"
                    type="textarea"
                    errorMessage="Enter Address"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom01"
                    value={creditcustmasterObject?.address}
                    onChange={handleChangecust}
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3 mt-2" >
                  <Button
                    style={{ background: "#eb6517" }}
                    type="submit"
                    disabled={isCreditLoading ? true : false}
                  >
                    {isCreditLoading ? "Adding" : "Submit"}
                  </Button>
                  <Button
                    className="ms-2"
                    style={{ background: 'black' }}
                    type="reset"
                    onClick={handleResetCust}
                  >Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </div>
      </Modal>


      {confirmDeletAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {

            axios
              .delete(`${API_URL}customer/saved-address/delete?customer_id=${addressIdTobeDeletedcust}&rece_id=${addressIdToBeDeletedrec}`, {
                headers: {
                  "x-access-token": accessToken,
                }
              })
              .then((res) => {
                if (res.data.status === 200) {

                  if (addressIdToBeDeletedrec.toString() == masterobjbasic?.receiver_id?.toString()) {
                    Settingreceiverdata();
                    setdeliverhere(-1);
                  }
                  toastr.success("Address Deleted Successfully");


                  axios
                    .get(`${API_URL}customer/saved-address?customer_id=${masterobjbasic?.sender_id}&rece_country=${masterobjbasic?.country_to}`, {
                      headers: {
                        "x-access-token": accessToken,
                      }
                    })
                    .then((res) => {
                      let result = res.data
                      setsavedData(result.data);
                    })
                }

                else {
                  toastr.error("Failed To Delete")
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setconfirmDeletAlert(false)
          }}
          onCancel={() => setconfirmDeletAlert(false)}
        >
          Are you sure want to delete Address?
        </SweetAlert>
      ) : null}
      {
        loader ? (

          <div className="page-content" style={{ paddingTop: "25px" }}>
            <div className="container-fluid">
              <Row>
                <Col md="12">

                  <Loader show={loader} type="box" message="Loading Data" />

                </Col>
              </Row>
            </div>
          </div>

        ) : (
          <>
            <div className="page-content" style={{ paddingBottom: "0px" }}>
              <div className="container-fluid">
                <h1 className="order-title-label">INTERNATIONAL ORDER</h1>
              </div>
            </div>
            <div class="tab-headers">
              <Stack sx={{ width: '64%' }} spacing={4}>
                {/* <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper> */}
                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Stack>
            </div>




            <div className="page-content" style={{ paddingTop: "25px" }}>
              <div className="container-fluid">
                {/************* Step 1 ***************/}
                {
                  activeStep == 0 ? (
                    <AvForm
                      className="needs-validation"
                      ref={formRefTab1}
                      onValidSubmit={(e, v) => {
                        handleValidSubmitTab1(e, v);
                      }}

                    >

                      <Row>
                        <Col xl="12">

                          <Row>
                            <Row>
                              <Col md="3">
                                <div className="mb-3">
                                  <Label>Cargo Type</Label>

                                  <Select
                                    styles={custStyle}
                                    name="cargo_type"
                                    value={selectcargo}
                                    options={cargooptions}
                                    placeholder="Select Cargo Type"
                                    onChange={(selected) => {
                                      handleSelectChange(selected, "cargo_type")
                                    }}
                                    classNamePrefix="select2-selection" />

                                  <p className='text-danger'
                                    style={{ fontSize: "11px" }}>
                                    {selectcargo === null ? errors.cargo_type : ""}
                                  </p>

                                </div>
                              </Col>

                              <Col md="3">
                                <div className="mb-3">
                                  <Label>Payment Mode</Label>
                                  <Select
                                    styles={custStyle}
                                    name="cashType"
                                    classNamePrefix="select2-selection"
                                    value={selectcashcredit}
                                    // defaultValue={{ label: "Cash", value: 2 }} // Set the default value to "Cash" with a value of 2
                                    options={[
                                      { label: "Cash", value: 2 },
                                      { label: "Credit", value: 1 },
                                      { label: "Card", value: 3 },
                                      { label: "Bank Transfer", value: 4 }
                                    ]}
                                    onChange={(selected) => {
                                      handleSelectChange(selected, "payment_type")
                                    }}

                                  />
                                  <p className='text-danger'
                                    style={{ fontSize: "11px" }}>
                                    {selectcashcredit === null ? errors.payment_type : ""}
                                  </p>
                                </div>
                              </Col>

                              <Col md="3">
                                <div className="mb-3">
                                  <Label>From</Label>

                                  <Select
                                    name="country_from"
                                    classNamePrefix="select2-selection"
                                    placeholder="Select Country"
                                    value={selectfromcountry}
                                    options={fromcountryoptions}
                                    onChange={(selected) => {
                                      handleSelectChange(selected, "country_from")
                                    }}

                                  />
                                  <p className='text-danger'
                                    style={{ fontSize: "11px" }}>
                                    {selectfromcountry === null ? errors.country_from : ""}
                                  </p>
                                </div>
                              </Col>

                              <Col md="3">
                                <div className="mb-3">
                                  <Label>To</Label>

                                  <Select

                                    name="country_to"
                                    classNamePrefix="select2-selection"
                                    placeholder="Select Country"
                                    value={selectetocountry}
                                    options={fromcountryoptions}
                                    onChange={(selected) => {
                                      handleSelectChange(selected, "country_to")
                                    }}

                                  />
                                  <p className='text-danger'
                                    style={{ fontSize: "11px" }}>
                                    {selectetocountry === null ? errors.country_to : ""}
                                  </p>

                                </div>
                              </Col>

                              <Col md="3">
                                <div className="mb-4">
                                  <Label>Pickup/Offline reference</Label>
                                  <AvField
                                    name="pickup"
                                    placeholder="Pickup/Offline reference"
                                    type="text"
                                    className="form-control"
                                    // validate={{ required: { value: true } }}
                                    onChange={handleInput}
                                    value={masterobjbasic?.pickup}
                                    tabIndex="-1"
                                  />

                                </div>

                              </Col>

                            </Row>


                            {/* CONSIGNOR / SENDER */}
                            <Row>
                              <Col>
                                <h5 className="alfarha"> Consignor/Sender</h5>
                                <Row>

                                  <Col md="3">
                                    <div className="mb-4" ref={wrapperRef}>
                                      <Label>Name</Label>
                                      <AvField

                                        name="senderName"
                                        placeholder="Name"
                                        type="text"
                                        // onClick={() => setdisplay(!display)}
                                        className="form-control"
                                        onChange={handleSelectChangesearchname}
                                        value={masterobjbasic?.senderName}
                                        autoComplete={"off"}
                                        style={{ backgroundColor: '#f9f6f3' }}
                                        validate={{ required: { value: true } }}
                                        errorMessage="Enter Sender Name"
                                      />
                                    </div>
                                    <div ref={refname} className="dropdownAuto" style={{ backgroundColor: "white" }}>
                                      {

                                        searchdata &&
                                        searchdata?.data?.map((el, index) => {
                                          return (
                                            <>
                                              <div className="dropdownItem mt-2"
                                                onClick={() => {
                                                  settingmaster(el);
                                                  setreceiver(true);
                                                  setaddnew(true);
                                                  setaddressIdTobeDeletedcust(el._id);

                                                }}
                                              >
                                                <p style={{ color: "black", fontWeight: 500 }} >
                                                  {el?.name}</p>
                                              </div>
                                            </>


                                          )
                                        })
                                      }
                                    </div>

                                  </Col>

                                  <Col md="3">
                                    <div className="mb-4">
                                      <Label>ID No</Label>
                                      <AvField
                                        name="senderidNumber"
                                        placeholder="ID.No"
                                        type="text"
                                        className="form-control mb-2 inptFielsd"
                                        value={masterobjbasic?.senderidNumber}
                                        onChange={handleInput}
                                      // validate={{ required: { value: true } }}
                                      // errorMessage="Enter ID.NO"
                                      // tabIndex="-1"
                                      />
                                    </div>
                                  </Col>

                                  <Col md="3">
                                    <div className="mb-4">
                                      <Label>Mobile No.</Label>
                                      {/* <AvField
                                        name='senderMobileNumber'
                                        type="number"
                                        id="phone"
                                        autoComplete={"off"}
                                        className="form-control"
                                        validate={{ required: { value: true } }}
                                        errorMessage="Enter mobile number"
                                        style={{ backgroundColor: "#f9f6f3", width: "253px" }}
                                        onChange={handleSelectChangesearchnumber}
                                        value={masterobjbasic?.senderMobileNumber}
                                      />  */}

                                      <PhoneInput
                                        name='senderMobileNumber' // Provide a valid name for the input
                                        country={'om'}
                                        value={masterobjbasic?.senderMobileNumber}
                                        inputProps={{
                                          required: true,
                                          autoFocus: true,
                                          autoComplete: 'off',
                                          style: { width: "255px", backgroundColor: "#f9f6f3" }
                                        }}
                                        onChange={(value, country, event) => handleSelectChangesearchnumber(value, event)}
                                        validate={{ required: { value: true } }}
                                        errorMessage="Enter mobile number"
                                      />


                                      {/* <span id="valid-msg" class="hide" style={{ color: "green" }}></span> */}

                                    </div>
                                    <div ref={refnumber} className="dropdownAuto">
                                      {
                                        searchnumber &&
                                        searchnumber?.data?.map((el, index) => {
                                          return (
                                            <>
                                              <div className="dropdownItem"
                                                onClick={() => {
                                                  settingmaster(el)
                                                }}
                                              >
                                                <p >
                                                  {el?.mobile_number}</p>
                                              </div>
                                            </>

                                          )

                                        })
                                      }
                                    </div>

                                  </Col>

                                  <Col md="3">
                                    <div className="mb-4">
                                      <Label>Whatsapp No.</Label>
                                      {/* <AvField
                                        name='senderWhatsappNumber'
                                        type="number"
                                        id="whatsapp"
                                        className="form-control"
                                        onWheel={(e) => e.target.blur()}
                                        onChange={handleInput}
                                        style={{ width: "253px" }}
                                        value={masterobjbasic?.senderWhatsappNumber}
                                        tabIndex="-1"
                                      /> */}

                                      <PhoneInput
                                        country={'om'}
                                        name='senderWhatsappNumber'
                                        value={masterobjbasic?.senderWhattsappNumber}
                                        inputProps={{
                                          required: true,
                                          autoFocus: true,
                                          autoComplete: 'off',
                                          style: { width: "255px" },
                                          tabIndex: -1
                                        }}
                                        onChange={(value, country, event) => handleWhatsapp(value, event)}
                                        tabIndex="-1"
                                      />


                                    </div>
                                  </Col>

                                  {
                                    selectcashcredit?.value == 1 ? (
                                      <>
                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label>Credit Customer</Label>
                                            <InputGroup className="inputGroup">
                                              <div className="input-group-append" style={{ width: "86%" }}>
                                                <Select
                                                  styles={custStyle}
                                                  name="credit_cust_id"
                                                  value={selectedCreditCust}
                                                  options={creditCustomerOptions}
                                                  placeholder="Select Credit Customer"
                                                  onChange={(selected) => {
                                                    handleSelectChange(selected, "credit_custmer")
                                                  }}
                                                  classNamePrefix="select2-selection"
                                                />
                                              </div>

                                              <div
                                                className="input-group-append"
                                                onClick={() => {
                                                  setCreditCustModal(true);
                                                }}
                                              >
                                                <Button type="button" color="primary" className="select-group-btn">
                                                  <i className="mdi mdi-plus" />
                                                </Button>
                                              </div>
                                            </InputGroup>
                                            <p className='text-danger'
                                              style={{ fontSize: "11px" }}>
                                              {selectedCreditCust === null ? errors.credit_cust : ""}
                                            </p>

                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-4">
                                            <Label>Reference</Label>
                                            <AvField
                                              name="reference"
                                              placeholder="Reference"
                                              type="text"
                                              className="form-control"
                                              value={(selectedCreditCustObject?.reference) ? selectedCreditCustObject?.reference : ""}
                                              onChange={handleInput}
                                            // validate={{ required: { value: true } }}
                                            // errorMessage="Enter Reference"
                                            />
                                          </div>
                                        </Col>
                                      </>

                                    ) : null
                                  }

                                </Row>

                                <div class="row">

                                </div>
                                {/* {
                                  savedData.length > 0 ? (
                                    <Row className="mb-4">
                                      {
                                        savedData &&
                                        savedData?.map((el, index) => {
                                          return (
                                            <>
                                              <Col md="3" className="mt-3">
                                                <div
                                                  style={{
                                                    background: deliverhere === index ? "#f9f6f3" : "white",
                                                  }}

                                                  class="card deliver">

                                                  <div
                                                    className="editupdate"
                                                    style={{ display: "flex", gap: "8px" }}>
                                                    <i

                                                      style={{
                                                        color: "gray",
                                                        cursor: "pointer",
                                                      }}
                                                      class="fas fa-edit"
                                                      onClick={() => {
                                                        setreceiver(true);
                                                        setdeliverhere(index);
                                                        settingmasterreceiver(el);
                                                      }}
                                                    ></i>
                                                    <i
                                                      onClick={() => {

                                                        setaddressIdToBeDeletedrec(el._id);
                                                        setconfirmDeletAlert(true);
                                                      }}
                                                      style={{
                                                        color: "gray",
                                                        cursor: "pointer",
                                                      }}
                                                      class="fas fa-trash"
                                                    ></i>
                                                  </div>

                                                  <div class="card-body"
                                                    onClick={() => {
                                                      setreceiver(true);
                                                      settingmasterreceiver(el)
                                                      setdeliverhere(index)
                                                    }}
                                                  >
                                                    {
                                                      deliverhere === index ? (
                                                        <p class="deliver-badge">
                                                          <i class="fa fa-map-marker"> </i>
                                                          Deliver Here
                                                        </p>
                                                      ) : null
                                                    }


                                                    <div className="deliver-content">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          gap: "8px",
                                                        }}
                                                      >
                                                        <i
                                                          style={{ color: "#eb6517" }}
                                                          class="fas fa-user"
                                                        ></i>

                                                        <h6 class="text-muted mb-0">
                                                          {el?.name}
                                                        </h6>
                                                      </div>

                                                    </div>
                                                    <hr style={{ margin: ".5rem 0" }}></hr>
                                                    <p
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "start",
                                                        gap: "8px",
                                                      }}
                                                      class="text-muted mt-1 mb-0"
                                                    >
                                                      <i
                                                        style={{
                                                          color: "#eb6517",
                                                          lineHeight: "20px",
                                                        }}
                                                        class="fa fa-address-book"
                                                      >
                                                        {" "}
                                                      </i>
                                                      <span class="text-muted me-1">
                                                        {" "}
                                                        {el?.house_name} <br />
                                                        {el?.rece_state?.name}<br />
                                                        {el?.landmark}
                                                      </span>
                                                    </p>
                                                    <p
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "start",
                                                        gap: "8px",
                                                      }}
                                                      class="text-muted mt-1 mb-0"
                                                    >
                                                      <i
                                                        style={{
                                                          color: "#eb6517",
                                                          lineHeight: "20px",
                                                        }}
                                                        class="fa fa-phone"
                                                      >
                                                        {" "}
                                                      </i>
                                                      <span class="text-muted  me-1">
                                                        {" "}
                                                        {el?.mobile_number}
                                                      </span>
                                                    </p>
                                                  </div>
                                                </div>
                                              </Col>
                                            </>

                                          )
                                        })


                                      }
                                      {
                                        addnew &&
                                        <Col md="3" className="mt-3">
                                          <div
                                            class="card  newcard"
                                            onClick={() => {
                                              Settingreceiverdata()
                                              setdeliverhere(-1);
                                            }}
                                          >
                                            <div class="card-body">
                                              <div className="plusbtn">
                                                <i class="fa fa-plus"></i>
                                              </div>
                                              <span className="btnspan">Add New Receiver Address</span>
                                            </div>
                                          </div>
                                        </Col>
                                      }



                                    </Row>
                                  ) : null
                                } */}

                                <Row className="mb-4">

                                  {
                                    savedData &&
                                    savedData?.map((el, index) => {
                                      var formattedNumber = el?.mobile_number?.replace(/\D/g, ''); // Remove non-numeric characters
                                      let spacedNumber = '';
                                      for (let i = 0; i < formattedNumber.length; i += 3) {
                                        spacedNumber += formattedNumber.slice(i, i + 3) + ' ';
                                      }
                                      var mobile = spacedNumber.trim();
                                      if (el?.telephone_number) {
                                        var formattedNumber1 = el?.telephone_number?.replace(/\D/g, ''); // Remove non-numeric characters
                                        let spacedNumber1 = '';
                                        for (let i = 0; i < formattedNumber1.length; i += 3) {
                                          spacedNumber1 += formattedNumber1.slice(i, i + 3) + ' ';
                                        }
                                        var tele = spacedNumber1.trim();
                                      }
                                      else {
                                        var tele = "Not available"
                                      }
                                      return (
                                        <div class="col-xl-3 col-sm-6" >
                                          <div class={deliverhere === index ? "card active-card-box my-card" : "card my-card"}>
                                            <div className="sub-card-body">
                                              {
                                                deliverhere === index ? (
                                                  <p class="deliver-badge">
                                                    <i class="fa fa-map-marker"> </i>
                                                    Deliver Here
                                                  </p>
                                                ) : null
                                              }
                                              <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 me-3">
                                                  <div class="avatar-xs">
                                                    <span class="avatar-title rounded-circle bg-primary-subtle">{el?.name?.substring(0, 1)?.toUpperCase()}</span>
                                                  </div>
                                                </div>
                                                <div class="flex-grow-1">
                                                  <h5 class="font-size-14 mb-0 toCapitalize">{el?.name.toLowerCase()}</h5>
                                                  <span class="badge mb-0 toCapitalize my-address-badge">{el?.district?.toLowerCase() + ", " + el?.rece_state?.name?.toLowerCase()}</span>
                                                </div>
                                                <div>
                                                  <i
                                                    onClick={() => {
                                                      setaddressIdToBeDeletedrec(el._id);
                                                      setconfirmDeletAlert(true);
                                                    }}
                                                    style={{
                                                      color: "gray",
                                                      cursor: "pointer",
                                                    }}
                                                    class="fas fa-trash"
                                                  ></i>

                                                </div>
                                              </div>
                                              {/* <div class="d-flex align-items-center">
                  <div class=" avatar-md rounded-circle img-thumbnail" style={{
                    textAlign: 'center',
                    padding: '8px',
                    background: '#eb651724',
                  }}><i class="fa fa-user" style={{
                    color: ' #eb6517', fontSize: "21px"
                  }}></i></div>
                  <div class="flex-1 ms-2">
                    <h5 class="font-size-15 mb-1"><a href="#" class="text-dark toCapitalize">{el?.name}</a></h5>
                    <span class="badge mb-0 toCapitalize my-address-badge">{el?.district + ", " + el?.rece_state?.name}</span>
                  </div>
                  <div>
                    <i
                      onClick={() => {

                        setaddressIdToBeDeletedrec(el._id);
                        setconfirmDeletAlert(true);
                      }}
                      style={{
                        color: "gray",
                        cursor: "pointer",
                      }}
                      class="fas fa-trash"
                    ></i>

                  </div>
                </div> */}
                                            </div>
                                            <div class="card-body" style={{ padding: "10px", paddingTop: "0px", cursor: "pointer" }}
                                              onClick={() => {
                                                setreceiver(true);
                                                settingmasterreceiver(el)
                                                setdeliverhere(index)
                                              }}
                                            >

                                              <hr style={{
                                                padding: '0px',
                                                margin: '8px',
                                                color: '#eb6517'
                                              }}
                                              />
                                              <div class="mt-1 pt-1">
                                                <p class="text-muted mb-0"><i class="mdi mdi-phone font-size-15 align-middle pe-2 text-primay" style={{ color: "#eb6517a8" }}></i> {mobile}</p>
                                                <p class="text-muted mb-0 mt-2"><i class="mdi mdi-phone-classic font-size-15 align-middle pe-2 text-primasry" style={{ color: "#eb6517a8" }}></i> {tele}</p>
                                                <p class="text-muted mb-0 mt-2 toCapitalize">
                                                  <Row>
                                                    <Col md="1"><i class="mdi mdi-map-marker-radius font-size-17 align-middle pe-2 text-primarys" style={{ color: "#eb6517a8" }}></i>
                                                    </Col>
                                                    <Col md="11" className="ps-3">
                                                      {el?.house_name?.toLowerCase() + " " + (el?.street ? el.street.toLowerCase() : "")}{" "}{(el?.landmark ? el.landmark.toLowerCase() : "") + " " + (el?.post_office ? el.post_office.toLowerCase() : "") + " " + el?.pin_code}
                                                    </Col>
                                                  </Row>
                                                </p>
                                                {/* <p class="text-muted mb-0 mt-2 toCapitalize"><i class="mdi mdi-map-marker-radius font-size-17 align-middle pe-2 text-primasry" style={{ color: "#eb6517a8" }}></i> {el?.district + ", " + el?.rece_state?.name}</p> */}

                                              </div></div></div>
                                        </div>
                                      )

                                    })}
                                  {
                                    addnew &&
                                    <Col md="3">
                                      <div
                                        class="card  newcard my-card"
                                        onClick={() => {
                                          Settingreceiverdata()
                                          setdeliverhere(-1);
                                        }}
                                      >
                                        <div class="card-body">
                                          <div className="plusbtn">
                                            <i class="fa fa-plus" style={{ fontSize: '26px' }} ></i>
                                          </div>
                                          <span className="btnspan mt-3">Add New Receiver Address</span>
                                        </div>
                                      </div>
                                    </Col>
                                  }
                                </Row>


                                {/* CONSIGNEE / RECEIVER */}

                                <Row>
                                  {
                                    receiver &&
                                    <Col>
                                      <h5 className="alfarha">
                                        Consignee/Receiver
                                      </h5>

                                      <Row>
                                        <Col md="3">
                                          <div className="mb-2" ref={wraaRef}>
                                            <Label>Name</Label>
                                            <AvField
                                              name="receiverName"
                                              placeholder="Name"
                                              className="form-control"
                                              type="text"
                                              autoComplete={"off"}
                                              onChange={handleSelectChangesearchnameReceiver}
                                              style={{ backgroundColor: '#f9f6f3' }}
                                              value={masterobjbasic?.receiverName ? masterobjbasic?.receiverName : ""}
                                              validate={{ required: { value: true } }}
                                              errorMessage="Enter Receiver Name"

                                            />
                                          </div>
                                          <div ref={refnameReceiver} className="dropdownAuto" style={{ backgroundColor: "white" }}>
                                            {

                                              searchdataReceiver &&
                                              searchdataReceiver?.data?.map((el, index) => {
                                                return (
                                                  <>
                                                    <div className="dropdownItem mt-2"
                                                      onClick={() => {
                                                        settingmasterreceiver(el);

                                                      }}
                                                    >
                                                      <p style={{ color: "black", fontWeight: 500 }} >
                                                        {el?.name}</p>
                                                    </div>
                                                  </>


                                                )
                                              })
                                            }
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>ID No</Label>
                                            <AvField
                                              name="receiveridNumber"
                                              placeholder="ID.NO"
                                              className="form-control"
                                              type="text"
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiveridNumber}
                                              // validate={{ required: { value: true } }}
                                              // errorMessage="Enter ID.NO"
                                              tabIndex="-1"
                                            />
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>House Name</Label>
                                            <AvField
                                              name="receiverHouseName"
                                              placeholder="House Name"
                                              className="form-control"
                                              type="text"
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiverHouseName}
                                            // validate={{ required: { value: true } }}
                                            // errorMessage="Enter House Name/Number"
                                            />
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>Land Mark</Label>
                                            <AvField
                                              name="receiverLandmark"
                                              placeholder="Land Mark"
                                              className="form-control"
                                              type="text"
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiverLandmark}
                                            />
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>Street</Label>
                                            <AvField
                                              name="receiverStreet"
                                              placeholder="Street"
                                              className="form-control"
                                              type="text"
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiverStreet}
                                            />
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>Post Office</Label>
                                            <AvField
                                              name="receiverPostOffice"
                                              placeholder="Post Office"
                                              className="form-control"
                                              type="text"
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiverPostOffice}
                                              validate={{ required: { value: true } }}
                                              errorMessage="Enter Post Office"
                                            />
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>Pin Code</Label>
                                            <AvField
                                              name="receiverPinCode"
                                              placeholder="Pin Code"
                                              className="form-control"
                                              type="text"
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiverPinCode}
                                              validate={{
                                                // required: { value: true },
                                                pattern: {
                                                  value: /^[0-9a-zA-Z ]*$/, // Regular expression to allow digits, alphabets, and spaces
                                                },
                                              }}
                                            />
                                          </div>
                                        </Col>


                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>District</Label>
                                            <AvField
                                              name="receiverDistrict"
                                              placeholder="District"
                                              className="form-control"
                                              type="text"
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiverDistrict}
                                              validate={{ required: { value: true } }}
                                              errorMessage="Enter District"
                                            />
                                          </div>
                                        </Col>


                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>State</Label>
                                            <Select
                                              name="receiverState"
                                              classNamePrefix="select2-selection"
                                              options={stateoptions}
                                              value={selecetdstate}
                                              onChange={(selected) => {
                                                handleSelectChange(selected, "receiverState")
                                              }}
                                            />
                                          </div>
                                          <p className='text-danger'
                                            style={{ fontSize: "11px" }}>
                                            {errors?.state ? errors?.state : ""}
                                          </p>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-2" ref={wrrRef}>
                                            <Label>Mobile No.</Label>
                                            {/* <AvField
                                              name="receiverMobileNumber"
                                              // placeholder="Mobile No."
                                              id="contact"
                                              type="number"
                                              autoComplete={"off"}
                                              className="form-control"
                                              validate={{ required: { value: true } }}
                                              errorMessage="Enter mobile number"
                                              style={{ backgroundColor: '#f9f6f3', width: "253px" }}
                                              // minLength={10}
                                              // maxLength={10}
                                              onChange={handleSelectChangesearchnumberReceiver}
                                              value={masterobjbasic?.receiverMobileNumber}

                                            /> */}

                                            <PhoneInput
                                              name='receiverMobileNumber'
                                              country={'om'}
                                              value={masterobjbasic?.receiverMobileNumber}
                                              inputProps={{
                                                required: true,
                                                autoFocus: true,
                                                autoComplete: 'off',
                                                style: { width: "255px", backgroundColor: "#f9f6f3" }
                                              }}
                                              onChange={(value, country, event) => handleSelectChangesearchnumberReceiver(value, event)}
                                              validate={{ required: { value: true } }}
                                              errorMessage="Enter mobile number"
                                            />
                                          </div>


                                          <div ref={refnumberReceiver} className="dropdownAuto">
                                            {
                                              searchnumberReceiver &&
                                              searchnumberReceiver?.data?.map((el, index) => {
                                                return (
                                                  <>
                                                    <div className="dropdownItem"
                                                      onClick={() => {
                                                        settingmasterreceiver(el)
                                                      }}
                                                    >
                                                      <p >
                                                        {el?.mobile_number}</p>
                                                    </div>
                                                  </>

                                                )

                                              })
                                            }
                                          </div>
                                        </Col>


                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>Telephone No.</Label>
                                            {/* <AvField
                                              name="receiverTelephoneNumber"
                                              // placeholder="Telephone No."
                                              type="phone"
                                              className="form-control"
                                              id="telephone"
                                              // minLength={11}
                                              // maxLength={11}
                                              style={{ width: "253px" }}
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiverTelephoneNumber ? masterobjbasic?.receiverTelephoneNumber : ""}
                                            /> */}

                                            <PhoneInput
                                              country={'om'}
                                              name="receiverTelephoneNumber"
                                              value={masterobjbasic?.receiverTelephoneNumber}
                                              inputProps={{
                                                required: true,
                                                autoFocus: true,
                                                autoComplete: 'off',
                                                style: { width: "255px" },
                                                tabIndex: -1
                                              }}
                                              onChange={(value, country, event) => handleTelephoneChange(value, event)}
                                            />


                                          </div>
                                        </Col>

                                      </Row>
                                    </Col>
                                  }
                                </Row>

                              </Col>
                            </Row>
                          </Row>


                        </Col>
                      </Row>

                      <Row>

                        <Col className="btnlist py-3">
                          {/* <button
                      type="reset"
                      onClick={() => { setActiveStep(1) }}
                      className="refresh mx-2">Next test</button> */}
                          <button
                            type="reset"
                            onClick={() => { resetTab1() }}
                            className="refresh mx-2"><RefreshIcon /></button>

                          {BasicDetailsTobeUpdated ? (
                            <button
                              className="submit mx-2"
                              type="update"                            >
                              Update&Next
                            </button>
                          ) :
                            <button
                              className="submit mx-2"
                              type="submit"
                            >
                              Next
                            </button>}


                        </Col>

                      </Row>
                    </AvForm>
                  ) : null
                }

                {/************* Step 2 ***************/}
                {
                  activeStep == 1 ? (
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmitTab2(e, v)
                      }}
                      ref={formRefTab2}
                    >
                      <Row className="mt-4">
                        <Col xl="12">
                          <Table
                            id="OrderItemTable"
                            style={{ textAlign: "center" }}
                            className="table table-bordered dataTable"
                          >
                            <TableHead className="table table-bordered dataTable">
                              <TableRow style={{ textAlign: "center" }}>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                  }}
                                >
                                  #
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",

                                  }}
                                >
                                  Item Type
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",

                                  }}
                                >
                                  Item Category
                                </TableCell>

                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",

                                  }}
                                >
                                  Description
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",

                                  }}
                                >
                                  Unit
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",

                                  }}
                                >
                                  Quantity
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",

                                  }}
                                >
                                  Rate
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",

                                  }}
                                >
                                  Amount
                                </TableCell>
                                {values.length !== 1 ? (
                                  <TableCell
                                    style={{
                                      width: "0px",
                                      textAlign: "center",
                                      fontWeight: "normal",
                                      paddingLeft: "0rem",
                                      paddingRight: "0rem",
                                      fontWeight: "700",
                                      fontSize: "0.875rem",
                                      color: "#282828",

                                    }}
                                  >
                                    Action
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values &&
                                values.map((item, index) => (

                                  <TableRow>
                                    <TableCell
                                      component="th"
                                      scope="row"
                                      style={{ textAlign: "center" }}
                                    >
                                      {index + 1}
                                    </TableCell>

                                    <TableCell>
                                      <Select
                                        name="item_type"
                                        placeholder="Item Type"
                                        options={[
                                          { label: "Item", value: 1 },
                                          { label: "Packing", value: 2 },
                                          { label: "Extra", value: 3 },
                                          { label: "Package", value: 4 },
                                        ]}

                                        classNamePrefix={(item?.item_type_error) ? "invalid-select select2-selection" : "select2-selection"}
                                        // value={item.itemtypeObject}
                                        value={{
                                          label: item?.item_typename,
                                          value: item?.item_type,
                                        }}
                                        onChange={(selected) => {
                                          handleSelectChange(selected, "item_type", index)
                                        }}
                                      />
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {item?.item_type_error}
                                      </div>
                                    </TableCell>

                                    <TableCell>
                                      <Select
                                        name="item_category"
                                        placeholder="Category"
                                        // value={item.itemCatObject}
                                        value={{
                                          label: item?.item_categoryName,
                                          value: item?.item_category
                                        }}
                                        options={item?.categoryOptions}
                                        onChange={(selected,) => {
                                          handleSelectChange(selected, "item_category", index)
                                        }}
                                        classNamePrefix={(item?.item_category_error) ? "invalid-select select2-selection" : "select2-selection"}

                                      />
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {item?.item_category_error}
                                      </div>
                                    </TableCell>

                                    <TableCell>
                                      <AvField
                                        name="description"
                                        type="text"
                                        placeholder="Description"
                                        value={item.description}
                                        onChange={(e) => {
                                          handledescripchange(e, index)
                                        }}
                                      />
                                    </TableCell>

                                    <TableCell>
                                      <AvField
                                        name="unit"
                                        type="text"
                                        readOnly={true}
                                        onWheel={(e) => e.target.blur()}
                                        placeholder="Unit"
                                        value={item?.unit}
                                        tabIndex="1"
                                      />
                                    </TableCell>

                                    <TableCell>
                                      <AvField
                                        name="quantity"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder="Quantity"
                                        value={item?.quantity}
                                        onChange={(e) => {
                                          handleUnitchange(e, index)
                                        }}
                                        style={{
                                          borderColor: item?.quantity_error
                                            ? "#f46a6a"
                                            : "#ced4da",
                                        }}
                                      // validate={{ required: { value: true } }}
                                      // errorMessage="Enter Quantity"
                                      />
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {item?.quantity_error}
                                      </div>
                                    </TableCell>

                                    <TableCell>
                                      <AvField
                                        name="rate"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder="Rate"
                                        value={item?.rate}
                                        // onClick={() => addMore()} // Will be called when the user clicks on the input field                                        
                                        onChange={(e) => {
                                          handleRateChange(e, index)
                                        }}
                                        onKeyPress={(e) => {
                                          if (e.key === "Enter") {
                                            addMore(); // Will be called when the user presses the Enter key on the input field
                                          }
                                        }}
                                      // readOnly={true}
                                      />
                                    </TableCell>

                                    <TableCell>
                                      <AvField
                                        name="amount"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder="Amount"
                                        readOnly={true}
                                        value={item?.amount}
                                        onClick={() => addMore()}
                                        tabIndex="1"
                                      />
                                    </TableCell>

                                    {values.length !== 1 && (
                                      <TableCell>
                                        <i
                                          className="uil uil-times"
                                          style={{
                                            fontSize: "20px",
                                            cursor: "pointer",
                                            paddingLeft: "5px",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                          onClick={() => handleRemove(index)}
                                        ></i>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                        </Col>
                      </Row>

                      <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>

                        <span className="bg-dark badge bg-secondary font-size-12 addspan"
                          onClick={() => addMore()}
                        ><i class="mdi mdi mdi-plus-thick"></i> New Item</span>
                      </div>
                      <br />

                      <Col>

                        <h5 className="alfarha">Cargo Details</h5>
                        <Row className="py-2" style={{ rowGap: "10px" }} >
                          <Col md="3" >
                            <Label>No of Boxes</Label>
                            <AvField
                              name="no_of_boxes"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              placeholder="No of boxes"
                              onChange={handlenofBoxchanges}
                              value={(ItemDetailsObject?.no_of_boxes) ? ItemDetailsObject.no_of_boxes : ""}
                              validate={{ required: { value: true } }}
                              errorMessage="Enter No. Of Boxes"
                            />
                          </Col>

                          <Col md="3">
                            <Label>Total Weight</Label>
                            <AvField
                              name="weight"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              placeholder="Total weight"
                              value={(ItemDetailsObject?.total_weight) ? ItemDetailsObject.total_weight : ""}
                              readOnly={true}
                              tabIndex="-1"
                            />
                          </Col>


                          <Col md="3">
                            <Label>Handling Rate</Label>
                            <AvField
                              name="rate"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              placeholder="Handling rate"
                              // value={(ItemDetailsObject?.handling_rate) ? ItemDetailsObject.handling_rate : ""}
                              value={ItemDetailsObject?.handling_rate !== undefined ? ItemDetailsObject?.handling_rate : handlingRate}
                              readOnly={true}
                              tabIndex="-1"
                            />
                          </Col>


                          <Col md="3">
                            <Label>Total Handling Rate</Label>
                            <AvField
                              name="handling_rate_total"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              placeholder="Total Handling rate"
                              readOnly={true}
                              value={(ItemDetailsObject?.handling_rate_total) ? ItemDetailsObject.handling_rate_total : ""}
                              tabIndex="-1"
                            />
                          </Col>

                          {/* <Col md-3>
                             <Label>Discount</Label>
                            <AvField
                              name="discount"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              placeholder="Discount"
                            />
                            </Col> */}

                          <Col md="3">
                            <Label>VAT %</Label>
                            <AvField
                              name="vat_percentage"
                              type="number"
                              readOnly="true"
                              value={(ItemDetailsObject?.vat_percentage) ? ItemDetailsObject.vat_percentage : ""}
                              onWheel={(e) => e.target.blur()}
                              placeholder="VAT %"
                              tabIndex="-1"
                            />
                          </Col>

                          <Col md="3">
                            <Label>VAT Amount</Label>
                            <AvField
                              name="vatamount"
                              type="number"
                              readOnly="true"
                              value={(ItemDetailsObject?.vat_amount) ? ItemDetailsObject.vat_amount : ""}
                              onWheel={(e) => e.target.blur()}
                              placeholder="VAT Amount"
                              tabIndex="-1"
                            />
                          </Col>

                          <Col md="3">
                            <Label>Amount</Label>
                            <AvField
                              name="amount"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              placeholder="Amount"
                              value={(ItemDetailsObject?.amount) ? ItemDetailsObject.amount : ""}
                              readOnly={true}
                              tabIndex="-1"
                            />
                          </Col>

                          <Col md="3">
                            <Label>Total Value</Label>
                            <AvField
                              name="total_value"
                              type="number"
                              value={(ItemDetailsObject?.total_value) ? ItemDetailsObject.total_value : ""}
                              onChange={(e) => {
                                setItemDetailsObject({
                                  ...ItemDetailsObject,
                                  "total_value": e.target.value
                                });
                              }}
                              validate={{ required: { value: true } }}
                              errorMessage="Enter Total Value"
                              onWheel={(e) => e.target.blur()}
                              placeholder="Total Value"
                            />
                          </Col>

                          {/* <Col md-3>
                    <Label>Recieved Cash</Label>
                    <AvField
                      name="discount"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Recieved Cash"
                    />
                  </Col> */}

                          {/* <Col md-3>
                    <Label>Credit Balance</Label>
                    <AvField
                      name="vatpercent"
                      type="number"
                      onWheel={(e) => e.target.blur()}
                      placeholder="Credit Balance"
                    />
                  </Col> */}

                          <Col md="3">
                            <Label>Grand Total</Label>
                            <AvField
                              name="grand_total"
                              type="number"
                              onWheel={(e) => e.target.blur()}
                              placeholder="Grand Total"
                              value={(ItemDetailsObject?.grand_total) ? ItemDetailsObject.grand_total : ""}
                              readOnly={true}
                              tabIndex="-1"
                            />
                          </Col>

                        </Row>

                      </Col>

                      <Row>
                        <Col className="btnlist py-3">
                          {/* <button
                      type="reset"
                      onClick={() => { setActiveStep(2) }}
                      className="refresh mx-2">Next test</button> */}
                          <button
                            className="back1"
                            type="reset"
                            onClick={() => {
                              {
                                //preupdateBasicdetails()
                                setActiveStep(0);
                                window.scrollTo(0, 0);
                              }
                            }}
                          >
                            <i class="fa fa-long-arrow-alt-left"></i>
                          </button>
                          <button
                            type="reset"
                            onClick={() => resetTab2()}
                            className="refresh mx-2"

                          ><RefreshIcon /></button>
                          <button
                            className="submit mx-2"
                            type="submit"
                          >
                            Next
                          </button>


                        </Col>
                      </Row>
                    </AvForm>
                  ) : null
                }


                {/************* Step 3 ***************/}

                {
                  activeStep == 2 ? (
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmitTab3(e, v)
                      }}
                      ref={formRefTab3}

                    >

                      <Col className="mt-3" xl="12">

                        <Row>

                          <Col className="mt-4" lg="12">
                            <Row>
                              <Col xl="12">
                                <Table
                                  id="OrderItemTable"
                                  style={{ textAlign: "center" }}
                                  className="table table-bordered dataTable"
                                >
                                  <TableHead className="table table-bordered dataTable">
                                    <TableRow style={{ textAlign: "center" }}>
                                      <TableCell
                                        style={{
                                          width: "0px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                          paddingLeft: "0rem",
                                          paddingRight: "0rem",
                                          fontWeight: "700",
                                          fontSize: "0.875rem",
                                          color: "#282828",
                                        }}
                                      >
                                        Box.No
                                      </TableCell>
                                      <TableCell
                                        style={{
                                          width: "0px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                          paddingLeft: "0rem",
                                          paddingRight: "0rem",
                                          fontWeight: "700",
                                          fontSize: "0.875rem",
                                          color: "#282828",

                                        }}
                                      >
                                        Weight
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          width: "0px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                          paddingLeft: "0rem",
                                          paddingRight: "0rem",
                                          fontWeight: "700",
                                          fontSize: "0.875rem",
                                          color: "#282828",

                                        }}
                                      >
                                        L
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          width: "0px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                          paddingLeft: "0rem",
                                          paddingRight: "0rem",
                                          fontWeight: "700",
                                          fontSize: "0.875rem",
                                          color: "#282828",

                                        }}
                                      >
                                        B
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          width: "0px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                          paddingLeft: "0rem",
                                          paddingRight: "0rem",
                                          fontWeight: "700",
                                          fontSize: "0.875rem",
                                          color: "#282828",

                                        }}
                                      >
                                        H
                                      </TableCell>

                                      <TableCell
                                        style={{
                                          width: "0px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                          paddingLeft: "0rem",
                                          paddingRight: "0rem",
                                          fontWeight: "700",
                                          fontSize: "0.875rem",
                                          color: "#282828",

                                        }}
                                      >
                                        {/* Packed Items */}
                                        Description
                                      </TableCell>

                                      {boxValue.length !== 1 ? (
                                        <TableCell
                                          style={{
                                            width: "0px",
                                            textAlign: "center",
                                            fontWeight: "normal",
                                            paddingLeft: "0rem",
                                            paddingRight: "0rem",
                                            fontWeight: "700",
                                            fontSize: "0.875rem",
                                            color: "#282828",
                                          }}
                                        >
                                          Action
                                        </TableCell>
                                      ) : null}
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {boxValue &&
                                      boxValue.map((item, index) => {

                                        return (
                                          <TableRow>
                                            <TableCell

                                              component="th"
                                              scope="row"
                                              style={{ textAlign: "center", width: "100px" }}

                                            >

                                              {index + 1}
                                            </TableCell>

                                            <TableCell style={{ width: "100px" }}>
                                              <AvField
                                                name="box_weight"
                                                type="number"
                                                placeholder="Weight"
                                                value={item?.box_weight}
                                                onChange={(e) => {
                                                  handleWeightchange(e, index)
                                                }}
                                                style={{
                                                  borderColor: item?.box_weight_error
                                                    ? "#f46a6a"
                                                    : "#ced4da",
                                                }}

                                                onWheel={(e) => e.target.blur()}


                                              />
                                              <div
                                                className="invalid-feedback"
                                                style={{
                                                  display: "block",
                                                  margin: "0px",
                                                  padding: "0px",
                                                }}
                                              >
                                                {item?.box_weight_error}
                                              </div>
                                            </TableCell>

                                            <TableCell >
                                              <AvField
                                                name={`l-${index}`}
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                placeholder=""
                                                value={BoxdetailsObject[index]?.l || ""}
                                                onChange={(e) => {
                                                  const newValue = e.target.value;
                                                  // Update the boxDetails state with the new value for 'l'
                                                  setBoxdetailsObject((prevBoxdetailsObject) => ({
                                                    ...prevBoxdetailsObject,
                                                    [index]: {
                                                      ...prevBoxdetailsObject[index],
                                                      l: newValue,
                                                    },
                                                  }));
                                                  handleDimensions(e, index);
                                                }} />
                                            </TableCell>
                                            <TableCell >
                                              <AvField
                                                name={`b-${index}`}
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                placeholder=""
                                                value={BoxdetailsObject[index]?.b || ""}
                                                onChange={(e) => {
                                                  const newValue = e.target.value;

                                                  // Update the boxDetails state with the new value for 'b'
                                                  setBoxdetailsObject((prevBoxdetailsObject) => ({
                                                    ...prevBoxdetailsObject,
                                                    [index]: {
                                                      ...prevBoxdetailsObject[index],
                                                      b: newValue,
                                                    },
                                                  }));

                                                  handleDimensions1(e, index); // You can call handleDimensions1 if needed
                                                }} />
                                            </TableCell>
                                            <TableCell >
                                              <AvField
                                                name={`h-${index}`}
                                                type="number"
                                                onWheel={(e) => e.target.blur()}
                                                placeholder=""
                                                value={BoxdetailsObject[index]?.h || ""}
                                                onChange={(e) => {
                                                  const newValue = e.target.value;

                                                  // Update the boxDetails state with the new value for 'b'
                                                  setBoxdetailsObject((prevBoxdetailsObject) => ({
                                                    ...prevBoxdetailsObject,
                                                    [index]: {
                                                      ...prevBoxdetailsObject[index],
                                                      h: newValue,
                                                    },
                                                  }));

                                                  handleDimensions2(e, index); // You can call handleDimensions1 if needed
                                                }}
                                              // validate={{ required: { value: true } }}
                                              // errorMessage="Enter Quantity"
                                              />
                                            </TableCell>


                                            <TableCell style={{ width: "200px" }}>
                                              <AvField
                                                name="packeditem"
                                                type="text"
                                                placeholder="Description"
                                                value={item?.packed_items}
                                                onChange={(e) => {
                                                  handlePackedItemchange(e, index)
                                                }}
                                              />
                                            </TableCell>


                                            {boxValue.length !== 1 && (
                                              <TableCell style={{ width: "50px" }}
                                              >
                                                <i
                                                  className="uil uil-times"
                                                  style={{
                                                    fontSize: "20px",
                                                    cursor: "pointer",
                                                    paddingLeft: "5px",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                  }}
                                                  onClick={() => handleRemove1(index)}
                                                ></i>
                                              </TableCell>
                                            )}
                                          </TableRow>

                                        )
                                      })}
                                  </TableBody>
                                </Table>

                                {/* <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                  <span style={{ display: boxValue.length == BoxNO ? "none" : "block" }} className="bg-dark badge bg-secondary font-size-12 addspan"
                                    onClick={() => addMoreRow()}
                                  ><i class="mdi mdi mdi-plus-thick"></i> New Box</span>

                                </div> */}


                              </Col>

                              <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>
                                <span style={{ display: boxValue.length == BoxNO ? "none" : "block" }} className="bg-dark badge bg-secondary font-size-12 addspan"
                                  onClick={() => addMoreRow()}
                                ><i class="mdi mdi mdi-plus-thick"></i> New Box</span>

                              </div>


                              <Col md="2" >
                                <div className="mb-3 alfarha">
                                  <div className="alfarha" style={{ fontWeight: "700" }}>Cargo Packed By : </div> &nbsp;

                                  <div className="checkbox-container">
                                    <input
                                      type="checkbox"
                                      name="cargo_packed_by"
                                      value={0}
                                      onChange={(e) => handleChange(e, 0)}
                                      checked={isChecked}
                                      style={{ marginLeft: "132px" }}
                                    />{" "}
                                    Customer
                                    {""}&nbsp;
                                    <input
                                      type="checkbox"
                                      name="cargo_packed_by"
                                      value={1}
                                      onChange={(e) => handleChange(e, 1)}
                                      checked={isChecked1}
                                    />{""}
                                    Company
                                  </div>
                                </div>

                              </Col>

                              <Row className="alfarha">
                                <Col md="3">
                                  <div className="mb-3">
                                    <div className="alfarha" style={{ fontWeight: "700" }}>Expected Delivery :</div>

                                    <div className="expedelivery-container">
                                      <AvField
                                        name="expected_delivery"
                                        value={BoxdetailsObject?.expected_delivery}
                                        onChange={handleDelivery}
                                        style={{
                                          marginLeft: "132px",
                                          backgroundColor: "#f9f6f3",
                                          width: "120px",
                                          height: "29px"
                                        }}
                                      />
                                    </div>
                                  </div>
                                </Col>

                              </Row>



                              {/* <Row>
                                <Col md="3">
                                  <div className="mb-3">
                                    <Label>Expected Delivery : </Label>

                                    {calculatedDate ? null : (
                                      <div className="expedelivery-container">
                                        <AvField
                                          name="expected_delivery"
                                          value={expectedDelivery}
                                          onChange={handleExpectedDeliveryChange}
                                          onBlur={handleExpectedDeliveryChange}
                                          style={{
                                            marginLeft: "134px",
                                          }}
                                        />
                                      </div>

                                    )}
                                    {calculatedDate && (
                                      <p>
                                        {calculatedDate}
                                      </p>
                                    )}


                                  </div>

                                </Col>

                              </Row> */}

                              {/* <Row className="alfarha">
                                {calculatedDate ? (
                                  <Col md="3">
                                    <div className="mb-3">
                                      <div className="alfarha" style={{ fontWeight: "700" }}>Expected Delivery : </div>

                                      <div className="expedelivery-container">
                                        <AvField
                                          name="expected_delivery"
                                          value={calculatedDate}
                                          onChange={handleExpectedDeliveryChange}
                                          style={{
                                            marginLeft: "132px",
                                            backgroundColor: "#f9f6f3",
                                            width: "120px",
                                            height: "29px"
                                          }}
                                        />
                                      </div>

                                    </div>
                                  </Col>
                                ) : (
                                  <Col md="3" >
                                    <div className="mb-3">
                                      <div className="alfarha" style={{ fontWeight: "700" }}>Expected Delivery : </div>

                                      <div className="expedelivery-container">
                                        <AvField
                                          name="expected_delivery"
                                          value={expectedDelivery}
                                          onChange={handleExpectedDeliveryChange}
                                          style={{
                                            marginLeft: "132px",
                                            backgroundColor: "#f9f6f3",
                                            width: "120px",
                                            height: "29px"
                                          }}
                                        />
                                      </div>

                                    </div>
                                  </Col>

                                )}
                              </Row> */}


                            </Row>
                            <Row>
                              <Col md="6" ></Col>
                              <Col md="6" >
                                <Row>

                                  <Col md="9" className="mt-3" style={{ textAlign: "right" }}><h6 className="alfarha mx-2 text-right">Total No.Of Boxes</h6></Col>
                                  <Col md="3" className="mt-3"><h6 className="alfarha mx-2 text-right">: {BoxNO}</h6></Col>

                                  <Col md="9" className="mt-3" style={{ textAlign: "right" }}><h6 className="alfarha mx-2 text-right">Total Box Weight</h6></Col>
                                  <Col md="3" className="mt-3"><h6 className="alfarha mx-2 text-right">: {totalENboxwieght}KG</h6></Col>

                                  <Col md="9" className="mt-3" style={{ textAlign: "right" }}><h6 className="alfarha mx-2 text-right">Total Weight</h6></Col>
                                  <Col md="3" className="mt-3"><h6 className="alfarha mx-2 text-right">: {TotalBoxWeight}KG</h6></Col>
                                </Row>

                              </Col>
                            </Row>

                          </Col>
                        </Row>
                      </Col>
                      <Row>
                        <Col className="btnlist py-3">
                          {/* <button
                      type="reset"
                      onClick={() => { setActiveStep(3) }}
                      className="refresh mx-2">Next test</button> */}
                          <button
                            className="back1"
                            type="reset"
                            onClick={() => {
                              //PreupdateItemDetails()
                              setActiveStep(1);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <i class="fa fa-long-arrow-alt-left"></i>
                          </button>
                          <button
                            type="reset"
                            onClick={() => { resetTab3() }}
                            className="refresh mx-2"><RefreshIcon /></button>
                          <button
                            className="submit mx-2"

                            type="submit"
                          >
                            Next
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  ) : null
                }
                {
                  activeStep == 3 ? (
                    <>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="ordersummary " style={{ backgroundColor: "#fff", width: "75%", padding: "32px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                          <Box className="summary text-center">

                            <Row>
                              <Col className="content md-5" md="6">
                                <h6 className="alfarha toCapitalize">{SingleOrderList?.branch_id?.branch_name?.toLowerCase()}, {SingleOrderList?.branch_id?.address?.toLowerCase()}, {SingleOrderList?.branch_id?.state_id?.name?.toLowerCase()}, {SingleOrderList?.branch_id?.country_id?.name?.toLowerCase()}</h6>
                                <Row>
                                  <Col md="12">
                                    <i class="fa fa-envelope icons"></i> {SingleOrderList?.branch_id?.email_id ?? ''}
                                  </Col>
                                  <Col md="12">
                                    <i class="fa fa-phone-alt icons"></i> {SingleOrderList?.branch_id?.mobile_no ?? ''}
                                  </Col>
                                  <Col md="12">
                                    <i class="fa fa-globe icons" aria-hidden="true"></i> {SingleOrderList?.branch_id?.website ?? ''}
                                  </Col>

                                </Row>
                              </Col>
                              <Col md="1"></Col>

                              <Col md="5">
                                <img
                                  src={LogoDark}
                                  style={{ width: "86%", marginTop: "-21px" }}
                                  alt=""
                                />

                              </Col>
                            </Row>
                            <hr style={{ marginTop: "9px", marginBottom: "1px", height: "1.3px", color: "#0264bc" }} />
                          </Box>
                          <Row>
                            <Col md="12">
                              <table style={{ width: "100%" }}>
                                <tr>
                                  <td style={{ padding: "0px" }}><h4 className="alfarha font-size-16">BOOKING NOTE</h4></td>
                                  <td style={{ padding: "0px", textAlign: "right" }}><Barcode value={SingleOrderList?.order_number} format="CODE128"
                                    displayValue={false}
                                    fontSize={12}
                                    width={1.5}
                                    height={30}
                                    ref={barcodeRef}
                                  /></td>
                                </tr>
                              </table>

                            </Col>

                          </Row>
                          <hr className='hr-dashed' style={{ marginTop: "1px" }} />
                          <Row>
                            <Col md="12" className="text-center">
                              <h4 className="alfarha font-size-16">{(SingleOrderList?.booking_type == 0) ? SingleOrderList?.country_to?.name?.toUpperCase() : SingleOrderList?.city_to?.name?.toUpperCase()}</h4>
                            </Col>
                          </Row>
                          <hr className='hr-dashed' style={{ marginTop: "1px" }} />
                          <Row>
                            <Col md="6" className="alfarha">Reference No : <b>{SingleOrderList?.cargo_type?.cargo_Type} {SingleOrderList?.order_number}</b></Col>
                            <Col md="6" className="content1 alfarha">Date : <b>{moment(SingleOrderList?.date).format("DD-MM-YYYY")}</b></Col>
                          </Row>
                          <hr className='hr-dashed' style={{ marginTop: "9px" }} />
                          <Row>
                            <Col>

                              <h6 className="alfarha">CONSIGNOR / SENDER ADDRESS</h6>
                              <table id="senderAddTable">
                                <tr>
                                  <th>Name</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.sender?.name?.toLowerCase()}</th>
                                </tr>

                                {SingleOrderList?.sender?.id_number && (
                                  <tr>
                                    <th>ID No</th><th> : </th><th className="toCapitalize">{SingleOrderList?.sender?.id_number}</th>
                                  </tr>
                                )}
                                <tr>
                                  <th>Mobile</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.sender?.mobile_number}
                                    {
                                      SingleOrderList?.sender?.whattsapp_number ? (
                                        <> / {SingleOrderList?.sender?.whattsapp_number}</>
                                      ) : null
                                    }
                                  </th>
                                </tr>

                              </table>

                            </Col>
                            <Col className="mx-2" md-3>
                              <h6 className="alfarha">CONSIGNEE / RECEIVER ADDRESS</h6>
                              <table id="receiverAddTable">
                                <tr>
                                  <th>Name</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList?.receiver?.name?.toLowerCase()}</b></th>
                                </tr>

                                {SingleOrderList?.receiver?.house_name && (
                                  <tr>
                                    <th>House Name</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.house_name?.toLowerCase()}</th>
                                  </tr>
                                )}

                                {SingleOrderList?.receiver?.street && (
                                  <tr>
                                    <th>Street</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toLowerCase() : "")}</th>
                                  </tr>
                                )}

                                <tr>
                                  <th>Post Office</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toLowerCase() : "")}</th>
                                </tr>

                                {SingleOrderList?.receiver?.pin_code && (
                                  <tr>
                                    <th>Pincode</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.pin_code}</th>
                                  </tr>
                                )}

                                <tr>
                                  <th>District</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.district}</th>
                                </tr>
                                <tr>
                                  <th>State</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.rece_state?.name}</th>
                                </tr>
                                <tr>
                                  <th>Mobile</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList?.receiver?.mobile_number} {SingleOrderList?.receiver?.telephone_number ? (
                                    <> / {SingleOrderList?.receiver?.telephone_number}</>
                                  ) : null}</b></th>
                                </tr>
                                {/* <tr>
                        <th>Address</th><th> : </th><th className="toCapitalize" style={{ textAlign: "justify" }}>{SingleOrderList?.receiver?.house_name?.toLowerCase() + " " + (SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toLowerCase() : "")}{" "}{(SingleOrderList?.receiver?.landmark ? SingleOrderList.receiver.landmark.toLowerCase() : "") + " " + (SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toLowerCase() : "") + " " + SingleOrderList?.receiver?.pin_code}
                          <br />{SingleOrderList?.receiver?.district + ", " + SingleOrderList?.receiver?.rece_state?.name}
                        </th>
                      </tr> */}

                              </table>



                            </Col>

                          </Row>
                          <Row >
                            <Col className="mt-2">
                              {/* <h6 className="alfarha">ITEM DETAILS</h6> */}
                              <table id="summeryItemsTable">
                                <tbody>
                                  <tr>
                                    <th>#</th>
                                    <th>Item</th>
                                    <th>Unit</th>
                                    <th>Weight</th>
                                    <th>Rate</th>
                                    <th>Amount</th>
                                  </tr>
                                </tbody>
                                <tbody>
                                  {
                                    SingleOrderList &&
                                    SingleOrderList?.item_details?.map((el, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td className="toCapitalize">{el?.item_category?.name?.toLowerCase()}</td>
                                          <td>{el?.unit}</td>
                                          <td>{el?.quantity.toFixed(3)}</td>
                                          <td>{el?.rate.toFixed(3)}</td>
                                          <td>{el?.amount.toFixed(3)}</td>
                                        </tr>
                                      )
                                    })
                                  }
                                </tbody>
                                <tbody >
                                  <tr style={{ height: "100px" }}>
                                    <td colspan="6"></td>
                                  </tr>

                                  <tr>
                                    <th >Total</th>
                                    <th> :</th>
                                    <th></th>
                                    <th>{SingleOrderList?.total_weight?.toFixed(3)}</th>
                                    <th></th>
                                    <th style={{ textAlign: "right" }}>{SingleOrderList?.amount?.toFixed(3)}</th>
                                  </tr>

                                  <tr>
                                    <th>No.of Boxes</th><th>: {SingleOrderList?.no_of_boxes}</th>
                                    <th>Handling Rate</th><th>: {SingleOrderList?.handling_rate?.toFixed(3)}</th>
                                    <th style={{ textAlign: "right", width: "156px" }}>Amount &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</th>
                                    <th>{(SingleOrderList?.handling_rate_total) ? SingleOrderList.handling_rate_total?.toFixed(3) : "0.000"}</th>
                                  </tr>

                                  <tr>
                                    <th>vat %</th><th>: {SingleOrderList?.vat_percentage}</th>
                                    <th>Vat Amount</th><th>: {SingleOrderList?.vat_amount?.toFixed(3)}</th>
                                    <th>Total Value(OMR) :</th><th>{SingleOrderList?.total_value?.toFixed(3)}</th>
                                  </tr>

                                  <tr>
                                    <th><b>Grand Total</b></th><th> :</th>
                                    <th colSpan={4} style={{ textAlign: "right" }}><b>{SingleOrderList?.grand_total?.toFixed(3)}</b></th>
                                  </tr>

                                </tbody>
                              </table>

                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" className="text-center mt-2"><h6 className="alfarha">OFFICE USE</h6></Col>

                            <Col md="6">
                              Signature of Shipper
                            </Col>
                            <Col md="6" className="content1">
                              Signature
                            </Col>
                          </Row>

                          <Row>
                            <Col md="12" className="mt-5" style={{ marginLeft: "10px", width: "100%", borderTop: "1.5px dashed #6d6b6b" }}>

                              <h6 className="alfarha" style={{
                                width: "51px",
                                marginTop: "-10px",
                                marginLeft: "19px",
                                background: "white",
                                paddingLeft: "4px"
                              }}>Boxes :</h6>
                            </Col>


                            {
                              SingleOrderList &&
                              SingleOrderList?.box_details?.map((el, index) => {

                                return (
                                  <Col md="1" style={{ fontSize: "11px" }}>{el?.box_weight} KG</Col>
                                )
                              })
                            }


                          </Row>

                        </div>

                      </div>
                      <Row>
                        <Col className="btnlist py-3">
                          <button
                            className="back1"
                            type="reset"
                            onClick={() => {
                              {
                                setActiveStep(2);
                                window.scrollTo(0, 0);
                              }
                            }}
                            style={{ backgroundColor: "black" }}

                          >
                            <i class="fa fa-long-arrow-alt-left"></i>
                          </button>


                          <button
                            type="submit"
                            className="submit mx-2"
                            onClick={finalSubmitOrder}
                          >Submit</button>
                        </Col>
                      </Row>
                    </>

                  ) : null
                }
              </div>
            </div >
          </>
        )
      }

    </React.Fragment >
  );
}


export default Addbooking;