import React, { useRef } from 'react';
import {
    Row,
    Col,
} from "reactstrap";
import { Box } from "@mui/system";
import Barcode from 'react-barcode';
import QRCode from 'react-qr-code';
import moment from "moment";
import img from "../../assets/images/img.png";
import img2 from "../../assets/images/img2.png";
import LogoDark from "../../assets/images/logo-large.png"
export const PrintableComponent = React.forwardRef((props, ref) => {
    const SingleOrderList = props.data;
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const barcodeRef = useRef(null);
    const qrcodeRef = useRef(null);
    const qrcodeValue = "https://www.com";

    const companyCheckbox = document.getElementById('companyCheckbox');
    const customerCheckbox = document.getElementById('customerCheckbox');

    if (SingleOrderList.cargo_packed_by === 0) {
        customerCheckbox.checked = true;
        companyCheckbox.checked = false;
    }
    if (SingleOrderList.cargo_packed_by === 1) {
        companyCheckbox.checked = true;
        customerCheckbox.checked = false;
    }


    return (
        <div ref={ref}>
            <div className="modal-content" style={{ display: "flex", justifyContent: "center" }}>
                <div className="ordersummary " style={{ width: "100%", padding: "15px" }}>
                    <Box className="summary text-center">
                        {/* <Row>
                    <h4 className="alfarha font-size-16">BOOKING NOTE # {SingleOrderList?.order_number}</h4>
                  </Row> */}
                        <Row>
                            <Col className="content md-8" md="6" >
                                <h6 className="alfarha toCapitalize" style={{ fontFamily: 'Almarai' }}>{SingleOrderList?.branch_id?.branch_name?.toUpperCase()} <br /> {SingleOrderList?.branch_id?.address?.toUpperCase()} <br /> {SingleOrderList?.branch_id?.state_id?.name?.toUpperCase()}, {SingleOrderList?.branch_id?.country_id?.name?.toUpperCase()}</h6>
                                <Row >
                                    <Col md="12" style={{ fontFamily: 'Almarai' }} className='toCapitalize'>
                                        &nbsp;<i class="fa fa-phone-alt icons"></i>
                                        &nbsp; {SingleOrderList?.branch_id?.mobile_no}
                                    </Col>
                                    <Col md="12" style={{ fontFamily: 'Almarai' }} className='toCapitalize'>
                                        &nbsp;<i class="fa fa-globe icons" aria-hidden="true"></i>
                                        &nbsp;{SingleOrderList?.branch_id?.website}
                                    </Col>
                                    <Col md="12" style={{ fontFamily: 'Almarai' }} className='toCapitalize'>
                                        &nbsp;<i class="fa fa-envelope icons"></i>
                                        &nbsp;{SingleOrderList?.branch_id?.email_id}
                                    </Col>

                                </Row>
                            </Col>
                            <Col md="1"></Col>
                            <Col md="5">
                                <img
                                    src={LogoDark}
                                    style={{ width: "86%", marginTop: "-21px", fontFamily: "Almarai" }}
                                    alt=""
                                />

                            </Col>
                        </Row>
                        <hr style={{ marginTop: "0px", marginBottom: "0px", height: "1.3px", color: "#000" }} />
                    </Box>
                    <Row style={{ margin: "0px" }}>
                        <Col md="4" className="alfarha" style={{ fontFamily: "Almarai", fontSize: "16px", margin: "0", textAlign: "center", marginTop: "3px" }} >INVOICE CUM BOOKING NOTE</Col>
                        <Col md="5"></Col>
                        <Col md="3" style={{ margin: "1" }}>
                            <Barcode value={SingleOrderList?.order_number} format='CODE128'
                                displayValue={false}
                                // fontSize={16}
                                width={1.5}
                                height={30}
                                margin={1}
                                ref={barcodeRef}
                            />
                        </Col>

                    </Row>
                    <hr style={{ margin: "0px", height: "1.3px", color: "#000" }} />

                    <Row>
                        <Col md="12" className="text-center">
                            <h4 className="alfarha " style={{ fontSize: "2rem", fontFamily: 'Almarai', marginBottom: "0px" }}>{(SingleOrderList?.booking_type == 0) ? SingleOrderList?.country_to?.name?.toUpperCase() : SingleOrderList?.city_to?.name?.toUpperCase()}</h4>
                        </Col>
                    </Row>
                    <hr style={{ marginTop: "0px", marginBottom: "0px", height: "1.3px", color: "#000" }} />
                    <Row>
                        <Col md="4" className='alfarha' style={{ fontFamily: 'Almarai' }}>Booking ID: <span className='alfarha2' style={{ fontSize: "17px" }}><b>{SingleOrderList?.cargo_type?.cargo_Type} {SingleOrderList?.order_number}</b></span></Col>
                        <Col md="4"></Col>
                        <Col md="4" className="content1 alfarha" style={{ fontFamily: 'Almarai' }}>Date : <b>{moment(SingleOrderList?.date).format("DD-MM-YYYY")}</b></Col>
                    </Row>
                    <hr className='hr-dashed' style={{ marginTop: "0px", marginBottom: "0px" }} />
                    <Row>
                        <Col>

                            <h6 className="alfarha" style={{ fontFamily: 'Almarai', margin: "0", padding: "0" }}>Consignor / Shipper Details :</h6>
                            <table id="senderAddTable" style={{ margin: "0", padding: "0" }}>
                                <tr style={{ margin: "0", padding: "0" }}>
                                    <th style={{ fontFamily: 'Almarai', margin: "0", padding: "0" }}>Name</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}><b>{SingleOrderList?.sender?.name?.toUpperCase()}</b></th>
                                </tr>

                                {SingleOrderList?.sender?.id_number && (
                                    <tr style={{ margin: "0", padding: "0" }}>
                                        <th style={{ fontFamily: 'Almarai', margin: "0", padding: "0" }}>ID No</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}><b>{SingleOrderList?.sender?.id_number?.toUpperCase()}</b></th>
                                    </tr>
                                )}

                                <tr style={{ margin: "0", padding: "0" }}>
                                    <th style={{ fontFamily: 'Almarai', margin: "0", padding: "0" }}>Mobile No</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}><b>{SingleOrderList?.sender?.mobile_number}
                                        {
                                            SingleOrderList?.sender?.whattsapp_number ? (
                                                <> / {SingleOrderList?.sender?.whattsapp_number}</>
                                            ) : null
                                        }</b>
                                    </th>
                                </tr>

                            </table>

                        </Col>
                        <Col className="mx-2" md-3>
                            <h6 className="alfarha" style={{ fontFamily: 'Almarai', margin: "0", padding: "0" }}>Consignee / Receiver Details:</h6>
                            <table id="receiverAddTable" style={{ margin: "0", padding: "0" }}>
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Name</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}><b>{SingleOrderList?.receiver?.name?.toUpperCase()}</b></th>
                                </tr>
                                {/* <tr>
                                    <th>House Name</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.house_name?.toLowerCase()}</th>
                                </tr>
                                <tr>
                                    <th>Street</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toLowerCase() : "")}</th>
                                </tr>
                                <tr>
                                    <th>Post Office</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toLowerCase() : "")}</th>
                                </tr> */}
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Address</th><th> : </th><th style={{ fontSize: "9px" }}><b>{SingleOrderList?.receiver?.house_name?.toUpperCase()},{(SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toUpperCase() : "")},
                                        {(SingleOrderList?.receiver?.landmark ? SingleOrderList?.receiver?.landmark?.toUpperCase() : "")},{(SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toUpperCase() : "")}</b></th>
                                </tr>
                                {SingleOrderList?.receiver?.pin_code && (
                                    <tr>
                                        <th style={{ fontFamily: 'Almarai' }}>Pincode</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}>{SingleOrderList?.receiver?.pin_code?.toUpperCase()}</th>
                                    </tr>
                                )}
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>District</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}>{SingleOrderList?.receiver?.district?.toUpperCase()}</th>
                                </tr>
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>State</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}>{SingleOrderList?.receiver?.rece_state?.name?.toUpperCase()}</th>
                                </tr>
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Mobile</th><th> : </th><th style={{ fontSize: "10px", fontWeight: "600", color: "#282828" }}><b>{SingleOrderList?.receiver?.mobile_number} {SingleOrderList?.receiver?.telephone_number ? (
                                        <> / {SingleOrderList?.receiver?.telephone_number}</>
                                    ) : null}</b></th>
                                </tr>
                                {/* <tr>
                        <th>Address</th><th> : </th><th className="toCapitalize" style={{ textAlign: "justify" }}>{SingleOrderList?.receiver?.house_name?.toLowerCase() + " " + (SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toLowerCase() : "")}{" "}{(SingleOrderList?.receiver?.landmark ? SingleOrderList.receiver.landmark.toLowerCase() : "") + " " + (SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toLowerCase() : "") + " " + SingleOrderList?.receiver?.pin_code}
                          <br />{SingleOrderList?.receiver?.district + ", " + SingleOrderList?.receiver?.rece_state?.name}
                        </th>
                      </tr> */}

                            </table>



                        </Col>

                    </Row>
                    <hr className='hr-dashed' style={{ marginTop: "0px", marginBottom: "0px" }} />

                    <table id="invoicetableid" style={{ margin: "0", padding: "0" }}>
                        <tbody>
                            <tr>
                                <th className='SlNoColumn' style={{ fontFamily: 'Almarai' }}>Sl No</th>
                                <th style={{ fontFamily: 'Almarai' }}>Item Category</th>
                                <th style={{ fontFamily: 'Almarai' }}>Unit</th>
                                <th style={{ fontFamily: 'Almarai' }}>Qnty</th>
                                <th style={{ fontFamily: 'Almarai' }}>Rate</th>
                                <th style={{ fontFamily: 'Almarai' }}>Amount</th>
                            </tr>
                        </tbody>
                        <tbody>
                            {
                                SingleOrderList &&
                                SingleOrderList?.item_details?.map((el, index) => {
                                    return (
                                        <tr>
                                            <td className="toCapitalize">{index + 1}</td>
                                            <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>{el?.item_category?.name?.toUpperCase()}</td>
                                            <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>{el?.unit?.toUpperCase()}</td>
                                            <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>{el?.quantity.toFixed(3)}</td>
                                            <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>{el?.rate.toFixed(3)}</td>
                                            <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>{el?.amount.toFixed(3)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    {/* <hr className='hr-dashed' style={{ marginTop: "0px", margin: "0" }} /> */}

                    <table style={{ width: "100%", marginTop: "0px", margin: "0" }}>
                        <tbody>
                            <tr>
                                <td colspan="6"></td>
                            </tr>
                            <tr><td colspan="6"></td></tr>
                            <tr><td colspan="6"></td></tr>
                            <tr><td colspan="6"></td></tr>
                            <tr><td colspan="6"></td></tr>
                            <tr><td colspan="6"></td></tr>
                            <tr><td colspan="6"></td></tr>
                            <tr><td colspan="6"></td></tr>
                            <tr><td colspan="6"></td></tr>
                            <tr><td colspan="6"></td></tr>
                            <tr className='dashed-border'>
                                <th style={{ fontFamily: 'Almarai' }}>Total Cargo Value(OMR): &nbsp;{SingleOrderList?.total_value?.toFixed(3)}</th>
                                <th> </th>
                                <th></th>
                                <th></th>
                                <th></th>
                                <th style={{ textAlign: "right", fontFamily: "Almarai" }}>{SingleOrderList?.amount?.toFixed(3)}</th>
                            </tr>
                        </tbody>
                    </table>
                    <hr className='hr-dashed' style={{ marginTop: "0px", margin: "0" }} />

                    <Row>
                        <Col md="2" style={{ borderRight: "1px dashed #000" }}><b>No of Boxes : </b><br />
                            <span className="second-line-value" ><b>{SingleOrderList?.no_of_boxes}</b></span>
                        </Col>

                        <Col md="2" ><b>Total Weight :</b><br />
                            <span className='second-line-value'><b>{SingleOrderList?.total_weight?.toFixed(2)}</b></span>
                        </Col>

                        <Col md="8">
                            <table style={{ width: "100%" }}>
                                <tbody>
                                    <tr >
                                        <th style={{ fontFamily: 'Almarai' }}>Handling Rate</th><th>: {SingleOrderList?.handling_rate ? SingleOrderList?.handling_rate?.toFixed(3) : "0.000"}</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Handling Charge </th><th style={{ textAlign: "right" }}>: {SingleOrderList?.handling_rate_total ? SingleOrderList?.handling_rate_total?.toFixed(3) : "0.000"}</th>
                                    </tr>
                                    <tr style={{ borderTop: "1px dashed #000" }}>
                                        <th style={{ fontFamily: 'Almarai' }}>vat %</th><th>: {SingleOrderList?.vat_percentage ? SingleOrderList?.vat_percentage?.toFixed(3) : "0.000"}</th>
                                        <th style={{ fontFamily: 'Almarai' }}>VAT </th><th style={{ textAlign: "right" }}>: {SingleOrderList?.vat_amount ? SingleOrderList?.vat_amount?.toFixed(3) : "0.000"}</th>
                                    </tr>
                                    <tr style={{ borderTop: "1px dashed #000" }}>
                                        <th style={{ fontFamily: 'Almarai' }}><b>Grand Total</b></th>
                                        <th colSpan={4} style={{ textAlign: "right", fontFamily: "Almarai" }}><b>{SingleOrderList?.grand_total?.toFixed(3)}</b></th>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <hr className='hr-dashed' style={{ marginTop: "0px", margin: "0" }} />


                    <Row style={{ margin: "0", padding: "0" }} >
                        <Col md="1">
                            {SingleOrderList?.box_details && SingleOrderList.box_details.length > 0 && (
                                <div style={{ height: "auto", margin: "0 auto", marginTop: "0px", marginBottom: "0px" }}>
                                    <QRCode
                                        value={SingleOrderList.box_details[0]?.box_number}
                                        format="CODE128"
                                        displayValue={false}
                                        size={256}
                                        style={{ height: "200%", maxWidth: "300%", width: "200%" }}
                                        viewBox={`0 0 256 256`}
                                    />
                                </div>
                            )}
                        </Col>

                        <Col md="11" >
                            {SingleOrderList?.item_details &&
                                <div style={{ fontFamily: 'Almarai', marginLeft: "30px" }}><b>
                                    Item Description: </b>
                                    <ul style={{ listStyle: "dot", fontFamily: "Almarai",marginLeft: "30px" }}>
                                        {SingleOrderList.item_details.map((el, indx) => (
                                            <li key={indx} style={{ display: "inline", fontFamily: 'Almarai', fontSize: "10px" }}>
                                                <b>
                                                    {el.item_type === 1 || el.item_type === 3 || el.item_type === 4 ? el.description : null},
                                                </b>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            }

                            <Row>
                                <Col md="6" >
                                    <div style={{ fontFamily: 'Almarai',marginLeft: "30px" }}><b>Expected Delivery: {SingleOrderList?.expected_delivery}</b></div>
                                    <div style={{ fontFamily: 'Almarai',marginLeft: "30px" }}><b>Cargo Packed By: </b>&nbsp;
                                        <input type='checkbox' id="companyCheckbox" style={{ fontFamily: 'Almarai' }} />&nbsp; <b>Company</b> &nbsp;
                                        <input type='checkbox' id="customerCheckbox" style={{ fontFamily: 'Almarai' }} />&nbsp; <b>Customer</b>
                                    </div>
                                </Col>

                                <Col md="5">
                                    <div>
                                        <img src={img2} alt="" style={{ width: "120%" }} />
                                    </div>
                                </Col>

                            </Row>

                        </Col>

                    </Row>

                    <Row style={{ padding: "0", margin: "0" }}>
                        <Col md="12" style={{ paddingTop: "0", paddingBottom: "0", margin: "0" }}>
                            <div>
                                <img src={img} alt="" style={{ width: "100%" }} />
                            </div>
                        </Col>
                    </Row>

                    <Row >
                        <Col md="6" className='mt-5' style={{ fontWeight: "700", fontFamily: 'Almarai' }}>
                            Signature of Shipper
                        </Col>
                        <Col md="6" className="content1 mt-5" style={{ fontWeight: "700", fontFamily: 'Almarai' }}>
                            Payment Received By
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12" style={{ marginLeft: "10px", width: "100%" }}>
                            <div style={{ fontFamily: 'Almarai', margin: "0",padding: "0" }}><b>Box Details :</b></div>

                            {
                                SingleOrderList &&
                                SingleOrderList?.box_details?.map((el, index) => {
                                    return (
                                        <span md="1" key={index} style={{ fontSize: "11px", fontFamily: 'Almarai', margin: "0", padding: "0" }}>
                                            <b>({index + 1}) {el?.box_weight?.toFixed(3)} </b></span>
                                    )
                                })
                            }
                        </Col>
                    </Row>


                </div>

            </div>
        </div>
    );
});