import {
    GET_SLIDER,
    GET_SLIDER_SUCCESS,
    GET_SLIDER_FAIL,
    ADD_SLIDER,
    ADD_SLIDER_SUCCESS,
    ADD_SLIDER_FAIL,
    UPDATE_SLIDER,
    UPDATE_SLIDER_SUCCESS,
    UPDATE_SLIDER_FAIL,
    DELETE_SLIDER,
    DELETE_SLIDER_SUCCESS,
    DELETE_SLIDER_FAIL,
  } from "./actionTypes";
  
  const INIT_STATE = {
    sliders: [],
    addingSlider: false,
    deletingSlider: false,
    addSliderResponse: {},
    updateSliderResponse: {},
    deleteSliderResponse: {},
    sliderIdToBeDeleted: "",
    slider: {},
    error: {},
  };
  
  const sliders = (state = INIT_STATE, action) => {
    switch (action.type) {
      case GET_SLIDER:
        return {
          ...state,
          params: action.payload,
        };
  
      case GET_SLIDER_SUCCESS:
        return {
          ...state,
          sliders: action.payload.data,
        };
  
      case GET_SLIDER_FAIL:
        return {
          ...state,
          error: action.payload,
        };
      case ADD_SLIDER:
        return {
          ...state,
          addingSlider: true,
          slider: action.payload,
        };
  
      case ADD_SLIDER_SUCCESS:
        let newSliders = [JSON.parse(JSON.stringify(action.payload.data[0]))];
        newSliders.push(...state.sliders);
        return {
          ...state,
          addingSlider: false,
          addSliderResponse: {
            type: "success",
            message: "Slider created successfully",
          },
          sliders: newSliders,
          error: {},
        };
  
      case ADD_SLIDER_FAIL:
        return {
          ...state,
          addingSlider: false,
          addSliderResponse: { type: "failure", message: "Creating slider failed" },
          error: action.payload,
        };
      case DELETE_SLIDER:
        return {
          ...state,
          deletingSlider: true,
          sliderIdToBeDeleted: action.payload,
        };
  
      case DELETE_SLIDER_SUCCESS:
        let newData = state.sliders.filter((e) => {
          return e.slider_id != state.sliderIdToBeDeleted;
        });
        return {
          ...state,
          deletingSlider: false,
          sliderIdToBeDeleted: "",
          deleteSliderResponse: {
            type: "success",
            message: "Slider deleted successfully",
          },
          sliders: newData,
        };
  
      case DELETE_SLIDER_FAIL:
        return {
          ...state,
          deletingSlider: false,
          deleteSliderResponse: {
            type: "failure",
            message: "Deleting slider failed",
          },
          error: action.payload,
        };
  
      case UPDATE_SLIDER:
        return {
          ...state,
          updatingSlider: true,
          slider: action.payload,
        };
  
      case UPDATE_SLIDER_SUCCESS:
        let newSliderarr = [];
        let newSlider = state.sliders.filter((slider) => {
          if (slider.slider_id == state.slider.slider_id) {
            console.log(JSON.parse(JSON.stringify(action.payload.data[0])));
            newSliderarr.push(JSON.parse(JSON.stringify(action.payload.data[0])));
          } else {
            newSliderarr.push(slider);
          }
        });

      
  
        return {
          ...state,
          updatingSlider: false,
          slider: "",
          updatingSlider: false,
          updateSliderResponse: {
            type: "success",
            message: "Slider updated successfully",
          },
          slders: newSliderarr,
        };
  
      case UPDATE_SLIDER_FAIL:
        return {
          ...state,
          updatingSlider: false,
          updateSliderResponse: {
            type: "failure",
            message: "Updating slider failed",
          },
          error: action.payload,
        };
  
      default:
        return state;
    }
  };
  
  export default sliders;
  