import React, { useState, useEffect } from "react";
import { MDBDataTable } from "mdbreact";
// import DeleteIcon from "@mui/icons-material/Delete";
// import EditIcon from "@mui/icons-material/Edit";
// import Settings from "@mui/icons-material/Settings";

// import MenuItem from "@mui/material/MenuItem";

import Select from "react-select";
import toastr from "toastr";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import {
  Row,
  Col,
  Card,
  CardBody,
  Label
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./rulesApp.scss";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const DatatableTables = () => {
  const [master, setMaster] = useState({});
  const [details, setDetails] = useState([]);
  const [selectedPrivilege, setSelectedPrivilege] = useState(null);
  const [privilegeOptions, setPrivilegeOptions] = useState([]);
  const [options, setOptions] = useState([]);
  const [dataOptions, setDataOptions] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  useEffect(() => {
    fetch_option_list();
  }, []);
  const fetch_option_list = () => {
    axios
      .get(`${API_URL}rules/option_list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        setOptions(result);
      });
  };
  const handleSelectChange = (selected) => {
    setSelectedPrivilege(selected);

    let userid = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      userid = user_obj._id;
    }
    data = {
      //user_id: userid,
      privilege: selected.value,
    };
    axios
      .post(`${API_URL}app_rules/list`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        // let data = result[0].main_menu;

        result &&
          result.map((item, index) => {
            // let submenus = item.sub_menu;
            item.sub_menu &&
            item.sub_menu.map((opt) => {
              opt.option = options;
              opt.mainmenu_id = item._id;
            });
            // item.mmenu = (
            //   <div>
            //     <ul style={{ listStyleType: "none" }}>
            //       <li className="mb-2">
            //         <input type="checkbox" className="mb-2"
            //         checked={
            //           item.allocation_status === 1 ? true : false
            //         }
            //          onChange={(e) =>
            //           handleMainmenuCheckboxChange(
            //             e,
            //             item,
            //             selected
            //           )
            //         }
            //          />
            //         <span
            //           style={{
            //             marginLeft: "5px",
            //           }}
            //         >
            //           {item.menu_english_name}
            //         </span>
            //       </li>
            //     </ul>
            //   </div>
            // );
            // item.smenu = (
            //   <div>
            //     <ul style={{ listStyleType: "none" }}>
            //       {submenus.map((element, index) => {
            //         return (
            //           <li className="mb-2">
            //             <input
            //               type="checkbox"
            //               checked={
            //                 element.allocation_status === 1 ? true : false
            //               }
            //               onChange={(e) =>
            //                 handleCheckboxChange(
            //                   e,
            //                   element,
            //                   item,
            //                   selected
            //                 )
            //               }
            //               className="mb-2"
            //             />
            //             <span
            //               style={{
            //                 marginLeft: "5px",
            //               }}
            //             >
            //               {element.sub_english_name}
            //             </span>
            //           </li>
            //         );
            //       })}
            //     </ul>
            //   </div>
            // );
            // item.view = (
            //   <div>
            //     <ul style={{ listStyleType: "none" }}>
            //       {submenus.map((element, index) => {
            //         return (
            //           <li className="mb-2">
            //             <input type="checkbox" className="mb-2" />
            //             <span
            //               style={{
            //                 marginLeft: "5px",
            //               }}
            //             >
            //               {""}
            //             </span>
            //           </li>
            //         );
            //       })}
            //     </ul>
            //   </div>
            // );
            // item.edit = (
            //   <div>
            //     <ul style={{ listStyleType: "none" }}>
            //       {submenus.map((element, index) => {
            //         return (
            //           <li className="mb-2">
            //             <input type="checkbox" className="mb-2" />
            //             <span
            //               style={{
            //                 marginLeft: "5px",
            //               }}
            //             >
            //               {""}
            //             </span>
            //           </li>
            //         );
            //       })}
            //     </ul>
            //   </div>
            // );
            // item.delete = (
            //   <div>
            //     <ul style={{ listStyleType: "none" }}>
            //       {submenus.map((element, index) => {
            //         return (
            //           <li className="mb-2">
            //             <input type="checkbox" className="mb-2" />
            //             <span
            //               style={{
            //                 marginLeft: "5px",
            //               }}
            //             >
            //               {""}
            //             </span>
            //           </li>
            //         );
            //       })}
            //     </ul>
            //   </div>
            // );
            // item.export = (
            //   <div>
            //     <ul style={{ listStyleType: "none" }}>
            //       {submenus.map((element, index) => {
            //         return (
            //           <li style={{ textAlign: "left" }} className="mb-2">
            //             <input type="checkbox" className="mb-2" />
            //             <span
            //               style={{
            //                 marginLeft: "5px",
            //               }}
            //             >
            //               {""}
            //             </span>
            //           </li>
            //         );
            //       })}
            //     </ul>
            //   </div>
            // );
          });
        // setDetails(result);
        setDataOptions(result);
      });
  };
  const handleMainmenuCheckboxChange = (e,item) => {
    let data = {
      mainmenu_id: item._id,
      privilege: selectedPrivilege.value,
      status: e.target.checked === true ? 1 : 0,
    };
    console.log("data: ", data);
    axios
      .post(`${API_URL}app_rules/menu`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleSelectChange(selectedPrivilege);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };

  const handleCheckboxChange = (e, element, item) => {
    let data = {
      mainmenu_id: item._id,
      privilege: selectedPrivilege.value,
      submenu_id: element._id,
      status: e.target.checked === true ? 1 : 0,
    };
    console.log("data: ", data);
    axios
      .post(`${API_URL}app_rules/add`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleSelectChange(selectedPrivilege);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  useEffect(() => {
    fetch_all_privilege();
  }, []);
  function fetch_all_privilege() {
    axios
      .get(`${API_URL}privilage/items`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var privilege_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.privilege_name,
              value: el._id,
            };
          });

        setPrivilegeOptions([
          {
            options: privilege_data,
          },
        ]);
      });
  }

  const handleAllmenuCheckboxChange = (e, item) => {
    let data = {
      status: e.target.checked === true ? 1 : 0,
      option_name: item._id,
      privilege: selectedPrivilege?.value,
    };
    axios
      .post(`${API_URL}app_rules/options`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleSelectChange(selectedPrivilege);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  const handleOptionCheckboxChange = (e, el, element) => {
    let data = {
      status: e.target.checked === true ? 1 : 0,
      option_name: element._id,
      privilege: selectedPrivilege?.value,
      sub_menu: el?._id,
      main_menu: el?.mainmenu_id,
    };
    axios
      .post(`${API_URL}app_rules/menu_option`, data, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          handleSelectChange(selectedPrivilege);
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };
  const data = {
    columns: [
      {
        label: "Menu",
        field: "mmenu",
        sort: "asc",
        width: 150,
        class: "menu",
      },
      {
        label: "Sub Menu",
        field: "smenu",
        sort: "asc",
        width: 150,
      },
      {
        label: (
          <>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="start"
                  control={<Checkbox size="small"/>}
                  label="View"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </>
        ),
        field: "view",
        sort: "asc",
        width: 270,
      },
      {
        label: (
          <>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="start"
                  control={<Checkbox size="small"/>}
                  label="Edit"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </>
        ),
        field: "edit",
        sort: "asc",
        width: 150,
      },
      {
        label: (
          <>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="start"
                  control={<Checkbox size="small"/>}
                  label="Delete"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </>
        ),
        field: "delete",
        sort: "asc",
        width: 150,
      },
      {
        label: (
          <>
            <FormControl component="fieldset">
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="start"
                  control={<Checkbox size="small"/>}
                  label="Export"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </>
        ),
        field: "export",
        sort: "asc",
        width: 200,
      },
    ],
    rows: details,
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Rules APP" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    <Row>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Set Rules</Label>
                          <Select
                            name="rules"
                            value={selectedPrivilege}
                            onChange={(selected) => {
                              handleSelectChange(selected);
                            }}
                            classNamePrefix="select2-selection"
                            options={privilegeOptions}
                          />
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            {/* <Col className="col-12">
              <Card>
                <CardBody>
                  <MDBDataTable
                    paging={false}
                    responsive
                    bordered
                    data={data}
                    id="testRuleApp"
                  />
                </CardBody>
              </Card>
            </Col> */}
            <Col xl="12">
              <Card>
                <CardBody style={{ marginTop: "35px", marginBottom: "35px" }}>
                  <Table
                    style={{ textAlign: "center" }}
                    id="waste_id"
                    className="table table-bordered dataTable"
                  >
                    <TableHead>
                      <TableRow style={{ textAlign: "center" }}>
                        <TableCell
                          style={{ width: "180px", textAlign: "center" }}
                        >
                          Menu
                        </TableCell>
                        <TableCell
                          style={{ width: "210px", textAlign: "center" }}
                        >
                          Sub Menu
                        </TableCell>

                        {options &&
                          options.map((item) => {

                            let status = false;
                            dataOptions&&dataOptions.map((mainmenu)=>{
                              let submenu = mainmenu.sub_menu;
                             
                              submenu&&submenu.map((submenu)=>{
                                let submenuOptions = submenu.sub_menu_options;
                                submenuOptions&&submenuOptions.map((submenuOptions)=>{
                                  if(submenuOptions === item?._id)
                                    status = true;
                                })
                              })
                            });
                            return (
                              <TableCell
                                style={{ width: "130px", textAlign: "center" }}
                              >
                                {item.option_name}
                                <input
                                  type="checkbox"
                                  className="mb-2"
                                  style={{marginLeft:"6px",cursor:"pointer"}}
                                  checked={
                                    status === true ? true : false
                                  }
                                  onChange={(e) =>
                                    handleAllmenuCheckboxChange(e, item)
                                  }
                                />
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {dataOptions &&
                        dataOptions.map((item, index) => (
                          <TableRow style={{ verticalAlign: "top" }}>
                            <TableCell
                              component="td"
                              scope="row"
                              style={{ textAlign: "left" }}
                            >
                              <div>
                                <ul style={{ listStyleType: "none" }}>
                                  <li className="mb-2">
                                    <input
                                      type="checkbox"
                                      className="mb-2"
                                      checked={
                                        item.allocation_status === 1
                                          ? true
                                          : false
                                      }
                                      onChange={(e) =>
                                        handleMainmenuCheckboxChange(e, item)
                                      }
                                      style={{cursor:"pointer"}}
                                    />
                                    <span
                                      style={{
                                        marginLeft: "5px",
                                      }}
                                    >
                                      {item.menu_english_name}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </TableCell>
                            <TableCell style={{ textAlign: "left" }}>
                              <div>
                                <ul style={{ listStyleType: "none" }}>
                                  {item.sub_menu &&
                                    item.sub_menu.map((element, index) => {
                                      return (
                                        <li className="mb-2">
                                          <input
                                            type="checkbox"
                                            checked={
                                              element.allocation_status === 1
                                                ? true
                                                : false
                                            }
                                            onChange={(e) =>
                                              handleCheckboxChange(
                                                e,
                                                element,
                                                item
                                              )
                                            }
                                            className="mb-2"
                                            style={{cursor:"pointer"}}
                                          />
                                          <span
                                            style={{
                                              marginLeft: "5px",
                                            }}
                                          >
                                            {element.sub_english_name}
                                          </span>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            </TableCell>
                            {options &&
                              options.map((element, index) => {
                                return (
                                  <TableCell>
                                    <div>
                                      <ul style={{ listStyleType: "none" }}>
                                        {item.sub_menu &&
                                          item.sub_menu.map((el, index) => {
                                            let status = false;

                                            el.sub_menu_options &&
                                              el.sub_menu_options.map(
                                                (submenuOpt) => {
                                                  if (
                                                    submenuOpt === element?._id
                                                  ) {
                                                    status = true;
                                                  }
                                                }
                                              );
                                            return (
                                              <li className="mb-2">
                                                <input
                                                  type="checkbox"
                                                  className="mb-2"
                                                  checked={status === true?true:false}
                                                  onChange={(e) =>
                                                    handleOptionCheckboxChange(
                                                      e,
                                                      el,
                                                      element
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                />
                                                <span
                                                  style={{
                                                    marginLeft: "5px",
                                                  }}
                                                >
                                                  {""}
                                                </span>
                                              </li>
                                            );
                                          })}
                                      </ul>
                                    </div>
                                  </TableCell>
                                );
                              })}
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
