import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import { Row, Col, Label, Button } from "reactstrap";
import Select from "react-select";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { getFirstday, getDate } from "../../../helpers/globalFunctions";
import $ from "jquery";
import moment from "moment";
import resetPassword from "../../Authentication/resetPassword";
import { MDBDataTable } from "mdbreact";
import { Modal } from "react-bootstrap";
import toastr from "toastr";
import "./orderreport.scss";
import { CSVLink } from "react-csv";
import SweetAlert from "react-bootstrap-sweetalert";





function OrderReport() {

    const [masterObject, setmasterObject] = useState({});
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

    const [selectedOrderFrom, setSelectedOrderFrom] = useState(null);
    const [selectedOrderTo, setSelectedOrderTo] = useState(null);
    const [countryOptions, setCountryoptions] = useState([]);

    const [searchData, setSearchData] = useState({
        from_date: getFirstday(new Date()),
        to_date: getDate(new Date()),
    });

    const [orderTable, setOrderTable] = useState([]);

    const [popupView, setpopupView] = useState(false);
    const [popupData, setpopupData] = useState({});
    const [itempop, setItemPop] = useState({});

    const [dataToBeExported, setDataToBeExported] = useState([]);
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false);
    const [orderIdToBeDeleted, setOrderIdToBeDeleted] = useState(null);



    useEffect(() => {
        fetch_country();
        handleTableData();
    }, []);




    function fetch_country() {
        axios
            .get(`${API_URL}newStates/countries`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                var country_data =
                    res.data.data &&
                    res.data.data.map((el) => {

                        return {
                            label: el.name,
                            value: el._id,
                        };
                    });

                setCountryoptions([
                    {
                        options: country_data,
                    },
                ]);
            });
    }


    // SELECT CHANGE
    const handleSelectChange = (selected, name) => {
        switch (name) {

            case "order_from":
                setSelectedOrderFrom(selected);
                setSearchData({
                    ...searchData,
                    order_from: selected.value,
                });
                break;

            case "order_to":
                setSelectedOrderTo(selected);
                setSearchData({
                    ...searchData,
                    order_to: selected.value,
                });
                break;

            default:
                break;

        }
    }

    // DATE
    let handleDate = (e) => {
        var date1 = $("#from_date").val();
        var date2 = $("#to_date").val();

        // date validation
        let toDate = moment(date1);
        let fromDate = moment(date2);
        let result = fromDate.diff(toDate, "days");

        if (result + 1 <= 0) {
            setSearchData({
                ...searchData,
                [e.target.name]: e.target.value,
                ["to_date"]: "",
            });
            date2 = "";
        } else {
            setSearchData({
                ...searchData,
                [e.target.name]: e.target.value,
            });
        }

        handleTableData(date1, date2);
    }


    // RESET
    function reset() {
        setSearchData({
            from_date: getFirstday(new Date()),
            to_date: getDate(new Date()),
        });
        setSelectedOrderFrom(null);
        setSelectedOrderTo(null);
        handleTableData();
    }


    // TABLE DATA
    const handleTableData = (
        from_date = "",
        to_date = "",
        order_from = "",
        order_to = "",
        Datefrom = "",
    ) => {
        var url = `${API_URL}order/order-report?Datefrom=` + from_date;

        url =
            url +
            "&Dateto=" +
            to_date +
            "&countryfrom=" +
            order_from +
            "&countryto=" +
            order_to;

        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let result = res.data.data;

                let Data = [];
                let dataToBeExported = [];

                result.map((item, index) => {

                    item.id = index + 1;
                    item.date1 = moment(item.date).format("DD-MM-YYYY");

                    item.action = (
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <i
                                className="fas fa-eye"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer",
                                    marginLeft: "0.4em",
                                    marginRight: "0.4em"
                                }}
                                onClick={() => {
                                    handleClickOpenModal(item);
                                    setpopupView(true);
                                }}
                            ></i>

                            <i
                                className="fas fa-trash-alt"
                                style={{
                                    fontSize: "1em",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    setConfirmDeleteAlert(true);
                                    setOrderIdToBeDeleted(item._id);
                                }}
                            ></i>

                        </div>
                    )

                    var exportItem = {};

                    exportItem.Slno = item.id;
                    exportItem.Date = item.date1;
                    exportItem.Order_number = item.order_number;
                    exportItem.From = item.from;
                    exportItem.To = item.to;
                    exportItem.Receiver = item.receiver;
                    exportItem.Grand_total = item.amount;

                    dataToBeExported.push(exportItem);

                    Data.push(item);
                })

                setOrderTable(Data);

                setDataToBeExported([
                    ...dataToBeExported,
                    {
                        Receiver: "",

                        Grand_total: result?.amount,
                    }
                ])
            })
    }

    // DATA
    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: "date1",
                sort: "asc",
                width: 150,
            },
            {
                label: "Order Number",
                field: "order_number",
                sort: "asc",
                width: 150,
            },
            {
                label: "From",
                field: "from",
                sort: "asc",
                width: 150,
            },
            {
                label: "To",
                field: "to",
                sort: "asc",
                width: 150,
            },
            {
                label: "Receiver",
                field: "receiver",
                sort: "asc",
                width: 150,
            },
            {
                label: "Grand Total",
                field: "amount",
                sort: "asc",
                width: 150,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150,
            },
        ],
        rows: orderTable,
        // rows: [
        //     {
        //         id: "1",
        //         date: "11-08-2023",
        //         order_number: "311 3056",
        //         order_from: "Oman",
        //         order_to: "Afganisthan",
        //         receiver: "Muhammed Adhil",
        //         grand_total: "100.0",
        //         action: (
        //             <div style={{ display: "flex", justifyContent: "center" }}>
        //                 <i
        //                     className="fas fa-eye"
        //                     style={{
        //                         fontSize: "1em",
        //                         cursor: "pointer",
        //                         marginLeft: "0.2em",
        //                         marginRight: "0.5em",
        //                     }}
        //                     onClick={() => {
        //                         setpopupView(true);
        //                     }}
        //                 ></i>

        //                 <i
        //                     className="far fa-trash-alt"
        //                     style={{ fontSize: "1em", cursor: "pointer" }}
        //                 ></i>

        //             </div>
        //         )
        //     }
        // ]
    }

    function handleSearch() {
        let date1 = searchData?.from_date ? searchData?.from_date : "";
        let date2 = searchData?.to_date ? searchData?.to_date : "";
        let orderfrom = searchData?.order_from ? searchData?.order_from : "";
        let orderto = searchData?.order_to ? searchData?.order_to : "";
        handleTableData(date1, date2, orderfrom, orderto);
    }


    function handleClickOpenModal(item) {
        axios
            .get(`${API_URL}order/single-order?_id=${item._id}`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let result = res.data.data;

                result.date1 = moment(result.date).format("DD-MM-YYYY");

                result.item_details &&
                    result.item_details.map((item, index) => {

                        item.itemType = item.item_category.item_Type;
                        item.itemcategory = item.item_category.name;
                        item.unit = item.item_category.unit;
                        item.quantity = item.item_category.quantity;
                        item.rate = item.item_category.rate;

                        setItemPop(item);
                        console.log(item, "itempop");
                    })


                setpopupData(result);
                console.log(result, "popupData");
                setpopupView(true);
            })
    }
    return (

        <React.Fragment>

            {confirmDeleteAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}order/order_reportDelete?_id=${orderIdToBeDeleted}`, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            }).then((res) => {
                                if (res.data.status === 200) {
                                    toastr.success("Order deleted successfully");
                                    handleTableData();
                                    setmasterObject({});
                                } else {
                                    toastr.error("Failed to delete Order");
                                    return;
                                }
                            })
                            .catch((err) => {
                                toastr.error(err.response.data.message);
                                return;
                            })
                        setConfirmDeleteAlert(false);
                    }}
                    onCancel={() => setConfirmDeleteAlert(false)}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : null}

            <Modal show={popupView} isOpen={popupView} size="small" centered={true} toggle={() => setpopupView(!popupView)}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0">Order Details</h5>
                    <button
                        type="button"
                        onClick={() => {
                            setpopupView(false);
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    ></button>
                </div>


                <AvForm className="needs-validation">
                    <div className="modal-body">
                        <table className="table table-bordered dataTable">
                            <tr>
                                <td style={{ padding: "10px", textAlign: "left" }}>Date</td>
                                <td>: {popupData?.date1}</td>
                                <td style={{ paddingLeft: "25px" }}>Order Number</td>
                                <td style={{ textAlign: "left" }}>: {popupData?.order_number}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: "10px", textAlign: "left" }}>Item Type</td>
                                <td>: {itempop?.item_type}</td>
                                <td style={{ paddingLeft: "25px" }}>Item Category</td>
                                <td style={{ textAlign: "left" }}>: {itempop?.itemcategory}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: "10px", textAlign: "left" }}>Unit</td>
                                <td>: {itempop?.unit}</td>
                                <td style={{ paddingLeft: "25px" }}>Rate</td>
                                <td style={{ textAlign: "left" }}>: {itempop?.rate}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: "10px", textAlign: "left" }}>Amount</td>
                                <td>: {popupData?.amount}</td>
                                <td style={{ paddingLeft: "25px" }}>No of boxes</td>
                                <td style={{ textAlign: "left" }}>: {popupData?.no_of_boxes}</td>
                            </tr>

                            <tr>
                                <td style={{ padding: "10px", textAlign: "left" }}>Grand Total</td>
                                <td>: {popupData?.grand_total}</td>
                            </tr>

                        </table>

                    </div>

                </AvForm>

            </Modal>

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Home" breadcrumbItem="Order Report" />

                    <Row>
                        <Col md="12">

                            <Row>

                                <Col md="2">
                                    <Label>From</Label>
                                    <input
                                        name="from_date"
                                        type="date"
                                        id="from_date"
                                        className="form-control"
                                        value={searchData?.from_date}
                                        onChange={handleDate}
                                    />
                                </Col>

                                <Col md="2">
                                    <Label>To</Label>
                                    <input
                                        name="to_date"
                                        type="date"
                                        id="to_date"
                                        value={searchData?.to_date}
                                        min={searchData?.from_date}
                                        onChange={handleDate}
                                        className="form-control"
                                    />
                                </Col>

                                {/* <Col md="2">
                                        <Label>Cargo Type</Label>
                                        <Select
                                            name="cargo_type"
                                            value={selectedCargoType}
                                            options={cargoTypeOptions}
                                            onChange={(selected) => {
                                                handleSelectChange(selected, "cargo_type")
                                            }}
                                            classNamePrefix="select2-selection"
                                        />
                                    </Col> */}

                                <Col md="2">
                                    <Label>Order From</Label>
                                    <Select
                                        name="order_from"
                                        value={selectedOrderFrom}
                                        options={countryOptions}
                                        onChange={(selected) => {
                                            handleSelectChange(selected, "order_from")
                                        }}
                                        classNamePrefix="select2-selection"
                                    />
                                </Col>

                                <Col md="2">
                                    <Label>Order To</Label>
                                    <Select
                                        name="order_to"
                                        value={selectedOrderTo}
                                        options={countryOptions}
                                        onChange={(selected) => {
                                            handleSelectChange(selected, "order_to")
                                        }}
                                        classNamePrefix="select2-selection"
                                    />
                                </Col>

                                <Col md="3" style={{ paddingTop: "28px" }}>

                                    <Button type="search" color="primary" onClick={() => handleSearch()}>
                                        Search
                                    </Button>
                                    <Button color="danger" type="reset" onClick={() => reset()} style={{ marginLeft: "13px" }}>
                                        Reset
                                    </Button>

                                    <Button className="btn btn-success" type="submit" style={{ marginLeft: "13px" }}><i class="fas fa-file-excel"></i>
                                        <CSVLink
                                            data={dataToBeExported}
                                            filename={
                                                "Order_report_" + getDate(new Date()) + ".xls"
                                            }
                                            style={{ color: "white" }}
                                        >
                                            Export
                                        </CSVLink>

                                    </Button>

                                </Col>

                            </Row>

                        </Col>

                        <Col md="12">
                            <MDBDataTable
                                responsive
                                bordered
                                id="orderreporttable"
                                data={data}
                                entries={20}
                            />

                        </Col>
                    </Row>

                </div>

            </div>

        </React.Fragment>
    )
}

export default OrderReport;