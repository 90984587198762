import React from 'react'

function Bookingdashboard() {


  
  return (
    <div>Bookingdashboard</div>
  )
}

export default Bookingdashboard