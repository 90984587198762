import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Label, Button } from "reactstrap";
import Select from "react-select";
import { MDBDataTable } from 'mdbreact';
import "./income.scss";
import { useDispatch, useSelector } from "react-redux";
import axios from 'axios';
import accessToken from '../../../helpers/jwt-token-access/accessToken';
import {
    getDateTime,
    getDate,
    getFirstday,
    getLocalbody,
} from "../../../helpers/globalFunctions";
import moment from "moment";
import toastr from 'toastr';
import { resetProfileFlag } from '../../../store/actions';
import $ from "jquery";
import { CSVLink } from 'react-csv';


function IncomeReport() {

    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const [masterObject, setmasterObject] = useState({
        income_expense_date: getDate(new Date()),
        income_expense_time: moment().format("HH:mm:ss"),
        localbody_id: getLocalbody(),
    });
    const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());

    const [accTypeForSearch, setAccTypeForSearch] = useState(null);
    const [accountType, setAccountType] = useState([]);
    const [incomeExpenseList, setIncomeExpenseList] = useState([]);

    const [searchData, setSearchData] = useState({
        from_date: getFirstday(new Date()),
        to_date: getDate(new Date()),
        localbody_id: getLocalbody(),
    });

    const [dataToBeExported, setDataToBeExported] = useState([]);


    useEffect(() => {
        handleTableData();
        fetch_account_type();
    }, []);


    // useEffect(() => {
    //     var data =
    //         incomeExpenseAccTypes &&
    //         incomeExpenseAccTypes.map((item) => {
    //             return {
    //                 label: item.acc_type_name,
    //                 value: item._id,
    //             };
    //         });

    //     setAccountType([
    //         {
    //             options: data,
    //         },
    //     ]);
    // }, [incomeExpenseAccTypes]);

    function fetch_account_type() {
        axios
            .get(`${API_URL}account_type/options`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                var acnt_data =
                    res.data.data &&
                    res.data.data.map((el) => {

                        return {
                            label: el?.acc_type_name,
                            value: el?._id
                        }
                    })

                setAccountType([{ options: acnt_data }])
            })
    }

    function handleSelectedForSearch(selected, name) {
        switch (name) {
            case "accType":
                setAccTypeForSearch(selected);
                setSearchData({
                    ...searchData,
                    accType: selected.value,
                });
                break;

            default:
                break;
        }
    }

    const style = {
        control: (base) => ({
            ...base,
            border: 0,
            boxShadow: "none",
        }),
    };

    const handleTableData = (
        from_date = "",
        to_date = "",
        acc_type = "",
        acc_detail = "",
        acc_chart = "",
        user_id = ""
    ) => {
        var url = `${API_URL}income_expense?localbody_id=` + currentLocalbody;

        if (from_date === "" && to_date === "") {
            from_date = from_date ? from_date : getFirstday(new Date());
            to_date = to_date ? to_date : getDate(new Date());
        }

        url =
            url +
            "&from_date=" +
            from_date +
            "&to_date=" +
            to_date +
            "&acc_type=" +
            acc_type +
            "&acc_detail=" +
            acc_detail +
            "&acc_chart=" +
            acc_chart +
            "&user_id=" +
            user_id;

        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {

                let result = res.data.data;

                let Data = [];

                let dataToBeExported = [];
                result.map((item, index) => { 
                    item.id = index + 1;
                    item.income_expense_amount1 = item.income_expense_amount;
                    item.income_expense_total1 = item.income_expense_total;
                    item.income_expense_date1 = moment(item.income_expense_date).format(
                        "DD-MM-YYYY"
                    );

                    let selectedStatus = {};
                    if (item.income_expense_approve_status === 0) {
                        selectedStatus = {
                            label: "Pending",
                            value: 0,
                        };
                    } else if (item.income_expense_approve_status === 1) {
                        selectedStatus = {
                            label: "Approved",
                            value: 1,
                        };
                    } else if (item.income_expense_approve_status === 2) {
                        selectedStatus = { label: "Rejected", value: 2 };
                    } else selectedStatus = null;

                    if (item.income_expense_approve_status === 0) {
                        item.status = (
                            <div className="mb-3">
                                <Select
                                    styles={style}
                                    name="income_expense_approve_status"
                                    value={selectedStatus}
                                    onChange={(selected) => {
                                        updateApproveStatus(item, selected);
                                    }}
                                    options={[
                                        // {
                                        //   label: "Pending",
                                        //   value: 0,
                                        // },
                                        {
                                            label: "Approved",
                                            value: 1,
                                        },
                                        {
                                            label: "Rejected",
                                            value: 2,
                                        },
                                    ]}
                                    classNamePrefix="select2-selection"
                                />
                            </div>
                        );
                    } else {
                        item.status = (
                            <div className="mb-3">
                                <Select
                                    styles={style}
                                    name="income_expense_approve_status"
                                    value={selectedStatus}
                                    onChange={(selected) => {
                                        // updateStatus(selected, item);
                                    }}
                                    options={[]}
                                    classNamePrefix="select2-selection"
                                    isDisabled={true}
                                />
                            </div>
                        );
                    }

                    // if (item.income_expense_approve_status === 0) {
                    //   item.status = (
                    //     <div style={{ display: "flex", justifyContent: "center" }}>
                    //       <Button
                    //         color="primary"
                    //         size="sm"
                    //         onClick={() => {
                    //           updateApproveStatus(item, 1);
                    //         }}
                    //       >
                    //         Pending
                    //       </Button>
                    //     </div>
                    //   );
                    // } else if (item.income_expense_approve_status === 1) {
                    //   item.status = (
                    //     <div style={{ display: "flex", justifyContent: "center" }}>
                    //       <Button color="success" size="sm" disabled>
                    //         Approved
                    //       </Button>
                    //     </div>
                    //   );
                    // } else if (item.income_expense_approve_status === 2) {
                    //   item.status = (
                    //     <div style={{ display: "flex", justifyContent: "center" }}>
                    //       <Button color="danger" size="sm" disabled>
                    //         Rejected
                    //       </Button>
                    //     </div>
                    //   );
                    // }

                    var exportItem = {};
                    item.id = index + 1;

                    exportItem.Slno = item.id;
                    exportItem.Date = item.income_expense_date1;
                    exportItem.Account_Head = item.acc_type_name;
                    exportItem.Category = item.acc_chart_name;
                    exportItem.Fund_Source = item.fund_source;
                    exportItem.Amount = item.income_expense_amount;
                    exportItem.Staff = item.added_user;
                    
                    dataToBeExported.push(exportItem);

                    Data.push(item);
                });
                setIncomeExpenseList(Data);
                
                setDataToBeExported([
                    ...dataToBeExported,
                    {
                        Fund_Source: "",

                        Amount: result?.income_expense_total
                    }
                ])

            });
    };


    const updateApproveStatus = (item, selected) => {
        let user_id = "";

        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            user_id = obj._id;
        }

        let data = {
            user_id: user_id,
            localbody_id: currentLocalbody,
            status: selected?.value,
            id: item?._id,
        };
        axios
            .post(`${API_URL}api/staff/approve_expense`, data, {
                headers: {
                    "x-access-token": accessToken,
                },
            })
            .then((res) => {
                if (res.data.success === true) {
                    toastr.success(res.data.message);

                    let date1 = searchData?.from_date ? searchData.from_date : "";
                    let date2 = searchData?.to_date ? searchData.to_date : "";
                    let acc_type = searchData?.income_expense_head
                        ? searchData.income_expense_head
                        : "";
                    let acc_detail = searchData?.income_expense_subhead
                        ? searchData.income_expense_subhead
                        : "";
                    let user_id = searchData?.income_expense_staff
                        ? searchData.income_expense_staff
                        : "";
                    let acc_chart = searchData?.income_expense_chartofaccounts
                        ? searchData.income_expense_chartofaccounts
                        : "";

                    handleTableData(
                        date1,
                        date2,
                        acc_type,
                        acc_detail,
                        acc_chart,
                        user_id
                    );
                } else {
                    toastr.error(res.data.message);
                }
            })
            .catch((err) => {
                toastr.error(err.response.data.message);
                return;
            });
    };

    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "Date",
                field: "income_expense_date1",
                sort: "asc",
                width: 150,
            },
            {
                label: "Account Head",
                field: "acc_type_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Account Category",
                field: "acc_detail_name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Fund Source",
                field: "fund_source",
                sort: "asc",
                width: 150,
            },
            {
                label: "Amount",
                field: "income_expense_amount1",
                sort: "asc",
                width: 150,
            },
            {
                label: "Added By",
                field: "added_user",
                sort: "asc",
                width: 150,
            },
        ],
        rows: incomeExpenseList,
    }

    const reset = () => {
        setAccTypeForSearch(null);
        setmasterObject({});
        setSearchData({
            from_date: getFirstday(new Date()),
            to_date: getDate(new Date()),
        });
        handleTableData();
    }

    function handleSearch() {
        let acc_type = searchData?.accType ? searchData?.accType : "";
        let date1 = searchData?.from_date ? searchData?.from_date : "";
        let date2 = searchData?.to_date ? searchData?.to_date : "";

        handleTableData(date1, date2, acc_type);
    }

    const handleDateSearch = (e) => {
        var date1 = $("#from_date").val();
        var date2 = $("#to_date").val();
        let toDate = moment(date1);
        let fromDate = moment(date2);
        let result = fromDate.diff(toDate, "days");

        if (result + 1 <= 0) {
            setSearchData({
                ...searchData,
                [e.target.name]: e.target.value,
                ["to_date"]: "",
            });
            date2 = "";
        } else {
            setSearchData({
                ...searchData,
                [e.target.name]: e.target.value,
            });
        }

    };

    return (
        <React.Fragment>

            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumb title="Home" breadcrumbItem="Income/Expense Report" />

                    <Row>
                        <Col md="12">

                            <Row>
                                <Col md="2">
                                    <div >
                                        <Label htmlFor="validationCustom05">From</Label>
                                        {/* <div className="col-md-10"> */}
                                        <input
                                            className="form-control"
                                            type="date"
                                            id="from_date"
                                            name="from_date"
                                            value={searchData?.from_date}
                                            onChange={handleDateSearch}
                                        />
                                        {/* </div> */}
                                    </div>
                                </Col>
                                <Col md="2">
                                    <div >
                                        <Label htmlFor="validationCustom05">To</Label>
                                        {/* <div className="col-md-10"> */}
                                        <input
                                            className="form-control"
                                            type="date"
                                            id="to_date"
                                            name="to_date"
                                            value={searchData?.to_date}
                                            onChange={handleDateSearch}
                                            min={searchData?.from_date}
                                        />
                                        {/* </div> */}
                                    </div>
                                </Col>

                                <Col md="2">
                                    <Label>Account Head</Label>
                                    <Select
                                        name="customer_community_id"
                                        placeholder="Account Head"
                                        value={accTypeForSearch}
                                        onChange={(Selected) => {
                                            handleSelectedForSearch(Selected, "accType")
                                        }}
                                        options={accountType}
                                        classNamePrefix="select2-selection"
                                    />
                                </Col>

                                <Col md="1" style={{ marginTop: "26px" }}>
                                    <Button color="primary" type="search" onClick={() => handleSearch()}>Search</Button>
                                </Col>

                                <Col md="1" style={{ marginTop: "26px", marginLeft: "-18px" }}>
                                    <Button color="danger" type="reset" onClick={() => reset()}>Reset</Button>
                                </Col>

                                {searchData ? (
                                    <Col md="1" style={{ marginTop: "26px", marginLeft: "-46px" }}>
                                        <Button className='btn btn-success mb-3' type='submit' style={{ float: "right" }}><i class="fas fa-file-excel"></i>
                                            <CSVLink
                                                data={dataToBeExported}
                                                filename={
                                                    "Income_expense_report_" + getDate(new Date()) + ".xls"
                                                }
                                                style={{ color: "white" }}
                                            >
                                                Export
                                            </CSVLink>
                                        </Button>
                                    </Col>
                                ) : ""}
                            </Row>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12" className="mt-2">
                            <MDBDataTable
                                responsive
                                bordered
                                id="incometableid"
                                data={data}
                                entries={20}
                            />
                        </Col>
                    </Row>
                </div>

            </div>
        </React.Fragment>
    )

}

export default IncomeReport;