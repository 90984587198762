import {
  GET_ACCTYPES,
  GET_ACCTYPES_FAIL,
  GET_ACCTYPES_SUCCESS,
  ADD_ACCTYPE,
  ADD_ACCTYPE_FAIL,
  ADD_ACCTYPE_SUCCESS,
  UPDATE_ACCTYPE,
  UPDATE_ACCTYPE_FAIL,
  UPDATE_ACCTYPE_SUCCESS,
  DELETE_ACCTYPE,
  DELETE_ACCTYPE_FAIL,
  DELETE_ACCTYPE_SUCCESS,
  GET_ACCTYPE_OPTIONS,
  GET_ACCTYPE_OPTIONS_SUCCESS,
  GET_ACCTYPE_OPTIONS_FAIL,
} from './actionTypes';

export const getAllAccountTypes = (localbody_id) => ({
  type: GET_ACCTYPES,
  localbody_id: localbody_id,
});

export const getAllAccountTypesSuccess = (acctype) => ({
  type: GET_ACCTYPES_SUCCESS,
  payload: acctype,
});

export const getAllAccountTypesFail = (error) => ({
  type: GET_ACCTYPES_FAIL,
  payload: error,
});

export const addAccountType = (acctype) => ({
  type: ADD_ACCTYPE,
  payload: acctype,
});

export const addAccountTypeSuccess = (acctype) => ({
  type: ADD_ACCTYPE_SUCCESS,
  payload: acctype,
});

export const addAccountTypeFail = (error) => ({
  type: ADD_ACCTYPE_FAIL,
  payload: error,
});

export const updateAccountType = (acctype) => ({
  type: UPDATE_ACCTYPE,
  payload: acctype,
});

export const updateAccountTypeSuccess = (acctype) => ({
  type: UPDATE_ACCTYPE_SUCCESS,
  payload: acctype,
});

export const updateAccountTypeFail = (error) => ({
  type: UPDATE_ACCTYPE_FAIL,
  payload: error,
});

export const deleteAccountType = (acctype, auth_user) => ({
  type: DELETE_ACCTYPE,
  payload: acctype,
  auth_data: auth_user,
});

export const deleteAccountTypeSuccess = (acctype) => ({
  type: DELETE_ACCTYPE_SUCCESS,
  payload: acctype,
});

export const deleteAccountTypeFail = (error) => ({
  type: DELETE_ACCTYPE_FAIL,
  payload: error,
});

export const accountTypeOptions = () => ({
  type: GET_ACCTYPE_OPTIONS,
});

export const accountTypeOptionsSuccess = (acctypes) => ({
  type: GET_ACCTYPE_OPTIONS_SUCCESS,
  payload: acctypes,
});

export const accountTypeOptionsFail = (error) => ({
  type: GET_ACCTYPE_OPTIONS_FAIL,
  payload: error,
});
