import React, { useRef, useEffect, useState } from 'react';
import Select from 'react-select';
import { MDBDataTable } from "mdbreact";
import toastr from 'toastr';
import "./Addport.scss"
import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from 'axios';
import accessToken from '../../../helpers/jwt-token-access/accessToken';
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";



function AddPort() {


  useEffect(() => {
    fetch_all_country();
    handleTableData();


  }, [])


  const [addport, setaddport] = useState({});
  const [countryoptions, setcountryoptions] = useState([]);
  const [selectCountry, setselectCountry] = useState(null);
  const [stateoptions, setstateoptions] = useState([]);
  const [selectState, setselectState] = useState(null)
  const [itemForTable, setitemForTable] = useState([]);
  const [portIdToBeDeleted, setportIdToBeDeleted] = useState(null);
  const [confirmDeletAlert, setconfirmDeletAlert] = useState(null);
  const [portIdToBeUpdated, setportIdToBeUpdated] = useState(null);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [errors, seterrors] = useState({
    countryname: "",
    statename: "",

  })

  const fetch_all_country = () => {
    axios
      .get(`${API_URL}newStates/countries`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var country_data =
          res.data.data &&
          res.data.data.map((country) => {
            return {
              label: country?.name,
              value: country?._id,

            };
          })
        setcountryoptions([
          {
            options: country_data,
          },
        ]);
      });
  }

  const fetch_all_state = (country_id) => {
    axios
      .get(`${API_URL}newStates/list?country_id=` + country_id, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {
        var state_data =
          res.data.data &&
          res.data.data.map((st) => {
            return {
              label: st.name,
              value: st._id
            }
          })
        setstateoptions([{ options: state_data }])
      })
  }


  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "country":
        setselectCountry(selected);
        setselectState(null)
        fetch_all_state(selected.value)
        setaddport({ ...addport, country: selected.value })
        break;

      case "state":
        setselectState(selected);
        setaddport({ ...addport, state: selected.value })
        break;
      default:
        break
    }
  }



  function handleInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    setaddport({ ...addport, [name]: value })

  }
  const OnTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };




  let preupdatedport = (item,) => {

    setportIdToBeUpdated(item._id);
    fetch_all_state(item.country._id);
    let countryopt = {
      label: item?.country.name,
      value: item?.country._id

    };

    setselectCountry(countryopt);


    let stateopt = {
      label: item?.state.name,
      value: item?.state._id
    };
    setselectState(stateopt)



    setaddport(item)
  }

  const handleValidSubmit = () => {

    if (selectCountry === null ||
      selectState === null) {
      let errorVal = errors;
      if (selectCountry === null)
        errorVal.countryname = "Please Select Country"
      if (selectState === null)
        errorVal.statename = "Please Select State"

      seterrors({
        ...errorVal,
      });
    }

    else {
      if (portIdToBeUpdated) {
        axios
          .post(`${API_URL}Port/edit`, addport, {
            headers: {
              "x-access-token": accessToken,
            }
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Port Updated Successfully")
              formRef.current.reset();
              handleTableData();
              setaddport({});
              setportIdToBeUpdated(null);
              setselectCountry(null);
              setselectState(null);
            }
            else {
              toastr.error("Faild To Update")
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message)
            return;
          })
      }
      else {

        axios
          .post(`${API_URL}port/add`, addport, {
            headers: {
              "x-access-token": accessToken,
            }
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Port Added Successfully")
              handleTableData();
              formRef.current.reset();
              reset()
              setselectCountry(null)
              setselectState(null);
            }
            else {
              toastr.error("Faild To Add Port")
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message)
            return;
          })

      }
    }
  }

  function handleTableData() {
    axios
      .get(`${API_URL}port/list`, {
        headers: {
          "x-access-token": accessToken
        },
      })
      .then((res) => {
        var result = res.data.data
        let portData = [];
        result.map((item, index) => { 


          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i className="uil-edit-alt"
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  marginLeft: "0.4rem",
                  marginRight: "0.4rem"
                }}
                onClick={() => {
                  OnTop();

                  preupdatedport(item);

                }}
              ></i>
              <i className="uil-trash-alt"
                style={{ fontSize: "1rem", cursor: "pointer" }}
                onClick={() => {

                  setportIdToBeDeleted(item._id);
                  setconfirmDeletAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1
          item.statename = item?.state?.name;
          item.staff = item?.createdBy?.firstName;


          if (item.country) {
            item.country1 = (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "absolute", top: "-30px" }}
                  >
                    {item?.country?.name}
                  </Tooltip>
                }
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={`${API_URL}uploads/country_flag/${item?.country?.flag}`} width={"30px"}></img>
                </div>
              </OverlayTrigger>);
          }



          // item.country1=(
          //   <div style={{ display: "flex", justifyContent: "center",cursor:"pointer" }}>
          //  <img 
          //   src={`${API_URL}uploads/flag/${item?.country?.flag}`} width={"30px"}
          //   title={`${item.country.name}`}
          //   >
          // </img>

          //  </div>
          //  )

          portData.push(item);

        });
        setitemForTable(portData);
      })
  }
  const reset = () => {
    formRef.current.reset();
    setaddport({});
    setportIdToBeDeleted(null);
    setportIdToBeUpdated(null)
    setselectCountry(null);
    setselectState(null)
    seterrors({
      countryname: "",
      statename: "",
    })
  }

  const formRef = useRef();
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150
      },
      {
        label: "Country",
        field: "country1",
        sort: "asc",
        width: 150
      },
      {
        label: "State",
        field: "statename",
        sort: "asc",
        width: 150
      },
      {
        label: "Port Name",
        field: "port_name",
        sort: "asc",
        width: 150
      },
      {
        label: "Latitude",
        field: "latitude",
        sort: "asc",
        width: 150
      },
      {
        label: "Longitude",
        field: "longitude",
        sort: "asc",
        width: 150
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150
      },

    ],
    rows: itemForTable,
  }

  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <React.Fragment>
      {confirmDeletAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}Port/delete` + "/" + portIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                }
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Port Deleted Successfully");
                  handleTableData();
                  setportIdToBeDeleted(null);
                  setaddport({});
                  formRef.current.reset();
                  setselectCountry(null)
                  setselectState(null);
                  setportIdToBeUpdated(null);
                  // seterrors({
                  //   countryname: "",
                  //   statename: "",
                  // })

                } else {
                  toastr.error("Failed To Delete")
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setconfirmDeletAlert(false)
          }}
          onCancel={() => setconfirmDeletAlert(false)}
        >
          Are you sure want to delete it?
        </SweetAlert>
      ) : null}

      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumb title="Home" breadcrumbItem="Add Port" />
          <Row>
            <Col xl="12">
              {/* <Card >
                 <CardBody > */}
              <AvForm
                className="needs-validation"
                ref={formRef}
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <Row>
                  <Col md="3">
                    <div className=' mb-3'>
                      <Label>Country</Label>
                      <Select
                        name="portname"
                        value={selectCountry}
                        options={countryoptions}
                        placeholder="Select Country"
                        onChange={(selected) => {
                          handleSelectChange(selected, "country")
                        }}
                        classNamePrefix="select2-selection" />
                      <p className='text-danger'
                        style={{ fontSize: "11px" }}>
                        {selectCountry === null ? errors.countryname : ""}
                      </p>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className=' mb-3'>
                      <Label>State</Label>
                      <Select
                        name="state"
                        value={selectState}
                        options={stateoptions}
                        placeholder="Select State"
                        onChange={(selected) => {
                          handleSelectChange(selected, "state")
                        }}
                        classNamePrefix="select2-selection" />
                      <p className='text-danger'
                        style={{ fontSize: "11px" }}>
                        {selectState === null ? errors.statename : ""}
                      </p>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Port Name</Label>
                      <AvField
                        style={customStyles}
                        name="port_name"
                        placeholder="Port Name"
                        type="text"
                        value={addport?.port_name}
                        onChange={handleInput}
                        className="form-control"
                        errorMessage="Enter Port Name"
                        validate={{ required: { value: true } }}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label >
                        Latitude
                      </Label>
                      <AvField
                        style={customStyles}
                        name="latitude"
                        placeholder="Latitude"
                        type="text"
                        value={addport?.latitude}
                        onChange={handleInput}
                        // errorMessage="Enter latitude"
                        className="form-control"
                      // validate={{ required: { value: true } }}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label >
                        Longitude
                      </Label>
                      <AvField
                        style={customStyles}
                        name="longitude"
                        placeholder="Longitude"
                        type="text"
                        value={addport?.longitude}
                        onChange={handleInput}
                        // errorMessage="Enter Longitude"
                        className="form-control"
                      // validate={{ required: { value: true } }}
                      />
                    </div>
                  </Col>
                  <Col md="3" style={{ paddingTop: "4px" }}>
                    <div className='mt-4'>
                      {portIdToBeUpdated ? (
                        <Button className='mx-2'
                          color='primary'
                          type='Update'>
                          Update
                        </Button>
                      ) : (
                        <Button className='mx-2'
                          color='primary'
                          type='submit'>
                          Submit
                        </Button>
                      )
                      }

                      <Button
                        color='danger'
                        type='reset'
                        onClick={() => reset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>

              </AvForm>
              {/* </CardBody>
               </Card> */}
            </Col>
          </Row>
          <Col className='col-12'>
            {/* <Card>
             <CardBody> */}
            <MDBDataTable
              bordered
              responsive
              id="Porttable"
              data={data}
              entries={20}

            />
            {/* </CardBody>
           </Card> */}
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AddPort