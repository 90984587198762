import React, { useRef, useEffect, useState } from 'react';
import Select from 'react-select';
import { MDBDataTable } from "mdbreact";
import toastr, { error } from 'toastr';
import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import axios from 'axios';
import accessToken from '../../../helpers/jwt-token-access/accessToken';
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";





function Quickorder() {
    const [countryOption, setcountryOption] = useState([]);
    const [selecetCountry, setselecetCountry] = useState(null);
    const [quickOrderobj, setquickOrderobj] = useState({});
    const [ItemsforTable, setItemsforTable] = useState([]);
    const [CountryIdtobeDeleted, setCountryIdtobeDeleted] = useState(null);
    const [confirmDeletAlert, setconfirmDeletAlert] = useState(false);
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

    useEffect(() => {
        fetch_all_country();
        handleTabledata();
    }, []);



    function fetch_all_country() {
        axios
            .get(`${API_URL}newStates/countries`, {
                headers: {
                    "x-access-token": accessToken,
                }
            })
            .then((res) => {
                var country_data =
                    res.data.data &&
                    res.data.data.map((cou) => {
                        return {
                            label: cou?.name,
                            value: cou?._id,
                        }
                    })
                setcountryOption([
                    { options: country_data }
                ])
            })
    }



    function handleSelectChange(selected, name) {
        switch (name) {
            case "country":
                setselecetCountry(selected);
                setquickOrderobj({ ...quickOrderobj, country_id: selected.value })
                break;
        }
    }

    function handleValidSubmit() {
        axios
            .post(`${API_URL}quick-order/country`, quickOrderobj, {
                headers: {
                    "x-access-token": accessToken,
                }
            })
            .then((res) => {
                if (res.data.status === 200) {
                    toastr.success("Country Added Successfully")
                    handleTabledata();
                    setCountryIdtobeDeleted(null);
                    setselecetCountry(null);
                } else {
                    toastr.error('faild to add quick order country')
                    return;
                }
            })
            .catch((err) => {
                toastr.error(err.response.data.message)
            })
    }

    function handleTabledata() {
        axios
            .get(`${API_URL}quick-order/countries`, {
                headers: {
                    "x-access-token": accessToken,
                }
            })
            .then((res) => {
                console.log("res table", res.data.data);
                var result = res.data.data
                let quick_data = [];

                result.map((item, index) => {

                    item.action = (
                        <div style={{ display: "flex", justifyContent: "center" }}>

                            <i className="uil-trash-alt"
                                style={{ fontSize: "1rem", cursor: "pointer" }}
                                onClick={() => {

                                    setCountryIdtobeDeleted(item._id);
                                    setconfirmDeletAlert(true);
                                }}
                            ></i>
                        </div>
                    );
                    item.id = index + 1
                    item.country1 = item?.country_id?.name
                    item.flag1 = (

                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={`${API_URL}uploads/country_flag/${item?.country_id?.flag}`} width={"30px"}></img>
                        </div>
                    )

                    quick_data.push(item)
                })
                setItemsforTable(quick_data);
            })
    }
    console.log("ii", ItemsforTable);


    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150
            },
            {
                label: "Country",
                field: "country1",
                sort: "asc",
                width: 150
            },
            {
                label: "Flag",
                field: "flag1",
                sort: "asc",
                width: 150

            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150
            }
        ],
        rows: ItemsforTable,
    }
    return (
        <React.Fragment>

            {confirmDeletAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmBtnText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}quick-order/country/delete?_id=${CountryIdtobeDeleted}`, {
                                headers: {
                                    "x-access-token": accessToken,
                                }
                            })
                            .then((res) => {
                                // console.log(res);
                                if (res.data.status === 200) {
                                    toastr.success("Country Deleted Successfully");
                                    handleTabledata();
                                    setselecetCountry(null);
                                    setCountryIdtobeDeleted(null);


                                } else {
                                    toastr.error("Failed To Delete")
                                    return;
                                }
                            })
                            .catch((err) => {
                                console.log("errr", err);
                                toastr.error(err.response.data.message);
                                return;
                            });
                        setconfirmDeletAlert(false)
                    }}
                    onCancel={() => setconfirmDeletAlert(false)}
                >
                    Are you sure want to delete it?
                </SweetAlert>
            ) : null}

            <div className='page-content'>
                <div className='container-fluid'>
                    <Breadcrumb title="Home" breadcrumbItem="Quick Orders" />
                    <AvForm
                        className="needs-validation"
                        // ref={formRef}
                        onValidSubmit={(e, v) => {
                            handleValidSubmit(e, v);
                        }}
                    >
                        <Row>
                            <Col md="3">
                                <div className='mb-3'>
                                    <Label>Add Country for Quick Order</Label>
                                    <Select
                                        name="country_id"
                                        placeholder="Select Country"
                                        value={selecetCountry}
                                        options={countryOption}
                                        onChange={(selected) => {
                                            handleSelectChange(selected, "country")
                                        }}
                                    >
                                    </Select>
                                </div>
                            </Col>
                            <Col md="3" style={{ paddingTop: "4px" }}>
                                <div className='mt-4'>

                                    <Button className='mx-2'
                                        color='primary'
                                        type='submit'
                                    >
                                        Submit
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                    <Row>
                        <Col md="8">
                            <MDBDataTable
                                bordered
                                id="quickorder"
                                data={data}
                                entries={20} />
                        </Col>
                    </Row>

                </div>
            </div>
        </React.Fragment>


    )
}

export default Quickorder
