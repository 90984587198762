import {
    GET_STAFF_SALARY,
    GET_STAFF_SALARY_SUCCESS,
    GET_STAFF_SALARY_FAIL,
} from "./actionTypes";

export const getStaffSalary = (localbody_id,month,days,work_days) => ({
    type: GET_STAFF_SALARY,
    localbody_id: localbody_id,
    month:month,
    days:days,
    work_days:work_days
});

export const getStaffSalarySuccess = (data) => ({
    type: GET_STAFF_SALARY_SUCCESS,
    payload: data,
});

export const getStaffSalaryFail = (error) => ({
    type: GET_STAFF_SALARY_FAIL,
    payload: error,
});