import React, { useRef } from 'react';
import {
    Row,
    Col,
} from "reactstrap";
import { Box } from "@mui/system";
import Barcode from 'react-barcode';
import moment from "moment";
import img from "../../assets/images/img.png";
import img2 from "../../assets/images/img2.png";
import LogoDark from "../../assets/images/logo-large.png"
import SingleOrder from '../Delivery/SingleOrder/SingleOrder';
export const PrintableComponentOfficeUseDomestic = React.forwardRef((props, ref) => {
    const SingleOrderList1 = props.data;
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const barcodeRef = useRef(null);
    return (
        <div ref={ref}>
            <div className="modal-content" style={{ display: "flex", justifyContent: "center" }}>
                <div className="ordersummary " style={{ width: "100%", padding: "32px" }}>
                    <Box className="summary text-center">
                        {/* <Row>
                    <h4 className="alfarha font-size-16">BOOKING NOTE # {SingleOrderList?.order_number}</h4>
                  </Row> */}
                        <Row>
                            <Col className="content md-8" md="5">
                                <img
                                    src={LogoDark}
                                    style={{ width: "90%", marginTop: "-21px" }}
                                    alt=""
                                />
                            </Col>
                            <Col md="3"><h4 className='alfarha2' style={{ marginBottom: "20px", marginTop: "15px", fontFamily: 'Almarai' }}><b>OFFICE COPY</b></h4></Col>
                            <Col md="4">
                                <h3 className='alfarha2' style={{ fontSize: "3rem", fontFamily: "Almarai" }}><b>{SingleOrderList1?.branch_id?.short_Code?.toUpperCase()}</b></h3> <br />
                                <h4 className='alfarha2' style={{ fontSize: "2rem", marginTop: "-5px", fontFamily: "Almarai" }}><b>BOOKING NOTE</b></h4>
                            </Col>
                        </Row>
                        <hr style={{ marginTop: "9px", height: "1.3px", color: "#000" }} />
                    </Box>
                    <Row>
                        <Col md="12">
                            <table style={{ width: "100%" }}>
                                <tr>
                                    <td style={{ padding: "0px" }}><h4 className="alfarha " style={{ fontSize: "2rem", fontFamily: 'Almarai' }}>{(SingleOrderList1?.booking_type == 0) ? SingleOrderList1?.country_to?.name?.toUpperCase() : SingleOrderList1?.city_to?.name?.toUpperCase()}</h4></td>
                                    <td style={{ padding: "0px", textAlign: "right" }}><Barcode value={SingleOrderList1?.order_number} format="CODE128"
                                        displayValue={false}
                                        fontSize={12}
                                        width={1.5}
                                        height={30}
                                        ref={barcodeRef}
                                    /></td>
                                </tr>
                            </table>

                        </Col>

                    </Row>
                    <hr style={{ marginTop: "9px", height: "1.3px", color: "#000" }} />
                    <Row>
                        <Col md="4" className="alfarha" style={{ fontFamily: 'Almarai' }}>Booking ID: <b>{SingleOrderList1?.cargo_type?.cargo_Type} {SingleOrderList1?.order_number}</b></Col>
                        <Col md="4" ></Col>
                        <Col md="4" className="content1 alfarha" style={{ fontFamily: 'Almarai' }}>Date : {moment(SingleOrderList1?.date + " " + SingleOrderList1?.time).format("DD-MM-YYYY hh:mm a")}</Col>
                    </Row>
                    <hr className='hr-dashed' style={{ marginTop: "9px" }} />
                    <Row>
                        <Col>

                            <h6 className="alfarha" style={{ fontFamily: 'Almarai' }}><b>Consignor / Shipper Details :</b></h6>
                            <table id="senderAddTable">
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Name</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList1?.sender?.name?.toUpperCase()}</b></th>
                                </tr>
                                {SingleOrderList1?.sender?.id_number && (
                                    <tr>
                                        <th style={{ fontFamily: 'Almarai' }}>ID No</th><th> : </th><th className="toCapitalize">{SingleOrderList1?.sender?.id_number}</th>
                                    </tr>
                                )}
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Mobile No</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList1?.sender?.mobile_number}
                                        {
                                            SingleOrderList1?.sender?.whattsapp_number ? (
                                                <> / {SingleOrderList1?.sender?.whattsapp_number}</>
                                            ) : null
                                        }</b>
                                    </th>
                                </tr>

                            </table>

                        </Col>
                        <Col className="mx-2" md-3>
                            <h6 className="alfarha" style={{ fontFamily: 'Almarai' }}><b>Consignee / Receiver Details :</b></h6>
                            <table id="receiverAddTable">
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Name</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList1?.receiver?.name?.toUpperCase()}</b></th>
                                </tr>
                                {/* <tr>
                                    <th>House Name</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.house_name?.toLowerCase()}</th>
                                </tr>
                                <tr>
                                    <th>Street</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toLowerCase() : "")}</th>
                                </tr>
                                <tr>
                                    <th>Post Office</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toLowerCase() : "")}</th>
                                </tr>
                                <tr>
                                    <th>Pincode</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.pin_code}</th>
                                </tr>
                                <tr>
                                    <th>District</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.district}</th>
                                </tr>
                                <tr>
                                    <th>State</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.rece_state?.name}</th>
                                </tr> */}

                                {/* <tr>
                                    <th>Address</th><th> : </th><th className="toCapitalize" style={{ textAlign: "justify" }}>{SingleOrderList?.receiver?.house_name?.toLowerCase() + " " + (SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toLowerCase() : "")}{" "}{(SingleOrderList?.receiver?.landmark ? SingleOrderList.receiver.landmark.toLowerCase() : "") + " " + (SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toLowerCase() : "") + " " + SingleOrderList?.receiver?.pin_code}
                                        <br />{SingleOrderList?.receiver?.district + ", " + SingleOrderList?.receiver?.rece_state?.name}
                                    </th>
                                </tr> */}
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Address</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList1?.receiver?.address?.toUpperCase()}</b></th>
                                </tr>

                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Mobile No</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList1?.receiver?.mobile_number} {SingleOrderList1?.receiver?.telephone_number ? (
                                        <> / {SingleOrderList1?.receiver?.telephone_number}</>
                                    ) : null}</b></th>
                                </tr>

                            </table>



                        </Col>

                    </Row>
                    <hr className='hr-dashed' style={{ marginTop: "1px" }} />

                    <Row >

                        <Col className="mt-2 with-dashed-border" md="8">
                            <table id="adddomestictableid">
                                <tbody>
                                    <tr>
                                        <th style={{ fontFamily: 'Almarai' }}>Sl No</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Item Category</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Unit</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Qnty</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Rate</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Amount</th>
                                    </tr>
                                </tbody>

                                <tbody>
                                    {
                                        SingleOrderList1 &&
                                        SingleOrderList1?.item_details?.map((el, index) => {
                                            return (
                                                <tr>
                                                    <td style={{ fontFamily: 'Almarai' }}>{index + 1}</td>
                                                    <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>{el?.item_category?.name?.toUpperCase()}</td>
                                                    <td style={{ fontFamily: 'Almarai' }}>{el?.unit?.toUpperCase()}</td>
                                                    <td style={{ fontFamily: 'Almarai' }}>{el?.quantity.toFixed(3)}</td>
                                                    <td style={{ fontFamily: 'Almarai' }}>{el?.rate.toFixed(3)}</td>
                                                    <td style={{ fontFamily: 'Almarai' }}>{el?.amount.toFixed(3)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>

                            </table>

                        </Col>

                        <Col md="4">

                            {/* <Row>

                                <Col md="2">
                                    <div className='mb-3 d-flex'>No of Boxes :</div>

                                </Col>

                                <Col md="2">
                                    <div>Total Weight :</div>

                                </Col>

                                <table>
                                    <tr>
                                        <th>Handling rate</th>
                                    </tr>
                                </table>
                            </Row> */}

                            <Row >
                                {/* {SingleOrderList?.no_of_boxes ? (
                                    <Col className='col-cls-cls  with-dashed-border' >
                                        <div className='boxes-bx' style={{ fontFamily: 'Almarai' }}><b>No of Boxes:</b></div>
                                        <div className='boxes-bx' style={{ fontWeight: "700", fontSize: "2rem", fontFamily: "Almarai" }}><b>{SingleOrderList?.no_of_boxes}</b></div>
                                    </Col>
                                ) : ""} */}

                                {/* <div className='vertical-line'></div> */}
                                <Col >
                                    <div className='boxes-bx1' style={{ fontFamily: 'Almarai' }}>
                                        <tr>
                                            <td style={{ fontWeight: "700", fontSize: "1rem", fontFamily: "Almarai" }}>Total Weight</td>&nbsp;
                                            <td style={{ fontFamily: "Almarai" }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <td style={{ fontWeight: "700", fontSize: "2rem", fontFamily: "Almarai" }}>{SingleOrderList1?.total_weight?.toFixed(2)}</td>
                                        </tr>
                                    </div>
                                    {/* <div className='boxes-bx1' style={{ fontWeight: "700", fontSize: "2rem", fontFamily: "Almarai" }}><b>{SingleOrderList?.total_weight?.toFixed(2)}</b></div> */}
                                </Col>

                                <hr className='hr-dashed' style={{ marginTop: "1px" }} />

                                <Col>
                                    <table >
                                        <tbody>
                                            {SingleOrderList1?.handling_rate ? (
                                                <tr>
                                                    <td style={{ fontFamily: 'Almarai' }}>Handling Rate / Box</td>&nbsp;
                                                    <td style={{ fontFamily: 'Almarai' }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    <td style={{ textAlign: "right", fontFamily: "Almarai" }}>{SingleOrderList1?.handling_rate?.toFixed(3)}</td>
                                                </tr>
                                            ) : ""}

                                            <tr>
                                                <td style={{ fontFamily: 'Almarai' }}>VAT %</td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <td style={{ fontFamily: "Almarai" }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <td style={{ textAlign: "right", fontFamily: "Almarai" }}>{SingleOrderList1?.vat_percentage?.toFixed(3)}</td>
                                            </tr>
                                        </tbody>

                                    </table>

                                </Col>

                                <hr className='hr-dashed' style={{ marginTop: "1px" }} />

                                <Col>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td style={{ fontFamily: 'Almarai' }}>Total Freight Charge</td>&nbsp;
                                                <td style={{ textAlign: "right", fontFamily: 'Almarai' }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <td style={{ textAlign: "right", fontFamily: "Almarai" }}>{SingleOrderList1?.total_freight_charge ? SingleOrderList1?.total_freight_charge?.toFixed(3) : "0.000"}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontFamily: 'Almarai' }}>Total Packing</td>&nbsp;
                                                <td style={{ textAlign: "right", fontFamily: 'Almarai' }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <td style={{ textAlign: "right", fontFamily: 'Almarai' }}>{SingleOrderList1?.total_packing ? SingleOrderList1?.total_packing?.toFixed(3) : "0.000"}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ fontFamily: 'Almarai' }}>Extra Charges</td>&nbsp;
                                                <td style={{ textAlign: "right", fontFamily: "Almarai" }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <td style={{ textAlign: "right", fontFamily: 'Almarai' }}>{SingleOrderList1?.extra_charges ? SingleOrderList1?.extra_charges?.toFixed(3) : "0.000"}</td>
                                            </tr>
                                            {/* <tr>
                                                <td style={{ fontFamily: 'Almarai' }}>Handling Charges</td>&nbsp;
                                                <td style={{ textAlign: "right", fontFamily: 'Almarai' }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <td style={{ textAlign: "right", fontFamily: 'Almarai' }}>{SingleOrderList?.handling_charge ? SingleOrderList?.handling_charge?.toFixed(3) : "0.000"}</td>
                                            </tr> */}
                                            <tr>
                                                <td style={{ fontFamily: 'Almarai' }}>VAT</td>&nbsp;
                                                <td style={{ textAlign: "right", fontFamily: 'Almarai' }}> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                <td style={{ textAlign: "right", fontFamily: 'Almarai' }}>{SingleOrderList1?.vat_amount?.toFixed(3)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                    <hr className='hr-dashed' style={{ marginTop: "1px" }} />
                    <Row>
                        <Col md="8">
                            <div style={{ fontWeight: "700", fontFamily: 'Almarai' }}>Total Cargo Value(OMR) : {SingleOrderList1?.total_weight?.toFixed(3)}</div>
                        </Col>

                        <Col md="4">
                            <table>
                                <tbody>
                                    <tr>
                                        <b>
                                            <td style={{ fontFamily: 'Almarai' }}>Grand Total</td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <td> : </td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                            <td style={{ textAlign: "right", fontFamily: "Almarai" }}>{SingleOrderList1?.Total?.toFixed(3)}</td>
                                        </b>
                                    </tr>
                                </tbody>
                            </table>
                        </Col>
                    </Row>
                    <hr className='hr-dashed' style={{ marginTop: "1px" }} />

                    {/* <Row style={{ height: "100px" }}>
                        <Col md="5">
                            <div>Expected Delivery: 18/08/2023</div>
                            <div>Cargo Packed By:
                                &nbsp;<input type='checkbox' />&nbsp;Company&nbsp;
                                <input type='checkbox' />&nbsp;Customer
                            </div>
                        </Col>

                        <Col md="4">
                            <div>
                                The provided delivery date is under normal circumstances,
                                and we <br />guarantee to deliver as per the terms & conditions.
                            </div>
                        </Col>

                        <Col md="3">
                            <div>
                                .ﺔــﻳدﺎﻌﻟا فوﺮــﻈﻟا ﻞــﻇ ﻲــﻓ مﺪــﻘﻤﻟا ﻢﻴﻠــﺴﺘﻟا ﺦــﻳرﺎ
                                مﺎﻜﺣﻷاو طوﺮﺸﻠﻟ ﺎًﻘﻓو ﻢﻴﻠﺴﺘﻟا ﻦﻤﻀﻧ ﻦﺤﻧ
                            </div>
                        </Col>
                    </Row> */}

                    <Row >
                        <Col md="12" >
                            <div>
                                <img src={img} alt="" style={{ width: "100%" }} />
                            </div>
                        </Col>
                    </Row>


                    <Row >
                        <Col md="6" className='mt-5' style={{ fontWeight: "700", fontFamily: 'Almarai' }}>
                            Signature of Shipper
                        </Col>
                        <Col md="6" className="content1 mt-5" style={{ fontWeight: "700", fontFamily: 'Almarai' }}>
                            Payment Received By
                        </Col>
                    </Row>


                    {/* <Row>
                        <Col md="12" className="mt-5" >

                            <table id="summeryBoxTable">
                                <tbody>
                                    <tr>
                                        <th>#</th>
                                        <th>Box No.</th>
                                        <th style={{ textAlign: "right" }}>Weight</th>
                                        <th>Packed Items</th>
                                        <th style={{ textAlign: "center" }}>Barcode</th>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        SingleOrderList &&
                                        SingleOrderList?.box_details?.map((el, index) => {
                                            return (
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{el?.box_number}</td>
                                                    <td>{el?.box_weight?.toFixed(3)}</td>
                                                    <td>{el?.packed_items}</td>
                                                    <td><Barcode value={el?.box_number} format="CODE128"
                                                        displayValue={false}
                                                        fontSize={12}
                                                        width={1}
                                                        height={18}
                                                    /></td>
                                                </tr>

                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </Col>





                    </Row> */}


                </div>

            </div>
        </div>
    );
});