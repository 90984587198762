import React, { useRef, useEffect, useState } from 'react';
// import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem, CardTitle, Collapse, Table } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Form } from 'react-bootstrap';
import Select from "react-select"
import "./international.scss";
import axios from "axios";
import accessToken from '../../../../helpers/jwt-token-access/accessToken';
import invoice from "../invoice.svg"
import boxicon from "../box.svg"
import toastr from 'toastr';
import Tooltip from 'react-power-tooltip'
import moment from 'moment';


const Processing = (props) => {
    const formRef = useRef();
    const API_URL = process.env.REACT_APP_APIURL || "https://crmapi.alfarhaonline.com";
    const [arrowHeight, setArraowHeight] = useState("10px");
    const [order, setOrder] = useState([]);
    const [col, setcol] = useState(-1);
    const [showStatus, setshowStatus] = useState(false);
    const [showFields, setshowFields] = useState(false);
    const [showOrders, setShowOrders] = useState(false);
    const [allStatus, setallStatus] = useState(null);
    const [statusUpdateObject, setstatusUpdateObject] = useState({});
    const [searchKey, setsearchKey] = useState('')
    const [allselectedOrders, setAllSelectedOrders] = useState([]);
    const [selectedBoxes, setSelectedBoxes] = useState([]);
    const [orderBoxes, setorderBoxes] = useState([]);
    const [selectedOrder, setselectedOrder] = useState(null);
    const [selectedOrders, setselectedOrders] = useState([]);
    const [showTooltip, setshowTooltip] = useState(false);
    const [selectedBoxesData, setselectedBoxesData] = useState([]);
    const [showExpand, setshowExpand] = useState(false);
    const [main_status] = useState("63da3145f7fb28151cb4eca2");
    const [selectedStatus, setSelectedStatus] = useState(null);

    useEffect(() => {
        getAllStatus()

    }, []);

    function getAllStatus() {
        axios
            .get(`${API_URL}process/all-sub-status?main_status=` + main_status, {
                headers: {
                    "x-access-token": accessToken
                }
            })
            .then((result) => {
                let data = result.data.data

                data = data.map(el => { return { label: el.sub_name, value: el._id } })

                setallStatus(data);

            })
            .catch((err) => console.log(err.message))

    }


    function handleoptions(selected) {
        if (selected) {
            setSelectedStatus(selected);
            // setshowFields(true);
            setstatusUpdateObject({ ...statusUpdateObject, sub_status: selected.value });
        }
        else setshowStatus(false)
    }


    function handleSelectAllBox() {

        var list = [...orderBoxes];
        var selectedList = [...selectedBoxes];
        var allselecteOrders = [...allselectedOrders];
        var choosed_orders = [...selectedOrders];
        var boxesData = [...selectedBoxesData];
        if (!selectedOrder?.isAllSelected) {
            allselecteOrders.push(selectedOrder?.id.toString());
            choosed_orders.push(selectedOrder?.id.toString());
            list.map((item) => {
                item.isSelected = true;
                selectedList.push(item?._id.toString());
                boxesData.push({ order_id: selectedOrder?.id.toString(), order_index: selectedOrder?.order_index, box_id: item?._id.toString(), box_number: item?.box_number });
            })
            setAllSelectedOrders(allselecteOrders);
            setorderBoxes(list);
            setselectedOrder({
                ...selectedOrder,
                'isAllSelected': true,
                'box_count': list.length,
            })
            setSelectedBoxes(selectedList);
            setselectedOrders(choosed_orders);
        }
        else {
            var o_pos = allselecteOrders.indexOf(selectedOrder?.id.toString());
            allselecteOrders.splice(o_pos, 1);
            setAllSelectedOrders(allselecteOrders);

            var o_pos1 = choosed_orders.indexOf(selectedOrder?.id.toString());
            choosed_orders.splice(o_pos1, 1);
            setselectedOrders(choosed_orders);
            list.map((item) => {
                item.isSelected = false;
                var pos = selectedList.indexOf(item?._id.toString());
                selectedList.splice(pos, 1);
            })
            setorderBoxes(list);
            setselectedOrder({
                ...selectedOrder,
                'isAllSelected': false,
                'box_count': 0
            })
            setSelectedBoxes(selectedList);
            boxesData = boxesData.filter(obj => obj.order_id !== selectedOrder?.id.toString());

        }
        setselectedBoxesData(boxesData);

    }


    function getOrders(e) {
        let orderNumber = e.target.value;
        setsearchKey(orderNumber)
        setshowFields(false);
        setshowStatus(false);
        axios
            .get(`${API_URL}process/order-international?order_id=${orderNumber}`, {
                headers: {
                    "x-access-token": accessToken
                }
            })
            .then(result => {
                let data = result.data.data;
                setOrder(data);
                if (data.length >= 1) {
                    setselectedOrder({ id: data[0]._id, isAllSelected: false, box_count: 0, order_index: 0 });
                    setorderBoxes(data[0].boxes)
                }
            })
            .catch(err => {
                console.log(err);
            });
    }

    function selectOrder(e, order, index) {
        const clickedElement = e.currentTarget;
        const rect = clickedElement.getBoundingClientRect();
        var marginTop = rect.top + window.pageYOffset;
        marginTop = marginTop - 186;
        setArraowHeight(marginTop + "px");
        var selectedOrders = [...allselectedOrders];
        var pos = selectedOrders.indexOf(order._id?.toString());
        const selectedCount = order?.boxes.reduce((count, item) => {
            if (item.isSelected) {
                return count + 1;
            }
            return count;
        }, 0);
        if (pos > -1) {
            setselectedOrder({ id: order._id, isAllSelected: selectedCount == order?.boxes.length ? true : false, box_count: selectedCount, order_index: index });
        }
        else {
            setselectedOrder({ id: order._id, isAllSelected: selectedCount == order?.boxes.length ? true : false, box_count: selectedCount, order_index: index });
        }

        setorderBoxes(order?.boxes);


    }

    function callStatusUpdateApi() {
        let bodyData = statusUpdateObject;
        if (selectedBoxes.length == 0) {
            toastr.warning("Please choose atleast 1 box");
            return
        }
        if (!bodyData?.sub_status) {
            toastr.warning("Please choose process status");
            return;
        }
        bodyData.status = main_status;
        bodyData.boxes = selectedBoxes;
        bodyData.date = moment().format("YYYY-MM-DD");
        bodyData.status_type = 1;
        axios
            .post(`${API_URL}process/bulk`, bodyData, {
                headers: { "x-access-token": accessToken }
            })
            .then((response) => {
                toastr.success('Status updated successfully')
                setshowStatus(false);
                setshowFields(false);
                setShowOrders(false);
                setstatusUpdateObject({});
                setOrder([]);
                setselectedOrder(null);
                setsearchKey('');

                setAllSelectedOrders([]);
                setSelectedBoxes([]);
                setselectedBoxesData([]);
                setorderBoxes([]);
                setselectedOrders([]);
                setSelectedStatus(null);
                formRef.current.reset();
                setstatusUpdateObject({ boxes: [], status: null, sub_status: null, location: "", remarks: "", date: null, status_type: null });

            })
            .catch((err) => console.log(err));
    }

    function updateStatusObject(e) {
        let value = e.target.value;
        let name = e.target.name;
        setstatusUpdateObject({ ...statusUpdateObject, [name]: value });
    }
    function handleBoxClick(box, index) {
        var box_id = box._id.toString();
        var list = [...selectedBoxes];
        var orderBox = [...orderBoxes];
        var pos = list.indexOf(box_id);
        var boxesData = [...selectedBoxesData];
        if (pos > -1) {
            boxesData = boxesData.filter(obj => obj.box_id !== box_id.toString());
            list.splice(pos, 1);
            orderBox[index].isSelected = false;

            var selected_Orders = [...allselectedOrders];
            var pos = selected_Orders.indexOf(selectedOrder.id?.toString());
            if (pos > -1) {
                selected_Orders.splice(pos, 1);
                setAllSelectedOrders(selected_Orders);
            }

            const selectedCount = orderBox.reduce((count, item) => {
                if (item.isSelected) {
                    return count + 1;
                }
                return count;
            }, 0);
            setselectedOrder({
                ...selectedOrder,
                'isAllSelected': false,
                'box_count': selectedCount
            });
            if (selectedCount == 0) {
                var choosed_orders = [...selectedOrders];
                var pos1 = choosed_orders.indexOf(selectedOrder.id?.toString());
                if (pos1 > -1) {
                    choosed_orders.splice(pos1, 1);
                    setselectedOrders(choosed_orders);
                }
            }
        }
        else {
            list.push(box_id);
            orderBox[index].isSelected = true;
            boxesData.push({ order_id: selectedOrder?.id.toString(), order_index: selectedOrder?.order_index, box_id: box_id, box_number: box?.box_number });
            const selectedCount = orderBox.reduce((count, item) => {
                if (item.isSelected) {
                    return count + 1;
                }
                return count;
            }, 0);
            if (selectedCount == orderBox.length) {
                setselectedOrder({
                    ...selectedOrder,
                    'isAllSelected': true,
                    'box_count': selectedCount
                });
                var selecteddOrders = [...allselectedOrders];
                selecteddOrders.push(selectedOrder.id.toString());
                setAllSelectedOrders(selecteddOrders);
            }
            else {
                setselectedOrder({
                    ...selectedOrder,
                    'isAllSelected': false,
                    'box_count': selectedCount
                });
            }
            var choosed_orders = [...selectedOrders];
            var pos1 = choosed_orders.indexOf(selectedOrder.id?.toString());
            if (pos1 == -1) {
                choosed_orders.push(selectedOrder.id?.toString());
                setselectedOrders(choosed_orders);
            }
        }
        setSelectedBoxes(list);
        setorderBoxes(orderBox);
        setselectedBoxesData(boxesData);
    }


    function handleRemoveBox(box) {
        var box_id = box.box_id;
        var list = [...selectedBoxes];
        var pos = list.indexOf(box_id);
        list.splice(pos, 1);
        setSelectedBoxes(list);

        var boxesData = [...selectedBoxesData];
        boxesData = boxesData.filter(obj => obj.box_id !== box_id.toString());
        setselectedBoxesData(boxesData);

        var order_index = box?.order_index;
        var orders = [...order];
        var current_order = orders[order_index];
        var boxes = current_order?.boxes;
        const box_index = boxes
            .map((obj, index) => obj._id.toString() === box_id.toString() ? index : -1)
            .filter(index => index !== -1)[0];
        orders[order_index].boxes[box_index].isSelected = false;
        setOrder(orders);

        var selectedCount = orders[order_index].boxes.reduce((count, item) => {
            if (item.isSelected) {
                return count + 1;
            }
            return count;
        }, 0);
        if (selectedCount == 0) {
            var choosed_orders = [...selectedOrders];
            var pos1 = choosed_orders.indexOf(box?.order_id?.toString());
            if (pos1 > -1) {
                choosed_orders.splice(pos1, 1);
                setselectedOrders(choosed_orders);
            }
        }

        if (selectedOrder?.order_index == box?.order_index) {
            var orderBox = [...orderBoxes];
            const box_index = orderBox
                .map((obj, index) => obj._id.toString() === box_id.toString() ? index : -1)
                .filter(index => index !== -1)[0];
            orderBox[box_index].isSelected = false;
            var selectedCount = orderBox.reduce((count, item) => {
                if (item.isSelected) {
                    return count + 1;
                }
                return count;
            }, 0);
            setselectedOrder({
                ...selectedOrder,
                'isAllSelected': false,
                'box_count': selectedCount
            });
            if (selectedCount == 0) {
                var selected_Orders = [...allselectedOrders];
                var pos = selected_Orders.indexOf(box?.order_id?.toString());
                if (pos > -1) {
                    selected_Orders.splice(pos, 1);
                    setAllSelectedOrders(selected_Orders);
                }

                var choosed_orders = [...selectedOrders];
                var pos1 = choosed_orders.indexOf(box?.order_id?.toString());
                if (pos1 > -1) {
                    choosed_orders.splice(pos1, 1);
                    setselectedOrders(choosed_orders);
                }


            }

        }


        // var selectedCount = orderBox.reduce((count, item) => {
        //     if (item.isSelected) {
        //         return count + 1;
        //     }
        //     return count;
        // }, 0);
        // setselectedOrder({
        //     ...selectedOrder,
        //     'isAllSelected': false,
        //     'box_count': selectedCount
        // });
        // if (selectedCount == 0) {
        //     var choosed_orders = [...selectedOrders];
        //     var pos1 = choosed_orders.indexOf(selectedOrder.id?.toString());
        //     if (pos1 > -1) {
        //         choosed_orders.splice(pos1, 1);
        //         setselectedOrders(choosed_orders);
        //     }
        // }
        // setorderBoxes(orderBox);
    }

    return (
        <React.Fragment>
            <div className='page-content'>
                <div className='container-fluid'>
                    <h1 className='trackingh1'>Process International</h1>
                    <Row>
                        <Col md="8">


                            <Card className='trackingcard'>

                                <CardBody className='trackingcard-cardbody' >
                                    <Form className="app-search d-none d-lg-block " onChange={(e) => {
                                        setsearchKey(e.target.value)

                                        setselectedOrder(null);
                                        setorderBoxes(null);
                                        setShowOrders(null);
                                        setAllSelectedOrders([]);
                                        setSelectedBoxes([]);
                                        setselectedBoxesData([]);
                                        setorderBoxes([]);
                                        setselectedOrders([]);
                                        getOrders(e);

                                    }} style={{ width: "100%" }}>
                                        <div className="position-relative">
                                            <input
                                                type="text"
                                                value={searchKey}
                                                className="form-control font-size-14"
                                                placeholder=' Search/Scan- OrderID , MobileNumber , QRcode'
                                            />

                                            <span className="uil-search">
                                                <i class="fa fa-qrcode mx-1"></i></span>

                                        </div>
                                    </Form>
                                </CardBody>
                            </Card>

                            <Row>
                                {/* <p className='srcresult'>Showing Results  </p> */}


                                <Col className='mt-1 ps-1 pe-1' dir="rtl" md="6">
                                    <Card className='maindetailcard' dir="rtl">
                                        {
                                            order && order?.map((el, index) => {
                                                console.log(el, "el");
                                                var selectedCount = el?.boxes.reduce((count, item) => {
                                                    if (item.isSelected) {
                                                        return count + 1;
                                                    }
                                                    return count;
                                                }, 0);
                                                return (

                                                    <Card className='resultcardtrack'>
                                                        <CardBody className='resultcardbodytrack'
                                                            onClick={(e) => {
                                                                selectOrder(e, el, index);
                                                                setShowOrders(true);
                                                            }}
                                                            style={{
                                                                background: selectedOrder?.id?.toString() == el._id.toString() ? "#ffe0cf" : (selectedOrders?.indexOf(el._id.toString()) > -1 ? "#ffe0cf" : "white"),
                                                                boxShadow: selectedOrder?.id?.toString() == el._id.toString() ? "rgb(169 166 165) 0px 3px 8px, rgb(90 90 90 / 38%) 0px 5px 5px" : ""
                                                            }} >

                                                            <div className='resultmain' dir="ltr">
                                                                <div style={{ display: "flex" }}>
                                                                    <img className='resulticon' src={invoice} alt="image" />
                                                                    <p className='detailsp mx-3 mt-2 mb-1'>Order-No: {el.order_number}
                                                                        <br /><span style={{ fontSize: ".9rem", fontWeight: "600" }}>Destination <span style={{ color: "red" }}>{el?.destination}</span></span>
                                                                        <br /><span style={{ fontSize: ".9rem", fontWeight: "600" }}>Selected Boxes <span style={{ color: "red" }}>{selectedCount}</span></span>
                                                                    </p>
                                                                </div>
                                                                <div>
                                                                    <h5 className='resultweight'>{el.total_weight}KG </h5>
                                                                </div>


                                                            </div>
                                                            <Collapse isOpen={col === el._id ? true : false}>
                                                                {
                                                                    el.boxes?.map((box, index1) => {
                                                                        return (
                                                                            <>
                                                                                <Row className='mt-2'>
                                                                                    <hr class="new2" />
                                                                                </Row>
                                                                                <div className='resultmain ps-5' style={{ position: "relative" }}>
                                                                                    <div style={{ display: "flex" }}>
                                                                                        <img className='resulticon' src={boxicon} alt="image" />
                                                                                        <p className='detailsp-box mx-3 mt-2'>Box-No: {box?.box_number}<br /><span style={{ fontSize: ".9rem", fontWeight: "600" }}>Status <span style={{ color: "red" }}>{box?.shipment_status?.ship_status_name}</span></span></p>
                                                                                    </div>
                                                                                    <div>
                                                                                        <h5 className='resultweight-box'>{box?.box_weight}KG</h5>
                                                                                    </div>

                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }


                                                            </Collapse>

                                                        </CardBody>
                                                        {/* <h5 className='arrowbutton'>

                                                            <i class="fa fa-angle-down  mx-3 me-0" onClick={() => {
                                                                setcol(el._id)
                                                                if (col === el._id) {
                                                                    setcol(-1)
                                                                }
                                                            }}></i></h5> */}
                                                    </Card>
                                                )
                                            })
                                        }

                                    </Card>
                                </Col>
                                <Col className='mt-1 ps-1 pe-1 box-div tooltips' md="6" style={{ display: selectedOrder ? 'block' : 'none', '--tooltips-top': arrowHeight }}>
                                    <Row>
                                        <Col md="6" className='a-box-all ps-1 text-center' >
                                            <label><span class="badge rounded-pill" style={{ background: "#eb6517" }}>{selectedOrder?.box_count}</span> Box Selected</label>
                                        </Col>
                                        <Col md="6" className='a-box-all pe-1'>
                                            <a
                                                onClick={() => {
                                                    handleSelectAllBox();
                                                }}
                                            >{selectedOrder?.isAllSelected ? "Unselect All" : "Select All"}</a>
                                        </Col>
                                    </Row>
                                    <Card className='maindetailcard'>

                                        {
                                            orderBoxes?.map((box, index1) => {
                                                return (
                                                    <Card className='resultcardtrack'>
                                                        <CardBody className='resultcardbodytrack'
                                                            style={{ background: (box?.isSelected) ? "#ffe0cf" : "white" }}
                                                            onClick={() => {
                                                                handleBoxClick(box, index1)
                                                            }}
                                                        >
                                                            <div className='resultmain'>
                                                                <div style={{ display: "flex" }}>
                                                                    <img className='resulticon' src={boxicon} alt="image" />
                                                                    <p className='detailsp mx-3 mt-2'>Box-No: {box?.box_number}<br /><span style={{ fontSize: ".9rem", fontWeight: "600" }}>Status <span style={{ color: "red" }}>{box?.shipment_status?.ship_status_name}</span></span></p>
                                                                </div>
                                                                <div>
                                                                    <h5 className='resultweight'>{box?.box_weight}KG </h5>
                                                                </div>


                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                )
                                            })}

                                    </Card>
                                </Col>

                            </Row>

                            {/* <AvForm onValidSubmit={(e) => { callStatusUpdateApi() }}>
                                <Row className='m-0 '>
                                    {
                                        showStatus ? (
                                            <Col md="6" className='fields'>

                                                <Select
                                                    name='status'
                                                    placeholder='Status'
                                                    onChange={(selected) => {
                                                        handleoptions(selected)
                                                    }}
                                                    options={allStatus}
                                                // classNamePrefix="select2-selection"
                                                />

                                            </Col>
                                        ) : ""
                                    }

                                    {
                                        showFields &&
                                        <>
                                            <Col md="6" className='fields'>
                                                <AvField
                                                    name="location"
                                                    placeholder="Location"
                                                    onChange={(e) => { updateStatusObject(e) }}
                                                    type="text"
                                                />
                                            </Col>

                                            <Col md="6" className='fields'>
                                                <AvField
                                                    name="date"
                                                    onChange={(e) => { updateStatusObject(e) }}
                                                    placeholder="Date"
                                                    type="date"
                                                />
                                            </Col>


                                            <Col md="6" className='fields'>
                                                <AvField
                                                    name="remarks"
                                                    placeholder="Remark"
                                                    type="textarea"
                                                    onChange={(e) => { updateStatusObject(e) }}
                                                />
                                            </Col>

                                            <Col md="4" className='fields'>
                                                <Button color='primary' type='submit' style={{}}>Submit</Button>

                                            </Col>
                                        </>
                                    }

                                </Row>
                            </AvForm> */}

                        </Col>

                        {showOrders ? (
                            <Col md="4">
                                <AvForm onValidSubmit={(e) => { callStatusUpdateApi() }} ref={formRef}>

                                    <Card className='trackingcard'>
                                        <CardBody className='trackingcard-cardbody' >
                                            <Row>
                                                <Col md="6" xs="6" sm="6">
                                                    <div class="header-items noti-icon waves-effect" style={{ height: "55px", padding: "10px" }}>
                                                        <i class="fas fa-box-open" style={{ paddingTop: "12px" }}></i>
                                                        <span class="badge rounded-pill" style={{ background: "rgb(235, 101, 23)", top: "6px", right: "0px" }}>{(selectedBoxes?.length) ? selectedBoxes?.length : 0}</span>
                                                    </div>

                                                </Col>
                                                <Col md="6" xs="6" sm="6" className='text-right'>
                                                    <div className='process-selected-box-arraow' onClick={() => {
                                                        setshowExpand(!showExpand);
                                                    }}><i class={showExpand ? "fa fa-angle-up  mx-3 me-0" : "fa fa-angle-down  mx-3 me-0"}></i></div>
                                                </Col>
                                                <Col className={showExpand ? "box-list-fade box-list-show" : "box-list-fade box-list-hide"} md="12" sm="12" xs="12">

                                                    <hr class="new2" style={{ marginTop: "0px" }} />

                                                    <Row className="box-list-row">
                                                        {
                                                            selectedBoxesData?.map((item) => {
                                                                return (
                                                                    <>
                                                                        <Col md="6" className='box-list-card'>

                                                                            <i class="mdi mdi-close-thick remove-box"
                                                                                onClick={() => {
                                                                                    handleRemoveBox(item);
                                                                                }}
                                                                            ></i> {item.box_number}
                                                                        </Col>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </Row>

                                                </Col>

                                            </Row>

                                        </CardBody>
                                    </Card>

                                    <Col md="12" xs="12" sm="12">
                                        {/* <AvForm onValidSubmit={(e) => { callStatusUpdateApi() }} ref={formRef}> */}
                                        <Row className='m-0 '>

                                            <Col md="6" className='fields'>

                                                <Select
                                                    name='sub_status'
                                                    placeholder='Status'
                                                    // menuPosition='fixed'
                                                    onChange={(selected) => {
                                                        handleoptions(selected)
                                                    }}
                                                    value={selectedStatus}
                                                    options={allStatus}
                                                // classNamePrefix="select2-selection"
                                                />

                                            </Col>



                                            <>
                                                <Col md="6" className='fields'>
                                                    <AvField
                                                        name="location"
                                                        placeholder="Location"
                                                        onChange={(e) => { updateStatusObject(e) }}
                                                        type="text"
                                                        value={statusUpdateObject?.location}
                                                        validate={{ required: { value: true, errorMessage: "Location required" } }}
                                                    />
                                                </Col>

                                                {/* <Col md="6" className='fields'>
                                        <AvField
                                            name="date"
                                            onChange={(e) => { updateStatusObject(e) }}
                                            placeholder="Date"
                                            type="date"
                                        />
                                    </Col> */}


                                                <Col md="12" className='fields'>
                                                    <AvField
                                                        name="remarks"
                                                        placeholder="Remark"
                                                        type="textarea"
                                                        value={statusUpdateObject?.remarks}
                                                        validate={{ required: { value: true, errorMessage: "Remarks required" } }}
                                                        onChange={(e) => { updateStatusObject(e) }}
                                                    />
                                                </Col>

                                                <Col md="4" className='fields'>
                                                    <Button color='primary' type='submit' style={{}}>Submit</Button>

                                                </Col>
                                            </>

                                        </Row>
                                    </Col>
                                </AvForm>
                            </Col>

                        ) : null}

                    </Row>

                </div>
            </div>
        </React.Fragment >
    )
}

export default Processing