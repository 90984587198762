// import React, { useState } from 'react';
// import { Link } from 'react-router-dom';

// const SubMenu = ({ item }) => {
//   const [subnav, setSubnav] = useState(false);

//   const showSubnav = () => setSubnav(!subnav);

//   return (
//     <ul className="metismenu list-unstyled " id="side-menu">
//       <li>
//         <Link to={item.path} onClick={item.subNav && showSubnav}>
//           <div className={`wave-effect ${item.subNav ? 'has-arrow' : null}`}>
//             <i className={item.icon}></i>
//             <span>{item.title}</span>
//           </div>
//         </Link>
//         {subnav &&
//           item.subNav.map((item, index) => {
//             return (
//               <ul className="sub-menu">
//                 <li>
//                   <Link to={item.path} key={index} className="waves-effect">
//                     <i className={item.icon}></i>
//                     <span className="sub-menu">{item.title}</span>
//                   </Link>
//                 </li>
//               </ul>
//             );
//           })}
//       </li>
//     </ul>
//   );
// };

// export default SubMenu;

import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";

import $ from "jquery";
const SubMenu = ({ item }) => {

  const [subnav, setSubnav] = useState(false);
  const [subsubnav, setsubsubnav] = useState(false);
  const [navstate, setnavstate] = useState([]);
  const [state, setState] = useState("");
  const [sub, setsub] = useState([]);
  const [background, setbackground] = useState(-1);

  const showSubnav = () => setSubnav(!subnav);
  const showsubsubnav = () => setsubsubnav(!subsubnav);

  const hideAll = () => {
    $(".sub-menu").hide();
    setSubnav(false);
  };
  function submenu(item,index) {
    if (item.submenu.length > 0) {
      showSubnav()
    }
  }


  function sub_submenu(item,index) {
    if (item.inn_sub_menu.length > 0) {
      showsubsubnav()
    }
    item.inn_sub_menu.map((el, index) => {
      setsub(el)
    })

  }
 

  return (

    <li>
      <NavLink
        to={item.main_link !== null ? item.main_link : null}
        ClassName={`sidebar-menu${navstate}` ? {
          backgroundColor: "#000000",
          borderRadius: "12px",
          color: "$sidebar-menu-item-hover-color "
        } :""}

        onClick={() => {
          submenu(item);
          
          
        }}
      >
        <div
          className={`wave-effect ${item.submenu.length > 0 ? "has-arrow" : null
            }`}
          onClick={hideAll}
        >
          <i
            id="icons"
            className={item.main_icon}></i>
          <span style={{fontSize:"1rem",fontWeight:"500"}} className="mx-2">{item.main_menuname}</span>
        </div>
      </NavLink>
      <ul style={{paddingLeft:"1.3rem"}}>
        {subnav &&
          item.submenu.map((item, index) => {

            return (
              <li className="sub-menu">

                <Link onClick={() => {
                  sub_submenu(item)
                }}
                  to={item.sub_link} key={index} className="waves-effect">
                  <div
                    className={`wave-effect ${item.inn_sub_menu.length > 0 ? "has-arrow" : null
                      }`}
                  ></div>
                  <i
                    className={item.sub_icon}></i>
                  <span
                    className="sub-menu mx-2" >{item.sub_name}</span>
                </Link>
                    <ul>
                        {
                          subsubnav &&
                          item.inn_sub_menu.map((item,index)=>{
                            return(
                              <li className="sub-menu">
                                <Link
                                to={item.inn_sub_link} key={index} className="wave-effect"
                                >
                                <i className={item.inn_sub_icon}></i>
                                 <span className="sub-menu">{item.inn_sub_name}</span>
                                </Link>
                               
                              </li>
                            )
                          })
                        }
                    </ul>
              </li>

            );
          })}

      </ul>
    </li>
    // </ul> 
  );
};

export default SubMenu;
