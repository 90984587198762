import {
    GET_STAFF_SALARY,
    GET_STAFF_SALARY_SUCCESS,
    GET_STAFF_SALARY_FAIL,
} from "./actionTypes";

const INIT_STATE = {
    staffsalaries: [],
    error: {},
};

const staffSalaries = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_STAFF_SALARY:
            return {
                ...state,
                params: action.payload,
            };

        case GET_STAFF_SALARY_SUCCESS:
            return {
                ...state,
                staffsalaries: action.payload.data,
            };

        case GET_STAFF_SALARY_FAIL:
            return {
                ...state,
                error: action.payload,
            };
       default:
            return state;
    }
};

export default staffSalaries;