/* LOCALBODIES */
export const GET_LOCALBODIES = "GET_LOCALBODIES";
export const GET_LOCALBODIES_SUCCESS = "GET_LOCALBODIES_SUCCESS";
export const GET_LOCALBODIES_FAIL = "GET_LOCALBODIES_FAIL";

export const GET_LOCALBODY = "GET_LOCALBODY";
export const GET_LOCALBODY_SUCCESS = "GET_LOCALBODY_SUCCESS";
export const GET_LOCALBODY_FAIL = "GET_LOCALBODY_FAIL";

export const ADD_LOCALBODY = "ADD_LOCALBODY";
export const ADD_LOCALBODY_SUCCESS = "ADD_LOCALBODY_SUCCESS";
export const ADD_LOCALBODY_FAIL = "ADD_LOCALBODY_FAIL";

export const UPDATE_LOCALBODY = "UPDATE_LOCALBODY";
export const UPDATE_LOCALBODY_SUCCESS = "UPDATE_LOCALBODY_SUCCESS";
export const UPDATE_LOCALBODY_FAIL = "UPDATE_LOCALBODY_FAIL";

export const DELETE_LOCALBODY = "DELETE_LOCALBODY";
export const DELETE_LOCALBODY_SUCCESS = "DELETE_LOCALBODY_SUCCESS";
export const DELETE_LOCALBODY_FAIL = "DELETE_LOCALBODY_FAIL";

export const GET_LOCALBODY_TYPES = "GET_LOCALBODY_TYPES";
export const GET_LOCALBODY_TYPES_SUCCESS = "GET_LOCALBODY_TYPES_SUCCESS";
export const GET_LOCALBODY_TYPES_FAIL = "GET_LOCALBODY_TYPES_FAIL";

export const GET_LOCALBODY_OPTIONS = "GET_LOCALBODY_OPTIONS";
export const GET_LOCALBODY_OPTIONS_SUCCESS = "GET_LOCALBODY_OPTIONS_SUCCESS";
export const GET_LOCALBODY_OPTIONS_FAIL = "GET_LOCALBODY_OPTIONS_FAIL";



export const GET_TYPES_LOCALBODY_OPTIONS = "GET_TYPES_LOCALBODY_OPTIONS";
export const GET_TYPES_LOCALBODY_OPTIONS_SUCCESS = "GET_TYPES_LOCALBODY_OPTIONS_SUCCESS";
export const GET_TYPES_LOCALBODY_OPTIONS_FAIL = "GET_TYPES_LOCALBODY_OPTIONS_FAIL";
