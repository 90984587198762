import React, { useState, useEffect, useRef } from "react";
import Select from 'react-select';
import { useDispatch, useSelector } from "react-redux";

import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Col, Label, Button, Card, CardBody, Row, ToastHeader } from 'reactstrap';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import "./Complaintstatus.scss"



function Complaintstatus() {
  const [complaintObject, setcomplaintObject] = useState({});
  const [selectedItem, setselectedItem] = useState(null);
  const [complaintForTable, setcomplaintForTable] = useState([]);
  const [complaintToBeDeleted, setcomplaintIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [complaintIdToBeUpdated, setcomplaintIdToBeUpdated] = useState(null);
  const [customerValue, setCustomerValue] = useState({});
  const [staffValue, setStaffValue] = useState({});
  const [bothValue, setBothValue] = useState({});


  const [errors, seterrors] = useState({
    StatusError: "",
    // stateError:"",
  });

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();


  const reset = () => {
    formRef.current.reset();
    setselectedItem(null);
    setcomplaintIdToBeUpdated(null);
    setcomplaintObject({});
  }
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    // fetch_modules();
    // fetch_branch();
    //  handleSelectChange();
    handleTableData();
  }, []);

  const {
    districts,
    addingcomplaint,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);
  function handleChangeInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    // setfaqValue(value);
    setcomplaintObject({ ...complaintObject, [name]: value });
  }

  let preUpdatecomplaint = (item) => {
    setcomplaintIdToBeUpdated(item._id);
    setcomplaintObject(item);
    // setselectedItem(item)
    //  formRef.current.reset();

    axios
      .get(`${API_URL}complaintStatus/single?status_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {


        let result = res.data.data;

        let valuess = {};
        if (result?.status_for === 0) {
          valuess = {
            label: "Customer",
            value: 0,
          };

        } else if (result?.status_for === 1) {

          valuess = {
            label: "Staff",
            values: 1,
          }
        } else if (result?.status_for === 2) {
          valuess = {
            label: "Both",
            value: 2
          }
        }
        setselectedItem(valuess);



        setcomplaintObject(result);

      })

    // setcomplaintIdToBeUpdated(item._id);
    // setcomplaintObject(item);

  }



  function handleSelectChange(selected) {
    // switch (name) {

    //   case "status_for":
    //     setselectedItem(selected);
    //     setcomplaintObject({
    //       ...complaintObject,
    //       ["status_for"]: selected.value
    //     });
    //     break;

    setcomplaintObject({ ...complaintObject, ["status_for"]: selected.value });
    setselectedItem({ label: selected.label, value: selected.value });
    seterrors({ ...errors, StatusError: "", })
  }

  const handleValidSubmit = (e, v) => {
    if (selectedItem === null) {
      let errorVal = errors;
      if (selectedItem === null)
        errorVal.StatusError = "Please Select Status For"
      seterrors({ ...errorVal })
    }

    else {

      if (complaintIdToBeUpdated) {
        axios
          .put(`${API_URL}complaintStatus/update`, complaintObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Complaint Status updated successfully");
              handleTableData();
              setselectedItem(null);
              seterrors({ StatusError: "" })
              // setselectedCategory();
              setcomplaintObject({});
              // setcomplaintObject(result[0])
              formRef.current.reset();
              setcomplaintIdToBeUpdated(null);
              seterrors({ ...errors, StatusError: "", })

            } else toastr.error("Failed to update Complaint Status");
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}complaintStatus/`, complaintObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Complaint Status added successfully");
              setselectedItem(null);
              seterrors({ StatusError: "" })
              handleTableData();
              // setShowCompany(false);
              // setfaqCategoryValue("")
              // setselectedCategory(null);
              setcomplaintObject({});
              formRef.current.reset();

            } else toastr.error("Failed to add Complaint Status");
          })
          .catch((err) => {
            console.log(err)
            toastr.error(err.response.data.message);
            return;
          });
      }
    };
  };

  function handleTableData() {
    var url = `${API_URL}complaintStatus/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let complaintData = [];

        result.map((item, index) => {

          //  let values = {};
          if (item.status_for === 0) {
            item.status_for = "Customer"
          } else if (item.status_for === 1) {
            item.status_for = "Staff"
          } else if (item.status_for === 2) {
            item.status_for = "Both"
          }

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdatecomplaint(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setcomplaintIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          // item.category1=item?.category?.category_name;

          if (item?.updatedBy) item.staff = item.updatedBy.firstName;
          else item.staff = item?.addedby?.firstName;
          complaintData.push(item);

        });

        setcomplaintForTable(complaintData);

      });

  }



  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        // sort: "asc",
        width: 150,
      },
      {
        label: "Status Name",
        field: "status_name",
        sort: "desc",
        width: 270,
      },
      {
        label: "Status For",
        field: "status_for",
        sort: "desc",
        width: 200,
      },

      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: complaintForTable,
  };
  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}complaintStatus` + "/" + complaintToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Complaint Status deleted successfully");

                  if (complaintObject && complaintObject?._id === complaintToBeDeleted) {
                    formRef.current.reset();
                    setcomplaintIdToBeUpdated(null);
                    setselectedItem(null);
                  }
                  handleTableData();
                } else {
                  toastr.error("Failed to delete Complaint Status");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}
      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumb title="Home" breadcrumbItem="Complaint Status" />
          <Row>
            <Col xl="12">
              {/* <Card>
                <CardBody> */}
              <AvForm
                className="needs-validation"
                ref={formRef}
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <Row>
                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Status Name</Label>
                      <AvField
                        name="status_name"
                        placeholder="Status Name"
                        type="text"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        errorMessage="Enter Status Name"
                        onChange={handleChangeInput}
                        value={complaintObject?.status_name}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3" >
                      <Label>Status For</Label>
                      <Select
                        name='status_for'
                        placeholder='Status For'
                        value={selectedItem}
                        validate={{ required: { value: true } }}
                        onChange={(selected) => {
                          handleSelectChange(selected)
                        }}
                        options={[
                          { label: "Customer", value: 0 },
                          { label: "Staff", value: 1 },
                          { label: "Both", value: 2 },
                        ]}
                        classNamePrefix="select2-selection"
                      />
                      <p className='text-danger'
                        style={{ fontSize: "11px" }}>
                        {selectedItem === null ? errors.StatusError : ""}
                      </p>
                    </div>
                  </Col>
                  <Col md="3" style={{ paddingTop: "4px" }}>
                    <div className='mt-4'>
                      {complaintIdToBeUpdated ? (
                        <Button className='mx-2'
                          color='primary'
                          type='submit'
                          // style={{ marginRight: "5%" }}
                          disabled={addingcomplaint ? true : false}
                        >
                          {addingcomplaint ? "Updating" : "Update"}
                        </Button>
                      )
                        :
                        <Button className='mx-2'
                          color='primary'
                          type='submit'
                          disabled={addingcomplaint ? true : false}
                        >
                          {addingcomplaint ? "Adding" : "Submit"}
                        </Button>
                      }
                      <Button
                        color='danger'
                        type='reset'
                        onClick={() => reset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
          <Row>
            <Col className='col-12'>
              {/* <Card>
                <CardBody> */}
              <MDBDataTable
                responsive
                id="AddComplaintstatustable"
                bordered
                data={data}
                searching={true}
                info={true}
                disableRetreatAfterSorting={true}
                entries={20} />
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
        </div>
      </div>

    </React.Fragment>

  )
}


export default Complaintstatus