import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from 'react-select';

import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Col, Label, Button, Card, CardBody, Row, ToastHeader } from 'reactstrap';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { MDBDataTable } from "mdbreact";
import "./Faq.scss"
import faq from "../../../assets/images/faqimages/img1.png";
import faqimages from "../../../assets/images/faqimages/img2.png";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";



const Faq = () => {
  const [faqObject, setfaqObject] = useState({});

  const [category, setcategory] = useState([]);//options fetching 
  const [selectedCategory, setselectedCategory] = useState(null);//selecting option
  const [uploadProgress, setUploadProgress] = useState();
  const [faqForTable, setfaqForTable] = useState([]);
  const [faqIdToBeDeleted, setfaqIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [faqIdToBeUpdated, setfaqIdToBeUpdated] = useState(null);

  const [errors, setErrors] = useState({
    categoryName: "",
    // stateError:"",
  });


  const reset = () => {
    formRef.current.reset();
    setfaqObject((prevfaqObject) => ({
      ...prevfaqObject,
      answer: "",// Clear the description field by setting it to an empty string
      image: ""
    }));
    setfaqIdToBeUpdated(null);
    setselectedCategory(null);
    setErrors({
      categoryName: "",
    })
  }


  const {
    districts,
    addingfaq,
    // addDistrictResponse,
    // deleteDistrictResponse,
    // updateDistrictResponse,
    error,
  } = useSelector((state) => state.districts);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();


  useEffect(() => {
    handleTableData();
    fetch_all_category();

  }, []);


  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };


  function handleTableData() {
    var url = `${API_URL}faq/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let faqData = [];

        result.map((item, index) => {

          if (typeof item.image != "undefined" && item.image != "") {
            item.image = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={`${API_URL}uploads/faq_image/${item.image}`}
                  style={{ height: "25px", cursor: "pointer" }}
                  onClick={() => window.open(`${API_URL}uploads/faq_image/${item.image}`, "_blank")}
                />
              </div>
            );
          }
          else {
            item.image = "";
          }

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-edit-alt"
                style={{
                  fontSize: "1.2em",
                  cursor: "pointer",
                  marginLeft: "0.5rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateFaq(item);
                }}
              ></i>
              <i
                className="uil-trash-alt"
                style={{ fontSize: "1.2em", cursor: "pointer" }}
                onClick={() => {
                  setfaqIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          item.category1 = item?.category?.category_name;


          // item.date = moment(item.subject_date).format("DD-MM-YYYY");
          //  item.time = moment(item.subject_time, "HHmmss").format("hh:mm a"); 
          // if (item?.subjectup_date) item.date = moment(item.subjectup_date).format("DD-MM-YYYY");
          // else item.date = moment(item.subject_date).format("DD-MM-YYYY");

          // if (item?.subjectup_time) item.time = moment(item.subjectup_time,"HHmmss").format("hh:mm a");
          // else item.time = moment(item.subject_time,"HHmmss").format("hh:mm a");

          if (item?.updatedBy) item.staff = item.updatedBy.firstName;
          else item.staff = item?.addedby?.firstName;
          faqData.push(item);
        });

        setfaqForTable(faqData);

      });

  }


  let preUpdateFaq = (item) => {
    // setfaqCategoryValue(item?.name);

    setfaqIdToBeUpdated(item?._id)
    axios
      .get(`${API_URL}faq/single?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data && res.data.success === true) {
          var result = res.data.data
          let category = result?.category || {};
          if (category?.category_name) {
            setselectedCategory({
              label: category?.category_name,
              value: category?._id,
            });
          } else setselectedCategory(null);
          setfaqObject(result);

        }
      });

  };


  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        // sort: "asc",
        width: 150,
      },
      {
        label: "Question",
        field: "question",
        sort: "asc",
        width: 270,
      },
      {
        label: "Answer",
        field: "answer",
        sort: "asc",
        width: 200,
      },
      {
        label: "Category",
        field: "category1",
        sort: "asc",
        width: 100,
      },
      {
        label: "Image",
        field: "image",
        sort: "asc",
        width: 10,
      },
      {
        label: "Order",
        field: "order",
        sort: "desc",
        width: 10,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "desc",
        width: 150,
      },
    ],
    rows: faqForTable,
  };

  function handleChangeInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    // setfaqValue(value);
    setfaqObject({ ...faqObject, [name]: value });
  }


  function fetch_all_category() {
    axios
      .get(`${API_URL}faqCategory/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var category_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.category_name,
              value: el._id,
            };

          });

        setcategory([{ options: category_data }])

      });

  }


  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "category":
        setselectedCategory(selected);
        setfaqObject({
          ...faqObject,
          category: selected.value,
        });
        setErrors({
          ...errors,
          categoryName: "",
        });
        break;
      default:
        break;
    }
  };

  const handleValidSubmit = () => {

    if (selectedCategory === null) {
      let errorVal = errors;
      if (selectedCategory === null)
        errorVal.categoryName = "Please Select Category"

      setErrors({
        ...errorVal,
      });
    }
    else {
      if (faqIdToBeUpdated) {
        axios
          .put(`${API_URL}faq/update?_id`, faqObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Faq updated successfully");
              handleTableData();
              setselectedCategory(null);
              setfaqObject({});
              formRef.current.reset();
              setfaqIdToBeUpdated(null);
              setErrors({
                ...errors,
                categoryName: ""
              });

            } else toastr.error("Failed to update Faq");
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}faq/`, faqObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("Faq added successfully");
              handleTableData();
              // setShowCompany(false);
              // setfaqCategoryValue("")
              setselectedCategory(null);
              setfaqObject({});
              setErrors({
                ...errors,
                categoryName: ""
              });
              formRef.current.reset();

            } else toastr.error("Failed to add Faq");
          })
          .catch((err) => {
            console.log(err)
            toastr.error(err.response.data.message);
            return;
          });
      }

    }
  };

  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("faq_image", e.target.files[0]);
    axios
      .post(`${API_URL}faq/faqimage/`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setfaqObject({
            ...faqObject,
            [`image`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteImage = () => {
    setfaqObject({
      ...faqObject,
      [`image`]: "",
    });
  };


  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}faq` + "/" + faqIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Faq deleted successfully");

                  if (faqObject && faqObject?._id === faqIdToBeDeleted) {
                    formRef.current.reset();
                    setfaqIdToBeUpdated(null);
                    setfaqObject({});
                    setselectedCategory(null);
                  }
                  handleTableData();
                } else {
                  toastr.error("Failed to delete Faq");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}


      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumb title="Home" breadcrumbItem="Faq" />
          <Row>
            <Col xl="12">
              {/* <Card >
                <CardBody> */}
              <AvForm
                className="needs-validation"
                ref={formRef}
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <Row>
                  <Col md="3">
                    <div className="mb-3" >
                      <Label>Category</Label>
                      <Select
                        name='category'
                        placeholder='Category'
                        value={selectedCategory}
                        validate={{ required: { value: true } }}
                        onChange={(selected) => {
                          handleSelectChange(selected, 'category')
                        }}
                        options={category}
                        classNamePrefix="select2-selection"
                      />
                      <p className='text-danger'
                        style={{ fontSize: "11px" }}>
                        {selectedCategory === null ? errors.categoryName : ""}
                      </p>
                    </div>
                  </Col>

                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Order</Label>
                      <AvField
                        name="order"
                        placeholder="Order"
                        type="Number"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        errorMessage="Enter Order"
                        onChange={handleChangeInput}
                        value={faqObject?.order}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Question</Label>
                      <AvField
                        name="question"
                        placeholder="Question"
                        type="text"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        errorMessage="Enter Question"
                        onChange={handleChangeInput}
                        value={faqObject?.question}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    {/* <Row md="1">  */}
                    <div className='mb-3'>
                      <Label>Answer</Label>
                      <AvField
                        name="answer"
                        placeholder="Answer"
                        type="textarea"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        rows="1"
                        errorMessage="Enter Answer"
                        onChange={handleChangeInput}
                        value={faqObject?.answer}
                      />
                    </div>
                    {/* </Row> */}
                  </Col>

                  <Col md="3">
                    <div className="mb-0">
                      <Label htmlFor="validationCustom03">
                        Image
                      </Label>
                      {faqObject?.image ? (
                        <div div className="img-wraps">
                          {faqObject.image &&
                            faqObject.image.startsWith(
                              "http"
                            ) ? (
                            <img
                              alt=""
                              width="150"
                              height="150"
                              src={`${faqObject.image}`}
                            />
                          ) : (
                            <img
                              alt=""
                              width="150"
                              height="150"
                              src={`${API_URL}/uploads/faq_image/${faqObject?.image}`}
                            />
                          )}
                          <button
                            className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                            onClick={deleteImage}
                            style={{ width: "150px" }}
                          >
                            Delete
                          </button>
                        </div>
                      ) : (
                        <AvField
                          name="image"
                          type="file"
                          className="form-control"
                          id="validationCustom03"
                          onChange={uploadImage}
                          rows="1"
                        />
                      )}
                    </div>
                  </Col>

                  <Col md="3" style={{ paddingTop: "4px" }}>
                    <div className='mt-4'>
                      {faqIdToBeUpdated ? (
                        <Button className='mx-2'
                          color='primary'
                          type='submit'
                          disabled={addingfaq ? true : false}
                        >
                          {addingfaq ? "Updating" : "Update"}
                        </Button>
                      )
                        :
                        <Button className='mx-2'
                          color='primary'
                          type='submit'
                          disabled={addingfaq ? true : false}
                        >
                          {addingfaq ? "Adding" : "Submit"}
                        </Button>
                      }

                      <Button
                        color='danger'
                        type='reset'
                        onClick={() => reset()}
                      >
                        Reset
                      </Button>

                    </div>
                  </Col>
                </Row>
              </AvForm>
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>


          <Row>
            <Col className='col-12' style={{ marginTop: "10px" }}>
              <MDBDataTable
                responsive
                id="AddFaqtable"
                bordered
                data={data}
                searching={true}
                info={true}
                disableRetreatAfterSorting={true}
                entries={20} />
            </Col>
          </Row>

        </div>
      </div>

    </React.Fragment>

  )
}

export default Faq;