import React, { useEffect, useState } from "react"
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap"
import { get, map } from "lodash"
import { withTranslation } from "react-i18next"
import axios from "axios";

//i18n
import i18n from "../../../i18n"
import languages from "../../../common/languages"
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import {
    setLocalbody,
    getLocalbody,
    setLocalbodyName,
  } from "../../../helpers/globalFunctions";
  import { useDispatch, useSelector } from "react-redux";
  import {
   getCompaniesMasterOptions,
   
  } from "../../../store/actions";
const LanguageDropdown = () => {
  // Declare a new state variable, which we'll call "menu"
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [selectedLang, setSelectedLang] = useState("")
  const [menu, setMenu] = useState(false)
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(null);
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      var user_obj = JSON.parse(data);
      setUserId(user_obj._id);
      //  fetchMasterCompanies(user_obj._id);
      dispatch(getCompaniesMasterOptions(user_obj._id));
      fetchMasterLocalbodies(user_obj._id);
    }
    // const currentLanguage = localStorage.getItem("I18N_LANGUAGE")
    // setSelectedLang(currentLanguage)
  }, [])

  const changeLanguageAction = lang => {
    //set language as i18n
    i18n.changeLanguage(lang)
    localStorage.setItem("I18N_LANGUAGE", lang)
    setSelectedLang(lang)
  }

  const toggle = () => {
    setMenu(!menu)
  }
  function fetchMasterLocalbodies(user_id, id = null) {
        var data = localStorage.getItem("authUser");
        var user_obj = JSON.parse(data);
        user_id = user_obj._id;
        if (id != null) {
          var url = `${API_URL}cargo_Branch/options?id=` + user_id + `&cid=` + id;
        } else {
          var url = `${API_URL}cargo_Branch/options?id=` + user_id;
        }
        axios
          .get(url, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            console.log(res.data.data,"cargobranches");
            var localbodies =
              res.data.data &&
              res.data.data.map((item) => {
                return {
                  label: item.branch_name,
                  value: item._id,
                };
              });
            var user_localbodies =
              res.data.user_localbody &&
              res.data.user_localbody.map((item) => {
                return {
                  label: item.branch_name,
                  value: item._id,
                };
              });
              setSelectedLang([
              {
                options: localbodies,
              },
            ]);
    
            if (currentLocalbody !== null) {
              if (localbodies && localbodies.length > 0) {
                localbodies.forEach((element) => {
                  if (element.value === currentLocalbody) {
                    setSelectedLang(element);
                    setLocalbodyName(element?.label);
                  }
                });
              }
            } else {
              if (user_localbodies && user_localbodies.length > 0) {
                setSelectedLang(user_localbodies[0]);
                setLocalbody(user_localbodies[0]?.value);
                setLocalbodyName(user_localbodies[0]?.label);
              }
            }
          });
      }
  return (
    <>
      <Dropdown isOpen={menu} toggle={toggle} className="d-inline-block language-switch">
        <DropdownToggle className="btn header-item waves-effect" tag="button">
          
         
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end" right>
          {map(Object.keys(languages), key => (
            <DropdownItem
              key={key}
              onClick={() => changeLanguageAction(key)}
              className={`notify-item ${selectedLang === key ? "active" : "none"
                }`}
            >
              <img
                src={get(languages, `${key}.flag`)}
                alt="Minible"
                className="me-1"
                height="12"
              />
              <span className="align-middle">
                {get(languages, `${key}.label`)}
              </span>
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    </>
  )
}

export default withTranslation()(LanguageDropdown)


























// import React, { useEffect, useState } from "react";
// import {
//   Dropdown,
//   DropdownItem,
//   DropdownMenu,
//   DropdownToggle,
// } from "reactstrap";
// import { get, map } from "lodash";
// import { withTranslation } from "react-i18next";
// import accessToken from "../../../helpers/jwt-token-access/accessToken";
// //i18n
// import i18n from "../../../i18n";
// import languages from "../../../common/languages";
// import axios from "axios";
// import {
//   setLocalbody,
//   getLocalbody,
//   setLocalbodyName,
// } from "../../../helpers/globalFunctions";

// const LanguageDropdown = () => {
//   // Declare a new state variable, which we'll call "menu"
//   const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());

//   const [selectedBranch, setSelectedBranch] = useState("");
//   const [menu, setMenu] = useState(false);
//   const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
//   useEffect(() => {
//     const currentLanguage = localStorage.getItem("I18N_LANGUAGE");
//     setSelectedBranch(currentLanguage);
//   }, []);

//   const changeLanguageAction = (lang) => {
//     //set language as i18n
//     i18n.changeLanguage(lang);
//     localStorage.setItem("I18N_LANGUAGE", lang);
//     setSelectedBranch(lang);
//   };

//   const toggle = () => {
//     setMenu(!menu);
//   };
//   function fetchMasterLocalbodies(user_id, id = null) {
//     var data = localStorage.getItem("authUser");
//     var user_obj = JSON.parse(data);
//     user_id = user_obj._id;
//     if (id != null) {
//       var url = `${API_URL}cargo_Branch/options?id=` + user_id + `&cid=` + id;
//     } else {
//       var url = `${API_URL}cargo_Branch/options?id=` + user_id;
//     }
//     axios
//       .get(url, {
//         headers: {
//           "x-access-token": accessToken,
//         },
//       })
//       .then((res) => {
//         var localbodies =
//           res.data.data &&
//           res.data.data.map((item) => {
//             return {
//               label: item.branch_name,
//               value: item._id,
//             };
//           });
//         var user_localbodies =
//           res.data.user_localbody &&
//           res.data.user_localbody.map((item) => {
//             return {
//               label: item.branch_name,
//               value: item._id,
//             };
//           });
//         setSelectedBranch([
//           {
//             options: localbodies,
//           },
//         ]);

//         if (currentLocalbody !== null) {
//           if (localbodies && localbodies.length > 0) {
//             localbodies.forEach((element) => {
//               if (element.value === currentLocalbody) {
//                 setSelectedBranch(element);
//                 setLocalbodyName(element?.label);
//               }
//             });
//           }
//         } else {
//           if (user_localbodies && user_localbodies.length > 0) {
//             setSelectedBranch(user_localbodies[0]);
//             setLocalbody(user_localbodies[0]?.value);
//             setLocalbodyName(user_localbodies[0]?.label);
//           }
//         }
//       });
//   }
//   return (
//     <>
//       <Dropdown
//         isOpen={menu}
//         toggle={toggle}
//         className="d-inline-block language-switch"
//       >
//         <DropdownToggle className="btn header-item waves-effect" tag="button">
//           <img
//             src={get(languages, `${selectedBranch}.flag`)}
//             alt="Header Language"
//             height="16"
//           />
//         </DropdownToggle>
//         <DropdownMenu className="dropdown-menu-end" right>
//           {map(Object.keys(languages), (key) => (
//             <DropdownItem
//               key={key}
//               onClick={() => changeLanguageAction(key)}
//               className={`notify-item ${
//                 selectedBranch === key ? "active" : "none"
//               }`}
//             >
//               <img
//                 src={get(languages, `${key}.flag`)}
//                 alt="Minible"
//                 className="me-1"
//                 height="12"
//               />
//               <span className="align-middle">
//                 {get(languages, `${key}.label`)}
//               </span>
//             </DropdownItem>
//           ))}
//         </DropdownMenu>
//       </Dropdown>
//     </>
//   );
// };

// export default withTranslation()(LanguageDropdown);
