import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";

import axios from "axios";
import toastr from "toastr";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import {OverlayTrigger, Tooltip  } from "react-bootstrap";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./customer.scss";
import { Grid } from "@mui/material";
import { withRouter, useHistory } from "react-router-dom";
import accessToken from "../../../helpers/jwt-token-access/accessToken";

import moment from "moment";

import {
  getLocalbody,
} from "../../../helpers/globalFunctions";

const AddCust = () => {
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [masterObject, setmasterObject] = useState({branch_id:currentLocalbody});
  const [custIdTobeUpdated, setcustIdToBeUpdated] = useState(null);
  const [custIdToBeDeleted, setcustIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [custForTable, setcustForTable] = useState([]);

  const [country, setCountry] = useState([]);//options fetching 
  const [selectedCountry, setSelectedCountry] = useState(null);//selecting option
  
  const [state, setState] = useState([]);//options fetching 
  const [selectedState, setSelectedState] = useState(null);//selecting option
  
  const [searchObject, setSearchObject] = useState({});
  const [selectedSearchCountry, setSelectedSearchCountry] = useState(null);//selecting option
  const [selectedSearchState, setSelectedSearchState] = useState(null);//selecting option
  
  const [uploadProgress, setUploadProgress] = useState();
  
 

  const [errors, setErrors] = useState({
    countryError: "",
    stateError:"",
  });

  const [showPopup, setShowPopup] = useState(false);//for model
  const [custDetails, setcustDetails] = useState({});//modelview
  const {
    addingcust,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  let history = useHistory();

  useEffect(() => {
    handleTableData();
    fetch_country();
  }, []);


function fetch_country(){
    axios
    .get(`${API_URL}newStates/countries/`, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((res)=>{
      var Country=
       res.data.data &&
       res.data.data.map((el)=>{
        return{
          label:el.name,
          value:el._id
        };
       });
       setCountry([{options:Country}]) 
    });
}

  const handleSelectSearchChange =(selected,name)=>{
    switch(name){
     
      case "country":
      
       setSelectedSearchCountry(selected);
       setSelectedSearchState(null);

       axios
       .get(`${API_URL}newStates/list?country_id=`+selected.value, {
       headers: {
           "x-access-token": accessToken,
           },
       })
       .then((res)=>{
           if (res.data && res.data.status === 200) {
           var state=
           res.data.data &&
           res.data.data.map((el)=>{ 
           return{
               label:el.name,
               value:el._id
           };
           });
           }else{
           state=[];
           }
           setState([{options:state}]) 
       });
        setSearchObject({
          ...searchObject,
          country:selected.value,
        });
      break;

      case "state":
      
        setSelectedSearchState(selected);
        setSearchObject({
          ...searchObject,
          state:selected.value,
        });
      break;
   
    
      default:
        break;
    }
  
  }

  const handleSearch =() =>{
        let country =searchObject?.country ? searchObject.country : "";
        let state =searchObject?.state ? searchObject.state : "";
        handleTableData(country,state);
  }

  const resetSearch=() =>{
    setSelectedSearchCountry("");
    setSelectedSearchState("");
    setSearchObject({
      country:"",
      state:""});
    handleTableData("","")
    
   }
 
  function handleTableData(country="",state="") {
    var url = `${API_URL}customer/list?country_id=${country}&state_id=${state}`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let testiData = [];

        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
            <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  history.push("/Customer/"+item._id);
                  // history.push("/Customer/ALFC00001");
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdatecust(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setcustIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
     
          item.id = index + 1;
if(item.cust_country){
        item.country1=(
          <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip
              id="button-tooltip-2"
              style={{ position: "absolute", top: "-30px" }}
            >
          {item?.cust_country?.name}
            </Tooltip>
          }
        >
        <div style={{ display: "flex", justifyContent: "center" }}>
        <img src={`${API_URL}uploads/country_flag/${item?.cust_country?.flag}`} width={"30px"}></img>
      </div>
      </OverlayTrigger>);
}
if(item.cust_state){
  item.state=item?.cust_state?.name
}
if(item.app_user == 1){
 item.info=( 
 <div style={{ display: "flex", justifyContent: "center" }}>
     <i
       className="uil-android"
       style={{
         fontSize: "1.5em",
         cursor: "pointer",
         marginLeft: "0.2em",
         marginRight: "0.5em",
         color: "green",
       }}
      
     ></i>
   </div>)
}
          testiData.push(item);
        });

        setcustForTable(testiData);
      });
  }

  let preUpdatecust = (item) => {
   setcustIdToBeUpdated(item?._id);


axios
    .get(`${API_URL}customer?customer_id=` + item._id, {
      headers: {
        "x-access-token": accessToken,
      },
    })
    .then((res) => {
      let result = res.data.data;
      result.branch_id = currentLocalbody;
      let countrys = {
        label: result?.cust_country?.name,
        value: result?.cust_country?._id 
      }; 
    setSelectedCountry(countrys);
   
   
    let states = {
        label: result?.cust_state?.name,
        value: result?.cust_state?._id,
      };
    setSelectedState(states);

    axios
    .get(`${API_URL}newStates/list?country_id=`+result?.cust_country?._id, {
    headers: {
        "x-access-token": accessToken,
        },
    })
    .then((res)=>{
        if (res.data && res.data.status === 200) {
        var state=
        res.data.data &&
        res.data.data.map((el)=>{ 
        return{
            label:el.name,
            value:el._id
        };
        });
        }else{
        state=[];
        }
        setState([{options:state}]) 
    });
  
      setmasterObject(result);

    });
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  
  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "Mobile",
        field: "mobile_number",
        sort: "asc",
        width: 270,
      },

      {
        label: "ID Number",
        field: "id_number",
        sort: "asc",
        width: 150,
      }, 
      {
        label: "Country",
        field: "country1",
        sort: "asc",
        width: 150,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 150,
      },
      {
        label: "App Info",
        field: "info",
        sort: "asc",
        width: 150,
      }, 
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows:custForTable
  };
  
  function handleChangecust(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterObject({ ...masterObject, [name]: value });
  }

  const handleSelectChange = (selected, name) => {
    switch (name) {
        case "country":
            setSelectedCountry(selected);
            setSelectedState(null);
            axios
            .get(`${API_URL}newStates/list?country_id=`+selected.value, {
            headers: {
                "x-access-token": accessToken,
                },
            })
            .then((res)=>{
                if (res.data && res.data.status === 200) {
                var state=
                res.data.data &&
                res.data.data.map((el)=>{ 
                return{
                    label:el.name,
                    value:el._id
                };
                });
                }else{
                state=[];
                }
                setState([{options:state}]) 
            });
        
                setmasterObject({
                ...masterObject,
                cust_country:selected.value,
                });
                setErrors({
                ...errors,
                countryError: "",
                });
        break;
        case "state":
            setSelectedState(selected);
            
                setmasterObject({
                ...masterObject,
                cust_state:selected.value,
                });
                setErrors({
                ...errors,
                stateError: "",
                });
        break;


      default:
        break;
    }
  };

  const handleValidSubmit = () => {
    if(selectedCountry === null || selectedState === null){
        let errorVal = errors;
        if(selectedCountry === null){
      errorVal.countryError ="Please Select Country";
      setErrors({
        ...errorVal,
      });
    }
    if( selectedState === null){
      errorVal.stateError ="Please Select State";
      setErrors({
        ...errorVal,
      });
    }}
    else{
    if (custIdTobeUpdated) {
      axios
        .put(`${API_URL}customer/`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Customer Updated Successfully");
            formRef.current.reset();
            handleTableData();
            setmasterObject({branch_id:currentLocalbody});
            setcustIdToBeUpdated(null);
            setSelectedCountry(null);
            setSelectedState(null);
            setErrors({
              ...errors,
              countryError: "",
              stateError:""
            });
          } else {
            toastr.error("Failed to Update Customer");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}customer/`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Customer Added Succesfully");
           
            handleTableData();
         
            setSelectedCountry(null);
            setSelectedState(null);

            formRef.current.reset();
            setmasterObject({branch_id:currentLocalbody});
            setErrors({
              ...errors,
              countryError: "",
              stateError:""
            });
          } else {
            toastr.error("Failed to add Customer");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
  }
  };

  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}customer/image/`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setmasterObject({
            ...masterObject,
            [`image`]: response.data.file.filename,
          });
          setErrors({
            ...errors,
            imageError: "",
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteImage = () => {
    setmasterObject({
      ...masterObject,
      [`image`]: "",
    });
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  return (
    <>
      <React.Fragment>
        {confirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmButtonText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}customer/${custIdToBeDeleted}`, {
                  headers: {
                    "x-access-token": accessToken,
                  },
                })
                .then((res) => {
                  if (res.data.status === 200) {
                    toastr.success("Customer Deleted successfully");
                    if (masterObject && masterObject._id === custIdToBeDeleted) {
                      formRef.current.reset();
                      setmasterObject({branch_id:currentLocalbody});
                      setcustIdToBeUpdated(null);
                      setSelectedCountry(null);
                      setSelectedState(null);
                    }
                    handleTableData();
                  } else {
                    toastr.error(
                      res.data.message,
                      "Failed to delete Customer"
                    );
                    return;
                  }
                });
              setConfirmDeleteAlert(false);
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure you want to delete it?
          </SweetAlert>
        ) : null}
     
        <div className="page-content">
          <div className="container-fluid">
            <Breadcrumbs title="Home" breadcrumbItem="Manage Customers" />

            <Row>
              <Col className="col-12">
                {/* <Card style={{ padding: "0px 0px 2px 0px" }}>
                  <CardBody> */}
                    <AvForm
                      ref={formRef}
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <Row>
                      <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Country  
                            </Label>
                            <Select
                              name="country"
                              value={selectedCountry}
                              options={country} 
                              classNamePrefix ="select2-selection"
                              onChange={(selected) => {
                                handleSelectChange(selected, "country");
                              }}
                            />
                              <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedCountry === null ? errors.countryError : ""}
                            </p>
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              State  
                            </Label>
                            <Select
                              name="state"
                              value={selectedState}
                              options={state}
                              classNamePrefix ="select2-selection"
                              onChange={(selected) => {
                                handleSelectChange(selected, "state");
                              }}
                            />
                              <p
                              className="text-danger"
                              style={{ fontSize: "11px" }}
                            >
                              {selectedState === null ? errors.stateError : ""}
                            </p>
                          </div>
                        </Col>
                       
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Name 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="name"
                              placeholder="Name"
                              type="text"
                              errorMessage="Enter Name"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.name}
                              onChange={handleChangecust}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              ID No 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="id_number"
                              placeholder="ID Number"
                              type="number"
                            //  errorMessage="Enter ID No"
                              className="form-control"
                              // validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.id_number}
                              onChange={handleChangecust}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom02">
                              Email 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="email"
                              placeholder="Email "
                              type="email"
                              errorMessage="Enter Email"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom02"
                              value={masterObject?.email}
                              onChange={handleChangecust}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Mobile 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="mobile_number"
                              placeholder="Mobile "
                              type="number"
                              errorMessage="Enter Mobile"
                              className="form-control"
                              validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.mobile_number}
                              onChange={handleChangecust}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Whatsapp 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="whattsapp_number"
                              placeholder="Whatsapp"
                              type="number"
                            //   errorMessage="Enter Whatsapp"
                              className="form-control"
                            //   validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.whattsapp_number}
                              onChange={handleChangecust}
                            />
                          </div>
                        </Col>
                       
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Address 
                            </Label>
                            <AvField
                              style={{lineHeight:"1.3"}}
                              name="address"
                              placeholder="Address"
                              type="textarea"
                            //   errorMessage="Enter Address"
                              className="form-control"
                            //   validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.address}
                              onChange={handleChangecust}
                            />
                          </div>
                        </Col>
                        
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Post Office 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="post_office"
                              placeholder="Post Office"
                              type="text"
                            //   errorMessage="Enter Post Office "
                              className="form-control"
                            //   validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.post_office}
                              onChange={handleChangecust}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Pincode 
                            </Label>
                            <AvField
                              style={customStyles}
                              name="pin_code"
                              placeholder="Pincode"
                              type="number"
                            //   errorMessage="Enter Pincode"
                              className="form-control"
                            //   validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.pin_code}
                              onChange={handleChangecust}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Landmark
                            </Label>
                            <AvField
                              style={customStyles}
                              name="landmark"
                              placeholder="Landmark"
                              type="text"
                            //   errorMessage="Enter Landmark"
                              className="form-control"
                            //   validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.landmark}
                              onChange={handleChangecust}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom01">
                              District
                            </Label>
                            <AvField
                              style={customStyles}
                              name="district"
                              placeholder="District"
                              type="text"
                            //   errorMessage="Enter District"
                              className="form-control"
                            //   validate={{ required: { value: true } }}
                              id="validationCustom01"
                              value={masterObject?.district}
                              onChange={handleChangecust}
                            />
                          </div>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Label htmlFor="validationCustom03">Image</Label>
                            {masterObject?.image ? (
                              <div div className="img-wraps">
                                {masterObject.image &&
                                masterObject.image.startsWith("http") ? (
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${masterObject.image}`}
                                  />
                                ) : (
                                  <img
                                    alt=""
                                    width="150"
                                    height="150"
                                    src={`${API_URL}uploads/customer/${masterObject?.image}`}
                                  />
                                )}
                                <button
                                  className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                                  onClick={deleteImage}
                                  style={{ width: "150px" }}
                                >
                                  Delete
                                </button>
                              </div>
                            ) : (
                              <AvField
                                name="image"
                                type="file"
                                className="form-control"
                                id="validationCustom03"
                                onChange={uploadImage}
                                rows="1"
                              />
                              
                            )}
                           
                          
                          </div>
                        
                        </Col>
                      
                      {/* </Row>
                      <Row> */}
                      <Col>
                          <div className="mb-3" style={{ paddingTop: "25px" }}>
                            {custIdTobeUpdated ? (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingcust ? true : false}
                              >
                                {addingcust ? "Updating" : "Update"}
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                type="submit"
                                disabled={addingcust ? true : false}
                              >
                                {addingcust ? "Adding" : "Submit"}
                              </Button>
                            )}
                          </div>
                        </Col>
                      </Row>
                    </AvForm>
                  {/* </CardBody>
                </Card> */}
              </Col>
            </Row>
            <Row>
                <Col className="col-12">
                  {/* <Card style={{ padding: "0px 0px 2px 0px" }}>
                    <CardBody> */}
                    <Row className="mb-3">
                    <Col md="2"> 
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                             Country
                          </Label>
                          <Select
                            name="country"
                            value={selectedSearchCountry}
                            onChange={(selected) => {
                               handleSelectSearchChange(selected, "country");
                            }}
                            options={country}
                          
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2"> 
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">
                             State
                          </Label>
                          <Select
                            name="state"
                            value={selectedSearchState}
                            onChange={(selected) => {
                               handleSelectSearchChange(selected, "state");
                            }}
                            options={state}
                          
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                  
                      
                      <Col
                        md="2"
                        // className="mt-4"
                        style={{ paddingTop: "25px" }}
                      >
                          <div className="mb-3">
                         <Button
                            color="success"
                            type="submit"
                            style={{ marginRight: "5%" }}
                             onClick={() => handleSearch()}
                          >
                            {"  "}
                            Filter
                          </Button>
                          <Button
                            color="danger"
                            type="submit"
                            style={{ marginRight: "5%" }}
                            onClick={() => resetSearch()}
                          >
                            {"  "}
                            Reset
                          </Button>
                      
                          </div>
                          </Col>
                          {/* <Col>
                          <div className="mb-3" > */}
                          {/* <Button
                            color="success"
                            type="submit"
                            style={{float:"right"}}
                          >
                             <CSVLink
                          //  data={dataToBeExported} 

                            // filename={
                            //   "leads_report_" + getDate(new Date()) + ".xls"
                            // }
                            style={{ color: "white" }}
                          >
                            {"  "}
                            Export
                            </CSVLink>
                          </Button> */}
                         {/* </div>
                      
                      </Col> */}
                  
               
                      <MDBDataTable
                        id="custTablesId"
                        responsive
                        bordered
                        data={data}
                        searching={true}
                        info={true}
                        disableRetreatAfterSorting={true}
                        entries={20}
                      />
                    </Row>
                  {/* </CardBody>
                </Card> */}
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>

      {/* pay modal end */}
    </>
  );
};

export default AddCust; 