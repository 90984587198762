import React, { useEffect, useState } from 'react'
import $ from "jquery";

import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table"
import Select from 'react-select';
import { CSVLink } from "react-csv";

import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Col, Label, Button, Card, CardBody, Row, ToastHeader } from 'reactstrap';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { MDBDataTable } from "mdbreact";
import {
  getLocalbody,
  getDate,
  getFirstday,
} from "../../../helpers/globalFunctions";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import "./Staffwise.scss";
import { Air } from '@mui/icons-material';

const Staffwise = (props) => {

  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState(null);

  const [order, setOrder] = useState([]);
  const [dataToBeExported, setDataToBeExported] = useState([]);

  const [staffData, setstaffData] = useState(null);
  const [staffObject, setStaffObject] = useState({});




  useEffect(() => {
    handleTableData();
    fetch_all_branch();
    fetch_all_country();
    fetch_all_staff();
  }, []);


  const fetch_all_branch = () => {
    axios
      .get(`${API_URL}reports/branch_box`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log("res", res);
        var branch_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.branch_name,
              value: el._id,
            };
          });

        setBranchOptions([
          {
            options: branch_data,
          },
        ]);
      });
  }

  const fetch_all_country = () => {
    axios
      .get(`${API_URL}newStates/countries`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var country_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setCountryOptions([
          {
            options: country_data,
          },
        ]);
      });
  };


  const fetch_all_staff = () => {
    axios
      .get(`${API_URL}reports/staff_wise`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log("res", res);
        var staff_data =
          res.data.data &&
          res.data.data.map((el) => {
            // console.log("stf",el);

            return {
              label: (el.firstName || '') + (el.lastName || ''),
              value: el._id,
            };
          });

        setStaffOptions([
          {
            options: staff_data,
          },
        ]);
      });
  }



  function handleTableData(
    from_date = "",
    to_date = "",
    country_id = "",
    branch_id = "",
    branch = "",
    country_from = "",
    country_to = "",
    staff = ""
  ) {
    axios
      .get(`${API_URL}reports/staff_wise`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        console.log("res.data.data", result);
        let staffData = [];

        result.map((item, index) => {
          // console.log("item", item);

          let val = {};
          val.rslt = item?.rslt;

          item.data &&
            item.data.map((el, index) => {

              if (index == 0) {
                val.d1 = el;
              }

              if (index == 1) {
                val.d2 = el;
              }

              if (index == 2) {
                val.d3 = el;
              }

              if (index == 0) {
                val.d4 = el;
              }

            })

          setOrder(result);

        })

      })

    var url =
      `${API_URL}reports/staff_wise?from_date=${from_date}&to_date=${to_date}&branch=${branch}&country_from=${country_from}&country_to=${country_to}&staff=${staff}`

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let dataToBeExported = [];
        result &&
          result.map((item, index) => {
            let exportItem = {};
            item.id = index + 1;

            item.date = moment(item?._id).format("DD-MM-YYYY");

            //export 
            exportItem.id = item.id;
            exportItem.Staff = (item?.firstName) + (item?.lastName);
            exportItem.Air_Orders = item.data[0].total_order;
            exportItem.Air_Amount = item.data[0].total_amount;
            exportItem.Air_Weight = item.data[0].total_weight;
            // exportItem.Sea = item.sea;
            exportItem.Sea_Orders = item.data[1].total_order;
            exportItem.Sea_Amount = item.data[1].total_amount;
            exportItem.Sea_Weight = item.data[1].total_weight;
            // exportItem.Road = item.road;
            exportItem.Rd_Orders = item.data[3].total_order;
            exportItem.Rd_Amount = item.data[3].total_amount;
            exportItem.Rd_Weight = item.data[3].total_weight;

            dataToBeExported.push(exportItem);
          });

        setDataToBeExported([
          ...dataToBeExported,
          {
            Staff: "Total",
            Air_Orders:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[0].total_order;
                return acc;
              }, 0),

            Air_Amount:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[0].total_amount;
                return acc;
              }, 0),

            Air_Weight:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[0].total_weight;
                return acc;
              }, 0),

            Sea_Orders:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[1].total_order;
                return acc;
              }, 0),

            Sea_Amount:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[1].total_amount;
                return acc;
              }, 0),

            Sea_Weight:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[1].total_weight;
                return acc;
              }, 0),

            Rd_Orders:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[3].total_order;
                return acc;
              }, 0),

            Rd_Amount:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[3].total_amount;
                return acc;
              }, 0),

            Rd_Weight:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[3].total_weight;
                return acc;
              }, 0),

          }
        ]);

      });

  }
  // console.log("ordr",order);


  const handleSearchSelectChange = (selected, name) => {
    switch (name) {
      case "country":
        setSelectedCountry(selected);
        setStaffObject({
          ...staffObject,
          country: selected.value,
        });
        break;

      case "branch":
        setSelectedBranch(selected);
        setStaffObject({
          ...staffObject,
          branch: selected.value,
        });
        break;

      case "staff":
        setSelectedStaff(selected);
        setStaffObject({
          ...staffObject,
          staff: selected.value,
        });
        break;



      default:
        break;
    }

  }

  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    //date validation
    let country_id = searchData?.country_id ? searchData.country_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    handleTableData(date1, date2, country_id);

  };


  const reset = () => {
    // formRef.current.reset();
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedCountry(null);
    setSelectedBranch(null);
    setSelectedStaff(null);
    handleTableData();
  };


  const handleSearch = () => {
    let country_id = searchData?.country_id ? searchData.country_id : "";
    let branch_id = searchData?.branch_id ? searchData.branch_id : "";
    let branch_name = searchData?.branch_name ? searchData.branch_name : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    handleTableData(date1, date2, country_id, branch_id, branch_name);
  };





  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumb title="Home" breadcrumbItem="Staff wise Report" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                    }}
                  >
                    <Row>
                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">From</Label>
                          <input
                            className="form-control"
                            type="date"
                            id="from_date"
                            name="from_date"
                            value={searchData?.from_date}
                            onChange={handleDate}
                          />
                        </div>
                      </Col>


                      <Col md="2">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom05">To</Label>
                          <input
                            className="form-control"
                            type="date"
                            id="to_date"
                            name="to_date"
                            value={searchData?.to_date}
                            min={searchData?.from_date}
                            onChange={handleDate}
                          />
                        </div>
                      </Col>


                      <Col md="2">
                        <div className="mb-3" >
                          <Label>Branch</Label>
                          <Select
                            name='branch_id'
                            placeholder='Select'
                            value={selectedBranch}
                            options={branchOptions}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "branch");
                            }}
                          />
                          <p className='text-danger'
                            style={{ fontSize: "11px" }}>
                          </p>
                        </div>
                      </Col>

                      <Col md="2">
                        <div className="mb-3" >
                          <Label>Country</Label>
                          <Select
                            name='country_id'
                            placeholder='Select'
                            value={selectedCountry}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "country");
                            }}
                            options={countryOptions}
                          />
                          <p className='text-danger'
                            style={{ fontSize: "11px" }}>
                          </p>
                        </div>
                      </Col>


                      <Col md="2">
                        <div className="mb-3" >
                          <Label>Staff</Label>
                          <Select
                            name='Staff'
                            placeholder='Select'
                            value={selectedStaff}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSearchSelectChange(selected, "staff");
                            }}
                            options={staffOptions}
                          />
                          <p className='text-danger'
                            style={{ fontSize: "11px" }}>
                          </p>
                        </div>
                      </Col>

                      <Col md="3" className="mt-2" style={{ paddingTop: "2px" }}>
                        <Button className="mx-2"
                          color="primary"
                          type="submit"
                          onClick={() => handleSearch()}
                        >
                          Search
                        </Button>

                        <Button className="mx-2"
                          color="success"
                          type="submit"
                        >
                          <CSVLink
                            data={dataToBeExported}
                            filename={
                              "Staff_wise_shipment_report_" + getDate(new Date()) + ".xls"
                            }
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>

                        <Button color="danger" type="reset" className="mx-2" onClick={() => reset()}>
                          Reset
                        </Button>
                      </Col>

                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>

            <Col md="12">
              <Card>
                <CardBody>
                  <table className="table table-bordered" id="tableReport">
                    <thead>
                      <tr>
                        <th></th>
                        <th style={{ textAlign: "center" }}></th>
                        <th style={{ textAlign: "center" }} colSpan="3" width="25%">Air</th>
                        <th style={{ textAlign: "center" }} colSpan="3" width="25%">Sea</th>
                        <th style={{ textAlign: "center" }} colSpan="3" width="25%">Road</th>
                      </tr>

                      <tr>
                        <td style={{ textAlign: "center", fontWeight: 'bold' }} >#</td>
                        <td style={{ textAlign: "center", fontWeight: 'bold' }}>Staff</td>
                        <td style={{ textAlign: "center", fontWeight: 'bold' }}>Orders</td>
                        <td style={{ textAlign: "center", fontWeight: 'bold' }} >Amount</td>
                        <td style={{ textAlign: "center", fontWeight: 'bold' }} >Weight</td>
                        <td style={{ textAlign: "center", fontWeight: 'bold' }} >Orders</td>
                        <td style={{ textAlign: "center", fontWeight: 'bold' }} >Amount</td>
                        <td style={{ textAlign: "center", fontWeight: 'bold' }} >Weight</td>
                        <td style={{ textAlign: "center", fontWeight: 'bold' }}>Orders</td>
                        <td style={{ textAlign: "center", fontWeight: 'bold' }}>Amount</td>
                        <td style={{ textAlign: "center", fontWeight: 'bold' }}>Weight</td>
                      </tr>

                    </thead>

                    <tbody>

                      {
                        order.map((item, index) => {
                          return (
                            <tr>
                              <td style={{ textAlign: "center" }}>{index + 1}</td>
                              <td style={{ textAlign: "left" }}>{item?.firstName || ''}  {item?.lastName || ''}</td>
                              <td style={{ textAlign: "left" }}>{item?.data[0].total_order}</td>
                              <td style={{ textAlign: "left" }}>{item?.data[0].total_amount}</td>
                              <td style={{ textAlign: "left" }}>{item?.data[0].total_weight}</td>
                              <td style={{ textAlign: "left" }}>{item?.data[1].total_order}</td> 
                              <td style={{ textAlign: "left" }}>{item?.data[1].total_amount}</td>
                              <td style={{ textAlign: "left" }}>{item?.data[1].total_weight}</td>
                              <td style={{ textAlign: "left" }}>{item?.data[3].total_order}</td>
                              <td style={{ textAlign: "left" }}>{item?.data[3].total_amount}</td>
                              <td style={{ textAlign: "left" }}>{item?.data[3].total_weight}</td>
                            </tr>

                          )
                        })
                      }

                      <tr>
                        <td></td>

                        <td style={{ textAlign: "right" }}><b>Total</b></td>

                        <td style={{ fontWeight: "1000", textAlign: "left" }}>
                          {order &&
                            order.reduce((acc, curr) => {
                              acc = acc + curr.data[0].total_order;
                              return acc;
                            }, 0)
                          }
                        </td>

                        <td style={{ fontWeight: "1000", textAlign: "left" }}>
                          {order &&
                            order.reduce((acc, curr) => {
                              acc = acc + curr.data[0].total_amount;
                              return acc;
                            }, 0)
                          }
                        </td>

                        <td style={{ fontWeight: "1000", textAlign: "left" }}>
                          {order &&
                            order.reduce((acc, curr) => {
                              acc = acc + curr.data[0].total_weight;
                              return acc;
                            }, 0)
                          }
                        </td>

                        <td style={{ fontWeight: "1000", textAlign: "left" }}>
                          {order &&
                            order.reduce((acc, curr) => {
                              acc = acc + curr.data[1].total_order;
                              return acc;
                            }, 0)
                          }
                        </td>

                        <td style={{ fontWeight: "1000", textAlign: "left" }}>
                          {order &&
                            order.reduce((acc, curr) => {
                              acc = acc + curr.data[1].total_amount;
                              return acc;
                            }, 0)
                          }
                        </td>

                        <td style={{ fontWeight: "1000", textAlign: "left" }}>
                          {order &&
                            order.reduce((acc, curr) => {
                              acc = acc + curr.data[1].total_weight;
                              return acc;
                            }, 0)
                          }
                        </td>

                        <td style={{ fontWeight: "1000", textAlign: "left" }}>
                          {order &&
                            order.reduce((acc, curr) => {
                              acc = acc + curr.data[3].total_order;
                              return acc;
                            }, 0)
                          }
                        </td>

                        <td style={{ fontWeight: "1000", textAlign: "left" }}>
                          {order &&
                            order.reduce((acc, curr) => {
                              acc = acc + curr.data[3].total_amount;
                              return acc;
                            }, 0)
                          }
                        </td>

                        <td style={{ fontWeight: "1000", textAlign: "left" }}>
                          {order &&
                            order.reduce((acc, curr) => {
                              acc = acc + curr.data[3].total_weight;
                              return acc;
                            }, 0)
                          }
                        </td>

                      </tr>


                    </tbody>
                  </table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>

    </React.Fragment>

  )
}
export default Staffwise;