import React, { useState, useEffect, useRef } from "react";
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Col, Label, Button, Card, CardBody, Row, ToastHeader } from 'reactstrap';
import { AvField, AvForm } from "availity-reactstrap-validation";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import toastr from "toastr";
import { MDBDataTable } from "mdbreact";
import "./Offercode.scss"
import SweetAlert from "react-bootstrap-sweetalert";


function Offercode(){
    const[offercodeObject,setoffercodeObject]=useState({})
    const[offercodeTable,setoffercodeTable]=useState([]);
    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
    const [offercodeIdToBeDeleted, setoffercodeIdToBeDeleted] = useState(null);
    const [offercodeIdToBeUpdated, setoffercodeIdToBeUpdated] = useState(null);

    const reset = () => {
         formRef.current.reset();
       // setfaqIdToBeDeleted(null);
       // setselectedCategory()
         setoffercodeObject({});
     }
     useEffect(() => {
        handleTableData();
        //  fetch_all_category();
         
       }, []);
     
     const toTop = () => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      };
     const {
        districts,
        addingoffercodeObject,
        // addDistrictResponse,
        // deleteDistrictResponse,
        // updateDistrictResponse,
         error,
      } = useSelector((state) => state.districts);
      const formRef = useRef();
      const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";


     function handleChangeInput (e) {
        let name= e.target.name;
        let value = e.target.value;
        setoffercodeObject({...offercodeObject,[name]:value });
        // console.log(e.target.value);
        //   console.log(e.target.name);
      }
      function handleValidSubmit(){
        console.log(offercodeObject)

        if (offercodeIdToBeUpdated) {
          axios
          .put(`${API_URL}coupon/update?_id`, offercodeObject, {
          headers: {
          "x-access-token": accessToken,
          },
          })
          .then((res) => {
          if (res.data.success === true) {
          toastr.success("Offer Code updates successfully");
          handleTableData();
          setoffercodeObject({});
          formRef.current.reset();
          setoffercodeIdToBeUpdated(null);
          
          } else toastr.error("Failed to update Offer Code");
          })
          .catch((err) => {
          toastr.error(err.response.data.message);
          return;
          });
          } else {
          axios
          .post(`${API_URL}coupon/`, offercodeObject, {
          headers: {
          "x-access-token": accessToken,
          },
          })
          .then((res) => {
          console.log(res);
          if (res.data.success === true) {
          toastr.success("Offer Code added successfully");
             handleTableData();
           setoffercodeObject({});
          formRef.current.reset();
          
          } else toastr.error("Failed to add Offer Code");
          })
          .catch((err) => {
          console.log(err)
          toastr.error(err.response.data.message);
          return;
          });
             }   
      }
      let preUpdateOffercode = (item) => {
        setoffercodeIdToBeUpdated(item?._id)
        setoffercodeObject(item)
      }
      
      function handleTableData() {
        var url = `${API_URL}coupon/list`;
        axios
          .get(url, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            var result = res.data.data;
            console.log("result: ", result);
            let offerData = [];
    
            result.map((item, index) => {
    
              
              
              item.action = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <i
                    className="uil-edit-alt"
                    style={{
                      fontSize: "1.2em",
                      cursor: "pointer",
                      marginLeft: "0.5rem",
                      marginRight: "0.5rem",
                    }}
                    onClick={() => {
                      toTop();
                       preUpdateOffercode(item);
                    }}
                  ></i>
                  <i
                    className="uil-trash-alt"
                    style={{ fontSize: "1.2em", cursor: "pointer" }}
                    onClick={() => {
                       setoffercodeIdToBeDeleted(item._id);
                       setConfirmDeleteAlert(true);
                    }}
                  ></i>
                </div>
               );
              item.id = index + 1;
             
             
            
               // item.date = moment(item.subject_date).format("DD-MM-YYYY");
               //  item.time = moment(item.subject_time, "HHmmss").format("hh:mm a"); 
              // if (item?.subjectup_date) item.date = moment(item.subjectup_date).format("DD-MM-YYYY");
              // else item.date = moment(item.subject_date).format("DD-MM-YYYY");
            
              // if (item?.subjectup_time) item.time = moment(item.subjectup_time,"HHmmss").format("hh:mm a");
              // else item.time = moment(item.subject_time,"HHmmss").format("hh:mm a");
    
              if (item?.updatedBy) item.staff = item.updatedBy.firstName;
              else item.staff = item?.addedby?.firstName;
              offerData.push(item);
            });
           
            setoffercodeTable(offerData);
            
          });
          
      }
      const data = {
        columns: [
        {
        label: "#",
        field: "id",
        // sort: "asc",
        width: 150,
        },
        {
        label: "Offer Name",
        field: "offer_name",
        sort: "asc",
        width: 270,
        },
        {
        label: "Offer Code",
        field: "offer_code",
        sort: "asc",
        width: 200,
        },
        {
        label: "Valid From",
        field: "valid_from",
        sort: "asc",
        width: 100,
        },
        {
        label: "Valid To",
        field: "valid_to",
        sort: "asc",
        width: 10,
        },
        {
        label: "Amount Type",
        field: "amount_Type",
        sort: "desc",
        width: 10,
        },
        {
        label: "Min Purchase Amount",
        field: "minimum_purchace_amt",
        sort: "desc",
        width: 100,
        },
        {
        label: "Max Offer Amount",
        field: "maximum_offer_amt",
        sort: "asc",
        width: 100,
        },
        {
        label: "Offer Code Max Usage",
        field: "offer_code_maximum_usage",
        sort: "asc",
        width: 100,
        },
        {
        label: "Offer Code Status",
        field: "offer_code_status",
        sort: "asc",
        width: 100,
        },
        {
        label: "Staff",
        field: "staff",
        sort: "desc",
        width: 100,
        },
        {
        label: "Action",
        field: "action",
        sort: "desc",
        width: 150,
        },
        ],
        rows: offercodeTable,
        };
  return (
    <React.Fragment>
           {confirmDeleteAlert ? (
 <SweetAlert
 title=""
 showCancel
 confirmButtonText="Delete"
 confirmBtnBsStyle="success"
 cancelBtnBsStyle="danger"
 onConfirm={() => {
 axios
 .delete(`${API_URL}coupon` + "/" + offercodeIdToBeDeleted, {
 headers: {
 "x-access-token": accessToken,
 },
 })
 .then((res) => {
 if (res.data.success === true) {
 toastr.success("Offer Code deleted successfully");

 if (offercodeObject && setoffercodeObject?._id === offercodeIdToBeDeleted) {
 formRef.current.reset();
//   setIdToBeUpdated(null);

 }
 handleTableData();
 } else {
 toastr.error("Failed to delete Offer Code");
 return;
 }
 });
 setConfirmDeleteAlert(false);
 }}
 onCancel={() => setConfirmDeleteAlert(false)}
 >
 Are you sure you want to delete it?
 </SweetAlert>
 ) : null}
     <div className='page-content'>
         <div className='container-fluid'>
         <Breadcrumb title="Home" breadcrumbItem="Offercode" />
         <Row>
            <Col xl="12">
                  <Card >
                    <CardBody>
                      <AvForm
                        className="needs-validation"
                        ref={formRef}
                        onValidSubmit={(e, v) => {
                         handleValidSubmit(e, v);
                        }}
                      >
                        <Row>
                         
                        <Col md="3">
                            <div className='mb-3'>
                              <Label>Offer Name</Label>
                              <AvField
                                name="offer_name"
                                placeholder="Offer Name"
                                type="text"
                                className="form-control"
                                 validate={{ required: { value: true } }}
                                 errorMessage="Enter Offer Name"
                                 onChange={handleChangeInput}
                                 value={offercodeObject?.offer_name}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className='mb-3'>
                              <Label>Offer Code</Label>
                              <AvField
                                name="offer_code"
                                placeholder="Offer Code"
                                type="text"
                                className="form-control"
                                 validate={{ required: { value: true } }}
                                 errorMessage="Enter Offer Code"
                                 onChange={handleChangeInput}
                                 value={offercodeObject?.offer_code}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className='mb-3'>
                              <Label>Valid From</Label>
                              <AvField
                                name="valid_from"
                                placeholder="Valid From"
                                type="date"
                                className="form-control"
                                 validate={{ required: { value: true } }}
                                 errorMessage="Enter Valid From"
                                  onChange={handleChangeInput}
                                 value={offercodeObject?.valid_from}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className='mb-3'>
                              <Label>Valid To</Label>
                              <AvField
                                name="valid_to"
                                placeholder="Valid To"
                                type="date"
                                className="form-control"
                                 validate={{ required: { value: true } }}
                                 errorMessage="Enter Valid From"
                                  onChange={handleChangeInput}
                                 value={offercodeObject?.valid_to}
                              />
                            </div>
                          </Col>
                            <Col md="3">
                            <div className='mb-3'>
                              <Label>Amount Type</Label>
                              <AvField
                                name="amount_Type"
                                placeholder="Amount Type"
                                type="Number"
                                className="form-control"
                                 validate={{ required: { value: true } }}
                                 errorMessage="Enter Amount Type"
                                 onChange={handleChangeInput}
                                 value={offercodeObject?.amount_Type}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className='mb-3'>
                              <Label>Minimum Purchase Amount</Label>
                              <AvField
                                name="minimum_purchace_amt"
                                placeholder="Minimum Purchase Amount"
                                type="Number"
                                className="form-control"
                                 validate={{ required: { value: true } }}
                                 errorMessage="Enter Minimum Purchase Amount"
                                 onChange={handleChangeInput}
                                 value={offercodeObject?.minimum_purchace_amt}
                              />
                            </div>
                          </Col>

                          <Col md="3">
                            <div className='mb-3'>
                              <Label>Maximum Offer Amount</Label>
                              <AvField
                                name="maximum_offer_amt"
                                placeholder="Maximum Offer Amount"
                                type="Number"
                                className="form-control"
                                 validate={{ required: { value: true } }}
                                 errorMessage="Enter Maximum Offer Amount"
                                 onChange={handleChangeInput}
                                 value={offercodeObject?.maximum_offer_amt}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className='mb-3'>
                              <Label>Offer Code Maximum Usage</Label>
                              <AvField
                                name="offer_code_maximum_usage"
                                placeholder="Offer Code Maximum Usage"
                                type="Number"
                                className="form-control"
                                 validate={{ required: { value: true } }}
                                 errorMessage="Enter Maximum Purchase Amount"
                                  onChange={handleChangeInput}
                                 value={offercodeObject?.offer_code_maximum_usage}
                              />
                            </div>
                          </Col>
                          <Col md="3">
                            <div className='mb-3'>
                              <Label>Offer Code Status</Label>
                              <AvField
                                name="offer_code_status"
                                placeholder="Offer Code Status"
                                type="Number"
                                className="form-control"
                                 validate={{ required: { value: true } }}
                                 errorMessage="Enter Maximum Purchase Amount"
                                  onChange={handleChangeInput}
                                 value={offercodeObject?.offer_code_status}
                              />
                            </div>
                          </Col>
                          <Col md="3" style={{ paddingTop: "4px" }}>
                            <div className='mt-4'>
                              {offercodeIdToBeUpdated ? ( 
                                 <Button className='mx-2'
                                  color='primary'
                                  type='submit'
                                  disabled={addingoffercodeObject ? true : false}
                                  >
                                  {addingoffercodeObject ? "Updating" : "Update"} 
                                </Button> 
                               )  
                               : 
                              <Button className='mx-2'
                                color='primary'
                                type='submit'
                               disabled={addingoffercodeObject ? true : false}
                               >
                             {addingoffercodeObject ? "Adding" : "Submit"}
                              </Button>
                               }  
                              <Button
                                color='danger'
                                type='reset'
                                onClick={() => reset()}
                              >
                                Reset
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
            </Row>
            <Row>
            <Col className='col-12'>
              <Card>
                <CardBody>
                  <MDBDataTable
                    responsive
                    id="AddOffercodetable"
                    bordered
                    data={data}
                    searching={true}
                    info={true}
                    disableRetreatAfterSorting={true}
                    entries={20} />
                </CardBody>
              </Card>
            </Col>
          </Row>  
        </div> 
     </div>
     </React.Fragment>
  )
}

export default Offercode