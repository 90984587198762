
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  // Modal,
  // Table,
} from "reactstrap";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Select from "react-select";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { CSVLink } from "react-csv";
import {
  getLocalbody,
  getDate,
  getFirstday,
  getArraySumByKey,
  formatMoney,
} from "../../../helpers/globalFunctions";
import "./BranchwiseBoxReport.scss";



const BranchwiseBoxReport = (props) => {
  
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const [dataToBeExported, setDataToBeExported] = useState([]);
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [total, setTotal] = useState(0);

  const [cargoTypeOptions, setCargoTypeOptions] = useState([]);
  const [selectedCargoType, setSelectedCargoType] = useState(null);

  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const [cargoAgentOptions, setCargoAgentOptions] = useState([]);
  const [selectedCargoAgent, setselectedCargoAgent] = useState(null);

  const [branchObject, setBranchObject] = useState({});

  const [order, setOrder] = useState([]);

  const [branchTable, setbranchTable] = useState([]);
  const [branchData, setbranchData] = useState(null);



  useEffect(() => {
    handleTableData();
    fetch_all_cargo_type();
    fetch_all_branch();
    fetch_all_country();
    fetch_all_cargo_agent();
  }, []);


  function fetch_all_cargo_type() {
    axios
      .get(`${API_URL}cargoType/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var cargo_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.cargo_Type,
              value: el._id,
            };
          });

        setCargoTypeOptions([
          {
            options: cargo_data,
          },
        ]);
      });
  }


  const fetch_all_branch = () => {
    axios
      .get(`${API_URL}reports/branch_box`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log("res", res);
        var branch_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.branch_name,
              value: el._id,
            };
          });

        setBranchOptions([
          {
            options: branch_data,
          },
        ]);
      });
  }



  const fetch_all_country = () => {
    axios
      .get(`${API_URL}newStates/countries`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var country_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };
          });
        setCountryOptions([
          {
            options: country_data,
          },
        ]);
      });
  };



  const fetch_all_cargo_agent = () => {
    axios
      .get(`${API_URL}cargoUser/agents`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        var agent_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: (el.firstName || '') + (el.lastName || ''),
              value: el._id,
            };
          });
        setCargoAgentOptions([
          {
            options: agent_data,
          },
        ]);
      });
  };



  function handleTableData(
    from_date = "",
    to_date = "",
    country_id = "",
    branch_id = "",
    cargo_id = "",
    cargo = "",
    country_from = "",
    country_to = "",
    branch = ""
  ) 

  {
    let userId = "";
    if (localStorage.getItem("authUser")) {
      var data = localStorage.getItem("authUser");
      const obj = JSON.parse(data);
      userId = obj._id;
    }

    if (from_date === "" && to_date === "") {
      from_date = from_date ? from_date : getFirstday(new Date());
      to_date = to_date ? to_date : getDate(new Date());
    }

    // url =
    //   url +
    //   "&from_date=" +
    //   from_date +
    //   "&to_date=" +
    //   to_date +
    //   "&country_id=" +
    //   country_id +
    //   "&country_from=" +
    //   country_from +
    //   "&country_to=" +
    //   country_to +
    //   "&cargo=" +
    //   cargo +
    //   "&branch_id=" +
    //   branch_id +
    //   "&cargo_id=" +
    //   cargo_id +
    //   "&branch=" +
    //   branch +

      axios
        .get(
          `${API_URL}reports/branch_box?from_date=${from_date}&to_date=${to_date}&cargo=${cargo}&country_from=${country_from}&country_to=${country_to}&branch=${branch}` ,
          {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          let result = res.data.data;
          var booked=
                 result &&
                 result.reduce((acc,curr) => {
                  acc = acc + curr.booked;
                  return acc;
                 }, 0)
          setOrder(result);


          let dataToBeExported = [];

          result &&
            result.map((item, index) => {
              console.log("item", item);
              let exportItem = {};
              item.id = index + 1;


              item.date = moment(item?._id).format("DD-MM-YYYY");

              //export data
              exportItem.id = item.id;
              exportItem.Branch = item.branch_name;
              exportItem.Booked = item.booked;
              exportItem.Dispatched = item.dispatched;
              exportItem.Incustom = item.incustoms;
              exportItem.Out_For_Delivery = item.out_for_delivery;
              exportItem.Delivered = item.delivered;

              dataToBeExported.push(exportItem);
            });

          setDataToBeExported([
            ...dataToBeExported,
            {
              Branch: "Total",

              Booked:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.booked;
                return acc;
              }, 0),

              Dispatched:
              result&&
              result.reduce((acc, curr) => {
                acc = acc + curr.dispatched;
                return acc;
              }, 0),

              Incustom:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.incustoms;
                return acc;
              }, 0),

              Out_For_Delivery:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.out_for_delivery;
                return acc;
              }, 0),

              Delivered:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.delivered;
                return acc;
              }, 0),

            }
          ]);

        });

    setbranchTable(branchData)

  }



  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Branch",
        field: "branch_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Booked",
        field: "booked",
        sort: "asc",
        width: 200,
      },
      {
        label: "Dispatched",
        field: "dispatched",
        sort: "asc",
        width: 200,
      },
      {
        label: "Incustom",
        field: "incustoms",
        sort: "asc",
        width: 200,
      },
      {
        label: "Out For Delivery",
        field: "out_for_delivery",
        sort: "asc",
        width: 200,
      },
      {
        label: "Delivered",
        field: "delivered",
        sort: "asc",
        width: 200,
      },

    ],
    rows: [
      ...order,
      {

        branch_name: <span style={{ fontWeight: "1000", float: "right" }}>Total</span>,
        booked: (
          <span style={{ fontWeight: "1000" }}>
            {order &&
              order.reduce((acc, curr) => {
                acc = acc + curr.booked;
                return acc;
              }, 0)
            }
          </span>
        ),

        dispatched: (
          <span style={{ fontWeight: "1000" }}>
            {order &&
              order.reduce((acc, curr) => {
                acc = acc + curr.dispatched;
                return acc;
              }, 0)
            }
          </span>
        ),

        incustoms: (
          <span style={{ fontWeight: "1000" }}>
            {order &&
              order.reduce((acc, curr) => {
                acc = acc + curr.incustoms;
                return acc;
              }, 0)
            }
          </span>
        ),

        out_for_delivery: (
          <span style={{ fontWeight: "1000" }}>
            {order &&
              order.reduce((acc, curr) => {
                acc = acc + curr.out_for_delivery;
                return acc;
              }, 0)
            }
          </span>
        ),

        delivered: (
          <span style={{ fontWeight: "1000" }}>
            {order &&
              order.reduce((acc, curr) => {
                acc = acc + curr.delivered;
                return acc;
              }, 0)
            }
          </span>
        )
      }
    ],
  };


  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }

  };

  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "cargo":
        setSelectedCargoType(selected);
        setBranchObject({
          ...branchObject,
        });
        break;

      case "branch":
        setSelectedBranch(selected);
        setBranchObject({
          ...branchObject,
        });
        break;

      case "country":
        setSelectedCountry(selected);
        setBranchObject({
          ...branchObject,
        });
        break;

      case "agent":
        setselectedCargoAgent(selected);
        setBranchObject({
          ...branchObject,
        });
        break;


      default:
        break;

    }
  };


  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedCountry(null);
    setSelectedCargoType(null);
    setselectedCargoAgent(null);
    setSelectedBranch(null);
    handleTableData();
  };



  const handleSearch = () => {
    let country_id = searchData?.country_id ? searchData.country_id : "";
    let branch_id = searchData?.branch_id ? searchData.branch_id : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    let branch_name = searchData?.branch_name ? searchData.branch_name : "";
    let cargo = searchData?.cargo ? searchData.cargo : "";
    let agent = searchData?.agent ? searchData.agent : "";


    handleTableData(date1, date2, branch_id, country_id, branch_name, cargo, agent);
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Branch wise Box Report" />
          <Row>
            <Col className="col-12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                          onChange={handleDate}
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label>Cargo Type</Label>
                        <Select
                          name="cargo"
                          value={selectedCargoType}
                          onChange={(selected) => {
                            handleSelectChange(selected, "cargo");
                          }}
                          options={cargoTypeOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label>Branch</Label>
                        <Select
                          name="branch"
                          value={selectedBranch}
                          onChange={(selected) => {
                            handleSelectChange(selected, "branch");
                          }}
                          options={branchOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label>Country</Label>
                        <Select
                          name="country_id"
                          value={selectedCountry}
                          onChange={(selected) => {
                            handleSelectChange(selected, "country");
                          }}
                          options={countryOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                    <Col md="2">
                      <div className="mb-3">
                        <Label>Agent</Label>
                        <Select
                          name="agent"
                          value={selectedCargoAgent}
                          onChange={(selected) => {
                            handleSelectChange(selected, "agent");
                          }}
                          options={cargoAgentOptions}
                          classNamePrefix="select2-selection"
                        />
                      </div>
                    </Col>

                  </Row>
                  <Row>
                    <Col md="1" style={{ paddingTop: "5px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="primary"
                          type="submit"
                          onClick={() => handleSearch()}
                        >
                          Search
                        </Button>
                      </div>
                    </Col>

                    <Col md="1" style={{ paddingTop: "5px" }}>
                      <div className="mt-2 mb-3">
                        <Button color="success" type="submit">
                          <CSVLink
                            data={dataToBeExported}
                            filename={
                              "Branch_wise_box_shipment_report_" + getDate(new Date()) + ".xls"
                            }
                            style={{ color: "white" }}
                          >
                            Export
                          </CSVLink>
                        </Button>
                      </div>
                    </Col>

                    <Col md="1" style={{ paddingTop: "5px" }}>
                      <div className="mt-2 mb-3">
                        <Button
                          color="danger"
                          type="reset"
                          onClick={() => reset()}
                        >
                          Reset
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <MDBDataTable
                    id="BranchTableId"
                    responsive
                    bordered
                    data={data}
                    info={true}
                    searching={true}
                    disableRetreatAfterSorting={true}
                    entries={20}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BranchwiseBoxReport;