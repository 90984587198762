/* WARDS */
export const GET_WARDS = "GET_WARDS";
export const GET_WARDS_SUCCESS = "GET_WARDS_SUCCESS";
export const GET_WARDS_FAIL = "GET_WARDS_FAIL";

export const GET_WARD = "GET_WARD";
export const GET_WARD_SUCCESS = "GET_WARD_SUCCESS";
export const GET_WARD_FAIL = "GET_WARD_FAIL";

export const ADD_WARD = "ADD_WARD";
export const ADD_WARD_SUCCESS = "ADD_WARD_SUCCESS";
export const ADD_WARD_FAIL = "ADD_WARD_FAIL";

export const UPDATE_WARD = "UPDATE_WARD";
export const UPDATE_WARD_SUCCESS = "UPDATE_WARD_SUCCESS";
export const UPDATE_WARD_FAIL = "UPDATE_WARD_FAIL";

export const DELETE_WARD = "DELETE_WARD";
export const DELETE_WARD_SUCCESS = "DELETE_WARD_SUCCESS";
export const DELETE_WARD_FAIL = "DELETE_WARD_FAIL";


export const GET_WARD_OPTIONS = "GET_WARD_OPTIONS";
export const GET_WARD_OPTIONS_SUCCESS = "GET_WARD_OPTIONS_SUCCESS";
export const GET_WARD_OPTIONS_FAIL = "GET_WARD_OPTIONS_FAIL";

