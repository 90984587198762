import { takeEvery, put, call, takeLatest } from "redux-saga/effects";
import {
    GET_INCOME_EXPENSE,
    GET_INCOME_EXPENSE_ACC_TYPE,
    ADD_INCOME_EXPENSE
} from "./actionTypes";
import {
    getIncomeExpenseAccTypeSuccess,
    getIncomeExpenseAccTypeFail,
    getAllIncomeExpensesSuccess,
    getAllIncomeExpensesFail,
    addIncomeExpenseSuccess,
    addIncomeExpenseFail
} from "./actions";
import {
    getIncomeExpenseAccType,
    getAllIncomeExpenses,
    addIncomeExpense
} from "../../helpers/backend_helper";

function* fetchIncomeExpenses({localbody_id: localbody_id}) {
    try {
        const response = yield call(getAllIncomeExpenses,localbody_id);
        yield put(getAllIncomeExpensesSuccess(response));
    } catch (error) {
        yield put(getAllIncomeExpensesFail(error));
    }
}
function* fetchIncomeExpenseAccType() {
    try {
        const response = yield call(getIncomeExpenseAccType);
        yield put(getIncomeExpenseAccTypeSuccess(response));
    } catch (error) {
        yield put(getIncomeExpenseAccTypeFail(error));
    }
}
function* onAddIncomeExpense({ payload: data }) {
    try {
        const response = yield call(addIncomeExpense, data);
        yield put(addIncomeExpenseSuccess(response));
    } catch (error) {
        yield put(addIncomeExpenseFail(error.response));
    }
}
function* incomeExpenseSaga() {
    yield takeEvery(GET_INCOME_EXPENSE, fetchIncomeExpenses);
    yield takeEvery(ADD_INCOME_EXPENSE, onAddIncomeExpense);
    yield takeEvery(GET_INCOME_EXPENSE_ACC_TYPE, fetchIncomeExpenseAccType);
}

export default incomeExpenseSaga;