import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Button, Label, } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Select from "react-select";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import toastr from "toastr";
import { MDBDataTable } from "mdbreact";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import "./user.scss";


//import { usePreviousProps } from "@mui/utils";
const AddcargoUser = (props) => {
  const [userObject, setUserObject] = useState({});
  const [details, setDetails] = useState([]);

  const [privilageName, setprivilageName] = useState([]);
  const [selectedprivilege, setSelectedPrivilege] = useState(null);
  const [privilegeOptions, setPrivilegeOptions] = useState([]);
  const [branchOptions, setbranchOptions] = useState([]);
  const [userIdToBeDeleted, setUserIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [userIdToBeUpdated, setUserIdToBeUpdated] = useState(null);
  const [userValue, setuserValue] = useState("");
  const [passwordObject, setPasswordObject] = useState({
    password: "",
    confirmPassword: "",
  });

  const [branchName, setbranchName] = useState([]);
  const [selectedbranch, setselectedbranch] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedModule, setSelectedModule] = useState(null);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [testDetails, settestDetails] = useState({});//modelview
  const [showCompany, setShowCompany] = useState(false);

  const [uploadProgress, setUploadProgress] = useState();
  const {
    districts,
    addingUser,

    error,
  } = useSelector((state) => state.districts);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();
  let history = useHistory();

  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);

  function togglePasswordVisibility() {
    setShowPassword(!showPassword);

  }

  function toggleNewPasswordVisibility() {
    setShowNewPassword(!showNewPassword);

  }

  function toggleConfirmPasswordVisibility() {
    setConfirmPassword(!confirmPassword);

  }


  useEffect(() => {
    // fetch_modules();
    fetch_privilege();
    handleTableData();
    fetch_branch();
  }, []);

  const [value, setValue] = React.useState(new Date("2014-08-18T21:11:54"));
  const [show, setShow] = useState(false);
  const handleCloseModal = () => setShow(false);
  const handleShowModal = () => setShow(true);
  const [open, setOpen] = React.useState(false);

  const [errors, setErrors] = useState({
    privillegename: "",
    branchname: "",
  });

  const handleClickOpen = (element) => {
    axios
      .get(`${API_URL}cargoUser/singlelist?user_id=` + element._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        let result = res.data.data;

        let privilage_value = result?.privilage || {};
        result.privilage_value = privilage_value.privilege_name;

        let branch_value = result?.branch || {};
        result.branch_value = branch_value.branch_name;

        let district_value = result?.district || {};
        result.district_value = district_value.district_name;

        result.date = moment(result.date).format("DD-MM-YYYY");

        if (result?.time)
          result.time = moment(result.time, "HHmmss").format("hh:mm a");
        else result.time = "";

        result.up_date = moment(result.up_date).format("DD-MM-YYYY");

        if (result?.up_time)
          result.up_time = moment(result.up_time, "HHmmss").format("hh:mm a");
        else result.up_time = "";

        let addedBy = result.added_by || {};
        result.addedBy = addedBy?.firstName;

        let updatedBy = result.updated_by || {};
        result.updatedBy = updatedBy?.firstName;

        settestDetails(result);
        setShowPopup(true);
      });

  };


  function updateStatus(element) {
    axios
      .get(`${API_URL}cargoUser/block?user_id=` + element._id, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        if (res.data.status === 200) {
          if (res.data.data.status === 0) {
            toastr.success("user unblocked successfully")
          } else toastr.success("user blocked successfully")
          handleTableData()
        } else {
          toastr.error("Faild")
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      })
  }


   const handleTableData = () => {
    var url = `${API_URL}cargoUser/list`;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })

      .then((res) => {

        let result = res.data.data;
        let categoryData = [];
        result &&
          result.map((element, index) => {
            //  if (element?.privilege_name=="Agent"){
            //  setShowResults(true)
            //  }
            element.id = index + 1;

            element.privilage1 = element?.privilage?.privilege_name;
            element.branch1 = element?.branch?.branch_name;

            // if (element?.lastName)
            // element.empName = element?.firstName + " " + element.lastName;
            // else element.empName = element?.firstName;

            element.action = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="fas fa-key"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    setShowModal(true);
                    setUserIdToBeUpdated(element._id);
                  }}
                ></i>
                <i
                  className="fas fa-eye"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    // history.push("/addUser/userId");
                    history.push("/addUser/" + element.userId);
                    //handleClickOpen(element);
                  }}
                ></i>
                <i
                  className="far fa-edit"
                  style={{
                    fontSize: "0.9em",
                    cursor: "pointer",
                    marginLeft: "0.1em",
                    marginRight: "0.4em",
                  }}
                  onClick={() => {
                    preUpdateUser(element);
                    toTop();
                  }}
                ></i>
                <i
                  className="far fa-trash-alt"
                  style={{ fontSize: "0.9em", cursor: "pointer" }}
                  onClick={() => {
                    setUserIdToBeDeleted(element._id);
                    setConfirmDeleteAlert(true);
                  }}
                ></i>
              </div>
            );
            if (element?.status === 0) {
              element.status1 = (
                <>
                  <LockOpenIcon
                    style={{
                      color: "green",
                      fontSize: "1.2em",
                      //  fontSize: "18px",
                      cursor: "pointer",
                    }}
                    onClick={() => updateStatus(element)}
                  />
                </>
              );
            } else if (element?.status === 2) {
              element.status1 = (
                <>
                  <LockOpenIcon
                    style={{
                      color: "red",
                      fontSize: "1.2em",
                      //  fontSize: "18px",
                      cursor: "pointer",
                    }}
                    onClick={() => updateStatus(element)}
                  />
                </>
              );
            }
            if (element.app_user === 1) {
              element.info1 = (
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <i
                    className="uil-android"
                    style={{
                      //  fontSize: "1.5em",
                      //  cursor: "pointer",
                      //  marginLeft: "0.2em",
                      //  marginRight: "0.5em",
                      fontSize: "1.2em",
                      cursor: "pointer",
                      marginLeft: "0.1em",
                      marginRight: "0.4em",
                      color: "green",
                    }}
                  // onClick={() => {
                  //   handleClickOpenForm(element);
                  // }}
                  ></i>
                </div>
              );
            }
            if (element?.lastName) {
              element.name = element?.firstName + " " + element?.lastName;
            } else {
              element.name = element?.firstName;
            }

            categoryData.push(element)
          });
        setDetails(categoryData);
      });

  }
  const preUpdateUser = (user) => {
    // setUserIdToBeUpdated(element._id);
    // setUserObject({...element,password : null});
    // };
    // let preUpdateCategoryPassword = (element) => {
    // setUserIdToBeUpdated(element._id);
    // setShowModal(true);
    setUserIdToBeUpdated(user?._id);
    axios
      .get(`${API_URL}cargoUser/singlelist?user_id=` + user?._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;

        let branchData = result?.branch || {};
        let privilegeData = result?.privilage || {};
        // let moduleData = result?.module || {};
        // let departmentData = result?.department || {};
        // let productData = result?.product || {};
        // let designationData = result?.user_desig || {};
        // if (companyData?.company_name) {
        // setSelectedCompany({
        // label: companyData?.company_name,
        // value: companyData?._id,
        // });
        // } else setSelectedCompany(null);

        if (privilegeData?.privilege_name) {
          setSelectedPrivilege({
            label: privilegeData?.privilege_name,
            value: privilegeData?._id,
          });
          fetch_modules(privilegeData?._id);

          if (privilegeData.privilege_name == "Agent") { setShowCompany(true) }
          else (setShowCompany(false))
        } else setSelectedPrivilege(null);

        if (branchData?.branch_name) {
          setselectedbranch({
            label: branchData?.branch_name,
            value: branchData?._id,
          });
        } else setselectedbranch(null);

        // if (departmentData?.depart_Name) {
        // setSelectedDepartment({
        // label: departmentData?.depart_Name,
        // value: departmentData?._id,
        // });
        // } else setSelectedDepartment(null);

        // if (productData?.product_name) {
        // setSelectedProduct({
        // label: productData?.product_name,
        // value: productData?._id,
        // });
        // } else setSelectedProduct(null);

        // if (designationData?.desig_name) {
        // setSelectedDesignation({
        // label: designationData?.desig_name,
        // value: designationData?._id,
        // });
        // } else setSelectedDesignation(null);

        result.user_id = result?._id;
        setUserObject(result);
      });
  };


  const fetch_privilege = () => {
    axios
      .get(`${API_URL}privilage/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let privilege_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.privilege_name,
              value: el._id,
            };
          });
        setPrivilegeOptions([
          {
            options: privilege_data,
          },
        ]);
      });
  };
  const fetch_modules = (id) => {
    axios
      .get(`${API_URL}privilage/module_options?privilage_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let data = res.data.data[0]?.alloted_modules;
        let module_data =
          data &&
          data.map((el) => {
            return {
              label: el?.moduleName,
              value: el?._id,
            };
          });
        setModuleOptions([
          {
            options: module_data,
          },
        ]);
      });
  };
  const fetch_branch = () => {
    axios
      .get(`${API_URL}cargo_Branch/names`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let branch_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el?.branch_name,
              value: el?._id,
            };
          });
        setbranchOptions([
          {
            options: branch_data,
          },
        ]);
      });
  };
  let closeModal = () => {
    setShowModal(false);
    setUserIdToBeUpdated(null);
    setPasswordObject({
      password: "",
      confirmPassword: "",
    });
  };
  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "User ID",
        field: "userId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },

      //  {
      //  label: "First Name",
      //  field: "firstName",
      //  sort: "asc",
      //  width: 150,
      //  },

      //  {
      //  label: "Last Name",
      //  field: "lastName",
      //  sort: "asc",
      //  width: 150,
      //  },

      {
        label: "Mobile",
        field: "mobile",
        sort: "asc",
        width: 150,
      },
      //  {
      //     label: "Email",
      //     field: "email",
      //     sort: "asc",
      //     width: 150,
      //     },
      {
        label: "Privilege",
        field: "privilage1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Branch",
        field: "branch1",
        sort: "asc",
        width: 150,
      },
      // {
      // label: "Module",
      // field: "module_name",
      // sort: "asc",
      // width: 150,
      // },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 150,
      },
      //  {
      //  label: "Info",
      //  field: "info1",
      //  sort: "asc",
      //  width: 150,
      //  },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: details,
  };

  const customStyles = {
    lineHeight: "1.8",
  };

  const handleInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setuserValue(value);
    setUserObject({
      ...userObject,
      [e.target.name]: e.target.value,
    });
  };

  const handleValidSubmit = () => {

    if (selectedbranch === null || selectedprivilege === null) {
      let errorVal = errors;
      if (selectedprivilege === null)
        errorVal.privillegename = "Please select privillege";
      if (selectedbranch === null)
        errorVal.branchname = "Please select branch"
      setErrors({ ...errorVal })
    } else {
      if (userIdToBeUpdated) {
        axios
          .put(`${API_URL}cargoUser/update?user_id`, userObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("User updated successfully");
              handleTableData();
              // setUserObject({});
              formRef.current.reset();
              setUserIdToBeUpdated(null);
              setUserObject({
                password: "",
              });
              setselectedbranch(null);
              setSelectedPrivilege(null);
              setShowCompany(false);
              setErrors({
                privillegename: "",
                branchname: "",
              })
              // setSelectedModule(null);
              // setModuleOptions([]);
              // setSelectedDepartment(null);
              // setSelectedDesignation(null);
              // setSelectedProduct(null);
              // setPasswordObject({});
            } else toastr.error("Failed to update user");
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}cargoUser/adduser`, userObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.success === true) {
              toastr.success("User created successfully");
              handleTableData();
              setShowCompany(false);
              setuserValue("")
              // setUserObject({});
              formRef.current.reset();
              setUserObject({
                password: "",
              });
              setErrors({
                privillegename: "",
                branchname: "",
              })
              setselectedbranch(null);
              setSelectedPrivilege(null);
              // setSelectedModule(null);
              // setModuleOptions([]);
              // setSelectedDepartment(null);
              // setSelectedDesignation(null);
              // setSelectedProduct(null);
            } else toastr.error("Failed to create user");
          })
          .catch((err) => {
            console.log(err)
            toastr.error(err.response.data.message);
            return;
          });
      }

    }
  }


  function handleChangePassword(e) {
    let name = e.target.name;
    let value = e.target.value;
    setPasswordObject({ ...passwordObject, [name]: value });
  }


  const handleValidSubmitPassword = () => {
    if (passwordObject.password == passwordObject.confirmPassword) {
      let item = {
        user_id: userIdToBeUpdated,
        password: passwordObject?.password,
      };
      axios
        .post(`${API_URL}cargoUser/reset_password`, item, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.success === true) {
            toastr.success(res.data.message);
            setPasswordObject({
              password: "",
              confirmPassword: "",
            });
            closeModal();
            formRef.current.reset();
          } else {
            toastr.error(res.data.message);
            return;
          }
        });
    } else {
      toastr.error("Passwords are not matching");
    }
  };
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("user_image", e.target.files[0]);
    axios
      .post(`${API_URL}cargoUser/image/`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setUserObject({
            ...userObject,
            [`user_image`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };

  const deleteImage = () => {
    setUserObject({
      ...userObject,
      [`user_image`]: "",
    });
  };

  // const onClick = () => setShowResults(true)

  const handleSelectChange = (selected, name) => {
    switch (name) {
      // case "branch_name":
      // setSelectedBranch(selected);
      // setBranchObject({
      // ...branchObject,
      // branch_name: selected.label,
      // });
      // break;
      case "privilage":
        setSelectedPrivilege(selected);
        setSelectedModule(null);
        setUserObject({
          ...userObject,
          privilage: selected.value,
          module: "",
        });

        if (selected.label == "Agent") { setShowCompany(true) }
        else (setShowCompany(false))
        // fetch_modules(selected?.value);
        break;
      case "branch":
        setselectedbranch(selected);
        fetch_branch(selected);
        setUserObject({
          ...userObject,
          branch: selected.value,
        });
        break;


      default:
        break;
    }
  };


  const reset = () => {
    formRef.current.reset();
    setUserObject({});
    setShowCompany(false)
    setUserIdToBeUpdated(null);
    // handleTableData();
    setSelectedPrivilege(null);
    setselectedbranch(null);
    setErrors({
      privillegename: "",
      branchname: "",
    })
  };

  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}cargoUser` + "/" + userIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("User deleted successfully");

                  if (userObject && userObject?._id === userIdToBeDeleted) {
                    formRef.current.reset();
                    setUserIdToBeUpdated(null);
                    setUserObject({
                      password: "",
                    });
                    setselectedbranch(null);
                    setSelectedPrivilege(null);
                    // setSelectedModule(null);
                    // setModuleOptions([]);
                    // setSelectedDepartment(null);
                    // setSelectedDesignation(null);
                    // setSelectedProduct(null);
                    setPasswordObject({});
                  }
                  handleTableData();
                } else {
                  toastr.error("Failed to delete user");
                  return;
                }
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}


      <Modal show={showPopup} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">User Details</h5>
          <button
            type="button"
            onClick={() => {
              setShowPopup(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <table
              id="product_available_day"
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Added By :
                </td>
                <td>{testDetails?.addedBy}</td>
                <td>Updated By :</td>
                <td style={{ textAlign: "left" }}>{testDetails?.updatedBy}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px" }}>
                  FirstName :
                </td>
                <td>{testDetails?.firstName}</td>
                <td>LastName :</td>
                <td style={{ textAlign: "left" }}>
                  {testDetails?.lastName}
                </td>
              </tr>
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Email :
                </td>
                <td>{testDetails?.email}</td>
                <td> Mobile:</td>
                <td style={{ textAlign: "left" }}>
                  {testDetails?.mobile}
                </td>
              </tr>
              <tr>
                <td style={{ width: "184px", padding: "10px" }}>
                  Privilege :
                </td>
                <td>{testDetails?.privilage_value}</td>
                <td> Branch:</td>
                <td style={{ textAlign: "left" }}>
                  {testDetails?.branch_value}
                </td>
              </tr>
              <tr>
                {/* <td style={{ width: "184px", padding: "10px" }}>
 User ID :
 </td>
 <td>{testDetails?.userId}</td>
 {/* <td> Mobile:</td>
 <td style={{ textAlign: "left" }}>
 {testDetails?.mobile}
 </td> */}
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Date :</td>
                <td>{testDetails?.date}</td>
                <td>Time :</td>
                <td style={{ textAlign: "left" }}>{testDetails?.time}</td>
              </tr>

              <tr hidden={!testDetails?.updatedBy}>
                <td style={{ padding: "10px", textAlign: "left" }}>
                  Updated Date :
                </td>
                <td>{testDetails?.up_date}</td>
                <td>Updated Time :</td>
                <td style={{ textAlign: "left" }}>{testDetails?.up_time}</td>
              </tr>

              <tr>
                <td style={{ padding: "10px" }}>User Id :</td>
                <td colSpan={3} style={{ textAlign: "left" }}>{testDetails?.userId}</td>
              </tr>


              <tr hidden={!testDetails?.user_image}>
                <td style={{ padding: "10px" }}>Image :</td>
                <td style={{ textAlign: "left" }}>
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      paddingLeft: "0px",
                      cursor: "pointer"
                    }}
                    src={`${API_URL}uploads/cargoUser_images/${testDetails?.user_image}`}
                    onClick={() => window.open(`${API_URL}uploads/cargoUser_images/${testDetails.user_image}`, "_blank")}
                  ></img>
                </td>
              </tr>

            </table>
          </div>
        </AvForm>
      </Modal>





      <Modal
        show={showModal}
        toggle={() => {
          closeModal();
        }}
        centered={true}
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Reset Password</h5>
          <button
            type="button"
            onClick={() => {
              closeModal();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm
          className="needs-validation"
          onValidSubmit={() => {
            handleValidSubmitPassword();
          }}
        >
          <div className="modal-body">
            <Row>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Password</Label>
                  <AvField
                    name="password"
                    placeholder="Password"
                    type={showNewPassword ? "text" : "password"}
                    errorMessage=" Please provide a valid password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.password}
                    onChange={handleChangePassword}
                  />
                  <i
                    className={`fas fa-eye${showNewPassword ? "-slash" : ""}`}
                    style={{
                      position: "absolute",
                      right: "23px",
                      top: "55%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={toggleNewPasswordVisibility} // Update the function name here
                  ></i>
                </div>
              </Col>
              <Col md="12">
                <div className="mb-3">
                  <Label htmlFor="validationCustom05">Confirm Password</Label>
                  <AvField
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    type={confirmPassword ? "text" : "password"}
                    errorMessage=" Please confirm the password"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom05"
                    value={passwordObject.confirmPassword}
                    onChange={handleChangePassword}
                  />
                  <i
                    className={`fas fa-eye${confirmPassword ? "-slash" : ""}`}
                    style={{
                      position: "absolute",
                      right: "23px",
                      top: "55%",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={toggleConfirmPasswordVisibility} // Update the function name here
                  ></i>
                </div>
              </Col>
            </Row>
          </div>
          <div className="modal-footer">
            <button
              className="btn btn-dark"
              style={{ marginRight: "1rem" }}
              onClick={closeModal}
            >
              Close
            </button>
            <button className="btn btn-primary" type="submit">
              Confirm
            </button>
          </div>
        </AvForm>
      </Modal>

      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Manage User" />
          <Row>
            <Col xl="12">
              {/* <Card>
 <CardBody> */}
              <AvForm
                ref={formRef}
                className="needs-validation"
                onValidSubmit={() => {
                  handleValidSubmit();
                }}
              >
                <Row>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">First Name</Label>
                      <AvField
                        style={customStyles}
                        name="firstName"
                        placeholder="First Name"
                        type="text"
                        errorMessage="Enter First Name"
                        className="form-control"
                        validate={{
                          required: { value: true, errorMessage: "Enter FirstName" },
                          pattern: {
                            value: /^[a-zA-Z]+/,
                            errorMessage: "Enter a valid FirstName"
                          }
                        }}
                        onChange={handleInputChange}
                        value={userObject?.firstName}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Last Name</Label>
                      <AvField
                        style={customStyles}
                        name="lastName"
                        placeholder="Last Name"
                        type="text"
                        errorMessage="Enter Last Name"
                        className="form-control"
                        validate={{
                          required: { value: true, errorMessage: "Enter LastName" },
                          pattern: {
                            value: /^[a-zA-Z]+/,
                            errorMessage: "Enter a valid LastName"
                          }
                        }}
                        onChange={handleInputChange}
                        value={userObject?.lastName}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Email</Label>
                      <AvField
                        style={customStyles}
                        name="email"
                        placeholder="Email"
                        type="email"
                        errorMessage="Enter Email"
                        className="form-control"
                        validate={{
                          required: { value: true, errorMessage: "Enter Email ID" },
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            errorMessage: "Enter a valid Email ID"
                          }
                        }}
                        id="validationCustom02"
                        onChange={handleInputChange}
                        value={userObject?.email}
                      />
                    </div>
                  </Col>

                  {/* <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">WhatsApp Number</Label>
                      <AvField
                        style={customStyles}
                        name="whatsapp_number"
                        placeholder="WhatsApp Number"
                        type="number"
                        // errorMessage="Please provide a valid WhatsApp Number."
                        className="form-control"
                        validate={{
                          required: { value: true, errorMessage: "Enter Whatsapp Number" },
                          pattern: {
                            value: /^\d{10}$/,
                            errorMessage: "Enter a valid Number"
                          }
                        }}
                        id="validationCustom02"
                        minLength={10}
                        maxLength={10}
                        onChange={handleInputChange}
                        value={userObject?.whatsapp}
                      />
                    </div>
                  </Col>
 */}

                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Mobile</Label>
                      <AvField
                        style={customStyles}
                        name="mobile"
                        placeholder="Mobile"
                        type="number"
                        // errorMessage="Please provide a valid mobile."
                        className="form-control"
                        validate={{
                          required: { value: true, errorMessage: "Enter Mobile" },
                          pattern: {
                            value: /^\d{10}$/,
                            errorMessage: "Enter a valid Number"
                          }
                        }}
                        id="validationCustom02"
                        minLength={10}
                        maxLength={10}
                        onChange={handleInputChange}
                        value={userObject?.mobile}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Privilage</Label>
                      <Select
                        name="privilage"
                        errorMessage="Select privilage"
                        value={selectedprivilege}
                        onChange={(selected) => {
                          handleSelectChange(selected, "privilage");
                        }}
                        options={privilegeOptions}
                        classNamePrefix="select2-selection"
                      />
                      <p className="text-danger" style={{ fontSize: "11px" }}>
                        {selectedprivilege === null ? errors.privillegename : ""}
                      </p>
                    </div>
                  </Col>
                  {showCompany === true ? (
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom02">Company Name</Label>
                        <AvField
                          style={customStyles}
                          name="company"
                          placeholder="Company Name"
                          type="text"
                          errorMessage="Enter Company Name."
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom02"
                          onChange={handleInputChange}
                          value={userObject?.company}
                        />
                      </div>
                    </Col>
                  ) : null}
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom02">Branch</Label>
                      <Select
                        name="branch"
                        errorMessage="Select branch"
                        value={selectedbranch}
                        onChange={(selected) => {
                          handleSelectChange(selected, "branch");
                        }}
                        options={branchOptions}
                        classNamePrefix="select2-selection"
                      />
                      <p className="text-danger" style={{ fontSize: "11px" }}>
                        {selectedbranch === null ? errors.branchname : ""}
                      </p>
                    </div>
                  </Col>
                  {userIdToBeUpdated ? null : (
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom02">Username</Label>
                        <AvField
                          style={customStyles}
                          name="username"
                          placeholder="Username"
                          type="text"
                          errorMessage="Enter Username"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom02"
                          onChange={handleInputChange}
                          value={userObject?.username}
                        />
                      </div>
                    </Col>
                  )}
                  {userIdToBeUpdated ? null : (
                    <Col md="3">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom02">Password</Label>
                        <AvField
                          style={customStyles}
                          name="password"
                          placeholder="Password"
                          type={showPassword ? "text" : "password"}
                          errorMessage="Enter Password"
                          className="form-control"
                          validate={{ required: { value: true } }}
                          id="validationCustom02"
                          onChange={handleInputChange}
                          value={userObject?.password}
                        />
                        <i
                          className={`fas fa-eye${showPassword ? "-slash" : ""}`}
                          style={{
                            position: "absolute",
                            right: "23px",
                            top: "55%",
                            transform: "translateY(-50%)",
                            cursor: "pointer",
                          }}
                          onClick={togglePasswordVisibility} // Update the function name here
                        ></i>

                      </div>
                    </Col>
                  )}
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">
                        Upload Photo
                      </Label>
                      {userObject?.user_image ? (
                        <div div className="img-wraps">
                          {userObject.user_image &&
                            userObject.user_image.startsWith(
                              "http"
                            ) ? (
                            <img
                              alt=""
                              width="150"
                              height="150"
                              src={`${userObject.user_image}`}
                            />
                          ) : (
                            <img
                              alt=""
                              width="150"
                              height="150"
                              src={`${API_URL}/uploads/cargoUser_images/${userObject?.user_image}`}
                            />
                          )}
                          <button
                            className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                            onClick={deleteImage}
                            style={{ width: "150px" }}
                          >
                            Delete
                          </button>
                        </div>
                      ) : (
                        <AvField
                          name="image"
                          type="file"
                          className="form-control"
                          id="validationCustom03"
                          onChange={uploadImage}
                          rows="1"
                        />
                      )}
                    </div>
                  </Col>

                  <Col
                    md="1"
                    className="mt-4"
                    style={{ paddingTop: "4px" }}
                  >
                    <div className="mb-2">
                      {userIdToBeUpdated ? (
                        <Button
                          color="primary"
                          type="submit"
                          style={{ marginRight: "5%" }}
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          type="submit"
                          style={{ marginRight: "5%" }}
                        >
                          Submit
                        </Button>
                      )}
                    </div>
                  </Col>
                  <Col md="1" className="mt-4" style={{ paddingTop: "4px", marginLeft: "-11px" }}>
                    <Button
                      color="danger"
                      type="reset"
                      onClick={() => reset()}
                    >
                      {" "}
                      Reset
                    </Button>
                  </Col>
                </Row>
              </AvForm>
              {/* </CardBody>
 </Card> */}
            </Col>

          </Row>
          <Row>

            <Col className="col-12">
              {/* <Card>
 <CardBody> */}
              <MDBDataTable
                responsive
                bordered
                id="userTablesId"
                data={data}
                searching={true}
                info={true}
                disableRetreatAfterSorting={true}
                entries={20}
              />
              {/* </CardBody>
 </Card> */}
            </Col>
          </Row>
        </div>

      </div>
    </React.Fragment>

  )
}

export default AddcargoUser;
