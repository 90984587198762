import {
    GET_INCOME_EXPENSE_ACC_TYPE,
    GET_INCOME_EXPENSE_ACC_TYPE_SUCCESS,
    GET_INCOME_EXPENSE_ACC_TYPE_FAIL,
    GET_INCOME_EXPENSE,
    GET_INCOME_EXPENSE_SUCCESS,
    GET_INCOME_EXPENSE_FAIL,
    ADD_INCOME_EXPENSE,
    ADD_INCOME_EXPENSE_SUCCESS,
    ADD_INCOME_EXPENSE_FAIL
} from "./actionTypes";

export const getIncomeExpenseAccType = () => ({
    type: GET_INCOME_EXPENSE_ACC_TYPE,
});

export const getIncomeExpenseAccTypeSuccess = (data) => ({
    type: GET_INCOME_EXPENSE_ACC_TYPE_SUCCESS,
    payload: data,
});

export const getIncomeExpenseAccTypeFail = (error) => ({
    type: GET_INCOME_EXPENSE_ACC_TYPE_FAIL,
    payload: error,
});

export const getAllIncomeExpenses = (localbody_id) => ({
    type: GET_INCOME_EXPENSE,
    localbody_id: localbody_id,
});

export const getAllIncomeExpensesSuccess = (data) => ({
    type: GET_INCOME_EXPENSE_SUCCESS,
    payload: data,
});

export const getAllIncomeExpensesFail = (error) => ({
    type: GET_INCOME_EXPENSE_FAIL,
    payload: error,
});

export const addIncomeExpense = (data) => ({
    type: ADD_INCOME_EXPENSE,
    payload: data,
  });
  
  export const addIncomeExpenseSuccess = (data) => ({
    type: ADD_INCOME_EXPENSE_SUCCESS,
    payload: data,
  });
  
  export const addIncomeExpenseFail = (error) => ({
    type: ADD_INCOME_EXPENSE_FAIL,
    payload: error,
  });