import React, { useState, useEffect, useRef } from "react";
import {
  CNav,
  CNavLink,
  CTabContent,
  CNavItem,
  CTabPane,
} from "@coreui/bootstrap-react";
import {
  Row, Col, Button, Table, Label, InputGroup, Card, CardBody
} from "reactstrap";

import { Modal } from "react-bootstrap";

import CreatableSelect from "react-select/creatable";
import toastr from 'toastr';
import Barcode from 'react-barcode';
import moment from "moment";
import { useLocation } from 'react-router-dom';

import { AvField, AvForm } from "availity-reactstrap-validation";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "react-select";
import "./Domestic.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconButton from "@mui/material/IconButton";
import { Box } from "@mui/system";
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import SweetAlert from 'react-bootstrap-sweetalert';
import { withRouter, useHistory } from "react-router-dom";
import {
  getLocalbody,
} from "../../../helpers/globalFunctions";
import LogoDark from "../../../assets/images/logo-large.png"
/** FOR TAB STYLE */
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import PaymentsIcon from '@mui/icons-material/Payments';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import InventoryIcon from '@mui/icons-material/Inventory';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Loader from "react-spinner-loader";
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 35,
  height: 35,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <PersonOutlineIcon />,
    2: <FactCheckIcon />,
    3: <PaymentsIcon />,
    4: <ReceiptLongIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ['PERSONAL DETAILS', 'BOX/ITEM DETAILS', 'PAYMENT DETAILS', 'SUMMARY'];
function Addbooking(props) {
  var search = props?.location?.search;
  if (search) {
    var search_data = search.split("=");
    var order_search_no = search_data[1];
  }
  else
    var order_search_no = "";
  const [loader, setLoader] = useState(false);
  const [searchOrder] = useState(order_search_no);
  const [activeStep, setActiveStep] = useState(0);
  const custStyle = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: state.isFocused && "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isFocused && "rgb(235 101 23 / 30%)",
      color: state.isFocused && "black",
    }),
  }
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const barcodeRef = useRef(null);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [creditCustModal, setCreditCustModal] = useState(false);


  // =======================basicdetaisTAB1tate=================================================================>

  const [OrderObj_id, setOrderObj_id] = useState(null);
  const [masterobjbasic, setmasterobjbasic] = useState({});
  const [BoxdetailsObject, setBoxdetailsObject] = useState({ order_id: OrderObj_id });
  const [OrderSummyery, setOrderSummyery] = useState({});
  const [selectDeliveryType, setselectDeliveryType] = useState(null);
  const [deliverytypeOptions, setdeliverytypeOptions] = useState([{ label: "Door Delivery", value: 1 }, { label: "Branch Collection", value: 0 }]);
  const [selectedCreditCust, setselectedCreditCust] = useState(null);
  const [creditCustomerOptions, setcreditCustomerOptions] = useState([]);
  const [selectcashcredit, setselectcashcredit] = useState(null);
  const [selectfromcity, setselectfromcity] = useState(null);
  const [fromcityoptions, setfromcityoptions] = useState([]);
  const [selectTocity, setselectTocity] = useState(null);
  const [selectedMobile, setselectedMobile] = useState(null);
  const [searchMobileListOptions, setsearchMobileListOptions] = useState([]);
  const [searchdata, setsearchdata] = useState([]);
  const [searchdataReceiver, setsearchdataReceiver] = useState([]);
  const [searchnumber, setsearchnumber] = useState([]);
  const [searchnumberReceiver, setsearchnumberReceiver] = useState([]);
  const [savedData, setsavedData] = useState([]);
  const [receiver, setreceiver] = useState(true);
  const [addnew, setaddnew] = useState(false);
  const [stateoptions, setstateoptions] = useState([]);
  const [selecetdstate, setselecetdstate] = useState(null);
  const [deliverhere, setdeliverhere] = useState(-1);
  const [tocountry_id, settocountry_id] = useState(null);
  const [addressIdToBeDeletedrec, setaddressIdToBeDeletedrec] = useState(null);
  const [confirmDeletAlert, setconfirmDeletAlert] = useState(null);
  const [addressIdTobeDeletedcust, setaddressIdTobeDeletedcust] = useState(null);
  const [branchCountryId, setbranchCountryId] = useState(null);

  const [BasicDetailsTobeUpdated, setBasicDetailsTobeUpdated] = useState(null);
  // =========================ITEMDETAILSSTATES==============================>

  const [ItemDetailsObject, setItemDetailsObject] = useState(null);
  const [selectedItemType, setselectedItemType] = useState(null);
  const [ItemcatOptions, setItemcatOptions] = useState([]);
  const [selectedItemcat, setselectedItemcat] = useState(null);

  // ===========================BOXDETAILS FUNCTIONS==========>

  const [TotalBoxWeight, setTotalBoxWeight] = useState(0);
  const [displayaddmore, setdisplayaddmore] = useState("block");
  const [totalENboxwieght, settotalENboxwieght] = useState(0);
  const [BoxNO, setBoxNO] = useState(0);

  const [SingleOrderList, setSingleOrderList] = useState({});
  const [SuccessPage, setSuccessPage] = useState(false);
  const [selectedPackageTypes, setselectedPackageTypes] = useState([]);

  const address = `${masterobjbasic.receiverHouseName}, ${masterobjbasic.receiverLandmark}, ${masterobjbasic.receiverStreet}, ${masterobjbasic.receiverPostOffice}, ${masterobjbasic.receiverPinCode}`;

  let history = useHistory();
  const location = useLocation();
  const objectId = location?.state?.objectId;
  const name = location?.state?.name;
  /**  Prevent window reload */
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      if (!searchOrder && OrderObj_id) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [OrderObj_id]);
  /** Prevent history change */
  useEffect(() => {
    const unblock = history.block((tx) => {
      if (!searchOrder && OrderObj_id) {
        const shouldBlock = window.confirm('There are unsaved changes. Are you sure you want to leave?');
        if (shouldBlock) {
          return true; // Allow navigation
        } else {
          return false; // Prevent navigation
        }
      }
      return true; // Allow navigation
    });

    return () => {
      unblock();
    };
  }, [OrderObj_id, history]);

  useEffect(() => {
    if (searchOrder) {
      setLoader(true);
      axios
        .get(`${API_URL}order/domestic_singleorder?order_no=${searchOrder}`, {
          headers: {
            "x-access-token": accessToken
          }
        })
        .then((res) => { console.log(res,"res");
          var orderInfo = res.data.data.data;
          
          if (orderInfo) {
            setOrderObj_id(orderInfo._id);
            settocountry_id(orderInfo?.city_to?._id);

            if (orderInfo.delivery_type == 1) {
              setselectDeliveryType({ label: "Door Delivery", value: 1 })
            }
            else {
              setselectDeliveryType({ label: "Branch Collection", value: 0 })
            }

            if (orderInfo?.payment_type == 1) {
              var pay = "Credit";
              setselectedCreditCust({ label: orderInfo?.credit_det?.credit_cust_id?.name, value: orderInfo?.credit_det?.credit_cust_id?._id })
              setselectedCreditCustObject({ credit_cust_id: orderInfo?.credit_det?.credit_cust_id?._id, reference: orderInfo?.credit_det?.reference })
            }
            // setselectDeliveryType({ label: orderInfo?.delivery_type?.cargo_sub_type, value: orderInfo?.delivery_type?._id });
            // setselectcashcredit({ label: pay, value: orderInfo?.payment_type })
            setselectcashcredit(orderInfo?.payment_type);
            setselectfromcity({ label: orderInfo?.city_from?.name, value: orderInfo?.city_from?._id });
            setselectTocity({ label: orderInfo?.city_to?.name, value: orderInfo?.city_to?._id });
            setselecetdstate({ label: orderInfo?.receiver?.rece_state?.name, value: orderInfo?.receiver?.rece_state?._id });
            setsavedData(orderInfo?.sender?.saved_addresses);
            var address = orderInfo?.sender?.saved_addresses;
            if (address?.length > 0) {
              var index_no = address?.map((obj, index) => obj._id.toString() === orderInfo?.receiver?._id?.toString() ? index : -1)
                .filter(index => index !== -1)[0];
              setdeliverhere(index_no);
            }
            setaddnew(true);
            setmasterobjbasic({
              "order_id": orderInfo._id,
              "senderName": orderInfo?.sender?.name,
              "senderMobileNumber": orderInfo?.sender?.mobile_number,
              "senderWhattsappNumber": orderInfo?.sender?.whattsapp_number,
              "senderidNumber": orderInfo?.sender?.id_number,
              "sender_id": orderInfo?.sender?._id,
              "city_to": orderInfo?.city_to?._id,
              "city_from": orderInfo?.city_from?._id,
              "branch_id": orderInfo?.branch_id?._id,
              "receiver_id": orderInfo?.receiver?._id,
              "receiverName": orderInfo?.receiver?.name || '',
              "receiveridNumber": orderInfo?.receiver?.id_number,
              "receiverHouseName": orderInfo?.receiver?.house_name,
              "receiverLandmark": orderInfo?.receiver?.landmark,
              "receiverStreet": orderInfo?.receiver?.street,
              "receiverPostOffice": orderInfo?.receiver?.post_office,
              'receiverPinCode': orderInfo?.receiver?.pin_code,
              "receiverDistrict": orderInfo?.receiver?.district,
              "receiverMobileNumber": orderInfo?.receiver?.mobile_number,
              "receiverTelephoneNumber": orderInfo?.receiver?.telephone_number,
              "receiverState": orderInfo?.receiver?.rece_state?._id,
              //"delivery_type": orderInfo?.delivery_type?._id,
              "payment_type": orderInfo?.payment_type,
              "booking_type": orderInfo?.booking_type,
              "credit_det": { credit_cust_id: orderInfo?.credit_det?.credit_cust_id?._id, reference: orderInfo?.credit_det?.reference },
              "grand_total": orderInfo?.grand_total,
              "credit_balance": 0,
              "delivery_type:": orderInfo.delivery_type,
              "address": orderInfo.address
            });
            /** ORDER ITEMS */
            var item_details = [];
            orderInfo?.item_details?.map((itemD, index1) => {
              item_details.push({
                item_typename: itemD?.item_type == 5 ? "Domestic" : "Domestic",
                item_type: itemD?.item_type,
                item_categoryName: itemD?.item_category?.name,
                item_category: itemD?.item_category?._id,
                description: itemD?.description,
                unit: itemD?.unit,
                quantity: itemD?.quantity,
                rate: itemD?.rate,
                amount: itemD?.amount,
                item_image: itemD?.item_category?.image
              })
            })
            setvalues(item_details);
            calculatePackageType(item_details);
            setItemDetailsObject({
              "order_id": orderInfo?._id,
              "grand_total": orderInfo?.grand_total,
              "received_cash": 0,
              "vat_amount": orderInfo?.vat_amount,
              "handling_rate_total": orderInfo?.handling_rate_total,
              "total_value": orderInfo?.total_value,
              "vat_percentage": orderInfo?.vat_percentage,
              "amount": orderInfo?.amount,
              "total_weight": orderInfo?.total_weight,
              "handling_rate": orderInfo?.handling_rate,
              "no_of_boxes": orderInfo?.no_of_boxes,
              "item_details": item_details,
              "credit_balance": 0,
              "discount_amount": "",
              "contents": orderInfo?.contents,
              "additional_charge": orderInfo?.additional_charge ? orderInfo.additional_charge : 0
            });

            /** ORDER BOXS  */
            //var boxes = [];

            // orderInfo?.box_details?.map((boxItem, index2) => {
            //   boxes.push({ _id: boxItem?._id, box_no: index2 + 1, box_weight: boxItem?.box_weight, packed_items: boxItem?.packed_items })
            // })
            // setboxValue(boxes);
            // setBoxdetailsObject({
            //   "order_id": orderInfo?._id,
            //   "box_details": boxes
            // });
            //setBoxNO(boxes.length);
            settotalENboxwieght(orderInfo?.total_weight);
            setTotalBoxWeight(orderInfo?.total_weight);
          }

          setLoader(false);

        });
    }
    else {
      setmasterobjbasic({ branch_id: currentLocalbody, booking_type: 1, city_from: "634d29022bd8fbee01762b69", receiverName: '' });
      setItemDetailsObject({ received_cash: 0, credit_balance: 0, vat_amount: "", vat_percentage: 0, discount_amount: "" });
    }

  }, [])

  // =============================click outside close function=======>

  const refname = useRef();
  const refnumber = useRef()
  const refnameReceiver = useRef();
  const refnumberReceiver = useRef()

  const formRefTab1 = useRef();
  const formRefTab2 = useRef()
  const formRefTab3 = useRef();
  const formRefCreditCust = useRef();

  function resetTab1() {
    formRefTab1.current.reset();
    setselectDeliveryType(null);
    setselectcashcredit(null);
    setselectTocity(null);
    // setselectfromcity({ label: "Oman", value: "634d29022bd8fbee01762b69" });
    setselectfromcity(null);
    setsavedData([])
    setaddnew(false)
    setreceiver(true)
    setselectedCreditCustObject(null);
    setselectedCreditCust(null);
    setselecetdstate(null);
    setmasterobjbasic({
      branch_id: currentLocalbody, booking_type: 1, city_from: "634d29022bd8fbee01762b69", receiver_id: null, credit_balance: 0,
      order_id: OrderObj_id,
      receiverName: "",
      receiveridNumber: "",
      receiverHouseName: "",
      receiverLandmark: "",
      receiverStreet: "",
      receiverPostOffice: "",
      receiverPinCode: "",
      receiverDistrict: "",
      receiverMobileNumber: "",
      receiverTelephoneNumber: ""
    })

  }



  function resetTab2() {
    setvalues([{
      item_type: "",
      item_category: "",
      description: "",
      unit: "",
      quantity: "",
      l: "",
      b: "",
      h: "",
      rate: "",
      amount: "",
      categoryOptions: []
    }]);
    setselectedPackageTypes([]);
    //formRefTab2.current.reset();
    setItemDetailsObject({ item_details: [], no_of_boxes: "", handling_rate: ItemDetailsObject?.handling_rate, total_weight: "", amount: "", vat_percentage: ItemDetailsObject?.vat_percentage, total_value: "", handling_rate_total: "", vat_amount: "", grand_total: "", order_id: OrderObj_id, credit_balance: 0 });
    //Itemcalculation();
  }




  function resetTab3() {
    //formRefTab3.current.reset();
    setselectedCreditCust(null);
    setselectedCreditCustObject(null);
    setselectcashcredit(null);
    setmasterobjbasic({
      ...masterobjbasic,
      "payment_type": null,
      "credit_det": null
    });
    Itemcalculation("", null, "", 0);

  }

  const onDocumentMouseDown = e => {
    if (refname.current && !refname.current.contains(e.target)) {
      setsearchdata([]);
    }
    if (refnumber.current && !refnumber.current.contains(e.target)) {
      setsearchnumber([]);
    }
    if (refnameReceiver.current && !refnameReceiver.current.contains(e.target)) {
      setsearchdataReceiver([]);
    }
    if (refnumberReceiver.current && !refnumberReceiver.current.contains(e.target)) {
      setsearchnumberReceiver([]);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', onDocumentMouseDown);
    return () => {
      document.removeEventListener('mousedown', onDocumentMouseDown);
    };
  }, []);

  // =======================================================================

  const [errors, seterrors] = useState({
    city_from: "",
    city_to: "",
    delivery_type: "",
    payment_type: "",
    credit_cust: "",
    state: ""
  })
  const [values, setvalues] = useState([
    {
      item_type: "",
      item_category: "",
      description: "",
      unit: "",
      quantity: "",
      rate: "",
      amount: "",
    },
  ]);
  const [boxValue, setboxValue] = useState([
    {
      box_no: "",
      box_weight: "",
      packed_items: "",
    },
  ]);

  const addMore = () => {
    setvalues([
      ...values,
      {
        item_type: "",
        item_category: "",
        description: "",
        unit: "",
        quantity: "",
        rate: "",
        amount: "",
        categoryOptions: []
      },
    ]);
  };
  const addMoreRow = () => {
    setboxValue([
      ...boxValue,
      {
        box_no: "",
        box_weight: "",
        packed_items: "",
      },
    ]);

  };


  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    setvalues(list);
    Itemcalculation(0, index);
    calculatePackageType(list);
  };

  const handleRemove1 = (index) => {
    const list = [...boxValue];
    list.splice(index, 1);
    let valueArray = [];
    let total_bweight = 0;
    list && list.forEach((element) => { });

    list.map((el) => {
      let box_weight = el.box_weight
      total_bweight = total_bweight + parseFloat(box_weight);

      if (TotalBoxWeight === total_bweight) {
        setdisplayaddmore("none")
      } else {
        setdisplayaddmore("block")
      }

      if (total_bweight > TotalBoxWeight) {
        setdisplayaddmore("none")
      }

      if (total_bweight < TotalBoxWeight) {
        setdisplayaddmore("block")
      }

      settotalENboxwieght(total_bweight);

    })

    setboxValue(list);
  };

  const wrapperRef = useRef(null);
  const wrRef = useRef(null);
  const wraaRef = useRef(null);
  const wrrRef = useRef(null);

  useEffect(() => {
    fetch_all_itemCategory();
    fetch_vat();
    fetch_credit_customers();
    fetc_branch_country();
  }, [])

  const handleChangeCharge = (e) => {
    Itemcalculation("", null, "", e.target.value);
  }

  // ===============fetchcargotype===============>
  function fetc_branch_country() {
    axios
      .get(`${API_URL}cargo_Branch/data?_id=${currentLocalbody}`, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {
        var result = res.data.data
        fetch_all_city(result?.country_id?._id)
        setbranchCountryId(result?.country_id?._id)
        fetc_states(result?.country_id?._id)
      })
  }
  function fetc_states(Id) {
    axios
      .get(`${API_URL}newStates/list?country_id=${Id}`, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {
        var stateop =
          res.data.data &&
          res.data.data.map((st) => {
            return {
              label: st?.name,
              value: st?._id
            }
          })
        setstateoptions([{ options: stateop }])
      })

  }
  // function fetch_delivery_type() {
  //   axios
  //     .get(`${API_URL}cargoType/list`, {
  //       headers: {
  //         "x-access-token": accessToken,
  //       }
  //     })
  //     .then((res) => {
  //       var cargoop =
  //         res.data.data &&
  //         res.data.data.map((cargo) => {
  //           return {
  //             label: cargo?.cargo_sub_type,
  //             value: cargo?._id,
  //             handling_rate: cargo?.handling_rate,
  //           }
  //         })
  //       setdeliverytypeOptions([{ options: cargoop }])
  //     })

  // }
  function fetch_vat() {
    axios
      .get(`${API_URL}vatadd/vatlist`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var data = res.data.data;
        if (data.length > 0) {
          var vat = data[0].vat;
          if (!searchOrder) {
            setItemDetailsObject({
              ...ItemDetailsObject,
              "vat_percentage": vat
            })
          }

        }

      })

  }

  function fetch_credit_customers() {
    axios
      .get(`${API_URL}customer/credit-customer/list`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var options =
          res.data.data &&
          res.data.data.map((cust) => {
            return {
              label: cust?.name + " " + cust?.mobile_number,
              value: cust?._id,
            }
          })
        setcreditCustomerOptions([{ options: options }])
      })

  }
  // ============================fetchcountry==========>

  function fetch_all_city(CountryId) {

    axios
      .get(`${API_URL}city/country?country_id=${CountryId}`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var cityop =
          res.data.data &&
          res.data.data.map((city) => {
            return {
              label: city?.name,
              value: city?._id
            }
          })
        setfromcityoptions([{ options: cityop }])
      })
  }
  // =================selectechange==================
  async function handleSelectChange(selected, name, index = null) {
    switch (name) {
      case "delivery_type":
        setselectDeliveryType(selected);
        setmasterobjbasic({ ...masterobjbasic, delivery_type: selected.value })
        //setItemDetailsObject({ ...ItemDetailsObject, handling_rate: selected.handling_rate })
        Itemcalculation("", null, selected.handling_rate);
        break;
      case "payment_type":
        //fetch_credit_customers();
        setselectcashcredit(selected.target.value)
        setmasterobjbasic({ ...masterobjbasic, payment_type: selected.target.value });
        seterrors({
          ...errors,
          payment_type: ""
        });
        break;
      case "credit_custmer":
        setselectedCreditCust(selected);
        setselectedCreditCustObject({
          ...selectedCreditCustObject,
          "credit_cust_id": selected?.value
        });
        seterrors({
          ...errors,
          payment_type: ""
        });
        break;

      case "city_from":
        setselectfromcity(selected)
        setmasterobjbasic({ ...masterobjbasic, city_from: selected.value })

        break;

      case "city_to":
        setselectTocity(selected)
        settocountry_id(selected.value)
        setmasterobjbasic({ ...masterobjbasic, city_to: selected.value });
        // if (masterobjbasic?.sender_id) {
        //   axios
        //     .get(`${API_URL}customer/saved-address?customer_id=${masterobjbasic?.sender_id}&rece_country=${selected.value}`, {
        //       headers: {
        //         "x-access-token": accessToken,
        //       }
        //     })
        //     .then((res) => {
        //       let result = res.data
        //       setsavedData(result.data);
        //       setdeliverhere(-1);
        //       setreceiver(true)
        //       setmasterobjbasic({
        //         ...masterobjbasic,
        //         city_to: selected.value,
        //         receiver_id: null,
        //         receiverName: "",
        //         receiveridNumber: "",
        //         receiverHouseName: "",
        //         receiverLandmark: "",
        //         receiverStreet: "",
        //         receiverPostOffice: "",
        //         receiverPinCode: "",
        //         receiverDistrict: "",
        //         receiverMobileNumber: "",
        //         receiverTelephoneNumber: ""

        //       })

        //     })
        // }

        break;
      case "receiverState":
        setselecetdstate(selected)
        setmasterobjbasic({ ...masterobjbasic, receiverState: selected.value })
        seterrors({
          ...errors,
          state: ""
        });
        break;
      case "item_type":
        //setselectedItemType(selected)
        var categoriesOptions = await fetch_all_itemCategory(selected.value, index);
        var list = [...values];
        // list[index].itemtypeObject = selected;
        list[index].item_type = selected.value;
        list[index].item_typename = selected.label
        list[index].item_category = "";
        list[index].item_categoryName = "";
        list[index].unit = "";
        list[index].description = "";
        list[index].rate = "";
        list[index].quantity = "";
        list[index].amount = "";
        list[index].categoryOptions = categoriesOptions;
        list[index].item_type_error = "";
        setvalues(list);
        Itemcalculation()
        break;
      case "item_category":
        setselectedItemcat(selected)
        var list = [...values];
        list[index].item_type = 5;
        list[index].item_category = selected.value;
        list[index].item_categoryName = selected.label;
        list[index].item_image = selected.image;
        list[index].unit = selected.unit;
        list[index].rate = selected.rate;
        list[index].quantity = (list[index]?.quantity) ? list[index]?.quantity : 1;
        list[index].item_category_error = "";
        list[index].quantity_error = "";
        list[index].amount_error = "";
        var rate = parseFloat(list[index]?.rate)
        var quantity = parseFloat(list[index]?.quantity)
        list[index].amount = rate * quantity
        setvalues(list);
        Itemcalculation()
        calculatePackageType(list);
        break;
    }
  }
  function calculatePackageType(list) {
    const countByGrade = list.reduce((arr, item) => {
      const { item_category, item_image, item_categoryName } = item;
      if (item_category !== "") {
        var index_no = arr?.map((obj, index) => obj._id.toString() === item_category?.toString() ? index : -1)
          .filter(index => index !== -1);
        if (index_no.length == 0) {
          arr.push({ _id: item_category, count: 1, image: item_image, name: item_categoryName })
        } else {
          arr[index_no[0]].count++;
        }

      }
      return arr;

    }, []);
    setselectedPackageTypes(countByGrade);

  }
  function Itemcalculation(noBox = "", del_index = null, handling_rate = "", additional_charge = null) {
    var list = [...values];
    var amount = 0;
    var weight = 0;
    var grand_total = 0;
    if (additional_charge == null)
      additional_charge = ItemDetailsObject?.additional_charge ? ItemDetailsObject.additional_charge : 0;
    list.map((item, index) => {
      if (del_index != index) {
        var qty = parseFloat(item?.quantity ? item?.quantity : 0);
        var rate = parseFloat(item?.rate ? item.rate : 0);
        var amt = qty * rate;
        if (item?.amount != amt)
          amt = item?.amount
        if (item?.item_type != 2) {
          weight = weight + qty;
        }
        //item.amount = amt;
        amount = amount + amt;
      }

    });
    amount = parseFloat(amount.toFixed(2));
    var vat_perc = ItemDetailsObject?.vat_percentage ? ItemDetailsObject.vat_percentage : 0;
    if (vat_perc) {
      var vat_amount = (vat_perc / 100) * amount;
      vat_amount = parseFloat(vat_amount.toFixed(2));
    }
    else
      var vat_amount = 0;
    if (!handling_rate) {
      handling_rate = ItemDetailsObject?.handling_rate ? ItemDetailsObject.handling_rate : 0;
    }
    if (noBox) {
      var handling_rate_total = handling_rate * noBox;
      grand_total = amount + vat_amount + parseFloat(additional_charge);
      grand_total = parseFloat(grand_total.toFixed(2));
      setItemDetailsObject({
        ...ItemDetailsObject,
        "total_weight": weight,
        "amount": amount,
        "grand_total": grand_total,
        "handling_rate": 0,
        "handling_rate_total": 0,
        "no_of_boxes": noBox,
        "vat_amount": vat_amount,
        "additional_charge": additional_charge
      });
    }
    else {
      noBox = ItemDetailsObject?.no_of_boxes ? ItemDetailsObject.no_of_boxes : 0;
      var handling_rate_total = handling_rate * noBox;
      // var handling_rate_total = ItemDetailsObject?.handling_rate_total ? ItemDetailsObject?.handling_rate_total : 0;
      grand_total = amount + vat_amount + parseFloat(additional_charge);
      setItemDetailsObject({
        ...ItemDetailsObject,
        "total_weight": weight,
        "amount": amount,
        "handling_rate": 0,
        "handling_rate_total": 0,
        "grand_total": grand_total,
        "no_of_boxes": noBox,
        "vat_amount": vat_amount,
        "additional_charge": additional_charge
      });
    }



    //setvalues(list);

  }
  // ===================searchby name===================>

  function handleSelectChangesearchname(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterobjbasic({ ...masterobjbasic, [name]: value })

    axios
      .get(`${API_URL}customer/search?value=${e.target.value}`, {
        headers: {
          "x-access-token": accessToken,
        }
      }).then((res) => {

        let result = res.data
        setsearchdata(result)
      })
  }

  function handleSelectChangesearchnameReceiver(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterobjbasic({ ...masterobjbasic, [name]: value })

    axios
      .get(`${API_URL}receiver/search?name=${e.target.value}&rece_country=${branchCountryId}`, {
        headers: {
          "x-access-token": accessToken,
        }
      }).then((res) => {

        let result = res.data
        setsearchdataReceiver(result)
      })
  }
  // =================handlesearchnumber============>

  function handleSelectChangesearchnumber(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterobjbasic({ ...masterobjbasic, [name]: value })

    axios
      .get(`${API_URL}customer/search?value=${e.target.value}`, {
        headers: {
          "x-access-token": accessToken,
        }
      }).then((res) => {

        let result = res.data
        setsearchnumber(result);
      })
    setsearchdata([])
  }
  function handleSelectChangesearchnumberReceiver(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterobjbasic({ ...masterobjbasic, [name]: value })

    axios
      .get(`${API_URL}receiver/search?name=${e.target.value}&rece_country=${branchCountryId}`, {
        headers: {
          "x-access-token": accessToken,
        }
      }).then((res) => {

        let result = res.data
        setsearchnumberReceiver(result);
      })
    setsearchdataReceiver([])
  }
  // ===============settting matsr for sender ==========

  function settingmaster(el) {
    setmasterobjbasic({
      ...masterobjbasic,
      senderName: el?.name,
      sender_id: el?._id,
      senderidNumber: el?.id_number,
      senderMobileNumber: el?.mobile_number,
    })
    setsearchdata([])
    setsearchnumber([])
    // =============fetching saved adress from selectedoption=======>
    axios
      .get(`${API_URL}customer/saved-address?customer_id=${el?._id}&rece_country=${branchCountryId}`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        let result = res.data
        setsavedData(result.data)
      })
  }
  // ======================setting receviver adrress to creditcustmasterObject========>

  function settingmasterreceiver(el) {
    if (el?._id?.toString() !== masterobjbasic?.receiver_id?.toString()) {
      var address = [...savedData];
      if (address?.length > 0) {
        var index_no = address?.map((obj, index) => obj._id.toString() === el?._id?.toString() ? index : -1)
          .filter(index => index !== -1)[0];
        setdeliverhere(index_no);
      }

    }
    setmasterobjbasic({
      ...masterobjbasic,
      receiverName: el?.name,
      receiveridNumber: el?.id_number,
      receiverHouseName: el?.house_name,
      receiverLandmark: el?.landmark,
      receiverStreet: el?.street,
      receiverDistrict: el?.district,
      receiver_id: el?._id,
      receiverPostOffice: el?.post_office,
      receiverPinCode: el?.pin_code,
      receiverMobileNumber: el?.mobile_number,
      receiverState: el?.rece_state?._id,
      receiverTelephoneNumber: el?.telephone_number,
    });
    setselecetdstate({ label: el?.rece_state?.name, value: el?.rece_state?._id });
    setsearchdataReceiver([]);
    setsearchnumberReceiver([]);
  }
  // =============settingreceiverdata===================>
  function Settingreceiverdata() {
    setreceiver(true)
    //setsavedData([])
    setaddnew(true)
    setmasterobjbasic({
      ...masterobjbasic,
      receiver_id: null,
      receiverName: "",
      receiveridNumber: "",
      receiverHouseName: "",
      receiverLandmark: "",
      receiverStreet: "",
      receiverPostOffice: "",
      receiverPinCode: "",
      receiverDistrict: "",
      receiverMobileNumber: "",
      receiverTelephoneNumber: ""

    })
    setselecetdstate(null);
  }

  // ==================handleinput============>

  function handleInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    if (name == "reference") {
      setselectedCreditCustObject({
        ...selectedCreditCustObject,
        [name]: value
      });
    }
    else {
      setmasterobjbasic({ ...masterobjbasic, [name]: value })
    }
    setsearchdata([])
  }
  // ===================SubmitTab1===============>
  function handleValidSubmitTab1() {
    if (selecetdstate === null || masterobjbasic?.receiverState == null || selectDeliveryType === null || selectTocity === null || selectfromcity === null) {
      let errorVal = errors;
      if (selectDeliveryType === null)
        errorVal.delivery_type = "Please Select Delivery Type"
      // if (selectcashcredit === null)
      //   errorVal.payment_type = "Please Select Payment Type"
      if (selectTocity === null)
        errorVal.city_to = "Please Select City"
      if (selectfromcity === null)
        errorVal.city_from = "Please Select City"
      if (selecetdstate === null || masterobjbasic?.receiverState == null)
        errorVal.state = "Please Select Governorate"

      seterrors({
        ...errorVal
      });
      window.scrollTo(0, 0);
    }
    else if (selectcashcredit == 1 && selectedCreditCust == null) {
      let errorVal = errors;
      errorVal.credit_cust = "Please select Customer";
      seterrors({
        ...errorVal
      });
    }
    else {
      masterobjbasic.credit_det = selectedCreditCustObject;
      if (OrderObj_id) {
        axios
          .put(`${API_URL}order/edit/domestic`, masterobjbasic, {
            headers: {
              "x-access-token": accessToken,
            }
          })
          .then((res) => {
            if (res.data.status === 200) {
              // toastr.success("Basic Details Updated Successfully")

              //formRefTab1.current.reset()
              setActiveStep(1);
              window.scrollTo(0, 0);
              // setmasterobjbasic({});
              // setselectDeliveryType(null);
              // setselectcashcredit(null);
              // setselectTocity(null);
              // setselectfromcity(null);
              // setsavedData([])
              // setaddnew(false)
              // setreceiver(true)

            }
            else {
              toastr.error("faild to update")
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message)
            return;
          })
      }
      else {
        axios
          .post(`${API_URL}order/domestic`, masterobjbasic, {
            headers: {
              "x-access-token": accessToken,
            }
          }).then((res) => {
            if (res.data.status === 200) {
              // toastr.success("Basics Details Saved");

              setOrderObj_id(res?.data?.data?._id);
              let order_id = res?.data?.data?._id
              setItemDetailsObject({ ...ItemDetailsObject, order_id: order_id })
              setBoxdetailsObject({ ...BoxdetailsObject, order_id: order_id })
              setmasterobjbasic({
                ...masterobjbasic,
                order_id: order_id,
                sender_id: res?.data?.data?.sender,
                receiver_id: res?.data?.data?.receiver
              });
              //formRefTab1.current.reset()
              setActiveStep(1);
              window.scrollTo(0, 0);
              // setmasterobjbasic({});
              // setselectDeliveryType(null);
              // setselectcashcredit(null);
              // setselectTocity(null);
              // setselectfromcity(null);
              // setsavedData([])
              // setaddnew(false)
              // setreceiver(true)

            }
            else {
              toastr.error("Faild To Save Basic Details")
              return;
            }
          }).catch((err) => {
            console.log(err);
            toastr.error(err.response.data.message)
            return;
          })
      }

    }
  }
  // ====================itemdetailFunction===================>

  async function fetch_all_itemCategory(id = 5, index = null) {

    var res = await axios
      .get(`${API_URL}itemCategory/search_by_type?type=${id}`, {
        headers: {
          "x-access-token": accessToken,
        }
      });
    var Cate_data =
      res.data.data &&
      res.data.data.map((cat) => {
        return {
          label: cat?.name,
          value: cat?._id,
          unit: cat?.unit,
          rate: cat?.rate,
          image: cat?.image
        }
      });
    setItemcatOptions(Cate_data);
  }

  function handleUnitchange(e, index) {
    var list = [...values];
    var rate = list[index]?.rate;
    var quantity = e.target.value;
    var amount = (rate * quantity).toFixed(2);
    list[index].quantity = quantity;
    // list[index].amount = parseFloat(amount);
    list[index].quantity_error = "";
    setvalues(list);
    Itemcalculation();
  }
  function handleAmountchange(e, index) {
    var list = [...values];
    // var quantity = list[index]?.quantity;
    // var  rate= e.target.value;
    // var amount = (rate * quantity).toFixed(2);
    var amount = e.target.value;
    if (!amount)
      amount = 0.000
    // list[index].rate = rate;
    list[index].amount = parseFloat(amount);
    list[index].amount_error = "";
    setvalues(list);
    Itemcalculation();
  }
  function handleDimensions(e, index) {
    var list = [...values];
    list[index][e.target.name] = e.target.value;
    setvalues(list);
    Itemcalculation();
  }
  // ================description=====================>
  function handledescripchange(e, index) {
    var list = [...values]
    var description = e.target.value;
    list[index].description = description;
    setvalues(list)
  }
  // =================item details inputchange  handling rate total ,Grant total calculation===========>
  function handlenofBoxchanges(e) {
    let no_of_boxes = e.target.value
    setBoxNO(no_of_boxes)
    //let handling_rate_total = parseFloat(ItemDetailsObject.handling_rate) * parseInt(no_of_boxes)
    //setItemDetailsObject({ ...ItemDetailsObject, handling_rate_total: handling_rate_total, no_of_boxes: no_of_boxes });
    Itemcalculation(no_of_boxes);
  }

  // =============================SUBMIT ITEM Details================>
  function handleValidSubmitTab2() {
    var list = [...values];
    var err = 0;
    // var type_miss = list
    //   .map((item, index) => ({ item, index }))
    //   .filter(
    //     ({ item }) =>
    //       !item.hasOwnProperty("item_type") ||
    //       item.item_type == ""
    //   )
    //   .map(({ index }) => index);
    // if (type_miss.length > 0) {
    //   type_miss.map((item) => {
    //     list[item]["item_type" + "_error"] = "Select Type";
    //   });
    //   err++;
    // }

    var cat_miss = list
      .map((item, index) => ({ item, index }))
      .filter(
        ({ item }) =>
          !item.hasOwnProperty("item_category") ||
          item.item_category == ""
      )
      .map(({ index }) => index);
    if (cat_miss.length > 0) {
      cat_miss.map((item) => {
        list[item]["item_category" + "_error"] = "Select Category";
      });
      err++;
    }

    var qty_miss = list
      .map((item, index) => ({ item, index }))
      .filter(
        ({ item }) =>
          !item.hasOwnProperty("quantity") ||
          item.quantity == ""
      )
      .map(({ index }) => index);
    if (qty_miss.length > 0) {
      qty_miss.map((item) => {
        list[item]["quantity" + "_error"] = "Enter weight";
      });
      err++;
    }
    var amt_miss = list
      .map((item, index) => ({ item, index }))
      .filter(
        ({ item }) =>
          !item.hasOwnProperty("amount") ||
          item.amount == "" || item.amount == "0.000"
      )
      .map(({ index }) => index);
    if (amt_miss.length > 0) {
      amt_miss.map((item) => {
        list[item]["amount" + "_error"] = "Enter amount";
      });
      err++;
    }

    if (err > 0) {
      setvalues(list);
      window.scrollTo(0, 0);
      return;
    }
    else {
      ItemDetailsObject.item_details = values;
      // return;
      axios
        .put(`${API_URL}order/editItemDetails`, ItemDetailsObject, {
          headers: {
            "x-access-token": accessToken,
          }
        }).then((res) => {
          if (res.data.status === 200) {
            //toastr.success("Item Details Saved")
            // setvalues([{
            //   item_type: "",
            //   item_category: "",
            //   description: "",
            //   unit: "",
            //   quantity: "",
            //   rate: "",
            //   amount: "",
            //   categoryOptions: []
            // }]);
            // formRefTab2.current.reset();
            // setItemDetailsObject({ item_details: [], no_of_boxes: "", handling_rate: ItemDetailsObject?.handling_rate, total_weight: "", amount: "", vat_percentage: ItemDetailsObject?.vat_percentage, total_value: "", handling_rate_total: "", vat_amount: "", grand_total: "", order_id: OrderObj_id });
            setActiveStep(2);
            window.scrollTo(0, 0);
            setTotalBoxWeight(res?.data?.data?.total_weight)
          }
          else {
            toastr.error("Faild To Save Item Details")
            return;
          }
        })
        .catch((err) => {
          toastr.error("Faild To Save Item Details")
          return;
        })
    }

  }
  // =============================================================
  function handleWeightchange(e, index) {
    var list = [...boxValue]
    var box_weight = e.target.value;
    list[index].box_weight = box_weight;
    list[index].box_no = index + 1;
    if (box_weight)
      list[index].box_weight_error = "";
    else
      list[index].box_weight_error = "Enter Weight";
    let total_bweight = 0;
    let reamin_weight = 0
    let totalbWeightresponse = TotalBoxWeight
    boxValue.map((el) => {
      let box_weight = el.box_weight
      if (box_weight === null || box_weight === NaN || box_weight === "") {
        box_weight = 0;
      }
      total_bweight = total_bweight + parseFloat(box_weight);
      if (TotalBoxWeight === total_bweight) {
        setdisplayaddmore("none")
      } else {
        setdisplayaddmore("block")
      }
      if (total_bweight > TotalBoxWeight) {
        setdisplayaddmore("none")
      }
      if (total_bweight < TotalBoxWeight) {
        setdisplayaddmore("block")
      }
    })
    settotalENboxwieght(total_bweight);
    setboxValue(list);
  }

  function handlePackedItemchange(e, index) {
    var list = [...boxValue]
    list[index].packed_items = e.target.value;
    if (e.target.value)
      list[index].packed_items_error = "";
    else
      list[index].packed_items_error = "Enter Items";
    setboxValue(list);
  }
  // ========================SUBMITbOXDETAILS==============>


  function handleValidSubmitTab3(e, v) {
    let errorVal = errors;
    if (selectcashcredit === null) {
      errorVal.payment_type = "Please Select Payment Type";
      seterrors({
        ...errorVal
      });
      window.scrollTo(0, 0);
      return;
    }
    else if (selectcashcredit == 1 && selectedCreditCust == null) {
      let errorVal = errors;
      errorVal.credit_cust = "Please select Customer";
      seterrors({
        ...errorVal
      });
      return;
    }
    var data = { payment_type: masterobjbasic.payment_type, order_id: OrderObj_id, credit_det: selectedCreditCustObject, additional_charge: ItemDetailsObject?.additional_charge, grand_total: ItemDetailsObject?.grand_total };
    // return;
    axios
      .post(`${API_URL}order/domestic/payment-type`, data, {
        headers: {
          "x-access-token": accessToken
        }
      }).then((res) => {
        if (res.data.status === 200) {
          // setOrderSummyery(res.data.data)
          fetch_order_summary();
          // toastr.success("Box Detail saved")
          setActiveStep(3);
          window.scrollTo(0, 0);
        }
        else {
          toastr.error("Faild To Save payment Details")
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message)
        return;
      })
  }
  // =================================== Finalsubmit Order==================
  function finalSubmitOrder() {
    axios
      .get(`${API_URL}order/submit?order_id=${OrderObj_id}`, {
        headers: {
          "x-access-token": accessToken,
        }
      }).then((res) => {
        if (res.data.status === 200) {
          toastr.success("Order Created Successfully");
          setOrderObj_id(null);
          setSuccessPage(true);
        }
        else {
          toastr.error("Faild to create order")
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message)
        return;
      })
  }
  // =====================Order summmary==============
  function fetch_order_summary() {
    axios
      .get(`${API_URL}order/domestic_singleorder?_id=${OrderObj_id}`, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {
        if (res.data.status === 200) {
          setSingleOrderList(res.data.data.data)
        } else {
          toastr.error("faild to get Summary")
          return;
        }
      })
  }
  // ======================Preupdatebasicdetails==============
  // ==========================PreupdateItemDetailsupdate=============>
  /** Credit Customer  */
  const [selectedCreditCustObject, setselectedCreditCustObject] = useState(null);
  const [creditcustmasterObject, setcreditcustmasterObject] = useState({ branch_id: currentLocalbody });
  const [isCreditLoading, setisCreditLoading] = useState("");
  const handleValidSubmitCreditCust = () => {
    setisCreditLoading(true);
    axios
      .post(`${API_URL}customer/credit-customer`, creditcustmasterObject, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        if (res.data.status === 200) {
          fetch_credit_customers();
          var cust = res.data.data;
          setselectedCreditCust({
            label: cust?.name + " " + cust?.mobile_number,
            value: cust?._id
          })
          toastr.success("Customer Added Succesfully");
          formRefCreditCust.current.reset();
          setCreditCustModal(false);
          setselectedCreditCustObject({
            ...selectedCreditCustObject,
            "credit_cust_id": cust?._id
          })
          setcreditcustmasterObject({ branch_id: currentLocalbody });
        } else {
          toastr.error("Failed to add Customer");
          return;
        }
        setisCreditLoading("")
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        setisCreditLoading("")
        return;
      });
  };
  function handleChangecust(e) {
    let name = e.target.name;
    let value = e.target.value;
    setcreditcustmasterObject({ ...creditcustmasterObject, [name]: value });
  }
  function handleResetCust() {
    formRefCreditCust.current.reset();
    setcreditcustmasterObject({ name: "", address: "", mobile_number: "", email: "" });
  }
  return (
    <React.Fragment>

      <Modal
        show={SuccessPage}
        isOpen={SuccessPage}
        toggle={() => setSuccessPage(!SuccessPage)}
        size="md"
        centered={true}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className='modal-header'>
          <h4 className='modal-title mt-0 ml-4'>Order Created Successfully</h4>

        </div>
        <div className="mt-2 mb-2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <h5>Please note your order ID: {SingleOrderList?.order_number}  </h5>
        </div>

        <div className="mt-2 mb-2" style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Button className="mx-2"

            onClick={() => {
              history.push("/dashboard");

            }}

            style={{ backgroundColor: "black", borderRadius: "2rem", color: "white", border: "none" }}>
            Home
          </Button>

          <Button className="mx-2"
            onClick={() => {
              history.push("/all-booking");

            }}
            style={{ backgroundColor: "#eb6517", borderRadius: "2rem", color: "white", border: "none" }}
          >

            View Order
          </Button>

        </div>

      </Modal>
      <Modal
        show={creditCustModal}
        isOpen={creditCustModal}
        toggle={() => setCreditCustModal(!creditCustModal)}
        size="md"
        centered={true}
      >
        <div class="modal-header">
          <h5 class="modal-title mt-0"> Create New Credit Customer</h5>
          <button type="button" class="close" data-dismiss="modal" onClick={() => { setCreditCustModal(!creditCustModal) }} aria-label="Close"></button>
        </div>
        <div class="modal-body">

          <AvForm
            ref={formRefCreditCust}
            className="needs-validation"
            onValidSubmit={(e, v) => {
              handleValidSubmitCreditCust(e, v);
            }}
          >
            <Row>
              <Col md="6">
                <div >
                  <Label htmlFor="validationCustom01">
                    Name
                  </Label>
                  <AvField
                    name="name"
                    placeholder="Name"
                    type="text"
                    errorMessage="Enter Name"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom01"
                    value={creditcustmasterObject?.name}
                    onChange={handleChangecust}
                  />
                </div>
              </Col>
              <Col md="6">
                <div >
                  <Label htmlFor="validationCustom01">
                    Mobile
                  </Label>
                  <AvField
                    name="mobile_number"
                    placeholder="Mobile "
                    type="number"
                    errorMessage="Enter Mobile"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom01"
                    value={creditcustmasterObject?.mobile_number}
                    onChange={handleChangecust}
                  />
                </div>
              </Col>
              <Col md="6" className="mt-1">
                <div >
                  <Label htmlFor="validationCustom02">
                    Email
                  </Label>
                  <AvField
                    name="email"
                    placeholder="Email "
                    type="email"
                    errorMessage="Enter valid Email"
                    className="form-control"
                    validate={{ required: { value: false } }}
                    id="validationCustom02"
                    value={(creditcustmasterObject?.email) ? creditcustmasterObject?.email : ""}
                    onChange={handleChangecust}
                  />
                </div>
              </Col>

              <Col md="6" className="mt-1">
                <div >
                  <Label htmlFor="validationCustom01">
                    Address
                  </Label>
                  <AvField
                    style={{ lineHeight: "1.3" }}
                    name="address"
                    placeholder="Address"
                    type="textarea"
                    errorMessage="Enter Address"
                    className="form-control"
                    validate={{ required: { value: true } }}
                    id="validationCustom01"
                    value={creditcustmasterObject?.address}
                    onChange={handleChangecust}
                  />
                </div>
              </Col>
              <Col>
                <div className="mb-3 mt-2" >
                  <Button
                    style={{ background: "#eb6517" }}
                    type="submit"
                    disabled={isCreditLoading ? true : false}
                  >
                    {isCreditLoading ? "Adding" : "Submit"}
                  </Button>
                  <Button
                    className="ms-2"
                    style={{ background: 'black' }}
                    type="reset"
                    onClick={handleResetCust}
                  >Reset
                  </Button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </div>
      </Modal>


      {confirmDeletAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {

            axios
              .delete(`${API_URL}customer/saved-address/delete?customer_id=${addressIdTobeDeletedcust}&rece_id=${addressIdToBeDeletedrec}`, {
                headers: {
                  "x-access-token": accessToken,
                }
              })
              .then((res) => {
                if (res.data.status === 200) {

                  if (addressIdToBeDeletedrec.toString() == masterobjbasic?.receiver_id?.toString()) {
                    Settingreceiverdata();
                    setdeliverhere(-1);
                  }
                  toastr.success("Address Deleted Successfully");


                  axios
                    .get(`${API_URL}customer/saved-address?customer_id=${masterobjbasic?.sender_id}&rece_country=${branchCountryId}`, {
                      headers: {
                        "x-access-token": accessToken,
                      }
                    })
                    .then((res) => {
                      let result = res.data
                      setsavedData(result.data)
                    })
                }

                else {
                  toastr.error("Failed To Delete")
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setconfirmDeletAlert(false)
          }}
          onCancel={() => setconfirmDeletAlert(false)}
        >
          Are you sure want to delete Address?
        </SweetAlert>
      ) : null}
      {
        loader ? (

          <div className="page-content" style={{ paddingTop: "25px" }}>
            <div className="container-fluid">
              <Row>
                <Col md="12">

                  <Loader show={loader} type="box" message="Loading Data" />

                </Col>
              </Row>
            </div>
          </div>

        ) : (
          <>
            <div className="page-content" style={{ paddingBottom: "0px" }}>
              <div className="container-fluid">
                <h1 className="order-title-label">DOMESTIC ORDER</h1>
              </div>
            </div>
            <div class="tab-headers">
              <Stack sx={{ width: '64%' }} spacing={4}>
                {/* <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper> */}
                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Stack>
            </div>




            <div className="page-content" style={{ paddingTop: "25px" }}>
              <div className="container-fluid">
                {/************* Step 1 ***************/}
                {
                  activeStep == 0 ? (
                    <AvForm
                      className="needs-validation"
                      ref={formRefTab1}
                      onValidSubmit={(e, v) => {
                        handleValidSubmitTab1(e, v)
                      }}

                    >

                      <Row>
                        <Col xl="12">

                          <Row>
                            <Row>
                              <Col md="3">
                                <div className="mb-3">
                                  <Label>Delivery Type</Label>

                                  <Select
                                    styles={custStyle}
                                    name="delivery_type"
                                    value={selectDeliveryType}
                                    options={deliverytypeOptions}
                                    placeholder="Select Delivery Type"
                                    onChange={(selected) => {
                                      handleSelectChange(selected, "delivery_type")
                                    }}
                                    classNamePrefix="select2-selection" />

                                  <p className='text-danger'
                                    style={{ fontSize: "11px" }}>
                                    {selectDeliveryType === null ? errors.delivery_type : ""}
                                  </p>

                                </div>
                              </Col>
                              {/* <Col md="3">
                                <div className="mb-3">
                                  <Label>Payment Mode</Label>

                                  <Select

                                    styles={custStyle}

                                    name="cashType"
                                    classNamePrefix="select2-selection"
                                    value={selectcashcredit}
                                    options={[
                                      { label: "Cash", value: 2 },
                                      { label: "Credit", value: 1 },
                                      { label: "Card", value: 3 },
                                      { label: "Bank Transfer", value: 4 }
                                    ]}
                                    onChange={(selected) => {
                                      handleSelectChange(selected, "payment_type")
                                    }}
                                  />
                                  <p className='text-danger'
                                    style={{ fontSize: "11px" }}>
                                    {selectcashcredit === null ? errors.payment_type : ""}
                                  </p>
                                </div>
                              </Col>
                              {
                                selectcashcredit?.value == 1 ? (
                                  <>
                                    <Col md="3">
                                      <div className="mb-3">
                                        <Label>Credit Customer</Label>
                                        <InputGroup className="inputGroup">
                                          <div className="input-group-append" style={{ width: "86%" }}>
                                            <Select
                                              styles={custStyle}
                                              name="credit_cust_id"
                                              value={selectedCreditCust}
                                              options={creditCustomerOptions}
                                              placeholder="Select Credit Customer"
                                              onChange={(selected) => {
                                                handleSelectChange(selected, "credit_custmer")
                                              }}
                                              classNamePrefix="select2-selection"
                                            />
                                          </div>

                                          <div
                                            className="input-group-append"
                                            onClick={() => {
                                              setCreditCustModal(true);
                                            }}
                                          >
                                            <Button type="button" color="primary" className="select-group-btn">
                                              <i className="mdi mdi-plus" />
                                            </Button>
                                          </div>
                                        </InputGroup>


                                        <p className='text-danger'
                                          style={{ fontSize: "11px" }}>
                                          {selectedCreditCust === null ? errors.credit_cust : ""}
                                        </p>

                                      </div>
                                    </Col>
                                    <Col md="3">
                                      <div className="mb-4">
                                        <Label>Reference</Label>
                                        <AvField
                                          name="reference"
                                          placeholder="Reference"
                                          type="text"
                                          className="form-control"
                                          value={(selectedCreditCustObject?.reference) ? selectedCreditCustObject?.reference : ""}
                                          onChange={handleInput}
                                          validate={{ required: { value: true } }}
                                          errorMessage="Enter Reference"
                                        />
                                      </div>
                                    </Col>
                                  </>

                                ) : null
                              } */}

                              <Col md="3">
                                <div className="mb-3">
                                  <Label>From</Label>

                                  <Select
                                    name="city_from"
                                    classNamePrefix="select2-selection"
                                    placeholder="Select City"
                                    value={selectfromcity}
                                    options={fromcityoptions}
                                    onChange={(selected) => {
                                      handleSelectChange(selected, "city_from")
                                    }}

                                  />
                                  <p className='text-danger'
                                    style={{ fontSize: "11px" }}>
                                    {selectfromcity === null ? errors.city_from : ""}
                                  </p>
                                </div>
                              </Col>
                              <Col md="3">
                                <div className="mb-3">
                                  <Label>To</Label>

                                  <Select
                                    placeholder="Select City"
                                    name="city_to"
                                    classNamePrefix="select2-selection"
                                    value={selectTocity}
                                    options={fromcityoptions}
                                    onChange={(selected) => {
                                      handleSelectChange(selected, "city_to")
                                    }}

                                  />
                                  <p className='text-danger'
                                    style={{ fontSize: "11px" }}>
                                    {selectTocity === null ? errors.city_to : ""}
                                  </p>

                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <h5 className="alfarha"> Consignor/Sender</h5>
                                <Row>
                                  <Col md="3">
                                    <div className="mb-4" ref={wrapperRef}>
                                      <Label>Name</Label>
                                      <AvField

                                        name="senderName"
                                        placeholder="Name"
                                        type="text"
                                        // onClick={() => setdisplay(!display)}
                                        className="form-control"
                                        autoComplete={"off"}
                                        onChange={handleSelectChangesearchname}
                                        value={masterobjbasic?.senderName}
                                        style={{ backgroundColor: '#f9f6f3' }}
                                        validate={{ required: { value: true } }}
                                        errorMessage="Enter Sender Name"
                                      />
                                    </div>
                                    <div ref={refname} className="dropdownAuto" style={{ backgroundColor: "white" }}>
                                      {

                                        searchdata &&
                                        searchdata?.data?.map((el, index) => {
                                          return (
                                            <>
                                              <div className="dropdownItem mt-2"
                                                onClick={() => {
                                                  settingmaster(el);
                                                  setreceiver(true);
                                                  setaddnew(true);
                                                  setaddressIdTobeDeletedcust(el._id);

                                                }}
                                              >
                                                <p style={{ color: "black", fontWeight: 500 }} >
                                                  {el?.name}</p>
                                              </div>
                                            </>


                                          )
                                        })
                                      }
                                    </div>

                                  </Col>
                                  <Col md="3">
                                    <div className="mb-4">
                                      <Label>ID No</Label>
                                      <AvField
                                        name="senderidNumber"
                                        placeholder="ID.No"
                                        type="text"
                                        className="form-control"
                                        value={masterobjbasic?.senderidNumber}
                                        onChange={handleInput}
                                        // validate={{ required: { value: true } }}
                                        // errorMessage="Enter ID.NO"
                                        tabIndex="-1"
                                      />
                                    </div>
                                  </Col>

                                  <Col md="3">
                                    <div className="mb-4" ref={wrRef}>
                                      <Label>Mobile No</Label>
                                      <AvField
                                        name="senderMobileNumber"
                                        placeholder="Mobile Number"
                                        type="number"
                                        autoComplete={"off"}
                                        // onClick={() => setdisplay(!display)}
                                        className="form-control"
                                        onChange={handleSelectChangesearchnumber}
                                        value={masterobjbasic?.senderMobileNumber}
                                        style={{ backgroundColor: '#f9f6f3' }}
                                        validate={{ required: { value: true } }}
                                        errorMessage="Enter mobile number"
                                      // minLength={10}
                                      // maxLength={10}
                                      />
                                    </div>
                                    <div ref={refnumber} className="dropdownAuto">
                                      {
                                        searchnumber &&
                                        searchnumber?.data?.map((el, index) => {
                                          return (
                                            <>
                                              <div className="dropdownItem"
                                                onClick={() => {
                                                  settingmaster(el)
                                                }}
                                              >
                                                <p >
                                                  {el?.mobile_number}</p>
                                              </div>
                                            </>

                                          )

                                        })
                                      }
                                    </div>

                                  </Col>

                                  <Col md="3">
                                    <div className="mb-4">
                                      <Label>Whatsapp No</Label>
                                      <AvField
                                        name="senderWhattsappNumber"
                                        placeholder="Whatsapp.No"
                                        className="form-control"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        onChange={handleInput}
                                        value={masterobjbasic?.senderWhattsappNumber}
                                        pattern="\d{10}"  // Example pattern for 10-digit mobile number

                                      />
                                    </div>
                                  </Col>

                                </Row>
                                {/* {
                                  savedData.length > 0 ? (
                                    <Row className="mb-4">
                                      {
                                        savedData &&
                                        savedData?.map((el, index) => {
                                          return (
                                            <>
                                              <Col md="3" className="mt-3">
                                                <div
                                                  style={{
                                                    background: deliverhere === index ? "#f9f6f3" : "white",
                                                  }}

                                                  class="card deliver">

                                                  <div
                                                    className="editupdate"
                                                    style={{ display: "flex", gap: "8px" }}>
                                                    <i

                                                      style={{
                                                        color: "gray",
                                                        cursor: "pointer",
                                                      }}
                                                      class="fas fa-edit"
                                                      onClick={() => {
                                                        setreceiver(true);
                                                        setdeliverhere(index);
                                                        settingmasterreceiver(el);
                                                      }}
                                                    ></i>
                                                    <i
                                                      onClick={() => {

                                                        setaddressIdToBeDeletedrec(el._id);
                                                        setconfirmDeletAlert(true);
                                                      }}
                                                      style={{
                                                        color: "gray",
                                                        cursor: "pointer",
                                                      }}
                                                      class="fas fa-trash"
                                                    ></i>
                                                  </div>

                                                  <div class="card-body"
                                                    onClick={() => {
                                                      setreceiver(true);
                                                      settingmasterreceiver(el)
                                                      setdeliverhere(index)
                                                    }}
                                                  >
                                                    {
                                                      deliverhere === index ? (
                                                        <p class="deliver-badge">
                                                          <i class="fa fa-map-marker"> </i>
                                                          Deliver Here
                                                        </p>
                                                      ) : null
                                                    }


                                                    <div className="deliver-content">
                                                      <div
                                                        style={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          gap: "8px",
                                                        }}
                                                      >
                                                        <i
                                                          style={{ color: "#eb6517" }}
                                                          class="fas fa-user"
                                                        ></i>

                                                        <h6 class="text-muted mb-0">
                                                          {el?.name}
                                                        </h6>
                                                      </div>

                                                    </div>
                                                    <hr style={{ margin: ".5rem 0" }}></hr>
                                                    <p
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "start",
                                                        gap: "8px",
                                                      }}
                                                      class="text-muted mt-1 mb-0"
                                                    >
                                                      <i
                                                        style={{
                                                          color: "#eb6517",
                                                          lineHeight: "20px",
                                                        }}
                                                        class="fa fa-address-book"
                                                      >
                                                        {" "}
                                                      </i>
                                                      <span class="text-muted me-1">
                                                        {" "}
                                                        {el?.house_name} <br />
                                                        {el?.rece_state?.name}<br />
                                                        {el?.landmark}
                                                      </span>
                                                    </p>
                                                    <p
                                                      style={{
                                                        display: "flex",
                                                        alignItems: "start",
                                                        gap: "8px",
                                                      }}
                                                      class="text-muted mt-1 mb-0"
                                                    >
                                                      <i
                                                        style={{
                                                          color: "#eb6517",
                                                          lineHeight: "20px",
                                                        }}
                                                        class="fa fa-phone"
                                                      >
                                                        {" "}
                                                      </i>
                                                      <span class="text-muted  me-1">
                                                        {" "}
                                                        {el?.mobile_number}
                                                      </span>
                                                    </p>
                                                  </div>
                                                </div>
                                              </Col>
                                            </>

                                          )
                                        })


                                      }
                                      {
                                        addnew &&
                                        <Col md="3" className="mt-3">
                                          <div
                                            class="card  newcard"
                                            onClick={() => {
                                              Settingreceiverdata()
                                              setdeliverhere(-1);
                                            }}
                                          >
                                            <div class="card-body">
                                              <div className="plusbtn">
                                                <i class="fa fa-plus"></i>
                                              </div>
                                              <span className="btnspan">Add New Receiver Address</span>
                                            </div>
                                          </div>
                                        </Col>
                                      }



                                    </Row>
                                  ) : null
                                } */}
                                <Row className="mb-4">

                                  {
                                    savedData &&
                                    savedData?.map((el, index) => {
                                      var formattedNumber = el?.mobile_number?.replace(/\D/g, ''); // Remove non-numeric characters
                                      let spacedNumber = '';
                                      for (let i = 0; i < formattedNumber.length; i += 3) {
                                        spacedNumber += formattedNumber.slice(i, i + 3) + ' ';
                                      }
                                      var mobile = spacedNumber.trim();
                                      if (el?.telephone_number) {
                                        var formattedNumber1 = el?.telephone_number?.replace(/\D/g, ''); // Remove non-numeric characters
                                        let spacedNumber1 = '';
                                        for (let i = 0; i < formattedNumber1.length; i += 3) {
                                          spacedNumber1 += formattedNumber1.slice(i, i + 3) + ' ';
                                        }
                                        var tele = spacedNumber1.trim();
                                      }
                                      else {
                                        var tele = "Not available"
                                      }
                                      return (
                                        <div class="col-xl-3 col-sm-6" >
                                          <div class={deliverhere === index ? "card active-card-box my-card" : "card my-card"}>
                                            <div className="sub-card-body">
                                              {
                                                deliverhere === index ? (
                                                  <p class="deliver-badge">
                                                    <i class="fa fa-map-marker"> </i>
                                                    Deliver Here
                                                  </p>
                                                ) : null
                                              }
                                              <div class="d-flex align-items-center">
                                                <div class="flex-shrink-0 me-3">
                                                  <div class="avatar-xs">
                                                    <span class="avatar-title rounded-circle bg-primary-subtle">{el?.name?.substring(0, 1)?.toUpperCase()}</span>
                                                  </div>
                                                </div>
                                                <div class="flex-grow-1">
                                                  <h5 class="font-size-14 mb-0 toCapitalize">{el?.name.toLowerCase()}</h5>
                                                  <span class="badge mb-0 toCapitalize my-address-badge">{el?.district?.toLowerCase() + ", " + el?.rece_state?.name?.toLowerCase()}</span>
                                                </div>
                                                <div>
                                                  <i
                                                    onClick={() => {

                                                      setaddressIdToBeDeletedrec(el._id);
                                                      setconfirmDeletAlert(true);
                                                    }}
                                                    style={{
                                                      color: "gray",
                                                      cursor: "pointer",
                                                    }}
                                                    class="fas fa-trash"
                                                  ></i>

                                                </div>
                                              </div>
                                              {/* <div class="d-flex align-items-center">
                                                    <div class=" avatar-md rounded-circle img-thumbnail" style={{
                                                      textAlign: 'center',
                                                      padding: '8px',
                                                      background: '#eb651724',
                                                    }}><i class="fa fa-user" style={{
                                                      color: ' #eb6517', fontSize: "21px"
                                                    }}></i></div>
                                                    <div class="flex-1 ms-2">
                                                      <h5 class="font-size-15 mb-1"><a href="#" class="text-dark toCapitalize">{el?.name}</a></h5>
                                                      <span class="badge mb-0 toCapitalize my-address-badge">{el?.district + ", " + el?.rece_state?.name}</span>
                                                    </div>
                                                    <div>
                                                      <i
                                                        onClick={() => {

                                                          setaddressIdToBeDeletedrec(el._id);
                                                          setconfirmDeletAlert(true);
                                                        }}
                                                        style={{
                                                          color: "gray",
                                                          cursor: "pointer",
                                                        }}
                                                        class="fas fa-trash"
                                                      ></i>

                                                    </div>
                                                  </div> */}
                                            </div>
                                            <div class="card-body" style={{ padding: "10px", paddingTop: "0px", cursor: "pointer" }}
                                              onClick={() => {
                                                setreceiver(true);
                                                settingmasterreceiver(el)
                                                setdeliverhere(index)
                                              }}
                                            >

                                              <hr style={{
                                                padding: '0px',
                                                margin: '8px',
                                                color: '#eb6517'
                                              }}
                                              />
                                              <div class="mt-1 pt-1">
                                                <p class="text-muted mb-0"><i class="mdi mdi-phone font-size-15 align-middle pe-2 text-primay" style={{ color: "#eb6517a8" }}></i> {mobile}</p>
                                                <p class="text-muted mb-0 mt-2"><i class="mdi mdi-phone-classic font-size-15 align-middle pe-2 text-primasry" style={{ color: "#eb6517a8" }}></i> {tele}</p>
                                                <p class="text-muted mb-0 mt-2 toCapitalize">
                                                  <Row>
                                                    <Col md="1"><i class="mdi mdi-map-marker-radius font-size-17 align-middle pe-2 text-primarys" style={{ color: "#eb6517a8" }}></i>
                                                    </Col>
                                                    <Col md="11" className="ps-3">
                                                      {el?.house_name?.toLowerCase() + " " + (el?.street ? el.street.toLowerCase() : "")}{" "}{(el?.landmark ? el.landmark.toLowerCase() : "") + " " + (el?.post_office ? el.post_office.toLowerCase() : "") + " " + el?.pin_code}
                                                    </Col>
                                                  </Row>
                                                </p>
                                                {/* <p class="text-muted mb-0 mt-2 toCapitalize"><i class="mdi mdi-map-marker-radius font-size-17 align-middle pe-2 text-primasry" style={{ color: "#eb6517a8" }}></i> {el?.district + ", " + el?.rece_state?.name}</p> */}

                                              </div></div></div>
                                        </div>
                                      )

                                    })}
                                  {
                                    addnew &&
                                    <Col md="3">
                                      <div
                                        class="card  newcard my-card"
                                        onClick={() => {
                                          Settingreceiverdata()
                                          setdeliverhere(-1);
                                        }}
                                      >
                                        <div class="card-body">
                                          <div className="plusbtn">
                                            <i class="fa fa-plus" style={{ fontSize: '26px' }} ></i>
                                          </div>
                                          <span className="btnspan mt-3">Add New Receiver Address</span>
                                        </div>
                                      </div>
                                    </Col>
                                  }
                                </Row>

                                <Row>
                                  {
                                    receiver &&
                                    <Col>
                                      <h5 className="alfarha">
                                        Consignee/Receiver
                                      </h5>

                                      <Row>
                                        <Col md="3">
                                          <div className="mb-2" ref={wraaRef}>
                                            <Label>Name</Label>
                                            <AvField
                                              name="receiverName"
                                              placeholder="Name"
                                              className="form-control"
                                              autoComplete={"off"}
                                              type="text"
                                              onChange={handleSelectChangesearchnameReceiver}
                                              style={{ backgroundColor: '#f9f6f3' }}
                                              value={masterobjbasic?.receiverName ? masterobjbasic?.receiverName : ""}
                                              validate={{ required: { value: true } }}
                                              errorMessage="Enter Receiver Name"

                                            />
                                          </div>
                                          <div ref={refnameReceiver} className="dropdownAuto" style={{ backgroundColor: "white" }}>
                                            {

                                              searchdataReceiver &&
                                              searchdataReceiver?.data?.map((el, index) => {
                                                return (
                                                  <>
                                                    <div className="dropdownItem mt-2"
                                                      onClick={() => {
                                                        settingmasterreceiver(el);

                                                      }}
                                                    >
                                                      <p style={{ color: "black", fontWeight: 500 }} >
                                                        {el?.name}</p>
                                                    </div>
                                                  </>


                                                )
                                              })
                                            }
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>ID No</Label>
                                            <AvField
                                              name="receiveridNumber"
                                              placeholder="ID.NO"
                                              className="form-control"
                                              type="text"
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiveridNumber}
                                              // validate={{ required: { value: true } }}
                                              // errorMessage="Enter ID.NO"
                                              tabIndex="-1"
                                            />
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-3">
                                            <Label>Address</Label>
                                            <AvField
                                              name="address"
                                              placeholder="address"
                                              className="form-control"
                                              type="textarea"
                                              onChange={handleInput}
                                              validate={{ required: { value: true } }}
                                              errorMessage="Enter address"
                                              value={masterobjbasic?.address}
                                            />
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>Governorate</Label>
                                            <Select
                                              name="receiverState"
                                              classNamePrefix="select2-selection"
                                              options={stateoptions}
                                              value={selecetdstate}
                                              onChange={(selected) => {
                                                handleSelectChange(selected, "receiverState")
                                              }}
                                            />
                                          </div>
                                          <p className='text-danger'
                                            style={{ fontSize: "11px" }}>
                                            {errors?.state ? errors?.state : ""}
                                          </p>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>Wilayat</Label>
                                            <AvField
                                              name="receiverDistrict"
                                              placeholder="Wilayat"
                                              className="form-control"
                                              type="text"
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiverDistrict}
                                              validate={{ required: { value: true } }}
                                              errorMessage="Enter Wilayat"
                                            />
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-2" ref={wrrRef}>
                                            <Label>Mobile No.</Label>
                                            <AvField
                                              name="receiverMobileNumber"
                                              placeholder="Mobile No."
                                              autoComplete={"off"}
                                              type="number"
                                              className="form-control"
                                              validate={{ required: { value: true } }}
                                              errorMessage="Enter mobile number"
                                              style={{ backgroundColor: '#f9f6f3' }}
                                              // minLength={10}
                                              // maxLength={10}
                                              onChange={handleSelectChangesearchnumberReceiver}
                                              value={masterobjbasic?.receiverMobileNumber}
                                            />
                                          </div>
                                          <div ref={refnumberReceiver} className="dropdownAuto">
                                            {
                                              searchnumberReceiver &&
                                              searchnumberReceiver?.data?.map((el, index) => {
                                                return (
                                                  <>
                                                    <div className="dropdownItem"
                                                      onClick={() => {
                                                        settingmasterreceiver(el)
                                                      }}
                                                    >
                                                      <p >
                                                        {el?.mobile_number}</p>
                                                    </div>
                                                  </>

                                                )

                                              })
                                            }
                                          </div>
                                        </Col>

                                        {/* <Col md="3">
                                          <div className="mb-2">
                                            <Label>House Name</Label>
                                            <AvField
                                              name="receiverHouseName"
                                              placeholder="House Name"
                                              className="form-control"
                                              type="text"
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiverHouseName}
                                            // validate={{ required: { value: true } }}
                                            // errorMessage="Enter House Name/Number"
                                            />
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>Land Mark</Label>
                                            <AvField
                                              name="receiverLandmark"
                                              placeholder="Land Mark"
                                              className="form-control"
                                              type="text"
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiverLandmark}
                                            />
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>Street</Label>
                                            <AvField
                                              name="receiverStreet"
                                              placeholder="Street"
                                              className="form-control"
                                              type="text"
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiverStreet}
                                            />
                                          </div>
                                        </Col>

                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>Post Office</Label>
                                            <AvField
                                              name="receiverPostOffice"
                                              placeholder="Post Office"
                                              className="form-control"
                                              type="text"
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiverPostOffice}
                                              validate={{ required: { value: true } }}
                                              errorMessage="Enter Post Office"
                                            />
                                          </div>
                                        </Col> */}

                                        {/* <Col md="3">
                                          <div className="mb-2">
                                            <Label>Pin Code</Label>
                                            <AvField
                                              name="receiverPinCode"
                                              placeholder="Pin Code"
                                              className="form-control"
                                              type="number"
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiverPinCode}
                                              validate={{
                                                pattern: {
                                                  value: /^[0-9a-zA-Z]*$/,
                                                },
                                              }}
                                              // validate={{ required: { value: true } }}
                                              // errorMessage="Enter Pin Code"
                                            />
                                          </div>
                                        </Col> */}


                                        <Col md="3">
                                          <div className="mb-2">
                                            <Label>Alt.Phone No.</Label>
                                            <AvField
                                              name="receiverTelephoneNumber"
                                              placeholder="Alt.Phone No."
                                              type="phone"

                                              className="form-control"

                                              // minLength={11}
                                              // maxLength={11}
                                              onChange={handleInput}
                                              value={masterobjbasic?.receiverTelephoneNumber ? masterobjbasic?.receiverTelephoneNumber : ""}
                                              pattern="\d{10}"  // Example pattern for 10-digit mobile number
                                            />
                                          </div>
                                        </Col>
                                      </Row>
                                    </Col>
                                  }
                                </Row>

                              </Col>
                            </Row>
                          </Row>


                        </Col>
                      </Row>

                      <Row>

                        <Col className="btnlist py-3">
                          {/* <button
                      type="reset"
                      onClick={() => { setActiveStep(1) }}
                      className="refresh mx-2">Next test</button> */}
                          <button
                            type="reset"
                            onClick={() => { resetTab1() }}
                            className="refresh mx-2"><RefreshIcon /></button>

                          {BasicDetailsTobeUpdated ? (
                            <button
                              className="submit mx-2"
                              type="update"                            >
                              Update&Next
                            </button>
                          ) :
                            <button
                              className="submit mx-2"
                              type="submit"
                            >
                              Next
                            </button>}


                        </Col>

                      </Row>
                    </AvForm>
                  ) : null
                }

                {/************* Step 2 ***************/}
                {
                  activeStep == 1 ? (
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmitTab2(e, v)
                      }}
                      ref={formRefTab2}
                    >
                      <Row className="mt-4">
                        <Col xl="7">
                          <Table
                            id="OrderItemTable"
                            style={{ textAlign: "center" }}
                            className="table table-borderedd dataTable"
                          >
                            <TableHead className="table table-bordered dataTable">
                              <TableRow style={{ textAlign: "center" }}>

                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    background: "#f9f6f3",
                                    borderColor: "#f9f6f3"
                                  }}
                                >
                                  Item Category
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    background: "#f9f6f3",
                                    borderColor: "#f9f6f3"

                                  }}
                                >
                                  L
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    background: "#f9f6f3",
                                    borderColor: "#f9f6f3"

                                  }}
                                >
                                  B
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    background: "#f9f6f3",
                                    borderColor: "#f9f6f3"

                                  }}
                                >
                                  H
                                </TableCell>

                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    background: "#f9f6f3",
                                    borderColor: "#f9f6f3"

                                  }}
                                >
                                  Description
                                </TableCell>

                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    background: "#f9f6f3",
                                    borderColor: "#f9f6f3"

                                  }}
                                >
                                  Weight
                                </TableCell>

                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    background: "#f9f6f3",
                                    borderColor: "#f9f6f3"

                                  }}
                                >
                                  Amount
                                </TableCell>
                                {values.length !== 1 ? (
                                  <TableCell
                                    style={{
                                      width: "0px",
                                      textAlign: "center",
                                      fontWeight: "normal",
                                      paddingLeft: "0rem",
                                      paddingRight: "0rem",
                                      fontWeight: "700",
                                      fontSize: "0.875rem",
                                      color: "#282828",
                                      background: "#f9f6f3",
                                      borderColor: "#f9f6f3"

                                    }}
                                  >
                                    Action
                                  </TableCell>
                                ) : null}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values &&
                                values.map((item, index) => (

                                  <TableRow>

                                    <TableCell style={{ width: "100px", background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                      <Select
                                        name="item_category"
                                        placeholder="Category"
                                        // value={item.itemCatObject}
                                        value={{
                                          label: item?.item_categoryName,
                                          value: item?.item_category
                                        }}
                                        options={ItemcatOptions}
                                        onChange={(selected,) => {
                                          handleSelectChange(selected, "item_category", index)
                                        }}
                                        classNamePrefix={(item?.item_category_error) ? "invalid-select select2-selection" : "select2-selection"}

                                      />
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {item?.item_category_error}
                                      </div>
                                    </TableCell>
                                    <TableCell style={{ background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                      <AvField
                                        name="l"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder=""
                                        value={item?.l}
                                        onChange={(e) => {
                                          handleDimensions(e, index)
                                        }}
                                        style={{
                                          borderColor: item?.l_error
                                            ? "#f46a6a"
                                            : "#ced4da",
                                        }}
                                      // validate={{ required: { value: true } }}
                                      // errorMessage="Enter Quantity"
                                      />
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {item?.l_error}
                                      </div>
                                    </TableCell>
                                    <TableCell style={{ background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                      <AvField
                                        name="b"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder=""
                                        value={item?.b}
                                        onChange={(e) => {
                                          handleDimensions(e, index)
                                        }}
                                        style={{
                                          borderColor: item?.b_error
                                            ? "#f46a6a"
                                            : "#ced4da",
                                        }}
                                      // validate={{ required: { value: true } }}
                                      // errorMessage="Enter Quantity"
                                      />
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {item?.b_error}
                                      </div>
                                    </TableCell>
                                    <TableCell style={{ background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                      <AvField
                                        name="h"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder=""
                                        value={item?.h}
                                        onChange={(e) => {
                                          handleDimensions(e, index)
                                        }}
                                        style={{
                                          borderColor: item?.h_error
                                            ? "#f46a6a"
                                            : "#ced4da",
                                        }}
                                      // validate={{ required: { value: true } }}
                                      // errorMessage="Enter Quantity"
                                      />
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {item?.h_error}
                                      </div>
                                    </TableCell>
                                    <TableCell style={{ width: "100px", background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                      <AvField
                                        name="description"
                                        type="text"
                                        placeholder=""
                                        value={item.description}
                                        onChange={(e) => {
                                          handledescripchange(e, index)
                                        }}
                                      />
                                    </TableCell>

                                    <TableCell style={{ background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                      <AvField
                                        name="quantity"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder=""
                                        value={item?.quantity}
                                        onChange={(e) => {
                                          handleUnitchange(e, index)
                                        }}
                                        style={{
                                          borderColor: item?.quantity_error
                                            ? "#f46a6a"
                                            : "#ced4da",
                                        }}
                                      // validate={{ required: { value: true } }}
                                      // errorMessage="Enter Quantity"
                                      />
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {item?.quantity_error}
                                      </div>
                                    </TableCell>

                                    <TableCell style={{ background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                      <AvField
                                        name="amount"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder=""
                                        // readOnly={true}
                                        value={parseFloat(item?.amount ? item.amount : 0).toFixed(3)}
                                        onChange={(e) => {
                                          handleAmountchange(e, index)
                                        }}


                                      />
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {item?.amount_error}
                                      </div>
                                    </TableCell>
                                    {values.length !== 1 && (
                                      <TableCell style={{ background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                        <i
                                          className="uil uil-times"
                                          style={{
                                            fontSize: "20px",
                                            cursor: "pointer",
                                            paddingLeft: "5px",
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                          onClick={() => handleRemove(index)}
                                        ></i>
                                      </TableCell>
                                    )}
                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                          {
                            values?.length < 6 ? (
                              <div style={{ display: 'flex', justifyContent: "center", alignItems: "center" }}>

                                <span className="bg-dark badge bg-secondary font-size-12 addspan"
                                  onClick={() => addMore()}
                                ><i class="mdi mdi mdi-plus-thick"></i> New Item</span>
                              </div>
                            ) : null
                          }
                          <Row className="py-2" style={{ rowGap: "10px" }} >
                            <Label className="max-label">
                              * MAXIMUM UPTO 6 ITEMS PER SHIPMENT
                            </Label>
                            <Col md="4">
                              <Label>Total Weight</Label>
                              <AvField
                                name="weight"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                placeholder="Total weight"
                                value={ItemDetailsObject?.total_weight}
                                readOnly={true}
                              />
                            </Col>
                            <Col md="4" ></Col>
                            <Col md="4" >
                              <Label>Total Amount</Label>
                              <AvField
                                name="sub_total"
                                type="number"
                                onWheel={(e) => e.target.blur()}
                                placeholder="Grand Total"
                                value={parseFloat(ItemDetailsObject?.amount ? ItemDetailsObject.amount : 0)?.toFixed(3)}
                                readOnly={true}

                              />
                            </Col>
                          </Row>


                        </Col>
                        <Col xl="5" style={{ borderLeft: "#00000052 1px dashed" }}>
                          {
                            selectedPackageTypes?.length > 0 ? (
                              <h6 className="alfarha mt-3">Package Type</h6>
                            ) : null
                          }

                          <Row>
                            {selectedPackageTypes.map((pckg, pack_index) => {
                              return (
                                <Col md="2" className="pckg-box">
                                  <img src={API_URL + "uploads/item_category/" + pckg?.image} width={"100%"} />
                                  <div className="packg-count">{pckg?.count}</div>
                                </Col>
                              )
                            })}
                            <Col md="12" className="mt-3">
                              <h6 className="alfarha mt-3">Contents</h6>
                              <AvField
                                type="textarea"
                                name="content"
                                value={ItemDetailsObject?.contents ?? ''}
                                placeholder="Contents"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                errorMessage="Enter Contents"
                                onChange={(e) => {
                                  setItemDetailsObject({
                                    ...ItemDetailsObject,
                                    "contents": e.target.value
                                  });
                                }}
                              />
                            </Col>
                            {
                              selectedPackageTypes?.length > 0 ? (
                                <Col md="12">
                                  <h6 className="alfarha mt-3">No.of Boxes/PCS</h6>
                                  <Row>

                                    {selectedPackageTypes.map((pckg, pack_index) => {
                                      return (
                                        <Col md="12">
                                          <Label>{pckg.count} {pckg.name}</Label>
                                        </Col>
                                      )
                                    })}
                                  </Row>

                                </Col>
                              ) : null
                            }

                          </Row>

                        </Col>
                      </Row>





                      <Row>
                        <Col className="btnlist py-3">
                          {/* <button
                      type="reset"
                      onClick={() => { setActiveStep(2) }}
                      className="refresh mx-2">Next test</button> */}
                          <button
                            className="back1"
                            type="reset"
                            onClick={() => {
                              {
                                //preupdateBasicdetails()
                                setActiveStep(0);
                                window.scrollTo(0, 0);
                              }
                            }}
                          >
                            <i class="fa fa-long-arrow-alt-left"></i>
                          </button>
                          <button
                            type="reset"
                            onClick={() => resetTab2()}
                            className="refresh mx-2"

                          ><RefreshIcon /></button>
                          <button
                            className="submit mx-2"
                            type="submit"
                          >
                            Next
                          </button>


                        </Col>
                      </Row>
                    </AvForm>
                  ) : null
                }
                {/************* Step 3 ***************/}

                {
                  activeStep == 2 ? (
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmitTab3(e, v)
                      }}
                      ref={formRefTab3}

                    >

                      <Row>
                        <Col md="7">
                          <Table
                            id="OrderItemTable"
                            style={{ textAlign: "center" }}
                            className="table table-borderedd dataTable"
                          >
                            <TableHead className="table table-bordered dataTable">
                              <TableRow style={{ textAlign: "center" }}>

                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    background: "#f9f6f3",
                                    borderColor: "#f9f6f3"
                                  }}
                                >
                                  Item Category
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    background: "#f9f6f3",
                                    borderColor: "#f9f6f3"

                                  }}
                                >
                                  L
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    background: "#f9f6f3",
                                    borderColor: "#f9f6f3"

                                  }}
                                >
                                  B
                                </TableCell>
                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    background: "#f9f6f3",
                                    borderColor: "#f9f6f3"

                                  }}
                                >
                                  H
                                </TableCell>

                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    background: "#f9f6f3",
                                    borderColor: "#f9f6f3"

                                  }}
                                >
                                  Description
                                </TableCell>

                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    background: "#f9f6f3",
                                    borderColor: "#f9f6f3"

                                  }}
                                >
                                  Weight
                                </TableCell>

                                <TableCell
                                  style={{
                                    width: "0px",
                                    textAlign: "center",
                                    fontWeight: "normal",
                                    paddingLeft: "0rem",
                                    paddingRight: "0rem",
                                    fontWeight: "700",
                                    fontSize: "0.875rem",
                                    color: "#282828",
                                    background: "#f9f6f3",
                                    borderColor: "#f9f6f3"

                                  }}
                                >
                                  Amount
                                </TableCell>

                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {values &&
                                values.map((item, index) => (

                                  <TableRow>

                                    <TableCell style={{ width: "100px", background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                      <AvField
                                        name="item_category"
                                        type="test"
                                        placeholder="Category"
                                        value={item?.item_categoryName}
                                        onChange={(e) => {
                                          handleDimensions(e, index)
                                        }}
                                        readOnly={true}
                                        style={{
                                          borderColor: item?.item_category_error
                                            ? "#f46a6a"
                                            : "#ced4da",
                                        }}
                                      // validate={{ required: { value: true } }}
                                      // errorMessage="Enter Quantity"
                                      />
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {item?.item_category_error}
                                      </div>
                                    </TableCell>
                                    <TableCell style={{ background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                      <AvField
                                        name="l"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder=""
                                        value={item?.l}
                                        onChange={(e) => {
                                          handleDimensions(e, index)
                                        }}
                                        readOnly={true}
                                        style={{
                                          borderColor: item?.l_error
                                            ? "#f46a6a"
                                            : "#ced4da",
                                        }}
                                      // validate={{ required: { value: true } }}
                                      // errorMessage="Enter Quantity"
                                      />
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {item?.l_error}
                                      </div>
                                    </TableCell>
                                    <TableCell style={{ background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                      <AvField
                                        name="b"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder=""
                                        value={item?.b}
                                        readOnly={true}
                                        onChange={(e) => {
                                          handleDimensions(e, index)
                                        }}
                                        style={{
                                          borderColor: item?.b_error
                                            ? "#f46a6a"
                                            : "#ced4da",
                                        }}
                                      // validate={{ required: { value: true } }}
                                      // errorMessage="Enter Quantity"
                                      />
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {item?.b_error}
                                      </div>
                                    </TableCell>
                                    <TableCell style={{ background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                      <AvField
                                        name="h"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder=""
                                        value={item?.h}
                                        onChange={(e) => {
                                          handleDimensions(e, index)
                                        }}
                                        readOnly={true}
                                        style={{
                                          borderColor: item?.h_error
                                            ? "#f46a6a"
                                            : "#ced4da",
                                        }}
                                      // validate={{ required: { value: true } }}
                                      // errorMessage="Enter Quantity"
                                      />
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {item?.h_error}
                                      </div>
                                    </TableCell>
                                    <TableCell style={{ width: "100px", background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                      <AvField
                                        name="description"
                                        type="text"
                                        placeholder=""
                                        value={item.description}
                                        onChange={(e) => {
                                          handledescripchange(e, index)
                                        }}
                                        readOnly={true}
                                      />
                                    </TableCell>

                                    <TableCell style={{ background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                      <AvField
                                        name="quantity"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder=""
                                        value={parseFloat(item.quantity).toFixed(3)}
                                        onChange={(e) => {
                                          handleUnitchange(e, index)
                                        }}
                                        readOnly={true}
                                        style={{
                                          borderColor: item?.quantity_error
                                            ? "#f46a6a"
                                            : "#ced4da",
                                        }}
                                      // validate={{ required: { value: true } }}
                                      // errorMessage="Enter Quantity"
                                      />
                                      <div
                                        className="invalid-feedback"
                                        style={{
                                          display: "block",
                                          margin: "0px",
                                          padding: "0px",
                                        }}
                                      >
                                        {item?.quantity_error}
                                      </div>
                                    </TableCell>

                                    <TableCell style={{ background: "#f9f6f3", borderColor: "#f9f6f3" }}>
                                      <AvField
                                        name="amount"
                                        type="number"
                                        onWheel={(e) => e.target.blur()}
                                        placeholder=""
                                        readOnly={true}
                                        value={parseFloat(item.amount).toFixed(3)}


                                      />
                                    </TableCell>

                                  </TableRow>
                                ))}
                            </TableBody>
                          </Table>
                          <Row>
                            <Col md="6"></Col>
                            <Col md="6">
                              <Row>
                                <Col md="8" className="mt-3" style={{ textAlign: "right" }}><h6 className="alfarha mx-2 text-right" style={{ paddingTop: "9px" }}>Additional Charges</h6></Col>
                                <Col md="4" className="mt-3" style={{ paddingLeft: "24px" }}><input type="number" className="form-control" validate={{ required: { value: true }, errorMessage: "" }} name="additional_charge" value={parseFloat(ItemDetailsObject?.additional_charge ? ItemDetailsObject.additional_charge : 0).toFixed(3)} onChange={handleChangeCharge} /></Col>
                                <Col md="8" className="mt-3" style={{ textAlign: "right" }}><h6 className="alfarha mx-2 text-right" style={{ paddingTop: "9px" }}>Vat %</h6></Col>
                                <Col md="4" className="mt-3" style={{ paddingLeft: "24px" }}><input type="number" className="form-control" validate={{ required: { value: true }, errorMessage: "" }} name="additional_charge" value={parseFloat(ItemDetailsObject?.vat_amount ? ItemDetailsObject.vat_amount : 0).toFixed(3)} readOnly={true} /></Col>
                                <Col md="8" className="mt-3" style={{ textAlign: "right" }}><h6 className="alfarha mx-2 text-right" style={{ paddingTop: "9px" }}>Total Amount</h6></Col>
                                <Col md="4" className="mt-3" style={{ paddingLeft: "24px" }}><input type="number" className="form-control" validate={{ required: { value: true }, errorMessage: "" }} name="additional_charge" value={parseFloat(ItemDetailsObject.grand_total).toFixed(3)} readOnly={true} /></Col>

                              </Row>

                            </Col>
                          </Row>
                        </Col>
                        <Col md="5" className="mt-2" style={{ borderLeft: "#00000052 1px dashed" }}>
                          <Row>
                            <Col md="3"><b>PAYMENT INFO :</b>

                            </Col>
                            <Col md="2">

                              <div class="gap-2">
                                <div class="form-check">
                                  <input class="form-check-input" onClick={(selected) => {
                                    handleSelectChange(selected, "payment_type")
                                  }} type="radio" name="payment_mode" id="payment_mode1" value="2" checked={selectcashcredit == 2 ? "checked" : ""} />
                                  <label class="form-check-label" for="payment_mode1">Cash</label>
                                </div>
                              </div>
                            </Col>
                            <Col md="4">

                              <div class="gap-2">
                                <div class="form-check">
                                  <input class="form-check-input" onClick={(selected) => {
                                    handleSelectChange(selected, "payment_type")
                                  }} type="radio" name="payment_mode" id="payment_mode2" value="5" checked={selectcashcredit == 5 ? "checked" : ""} />
                                  <label class="form-check-label" for="payment_mode2">Cash on Delivery</label>
                                </div>
                              </div>
                            </Col>
                            <Col md="3">

                              <div class="gap-2">
                                <div class="form-check">
                                  <input class="form-check-input" onClick={(selected) => {
                                    handleSelectChange(selected, "payment_type")
                                  }} type="radio" name="payment_mode" id="payment_mode3" value="1" checked={selectcashcredit == 1 ? "checked" : ""} />
                                  <label class="form-check-label" for="payment_mode3">Credit</label>
                                </div>
                              </div>
                            </Col>
                            <Col md="12">
                              <p className='text-danger'
                                style={{ fontSize: "11px" }}>
                                {errors.payment_type}
                              </p>
                            </Col>

                            {
                              selectcashcredit == 1 ? (
                                <>
                                  <Col md="6" className="mt-2">
                                    <div className="mb-3">
                                      <Label>Credit Customer</Label>
                                      <InputGroup className="inputGroup">
                                        <div className="input-group-append" style={{ width: "86%" }}>
                                          <Select
                                            styles={custStyle}
                                            name="credit_cust_id"
                                            value={selectedCreditCust}
                                            options={creditCustomerOptions}
                                            placeholder="Select Credit Customer"
                                            onChange={(selected) => {
                                              handleSelectChange(selected, "credit_custmer")
                                            }}
                                            classNamePrefix="select2-selection"
                                          />
                                        </div>

                                        <div
                                          className="input-group-append"
                                          onClick={() => {
                                            setCreditCustModal(true);
                                          }}
                                        >
                                          <Button type="button" color="primary" className="select-group-btn">
                                            <i className="mdi mdi-plus" />
                                          </Button>
                                        </div>
                                      </InputGroup>


                                      <p className='text-danger'
                                        style={{ fontSize: "11px" }}>
                                        {selectedCreditCust === null ? errors.credit_cust : ""}
                                      </p>

                                    </div>
                                  </Col>
                                  <Col md="6" className="mt-2">
                                    <div className="mb-4">
                                      <Label>Reference</Label>
                                      <AvField
                                        name="reference"
                                        placeholder="Reference"
                                        type="text"
                                        className="form-control"
                                        value={(selectedCreditCustObject?.reference) ? selectedCreditCustObject?.reference : ""}
                                        onChange={handleInput}
                                        validate={{ required: { value: true } }}
                                        errorMessage="Enter Reference"
                                      />
                                    </div>
                                  </Col>
                                </>

                              ) : null
                            }
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="btnlist py-3">

                          <button
                            className="back1"
                            type="reset"
                            onClick={() => {
                              //PreupdateItemDetails()
                              setActiveStep(1);
                              window.scrollTo(0, 0);
                            }}
                          >
                            <i class="fa fa-long-arrow-alt-left"></i>
                          </button>
                          <button
                            type="reset"
                            onClick={() => { resetTab3() }}
                            className="refresh mx-2"><RefreshIcon /></button>
                          <button
                            className="submit mx-2"

                            type="submit"
                          >
                            Next
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  ) : null
                }
                {
                  activeStep == 3 ? (
                    <>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="ordersummary " style={{ backgroundColor: "#fff", width: "75%", padding: "32px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                          <Box className="summary text-center">

                            <Row>
                              <Col className="content md-5" md="6">
                                <h6 className="alfarha toCapitalize">{SingleOrderList?.branch_id?.branch_name?.toLowerCase()}, {SingleOrderList?.branch_id?.address?.toLowerCase()}, {SingleOrderList?.branch_id?.state_id?.name?.toLowerCase()}, {SingleOrderList?.branch_id?.country_id?.name?.toLowerCase()}</h6>
                                <Row>
                                  <Col md="12">
                                    <i class="fa fa-envelope icons"></i> {SingleOrderList?.branch_id?.email_id ?? ''}
                                  </Col>
                                  <Col md="12">
                                    <i class="fa fa-phone-alt icons"></i> {SingleOrderList?.branch_id?.mobile_no ?? ''}
                                  </Col>
                                  <Col md="12">
                                    <i class="fa fa-globe icons" aria-hidden="true"></i> {SingleOrderList?.branch_id?.website ?? ''}
                                  </Col>

                                </Row>
                              </Col>
                              <Col md="1"></Col>

                              <Col md="5">
                                <img
                                  src={LogoDark}
                                  style={{ width: "86%", marginTop: "-21px" }}
                                  alt=""
                                />

                              </Col>
                            </Row>
                            <hr style={{ marginTop: "9px", marginBottom: "1px", height: "1.3px", color: "#0264bc" }} />
                          </Box>
                          <Row>
                            <Col md="12">
                              <table style={{ width: "100%" }}>
                                <tr>
                                  <td style={{ padding: "0px" }}><h4 className="alfarha font-size-16">BOOKING NOTE</h4></td>
                                  <td style={{ padding: "0px", textAlign: "right" }}><Barcode value={SingleOrderList?.order_number} format="CODE128"
                                    displayValue={false}
                                    fontSize={12}
                                    width={1.5}
                                    height={30}
                                    ref={barcodeRef}
                                  /></td>
                                </tr>
                              </table>

                            </Col>

                          </Row>
                          <hr className='hr-dashed' style={{ marginTop: "1px" }} />
                          <Row>
                            <Col md="12" className="text-center">
                              <h4 className="alfarha font-size-16">{(SingleOrderList?.booking_type == 0) ? SingleOrderList?.country_to?.name?.toUpperCase() : SingleOrderList?.city_to?.name?.toUpperCase()}</h4>
                            </Col>
                          </Row>
                          <hr className='hr-dashed' style={{ marginTop: "1px" }} />
                          <Row>
                            <Col md="6" className="alfarha">Reference No : <b>{SingleOrderList?.cargo_type?.cargo_Type} {SingleOrderList?.order_number}</b></Col>
                            <Col md="6" className="content1 alfarha">Date : <b>{moment(SingleOrderList?.date).format("DD-MM-YYYY")}</b></Col>
                          </Row>
                          <hr className='hr-dashed' style={{ marginTop: "9px" }} />
                          <Row>
                            <Col>

                              <h6 className="alfarha">CONSIGNOR / SENDER ADDRESS</h6>
                              <table id="senderAddTable">
                                <tr>
                                  <th>Name</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.sender?.name?.toLowerCase()}</th>
                                </tr>

                                {SingleOrderList?.sender?.id_number && (
                                  <tr>
                                    <th>ID No</th><th> : </th><th className="toCapitalize">{SingleOrderList?.sender?.id_number}</th>
                                  </tr>
                                )}
                                <tr>
                                  <th>Mobile</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.sender?.mobile_number}
                                    {
                                      SingleOrderList?.sender?.whattsapp_number ? (
                                        <> / {SingleOrderList?.sender?.whattsapp_number}</>
                                      ) : null
                                    }
                                  </th>
                                </tr>

                              </table>

                            </Col>
                            <Col className="mx-2" md-3>
                              <h6 className="alfarha">CONSIGNEE / RECEIVER ADDRESS</h6>
                              <table id="receiverAddTable">
                                <tr>
                                  <th>Name</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList?.receiver?.name?.toLowerCase()}</b></th>
                                </tr>

                                {SingleOrderList?.receiver?.house_name && (
                                  <tr>
                                    <th>House Name</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.house_name?.toLowerCase()}</th>
                                  </tr>
                                )}

                                {SingleOrderList?.receiver?.street && (
                                  <tr>
                                    <th>Street</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toLowerCase() : "")}</th>
                                  </tr>
                                )}
                                <tr>
                                  <th>Address</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.address ? SingleOrderList.receiver.address.toLowerCase() : "")}</th>
                                </tr>

                                {SingleOrderList?.receiver?.pin_code && (
                                  <tr>
                                    <th>Pincode</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.pin_code}</th>
                                  </tr>
                                )}
                                <tr>
                                  <th>District</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.district}</th>
                                </tr>
                                <tr>
                                  <th>State</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.rece_state?.name}</th>
                                </tr>
                                <tr>
                                  <th>Mobile</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList?.receiver?.mobile_number} {SingleOrderList?.receiver?.telephone_number ? (
                                    <> / {SingleOrderList?.receiver?.telephone_number}</>
                                  ) : null}</b></th>
                                </tr>
                                {/* <tr>
                        <th>Address</th><th> : </th><th className="toCapitalize" style={{ textAlign: "justify" }}>{SingleOrderList?.receiver?.house_name?.toLowerCase() + " " + (SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toLowerCase() : "")}{" "}{(SingleOrderList?.receiver?.landmark ? SingleOrderList.receiver.landmark.toLowerCase() : "") + " " + (SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toLowerCase() : "") + " " + SingleOrderList?.receiver?.pin_code}
                          <br />{SingleOrderList?.receiver?.district + ", " + SingleOrderList?.receiver?.rece_state?.name}
                        </th>
                      </tr> */}

                              </table>



                            </Col>

                          </Row>
                          <Row >
                            <Col className="mt-2">
                              {/* <h6 className="alfarha">ITEM DETAILS</h6> */}
                              <table id="summeryItemsTable">
                                <tbody>
                                  <tr>
                                    <th>#</th>
                                    <th>Item</th>
                                    <th>Unit</th>
                                    <th>Weight</th>
                                    <th>Rate</th>
                                    <th>Amount</th>
                                  </tr>
                                </tbody>
                                <tbody>
                                  {
                                    SingleOrderList &&
                                    SingleOrderList?.item_details?.map((el, index) => {
                                      return (
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td className="toCapitalize">{el?.item_category?.name?.toLowerCase()}</td>
                                          <td>{el?.unit}</td>
                                          <td>{el?.quantity.toFixed(3)}</td>
                                          <td>{el?.rate.toFixed(3)}</td>
                                          <td>{el?.amount.toFixed(3)}</td>
                                        </tr>
                                      )
                                    })
                                  }
                                </tbody>
                                <tbody >
                                  <tr style={{ height: "100px" }}>
                                    <td colspan="6"></td>
                                  </tr>
                                  <tr>
                                    <th >Total</th>
                                    <th> :</th>
                                    <th></th>
                                    <th>{SingleOrderList?.total_weight?.toFixed(3)}</th>
                                    <th></th>
                                    <th style={{ textAlign: "right" }}>{SingleOrderList?.amount?.toFixed(3)}</th>
                                  </tr>
                                  {/* <tr>
                                    <th>No.of Boxes</th><th>: {SingleOrderList?.no_of_boxes}</th>
                                    <th>Handling Rate</th><th>: {SingleOrderList?.handling_rate?.toFixed(3)}</th>
                                    <th style={{ textAlign: "right", width: "156px" }}>Amount &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</th>
                                    <th>{(SingleOrderList?.handling_rate_total) ? SingleOrderList.handling_rate_total?.toFixed(3) : "0.000"}</th>
                                  </tr> */}
                                  <tr>
                                    <th>vat %</th><th>: {SingleOrderList?.vat_percentage}</th>
                                    <th>Vat Amount</th><th>: {SingleOrderList?.vat_amount?.toFixed(3)}</th>
                                    <th>Add.Charge :</th><th>{SingleOrderList?.additional_charge?.toFixed(3)}</th>
                                  </tr>
                                  <tr>
                                    <th><b>Grand Total</b></th><th> :</th>
                                    <th colSpan={4} style={{ textAlign: "right" }}><b>{SingleOrderList?.grand_total?.toFixed(3)}</b></th>
                                  </tr>
                                </tbody>
                              </table>

                            </Col>
                          </Row>
                          <Row>
                            <Col md="12" className="text-center mt-2"><h6 className="alfarha">OFFICE USE</h6></Col>

                            <Col md="6">
                              Signature of Shipper
                            </Col>
                            <Col md="6" className="content1">
                              Signature
                            </Col>
                          </Row>



                        </div>

                      </div>
                      <Row>
                        <Col className="btnlist py-3">
                          <button
                            className="back1"
                            type="reset"
                            onClick={() => {
                              {
                                setActiveStep(2);
                                window.scrollTo(0, 0);
                              }
                            }}
                            style={{ backgroundColor: "black" }}

                          >
                            <i class="fa fa-long-arrow-alt-left"></i>
                          </button>


                          <button
                            type="submit"
                            className="submit mx-2"
                            onClick={finalSubmitOrder}

                          >Submit</button>
                        </Col>
                      </Row>
                    </>

                  ) : null
                }
              </div>
            </div>
          </>
        )
      }

    </React.Fragment>
  );
}
export default Addbooking;