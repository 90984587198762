/* DISTRICTS */
export const GET_DISTRICTS = "GET_DISTRICTS";
export const GET_DISTRICTS_SUCCESS = "GET_DISTRICTS_SUCCESS";
export const GET_DISTRICTS_FAIL = "GET_DISTRICTS_FAIL";

export const GET_DISTRICT = "GET_DISTRICT";
export const GET_DISTRICT_SUCCESS = "GET_DISTRICT_SUCCESS";
export const GET_DISTRICT_FAIL = "GET_DISTRICT_FAIL";

export const ADD_DISTRICT = "ADD_DISTRICT";
export const ADD_DISTRICT_SUCCESS = "ADD_DISTRICT_SUCCESS";
export const ADD_DISTRICT_FAIL = "ADD_DISTRICT_FAIL";

export const UPDATE_DISTRICT = "UPDATE_DISTRICT";
export const UPDATE_DISTRICT_SUCCESS = "UPDATE_DISTRICT_SUCCESS";
export const UPDATE_DISTRICT_FAIL = "UPDATE_DISTRICT_FAIL";

export const DELETE_DISTRICT = "DELETE_DISTRICT";
export const DELETE_DISTRICT_SUCCESS = "DELETE_DISTRICT_SUCCESS";
export const DELETE_DISTRICT_FAIL = "DELETE_DISTRICT_FAIL";

 export const GET_DISTRICT_OPTIONS="GET_DISTRICT_OPTIONS";
 export const GET_DISTRICT_OPTIONS_SUCCESS="GET_DISTRICT_OPTIONS_SUCCESS";
 export const GET_DISTRICT_OPTIONS_FAIL="GET_DISTRICT_OPTIONS_FAIL";


