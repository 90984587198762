import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../components/Common/Breadcrumb";
import { Col, Row, Label, Button } from "reactstrap";
import { useStepContext } from "@mui/material";
import { getDate, getFirstday } from "../../../../helpers/globalFunctions";
import { getLocalBody } from "../../../../store/actions";
import $ from "jquery";
import moment from "moment";
import axios from "axios";
import accessToken from "../../../../helpers/jwt-token-access/accessToken";
import { MDBDataTable } from "mdbreact";
import "./style.scss";
import { CSVLink } from "react-csv";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";



function InOutReport() {

    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

    const [searchData, setSearchData] = useState({
        from_date: getFirstday(new Date()),
        to_date: getDate(new Date()),
        localbody_id: getLocalBody(),
    });

    const [reportTable, setReportTable] = useState([]);
    const [dataToBeExported, setDataToBeExported] = useState([]);

    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(false);
    const [outReportIdToBeDeleted, setOutReportIdToBeDeleted] = useState(null);


    useEffect(() => {
        handleTableData();
    }, []);


    // DATE FETCHING
    const handleDateSearch = (e) => {
        var date1 = $("#from_date").val();
        var date2 = $("#to_date").val();
        let toDate = moment(date1);
        let fromDate = moment(date2);
        let result = fromDate.diff(toDate, "days");

        if (result + 1 <= 0) {
            setSearchData({
                ...searchData,
                [e.target.name]: e.target.value,
                ["to_date"]: "",
            });
            date2 = "";
        } else {
            setSearchData({
                ...searchData,
                [e.target.name]: e.target.value,
            });
        }
    };


    // SEATCHING
    function handleSearch() {
        let fromdate = searchData?.from_date ? searchData?.from_date : "";
        let todate = searchData?.to_date ? searchData?.to_date : '';

        handleTableData(fromdate, todate);
    }


    // TABLE DATA
    function handleTableData(from_date = "", to_date = "") {
        var url = `${API_URL}process/in-out-report?from_date=` + from_date;

        if (from_date === "" && to_date === "") {
            from_date = from_date ? from_date : getFirstday(new Date());
            to_date = to_date ? to_date : getDate(new Date());
        }
        url =
            url +
            "&to_date=" +
            to_date;

        axios
            .get(url, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let Data = [];
                let result = res.data.data;

                result &&
                    result?.map((item, index) => {

                        item.action = (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                <i className="far fa-trash-alt"
                                    style={{ fontSize: "1em", cursor: "pointer" }}
                                    onClick={() => {
                                        setConfirmDeleteAlert(true);
                                        setOutReportIdToBeDeleted(item?._id);
                                    }}
                                ></i>
                            </div>
                        )

                        item?.to_branch &&
                            item?.to_branch?.map((el, index) => {

                                item.id = index + 1;
                                item.outboundId = item?.uniqueId;
                                item.to_branch = el?.branch_name;

                                item?.from_branch &&
                                    item?.from_branch?.map((brnch, index) => {

                                        item.from_branch = brnch?.branch_name;
                                    })

                                item?.order &&
                                    item?.order?.map((ordr, indx) => {

                                        item.ordernumber = ordr?.order_number;
                                        item.boxes = ordr?.no_of_boxes;
                                    })

                                item?.box &&
                                    item?.box?.map((bx, indx) => {

                                        item.bxnumbr = bx?.box_number;
                                    })
                                Data.push(item);

                                let exportItem = {};

                                exportItem.Slno = item.id;
                                exportItem.OutBoundID = item.uniqueId;
                                exportItem.Destination = el.branch_name;
                                exportItem.Order_No = item.ordernumber;
                                exportItem.Box_No = item.bxnumbr;
                                exportItem.No_of_boxes = item.boxes;

                                dataToBeExported.push(exportItem);

                            })

                    });

                setReportTable(Data);

                setDataToBeExported([
                    ...dataToBeExported,

                    {
                        Destination: ""
                    }
                ])
            })
    }


    // TABLE LISTING
    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 150,
            },
            {
                label: "OutBound ID",
                field: "outboundId",
                sort: "asc",
                width: 150,
            },
            {
                label: "From Branch",
                field: "from_branch",
                sort: "asc",
                width: 150,
            },
            {
                label: "To Branch",
                field: "to_branch",
                sort: "asc",
                width: 150,
            },
            {
                label: "Order No",
                field: "ordernumber",
                sort: "asc",
                width: 150,
            },
            {
                label: "Box No",
                field: "bxnumbr",
                sort: "asc",
                width: 150,
            },
            {
                label: "No of boxes",
                field: "boxes",
                sort: "asc",
                width: 150,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150,
            },
        ],
        rows: reportTable,
    };


    // RESET
    function reset() {
        setSearchData({
            from_date: getFirstday(new Date()),
            to_date: getDate(new Date()),
        });
        handleTableData();
    }


    return (
        <React.Fragment>

            {confirmDeleteAlert ? (
               <SweetAlert
                  title=''
                  showCancel
                  confirmBtnText="Delete"
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="success"
                  onConfirm={() => {
                    axios
                    .delete(`${API_URL}process/deletereport?_id=` + outReportIdToBeDeleted, {
                        headers: {
                            "x-access-token": accessToken,
                        },
                    }).then((res) => {
                        if (res.data.status === 200) {
                            toastr.success("Report deleted successfully");
                            handleTableData();
                        } else {
                            toastr.error("Failed to delete report");
                            return;
                        }
                    })
                    .catch((err) => {
                        toastr.error(err.response.data.message);
                    })
                    setConfirmDeleteAlert(false);
                  }}
                  onCancel={() => { setConfirmDeleteAlert(false)}}
               >
                   Are you sure you want to delete it?
               </SweetAlert>
            ): ""}

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumb title="Home" breadcrumbItem="In-Out Report" />

                    <Row>
                        <Col className="col-12">

                            <Row>
                                <Col md="2">
                                    <Label>From Date</Label>
                                    <input
                                        name="from_date"
                                        type="date"
                                        className="form-control"
                                        value={searchData?.from_date}
                                        onChange={handleDateSearch}
                                    />
                                </Col>

                                <Col md="2">
                                    <Label>To Date</Label>
                                    <input
                                        name="to_date"
                                        type="date"
                                        className="form-control"
                                        value={searchData?.to_date}
                                        onChange={handleDateSearch}
                                        min={searchData?.from_date}
                                    />
                                </Col>

                                <Col md="1" style={{ marginTop: "25px" }}>
                                    <Button color="primary" type="submit" onClick={() => { handleSearch() }}>Search</Button>
                                </Col>

                                <Col md="1" style={{ marginTop: "25px", marginLeft: "-12px" }}>
                                    <Button color="danger" type="reset" onClick={() => reset()}>Reset</Button>
                                </Col>

                                <Col md="1" style={{ marginTop: "25px", marginLeft: "-32px" }}>
                                    <Button className="btn btn-success mb-3" type="submit" style={{ float: "right" }}><i class="fas fa-file-excel"></i>
                                        <CSVLink
                                            data={dataToBeExported}
                                            filename={
                                                "In_Out_Bound_Report_" + getDate(new Date()) + ".xls"
                                            }
                                            style={{ color: "white" }}
                                        >
                                            Export
                                        </CSVLink>
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row mt="2">
                        <Col xl="12" className="mt-2">
                            <MDBDataTable
                                responsive
                                bordered
                                id="reporttableid"
                                info={true}
                                searching={true}
                                data={data}
                            />
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    )


}

export default InOutReport;