import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import LogoDark from "../../assets/images/logo-large.png"
import Barcode from 'react-barcode';

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Badge,
  // Modal,
  // Table,
} from "reactstrap";
import toastr from 'toastr';
import SweetAlert from 'react-bootstrap-sweetalert';
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import moment from "moment";
import $ from "jquery";
import Select from "react-select";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Modal } from "react-bootstrap";
import {
  getLocalbody,
  getDate,
  getFirstday,
} from "../../helpers/globalFunctions";
import "./booking.scss";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/system";
import Loader from "react-spinner-loader";
import { useReactToPrint } from 'react-to-print';
import { PrintableComponent } from './printableComponent';
import { PrintableComponentOfficeUse } from "./printableComponentOfficeUse";
import { PrintableComponentOfficeUseDomestic } from "./printableComponentOfficeUseDomestic";
import { PrintableComponentDomestic } from "./printableComponentDomestic";


const DaywiseReport = (props) => {
  const history = useHistory();
  const printRef = useRef();
  const printRef2 = useRef();

  const printRefDomestic = useRef();
  const printRef2Domestic = useRef();
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const dispatch = useDispatch();
  const [selectCargo, setselectCargo] = useState(null);
  const [CargoOptions, setCargoOptions] = useState([]);
  const [selecetfromcountry, setselecetfromcountry] = useState(null);
  const [Fromcountryoptions, setFromcountryoptions] = useState([]);
  const [selectTocountry, setselectTocountry] = useState(null);
  const [Tocountryoptions, setTocountryoptions] = useState([]);
  const [selectedBookingType, setselectedBookingType] = useState(null);
  const [selectedfromcity, setselectedfromcity] = useState(null);
  const [selectedtocity, setselectedtocity] = useState(null);
  const [cityoptions, setcityoptions] = useState([]);
  const [branchCountryId, setbranchCountryId] = useState(null);
  const [SingleOrderList, setSingleOrderList] = useState({});
  const [SingleOrderList1, setSingleOrderList1] = useState({})
  const [BoxList, setBoxList] = useState({});
  const [orderSummery, setOrderSummery] = useState(false);
  const [orderSummeryDomestic, setorderSummeryDomestic] = useState(false);
  const [loader, setLoader] = useState(false);

  const [ItemsForTable, setItemsForTable] = useState([]);
  const [OrderIdToBeDeleted, setOrderIdToBeDeleted] = useState(null);
  const [ConfirmDeleteAlert, setConfirmDeleteAlert] = useState(false);
  const [searchData, setSearchData] = useState({
    branch_id: currentLocalbody,
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });
  const barcodeRef = useRef(null);
  const [value, setValue] = useState('');
  const [ShowBarcode, setShowBarcode] = useState(false);
  const [barcodeNumber, setbarcodeNumber] = useState({});

  useEffect(() => {
    fetch_all_country();
    fetch_cargo_type();
    handleTableData();
    fetc_branch_country();
  }, []);

  function fetc_branch_country() {
    axios
      .get(`${API_URL}cargo_Branch/data?_id=${currentLocalbody}`, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {
        var result = res.data.data
        fetch_all_city(result?.country_id?._id)
        setbranchCountryId(result?.country_id?._id)
      })
  }

  function fetch_all_city(CountryId) {
    axios
      .get(`${API_URL}city/country?country_id=${CountryId}`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var cityop =
          res.data.data &&
          res.data.data.map((city) => {
            return {
              label: city?.name,
              value: city?._id
            }
          })
        setcityoptions([{ options: cityop }])
      })
  }

  function fetch_all_country() {
    axios
      .get(`${API_URL}newStates/countries`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var country_data =
          res.data.data &&
          res.data.data.map((country) => {
            return {
              label: country?.name,
              value: country?._id,

            };
          })
        setFromcountryoptions([
          {
            options: country_data,
          },
        ]);
        setTocountryoptions([
          {
            options: country_data,
          }
        ])
      });
  }


  function fetch_cargo_type() {
    axios
      .get(`${API_URL}cargoType/list`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var cargoop =
          res.data.data &&
          res.data.data.map((cargo) => {
            return {
              label: cargo?.cargo_sub_type,
              value: cargo?._id,
              handling_rate: cargo?.handling_rate,
            }
          })
        setCargoOptions([{ options: cargoop }])
      })

  }


  const handleGenerateBarcode = (item) => {
    setValue(item?.order_number);
    setShowBarcode(true)

  };

  const handleSearch = () => {
    // let branch_id = searchData?.branch_id ? searchData.branch_id : "";
    let branch_id = currentLocalbody;
    let from_date = searchData?.from_date ? searchData.from_date : "";
    let to_date = searchData?.to_date ? searchData.to_date : "";
    let cargo_type = searchData?.cargo_type ? searchData.cargo_type : "";
    let country_from = searchData?.country_from ? searchData.country_from : "";
    let country_to = searchData?.country_to ? searchData.country_to : "";
    let booking_type = (searchData?.booking_type !== null) ? searchData.booking_type : "";
    let city_from = searchData?.city_from ? searchData.city_from : "";
    let city_to = searchData?.city_to ? searchData.city_to : "";
    handleTableData(branch_id, from_date, to_date, cargo_type, country_from, country_to, booking_type, city_from, city_to);
  };

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const handlePrintOfficeUse = useReactToPrint({
    content: () => printRef2.current,
  })

  const handlePrintDomestic = useReactToPrint({
    content: () => printRefDomestic.current,
  });
  const handlePrintOfficeUseDomestic = useReactToPrint({
    content: () => printRef2Domestic.current,
  })

  function handleTableData(
    branch_id = currentLocalbody,
    from_date = "",
    to_date = "",
    cargo_type = "",
    country_from = "",
    country_to = "",
    booking_type = "",
    city_from = "",
    city_to = ""
  ) {

    var url = `${API_URL}order/view-orders?`
    // if (from_date === "" && to_date === "") {
    //   from_date = from_date ? from_date : getFirstday(new Date());
    //   to_date = to_date ? to_date : getDate(new Date());
    // }

    url =
      url +
      "&branch_id=" +
      branch_id +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&cargo_type=" +
      cargo_type +
      "&country_from=" +
      country_from +
      "&country_to=" +
      country_to +
      "&booking_type=" +
      booking_type
      + "&city_from=" + city_from
      + "&city_to=" + city_to;


    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var result = res.data.data
        let Order_data = [];
        result.map((item, index) => {
          console.log(item, "item");


          item.id = index + 1;
          item.date = moment(item.date).format("DD/MM/YYYY");
          item.grand_total = item?.grand_total?.toFixed(3);
          if (item?.booking_type == 0) {
            item.countryfrom = item?.country_from?.name;
            item.to = item?.country_to?.name;
          }
          else {
            item.countryfrom = item?.city_from?.name;
            item.to = item?.city_to?.name;
          }

          item.type = item?.cargo_type?.cargo_sub_type;

          item.sender1 = item?.sender?.name;
          item.receiver1 = item?.receiver?.name;


          if (item.payment_status === 2) {
            item.status1 = (
              <Badge className="bg-success"
                style={{ cursor: "pointer" }}
              >
                Paid
              </Badge>
            )
          }
          if (item.payment_status === 1) {
            item.status1 = (
              <Badge className="bg-success"
                style={{ cursor: "pointer" }}
              >
                Pending
              </Badge>
            )
          }

          if (item.payment_status === 0) {
            item.status1 = (
              <Badge className="bg-danger"
                style={{ cursor: "pointer" }}
              >
                Not Paid
              </Badge>
            )
          }

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i class="fa fa-eye"
                title="View"
                onClick={() => {
                  setLoader(true);
                  if (item.booking_type == 0)
                    setOrderSummery(true);
                  else
                    setorderSummeryDomestic(true)

                  if (item.booking_type == 0)
                    getOrderInfo(item?._id);
                  else
                    getOrderInfoDome(item?._id);
                }}

                style={{ fontSize: "1rem", cursor: "pointer", marginTop: ".3rem" }}
              ></i>
              <i class="fa fa-print"
                title="Customer Invoice Print"
                onClick={() => {
                  if (item.booking_type == 0)
                    getOrderInfo(item?._id, 1);
                  else
                    getOrderInfoDome(item?._id, 1);
                }}
                style={{
                  fontSize: "1rem", cursor: "pointer", marginLeft: "0.4rem",
                  marginRight: "0.4rem", marginTop: ".3rem"
                }}
              ></i>
              <i class="fa fa-print"
                title="Office Use Invoice Print"
                onClick={() => {
                  if (item.booking_type == 0)
                    getOrderInfo(item?._id, 2);
                  else
                    getOrderInfoDome(item?._id, 2);
                }}
                style={{
                  fontSize: "1rem", cursor: "pointer", marginLeft: "0.4rem",
                  marginRight: "0.4rem", marginTop: ".3rem",
                  color: "orange"
                }}
              ></i>
              {/* <i class="fa fa-qrcode"
                style={{
                  fontSize: "1rem", cursor: "pointer", marginLeft: "0.4rem",
                  marginRight: "0.4rem", marginTop: ".3rem"
                }}
                onClick={() => {
                  handleGenerateBarcode(item);

                }}
              ></i> */}
              {
                item?.payment_status == 0 ? (
                  <>
                    <i className="uil-edit-alt"
                      title="Edit"
                      style={{
                        fontSize: "1rem",
                        cursor: "pointer",
                        marginLeft: "0.4rem",
                        marginRight: "0.4rem"
                      }}
                      onClick={() => {
                        if (item?.booking_type == 0)
                          history.push("/new-booking?order=" + item?.order_number_search);
                        else
                          history.push("/new-booking-domestic?order=" + item?.order_number_search);
                      }}
                    ></i>
                    <i className="uil-trash-alt"
                      title="Delete"
                      style={{ fontSize: "1rem", cursor: "pointer" }}
                      onClick={() => {
                        setOrderIdToBeDeleted(item._id);
                        setConfirmDeleteAlert(true);
                      }}
                    ></i>
                  </>
                ) : null
              }



            </div>
          );




          Order_data.push(item)
        })
        setItemsForTable(Order_data)
      })
  }


  function getOrderInfo(id, from = null) {
    axios
      .get(`${API_URL}order/single-order?_id=${id}`, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {
        if (res.data.status === 200) {

          if (res.data.data.payment_type == "1") {
            res.data.data.payment_type = "Credit"
          }
          if (res.data.data.payment_type == "2") {
            res.data.data.payment_type = "Cash"
          }
          if (res.data.data.payment_type == "3") {
            res.data.data.payment_type = "Card"
          }
          if (res.data.data.payment_type == "4") {
            res.data.data.payment_type = "Bank Transfer"
          }

          setSingleOrderList(res.data.data);

          setLoader(false);
          if (from && from == 1 && res.data.data.booking_type == 0)
            handlePrint();
          else if (from && from == 2 && res.data.data.booking_type == 0)
            handlePrintOfficeUse();
          // if (from && from == 1 && res.data.data.booking_type == 1)
          //   handlePrintDomestic();
          // else if (from && from == 2 && res.data.data.booking_type == 1)
          //   handlePrintOfficeUseDomestic();
        } else {
          toastr.error("faild to get Summary")
          return;
        }
      })
  }

  function getOrderInfoDome(id, from = null) {
    axios
      .get(`${API_URL}order/domestic_singleorder?_id=${id}`, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {
        if (res.data.data.status === 200) {

          if (res.data.data.data.payment_type == "1") {
            res.data.data.data.payment_type = "Credit"
          }
          if (res.data.data.data.payment_type == "2") {
            res.data.data.data.payment_type = "Cash"
          }
          if (res.data.data.payment_type == "3") {
            res.data.data.payment_type = "Card"
          }
          if (res.data.data.data.payment_type == "4") {
            res.data.data.data.payment_type = "Bank Transfer"
          }

          setSingleOrderList1(res.data.data.data);

          setLoader(false);
          // if (from && from == 1 && res.data.data.booking_type == 0)
          //   handlePrint();
          // else if (from && from == 2 && res.data.data.booking_type == 0)
          //   handlePrintOfficeUse();
          if (from && from == 1 && res.data.data.data.booking_type == 1)
            handlePrintDomestic();
          else if (from && from == 2 && res.data.data.data.booking_type == 1)
            handlePrintOfficeUseDomestic();
        } else {
          toastr.error("faild to get Summary")
          return;
        }
      })

  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Order No.",
        field: "order_number",
        sort: "asc",
        width: 200,
      },
      {
        label: "From",
        field: "countryfrom",
        sort: "asc",
        width: 200,
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 200,
      },
      {
        label: "To",
        field: "to",
        sort: "asc",
        width: 200,
      },
      {
        label: "Sender",
        field: "sender1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Receiver",
        field: "receiver1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Amount",
        field: "grand_total",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: ItemsForTable,
  };


  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }

  };




  const handleSelectChange = (selected, name) => {

    switch (name) {
      case "type":
        setselectCargo(selected)
        setSearchData({ ...searchData, cargo_type: selected.value })
        break;
      case "countryfrom":
        setselecetfromcountry(selected)
        setSearchData({ ...searchData, country_from: selected.value })
        break;
      case "countryto":
        setselectTocountry(selected);
        setSearchData({ ...searchData, country_to: selected.value })
        break;
      case "booking_type":
        setselectedBookingType(selected);
        setselectedfromcity(null);
        setselectedtocity(null);
        setselecetfromcountry(null);
        setselectTocountry(null);
        setSearchData({ ...searchData, booking_type: selected.value, country_from: "", country_to: "", city_from: "", city_to: "" })
        break;
      case "cityfrom":
        setselectedfromcity(selected)
        setSearchData({ ...searchData, city_from: selected.value, country_from: branchCountryId, country_to: branchCountryId })
        break;
      case "cityto":
        setselectedtocity(selected);
        setSearchData({ ...searchData, city_to: selected.value, country_from: branchCountryId, country_to: branchCountryId })
        break;
    }


  };
  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setselecetfromcountry(null);
    setselectCargo(null);
    setselectTocountry(null);
    setselectedBookingType(null);
    setselectedfromcity(null);
    setselectedtocity(null);
    handleTableData();
  };

  return (
    <React.Fragment>
      <Modal
        show={orderSummery}
        isOpen={orderSummery}
        toggle={() => setOrderSummery(!orderSummery)}
        size="lg"
        centered={true}
      >

        <div className="modal-content" style={{ display: "flex", justifyContent: "center" }}>
          <div class="">
            <button type="button" onClick={() => {
              setOrderSummery(false);
            }} class="close" style={{ background: "none" }} data-dismiss="modal" aria-label="close">
              <i class="fa fa-window-close window-close" aria-hidden="true"></i></button>

          </div>
          {
            loader ? (
              <div className="page-content" style={{ paddingTop: "25px" }}>
                <div className="container-fluid">
                  <Row>
                    <Col md="12">

                      <Loader show={loader} type="box" message="Loading Data" />

                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <div className="ordersummary " style={{ backgroundColor: "#fff", width: "100%", padding: "32px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <Box className="summary text-center">
                  {/* <Row>
                    <h4 className="alfarha font-size-16">BOOKING NOTE # {SingleOrderList?.order_number}</h4>
                  </Row> */}
                  <Row>
                    <Col className="content md-8" md="6">
                      <h6 className="alfarha toCapitalize">{SingleOrderList?.branch_id?.branch_name?.toLowerCase()}, {SingleOrderList?.branch_id?.address?.toLowerCase()}, {SingleOrderList?.branch_id?.state_id?.name?.toLowerCase()}, {SingleOrderList?.branch_id?.country_id?.name?.toLowerCase()}</h6>
                      <Row>
                        <Col md="12">
                          <i class="fa fa-phone-alt icons"></i> {SingleOrderList?.branch_id?.mobile_no}
                        </Col>
                        <Col md="12">
                          <i class="fa fa-envelope icons"></i> {SingleOrderList?.branch_id?.email_id}
                        </Col>

                        <Col md="12">
                          <i class="fa fa-globe icons" aria-hidden="true"></i> {SingleOrderList?.branch_id?.website}
                        </Col>

                      </Row>
                    </Col>
                    <Col md="1"></Col>
                    {/* <Col md="4" style={{ paddingTop: "6%" }}>
                      <h4 className="alfarha font-size-16">BOOKING NOTE</h4>
                    </Col> */}
                    <Col md="5">
                      <img
                        src={LogoDark}
                        style={{ width: "86%", marginTop: "-21px" }}
                        alt=""
                      />
                      {/*  */}

                    </Col>
                  </Row>
                  <hr style={{ marginTop: "9px", marginBottom: "1px", height: "1.3px", color: "#0264bc" }} />
                </Box>
                <Row>
                  <Col md="12">
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td style={{ padding: "0px" }}><h4 className="alfarha font-size-16">BOOKING NOTE</h4></td>
                        <td style={{ padding: "0px", textAlign: "right" }}><Barcode value={SingleOrderList?.order_number} format="CODE128"
                          displayValue={false}
                          fontSize={12}
                          width={1.5}
                          height={30}
                          ref={barcodeRef}
                        /></td>
                      </tr>
                    </table>

                  </Col>

                </Row>
                <hr className='hr-dashed' style={{ marginTop: "1px" }} />
                <Row>
                  <Col md="12" className="text-center">
                    <h4 className="alfarha font-size-16">{(SingleOrderList?.booking_type == 0) ? SingleOrderList?.country_to?.name?.toUpperCase() : SingleOrderList?.city_to?.name?.toUpperCase()}</h4>
                  </Col>
                </Row>
                <hr className='hr-dashed' style={{ marginTop: "1px" }} />
                <Row>
                  <Col md="4" className="alfarha">Reference No : <b>{SingleOrderList?.cargo_type?.cargo_Type} {SingleOrderList?.order_number}</b></Col>
                  <Col md="4" className="content1 alfarha toCapitalize" style={{ textAlign: "center" }}>Created By : <b>{SingleOrderList?.added_by?.firstName} {SingleOrderList?.added_by?.lastName}</b></Col>
                  <Col md="4" className="content1 alfarha">Date : <b>{moment(SingleOrderList?.date + " " + SingleOrderList?.time).format("DD-MM-YYYY hh:mm a")}</b></Col>
                </Row>
                <hr className='hr-dashed' style={{ marginTop: "9px" }} />
                <Row>
                  <Col>

                    <h6 className="alfarha">CONSIGNOR / SENDER ADDRESS</h6>
                    <table id="senderAddTable">
                      <tr>
                        <th>Name</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.sender?.name?.toLowerCase()}</th>
                      </tr>

                      {SingleOrderList?.sender?.id_number && (
                        <tr>
                          <th>ID No</th><th> : </th><th className="toCapitalize">{SingleOrderList?.sender?.id_number}</th>
                        </tr>
                      )}
                      <tr>

                        <th>Mobile</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.sender?.mobile_number}
                          {
                            SingleOrderList?.sender?.whattsapp_number ? (
                              <> / {SingleOrderList?.sender?.whattsapp_number}</>
                            ) : null
                          }
                        </th>
                      </tr>

                    </table>

                  </Col>
                  <Col className="mx-2" md-3>
                    <h6 className="alfarha">CONSIGNEE / RECEIVER ADDRESS</h6>
                    <table id="receiverAddTable">
                      <tr>
                        <th>Name</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList?.receiver?.name?.toLowerCase()}</b></th>
                      </tr>

                      {SingleOrderList?.receiver?.house_name && (
                        <tr>
                          <th>House Name</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.house_name?.toLowerCase()}</th>
                        </tr>
                      )}

                      {SingleOrderList?.receiver?.landmark && (
                        <tr>
                          <th>Land Mark</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.landmark ? SingleOrderList.receiver.landmark.toLowerCase() : "")}</th>
                        </tr>
                      )}

                      {SingleOrderList?.receiver?.street && (
                        <tr>
                          <th>Street</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toLowerCase() : "")}</th>
                        </tr>
                      )}

                      <tr>
                        <th>Post Office</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toLowerCase() : "")}</th>
                      </tr>

                      {SingleOrderList?.receiver?.pin_code && (
                        <tr>
                          <th>Pincode</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.pin_code}</th>
                        </tr>
                      )}

                      <tr>
                        <th>District</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.district}</th>
                      </tr>
                      <tr>
                        <th>State</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.rece_state?.name}</th>
                      </tr>
                      <tr>
                        <th>Mobile</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList?.receiver?.mobile_number} {SingleOrderList?.receiver?.telephone_number ? (
                          <> / {SingleOrderList?.receiver?.telephone_number}</>
                        ) : null}</b></th>
                      </tr>
                      {/* <tr>
                        <th>Address</th><th> : </th><th className="toCapitalize" style={{ textAlign: "justify" }}>{SingleOrderList?.receiver?.house_name?.toLowerCase() + " " + (SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toLowerCase() : "")}{" "}{(SingleOrderList?.receiver?.landmark ? SingleOrderList.receiver.landmark.toLowerCase() : "") + " " + (SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toLowerCase() : "") + " " + SingleOrderList?.receiver?.pin_code}
                          <br />{SingleOrderList?.receiver?.district + ", " + SingleOrderList?.receiver?.rece_state?.name}
                        </th>
                      </tr> */}

                    </table>



                  </Col>

                </Row>
                {/* <hr className='hr-dashed' style={{ marginTop: "9px" }} /> */}
                <Row >
                  <Col className="mt-2">
                    {/* <h6 className="alfarha">ITEM DETAILS</h6> */}
                    <table id="summeryItemsTable">
                      <tbody>
                        <tr>
                          <th>#</th>
                          <th>Item</th>
                          <th>Unit</th>
                          <th>Weight</th>
                          <th>Rate</th>
                          <th>Amount</th>
                        </tr>
                      </tbody>
                      <tbody>
                        {
                          SingleOrderList &&
                          SingleOrderList?.item_details?.map((el, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td className="toCapitalize">{el?.item_category?.name?.toLowerCase()}</td>
                                <td>{el?.unit}</td>
                                <td>{el?.quantity.toFixed(3)}</td>
                                <td>{el?.rate.toFixed(3)}</td>
                                <td>{el?.amount.toFixed(3)}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                      <tbody >
                        <tr style={{ height: "100px" }}>
                          <td colspan="6"></td>
                        </tr>
                        <tr>
                          <th >Total</th>
                          <th> :</th>
                          <th></th>
                          <th>{SingleOrderList?.total_weight?.toFixed(3)}</th>
                          <th></th>
                          <th style={{ textAlign: "right" }}>{SingleOrderList?.amount?.toFixed(3)}</th>
                        </tr>
                        <tr>
                          <th>No.of Boxes</th><th>: {SingleOrderList?.no_of_boxes}</th>
                          <th>Handling Rate</th><th>: {SingleOrderList?.handling_rate?.toFixed(3)}</th>
                          <th style={{ textAlign: "right", width: "156px" }}>Amount &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</th>
                          <th>{(SingleOrderList?.handling_rate_total) ? SingleOrderList.handling_rate_total?.toFixed(3) : "0.000"}</th>
                        </tr>
                        <tr>
                          <th>vat %</th><th>: {SingleOrderList?.vat_percentage}</th>
                          <th>Vat Amount</th><th>: {SingleOrderList?.vat_amount?.toFixed(3)}</th>
                          <th>Total Value(OMR) :</th><th>{SingleOrderList?.total_value?.toFixed(3)}</th>
                        </tr>
                        <tr>
                          <th><b>Grand Total</b></th><th> :</th>
                          <th colSpan={4} style={{ textAlign: "right" }}><b>{SingleOrderList?.grand_total?.toFixed(3)}</b></th>
                        </tr>
                      </tbody>
                    </table>

                  </Col>
                </Row>
                {/* <hr className='hr-dashed' style={{ marginTop: "1px" }} /> */}
                <Row>
                  <Col md="12" className="text-center mt-2"><h6 className="alfarha">OFFICE USE</h6></Col>

                  <Col md="6">
                    Signature of Shipper
                  </Col>
                  <Col md="6" className="content1">
                    Signature
                  </Col>
                </Row>

                {/* <Row>
                  <Col md="12" className="mt-5" style={{ marginLeft: "10px", width: "100%", borderTop: "1.5px dashed #6d6b6b" }}>

                    <h6 className="alfarha" style={{
                      width: "51px",
                      marginTop: "-10px",
                      marginLeft: "19px",
                      background: "white",
                      paddingLeft: "4px"
                    }}>Boxes :</h6>
                  </Col>


                  {
                    SingleOrderList &&
                    SingleOrderList?.box_details?.map((el, index) => {
                      return (
                        <Col md="1" style={{ fontSize: "11px" }}>{el?.box_weight} KG</Col>
                      )
                    })
                  }


                </Row> */}
                <Row>
                  <Col md="12" className="mt-5" >

                    <table id="summeryBoxTable">
                      <tbody>
                        <tr>
                          <th>#</th>
                          <th>Box No.</th>
                          <th style={{ textAlign: "right" }}>Weight</th>
                          <th>Packed Items</th>
                          <th style={{ textAlign: "center" }}>Barcode</th>
                        </tr>
                      </tbody>
                      <tbody>
                        {
                          SingleOrderList &&
                          SingleOrderList?.box_details?.map((el, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{el?.box_number}</td>
                                <td>{el?.box_weight?.toFixed(3)}</td>
                                <td>{el?.packed_items}</td>
                                <td><Barcode value={el?.box_number} format="CODE128"
                                  displayValue={false}
                                  fontSize={12}
                                  width={1}
                                  height={18}
                                /></td>
                              </tr>

                            )
                          })
                        }
                      </tbody>
                    </table>
                  </Col>





                </Row>


              </div>
            )
          }


        </div>

      </Modal>

      {/* Domestic Modal */}

      <Modal
        show={orderSummeryDomestic}
        isOpen={orderSummeryDomestic}
        toggle={() => setorderSummeryDomestic(!orderSummeryDomestic)}
        size="lg"
        centered={true}
      >

        <div className="modal-content" style={{ display: "flex", justifyContent: "center" }}>
          <div class="">
            <button type="button" onClick={() => {
              setorderSummeryDomestic(false);
            }} class="close" style={{ background: "none" }} data-dismiss="modal" aria-label="close">
              <i class="fa fa-window-close window-close" aria-hidden="true"></i></button>

          </div>
          {
            loader ? (
              <div className="page-content" style={{ paddingTop: "25px" }}>
                <div className="container-fluid">
                  <Row>
                    <Col md="12">

                      <Loader show={loader} type="box" message="Loading Data" />

                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <div className="ordersummary " style={{ backgroundColor: "#fff", width: "100%", padding: "32px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                <Box className="summary text-center">
                  {/* <Row>
                    <h4 className="alfarha font-size-16">BOOKING NOTE # {SingleOrderList?.order_number}</h4>
                  </Row> */}
                  <Row>
                    <Col className="content md-8" md="6">
                      <h6 className="alfarha toCapitalize">{SingleOrderList1?.branch_id?.branch_name?.toLowerCase()}, {SingleOrderList1?.branch_id?.address?.toLowerCase()}, {SingleOrderList1?.branch_id?.state_id?.name?.toLowerCase()}, {SingleOrderList1?.branch_id?.country_id?.name?.toLowerCase()}</h6>
                      <Row>
                        <Col md="12">
                          <i class="fa fa-phone-alt icons"></i> {SingleOrderList1?.branch_id?.mobile_no}
                        </Col>
                        <Col md="12">
                          <i class="fa fa-envelope icons"></i> {SingleOrderList1?.branch_id?.email_id}
                        </Col>

                        <Col md="12">
                          <i class="fa fa-globe icons" aria-hidden="true"></i> {SingleOrderList1?.branch_id?.website}
                        </Col>

                      </Row>
                    </Col>
                    <Col md="1"></Col>
                    {/* <Col md="4" style={{ paddingTop: "6%" }}>
                      <h4 className="alfarha font-size-16">BOOKING NOTE</h4>
                    </Col> */}
                    <Col md="5">
                      <img
                        src={LogoDark}
                        style={{ width: "86%", marginTop: "-21px" }}
                        alt=""
                      />
                      {/*  */}

                    </Col>
                  </Row>
                  <hr style={{ marginTop: "9px", marginBottom: "1px", height: "1.3px", color: "#0264bc" }} />
                </Box>
                <Row>
                  <Col md="12">
                    <table style={{ width: "100%" }}>
                      <tr>
                        <td style={{ padding: "0px" }}><h4 className="alfarha font-size-16">BOOKING NOTE</h4></td>
                        <td style={{ padding: "0px", textAlign: "right" }}><Barcode value={SingleOrderList1?.order_number} format="CODE128"
                          displayValue={false}
                          fontSize={12}
                          width={1.5}
                          height={30}
                          ref={barcodeRef}
                        /></td>
                      </tr>
                    </table>

                  </Col>

                </Row>
                <hr className='hr-dashed' style={{ marginTop: "1px" }} />
                <Row>
                  <Col md="12" className="text-center">
                    <h4 className="alfarha font-size-16">{(SingleOrderList1?.booking_type == 0) ? SingleOrderList1?.country_to?.name?.toUpperCase() : SingleOrderList1?.city_to?.name?.toUpperCase()}</h4>
                  </Col>
                </Row>
                <hr className='hr-dashed' style={{ marginTop: "1px" }} />
                <Row>
                  <Col md="4" className="alfarha">Reference No : <b>{SingleOrderList1?.cargo_type?.cargo_Type} {SingleOrderList1?.order_number}</b></Col>
                  <Col md="4" className="content1 alfarha toCapitalize" style={{ textAlign: "center" }}>Created By : <b>{SingleOrderList1?.added_by?.firstName} {SingleOrderList1?.added_by?.lastName}</b></Col>
                  <Col md="4" className="content1 alfarha">Date : <b>{moment(SingleOrderList1?.date + " " + SingleOrderList1?.time).format("DD-MM-YYYY hh:mm a")}</b></Col>
                </Row>
                <hr className='hr-dashed' style={{ marginTop: "9px" }} />
                <Row>
                  <Col>

                    <h6 className="alfarha">CONSIGNOR / SENDER ADDRESS</h6>
                    <table id="senderAddTable">
                      <tr>
                        <th>Name</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList1?.sender?.name?.toLowerCase()}</th>
                      </tr>

                      {SingleOrderList1?.sender?.id_number && (
                        <tr>
                          <th>ID No</th><th> : </th><th className="toCapitalize">{SingleOrderList1?.sender?.id_number}</th>
                        </tr>
                      )}
                      <tr>
                        <th>Mobile</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList1?.sender?.mobile_number}
                          {
                            SingleOrderList1?.sender?.whattsapp_number ? (
                              <> / {SingleOrderList1?.sender?.whattsapp_number}</>
                            ) : null
                          }
                        </th>
                      </tr>

                    </table>

                  </Col>
                  <Col className="mx-2" md-3>
                    <h6 className="alfarha">CONSIGNEE / RECEIVER ADDRESS</h6>
                    <table id="receiverAddTable">
                      <tr>
                        <th>Name</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList1?.receiver?.name?.toLowerCase()}</b></th>
                      </tr>
                      {/* {SingleOrderList?.receiver?.house_name && (
                        <tr>
                          <th>House Name</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.house_name?.toLowerCase()}</th>
                        </tr>
                      )} */}

                      {/* {SingleOrderList?.receiver?.street && (
                        <tr>
                          <th>Street</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toLowerCase() : "")}</th>
                        </tr>
                      )} */}

                      {/* <tr>
                        <th>Post Office</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toLowerCase() : "")}</th>
                      </tr> */}

                      {/* {SingleOrderList?.receiver?.pin_code && (
                        <tr>
                          <th>Pincode</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.pin_code}</th>
                        </tr>
                      )} */}

                      <tr>
                        <th>Address</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList1?.receiver?.address}</th>
                      </tr>

                      <tr>
                        <th>District</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList1?.receiver?.district}</th>
                      </tr>
                      <tr>
                        <th>State</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList1?.receiver?.rece_state?.name}</th>
                      </tr>
                      <tr>
                        <th>Mobile</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList1?.receiver?.mobile_number} {SingleOrderList1?.receiver?.telephone_number ? (
                          <> / {SingleOrderList1?.receiver?.telephone_number}</>
                        ) : null}</b></th>
                      </tr>
                      {/* <tr>
                        <th>Address</th><th> : </th><th className="toCapitalize" style={{ textAlign: "justify" }}>{SingleOrderList?.receiver?.house_name?.toLowerCase() + " " + (SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toLowerCase() : "")}{" "}{(SingleOrderList?.receiver?.landmark ? SingleOrderList.receiver.landmark.toLowerCase() : "") + " " + (SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toLowerCase() : "") + " " + SingleOrderList?.receiver?.pin_code}
                          <br />{SingleOrderList?.receiver?.district + ", " + SingleOrderList?.receiver?.rece_state?.name}
                        </th>
                      </tr> */}

                    </table>



                  </Col>

                </Row>
                {/* <hr className='hr-dashed' style={{ marginTop: "9px" }} /> */}
                <Row >
                  <Col className="mt-2">
                    {/* <h6 className="alfarha">ITEM DETAILS</h6> */}
                    <table id="summeryItemsTable">
                      <tbody>
                        <tr>
                          <th>#</th>
                          <th>Item</th>
                          <th>Unit</th>
                          <th>Weight</th>
                          <th>Rate</th>
                          <th>Amount</th>
                        </tr>
                      </tbody>
                      <tbody>
                        {
                          SingleOrderList1 &&
                          SingleOrderList1?.item_details?.map((el, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td className="toCapitalize">{el?.item_category?.name?.toLowerCase()}</td>
                                <td>{el?.unit}</td>
                                <td>{el?.quantity.toFixed(3)}</td>
                                <td>{el?.rate.toFixed(3)}</td>
                                <td>{el?.amount.toFixed(3)}</td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                      <tbody >
                        <tr style={{ height: "100px" }}>
                          <td colspan="6"></td>
                        </tr>
                        <tr>
                          <th >Total</th>
                          <th> :</th>
                          <th></th>
                          <th>{SingleOrderList1?.total_weight?.toFixed(3)}</th>
                          <th></th>
                          <th style={{ textAlign: "right" }}>{SingleOrderList1?.amount?.toFixed(3)}</th>
                        </tr>
                        {/* <tr>
                          <th>No.of Boxes</th><th>: {SingleOrderList?.no_of_boxes}</th>
                          <th>Handling Rate</th><th>: {SingleOrderList?.handling_rate?.toFixed(3)}</th>
                          <th style={{ textAlign: "right", width: "156px" }}>Amount &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</th>
                          <th>{(SingleOrderList?.handling_rate_total) ? SingleOrderList.handling_rate_total?.toFixed(3) : "0.000"}</th>
                        </tr> */}
                        <tr>
                          <th>vat %</th><th>: {SingleOrderList1?.vat_percentage}</th>
                          <th>Vat Amount</th><th>: {SingleOrderList1?.vat_amount?.toFixed(3)}</th>
                          <th>Add. Charge</th><th>: {SingleOrderList1?.additional_charge?.toFixed(3)}</th>
                        </tr>
                        <tr>
                          <th><b>Grand Total</b></th><th> :</th>
                          <th colSpan={4} style={{ textAlign: "right" }}><b>{SingleOrderList1?.grand_total?.toFixed(3)}</b></th>
                        </tr>
                      </tbody>
                    </table>

                  </Col>
                </Row>
                {/* <hr className='hr-dashed' style={{ marginTop: "1px" }} /> */}
                <Row>
                  <Col md="12" className="text-center mt-2"><h6 className="alfarha">OFFICE USE</h6></Col>

                  <Col md="6">
                    Signature of Shipper
                  </Col>
                  <Col md="6" className="content1">
                    Signature
                  </Col>
                </Row>

                {/* <Row>
                  <Col md="12" className="mt-5" style={{ marginLeft: "10px", width: "100%", borderTop: "1.5px dashed #6d6b6b" }}>

                    <h6 className="alfarha" style={{
                      width: "51px",
                      marginTop: "-10px",
                      marginLeft: "19px",
                      background: "white",
                      paddingLeft: "4px"
                    }}>Boxes :</h6>
                  </Col>


                  {
                    SingleOrderList &&
                    SingleOrderList?.box_details?.map((el, index) => {
                      return (
                        <Col md="1" style={{ fontSize: "11px" }}>{el?.box_weight} KG</Col>
                      )
                    })
                  }


                </Row> */}

              </div>
            )
          }


        </div>

      </Modal>
      <Modal
        show={ShowBarcode}
        isOpen={ShowBarcode}
        toggle={() => setShowBarcode(!ShowBarcode)}
        size="md"
        centered={true}
      >
        <div className='modal-header'
          style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        >
          <h5 className='modal-title mt-0'>Order.No-{value}</h5>
          <button
            type='button'
            onClick={() => {
              setShowBarcode(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="close"
          ></button>
        </div>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }} className="modal-body">
          <Barcode value={value} format="CODE128"
            displayValue={false}
            width={2}
            height={50}
            ref={barcodeRef}
          />
          <Button
            // color="success"
            style={{ cursor: "pointer", backgroundColor: "#eb6517", color: "white", border: "none" }}

          >Print<i class="fa fa-print mx-1"></i></Button>
        </div>



      </Modal>




      {
        ConfirmDeleteAlert ? (
          <SweetAlert
            title=""
            showCancel
            confirmBtnText="Delete"
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              axios
                .delete(`${API_URL}order/delete?_id=${OrderIdToBeDeleted}`, {
                  headers: {
                    "x-access-token": accessToken,
                  }
                }).then((res) => {
                  if (res.data.status === 200) {
                    toastr.success("Order Deleted Successfully")
                    handleTableData();
                  } else {
                    toastr.error("Faild To Delete")
                    return;
                  }
                }).catch((err) => {
                  toastr.error(err.response.data.message)
                  return;
                })
              setConfirmDeleteAlert(false)
            }}
            onCancel={() => setConfirmDeleteAlert(false)}
          >
            Are you sure want to delete it?

          </SweetAlert>
        ) : null
      }
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Booking" />
          <Row>
            <Col className="col-12">

              {/* <Card>
                <CardBody> */}
              <Row>
                <Col md="2">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">From</Label>
                    {/* <div className="col-md-10"> */}
                    <input
                      className="form-control"
                      type="date"
                      id="from_date"
                      name="from_date"
                      value={searchData?.from_date}
                      onChange={handleDate}
                    />
                    {/* </div> */}
                  </div>
                </Col>
                <Col md="2">
                  <div className="mb-3">
                    <Label htmlFor="validationCustom05">To</Label>
                    <input
                      className="form-control"
                      type="date"
                      id="to_date"
                      name="to_date"
                      value={searchData?.to_date}
                      min={searchData?.from_date}
                      onChange={handleDate}
                    />
                    {/* </div> */}
                  </div>
                </Col>
                <Col md="2">
                  <div className="mb-3">
                    <Label>Cargo Type</Label>
                    <Select
                      //isMulti
                      name="type"
                      value={selectCargo}
                      options={CargoOptions}
                      onChange={(selected) => {
                        handleSelectChange(selected, "type");
                      }}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                <Col md="2">
                  <div className="mb-3">
                    <Label>Booking Type</Label>
                    <Select
                      //isMulti
                      name="booking_type"
                      value={selectedBookingType}
                      options={[{ label: "International", value: 0 }, { label: "Domestic", value: 1 }, { label: "All", value: "" }]}
                      onChange={(selected) => {
                        handleSelectChange(selected, "booking_type");
                      }}

                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>
                {
                  (searchData?.booking_type != 1) ? (
                    <>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Country From</Label>
                          <Select
                            //isMulti
                            name="country_from"
                            value={selecetfromcountry}
                            options={Fromcountryoptions}
                            onChange={(selected) => {
                              handleSelectChange(selected, "countryfrom");
                            }}

                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>Country To</Label>
                          <Select
                            name="country_to"
                            value={selectTocountry}
                            options={Tocountryoptions}
                            onChange={(selected) => {
                              handleSelectChange(selected, "countryto");
                            }}

                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                    </>

                  ) : (
                    <>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>City From</Label>
                          <Select
                            //isMulti
                            name="city_from"
                            value={selectedfromcity}
                            options={cityoptions}
                            onChange={(selected) => {
                              handleSelectChange(selected, "cityfrom");
                            }}

                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="2">
                        <div className="mb-3">
                          <Label>City To</Label>
                          <Select
                            name="city_to"
                            value={selectedtocity}
                            options={cityoptions}
                            onChange={(selected) => {
                              handleSelectChange(selected, "cityto");
                            }}

                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                    </>
                  )
                }



                <Col md="1">
                  <div className="mt-2 mb-3">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={() => handleSearch()}
                    >
                      Search
                    </Button>
                  </div>
                </Col>

                <Col md="1">
                  <div className="mt-2 mb-3">
                    <Button
                      color="danger"
                      type="reset"
                      onClick={() => reset()}
                    >
                      Reset
                    </Button>
                  </div>
                </Col>
              </Row>

              <MDBDataTable
                id="bookingTableId"
                responsive
                // striped
                bordered
                data={data}
                info={true}
                searching={true}
                disableRetreatAfterSorting={true}
                entries={20}
              />
              <div style={{ display: "none" }}>
                <PrintableComponent ref={printRef} data={SingleOrderList} />
              </div>
              <div style={{ display: "none" }}>
                <PrintableComponentOfficeUse ref={printRef2} data={SingleOrderList} />
              </div>
              <div style={{ display: "none" }}>
                <PrintableComponentDomestic ref={printRefDomestic} data={SingleOrderList1} />
              </div>
              <div style={{ display: "none" }}>
                <PrintableComponentOfficeUseDomestic ref={printRef2Domestic} data={SingleOrderList1} />
              </div>

              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>

        </div>
      </div>
    </React.Fragment>
  );
};

export default DaywiseReport;