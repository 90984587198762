import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

import {
  GET_SLIDER,
  ADD_SLIDER,
  UPDATE_SLIDER,
  DELETE_SLIDER,
} from "./actionTypes";

import {
  getAllSliderSuccess,
  getAllSliderFail,
  addSliderFail,
  addSliderSuccess,
  updateSliderSuccess,
  updateSliderFail,
  deleteSliderSuccess,
  deleteSliderFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  addSlider,
  getAllSlider,
  updateSlider,
  deleteSlider,
} from "../../helpers/backend_helper";

function* fetchSlider({localbody_id: localbody_id}) {
  try {
    const response = yield call(getAllSlider,localbody_id);
    yield put(getAllSliderSuccess(response));
  } catch (error) {
    yield put(getAllSliderFail(error));
  }
}

function* onAddSlider({ payload: data }) {
  try {
    const response = yield call(addSlider, data);
    yield put(addSliderSuccess(response));
  } catch (error) {
    yield put(addSliderFail(error.response));
  }
}

function* onUpdateSlider({ payload: data }) {
  try {
    const response = yield call(updateSlider, data);
    yield put(updateSliderSuccess(response));
  } catch (error) {
    yield put(updateSliderFail(error.response));
  }
}

function* ondeleteSlider({ payload: sliderId, auth_data: auth_user }) {
  try {
    const response = yield call(deleteSlider, sliderId, auth_user);
    yield put(deleteSliderSuccess(response));
  } catch (error) {
    yield put(deleteSliderFail(error.response));
  }
}

function* sliderSaga() {
  yield takeEvery(GET_SLIDER, fetchSlider);
  yield takeEvery(ADD_SLIDER, onAddSlider);
  yield takeEvery(UPDATE_SLIDER, onUpdateSlider);
  yield takeEvery(DELETE_SLIDER, ondeleteSlider);
}

export default sliderSaga;
