import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import axios from "axios";
import toastr, { error } from "toastr";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import {
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    Button,
    Label,
    CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
    getLocalbody,
} from "../../../helpers/globalFunctions";
import "./style.scss";
import { ResetTv } from "@mui/icons-material";

const CreditCustomer = () => {
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const formRef = useRef();
    let history = useHistory();
    const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
    const [masterObject, setmasterObject] = useState({ branch_id: currentLocalbody });

    const [custIdTobeUpdated, setcustIdToBeUpdated] = useState(null);
    const [custIdToBeDeleted, setcustIdToBeDeleted] = useState(null);

    const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
    const [custForTable, setcustForTable] = useState([]);
    const [isLoading, setisLoading] = useState("");
    const customStyles = {
        lineHeight: "1.8",
    };


    useEffect(() => {
        handleTableData();
    }, []);


    function handleTableData() {
        axios
            .get(`${API_URL}customer/credit-customer/list`, {
                headers: {
                    "x-access-token": accessToken,
                },
            }).then((res) => {

                let custData = [];
                let result = res.data.data;

                result.map((item, index) => {

                    item.action = (
                        <div style={{ display: "flex", justifyContent: "center" }}>

                            <i className="far fa-edit"
                                style={{
                                    cursor: "pointer",
                                    fontSize: "1em",
                                    marginLeft: "0.1rem",
                                    marginRight: "0.5rem"
                                }}
                                onClick={() => {
                                    toTop();
                                    preUpdateCust(item);
                                }}
                            ></i>

                            <i
                                className="far fa-trash-alt"
                                style={{ cursor: "pointer", fontSize: "1em" }}
                                onClick={() => {
                                    setcustIdToBeDeleted(item?._id);
                                    setConfirmDeleteAlert(true);
                                }}
                            ></i>
                        </div>
                    )

                    item.id = index + 1;
                    item.staff = item?.added_by?.firstName + " " + item?.added_by?.lastName;

                    custData.push(item);
                })

                setcustForTable(custData);
            })

    }


    const data = {
        columns: [
            {
                label: "#",
                field: "id",
                sort: "asc",
                width: 100
            },
            {
                label: "Name",
                field: "name",
                sort: "asc",
                width: 150,
            },
            {
                label: "Mobile",
                field: "mobile_number",
                sort: "asc",
                width: 200,
            },
            {
                label: "Email",
                field: "email",
                sort: "asc",
                width: 150,
            },
            {
                label: "Address",
                field: "address",
                sort: "asc",
                width: 150,
            },
            {
                label: "Staff",
                field: "staff",
                sort: "asc",
                width: 150,
            },
            {
                label: "Action",
                field: "action",
                sort: "asc",
                width: 150
            },
        ],
        rows: custForTable,
    }


    const toTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };


    let preUpdateCust = (item) => {
        setcustIdToBeUpdated(item?._id);
        setmasterObject(item);
    }


    const handleChangeInput = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setmasterObject({ ...masterObject, [name]: value })
    }


    const reset = () => {
        setmasterObject({ name: "", mobile: "", email: "", address: "" });
        setcustIdToBeUpdated(null);
    }

    function handleValidSubmit() {

        let data = {
            customer_id: masterObject?._id,
            name: masterObject?.name,
            mobile_number: masterObject?.mobile_number,
            address: masterObject?.address,
        }

        if (custIdTobeUpdated) {
            axios
                .put(`${API_URL}customer/credit-customer`, data, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                }).then((res) => {

                    if (res.data.status === 200) {
                        toastr.success("Customer updated successfully");
                        formRef.current.reset();
                        setmasterObject({ branch_id: currentLocalbody });
                        setcustIdToBeUpdated(null);
                        handleTableData();
                    } else {
                        toastr.error("Failed to update customer");
                        return;
                    }
                })
                .catch((err) => {
                    toastr.error(err.response.data.message);
                    return;
                });

        } else {
            axios
                .post(`${API_URL}customer/credit-customer`, masterObject, {
                    headers: {
                        "x-access-token": accessToken,
                    },
                }).then((res) => {

                    if (res.data.status === 200) {
                        toastr.success("Customer added succcessfully");
                        formRef.current.reset();
                        handleTableData();
                        setmasterObject({});
                    } else {
                        toastr.error("Failed to add customer");
                        return;
                    }
                })
                .catch((err) => {
                    toastr.error(err.response.data.message);
                    return;
                })

        }
    }


    return (
        <React.Fragment>
            {confirmDeleteAlert ? (
                <SweetAlert
                    title=""
                    showCancel
                    confirmButtonText="Delete"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        axios
                            .delete(`${API_URL}customer/${custIdToBeDeleted}`, {
                                headers: {
                                    "x-access-token": accessToken,
                                },
                            }).then((res) => {
                                if (res.data.status === 200) {
                                    toastr.success("Customer deleted successfully");
                                    handleTableData();
                                    formRef.current.reset();
                                    setmasterObject({});
                                    setcustIdToBeDeleted(null);
                                    setcustIdToBeUpdated(null);
                                } else {
                                    toastr.error(error.res.data.message);
                                    return;
                                }
                            })
                        setConfirmDeleteAlert(false);
                    }}
                >
                    Are you sure you want to delete it?
                </SweetAlert>
            ) : null}

            <div className="page-content">
                <div className="container-fluid">
                    <Breadcrumbs title="Home" breadcrumbItem="Manage Credit Customers" />

                    <Row>
                        <Col className="col-12">
                            <AvForm
                                ref={formRef}
                                className="needs-validation"
                                onValidSubmit={(e, v) => {
                                    handleValidSubmit(e, v)
                                }}
                            >

                                <Row>
                                    <Col md="3">
                                        <Label>Name</Label>
                                        <AvField
                                            name="name"
                                            placeholder="Name"
                                            type="text"
                                            value={masterObject?.name}
                                            onChange={handleChangeInput}
                                            validate={{ required: { value: true } }}
                                            errorMessage="Enter Name"
                                        />
                                    </Col>

                                    <Col md="3">
                                        <Label>Mobile</Label>
                                        <AvField
                                            name="mobile_number"
                                            placeholder="Mobile"
                                            type="number"
                                            value={masterObject?.mobile_number}
                                            onChange={handleChangeInput}
                                            validate={{ required: { value: true } }}
                                            errorMessage="Enter Mobile"
                                        />
                                    </Col>

                                    <Col md="3">
                                        <Label>Email</Label>
                                        <AvField
                                            name='email'
                                            type="email"
                                            placeholder="Email"
                                            value={masterObject?.email}
                                            onChange={handleChangeInput}
                                            validate={{ required: { value: true } }}
                                            errorMessage="Enter email"
                                        />
                                    </Col>

                                    <Col md="3">
                                        <Label>Address</Label>
                                        <AvField
                                            name="address"
                                            type="textarea"
                                            placeholder="Address"
                                            value={masterObject?.address}
                                            onChange={handleChangeInput}
                                            validate={{ required: { value: true } }}
                                            errorMessage="Enter Address"
                                        />
                                    </Col>

                                    <Col>
                                        <div className="mb-3 mt-2">
                                            {custIdTobeUpdated ? (
                                                <Button type="update" color="primary">
                                                    Update
                                                </Button>
                                            ) : (
                                                <Button type="submit" color="primary">
                                                    Submit
                                                </Button>
                                            )}


                                            <Button className="ms-2" color="danger" type="reset" onClick={() => reset()}>
                                                Reset
                                            </Button>

                                        </div>
                                    </Col>
                                </Row>

                            </AvForm>
                        </Col>
                    </Row>

                    <Row>
                        <Col md="12">
                            <MDBDataTable
                                responsive
                                bordered
                                id="creditcustTablesId"
                                searching={true}
                                info={true}
                                data={data}
                                entries={20}
                            />
                        </Col>
                    </Row>

                </div>
            </div>
        </React.Fragment>
    )
}


export default CreditCustomer;