import React, { useRef, useEffect, useState } from 'react';
import Select from 'react-select';
import { MDBDataTable } from "mdbreact";
import toastr from 'toastr';
import { Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumb from '../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "./bookingenquiry.scss"
import axios from 'axios';
import accessToken from '../../helpers/jwt-token-access/accessToken';
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import $ from "jquery";

function Enquiry() {
  const [addEnquiry, setaddEnquiry] = useState({})
  const [countryOptions, setcountryOptions] = useState([]);
  const [selectCountry, setselectCountry] = useState(null)

  const [timeOptions, settimeOptions] = useState([]);
  const [selecttime, setselecttime] = useState(null);
  const [stateOptions, setstateOptions] = useState([]);
  const [selectstate, setselectstate] = useState(null);
  const [selectCurrency, setselectCurrency] = useState(null);

  const [popupData, setpopupData] = useState({});
  const [popupView, setpopupView] = useState(false)
  const [enquiryIdToBeUpdated, setenquiryIdToBeUpdated] = useState(null);
  const [itemForTable, setitemForTable] = useState([]);
  const [confirmDeletAlert, setconfirmDeletAlert] = useState(null)
  const [enquiryIdToBeDeleted, setenquiryIdToBeDeleted] = useState(null)
  const [searchObject, setSearchObject] = useState({});


  const [errors, seterrors] = useState({
    country: ""
  })

  useEffect(() => {
    fetch_all_country();
    handleTableData();
  }, [])

  let history = useHistory();

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const formRef = useRef();

  const customestyle = {
    height: "auto",
  };

  const fetch_all_country = () => {
    axios
      .get(`${API_URL}newStates/countries`, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {

        var country_data =
          res.data.data &&
          res.data.data.map((country) => {

            return {
              label: country?.name,
              value: country?._id,

            };


          })
        setcountryOptions([
          {
            options: country_data,
          },
        ]);
      });
  }

  const Ontop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  let Preupdateitem = (item) => {
    setenquiryIdToBeUpdated(item._id)
    axios
      .get(`${API_URL}bookingEnquiry/singleList?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let cty = item?.destination
        let ctyop = {
          label: cty?.name,
          value: cty?._id
        };
        setselectCountry(ctyop);
        setaddEnquiry(result);
      })
  }

  const reset = () => {
    formRef.current.reset();
    setaddEnquiry({});
    setaddEnquiry({ adress: "" })
    setenquiryIdToBeUpdated(null);
    setselectCountry(null);
    setselectCurrency(null);
    setselectstate(null);
    setselecttime(null);
    seterrors({
      country: "",
    })
  }

  function handleInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    setaddEnquiry({ ...addEnquiry, [name]: value });
  }

  // add edit list
  const handleValidSubmit = () => {

    if (selectCountry === null
    ) {
      let errorVal = errors;
      if (selectCountry === null)
        errorVal.country = "Please Select Country"
      seterrors({
        ...errorVal,
      });
    }

    else {
      if (enquiryIdToBeUpdated) {
        axios
          .put(`${API_URL}bookingEnquiry/edit`, addEnquiry, {
            headers: {
              "x-access-token": accessToken
            }
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Booking Enquiry Updated Successfully");
              formRef.current.reset();
              handleTableData();
              setaddEnquiry({});
              setenquiryIdToBeUpdated(null);
              setselectCountry(null);
            }
            else {
              toastr.error("Faild to Update")
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message)
            return;
          })
      }
      else {
        axios
          .post(`${API_URL}bookingEnquiry`, addEnquiry, {
            headers: {
              "x-access-token": accessToken,
            }
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Booking Enquiry Added Successfully")
              formRef.current.reset();
              handleTableData();
              setaddEnquiry({});
              setselectCountry(null);
              seterrors({
                country: "",
              })
            }
            else {
              toastr.error("Failed to Add Booking Enquiry");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      };
    }
  }

  //view
  const handleClickOpenForm = (item) => {
    axios
      .get(`${API_URL}bookingEnquiry/singleList?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;


        if (result.date) {
          result.dates = moment(result.date).format("DD-MM-YYYY")
        }

        if (result.destination) {
          result.destination1 = (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id="button-tooltip-2"
                  style={{ position: "absolute", top: "-30px" }}
                >
                  {result?.destination?.name}
                </Tooltip>
              }
            >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src={`${API_URL}uploads/country_flag/${result?.destination?.flag}`} width={"30px"}></img>
              </div>
            </OverlayTrigger>);
        }

        if (result.source == 1) {
          result.source1 = "Website"
        }
        if (result.source == 0) {
          result.source1 = "CRM"
        }

        if (result.booking_status == 1) {
          result.status1 = "Booked"
        }
        if (result.booking_status == 0) {
          result.status1 = "New"
        }
        setpopupData(result);
        setpopupView(true);
      })
  }

  // table data
  function handleTableData(from_date = "", to_date = "") {
    axios
      .get(`${API_URL}bookingEnquiry/list?from_date=${from_date}&to_date=${to_date}`, {
        headers: {
          "x-access-token": accessToken
        },
      })
      .then((res) => {

        var result = res.data.data
        let enquiryData = [];
        result.map((item, index) => {
          item.id = index + 1
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="uil-eye"
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  marginRight: "0.4rem",
                  marginTop: "0.4rem",
                }}
                onClick={() => {
                  handleClickOpenForm(item);
                }}
              ></i>

              <i className='uil-edit-alt'
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  marginTop: "0.4rem",
                  marginRight: "0.4rem"
                }} onClick={() => {
                  Ontop();
                  Preupdateitem(item);
                }}
              ></i>
              <i className='uil-trash-alt'
                style={{ fontSize: "1rem", cursor: "pointer", marginRight: "0.4rem", marginTop: "0.4rem", }}
                onClick={() => {
                  setenquiryIdToBeDeleted(item._id);
                  setconfirmDeletAlert(true);

                }}
              ></i>
              <i
                className="uil-check-circle"
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  marginTop: "0.4rem",
                }}
                onClick={() => {
                  //  
                  history.push("/processing");
                }}
              ></i>
            </div>
          )
          if (item.date) {
            item.dates = moment(item.date).format("DD-MM-YYYY")
          }

          if (item.destination) {
            item.destination1 = (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "absolute", top: "-30px" }}
                  >
                    {item?.destination?.name}
                  </Tooltip>
                }
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={`${API_URL}uploads/country_flag/${item?.destination?.flag}`} width={"30px"}></img>
                </div>
              </OverlayTrigger>);
          }

          if (item.source == 1) {
            item.source1 = "Website"
          }
          if (item.source == 0) {
            item.source1 = "CRM"
          }

          if (item.booking_status == 1) {
            item.status1 = "Booked"
          }
          if (item.booking_status == 0) {
            item.status1 = "New"
          }

          enquiryData.push(item)
        })
        setitemForTable(enquiryData)
      });
  }

  //select options
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "country":
        setselectCountry(selected);
        setaddEnquiry({ ...addEnquiry, destination: selected.value })
        break;
      default:
        break
    }
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150
      },
      {
        label: "Date",
        field: "dates",
        sort: "asc",
        width: 150
      },
      {
        label: "To",
        field: "destination1",
        sort: "asc",
        width: 150
      },
      {
        label: "Shipper Name",
        field: "sender_name",
        sort: "asc",
        width: 150
      },
      {
        label: "Shipper Mobile",
        field: "sender_mobile_no",
        sort: "asc",
        width: 150
      },
      {
        label: " Consignee Name",
        field: "receiver_name",
        sort: "asc",
        width: 150
      },
      {
        label: "BoxNo",
        field: "number_of_boxes",
        sort: "asc",
        width: 150
      },
      {
        label: "Weight",
        field: "total_weight",
        sort: "asc",
        width: 150
      },
      {
        label: "Source",
        field: "source1",
        sort: "asc",
        width: 150
      },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 150
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150
      },
    ],
    rows: itemForTable,

  }


  let handleDate = (e) => {
    var from_date = $("#from_date").val();
    var to_date = $("#to_date").val();
    let toDate = moment(from_date);
    let fromDate = moment(to_date);
    let result = fromDate.diff(toDate, "days");
    if (result + 1 <= 0) {
      setSearchObject({
        ...searchObject,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      to_date = "";
    } else {
      setSearchObject({
        ...searchObject,
        [e.target.name]: e.target.value,
      });
    }
    // handleTableData(from_date, to_date);
  };

  const handleSearch = () => {
    let from_date = searchObject?.from_date ? searchObject.from_date : "";
    let to_date = searchObject?.to_date ? searchObject.to_date : "";
    handleTableData(from_date, to_date);
  }

  const resetSearch = () => {
    setSearchObject({
      from_date: "",
      to_date: ""
    });
    handleTableData("", "")
  }

  const customStyles = {
    lineHeight: "1.8",
  };

  return (

    <React.Fragment>
      {confirmDeletAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}bookingEnquiry/delete?_id=${enquiryIdToBeDeleted}`, {
                headers: {
                  "x-access-token": accessToken,
                }
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Booking Enquiry Deleted Successfully");
                  handleTableData();
                  setenquiryIdToBeDeleted(null);
                  reset();
                  setaddEnquiry({})
                  setselectCountry(null);
                  setenquiryIdToBeUpdated(null);
                } else {
                  toastr.error("faild to delete")
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setconfirmDeletAlert(false);
          }}
          onCancel={() => setconfirmDeletAlert(false)}
        >
          Are you sure want to delete
        </SweetAlert>
      ) : null}


      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setpopupView(!popupView)}
        size="xl"
        centered={true}>

        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Booking Enquiry Details {/*- {popupData?.status1}*/}</h5>
          <button
            type='button'
            onClick={() => {
              setpopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className='modal-body'>
            <table
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Destination</td>
                <td>
                  <img src={`${API_URL}uploads/country_flag/${popupData?.destination?.flag}`}
                    width={"85px"} height={"55px"}
                    title={`${popupData?.destination?.name}`}
                  >
                  </img></td>
                <td style={{ paddingLeft: "25px" }}>Source </td>
                <td style={{ textAlign: "left" }}>: {popupData?.source1}</td>
                <td style={{ paddingLeft: "25px" }}>Date</td>
                <td style={{ textAlign: "left" }}>: {popupData?.dates}
                </td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Shipper Name</td>
                <td >: {popupData?.sender_name}</td>
                <td style={{ paddingLeft: "25px" }}>Shipper Civil ID</td>
                <td style={{ textAlign: "left" }}>: {popupData?.sender_civil_id}</td>
                <td style={{ paddingLeft: "25px" }}>Mobile</td>
                <td style={{ textAlign: "left" }}>: {popupData?.sender_mobile_no}</td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Email ID</td>
                <td>: {popupData?.sender_email}</td>
                <td style={{ paddingLeft: "25px" }}>Consignee Name</td>
                <td style={{ textAlign: "left" }}>: {popupData?.receiver_name}</td>
                <td style={{ paddingLeft: "25px" }}>Consignee Mobile No.</td>
                <td style={{ textAlign: "left" }}>: {popupData?.receiver_mobile_number}</td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Consignee HouseName</td>
                <td >: {popupData?.receiver_house_name}</td>
                <td style={{ paddingLeft: "25px" }}>Consignee Street</td>
                <td style={{ textAlign: "left" }}>: {popupData?.receiver_street}</td>
                <td style={{ paddingLeft: "25px" }}>Consignee Post Office</td>
                <td style={{ textAlign: "left" }}>: {popupData?.receiver_post_office}</td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Consignee Pincode</td>
                <td >:  {popupData?.receiver_pincode}</td>
                <td style={{ paddingLeft: "25px" }}>Consignee District</td>
                <td style={{ textAlign: "left" }}>:  {popupData?.receiver_district}</td>
                <td style={{ paddingLeft: "25px" }}>Consignee State</td>
                <td style={{ textAlign: "left" }}>:  {popupData?.receiver_state} </td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Consignee Phone No.</td>
                <td >: {popupData?.receiver_telephone_number}</td>
                <td style={{ paddingLeft: "25px" }}>Shipment Total Weight</td>
                <td style={{ textAlign: "left" }}>: {popupData?.total_weight}</td>
                <td style={{ paddingLeft: "25px" }}>Shipment Box Number</td>
                <td style={{ textAlign: "left" }}>: {popupData?.number_of_boxes}</td>
              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Coupon Code</td>
                <td >: {popupData?.coupen_code}</td>
                <td style={{ paddingLeft: "25px" }}>Comments</td>
                <td colSpan={3} style={{ textAlign: "left" }}>: {popupData?.comment}</td>
              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>

      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumb title="Home" breadcrumbItem="Booking Enquiry" />
          <Row>
            <Col xl="12">
              {/* <Card >
                <CardBody > */}
                  <AvForm
                    ref={formRef}
                    className="needs-validation"
                    onValidSubmit={(e, v) => {
                      handleValidSubmit(e, v);
                    }}
                  >
                    <Row>
                      <Col md="3">
                        <div className='mb-3'>
                          <Label>Destination</Label>
                          <Select
                            name="country"
                            value={selectCountry}
                            placeholder="Select Country"
                            options={countryOptions}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSelectChange(selected, "country")
                            }}
                          />
                          <p className='text-danger'
                            style={{ fontSize: "11px" }}>
                            {selectCountry === null ? errors.country : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className='mb-3'>
                          <Label>Shipper Name</Label>
                          <AvField
                            style={customStyles}
                            name="sender_name"
                            placeholder="Shipper Name"
                            type="text"
                            value={addEnquiry?.sender_name}
                            onChange={handleInput}
                            errorMessage="Enter Shipper Name"
                            validate={{ required: { value: true } }}
                            className="form-control" />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label >
                            Shipper Civil ID
                          </Label>
                          <AvField
                            style={customStyles}
                            name="sender_civil_id"
                            placeholder="Shipper Civil ID"
                            type="text"
                            value={addEnquiry?.sender_civil_id}
                            onChange={handleInput}
                            errorMessage="Enter Civil ID "
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label >
                            Shipper Mobile No.
                          </Label>
                          <AvField
                            style={customStyles}
                            name="sender_mobile_no"
                            placeholder="Shipper Mobile No."
                            type="number"//tel"
                            value={addEnquiry?.sender_mobile_no}
                            onChange={handleInput}
                            errorMessage="Enter Shipper Mobile No."
                            className="form-control"
                            validate={{ required: { value: true } }}
                          // minLength={10}
                          // maxLength={10}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label >Shipper Email ID</Label>
                          <AvField
                            style={customStyles}
                            name="sender_email"
                            placeholder="Shipper Email ID"
                            type="email"
                            value={addEnquiry?.sender_email}
                            onChange={handleInput}
                            validate={{ required: { value: true } }}
                            errorMessage="Enter a valid Email ID."
                            className="form-control"
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className='mb-3'>
                          <Label>Consignee Name</Label>
                          <AvField
                            style={customStyles}
                            name="receiver_name"
                            placeholder="Consignee Name"
                            type="text"
                            value={addEnquiry?.receiver_name}
                            onChange={handleInput}
                            errorMessage="Enter Consignee Name"
                            validate={{ required: { value: true } }}
                            className="form-control" />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className='mb-3'>
                          <Label>Consignee House Name</Label>
                          <AvField
                            style={customStyles}
                            name="receiver_house_name"
                            placeholder="Consignee House Name"
                            type="text"
                            value={addEnquiry?.receiver_house_name}
                            onChange={handleInput}
                            errorMessage="Enter Consignee House Name"
                            validate={{ required: { value: true } }}
                            className="form-control" />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className='mb-3'>
                          <Label>Consignee Street</Label>
                          <AvField
                            style={customStyles}
                            name="receiver_street"
                            placeholder="Consignee Street"
                            type="text"
                            value={addEnquiry?.receiver_street}
                            onChange={handleInput}
                            errorMessage="Enter Consignee Street"
                            validate={{ required: { value: true } }}
                            className="form-control" />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className='mb-3'>
                          <Label>Consignee Post Office</Label>
                          <AvField
                            style={customStyles}
                            name="receiver_post_office"
                            placeholder="Consignee Post Office"
                            type="text"
                            value={addEnquiry?.receiver_post_office}
                            onChange={handleInput}
                            errorMessage="Enter Consignee Post Office"
                            validate={{ required: { value: true } }}
                            className="form-control" />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label >Consignee Pincode</Label>
                          <AvField
                            style={customStyles}
                            name="receiver_pincode"
                            placeholder="Consignee Pincode"
                            type="number"
                            value={addEnquiry?.receiver_pincode}
                            onChange={handleInput}
                            // errorMessage="Please provide a Pincode."
                            className="form-control"
                            errorMessage="Enter Consignee Pincode"
                            validate={{ required: { value: true } }}
                          // validate={{
                          //   pattern: {
                          //     value: "^[0-9]+$",
                          //     errorMessage: "PIN must be only with numbers",
                          //   },
                          // }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className='mb-3'>
                          <Label>Consignee District</Label>
                          <AvField
                            style={customStyles}
                            name="receiver_district"
                            placeholder="Consignee District"
                            type="text"
                            value={addEnquiry?.receiver_district}
                            onChange={handleInput}
                            errorMessage="Enter Consignee District"
                            validate={{ required: { value: true } }}
                            className="form-control" />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label>Consignee State</Label>
                          <AvField
                            style={customStyles}
                            name="receiver_state"
                            placeholder="Consignee State"
                            type="text"
                            value={addEnquiry?.receiver_state}
                            onChange={handleInput}
                            errorMessage="Enter Consignee State"
                            validate={{ required: { value: true } }}
                            className="form-control" />
                          {/* <Select
                            name="receiver_state"
                            value={selectstate}
                            placeholder="Select State"
                            options={stateOptions}
                            classNamePrefix="select2-selection"
                            onChange={(selected) => {
                              handleSelectChange(selected, "state")
                            }}
                          />
                          <p className='text-danger'
                            style={{ fontSize: "11px" }}>
                            {selectstate === null ? errors.state : ""}
                          </p> */}
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label >Consignee Mobile No.</Label>
                          <AvField
                            style={customStyles}
                            name="receiver_mobile_number"
                            placeholder="Consignee Mobile No."
                            type="number"
                            value={addEnquiry?.receiver_mobile_number}
                            onChange={handleInput}
                            errorMessage="Enter Consignee Mobile No."
                            className="form-control"
                            validate={{ required: { value: true } }}

                          // minLength={10}
                          // maxLength={10}
                          />
                        </div>
                      </Col>

                      <Col md="3">
                        <div className="mb-3">
                          <Label >
                            Consignee Phone No.
                          </Label>
                          <AvField
                            style={customStyles}
                            name="receiver_telephone_number"
                            placeholder="  Consignee Phone No."
                            type="number"
                            value={addEnquiry?.receiver_telephone_number}
                            onChange={handleInput}
                            errorMessage="Enter Consignee Phone No."
                            // className="form-control"
                            validate={{ required: { value: true } }}
                          // id="validationCustom04"
                          // minLength={11}
                          // maxLength={11}
                          />
                        </div>
                      </Col>


                      <Col md="3">
                        <div className="mb-3">
                          <Label >
                            Shipment Total Weight(Kg)
                          </Label>
                          <AvField
                            style={customStyles}
                            name="total_weight"
                            placeholder="Total Weight"
                            type="number"
                            value={addEnquiry?.total_weight}
                            onChange={handleInput}
                            errorMessage="Enter Shipment Total Weight(kg)"
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label >
                            Shipment No.Of.Boxes
                          </Label>
                          <AvField
                            style={customStyles}
                            name="number_of_boxes"
                            placeholder="Box Numbers"
                            type="number"
                            value={addEnquiry?.number_of_boxes}
                            onChange={handleInput}
                            errorMessage="Enter Shipment No.Of.Boxes"
                            className="form-control"
                            validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label >
                            Coupon Code
                          </Label>
                          <AvField
                            style={customStyles}
                            name="coupen_code"
                            placeholder="Coupon Code"
                            type="text"
                            value={addEnquiry?.coupen_code}
                            onChange={handleInput}
                          // errorMessage="Enter Coupon Code"
                          // className="form-control"
                          // validate={{ required: { value: true } }}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label >Comment</Label>
                          <AvField
                            style={customestyle}
                            name="comment"
                            placeholder="Comment"
                            type="textarea"
                            value={addEnquiry?.comment}
                            onChange={handleInput}
                            errorMessage="Enter Comment"
                            // className="form-control"
                            validate={{ required: { value: true } }}

                          />
                        </div>
                      </Col>

                      <Col md="3" style={{ paddingTop: "4px" }}>
                        <div className='mt-4'>
                          {enquiryIdToBeUpdated ? (
                            <Button className='mx-2'
                              color='primary'
                              type='Update'>
                              Update
                            </Button>
                          ) : <Button className='mx-2'
                            color='primary'
                            type='submit'>
                            Submit
                          </Button>}

                          <Button
                            color='danger'
                            type="reset"
                            onClick={() => reset()}
                          >

                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                {/* </CardBody>
              </Card> */}
            </Col>
          </Row>

          <Row>
            <Col className="col-12">
              {/* <Card style={{ padding: "0px 0px 2px 0px" }}>
                <CardBody> */}
                  <Row >
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          value={searchObject?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        {/* <div className="col-md-10"> */}
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          value={searchObject?.to_date}
                          min={searchObject?.from_date}
                          onChange={handleDate}
                        />
                        {/* </div> */}
                      </div>
                    </Col>


                    <Col
                      md="2"
                      // className="mt-4"
                      style={{ paddingTop: "25px" }}
                    >
                      <div className="mb-3">
                        <Button
                          color="success"
                          type="submit"
                          style={{ marginRight: "5%" }}
                          onClick={() => handleSearch()}
                        >
                          {"  "}
                          Filter
                        </Button>
                        <Button
                          color="danger"
                          type="submit"
                          style={{ marginRight: "5%" }}
                          onClick={() => resetSearch()}
                        >
                          {"  "}
                          Reset
                        </Button>

                      </div>
                    </Col>



                    <MDBDataTable
                      responsive
                      id="BookingEnqtable"
                      bordered
                      data={data}
                      entries={20} />
                  </Row>
                {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Enquiry