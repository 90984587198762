export const GET_CUST_CATEGORIES = "GET_CUST_CATEGORIES";
export const GET_CUST_CATEGORIES_SUCCESS = "GET_CUST_CATEGORIES_SUCCESS";
export const GET_CUST_CATEGORIES_FAIL = "GET_CUST_CATEGORIES_FAIL";

export const GET_CUST_CATEGORY = "GET_CUST_CATEGORY";
export const GET_CUST_CATEGORY_SUCCESS = "GET_CUST_CATEGORY_SUCCESS";
export const GET_CUST_CATEGORY_FAIL = "GET_CUST_CATEGORY_FAIL";

export const ADD_CUST_CATEGORY = "ADD__CUST_CATEGORY";
export const ADD_CUST_CATEGORY_SUCCESS = "ADD_CUST_CATEGORY_SUCCESS";
export const ADD_CUST_CATEGORY_FAIL = "ADD_CUST_CATEGORY_FAIL";

export const UPDATE_CUST_CATEGORY= "UPDATE_CUST_CATEGORY";
export const UPDATE_CUST_CATEGORY_SUCCESS = "UPDATE_CUST_CATEGORY_SUCCESS";
export const UPDATE_CUST_CATEGORY_FAIL = "UPDATE_CUST_CATEGORY_FAIL";

export const DELETE_CUST_CATEGORY = "DELETE_CUST_CATEGORY";
export const DELETE_CUST_CATEGORY_SUCCESS = "DELETE_CUST_CATEGORY_SUCCESS";
export const DELETE_CUST_CATEGORY_FAIL = "DELETE_CUST_CATEGORY_FAIL";

export const GET_CUST_CATEGORIES_OPTIONS = "GET_CUST_CATEGORIES_OPTIONS";
export const GET_CUST_CATEGORIES_OPTIONS_SUCCESS = "GET_CUST_CATEGORIES_OPTIONS_SUCCESS";
export const GET_CUST_CATEGORIES_OPTIONS_FAIL = "GET_CUST_CATEGORIES_OPTIONS_FAIL";
