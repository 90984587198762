import {
  GET_COMPANIES_OPTIONS,
  GET_COMPANIES_OPTIONS_SUCCESS,
  GET_COMPANIES_OPTIONS_FAIL,
  GET_COMPANIES_MASTER_OPTIONS,
  GET_COMPANIES_MASTER_OPTIONS_SUCCESS,
  GET_COMPANIES_MASTER_OPTIONS_FAIL,
  SAVE_SELECTED_COMPANY,
  SAVE_SELECTED_COMPANY_SUCCESS,
  SAVE_SELECTED_COMPANY_FAIL,
  GET_LOCALBODIES_MASTER_OPTIONS,
  GET_LOCALBODIES_MASTER_OPTIONS_SUCCESS,
  GET_LOCALBODIES_MASTER_OPTIONS_FAIL,
  ADD_COMPANY,
  ADD_COMPANY_SUCCESS,
  ADD_COMPANY_FAIL,
  UPDATE_COMPANY,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAIL,
  DELETE_COMPANY,
  DELETE_COMPANY_SUCCESS,
  DELETE_COMPANY_FAIL,
} from "./actionTypes";

export const getCompaniesOptions = () => ({
  type: GET_COMPANIES_OPTIONS,
});

export const getCompaniesOptionsSuccess = (companies) => ({
  type: GET_COMPANIES_OPTIONS_SUCCESS,
  payload: companies,
});

export const getCompaniesOptionsFail = (error) => ({
  type: GET_COMPANIES_OPTIONS_FAIL,
  payload: error,
});

export const getCompaniesMasterOptions = (company_id) => ({
  type: GET_COMPANIES_MASTER_OPTIONS,
  company_id: company_id,
});

export const getCompaniesMasterOptionsSuccess = (companies) => ({
  type: GET_COMPANIES_MASTER_OPTIONS_SUCCESS,
  payload: companies,
});

export const getCompaniesMasterOptionsFail = (error) => ({
  type: GET_COMPANIES_MASTER_OPTIONS_FAIL,
  payload: error,
});

export const saveCompanyName = (data) => ({
  type: SAVE_SELECTED_COMPANY,
  payload: data,
});

export const saveCompanyNameSuccess = (company) => ({
  type: SAVE_SELECTED_COMPANY_SUCCESS,
  payload: company,
});

export const saveCompanyNameFail = (error) => ({
  type: SAVE_SELECTED_COMPANY_FAIL,
  payload: error,
});

export const getLocalbodiesMasterOptions = () => ({
  type: GET_LOCALBODIES_MASTER_OPTIONS,
});

export const getLocalbodiesMasterOptionsSuccess = (localbodies) => ({
  type: GET_LOCALBODIES_MASTER_OPTIONS_SUCCESS,
  payload: localbodies,
});

export const getLocalbodiesMasterOptionsFail = (error) => ({
  type: GET_LOCALBODIES_MASTER_OPTIONS_FAIL,
  payload: error,
});


export const addCompany = (data) => ({
  type: ADD_COMPANY,
  payload: data,
});

export const addCompanySuccess = (data) => ({
  type: ADD_COMPANY_SUCCESS,
  payload: data,
});

export const addCompanyFail = (error) => ({
  type: ADD_COMPANY_FAIL,
  payload: error,
});

export const updateCompany = (data) => ({
  type: UPDATE_COMPANY,
  payload: data,
});

export const updateCompanySuccess = (data) => ({
  type: UPDATE_COMPANY_SUCCESS,
  payload: data,
});

export const updateCompanyFail = (error) => ({
  type: UPDATE_COMPANY_FAIL,
  payload: error,
});

export const deleteCompany = (data, auth_user) => ({
  type: DELETE_COMPANY,
  payload: data,
  auth_data: auth_user,
});

export const deleteCompanySuccess = (data) => ({
  type:DELETE_COMPANY_SUCCESS,
  payload: data,
});

export const deleteCompanyFail = (error) => ({
  type: DELETE_COMPANY_FAIL,
  payload: error,
});
