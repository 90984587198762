import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
import Resete from "@mui/icons-material/ResetTvRounded";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import defaultProfile from "../../../assets/images/person-icon.png";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./ViewCustomer.scss";
import { Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";

import { getUsers } from "../../../store/actions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody, getCompany } from "../../../helpers/globalFunctions";


const ViewCustomer = () => {
  const formRef = useRef();

  const [viewClientObject, setViewClientObject] = useState({});
  const [viewClientValue, setViewClientValue] = useState("");
  const [currentLocalbody, setCurrentLocalbody] = useState(getLocalbody());
  const [currentCompany, setCurrectCompany] = useState(getCompany());
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  let history = useHistory();

  const [tableData, setTableData] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(getUsers());
    handleTableData();
  }, [currentLocalbody]);
  function handleTableData(search = "") {

    var url = `${API_URL}customer/list?search=${search}`;

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        result.map((item, index) => {
          item.id = index + 1;
          // if(item.cust_country){ 
          //   item.country_icon=(
          //     <OverlayTrigger
          //     placement="top"
          //     overlay={
          //       <Tooltip
          //         id="button-tooltip-2"
          //         style={{ position: "absolute", top: "-30px" }}
          //       >
          //     {item?.cust_country?.name}
          //       </Tooltip>
          //     }
          //   >
          //   <div style={{ display: "flex", justifyContent: "center" }}>
          //   <img src={`${API_URL}uploads/flag/${item?.cust_country?.flag}`} width={"30px"}></img>
          // </div>
          // </OverlayTrigger>);
          // }
          setTableData(result);
        });
      });

    //     var result=[
    //       {
    //         name:"KamalRaj",
    //         mobile:"9099865790",
    //         country_icon:"https://cdn.britannica.com/73/5773-004-F7C13E3D/Flag-Oman.jpg",
    //         country_name:"Oman"

    //     },
    //     {
    //       name:"BALAKRISHNA SIRRA",
    //       mobile:"9087865791",
    //       country_icon:"https://cdn.britannica.com/73/5773-004-F7C13E3D/Flag-Oman.jpg",
    //       country_name:"Oman"

    //   },
    //   {
    //     name:"WASIM AHMED",
    //     mobile:"8799865790",
    //     country_icon:"https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg",
    //     country_name:"Kuwait"

    // },
    // {
    //   name:"NOOR ALAM KHAN",
    //   mobile:"8706865790",
    //   country_icon:"https://cdn.britannica.com/76/5776-050-74220DEB/Flag-Qatar.jpg?w=400&h=235&c=crop",
    //   country_name:"Qatar"

    // },
    // {
    //   name:"QURBAN ALI",
    //   mobile:"8799809790",
    //   country_icon:"https://upload.wikimedia.org/wikipedia/commons/a/aa/Flag_of_Kuwait.svg",
    //   country_name:"Kuwait"

    // }
    //   ]
    // result.map((item, index) => {
    //         item.id = index + 1;

    //       });

  }
  function handleChangeOurProject(e) {
    let name = e.target.name;
    let value = e.target.value;
    setViewClientValue(value);
    setViewClientObject({ ...viewClientObject, [name]: value });
  }
  const handleSearch = () => {
    let search = viewClientObject?.search ? viewClientObject.search : "";

    handleTableData(search);
  };

  const customStyles = {
    lineHeight: "1.8",
  };
  const reset = () => {
    formRef.current.reset();
    handleTableData();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="View Customer" />
          <Row>
            <Col xl="12">
              {/* <Card style={{ padding: "0px 0px 2px 0px" }}>
                <CardBody> */}
              <AvForm ref={formRef}>
                <Row>
                  <Col md="2">
                    <div className="mb-3">
                      <AvField
                        style={customStyles}
                        name="search"
                        placeholder="Search Name/Mobile"
                        type="text"
                        errorMessage="Search something"
                        className="form-control"
                        id="validationCustom02"
                        value={viewClientObject?.search}
                        onChange={handleChangeOurProject}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-2">
                      <Button
                        color="success"
                        type="submit"
                        style={{ marginRight: "5%" }}
                        onClick={() => handleSearch()}
                      >
                        Filter
                      </Button>

                      <Button
                        color="danger"
                        type="reset"
                        onClick={() => reset()}
                      >
                        {"  "}
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>


          <Row>
            {tableData?.map((item) => (
              <div className="col-sm-6 col-xl-3 mt-3" style={{ cursor: "pointer" }}>
                <div
                  className="text-center card "
                  style={{ borderRadius: "5px" }}
                  onClick={() => {
                    history.push("/Customer/" + item._id);
                  }
                  }
                >
                  <div className="card-body">
                    <div className="float-end dropdown">
                      <a
                        aria-haspopup="true"
                        className="text-body font-size-16 dropdown-toggle"
                        aria-expanded="false"
                      >
                        {/* <i className="uil uil-ellipsis-h"></i> */}
                      </a>
                      <div
                        tabindex="-1"
                        role="menu"
                        aria-hidden="true"
                        className="dropdown-menu-end dropdown-menu"
                      >
                        <a
                          href="/"
                          tabindex="0"
                          role="menuitem"
                          className="dropdown-item"
                        >
                          Edit
                        </a>
                        <a
                          href="/"
                          tabindex="0"
                          role="menuitem"
                          className="dropdown-item"
                        >
                          Action
                        </a>
                        <a
                          href="/"
                          tabindex="0"
                          role="menuitem"
                          className="dropdown-item"
                        >
                          Remove
                        </a>
                      </div>
                    </div>

                    <div className="clearfix"></div>
                    {item?.image ? (
                      <div className="mb-4">
                        <img
                          className="avatar-lg rounded-circle img-thumbnail"
                          src={`${API_URL}uploads/customer/${item?.image}`}
                          alt="/"
                        />
                      </div>
                    ) : (
                      <div className="mb-4">
                        <img
                          className="avatar-lg rounded-circle img-thumbnail"
                          // src={defaultProfile}
                          src={`${API_URL}uploads/customer/20221214123710user.svg`}
                          alt="/"
                        />
                      </div>
                    )}

                    <h5 className="font-size-16 mb-1">
                      <a className="text-dark">{item.name}</a>
                    </h5>

                    <p className="text-muted mb-2">{item.mobile_number}
                      {item?.app_user == 1 ? (
                        <i
                          className="uil-android"
                          style={{
                            fontSize: "1.5em",
                            cursor: "pointer",
                            marginLeft: "0.2em",
                            marginRight: "0.5em",
                            color: "green",
                          }}
                        // onClick={() => {
                        //   handleClickOpenForm(item);
                        // }}
                        ></i>) : ("")}</p>

                    {/* <div>
                      <img src={item.country_icon} width={"30px"}></img>
                   {/* {item.country_icon} */}
                    {/* <div><p className="text-muted mb-2">{item.country_name}</p></div> */}
                    {/* </div> */}

                    <div>
                      {item?.cust_country?.flag ? (
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip
                              id="button-tooltip-2"
                              style={{ position: "absolute", top: "-30px" }}
                            >
                              {item?.cust_country?.name}
                            </Tooltip>
                          }
                        >
                          <div style={{ display: "flex", justifyContent: "center" }}>
                            <img src={`${API_URL}uploads/country_flag/${item?.cust_country?.flag}`} width={"30px"}></img>
                          </div>
                        </OverlayTrigger>) : ("")
                      }
                    </div>
                    <a
                      type="button"
                      className="text-truncate btn btn-btn-outline-light"
                      style={{ textAlign: "left" }}
                      onClick={() => {
                        // history.push("/Customer/"+item._id);
                        // window.location.assign("/staff/" + item._id);
                      }
                      }
                    >
                      <i className="uil uil-user me-1"></i>
                      Profile
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewCustomer;