export const GET_TARIFF="GET_TARIFF";
export const GET_TARIFF_SUCCESS="GET_TARIFF_SUCCESS";
export const GET_TARIFF_FAIL="GET_TARIFF_FAIL";

export const GET_TARIFF_OPTIONS="GET_TARIFF_OPTIONS";
export const GET_TARIFF_OPTIONS_SUCCESS="GET_TARIFF_OPTIONS_SUCCESS";
export const GET_TARIFF_OPTIONS_FAIL="GET_TARIFF_OPTIONS_FAIL";

export const UPDATE_TARIFF_STATUS = "UPDATE_TARIFF_STATUS";
export const UPDATE_TARIFF_STATUS_SUCCESS = "UPDATE_TARIFF_STATUS_SUCCESS";
export const UPDATE_TARIFF_STATUS_FAIL = "UPDATE_TARIFF_STATUS_FAIL";