import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import toastr from "toastr";

import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Table,
  Container,
} from "reactstrap";
import CountUp from 'react-countup';
import { OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { Box, Divider } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import ArticleIcon from "@mui/icons-material/Article";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import CancelIcon from "@mui/icons-material/Cancel";
// import Select from "react-select";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Select from "react-select";
import sea from "../../../assets/icons/sea.svg";
import air from "../../../assets/icons/air.svg";

import bookedIcon from "../../../assets/images/Icons/book1.svg";
import dispatchedIcon from "../../../assets/images/Icons/book2.svg";
import customIcon from "../../../assets/images/Icons/book3.svg";
import outDeliveryIcon from "../../../assets/images/Icons/book4.svg";
import deliveryIcon from "../../../assets/images/Icons/book5.svg";
import { withRouter, useHistory } from "react-router-dom";
// import CardActions from "@mui/material/CardActions";
// import CardContent from "@mui/material/CardContent";
// import Typography from "@mui/material/Typography";

// import TextField from "@mui/material/TextField";

//import Table from "@mui/material/Table";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableBody from "@mui/material/TableBody";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Slide from "@mui/material/Slide";
import SweetAlert from "react-bootstrap-sweetalert";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import DescriptionIcon from "@mui/icons-material/Description";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import "./CustomerDashboard.scss";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import { getLocalbody, formatMoney } from "../../../helpers/globalFunctions";
import moment from "moment";
import ReceiptIcon from "@mui/icons-material/Receipt";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { maxHeight, minHeight } from "@mui/system";
// import logoDark from "../../assets/images/logo-dark.svg";
//import CallIcon from "@mui/icons-material/Call";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ViewCustomer = (props) => {
  const [details, setDetails] = useState({});
  const [visitLogData, setVisitLogData] = useState([]);
  const [receiptData, setReceiptData] = useState([]);
  const [statementData, setStatementData] = useState([]);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [custIdTobeUpdated, setcustIdToBeUpdated] = useState(null);
  const [creditNote, setCreditNote] = useState([]);
  const [invoiceData, setInvoiceData] = useState([]);


  const [agentNumber, setAgentNumber] = useState("");
  const [wallet, setWallet] = useState([]);
  const [popupView, setPopupView] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [wasteItemDetails, setWasteItemDetails] = useState([]);
  const [country, setCountry] = useState([]);//options fetching 

  const [state, setState] = useState([]);//options fetching 
  const [packageData, setPackageData] = useState({});
  const [tariffInfo, setTariffInfo] = useState([]);
  const [masterObject, setmasterObject] = useState({});
  const [invoicePopupView, setInvoicePopupView] = useState(false);
  const [invoicePopupData, setInvoicePopupData] = useState({});
  //consignee
  const { customerId } = props.match.params;
  const [consigneeData, setConsigneeData] = useState([])
  const [showConsigneePopup, setShowConsigneePopup] = useState(false);
  const [consigneeDetails, setConsigneeDetails] = useState({});
  const [consigneeObject, setConsigneeObject] = useState({ branch_id: currentLocalbody, customer_id: customerId });
  const [selectedConsigneeCountry, setSelectedConsigneeCountry] = useState(null);//selecting option
  const [selectedConsigneeState, setSelectedConsigneeState] = useState(null);//selecting option
  const [errors, setErrors] = useState({
    countryError: "",
    stateError: "",
  });
  const [consigneeIdTobeUpdated, setConsigneeIdToBeUpdated] = useState(null);
  const [consigneeIdToBeDeleted, setConsigneeIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  //wallet
  const [walletData, setWalletData] = useState([])
  //booking
  const [bookingData, setBookingData] = useState([])
  //count
  const [countData, setCountData] = useState({
    "booked": 0,
    "dispatched": 0,
    "incustoms": 0,
    "out_for_delivery": 0,
    "delivered": 0,
    "total": 0
  })

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  let history = useHistory();
  const formRef = useRef();
  useEffect(() => {
    if (customerId) {
      handleTableData(customerId);
      fetch_country();
      handleConsigneeData(customerId)
      handleWalletLog(customerId)
      handleBookingLog(customerId)
      handleCount(customerId)
    }
  }, []);
  // var tooltip = <Tooltip />
  const {
    addingcust,
  } = useSelector((state) => state.districts);

  const customStyles = {
    lineHeight: "0.8",
  };

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  function handleTableData(id) {
    axios
      .get(`${API_URL}customer?customer_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        {
          if (result.cust_country) {
            result.country1 = (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "absolute", top: "-30px" }}
                  // style={{ position: "absolute", top: "-30px" }}
                  >
                    {result?.cust_country?.name}
                  </Tooltip>
                }
              >
                {/* <div style={{ display: "flex", justifyContent: "left" }}> */}
                <img src={`${API_URL}uploads/country_flag/${result?.cust_country?.flag}`} width={"30px"}></img>
                {/* </div> */}
              </OverlayTrigger>)
          }
        }
        result.date = moment(result.createdAt).format("DD-MM-YYYY hh:mm a");
        result.date1 = moment(result.updatedAt).format("DD-MM-YYYY hh:mm a");


        let values = result?.updated_by || {};
        if (values?.lastName)
          result.staff1 = values.firstName + " " + values.lastName;
        else result.staff1 = values?.firstName;

        let val = result?.added_by || {};
        if (val?.lastName)
          result.staff = val.firstName + " " + val.lastName;
        else result.staff = val?.firstName;

        setDetails(result)

      });
  }


  function handleCount(id) {
    axios
      .get(`${API_URL}customer/count?customer_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data[0];
        if (result) {
          setCountData(result)
        }
      });

  }

  function fetch_country() {
    axios
      .get(`${API_URL}newStates/countries/`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var Country =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id
            };
          });
        setCountry([{ options: Country }])
      });
  }



  const statementTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 200,
      },
      //   {
      //     label: "Customer Name",
      //     field: "CustomerName",
      //     sort: "asc",
      //     width: 200,
      //   },
      {
        label: "Ref No",
        field: "reference_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Type",
        field: "statement_type",
        sort: "asc",
        width: 200,
      },

      {
        label: "Debit",
        field: "debit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Credit",
        field: "credit",
        sort: "asc",
        width: 200,
      },
      {
        label: "Balance",
        field: "balance",
        sort: "asc",
        width: 200,
      },
    ],
    rows: statementData,
  };
  const invoiceTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 400,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Invoice No",
        field: "invoice_no",
        sort: "asc",
        width: 200,
      },
      {
        label: "Customer",
        field: "invoice_customer_name",
        sort: "asc",
        width: 200,
      },
      // {
      //   label: "Package",
      //   field: "invoice_package_name",
      //   sort: "asc",
      //   width: 200,
      // },
      {
        label: "Amount",
        field: "invoice_total_amt",
        sort: "asc",
        width: 200,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 50,
      },
    ],
    rows: invoiceData,
  };
  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [tab, setTab] = React.useState("1");

  const handleTablChange = (event, newValue) => {
    setTab(newValue);
  };

  const [tab1, setTab1] = React.useState("1");

  const handleTablChange1 = (event, newValue) => {
    setTab1(newValue);
  };

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function createData(si, date, time, staff) {
    return { si, date, time, staff };
  }

  const rows1 = [createData(1, 159, 6.0, 24), createData(2, 237, 9.0, 37)];

  // Receipt table
  const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow1 = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function createData1(si, date, receiptno, amount, dueamount, staff) {
    return { si, date, receiptno, amount, dueamount, staff };
  }

  const rows2 = [
    createData1(1, "24 - 11 - 2021", "RE2111000004", "50/-", "50/-", "Prabija"),
    createData1(
      2,
      "24 - 11 - 2021",
      "RE2111000005",
      "400/-",
      "-350/-",
      "Prabija"
    ),
  ];

  function createData3(
    si,
    date,
    time,
    referenceNo,
    customerId,
    customerName,
    amount,
    comment,
    due,
    staff
  ) {
    return {
      si,
      date,
      time,
      referenceNo,
      customerId,
      customerName,
      amount,
      comment,
      due,
      staff,
    };
  }

  const rows3 = [
    createData3(
      1,
      "26-11-2020",
      "11:11:24",
      123,
      123,
      "admin",
      700,
      "abc",
      100,
      "srv"
    ),
  ];

  const [tab3, setTab3] = React.useState("1");

  const handleChangeTab = (event, newValue) => {
    setTab3(newValue);
  };

  const [date, setDate] = React.useState(new Date("2014-08-18T21:11:54"));

  const handleChangeDate = (newValue) => {
    setDate(newValue);
  };

  function createData4(
    si,
    date,
    time,
    customerName,
    type,
    invoiceNo,
    debit,
    credit,
    balance
  ) {
    return {
      si,
      date,
      time,
      customerName,
      type,
      invoiceNo,
      debit,
      credit,
      balance,
    };
  }

  const rows4 = [
    createData4(
      1,
      "26-11-2020",
      "11:11:24",
      "admin",
      "Invoice",
      "SRV123",
      700,
      100,
      100
    ),
  ];


  // //###################### booking data ################################

  function handleBookingLog(id) {
    axios
      .get(`${API_URL}customer/bookinglog?customer_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let Booking = []
        result.map((item, index) => {

          item.id = index + 1;
          if (item.date) {
            item.dates = moment(item.date).format("DD-MM-YYYY");
          }
          if (item.receiver) {
            item.consignee = item?.receiver?.name
          }

          if (item.shipment_status == 0) {
            item.statusnow =
              //"Booked"
              (<div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="primary"
                  size="sm"
                >
                  Booked
                </Button>
              </div>)
          }
          if (item.shipment_status == 1) {
            item.statusnow =
              //"Dispatched"
              (<div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="secondary"
                  size="sm"

                >
                  Dispatched
                </Button>
              </div>)
          }
          if (item.shipment_status == 2) {
            item.statusnow =
              //  "Incustoms"
              (<div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="info"
                  size="sm"

                >
                  Incustoms
                </Button>
              </div>)
          }
          if (item.shipment_status == 3) {
            item.statusnow =
              //  "Out For Delivery"
              (<div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="warning"
                  size="sm"

                >
                  Out For Delivery
                </Button>
              </div>)
          }
          if (item.shipment_status == 4) {
            item.statusnow =
              // "Delivered"
              (<div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  color="success"
                  size="sm"

                >
                  Delivered
                </Button>
              </div>)
          }




          if (item.country_from) {
            item.from = (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "absolute", top: "-30px" }}
                  >
                    {item?.country_from?.name}
                  </Tooltip>
                }
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={`${API_URL}uploads/country_flag/${item?.country_from?.flag}`} width={"30px"}></img>
                </div>
              </OverlayTrigger>);
          }

          if (item.country_to) {
            item.to = (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "absolute", top: "-30px" }}
                  >
                    {item?.country_to?.name}
                  </Tooltip>
                }
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={`${API_URL}uploads/country_flag/${item?.country_to?.flag}`} width={"30px"}></img>
                </div>
              </OverlayTrigger>);
          }

          if (item.cargo_type) {
            item.cargo = (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "absolute", top: "-30px" }}
                  >
                    {item?.cargo_type?.cargo_sub_type}
                  </Tooltip>
                }
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={`${API_URL}uploads/cargo_type_icon/${item?.cargo_type?.cargo_type_icon}`} width={"30px"}></img>
                </div>
              </OverlayTrigger>);
          }


          Booking.push(item);
        });

        setBookingData(Booking)
      });
  }
  const bookingTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "dates",
        sort: "asc",
        width: 400,
      },
      {
        label: "ID",
        field: "order_number",
        sort: "asc",
        width: 200,
      },
      {
        label: "From",
        field: "from",
        sort: "asc",
        width: 200,
      },
      {
        label: "Type",
        field: "cargo",
        sort: "asc",
        width: 200,
      },
      {
        label: "To",
        field: "to",
        sort: "asc",
        width: 200,
      },
      {
        label: "Consignee",
        field: "consignee",
        sort: "asc",
        width: 200,
      },
      {
        label: "Status",
        field: "statusnow",
        sort: "asc",
        width: 200,
      },
    ],
    rows: bookingData

  };
  // //###################### booking data ################################


  //###################### wallet data ################################

  function handleWalletLog(id) {
    axios
      .get(`${API_URL}customer/walletlog?customer_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let wallet = []
        result.map((item, index) => {

          item.id = index + 1;
          item.time = moment(item.wlog_time, "HHmmss").format("hh:mm a");
          if (item.wlog_type == 1) {
            item.credit = item.wlog_amount
          }
          if (item.wlog_type == 0) {
            item.debit = item.wlog_amount
          }
          if (item.wlog_date) {
            item.date = moment(item.wlog_date).format("DD-MM-YYYY");
          }

          wallet.push(item);
        });

        setWalletData(wallet)
      });
  }


  const walletTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 200,
      },
      {
        label: "Time",
        field: "time",
        sort: "asc",
        width: 100,
      },
      {
        label: "Credit",
        field: "credit",
        sort: "asc",
        width: 100,
      },
      {
        label: "Debit",
        field: "debit",
        sort: "asc",
        width: 150,
      },
      {
        label: "Balance",
        field: "wlog_balance",
        sort: "asc",
        width: 150,
      },
      {
        label: "Remarks",
        field: "wlog_message",
        sort: "asc",
        width: 150,
      },

    ],
    rows: walletData

  };
  //###################### wallet data ################################


  //###################consignee########################
  const customStyles1 = {
    lineHeight: "1.8",
    fontFamily: "IBM Plex Sans, sans-serif"
  };
  function handleConsigneeData(id) {
    axios
      .get(`${API_URL}receiver/list?customer_id=` + id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var result = res.data.data;
        let conData = []
        result.map((item, index) => {
          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleClickOpenConsignee(item)
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateConsignee(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setConsigneeIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );
          item.id = index + 1;
          if (item.rece_country) {
            item.country = (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "absolute", top: "-30px" }}
                  >
                    {item?.rece_country?.name}
                  </Tooltip>
                }
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img src={`${API_URL}uploads/country_flag/${item?.rece_country?.flag}`} width={"30px"}></img>
                </div>
              </OverlayTrigger>);
          }
          if (item.rece_state) {
            item.state = item?.rece_state?.name
          }
          if (item.app_user == 1) {
            item.info = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <i
                  className="uil-android"
                  style={{
                    fontSize: "1.5em",
                    cursor: "pointer",
                    marginLeft: "0.2em",
                    marginRight: "0.5em",
                    color: "green",
                  }}

                ></i>
              </div>)
          }
          conData.push(item);
        });


        setConsigneeData(conData)
      });
  }
  const consigneeDataList = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Mobile",
        field: "mobile_number",
        sort: "asc",
        width: 200,
      },
      {
        label: "ID No",
        field: "id_number",
        sort: "asc",
        width: 200,
      },
      {
        label: "Country",
        field: "country",
        sort: "asc",
        width: 200,
      },
      {
        label: "State",
        field: "state",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: consigneeData

  };

  const handleClickOpenConsignee = (item) => {
    axios
      .get(`${API_URL}receiver?receiver_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        {
          if (result.rece_country) {
            result.country = (
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id="button-tooltip-2"
                    style={{ position: "absolute", top: "-30px" }}
                  >
                    {result?.rece_country?.name}
                  </Tooltip>
                }
              >
                <div style={{ display: "flex", justifyContent: "left" }}>
                  <img src={`${API_URL}uploads/country_flag/${result?.rece_country?.flag}`} width={"30px"}></img>
                </div>
              </OverlayTrigger>)
          }
        }
        result.date = moment(result.createdAt).format("DD-MM-YYYY hh:mm a");
        result.date1 = moment(result.updatedAt).format("DD-MM-YYYY hh:mm a");


        let values = result?.updated_by || {};
        if (values?.lastName)
          result.staff1 = values.firstName + " " + values.lastName;
        else result.staff1 = values?.firstName;

        let val = result?.added_by || {};
        if (val?.lastName)
          result.staff = val.firstName + " " + val.lastName;
        else result.staff = val?.firstName;

        setConsigneeDetails(result);
        setShowConsigneePopup(true);
      });

  };

  function handleChangeConsignee(e) {
    let name = e.target.name;
    let value = e.target.value;
    setConsigneeObject({ ...consigneeObject, [name]: value });
  }

  const handleSelectConsigneeChange = (selected, name) => {
    switch (name) {
      case "rece_country":
        setSelectedConsigneeCountry(selected);
        setSelectedConsigneeState(null);
        axios
          .get(`${API_URL}newStates/list?country_id=` + selected.value, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data && res.data.status === 200) {
              var state =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.name,
                    value: el._id
                  };
                });
            } else {
              state = [];
            }
            setState([{ options: state }])
          });

        setConsigneeObject({
          ...consigneeObject,
          rece_country: selected.value,
        });
        setErrors({
          ...errors,
          countryError: "",
        });
        break;
      case "rece_state":
        setSelectedConsigneeState(selected);

        setConsigneeObject({
          ...consigneeObject,
          rece_state: selected.value,
        });
        setErrors({
          ...errors,
          stateError: "",
        });
        break;


      default:
        break;
    }
  };

  const handleValidConsigneeSubmit = () => {
    if (selectedConsigneeCountry === null || selectedConsigneeState === null) {
      let errorVal = errors;
      if (selectedConsigneeCountry === null) {
        errorVal.countryError = "Please Select Country";
        setErrors({
          ...errorVal,
        });
      }
      if (selectedConsigneeState === null) {
        errorVal.stateError = "Please Select State";
        setErrors({
          ...errorVal,
        });
      }
    }
    else {
      if (consigneeIdTobeUpdated) {
        axios
          .put(`${API_URL}receiver/`, consigneeObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Consignee Updated Successfully");
              formRef.current.reset();
              handleConsigneeData(customerId);
              setConsigneeObject({ branch_id: currentLocalbody, customer_id: customerId });
              setConsigneeIdToBeUpdated(null);
              setSelectedConsigneeCountry(null);
              setSelectedConsigneeState(null);
              setErrors({
                ...errors,
                countryError: "",
                stateError: ""
              });
            } else {
              toastr.error("Failed to Update Consignee");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      } else {
        axios
          .post(`${API_URL}receiver/`, consigneeObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Consignee Added Succesfully");

              handleConsigneeData(customerId)

              setSelectedConsigneeCountry(null);
              setSelectedConsigneeState(null);

              formRef.current.reset();
              setConsigneeObject({ branch_id: currentLocalbody, customer_id: customerId });
              setErrors({
                ...errors,
                countryError: "",
                stateError: ""
              });
            } else {
              toastr.error("Failed to Add Consignee");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
    }
  };

  let preUpdateConsignee = (item) => {
    setConsigneeIdToBeUpdated(item?._id);


    axios
      .get(`${API_URL}receiver?receiver_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        result.branch_id = currentLocalbody;
        let countrys = {
          label: result?.rece_country?.name,
          value: result?.rece_country?._id
        };
        setSelectedConsigneeCountry(countrys);


        let states = {
          label: result?.rece_state?.name,
          value: result?.rece_state?._id,
        };
        setSelectedConsigneeState(states);

        axios
          .get(`${API_URL}newStates/list?country_id=` + result?.rece_country?._id, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {
            if (res.data && res.data.status === 200) {
              var state =
                res.data.data &&
                res.data.data.map((el) => {
                  return {
                    label: el.name,
                    value: el._id
                  };
                });
            } else {
              state = [];
            }
            setState([{ options: state }])
          });

        setConsigneeObject(result);

      });
  };
  //###################consignee########################


  return (
    <React.Fragment>
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setPopupView(!popupView)}
        size="xl"
        centered={true}>

        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Consignee Details</h5>
          <button
            type='button'
            onClick={() => {
              setPopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className='modal-body'>
            <table
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Name</td>
                <td>: MUHAMMED ADHIL V.K</td>
                <td style={{ paddingLeft: "25px" }}>Mobile</td>
                <td style={{ textAlign: "left" }}>: 9912456789</td>
                <td style={{ paddingLeft: "25px" }}> ID No</td>
                <td style={{ textAlign: "left" }}>: 667890123451</td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>country</td>
                <td >: Oman</td>
                <td style={{ paddingLeft: "25px" }}>State</td>
                <td style={{ textAlign: "left" }}>: Nizwa</td>
                <td style={{ paddingLeft: "25px" }}>House Name</td>
                <td style={{ textAlign: "left" }}>: Muhammed Manzil</td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>LandMark</td>
                <td>: Near Alfar Hospital</td>
                <td style={{ paddingLeft: "25px" }}>PostOffice</td>
                <td style={{ textAlign: "left" }}>: Alfarzar</td>
                <td style={{ paddingLeft: "25px" }}>Pincode</td>
                <td style={{ textAlign: "left" }}>: 976453</td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>District</td>
                <td >: Nizwir</td>
                <td style={{ paddingLeft: "25px" }}>Alternate No</td>
                <td style={{ textAlign: "left" }}>: 9902007865</td>
                <td style={{ paddingLeft: "25px" }}>Street Name</td>
                <td style={{ textAlign: "left" }}>: Alfazar </td>

              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>

      <Modal show={invoicePopupView} size="lg" centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">View Invoice Details</h5>
          <button
            type="button"
            onClick={() => {
              setInvoicePopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <AvForm className="needs-validation" onValidSubmit={(e, v) => { }}>
          <div className="modal-body">
            <Container fluid>
              <Row>
                <Col lg="12">
                  <Card>
                    <CardBody>
                      <div className="invoice-title">
                        <h4 className="float-end font-size-16">
                          <span>
                            {" INVOICE "}
                            &nbsp;
                          </span>
                          <span>#</span>
                          <span style={{ color: "blue" }}>
                            {invoicePopupData?.invoice_no}
                          </span>
                        </h4>
                        <div className="mb-0">
                          {/* <img src={logoDark} alt="logo" height="42" /> */}
                        </div>
                        <div className="text-muted">
                          <h4 className="mb-1">{/* {} */}</h4>
                          <p className="mb-1">
                            {invoicePopupData?.companyAddress}
                          </p>
                          <p
                            className="mb-1"
                            hidden={!invoicePopupData?.companyEmail}
                          >
                            <i className="uil uil-envelope-alt me-1"></i>{" "}
                            {invoicePopupData?.companyEmail}
                          </p>
                          <p
                            className="mb-1"
                            hidden={!invoicePopupData?.companyMob}
                          >
                            <i className="uil uil-phone me-1"></i>{" "}
                            {invoicePopupData?.companyMob}
                          </p>
                          <p
                            className="mb-1"
                            hidden={!invoicePopupData?.companyWeb}
                          >
                            <i className="uil uil-google me-1"></i>{" "}
                            {invoicePopupData?.companyWeb}
                          </p>
                          <p className="mb-1">{/* {} */}</p>
                        </div>
                      </div>
                      <hr className="my-4" />
                      <Row>
                        <Col sm="6">
                          <div className="text-muted">
                            <h5 className="font-size-15 mb-2">
                              {invoicePopupData?.invoice_customer_name}
                            </h5>
                            <p className="mb-1">
                              {invoicePopupData?.customerId}
                            </p>
                            <p
                              className="mb-1"
                              hidden={!invoicePopupData?.cust_phone}
                            >
                              {" "}
                              <i className="uil uil-phone me-1"></i>
                              {invoicePopupData?.cust_phone}
                            </p>
                            <p className="mb-1">{invoicePopupData?.ward}</p>
                            <p className="mb-1">
                              {invoicePopupData?.groupName}
                            </p>
                            <p className="mb-1">
                              {/* <i className="uil uil-envelope-alt me-1"></i>{" "} */}
                              {/* {} */}
                            </p>
                          </div>
                        </Col>
                        <Col sm="6">
                          <div className="text-muted text-sm-end">
                            <div>
                              <h5 className="font-size-16 mb-1">
                                Invoice Date:
                              </h5>
                              <p>{invoicePopupData?.invoice_date}</p>
                            </div>
                            <div className="mt-4">
                              <h5 className="font-size-16 mb-1">
                                Invoice Time:
                              </h5>
                              <p>
                                {moment(
                                  invoicePopupData?.invoice_time,
                                  "h:mm:ss"
                                ).format("hh:mm a")}
                              </p>
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="py-2">
                        <div className="table-responsive mb-3">
                          <Table className="table-nowrap table-centered mb-0">
                            <thead>
                              <tr>
                                <th style={{ width: "70px" }}>#</th>
                                <th>Item Name</th>
                                <th>Quantity</th>
                                <th>Amount</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {invoicePopupData.invoice_items &&
                                invoicePopupData.invoice_items.map(
                                  (item, key) => (
                                    <tr key={key}>
                                      <td className="text-center">{key + 1}</td>
                                      <td>{item?.inovice_item_name}</td>
                                      <td className="text-center">
                                        {item.invoice_item_quanty}
                                      </td>
                                      <td className="text-end">
                                        {item?.invoice_item_amt}
                                      </td>
                                      <td className="text-end">
                                        {item.invoice_item_price}
                                      </td>
                                    </tr>
                                  )
                                )}
                            </tbody>
                          </Table>
                        </div>
                        <Row className="pt-4">
                          <Col lg={7}>
                            <Row>
                              <Col md={12}></Col>
                            </Row>
                          </Col>
                          <Col md={1}></Col>
                          <Col>
                            <Table className="table-nowrap table-centered mb-0">
                              <tbody>
                                <tr>
                                  <th colSpan="4" className="text-end">
                                    Total Amount :
                                  </th>
                                  <td className="text-end">
                                    {formatMoney(
                                      invoicePopupData?.invoice_total_amt
                                    )}
                                  </td>
                                </tr>
                                <tr>
                                  <th colSpan="4" className="border-0 text-end">
                                    Taxable Amount :
                                  </th>
                                  <td className="border-0 text-end">
                                    {formatMoney(
                                      invoicePopupData?.invoice_gst_amt
                                    )}
                                  </td>
                                </tr>
                                <tr></tr>
                                <tr></tr>
                                <tr>
                                  <th colSpan="4" className="border-0 text-end">
                                    Payable Amount
                                  </th>
                                  <td className="border-0 text-end">
                                    <h4 className="m-0">
                                      {formatMoney(
                                        invoicePopupData?.totalAmount
                                      )}
                                    </h4>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </Col>
                        </Row>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </AvForm>
      </Modal>


      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Home"
            breadcrumbItem={"Customer Details"
            }
          />
          <Row>
            <Col xl="12">
              {/* First Dialogue */}

              <div>
                <div className="row">
                  <div className="col-xl-3">
                    <div
                      className="card"
                      style={{

                        //width: "fit-content",
                        //width: "351px",
                        //width:"300px",
                        height: "max-content",
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                      }}
                    >
                      <div className="card-body">
                        <div className="float-end dropdown">
                          <div
                            tabindex="-1"
                            role="menu"
                            aria-hidden="true"
                            className="dropdown-menu-end dropdown-menu"
                          >
                            <a
                              to="/"
                              tabindex="0"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Edit
                            </a>
                            <a
                              to="/"
                              tabindex="1"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Action
                            </a>
                            <a
                              to="/"
                              tabindex="2"
                              role="menuitem"
                              className="dropdown-item"
                            >
                              Remove
                            </a>
                          </div>
                          {/* <div className="clearfix"></div> */}
                          {/* <div>
                                <img
                                  alt
                                  className="avatar-lg rounded-circle img-thumbnail"
                                  src="/static/media/avatar-4.b23e41d9.jpg"
                                />
                              </div> */}
                        </div>
                        {/* Customer Details Tab start */}

                        {details?.image ? (
                          <Stack
                            direction="row"
                            spacing={2}
                            style={{ justifyContent: "center" }}
                          >
                            <Avatar
                              alt="Remy Sharp"
                              src={`${API_URL}uploads/customer/${details?.image}`}
                              sx={{ width: 56, height: 56 }}
                              style={{
                                width: "6rem",
                                height: "6rem",
                                marginBottom: "auto",
                              }}
                            />
                          </Stack>
                        ) : (
                          <Stack
                            direction="row"
                            spacing={2}
                            style={{ justifyContent: "center" }}
                          >
                            <Avatar
                              alt="Remy Sharp"
                              // src={defaultProfile}
                              src={`${API_URL}uploads/customer/20221214123710user.svg`}
                              sx={{ width: 56, height: 56 }}
                              style={{
                                width: "6rem",
                                height: "6rem",
                                marginBottom: "auto",
                              }}
                            />
                          </Stack>
                        )}

                        <h5
                          className="mt-3 mb-1"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        >
                          {details?.name}
                        </h5>
                        <div
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            {/* <CallIcon style={{ width: "10px", height: "10px" }}/> */}
                            <OverlayTrigger
                              placement="top"
                              //delay={{ show: 250, hide: 400 }}
                              overlay={
                                <Tooltip
                                  id="button-tooltip-2"
                                  style={{ position: "absolute", top: "-30px" }}
                                >
                                  Call Now
                                </Tooltip>
                              }
                            >
                              <button
                                class="btn btn-success"
                                style={{
                                  borderRadius: "40px",
                                  marginRight: "10px",
                                  padding: "0",
                                  width: "28px",
                                }}
                              // hidden={!details.cust_phone}
                              //onClick={() => customerCall()}
                              >
                                <i
                                  className="uil-phone-alt"
                                  style={{ fontSize: "15px" }}
                                />
                              </button>
                            </OverlayTrigger>
                          </div>
                          {details?.mobile_number}
                          {/* 9099865790 */}
                        </div>
                        <p
                          className="text-muted"
                          style={{
                            textAlign: "center",
                            color: "green !important",
                            fontFamily: "IBM Plex Sans,sans-serif",
                            fontSize: "14px",
                            marginBottom: "3px",
                          }}
                          hidden={!details?.cust_whatsapp_no}
                        >
                          <div
                            class="btn-group btn-group-sm"
                            role="group"
                            aria-label="Basic example"
                          >
                            <button
                              class="btn btn-success"
                              style={{
                                borderRadius: "40px",
                                marginRight: "10px",
                                padding: "0",
                                width: "28px",
                              }}
                              hidden={!details.cust_whatsapp_no}
                            >
                              <i
                                className="uil-whatsapp"
                                style={{ fontSize: "15px" }}
                              //hidden={!details.cust_whatsapp_no}
                              />
                              {/* Call */}
                            </button>
                          </div>
                          {details?.cust_whatsapp_no}
                          {/* <img
                            //src="https://api.ir.ee/static/ssb_icon.png"
                            data-regcode="12336685"
                            
                          /> */}
                        </p>
                        <p
                          style={{
                            textAlign: "center",
                            marginBottom: "3px",
                          }}
                        >
                          {details?.custStatus}
                        </p>

                        <div
                          className="mt-3 mb-2"
                          style={{
                            textAlign: "center",
                            fontFamily: "IBM Plex Sans,sans-serif",
                          }}
                        ></div>
                        <Divider />
                        {/* <div  className="mt-2">
                          <p className="mb-1">Email:</p>
                          <h6 className="font-size-14">{details?.name}</h6>
                        </div> */}
                        <div >
                          <p className="mb-1">ID No:</p>
                          <h6 className="font-size-14">
                            {details?.id_number}
                          </h6>
                        </div>
                        <div >
                          <p className="mb-1">Email:</p>
                          <h6 className="font-size-14">
                            {details?.email}
                          </h6>
                        </div>
                        <div >
                          <p className="mb-1">Branch:</p>
                          <h6 className="font-size-14">
                            {details?.branch_id?.branch_name}
                          </h6>
                        </div>

                        <div className="mt-2">
                          <p className="mb-1">Country:</p>

                          <h6 className="font-size-14"> {details?.country1}</h6>
                        </div>
                        <div className="mt-2">
                          <p className="mb-1">State:</p>
                          <h6 className="font-size-14">
                            {details?.cust_state?.name}
                          </h6>
                        </div>
                        <div>
                          <p className="mb-1">Address:</p>
                          <h6 className="font-size-14">
                            {details?.address}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-9" style={{ marginBottom: "30px" }}>
                    <div
                      className="mb-0 card"
                      style={{
                        boxShadow: "2px 4px 7px 0px rgb(0 0 0 / 10%)",
                        width: "100%",

                      }}
                    >
                      <Box sx={{ width: "100%", typography: "body1", marginTop: "15px" }}>
                        <TabContext value={tab3}>
                          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                            <TabList
                              indicatorColor="primary"
                              textColor="primary"
                              variant="fullWidth"
                              onChange={handleChangeTab}
                              aria-label="lab API tabs example"
                            >
                              <Tab
                                // icon={
                                //   <Inventory2Icon
                                //     style={{ fontSize: "20px" }}
                                //   />
                                // }

                                label="Profile"
                                value="1"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                // icon={
                                //   <LocationCityIcon
                                //     style={{ fontSize: "20px" }}
                                //   />
                                // }
                                label="All Booking"
                                value="2"
                                size="small"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              <Tab
                                // icon={
                                //   <AccountBalanceWalletIcon
                                //     style={{ fontSize: "20px" }}
                                //   />
                                // }
                                label="Wallet"
                                value="5"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              {/* //###################consignee######################## */}
                              <Tab
                                // icon={
                                //   <ReceiptIcon style={{ fontSize: "20px" }} />
                                // }
                                label="Consignee Details"
                                value="4"
                                style={{
                                  textTransform: "capitalize",
                                  fontFamily: "IBM Plex Sans,sans-serif",
                                }}
                              />
                              {/* //###################consignee######################## */}


                            </TabList>
                          </Box>
                          <TabPanel
                            value="1"
                            style={{ padding: "14px 0px 0px 0px", marginLeft: "10px", marginRight: "10px", fontFamily: "IBM Plex Sans, sans-serif" }}
                          >
                            {/* <Dashboard /> */}
                            <Row>
                              <Col md="12">
                                <Row>
                                  <Col md={2} xl={2}>
                                    <Card>
                                      <CardBody style={{ padding: "5px 5px" }}>
                                        <div className="float-end mt-2">
                                          <img src={bookedIcon} height="30px"></img>
                                        </div>
                                        <div>
                                          <h4 className="mb-1 mt-1"><span><CountUp end={countData.total} separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                          <p className="text-muted mb-0">Total Booking</p>
                                        </div>

                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col md={2} xl={2}>
                                    <Card>
                                      <CardBody style={{ padding: "5px 5px", maxHeight: "82px" }}>
                                        <div className="float-end mt-2">
                                          <img src={bookedIcon} height="30px"></img>
                                        </div>
                                        <div>
                                          <h4 className="mb-1 mt-1"><span><CountUp end={countData.booked} separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                          <p></p>
                                          <p className="text-muted mb-0">Booked</p>
                                          <p></p>
                                        </div>

                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col md={2} xl={2}>
                                    <Card>
                                      <CardBody style={{ padding: "5px 5px", minHeight: "81px" }}>
                                        <div className="float-end mt-2">
                                          <img src={dispatchedIcon} height="30px"></img>
                                        </div>
                                        <div>
                                          <h4 className="mb-1 mt-1"><span><CountUp end={countData.dispatched} separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                          <p className="text-muted mb-0">Dispatched</p>
                                        </div>

                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col md={2} xl={2}>
                                    <Card>
                                      <CardBody style={{ padding: "5px 5px", minHeight: "81px" }}>
                                        <div className="float-end mt-2">
                                          <img src={customIcon} height="30px"></img>
                                        </div>
                                        <div>
                                          <h4 className="mb-1 mt-1"><span><CountUp end={countData.incustoms} separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                          <p></p>
                                          <p className="text-muted mb-0">Incustom</p>
                                        </div>

                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col md={2} xl={2}>
                                    <Card>
                                      <CardBody style={{ padding: "2px 2px", minHeight: "81px" }}>
                                        <div className="float-end mt-2">
                                          <img src={outDeliveryIcon} height="30px"></img>
                                        </div>
                                        <div>
                                          <h4 className="mb-1 mt-1"><span><CountUp end={countData.out_for_delivery} separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                          <p className="text-muted mb-0">Out for delivery</p>
                                        </div>

                                      </CardBody>
                                    </Card>
                                  </Col>
                                  <Col md={2} xl={2}>
                                    <Card>
                                      <CardBody style={{ padding: "5px 5px", minHeight: "81px" }}>
                                        <div className="float-end mt-2">
                                          <img src={deliveryIcon} height="30px"></img>
                                        </div>
                                        <div>
                                          <h4 className="mb-1 mt-1"><span><CountUp end={countData?.delivered} separator="" prefix="" suffix="" decimals={0} /></span></h4>
                                          <p className="text-muted mb-0">Delivered</p>
                                        </div>
                                      </CardBody>
                                    </Card>
                                  </Col>
                                </Row>
                              </Col>
                              <Col md="12">
                                <Card>
                                  <CardBody style={{ padding: "7px" }}>
                                    <div className="mb-3">
                                      <table
                                        id="projectInfoTable"
                                        width={"100%"}
                                      >

                                        <tr>
                                          <td style={{ padding: "10px", fontWeight: "500", }}>
                                            Whatsapp No
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            : {details.whattsapp_number}
                                            {/* : 9099865790 */}
                                          </td>
                                          <td style={{ padding: "10px", fontWeight: "500", }}>
                                            Post Office
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            : {details.post_office}
                                            {/* : Oman */}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ padding: "10px", fontWeight: "500", }}>
                                            Pin Code
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            : {details.pin_code}
                                            {/* : 987432 */}
                                          </td>
                                          <td style={{ padding: "10px", fontWeight: "500", }}>
                                            District
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            : {details.district}
                                            {/* : Burkat Al Mooz  */}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ padding: "10px", fontWeight: "500", }}>
                                            Landmark
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            : {details.landmark}
                                            {/* : Near Almazar Hospital */}
                                          </td>
                                          <td style={{ padding: "10px", fontWeight: "500", }}>
                                            App Version
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            {/* : 1.001 */}
                                            : {details.app_version}
                                          </td>
                                        </tr>
                                        <tr>
                                          <td style={{ padding: "10px", fontWeight: "500", }}>
                                            Created At
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            : {details.date}
                                          </td>
                                          <td style={{ padding: "10px", fontWeight: "500", }}>
                                            Created By
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            : {details.staff}
                                          </td>
                                        </tr>
                                        <tr hidden={!details?.updated_by}>
                                          <td style={{ padding: "10px", fontWeight: "500", }}>
                                            Updated At
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            : {details.date1}
                                          </td>
                                          <td style={{ padding: "10px", fontWeight: "500", }}>
                                            Updated By
                                          </td>
                                          <td style={{ textAlign: "left" }}>
                                            : {details.staff1}
                                          </td>
                                        </tr>
                                      </table>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>

                            </Row>
                          </TabPanel>
                          <TabPanel value="2" style={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
                            <Row>
                              <Col className="col-12">
                                <Card>
                                  <CardBody>
                                    <MDBDataTable
                                      id="custReceiptId"
                                      responsive
                                      bordered
                                      data={bookingTableData}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel value="3" style={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
                            <Row>
                              <Col className="col-12">
                                <Card>
                                  <CardBody>
                                    <MDBDataTable
                                      id="customerStatementId"
                                      responsive
                                      bordered
                                      data={statementTableData}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>
                          {/* //###################consignee######################## */}
                          <TabPanel value="4" style={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
                            {confirmDeleteAlert ? (
                              <SweetAlert
                                title=""
                                showCancel
                                confirmButtonText="Delete"
                                confirmBtnBsStyle="success"
                                cancelBtnBsStyle="danger"
                                onConfirm={() => {
                                  axios
                                    .delete(`${API_URL}receiver/${consigneeIdToBeDeleted}`, {
                                      headers: {
                                        "x-access-token": accessToken,
                                      },
                                    })
                                    .then((res) => {
                                      if (res.data.status === 200) {
                                        toastr.success("Consignee Deleted successfully");
                                        if (consigneeObject && consigneeObject._id === consigneeIdToBeDeleted) {
                                          formRef.current.reset();
                                          setConsigneeObject({ branch_id: currentLocalbody, customer_id: customerId });
                                          setConsigneeIdToBeUpdated(null);
                                          setSelectedConsigneeCountry(null);
                                          setSelectedConsigneeState(null);
                                        }
                                        handleConsigneeData(customerId);
                                      } else {
                                        toastr.error(
                                          res.data.message,
                                          "Failed to delete Consignee"
                                        );
                                        return;
                                      }
                                    });
                                  setConfirmDeleteAlert(false);
                                }}
                                onCancel={() => setConfirmDeleteAlert(false)}
                              >
                                Are you sure you want to delete it?
                              </SweetAlert>
                            ) : null}


                            <Modal
                              show={showConsigneePopup}
                              isOpen={showConsigneePopup}
                              toggle={() => setShowConsigneePopup(!showConsigneePopup)}
                              size="xl"
                              centered={true}>

                              <div className='modal-header'>
                                <h5 className='modal-title mt-0'>Consignee Details</h5>
                                <button
                                  type='button'
                                  onClick={() => {
                                    setShowConsigneePopup(false);
                                  }}
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="close"
                                ></button>
                              </div>
                              <AvForm className="needs-validation">
                                <div className='modal-body'>
                                  <table
                                    className="table table-bordered dataTable"
                                  >
                                    <tr>
                                      <td style={{ padding: "10px", textAlign: "left" }}>Name</td>
                                      <td>: {consigneeDetails?.name}</td>
                                      <td style={{ paddingLeft: "25px" }}>Mobile</td>
                                      <td style={{ textAlign: "left" }}>: {consigneeDetails?.mobile_number}</td>
                                      <td style={{ paddingLeft: "25px" }}> ID No</td>
                                      <td style={{ textAlign: "left" }}>: {consigneeDetails?.id_number}</td>

                                    </tr>
                                    <tr>
                                      <td style={{ padding: "10px", textAlign: "left" }}>Country</td>
                                      {/* <td > {consigneeDetails?.country}</td> */}

                                      <td style={{ textAlign: "left" }} >
                                        <OverlayTrigger
                                          trigger={['hover', 'focus']}
                                          placement="top"
                                          overlay={
                                            <Tooltip
                                              id="button-tooltip-2"
                                              style={{ position: "absolute", top: "-30px" }}
                                            >
                                              {consigneeDetails?.rece_country?.name}
                                            </Tooltip >
                                          }
                                        >
                                          <div style={{ display: "flex", justifyContent: "left" }}>
                                            <img src={`${API_URL}uploads/country_flag/${consigneeDetails?.rece_country?.flag}`}
                                              width={"30px"}
                                              title={`${consigneeDetails?.rece_country?.name}`}
                                            >
                                            </img>
                                          </div>
                                        </OverlayTrigger>

                                      </td>
                                      <td style={{ paddingLeft: "25px" }}>State</td>
                                      <td style={{ textAlign: "left" }}>: {consigneeDetails?.rece_state




                                        ?.name}</td>
                                      <td style={{ paddingLeft: "25px" }}>House Name</td>
                                      <td style={{ textAlign: "left" }}>: {consigneeDetails?.house_name}</td>

                                    </tr>
                                    <tr>
                                      <td style={{ padding: "10px", textAlign: "left" }}>Landmark</td>
                                      <td>:  {consigneeDetails?.landmark}</td>
                                      <td style={{ paddingLeft: "25px" }}>PostOffice</td>
                                      <td style={{ textAlign: "left" }}>: {consigneeDetails?.post_office}</td>
                                      <td style={{ paddingLeft: "25px" }}>Pincode</td>
                                      <td style={{ textAlign: "left" }}>: {consigneeDetails?.pin_code}</td>

                                    </tr>
                                    <tr>
                                      <td style={{ padding: "10px", textAlign: "left" }}>District</td>
                                      <td >: {consigneeDetails?.district}</td>
                                      <td style={{ paddingLeft: "25px" }}>Alternate No</td>
                                      <td style={{ textAlign: "left" }}>: {consigneeDetails?.telephone_number}</td>
                                      <td style={{ paddingLeft: "25px" }}>Street Name</td>
                                      <td style={{ textAlign: "left" }}>: {consigneeDetails?.street}</td>

                                    </tr>
                                    <tr>
                                      <td style={{ padding: "10px", textAlign: "left" }}>Branch</td>
                                      <td>:  {consigneeDetails?.branch_id?.branch_name}</td>
                                      <td style={{ paddingLeft: "25px" }}>Created At</td>
                                      <td style={{ textAlign: "left" }}>: {consigneeDetails?.date}</td>
                                      <td style={{ paddingLeft: "25px" }}>Created By</td>
                                      <td style={{ textAlign: "left" }}>: {consigneeDetails?.staff}</td>

                                    </tr>
                                    <tr hidden={!consigneeDetails?.updated_by}>
                                      <td style={{ padding: "10px", textAlign: "left" }}>Updated At</td>
                                      <td>:  {consigneeDetails?.date1}</td>
                                      <td style={{ paddingLeft: "25px" }}>Updated By</td>
                                      <td style={{ textAlign: "left" }}>: {consigneeDetails?.staff1}</td>

                                    </tr>
                                  </table>
                                </div>
                              </AvForm>
                            </Modal>

                            <Row>
                              <Col className="col-12">

                                <Card style={{ padding: "0px 0px 2px 0px" }}>
                                  <CardBody style={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
                                    <AvForm
                                      ref={formRef}
                                      className="needs-validation"
                                      onValidSubmit={(e, v) => {
                                        handleValidConsigneeSubmit(e, v);
                                      }}
                                    >
                                      <Row>
                                        <Col md="2">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom01">
                                              Country
                                            </Label>
                                            <Select
                                              className="new"
                                              name="rece_country"
                                              value={selectedConsigneeCountry}
                                              options={country}
                                              classNamePrefix="select2-selection"
                                              onChange={(selected) => {
                                                handleSelectConsigneeChange(selected, "rece_country");
                                              }}
                                            />
                                            <p
                                              className="text-danger"
                                              style={{ fontSize: "11px" }}
                                            >
                                              {selectedConsigneeCountry === null ? errors.countryError : ""}
                                            </p>
                                          </div>
                                        </Col>
                                        <Col md="2">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom01">
                                              State
                                            </Label>
                                            <Select
                                              className="new"
                                              name="rece_state"
                                              value={selectedConsigneeState}
                                              options={state}
                                              classNamePrefix="select2-selection"
                                              onChange={(selected) => {
                                                handleSelectConsigneeChange(selected, "rece_state");
                                              }}
                                            />
                                            <p
                                              className="text-danger"
                                              style={{ fontSize: "11px" }}
                                            >
                                              {selectedConsigneeState === null ? errors.stateError : ""}
                                            </p>
                                          </div>
                                        </Col>

                                        <Col md="2">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom01">
                                              Name
                                            </Label>
                                            <AvField
                                              style={customStyles1}
                                              name="name"
                                              placeholder="Name"
                                              type="text"
                                              errorMessage="Enter Name"
                                              className="form-control"
                                              validate={{ required: { value: true } }}
                                              id="validationCustom01"
                                              value={consigneeObject?.name}
                                              onChange={handleChangeConsignee}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="2">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom01">
                                              ID No
                                            </Label>
                                            <AvField
                                              style={customStyles1}
                                              name="id_number"
                                              placeholder="ID No"
                                              type="number"
                                              //   errorMessage="Enter ID No"
                                              className="form-control"
                                              //   validate={{ required: { value: true } }}
                                              id="validationCustom01"
                                              value={consigneeObject?.id_number}
                                              onChange={handleChangeConsignee}
                                            />
                                          </div>
                                        </Col>

                                        <Col md="2">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom01">
                                              Mobile
                                            </Label>
                                            <AvField
                                              style={customStyles1}
                                              name="mobile_number"
                                              placeholder="Mobile "
                                              type="number"
                                              errorMessage="Enter Mobile"
                                              className="form-control"
                                              validate={{ required: { value: true } }}
                                              id="validationCustom01"
                                              value={consigneeObject?.mobile_number}
                                              onChange={handleChangeConsignee}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="2">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom01">
                                              Alternate No
                                            </Label>
                                            <AvField
                                              style={customStyles1}
                                              name="telephone_number"
                                              placeholder="Alternate No"
                                              type="number"
                                              //   errorMessage="Enter Whatsapp"
                                              className="form-control"
                                              //   validate={{ required: { value: true } }}
                                              id="validationCustom01"
                                              value={consigneeObject?.telephone_number}
                                              onChange={handleChangeConsignee}
                                            />
                                          </div>
                                        </Col>

                                        <Col md="2">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom01">
                                              House Name
                                            </Label>
                                            <AvField
                                              style={customStyles1}
                                              name="house_name"
                                              placeholder="House Name "
                                              type="text"
                                              //   errorMessage="Enter Address"
                                              className="form-control"
                                              //   validate={{ required: { value: true } }}
                                              id="validationCustom01"
                                              value={consigneeObject?.house_name}
                                              onChange={handleChangeConsignee}
                                            />
                                          </div>
                                        </Col>

                                        <Col md="2">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom01">
                                              Landmark
                                            </Label>
                                            <AvField
                                              style={customStyles1}
                                              name="landmark"
                                              placeholder="Landmark"
                                              type="text"
                                              //   errorMessage="Enter Landmark"
                                              className="form-control"
                                              //   validate={{ required: { value: true } }}
                                              id="validationCustom01"
                                              value={consigneeObject?.landmark}
                                              onChange={handleChangeConsignee}
                                            />
                                          </div>
                                        </Col>

                                        <Col md="2">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom01">
                                              Street Name
                                            </Label>
                                            <AvField
                                              style={customStyles1}
                                              name="street"
                                              placeholder="Street Name"
                                              type="text"
                                              //   errorMessage="Enter District"
                                              className="form-control"
                                              //   validate={{ required: { value: true } }}
                                              id="validationCustom01"
                                              value={consigneeObject?.street}
                                              onChange={handleChangeConsignee}
                                            />
                                          </div>
                                        </Col>

                                        <Col md="2">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom01">
                                              Post Office
                                            </Label>
                                            <AvField
                                              style={customStyles1}
                                              name="post_office"
                                              placeholder="Post Office"
                                              type="text"
                                              //   errorMessage="Enter Post Office "
                                              className="form-control"
                                              //   validate={{ required: { value: true } }}
                                              id="validationCustom01"
                                              value={consigneeObject?.post_office}
                                              onChange={handleChangeConsignee}
                                            />
                                          </div>
                                        </Col>
                                        <Col md="2">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom01">
                                              Pincode
                                            </Label>
                                            <AvField
                                              style={customStyles1}
                                              name="pin_code"
                                              placeholder="Pincode"
                                              type="number"
                                              //   errorMessage="Enter Pincode"
                                              className="form-control"
                                              //   validate={{ required: { value: true } }}
                                              id="validationCustom01"
                                              value={consigneeObject?.pin_code}
                                              onChange={handleChangeConsignee}
                                            />
                                          </div>
                                        </Col>

                                        <Col md="2">
                                          <div className="mb-3">
                                            <Label htmlFor="validationCustom01">
                                              District
                                            </Label>
                                            <AvField
                                              style={customStyles1}
                                              name="district"
                                              placeholder="District"
                                              type="text"
                                              //   errorMessage="Enter District"
                                              className="form-control"
                                              //   validate={{ required: { value: true } }}
                                              id="validationCustom01"
                                              value={consigneeObject?.district}
                                              onChange={handleChangeConsignee}
                                            />
                                          </div>
                                        </Col>

                                        <Col>
                                          <div className="mb-3" style={{ paddingTop: "10px" }}>
                                            {custIdTobeUpdated ? (
                                              <Button
                                                color="primary"
                                                type="update"
                                              >
                                                Update
                                              </Button>
                                            ) : (
                                              <Button
                                                color="primary"
                                                type="submit"
                                              >
                                                Submit
                                              </Button>
                                            )}
                                          </div>
                                        </Col>
                                      </Row>

                                    </AvForm>
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                            <Row>
                              <Col className="col-12">
                                <Card>
                                  <CardBody>
                                    <MDBDataTable
                                      id="consigneeTable"
                                      responsive
                                      bordered
                                      data={consigneeDataList}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>
                          {/* //###################consignee######################## */}
                          <TabPanel value="5" style={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
                            <Row>
                              <Col className="col-12">
                                <Card>
                                  <CardBody>
                                    <MDBDataTable
                                      id="customerWalletId"
                                      responsive
                                      bordered
                                      data={walletTableData}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>
                          <TabPanel value="6" style={{ fontFamily: "IBM Plex Sans, sans-serif" }}>
                            <Row>
                              <Col className="col-12">
                                <Card>
                                  <CardBody>
                                    <MDBDataTable
                                      id="customerInvoiceId"
                                      responsive
                                      bordered
                                      data={invoiceTableData}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            </Row>
                          </TabPanel>

                        </TabContext>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>

              {/* End */}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ViewCustomer;
