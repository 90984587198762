import React, { useRef, useEffect, useState } from 'react';
// import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem, CardTitle, Collapse, Table } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import { Form } from 'react-bootstrap';
import Select from "react-select"
import "./OutBound.scss";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import axios from "axios";
import FormControl from '@mui/material/FormControl';
import { isEmpty, result, set } from 'lodash';
import toastr, { error, options } from "toastr";
import ReactDOM from "react-dom";
import { BreakfastDining, NoBackpackSharp } from '@mui/icons-material';
import { getAccordionActionsUtilityClass } from '@mui/material';
import accessToken from '../../../../helpers/jwt-token-access/accessToken';
import { getLocalbody } from '../../../../helpers/globalFunctions';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Modal } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import { MDBDataTable } from 'mdbreact';


function OutBound() {

  const formRef = useRef();
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());

  const [outboundObject, setOutboundObject] = useState({ from_branch: currentLocalbody, boxes: [], orders: [] });
  const [allBranch, setallBranch] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);

  const [order, setOrder] = useState([]);
  const [bound, setBound] = useState([]);
  const [details, setDetails] = useState([]);

  const [outboundTable, setOutboundTable] = useState(null);
  const [searchData, setSearchData] = useState({
    branch_id: currentLocalbody,
  });

  const [branchoptions, setBranchOptions] = useState("");
  const [checked, setChecked] = useState([]);

  const [selectedOrders, setSelectedOrders] = useState([]);
  const [selectedBoxes, setSelectedBoxes] = useState([]);

  const [SuccessPage, setSuccessPage] = useState(false);
  const [UniqueId, setUniqueId] = useState([]);
  const [boxNumber, setBoxNumber] = useState([]);
  const [outboundID, setOutBoundID] = useState([]);
  const [branchId, setBranchID] = useState([]);
  const [outInsts, setOutInsts] = useState([]);


  let history = useHistory();


  useEffect(() => {
    getAllBranches();
    handleTableData();
    fetch_branch(outboundObject.from_branch);
  }, []);



  function getAllBranches() {
    axios
      .get(`${API_URL}cargo_Branch/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        let branch_data = res.data.data;

        branch_data = branch_data.map((el) => {

          return {
            label: el.branch_name,
            value: el._id
          }
        });

        setallBranch([
          {
            options: branch_data,
          }
        ])
        setOrder(branch_data);
      })
  }


  function handleChangeInput(e) {
    let value = e.target.value;
    let name = e.target.name;
    setOutboundObject({ ...outboundObject, [name]: value });
  }


  function handleTableData(branch_from = "", branch_to = "") {
    var url = `${API_URL}order/search/filter?branch_from=` + currentLocalbody;
    url =
      url +
      "&branch_to=" +
      branch_to
      ;
    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {


        var result = res.data.data;

        result.map((item, index) => {

          item.id = index + 1;
          item.cargoType = item?.cargo_type?.cargo_Type;

          if (item?.status === 0) {
            item.payment_status = (
              <Button className="btn btn-sm" color="danger">Not Paid</Button>
            )
          }

          if (item?.status === 1) {
            item.payment_status = (
              <Button className="btn btn-sm" color="success">pending</Button>
            )
          }

          if (item?.status === 2) {
            item.payment_status = (
              <Button className="btn btn-sm" color="warning">Paid</Button>
            )
          }


          setDetails(item);


        })
        setBound(result);


      })
  }



  function handleOptions(selected, name) {
    switch (name) {
      case 'branch_name':
        setSelectedBranch(selected);
        setSearchData({ ...searchData, branch_name: selected.value })
        setOutboundObject({
          ...outboundObject,
          branch_name: selected.value,
        });

        break;

      default:
        break;
    }

  }


  function fetch_branch(el) {

    axios
      .get(`${API_URL}cargo_Branch/data?_id=${el}`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {


        let result = res.data.data;

        setBranchOptions(
          res.data.data.branch_name,
        )

      })
  }


  function handleValidSubmit() {

    let boundData = outboundObject

    axios
      .post(`${API_URL}process/out-bound`, boundData, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => { console.log(res,"res");
        const UniqueId = res.data.data.uniqueId;
        const outboundID = res.data.data._id;
        const branchId = res.data.data.from_branch;
        const outInstatus = res.data.data.outIn_status;
        toastr.success('Order updated successfully');
        setUniqueId(UniqueId);
        setOutBoundID(outboundID);
        setBranchID(branchId);
        setOutInsts(outInstatus);
        setSuccessPage(true);
        setOutboundObject({})
        setSelectedOrders([])
        setSelectedBoxes([])
        setOrder([null]);
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });

  }

  const handleMenuCheckboxChange = (e, item) => {
    const orderId = item._id;

    const boxStatus = item.box_details.map((bx) => {
      if (bx._id === orderId) {
        return {
          ...bx,
          status: checked ? 1 : 0
        };
      }
      return bx;
    });
    setOutboundObject({
      ...outboundObject,
      orders: [ ...outboundObject.orders, orderId],
      boxes: boxStatus,
    });

  }


  const handleCheckboxChange = (e, element, item) => {
    const orderId = item.order_number;
    const boxId = element.box_number;
    const checked = e.target.checked;

    // Update the box status
    const updatedBoxes = item.box_details.map((box) => {
      if (box.box_number === element._id) {
        return {
          ...box,
          status: checked ? true : false
        };
      }
      return box;
    });
    setOutboundObject({
      ...outboundObject,
      boxes: [...outboundObject.boxes, element._id],
      orders: [...outboundObject.orders, element.order_id],
    });

    if (checked) {
      setSelectedBoxes(selectedBoxes.filter((box) => box !== boxId));
      setSelectedOrders([...selectedOrders, orderId]);
    } else {
      setSelectedBoxes(selectedBoxes.filter((box) => box.box_number !== element._id));
      setSelectedOrders(selectedOrders.filter((order) => order !== orderId));
    }
  };


  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150
      },
      {
        label: "Order Number",
        field: "order_number",
        sort: "asc",
        width: 150
      },
      {
        label: "Box Number",
        field: "boxNumber",
        sort: "asc",
        width: 150
      },
      {
        label: "Weight",
        field: "order_number",
        sort: "asc",
        width: 150
      },
      {
        label: "Cargo Type",
        field: "cargoType",
        sort: "asc",
        width: 150
      },
      {
        label: "Payment Status",
        field: "order_number",
        sort: "asc",
        width: 150
      },
    ],
    rows: bound,
  }


  return (
    <>

      <React.Fragment>

        <Modal
          show={SuccessPage}
          isOpen={SuccessPage}
          toggle={() => setSuccessPage(!SuccessPage)}
          size="md"
          centered={true}>
          <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} className='modal-header'>
            <h4 className='modal-title mt-0 ml-4'>OutBound Created Successfully</h4>
          </div>

          <div className='mt-2 mb-2' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            <h5>Please note your outbound ID: {UniqueId}</h5>
          </div>

          <div className='mt-2 mb-2' style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button className='mx-2'
              onClick={() => {
                history.push("/dashboard");
              }}
              style={{ backgroundColor: "black", borderRadius: "2rem", color: "white", border: "none" }}>
              Home
            </Button>

            <Button
              className='mx-2'
              onClick={() => {
                history.push("/process-inbound", {outboundID, branchId, outInsts})
              }}
              style={{ backgroundColor: "#eb6517", borderRadius: "2rem", color: "white", border: "none" }}
            >
              Process Inbound
            </Button>
          </div>
        </Modal>


        <div className='page-content'>
          <div className='container-fluid'>
            <h1 className='processbound'>Process Out Bound</h1>

            <Row>
              <Col xl="12">
                <AvForm
                  ref={formRef}
                  className="needs-validation"
                  onValidSubmit={(e) => { handleValidSubmit() }}
                >
                  <Row>

                    <Col md="3">
                      <Label htmlFor="validationCustom01">From Branch</Label>
                      <AvField
                        name="from_branch"
                        placeholder='From branch'
                        className='form-control'
                        value={branchoptions}
                        readOnly={true}
                      />
                    </Col>

                    <Col md="3">
                      <Label htmlFor="validationCustom01">To Branch</Label>
                      <Select
                        name="to_branch"
                        placeholder='To branch'
                        options={allBranch}
                        validate={{ required: { value: true } }}
                        classNamePrefix="select2-selection"
                        onChange={(selected) => {
                          handleOptions(selected, "branch_name")
                          setOutboundObject({
                            ...outboundObject,
                            to_branch: selected.value,
                          })
                          // handleClickOpen()
                        }}
                      />
                    </Col>

                    <Col md="4">
                      <Label >Remark</Label>
                      <AvField
                        name="outIn_status"
                        placeholder="Remarks"
                        type="textarea"
                        onChange={(e) => { handleChangeInput(e) }}
                      />
                    </Col>

                    <Row >
                      {/* {outboundObject?.to_branch?.length > 0 ? ( */}
                      <Col className="col-12">
                        <div >
                          <h5> Select Orders</h5>
                        </div>

                        <Card style={{ marginBottom: "-40px" }}>
                          <CardBody >
                            <div style={{ maxHeight: "400px", overflow: "auto" }}>
                              <Table
                                style={{ textAlign: "center" }}
                                className="table table-bordered dataTable"
                              >
                                <TableHead>
                                  <TableRow style={{ textAlign: "center" }}>

                                    <TableCell
                                      style={{ width: "180px", textAlign: "center" }}
                                    >
                                      Order Number
                                    </TableCell>

                                    <TableCell
                                      style={{ width: "210px", textAlign: "center" }}
                                    >
                                      Box Number
                                    </TableCell>

                                    <TableCell
                                      style={{ width: "100px", textAlign: "center" }}
                                    >
                                      Weight
                                    </TableCell>

                                    <TableCell
                                      style={{ width: "100px", textAlign: "center" }}
                                    >
                                      Cargo Type
                                    </TableCell>


                                    <TableCell
                                      style={{ width: "180px", textAlign: "center" }}
                                    >
                                      Payment Status
                                    </TableCell>

                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {bound &&
                                    bound.map((item, index) => (


                                      <TableRow style={{ verticalAlign: "middle" }}>
                                        <TableCell
                                          component="td"
                                          scope="row"
                                          style={{ textAlign: "left", paddingLeft: "0rem", paddingRight: "0rem", width: "0px" }}
                                        >
                                          <div>
                                            <ul style={{ listStyleType: "none" }}>
                                              <li className="mb-1">
                                                <input
                                                  type="checkbox"
                                                  value="ordernumber"
                                                  className="mb-1"
                                                  checked={
                                                    selectedOrders.includes(item.order_number) || selectedBoxes.includes(item.box_details._id)
                                                  }
                                                  onChange={(e) => {
                                                    handleMenuCheckboxChange(e, item)
                                                    if (e.target.checked) {
                                                      setSelectedOrders([...selectedOrders, item.order_number]);
                                                    } else {
                                                      setSelectedOrders(
                                                        selectedOrders.filter((order) => order !== item.order_number)
                                                      );
                                                    }
                                                  }}
                                                  style={{ cursor: "pointer" }}
                                                />
                                                <span
                                                  style={{
                                                    marginLeft: "4px",
                                                  }}
                                                >
                                                  {item.order_number}
                                                </span>
                                              </li>
                                            </ul>
                                          </div>
                                        </TableCell>

                                        <TableCell style={{ textAlign: "left" }}>
                                          <div>
                                            <ul style={{ listStyleType: "none" }}>
                                              {item.box_details &&
                                                item.box_details.map((element, index) => {
                                                  return (
                                                    <li className="mb-1">
                                                      <input
                                                        type="checkbox"
                                                        checked={
                                                          selectedOrders.includes(item.order_number) || selectedBoxes.includes(element.box_number)
                                                        }
                                                        onChange={(e) => {
                                                          handleCheckboxChange(e, element, item);
                                                          if (e.target.checked) {
                                                            setSelectedBoxes([...selectedBoxes, element.box_number]);
                                                          } else {
                                                            setSelectedBoxes(
                                                              selectedBoxes.filter((box) => box !== element.box_number)
                                                            );
                                                          }
                                                        }}
                                                        className="mb-1"
                                                        style={{ cursor: "pointer" }}
                                                      />
                                                      <span
                                                        style={{
                                                          marginLeft: "4px",
                                                        }}
                                                      >
                                                        {element.box_number}
                                                      </span>
                                                    </li>
                                                  );
                                                })}
                                            </ul>
                                          </div>
                                        </TableCell>

                                        <TableCell style={{ textAlign: "left" }}>
                                          <div>
                                            <ul style={{ listStyleType: "none" }}>
                                              {item.box_details &&
                                                item.box_details.map((element, index) => {
                                                  return (
                                                    <li className="mb-1">
                                                      <span
                                                        style={{
                                                          marginLeft: "4px",
                                                        }}
                                                      >
                                                        {element.box_weight} KG
                                                      </span>

                                                    </li>
                                                  );
                                                })}
                                            </ul>
                                          </div>
                                        </TableCell>

                                        <TableCell style={{ textAlign: "left" }}>
                                          <div>
                                            <ul style={{ listStyleType: "none" }}>
                                              <li className="mb-1">
                                                <span
                                                  style={{
                                                    marginLeft: "4px",
                                                  }}
                                                >
                                                  {item?.cargo_type?.cargo_Type}
                                                </span>

                                              </li>
                                            </ul>
                                          </div>
                                        </TableCell>

                                        <TableCell>
                                          {item.payment_status}
                                        </TableCell>

                                      </TableRow>
                                    ))}
                                </TableBody>

                              </Table>

                            </div>
                          </CardBody>

                        </Card>
                      </Col>
                      {/* // ) : null} */}

                    </Row>


                    {outboundObject?.outIn_status?.length > 0 ? (
                      <Col style={{ paddingTop: "50px" }}>
                        <Button
                          color='primary'
                          type='submit'
                        >
                          Submit
                        </Button>
                      </Col>
                    ) : null}


                  </Row>


                </AvForm>
              </Col>
            </Row>


          </div>
        </div>

      </React.Fragment>
    </>
  );

};

export default OutBound;










