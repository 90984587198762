import React, { useState, useEffect } from "react"
import {
  Container, Row, Col, CardBody, Card, CardTitle, TabContent,
  TabPane,
  NavLink,
  NavItem,
  Nav, Button, Label, Modal
} from "reactstrap"
import classnames from "classnames"
import international from "./international.svg"
import domestic from "./domestic.svg"
import worldmap from "./cargomap.svg"
import india from "./india.svg";
import saudi from "./ksa.svg";
import qatar from "./qatar.svg";
import uae from "./uae.svg";
import srilanka from "./sri-lanka.svg";
import more from "./more.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PropTypes from 'prop-types'
import { VectorMap } from "react-jvectormap"
// import "./jquery-jvectormap.scss"
import { withRouter, useHistory } from "react-router-dom";


import "./dashboard.scss";
import { Box } from "@material-ui/core";
import { fontSize, height } from "@mui/system"

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { EffectFade, Navigation, Pagination, Autoplay } from "swiper";
import { FreeMode } from "swiper"
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/modules/free-mode/free-mode.min.css';
import 'swiper/modules/navigation/navigation.scss';
import 'swiper/modules/thumbs/thumbs.min.css';

//google map
import {
  GoogleMap,
  useLoadScript,
  Marker,
  InfoWindow,
} from "@react-google-maps/api";

import { formatRelative } from "date-fns";
import "@reach/combobox/styles.css";
import { options } from "toastr"

import mystyle from "./mystyle"
import axios from "axios"
import accessToken from "../../helpers/jwt-token-access/accessToken"
import Air from "../../assets/images/cargo-type/plane.svg";
import Sea from "../../assets/images/ship.svg";
import AddTaskIcon from '@mui/icons-material/AddTask';



const Dashboard = () => {
  const [userId, setuserId] = useState(null);
  const [comp, setcomp] = useState(false);
  const [todo, settodo] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [activeTabJustify1, setactiveTabJustify1] = useState("1")
  const [isNonDoc, setisNonDoc] = useState('block');
  const [isDoc, setisDoc] = useState('none');
  const [isPick, setisPick] = useState('block');
  const [isDrop, setisDrop] = useState('none');
  const [activeCargoType, setActiveCargoType] = useState('road');
  const [activeTabJustify2, setactiveTabJustify2] = useState("1")
  const [todolist, settodolist] = useState("todobox");
  const [complaint, setcomplaint] = useState("compbox")
  const [locationselectedomn, setlocationselectedomn] = useState(null);
  const [locationselectedind, setlocationselectedind] = useState(null);
  const [locationselectedpk, setlocationselectedpk] = useState(null);
  const [locationselectedsd, setlocationselectedsd] = useState(null);
  const [locationselectedbn, setlocationselectedbn] = useState(null);
  const [locationselectedsl, setlocationselectedsl] = useState(null);
  const [Quickorder, setQuickorder] = useState([]);
  const [cargo, setCargo] = useState(null);
  const [cargoType, setCargoType] = useState(null); // Add this state to hold the cargo type 
  const [cargoCode, setCargoCode] = useState(null);
  const [selectedCargoType, setSelectedCargoType] = useState([]);
  const [QuickCountry, setQuickCountry] = useState([]);
  const [modal_center, setmodal_center] = useState(false)
  const [selectedCountryId, setSelectedCountryId] = useState(null);
  const [selectedCountryName, setSelectedCountryName] = useState(null);
  const [selectedCargoId, setSelectedCargoId] = useState(null); // State to hold the selected cargo ID
  const [handlingRate, setHandlingRate] = useState(null);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [points, setpoints] = useState({});
  const [quickObject, setQuickobject] = useState({});
  const [allItems, setAllItems] = useState([]);

  let history = useHistory();

  useEffect(() => {

    fetch_all_branchlocation()
    fetch_all_quickorders()
    fetch_cargo_type()
    handleTableData()
  }, []);


  function fetch_all_quickorders() {
    axios
      .get(`${API_URL}quick-order/countries`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {

        let result = res.data.data;

        setQuickorder(result);

      })
  }


  function fetch_all_branchlocation() {
    var data = localStorage.getItem("authUser");
    var user_obj = JSON.parse(data);
    // setuserId(user_obj._id);
    axios
      .get(`${API_URL}cargo_Branch/options?id=` + user_obj._id, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {
        var result = res.data
        result.data.map((el) => {
        })
        setpoints(result);
      })
  }


  function fetch_cargo_type() {
    axios
      .get(`${API_URL}cargoType/air_sea`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        let result = res.data.data;

        result &&
          result?.map((item, index) => {

            setCargo(item);

          })
      })

  }


  function toggleCustomJustified1(tab) {
    if (activeTabJustify1 !== tab) {
      setactiveTabJustify1(tab)
    }
  }


  function toggleCustomJustified2(tab) {
    if (activeTabJustify2 !== tab) {
      setactiveTabJustify2(tab)
    }
  }


  function handleAddTask() {
  }




  //googlemap
  const libraries = ["places"];

  const mapContainerStyle = {
    height: "100%",
    width: "100%",
  };


  const center = {
    lat: 28.7041,
    lng: 77.1025,
  };


  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  if (loadError) return "Error";
  if (!isLoaded) return "Loading...";


  const optionss = {
    styles: mystyle,
    disableDefaultUI: true,

  }


  function navigateToPage(objectId, name, cargoType, cargoId, handlingRate, cargoCode) {
    history.push({
      pathname: '/new-booking',
      state: { objectId: objectId, name: name, cargoType: cargoType, cargoId: cargoId, handlingRate: handlingRate, cargoCode: cargoCode }
    });
  }


  // modal

  function tog_center(countryId, countryName) {
    setmodal_center(!modal_center)
    setSelectedCountryId(countryId);
    setSelectedCountryName(countryName);

    // // Toggle the modal after a delay of 2 seconds (2000 milliseconds)
    // setTimeout(() => {
    //   setmodal_center(!modal_center);
    // }, 2000);
  }


  // function removeBodyCss() {
  //   document.body.classList.add("no_padding")
  // }

  function handleTableData() {
    axios
      .get(`${API_URL}dashboard/details`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        let result = res.data.data;

        result &&
          result?.map((item, index) => {

            setAllItems(item);
          })

      })
  }


  return (
    <div className="page-content">
      <div className="container-fluid">
        <h1 style={{ fontSize: "1.2rem", fontWeight: "700", color: " #282828" }}>Dashboard</h1>
        <div className="py-4 px-4 justify-content-center pb-5">

          <Swiper
            freeMode={true}
            grabCursor={true}
            spaceBetween={30}
            loop={true}
            slidesPerView={4}

            modules={[Autoplay]}
            autoplay={{
              delay: "1500",
              disableOnInteraction: false,
            }}
            className="mySwiper"
          >
            <SwiperSlide>

              <div className="dashcard">

                <div class="item">
                  <div class="cardbox">
                    <div class="hdbox">
                      <h4 class="title">Today's Order</h4>
                      <span class="topicon"><i class="fa fa-truck"></i></span>
                    </div>
                    <div class="contbox">
                      <span class="icon"><i class="fas fa-box-open"></i></span>
                      <span class="mainval">100</span>
                      <span class="subval">4306<span>Total Orders</span></span>
                    </div>
                  </div>
                </div>

              </div>

            </SwiperSlide>

            <SwiperSlide>
              <div className="dashcard">
                <div class="item">
                  <div class="cardbox">
                    <div class="hdbox">
                      <h4 class="title">Total Shipment</h4>
                      <span class="topicon"><i class="fa fa-truck"></i></span>
                    </div>
                    <div class="contbox">
                      <span class="icon"><i class="fa fa-truck"></i></span>
                      <span class="mainval">12</span>
                      <span class="subval">412<span>Total Shipment</span></span>
                    </div>
                  </div>
                </div>

              </div>
            </SwiperSlide>

            {allItems?.total_customer &&
              allItems?.total_customer?.map((cust, index) => {
                return (
                  <SwiperSlide>
                    <div className="dashcard">
                      <div class="item">
                        <div class="cardbox">
                          <div class="hdbox">
                            <h4 class="title">Total Customers</h4>
                            <span class="topicon"><i class="fa fa-truck"></i></span>
                          </div>
                          <div class="contbox">
                            <span class="icon"><i class=" fa fa-users"></i></span>
                            <span class="mainval">49</span>
                            <span class="subval">{cust?.totalcustomers}<span>Due Customers</span></span>
                          </div>
                        </div>
                      </div>

                    </div>
                  </SwiperSlide>

                )
              })
            }

            <SwiperSlide>
              <div className="dashcard">
                <div class="item">
                  <div class="cardbox">
                    <div class="hdbox">
                      <h4 class="title">Pending Pickup</h4>
                      <span class="topicon"><i class="fa fa-truck"></i></span>
                    </div>
                    <div class="contbox">
                      <span class="icon"><i class="fas fa-box-open"></i></span>
                      <span class="mainval">4</span>
                      <span class="subval">46<span>Total Pickup</span></span>
                    </div>
                  </div>
                </div>

              </div>
            </SwiperSlide>

          </Swiper>

        </div>

        <h5 className="mt-1 quickrderh5">Quick Order</h5>
        <Row className="mt-3">
          <Col md="4">
            {/* <Row>
              {Quickorder &&
                Quickorder.map((item) => {
                  return ( */}
            {/* <Col className="mt-2" md="4">
                        <Card className="flagicon">
                          <CardBody onClick={() => tog_center(item?.country_id?._id, item?.country_id?.name)} className="flagcardbody">
                            <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip
                                  id="button-tooltip-2"
                                  style={{
                                    position: "absolute",
                                    top: "-30px",
                                    height: "3px",
                                    width: "80px",
                                    fontSize: "10px"
                                  }}
                                >
                                  {item?.country_id?.name}
                                </Tooltip>
                              }
                            >
                              <div style={{ display: "flex", justifyContent: "center" }}>
                                <img
                                  src={`${API_URL}uploads/country_flag/${item?.country_id?.flag}`}
                                  width={"50px"}
                                ></img>
                              </div>
                            </OverlayTrigger>
                          </CardBody>
                        </Card>

                      </Col> */}

            {/* //       )

            // </Row> */}

            <Row>
              {
                Quickorder &&
                Quickorder.map((item) => {
                  return (

                    <>
                      <Col className="mt-2" md="4">
                        <Card className="flagicon">
                          <CardBody
                            onClick={() => {
                              tog_center(item?.country_id?._id, item?.country_id?.name);
                              fetch_cargo_type(item)

                              // navigateToPage(item?.country_id?._id, item?.country_id?.name)
                            }}
                            className="flagcardbody">

                            {/* <OverlayTrigger
                              placement="bottom"
                              overlay={
                                <Tooltip
                                  id="button-tooltip-2"
                                  style={{ position: "absolute", top: "-30px", height: "3px", width: "80px", fontSize: "14px" }}
                                >
                                  {item?.country_id?.name}
                                </Tooltip>
                              }
                            > */}
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <img src={`${API_URL}uploads/country_flag/${item?.country_id?.flag}`} width={"50px"}></img>

                              <span style={{ position: "absolute", fontSize: "14px", color: "#333333", marginTop: "61px", fontWeight: "600" }}>{item?.country_id?.name}</span>
                            </div>


                            {/* </OverlayTrigger> */}
                          </CardBody>
                        </Card>
                      </Col >

                      <Modal
                        isOpen={modal_center}
                        toggle={tog_center}
                        centered={true}
                        className="cargomodal"
                        backdropClassName="transparent-backdrop" // Add this line to use the transparent backdrop class
                      >
                        <button
                          type="button"
                          onClick={tog_center}
                          className="close-btn-btn"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true" ><b>X</b></span>
                        </button>
                        <div className="modal-body">
                          <h4 class="text-center title-pop">CHOOSE CARGO TYPE</h4>

                          <Row>

                            {cargo?.air &&
                              cargo?.air.map((ar, indx) => {
                                return (
                                  <>
                                    <Col className="mt-2" md="4">
                                      <Card className="flagicon1" style={{ width: "226px" }}>
                                        <CardBody
                                          className="cargocardbody"
                                          onClick={() => {
                                            setSelectedCargoId("63f6e44d4de48017cc5f7ec1")
                                            setCargoType("SEA")
                                            setHandlingRate("2.5")
                                            setCargoCode("2")
                                            navigateToPage(selectedCountryId, selectedCountryName, ar?.cargo_Type, ar?._id, ar?.handling_rate, ar?.cargo_type_Code)
                                          }}                                >
                                          <div style={{ display: "flex", justifyContent: "center" }}>
                                            <img src={Air} alt="noimage" className="carg-img" width={"50px"} /> &nbsp;
                                            <span class="single-line">{ar?.cargo_Type} Cargo</span>
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </>
                                )
                              })
                            }

                            {cargo?.sea &&
                              cargo?.sea?.map((se, indx) => {
                                return (
                                  <>
                                    <Col className="mt-2" md="4">
                                      <Card className="flagicon1" style={{ marginLeft: "79px", width: "226px" }}>
                                        <CardBody
                                          className="cargocardbody"
                                          onClick={() => {
                                            navigateToPage(selectedCountryId, selectedCountryName, se?.cargo_Type, se?._id, se?.handling_rate, se?.cargo_type_code)
                                          }}                                >
                                          <div style={{ display: "flex", justifyContent: "center" }}>
                                            <img src={Sea} alt="noimage" className="carg-img" width={"50px"} /> &nbsp;
                                            <span class="single-line">{se?.cargo_Type} Cargo</span>
                                          </div>
                                        </CardBody>
                                      </Card>
                                    </Col>
                                  </>
                                )
                              })
                            }

                          </Row>
                        </div>
                      </Modal >

                    </>

                  )

                })
              }
            </Row>


            <Row className="mt-2">
              <Col md="7">
                <Card

                  onClick={() => {
                    history.push("/new-booking-domestic");
                  }}

                  className="bookingbtncard" >
                  <CardBody className="bookingbtncardbody" >
                    <img className="bookiconbtn" src={domestic} alt="icon" />
                    <p className="mx-2 mt-3 bookbtnp">Domestic Booking</p>
                  </CardBody>
                </Card>
              </Col>


              <Col md="5">
                <Card className="bookingbtncard"
                  onClick={() => {
                    history.push('/new-booking');
                  }}
                >
                  <CardBody className="bookingbtncardbody">
                    <img className="bookiconbtn" src={international} alt="icon" />
                    <p className="mx-2 mt-3 bookbtnp">Others</p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>

          {/* <Modal
            isOpen={modal_center}
            toggle={() => {
              tog_center()
            }}
            centered={true}
            className="cargomodal"
          >
            <div className="modal-header" style={{ background: "rgb(255 243 243)" }}>
              <button
                type="button"
                onClick={() => {
                  setmodal_center(false)
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body" >

              <h5 class="text-center">CHOOSE CARGO TYPE</h5>

              <Row>
                {Quickorder &&
                  Quickorder.map((item, indx) => {

                    return (
                      <Col className="mt-2" md="4" >
                        <Card className="flagicon">
                          <CardBody className="cargocardbody" onClick={() => navigateToPage(item?.country_id?._id, item?.country_id?.name)}>
                            <div style={{ display: "flex", justifyContent: "center" }}>
                              <img src={Air} alt="noimage" width={"50px"} /> &nbsp;
                              <span class="single-line">Air Cargo</span>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    )

                  })
                }

              </Row>            </div>
          </Modal>
 */}
          <Col style={{ display: "flex" }} md="8">
            {/* <Card>
              <CardBody>
                <img src={worldmap} />
              </CardBody>
            </Card> */}
            {/* <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}> */}
            <GoogleMap
              mapContainerStyle={mapContainerStyle}
              zoom={3}
              center={center}
              options={optionss}
            >
              {

                points &&
                points?.data?.map((el) => {

                  return (
                    <Marker
                      position={{ lat: parseFloat(el?.latitude), lng: parseFloat(el?.longitude) }}
                    />
                  )

                })
              }

              {/* 
              <Marker
                position={{ lat: 21.4735, lng: 55.9754 }}
                 /> */}



              {/* ==============================================mapstatic */}

              {/* <Marker
                position={{ lat: 21.4735, lng: 55.9754 }}
                onClick={() => {
                  setlocationselectedomn(true)
                }} />

              {locationselectedomn ? (
                <InfoWindow position={{ lat: 21.4735, lng: 55.9754 }}
                  onCloseClick={() => {
                    setlocationselectedomn(null);
                  }}
                >
                  <div>
                    <h6>country:Oman</h6>
                  </div>
                </InfoWindow>

              ) : null}



              <Marker
                position={{ lat: 20.5937, lng: 78.9629 }}
                onClick={() => {
                  setlocationselectedind(true)
                }}
              />

              {locationselectedind ? (
                <InfoWindow position={{ lat: 20.5937, lng: 78.9629 }}
                  onCloseClick={() => {
                    setlocationselectedind(null);
                  }}
                >
                  <div>
                    <h6>country:India</h6>
                  </div>
                </InfoWindow>

              ) : null}

              <Marker
                position={{ lat: 30.3753, lng: 69.3451 }}
                onClick={() => {
                  setlocationselectedpk(true)
                }}
              />
              {locationselectedpk ? (
                <InfoWindow position={{ lat: 30.3753, lng: 69.3451 }}
                  onCloseClick={() => {
                    setlocationselectedpk(null);
                  }}
                >
                  <div>
                    <h6>country:Pakistan</h6>
                  </div>
                </InfoWindow>

              ) : null}

              <Marker
                position={{ lat: 23.8859, lng: 45.0792 }}
                onClick={() => {
                  setlocationselectedsd(true)
                }}
              />
              {locationselectedsd ? (
                <InfoWindow position={{ lat: 23.8859, lng: 45.0792 }}
                  onCloseClick={() => {
                    setlocationselectedsd(null);
                  }}
                >
                  <div>
                    <h6>country:Saudi</h6>
                  </div>
                </InfoWindow>

              ) : null}
              <Marker
                position={{ lat: 7.8731, lng: 80.7718 }}
                onClick={() => {
                  setlocationselectedsl(true)
                }}
              />
              {locationselectedsl ? (
                <InfoWindow position={{ lat: 7.8731, lng: 80.7718 }}
                  onCloseClick={() => {
                    setlocationselectedsl(null);
                  }}
                >
                  <div>
                    <h6>country:Sree-Lanka</h6>
                  </div>
                </InfoWindow>

              ) : null}
              <Marker
                position={{ lat: 23.6850, lng: 90.3563 }}
                onClick={() => {
                  setlocationselectedbn(true)
                }}
              />
              {locationselectedbn ? (
                <InfoWindow position={{ lat: 23.6850, lng: 90.3563 }}
                  onCloseClick={() => {
                    setlocationselectedbn(null);
                  }}
                >
                  <div>
                    <h6>country:bangladesh</h6>
                  </div>
                </InfoWindow>

              ) : null} */}

              {/* ========================================mapstatic */}


            </GoogleMap>
            {/* </div> */}

          </Col>

        </Row>





        {/* ===========>TODOLIST<========== */}
        <div className="todolist">

          <Button onClick={() => {
            settodolist("todoboxshow")
            settodo(true)

            if (todo) {
              settodolist("todobox")
              settodo(false)
            }
          }} className="todobtn">{!todo ? <i class="fa fa-plus mx-2"></i> : <i class="fa fa-minus mx-2"></i>}To-Do list

          </Button>

          <div class={todolist}>
            <button onClick={() => {
              settodolist("todobox")
              settodo(false)
            }} class="todohead">
              <h6 style={{ fontSize: "1.1rem", fontWeight: "700", color: "white" }} class="mb-0">To-Do list</h6>
              <i class="fa fa-angle-right"></i>
            </button>
            <ul class="todoitems custscrollA">
              <li>
                <div class="itembox" style={{ cursor: "pointer" }} onClick={() => handleAddTask()}>
                  <AddTaskIcon />
                  <div class="datacol">
                    <h6>Add task</h6>
                  </div>
                </div>
              </li>

              <li>
                <div class="itembox">
                  <div class="datecol">
                    <span class="dd">14</span>
                    <span class="mmyy">Jan 23</span>
                  </div>
                  <div class="datacol">
                    <h6>Title</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.</p>
                  </div>
                </div>
              </li>
            </ul>

          </div>
        </div>

        {/* =============>TODOLISTEND<=========== */}

        {/* ============>COMPLAINT<================ */}

        <div className="todolistcomp">
          <Button onClick={() => {
            // setcomplaint("compboxshow")
            // setcomp(true)
            // settodolist("todobox")
            // settodo(false)

            // if (comp) {
            //   setcomplaint("compbox")
            //   setcomp(false)
            // }
            history.push('/add-complaints')
          }} className="todobtncomp"> <i class="fa fa-user mx-2"></i>Complaints

          </Button>

          {/* <div class={complaint}>
            <button onClick={() => {
              setcomplaint("compbox")
              setcomp(false)


            }}
              class="todohead">
              <h6 style={{ fontSize: "1.1rem", fontWeight: "700", color: "white" }} class="mb-0">Complaints</h6>
              <i class="fa fa-angle-right"></i>
            </button>
            <ul class="todoitems custscrollA">
              <li>
                <div class="itembox">
                  <div class="datecol">
                    <span class="dd">14</span>
                    <span class="mmyy">Jan 23</span>
                  </div>
                  <div class="datacol">
                    <h6>Title</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.</p>
                  </div>
                </div>
              </li>
              <li>

                <div class="itembox">
                  <div class="datecol">
                    <span class="dd">14</span>
                    <span class="mmyy">Jan 23</span>
                  </div>
                  <div class="datacol">
                    <h6>Title</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.</p>
                  </div>
                </div>
              </li>
              <li>

                <div class="itembox">
                  <div class="datecol">
                    <span class="dd">14</span>
                    <span class="mmyy">Jan 23</span>
                  </div>
                  <div class="datacol">
                    <h6>Title</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.</p>
                  </div>
                </div>
              </li>
              <li>

                <div class="itembox">
                  <div class="datecol">
                    <span class="dd">14</span>
                    <span class="mmyy">Jan 23</span>
                  </div>
                  <div class="datacol">
                    <h6>Title</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.</p>
                  </div>
                </div>
              </li>
              <li>

                <div class="itembox">
                  <div class="datecol">
                    <span class="dd">14</span>
                    <span class="mmyy">Jan 23</span>
                  </div>
                  <div class="datacol">
                    <h6>Title</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.</p>
                  </div>
                </div>
              </li>
              <li>

                <div class="itembox">
                  <div class="datecol">
                    <span class="dd">14</span>
                    <span class="mmyy">Jan 23</span>
                  </div>
                  <div class="datacol">
                    <h6>Title</h6>
                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.</p>
                  </div>
                </div>
              </li>
            </ul>
          </div> */}
        </div>

        {/* ================>complaint<==============      */}







      </div >
    </div >
  )
}

export default Dashboard;