import React, { useState, useEffect, useRef } from "react";
import {
  Row, Col, Button, Table, Label, InputGroup, Card, CardBody
} from "reactstrap";
import { Modal } from "react-bootstrap";

import CreatableSelect from "react-select/creatable";
import toastr, { error } from 'toastr';
import moment from "moment";
import { useLocation } from 'react-router-dom';

import Barcode from 'react-barcode';
import { AvField, AvForm } from "availity-reactstrap-validation";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Select from "react-select";
import "./newshipment.scss";
import $ from "jquery";

import { Box } from "@mui/system";
import RefreshIcon from '@mui/icons-material/Refresh';
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import SweetAlert from 'react-bootstrap-sweetalert';
import { useHistory } from "react-router-dom";
import {
  getLocalbody,
} from "../../../helpers/globalFunctions";
/** FOR TAB STYLE */
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import InventoryIcon from '@mui/icons-material/Inventory';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import Loader from "react-spinner-loader";
import logoDark from "../../../assets/images/logo-large.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { SignalCellularNullTwoTone } from "@mui/icons-material";


const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 35,
  height: 35,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <LocalShippingIcon />,
    2: <InventoryIcon />,
    3: <ReceiptLongIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ['SHIPMENT DETAILS', 'BOX DETAILS', 'SUMMARY'];

function NewShipment(props) {
  var search = props?.location?.search;
  if (search) {
    var search_data = search.split("=");
    var order_search_no = search_data[1];
  }
  else
    var order_search_no = "";
  const [loader, setLoader] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const custStyle = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: state.isFocused && "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isFocused && "rgb(235 101 23 / 30%)",
      color: state.isFocused && "black",
    }),
  }
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [Tab1, setTab1] = useState(false);
  const [Tab2, setTab2] = useState(false);
  const [Tab3, setTab3] = useState(false);
  const [Tab4, setTab4] = useState(false);

  // =======================basicdetaisTAB1tate=================================================================>
  const [OrderObj_id, setOrderObj_id] = useState(null);
  const [BasicDetailsTobeUpdated, setBasicDetailsTobeUpdated] = useState(null);

  const [TotalBoxWeight, setTotalBoxWeight] = useState(0);
  const [totalENboxwieght, settotalENboxwieght] = useState(0);




  // =============================click outside close function=======>

  const formRefTab1 = useRef();
  const formRefTab2 = useRef()
  const formRefTab3 = useRef();
  const formRef = useRef();



  function resetTab3() {
    formRefTab3.current.reset();
  }



  // ==================STEP 1======================================>

  useEffect(() => {
    fetch_country();
    fetch_agent();
    fetch_cargo_type();
    handleTableData();
  }, []);


  const [Ship_id, setShip_id] = useState(null);
  const [uniqueId, setUniqueId] = useState(null);
  const [cargoType, setCargoType] = useState(null);

  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedFromCountry, setSelectedFromCountry] = useState(null);
  const [selectedToCountry, setSelectedToCountry] = useState(null);

  const [agentListOptions, setAgentListOptions] = useState([]);
  const [selectedAgentName, setSelectedAgentName] = useState(null);

  const [stateOptions, setStateOptions] = useState([]);
  const [selectedState, setSelectedState] = useState(null);

  const [portOptions, setPortOptions] = useState([]);
  const [selectedPort, setSelectedPort] = useState(null);

  const [cargoOptions, setCargoOptions] = useState([]);
  const [selectedCargo, setSelectedCargo] = useState(null);

  const [Items, setItems] = useState(null);
  const [orderDetails, setOrderDetails] = useState(null);

  const [ItemsObject, setItemsObject] = useState({});

  const [errors, setErrors] = useState({
    fromName: ""
  });

  const [masterObject, setmasterObject] = useState({ branch_id: currentLocalbody });


  function fetch_country() {
    axios
      .get(`${API_URL}newStates/countries`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        var country_data =
          res.data.data &&
          res.data.data.map((cntry, index) => {

            // if (cntry?.name?.toLowerCase() == "oman") {
            //   setSelectedFromCountry({ label: cntry?.name, value: cntry?._id });
            // }

            return {
              label: cntry?.name,
              value: cntry?._id
            }
          })

        setCountryOptions([{ options: country_data }])
      })
  }


  function fetch_agent() {
    axios
      .get(`${API_URL}cargoUser/agent?name=`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        var agent_data =
          res.data.data &&
          res.data.data.map((agnt, indx) => {

            return {
              label: agnt.firstName + agnt.lastName,
              mobile: agnt.mobile,
              company: agnt.company,
              value: agnt._id
            }
          });
        setAgentListOptions([{ options: agent_data }])
      })
  }


  function fetch_state(country_id) {
    axios
      .get(`${API_URL}newStates/list?country_id=${country_id}`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        var state_data =
          res.data.data &&
          res.data.data.map((state, indx) => {

            return {
              label: state?.name,
              value: state?._id
            }
          })

        setStateOptions([{ options: state_data }])
      })
  }


  function fetch_port(selected) {

    axios
      .post(`${API_URL}port/search_by_state`, { state: selected }, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        var port_data =
          res.data.data &&
          res.data.data.map((port, indx) => {

            return {
              label: port.port_name,
              value: port._id
            }
          })

        setPortOptions([{ options: port_data }])
      })
  }


  function fetch_cargo_type() {
    axios
      .get(`${API_URL}cargoType/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        var cargo_data =
          res.data.data &&
          res.data.data.map((crgo, indx) => {

            return {
              label: crgo.cargo_Type,
              value: crgo._id
            }
          })

        setCargoOptions([{ options: cargo_data }])
      })
  }


  function handleChangeInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    setmasterObject({ ...masterObject, [name]: value })
  }


  function handleTableData() {
    axios
      .get(`${API_URL}shipment/shipment_single_list?shipment_id=${uniqueId}`, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {
      })
  }


  function handleSelectChange(selected, name) {
    switch (name) {
      case "order_from":
        setSelectedFromCountry(selected);
        setmasterObject({
          ...masterObject,
          order_from: selected.value,
        });
        break;

      case "order_to":
        setSelectedToCountry(selected);
        fetch_state(selected.value);
        setmasterObject({
          ...masterObject,
          order_to: selected.value,
        });
        break;

      case "to_state":
        setSelectedState(selected);
        fetch_port(selected.value);
        setmasterObject({
          ...masterObject,
          to_state: selected.value,
        });
        break;

      case "port_id":
        setSelectedPort(selected);
        setmasterObject({
          ...masterObject,
          port_id: selected.value,
        });
        break;

      case "agent_name":
        setSelectedAgentName(selected);
        setmasterObject({
          ...masterObject,
          label: selected.label,
          agent: selected.value,
          mobile: selected.mobile,
          company: selected.company,
        });
        break;

      case "cargo_type":
        setSelectedCargo(selected);
        setmasterObject({
          ...masterObject,
          cargo_type: selected.value,
        });

        //  Make an API request here with the cargo type value in the body
        fetch(`${API_URL}shipment/ordersFor_Shipment?cargotype=` + selected.value, {
          method: 'GET', // You can change the HTTP method as needed
          headers: {
            "x-access-token": accessToken,
          },
        })
          .then((response) => response.json())
          .then((res) => {

            let result = res.data;

            setItems(result);

            result &&
              result?.map((bx, index) => {

                setOrderDetails(bx);

                setItemsObject(bx);

              })


          })
          .catch((error) => {
            // Handle API request error here
          });
        break;

      default:
        break;
    }
  }


  function handleValidSubmitTab1() {

    let data = {
      _id: uniqueId,
      order_from: masterObject?.order_from,
      order_to: masterObject?.order_to,
      carrier_name: masterObject?.carrier_name,
      carrier_number: masterObject?.carrier_number,
      branch_id: masterObject?.branch_id,
      agent: masterObject?.agent,
      dispatch_date: masterObject?.dispatch_date,
      bill_number: masterObject?.bill_number,
      reference_number: masterObject?.reference_number,
      port_id: masterObject?.port_id,
      to_state: masterObject?.to_state,
      cargo_type: masterObject?.cargo_type
    }

    if (Ship_id) {
      axios
        .put(`${API_URL}shipment/shipment_edit`, data, {
          headers: {
            "x-access-token": accessToken,
          },
        }).then((res) => {

          if (res.data.status === 200) {
            setActiveStep(1);
            window.scrollTo(0, 0);
            setmasterObject({});
          } else {
            toastr.error("Failed to update");
            return;
          }
        }).catch((err) => {
          toastr.error(err.response.data.message);
          return;
        })

    } else {
      axios
        .post(`${API_URL}shipment/shipment_add`, masterObject, {
          headers: {
            "x-access-token": accessToken,
          },
        }).then((res) => {

          if (res.data.status === 200) {
            const responseData = res?.data?.data;
            setCargoType(res?.data?.data?.cargo_type);
            // handleOrdersTableData();

            if (responseData) {
              setShip_id(responseData?.shipment_id);
              let shipment_id = responseData?.shipment_id
              setUniqueId(responseData?.id)
              handleTableData();
              // handleOrdersTableData();
              setActiveStep(1);
              window.scrollTo(0, 0);
              setmasterObject();
              setErrors({
                ...errors,
                fromName: ""
              })

            } else {
              toastr.error("Failed to save Basic details");
              return;
            }

          } else {
            toastr.error("Failed to save basic details");
            return;
          }
        })
        .catch((err) => {
          console.log(err);
        })


    }
  }


  function resetTab1() {
    formRefTab1.current.reset();
    setErrors({
      ...errors,
      fromName: ""
    });
    setmasterObject({});
    setSelectedFromCountry(null);
    setSelectedToCountry(null);
    setSelectedAgentName(null);
    setSelectedCargo(null);
    setSelectedPort(null);
    setSelectedState(null);
  }


  // =============================STEP 2=============================>

  const [boxChecked, setBoxChecked] = useState(false);
  const [orderChecked, setOrderChecked] = useState([]);


  const handleOrderCheckboxChange = () => {
    setOrderChecked((prevOrderChecked) => !prevOrderChecked);

    if (!orderChecked) {
      const allBoxChecked = orderDetails.box.map(() => true);
      setBoxChecked(allBoxChecked);
    } else {
      setBoxChecked([]);
    }
  };

  const handleBoxCheckboxChange = (indx) => {
    const updatedBoxChecked = [...boxChecked];
    updatedBoxChecked[indx] = !updatedBoxChecked[indx];
    setBoxChecked(updatedBoxChecked);
  };

  // =============================SUBMIT ITEM Details================>
  function handleValidSubmitTab2() {

    let data = {
      shipment_id: uniqueId,
      _id: ItemsObject?._id,
    }

    axios
      .post(`${API_URL}shipment/shipment_orders`, ItemsObject, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        if (res.data.status === 200) {
          setActiveStep(2);
          window.scrollTo(0, 0);
        }
      })
  }

  // ========================SUBMITbOXDETAILS==============>

  function handleValidSubmitTab3() {
  }



  return (
    <React.Fragment>

      {
        loader ? (

          <div className="page-content" style={{ paddingTop: "25px" }}>
            <div className="container-fluid">
              <Row>
                <Col md="12">

                  <Loader show={loader} type="box" message="Loading Data" />

                </Col>
              </Row>
            </div>
          </div>

        ) : (
          <>
            <div className="page-content" style={{ paddingBottom: "0px" }}>
              <div className="container-fluid">
                <h1 className="order-title-label">SHIPMENT</h1>
              </div>
            </div>
            <div class="tab-headers">
              <Stack sx={{ width: '64%' }} spacing={4}>
                <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </Stack>
            </div>




            <div className="page-content" style={{ paddingTop: "25px" }}>
              <div className="container-fluid">
                {/************* Step 1 ***************/}
                {
                  activeStep == 0 ? (
                    <AvForm
                      className="needs-validation"
                      ref={formRefTab1}
                      onValidSubmit={(e, v) => {
                        handleValidSubmitTab1(e, v);
                      }}
                    >
                      <Row>
                        <Col md="6">
                          <div style={{ display: "flex", justifyContent: "left" }}>
                            <i
                              className="uil-map-marker"
                              style={{
                                fontSize: "1em",
                                marginLeft: "0.2em",
                                marginRight: "0.5em"
                              }}
                            ></i>
                            <h5 className="alfarha">Route Details</h5>
                          </div>
                          <Row>

                            <Col md="4">
                              <div className="mb-4">
                                <Label htmlFor="validationCustom01">From</Label>
                                <Select
                                  name="order_from"
                                  value={selectedFromCountry}
                                  options={countryOptions}
                                  classNamePrefix="select2-selection"
                                  validate={{ required: { value: true } }}
                                  onChange={(selected) => {
                                    handleSelectChange(selected, "order_from")
                                  }}
                                />
                              </div>
                              <p className="text-danger" style={{ fontSize: "11px" }}>
                                {errors?.fromName ? errors?.fromName : ""}
                              </p>
                            </Col>

                            <Col md="4">
                              <div className="mb-4">
                                <Label htmlFor="validationCustom01">To</Label>
                                <Select
                                  name="order_to"
                                  value={selectedToCountry}
                                  options={countryOptions}
                                  classNamePrefix="select2-selection"
                                  validate={{ required: { value: true } }}
                                  onChange={(selected) => {
                                    handleSelectChange(selected, "order_to")
                                  }}
                                />
                              </div>
                            </Col>

                          </Row>
                        </Col>

                        <Col md="6">
                          <div style={{ display: "flex", justifyContent: "left" }}>
                            <i
                              class="fas fa-shipping-fast mx-1 my-1"
                              style={{
                                fontSize: "1em",
                                marginLeft: "0.2em",
                                marginRight: "0.5em"
                              }}
                            ></i>
                            <h5 className="alfarha">Carrier Details</h5>
                          </div>

                          <Row>

                            <Col md="4">
                              <div className="mb-4">
                                <Label>Carrier Name</Label>
                                <AvField
                                  name="carrier_name"
                                  type='text'
                                  className="form-control"
                                  value={masterObject?.carrier_name}
                                  onChange={handleChangeInput}
                                  placeholder="Carrier Name"
                                  errorMessage="Enter Carrier Name"
                                  validate={{ required: { value: true } }}
                                />
                              </div>
                            </Col>

                            <Col md="4">
                              <div className="mb-4">
                                <Label>Carrier No.</Label>
                                <AvField
                                  name="carrier_number"
                                  type='text'
                                  className="form-control"
                                  placeholder="Carrier Number"
                                  value={masterObject?.carrier_number}
                                  onChange={handleChangeInput}
                                  errorMessage="Enter Carrier Number"
                                  validate={{ required: { value: true } }}
                                />
                              </div>
                            </Col>

                          </Row>
                        </Col>

                        <Row>
                          <Col md="6">
                            <div style={{ display: "flex", justifyContent: "left" }}>
                              <i
                                className="uil-user"
                                style={{
                                  fontSize: "1em",
                                  marginLeft: "0.2em",
                                  marginRight: "0.5em"
                                }}
                              ></i>
                              <h5 className="alfarha">Agent Details</h5>
                            </div>

                            <Row>
                              <Col md="4">
                                <div className="mb-4">
                                  <Label>Name</Label>
                                  <Select
                                    name="agent_name"
                                    placeholder="Name"
                                    classNamePrefix="select2-selection"
                                    searchable={true}
                                    openOnClick={false}
                                    openMenuOnClick={false}
                                    value={selectedAgentName}
                                    options={agentListOptions}
                                    onChange={(selected) => {
                                      handleSelectChange(selected, "agent_name")
                                    }}
                                    type="text"
                                    validate={{ required: { value: true } }}
                                  />
                                </div>
                              </Col>

                              <Col md="4">
                                <div className="mb-4">
                                  <Label>Mobile</Label>
                                  <AvField
                                    name="mobile"
                                    placeholder="Mobile "
                                    readOnly={true}
                                    value={masterObject?.mobile}
                                    className="form-control"
                                  />
                                </div>
                              </Col>

                              <Col md="4">
                                <div className="mb-4">
                                  <Label>Company</Label>
                                  <AvField
                                    name="company"
                                    placeholder="Company "
                                    readOnly={true}
                                    value={masterObject?.company}
                                    className="form-control"
                                  />
                                </div>
                              </Col>

                            </Row>
                          </Col>

                          <Col md="6">
                            <div style={{ display: "flex", justifyContent: "left" }}>
                              <i
                                className="uil-pen"
                                style={{
                                  fontSize: "1em",
                                  marginLeft: "0.2em",
                                  marginRight: "0.5em"
                                }}
                              ></i>
                              <h5 className="alfarha">Other Details</h5>
                            </div>

                            <Row>
                              <Col md="4">
                                <div className="mb-4">
                                  <Label>Dispatch Date</Label>
                                  <AvField
                                    name="dispatch_date"
                                    type="date"
                                    className="form-control"
                                    onChange={handleChangeInput}
                                    value={masterObject?.dispatch_date}
                                    validate={{ required: { value: true } }}
                                  />
                                </div>
                              </Col>

                              <Col md="4">
                                <div className="mb-4">
                                  <Label>Reference No.</Label>
                                  <AvField
                                    name="reference_number"
                                    type="text"
                                    placeholder="Reference Number"
                                    className="form-control"
                                    value={masterObject?.reference_number}
                                    onChange={handleChangeInput}
                                    validate={{ required: { value: true } }}
                                    errorMessage="Enter Reference Number"
                                  />
                                </div>
                              </Col>

                              <Col md="4">
                                <div className="mb-4">
                                  <Label>B/L Number</Label>
                                  <AvField
                                    name="bill_number"
                                    type="text"
                                    placeholder="Bill Number"
                                    value={masterObject?.bill_number}
                                    onChange={handleChangeInput}
                                    className="form-control"
                                    validate={{ required: { value: true } }}
                                    errorMessage="Enter Bill Number"
                                  />
                                </div>
                              </Col>


                              <Col md="4">
                                <div className="mb-4">
                                  <Label>To State</Label>
                                  <Select
                                    name="to_state"
                                    type="text"
                                    placeholder="State"
                                    classNamePrefix="select2-selection"
                                    value={selectedState}
                                    options={stateOptions}
                                    onChange={(selected) => {
                                      handleSelectChange(selected, "to_state")
                                    }}
                                    validate={{ required: { value: true } }}
                                  />
                                </div>
                              </Col>

                              <Col md="4">
                                <div className="mb-4">
                                  <Label>Port Name</Label>
                                  <Select
                                    name="port_id"
                                    type="text"
                                    placeholder="Port"
                                    classNamePrefix="select2-selection"
                                    value={selectedPort}
                                    options={portOptions}
                                    onChange={(selected) => {
                                      handleSelectChange(selected, "port_id")
                                    }}
                                    validate={{ required: { value: true } }}
                                  />
                                </div>
                              </Col>

                              <Col md="4">
                                <div className="mb-4">
                                  <Label>Cargo Type</Label>
                                  <Select
                                    name="cargo_type"
                                    type="text"
                                    placeholder="Cargo Type"
                                    classNamePrefix="select2-selection"
                                    value={selectedCargo}
                                    options={cargoOptions}
                                    onChange={(selected) => {
                                      handleSelectChange(selected, "cargo_type");
                                    }}
                                    validate={{ required: { value: true } }}
                                  />
                                </div>
                              </Col>

                            </Row>
                          </Col>
                        </Row>
                      </Row>

                      <Row>

                        <Col className="btnlist py-3">
                          <button
                            type="reset"
                            onClick={() => { resetTab1() }}
                            className="refresh mx-2"><RefreshIcon /></button>

                          <button
                            className="submit mx-2"
                            type="submit"
                          >
                            Next
                          </button>

                        </Col>

                      </Row>
                    </AvForm>
                  ) : null
                }

                {/************* Step 2 ***************/}
                {
                  activeStep == 1 ? (
                    <AvForm
                      className="needs-validation"
                      onValidSubmit={(e, v) => {
                        handleValidSubmitTab2(e, v)
                      }}
                      ref={formRefTab2}
                    >
                      <Row>
                        <Col xl="12">
                          <div style={{ display: "flex", justifyContent: "left" }}>
                            <i
                              className="uil-map-maker"
                              style={{
                                fontSize: "1em",
                                marginLeft: "0.2em",
                                marginRight: "0.5em"
                              }}
                            ></i>
                            <h5 className="alfarha">Order Details</h5>
                          </div>

                          <Row>
                            <Col md="12">
                              <Table
                                id="OrderItemTable"
                                style={{ textAlign: "center" }}
                                className="table table-bordered dataTable"
                              >
                                <TableHead className="table table-bordered dataTable">
                                  <TableRow style={{ textAlign: "center" }}>
                                    <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontWeight: "700",
                                        fontSize: "0.875rem",
                                        color: "#282828"
                                      }}
                                    >
                                      #
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontWeight: "700",
                                        fontSize: "0.875rem",
                                        color: "#282828"
                                      }}
                                    >
                                      Order Number
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontWeight: "700",
                                        fontSize: "0.875rem",
                                        color: "#282828"
                                      }}
                                    >
                                      Box Number
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontWeight: "700",
                                        fontSize: "0.875rem",
                                        color: "#282828"
                                      }}
                                    >
                                      Weight
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontWeight: "700",
                                        fontSize: "0.875rem",
                                        color: "#282828"
                                      }}
                                    >
                                      Cargo Type
                                    </TableCell>

                                    {/* <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontWeight: "700",
                                        fontSize: "0.875rem",
                                        color: "#282828"
                                      }}
                                    >
                                      Payment Status
                                    </TableCell> */}

                                  </TableRow>
                                </TableHead>

                                <TableBody>
                                  {Items &&
                                    Items?.map((el, indx) => {
                                      return (
                                        <TableRow>
                                          <TableCell component="th"
                                            scope="row"
                                            style={{ width: "100px" }}
                                          >
                                            {indx + 1}
                                          </TableCell>

                                          <TableCell component="th"
                                            scope="row"
                                            style={{ width: "100px" }}
                                          >
                                            <input type="checkbox" checked={orderChecked}
                                              onChange={handleOrderCheckboxChange} /> &nbsp;&nbsp;&nbsp;&nbsp;
                                            {el?.order_number}
                                          </TableCell>

                                          {el?.box &&
                                            <TableCell component="th" style={{ width: "100px" }}>
                                              {el.box.map((bx, indx) => (
                                                <div key={indx} style={{ display: "flex", alignItems: "center" }}>
                                                  <input type="checkbox" checked={boxChecked[indx] || false}
                                                    style={{ marginRight: "10px" }} onChange={() => handleBoxCheckboxChange(indx)} />
                                                  {bx.box_number}
                                                </div>
                                              ))}
                                            </TableCell>
                                          }

                                          {el?.box &&
                                            <TableCell component="th" style={{ width: "100px" }}>
                                              {el.box.map((bw, indx) => (
                                                <div key={indx} style={{ display: "flex", alignItems: "center" }}>
                                                  {bw.box_weight}
                                                </div>
                                              ))}
                                            </TableCell>
                                          }

                                          {el?.cargotype &&
                                            <TableCell component="th" style={{ width: "100px" }}>
                                              {el.cargotype.map((crg, indx) => (
                                                <div key={indx} style={{ display: "flex", alignItems: "center" }}>
                                                  {crg.cargo_Type}
                                                </div>
                                              ))}
                                            </TableCell>
                                          }

                                          {/* {el?.box &&
                                            el?.box?.map((boxItem, boxIndex) => (
                                              <TableCell component="th" style={{ width: "100px" }} key={boxIndex}>
                                                {boxItem?.payment &&
                                                  boxItem.payment.map((paymentItem, paymentIndex) => (
                                                    <div key={paymentIndex} style={{ display: "flex", alignItems: "center" }}>
                                                      {paymentItem.payment_status}
                                                    </div>
                                                  ))
                                                }
                                              </TableCell>
                                            ))
                                          } */}

                                        </TableRow>
                                      )
                                    })
                                  }

                                </TableBody>

                              </Table>
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col className="btnlist py-3">
                          <button
                            className="back1"
                            type="reset"
                            onClick={() => {
                              {
                                setActiveStep(0);
                                window.scrollTo(0, 0);
                                handleValidSubmitTab1();

                              }
                            }}
                          >
                            <i class="fa fa-long-arrow-alt-left"></i>
                          </button>
                          <button
                            type="reset"
                            className="refresh mx-2"

                          ><RefreshIcon /></button>
                          <button
                            className="submit mx-2"
                            type="submit"
                          >
                            Next
                          </button>


                        </Col>
                      </Row>
                    </AvForm>
                  ) : null
                }


                {/************* Step 3 ***************/}

                {
                  activeStep == 2 ? (
                    <>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div className="ordersummary " style={{ backgroundColor: "#fff", width: "75%", padding: "32px", boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px" }}>
                          <Box className="summary text-center">

                            <Row>
                              <Col className='content md-5' md="6">
                                <h6 className="alfarha toCapitalize">
                                  AL-FARHA <br />
                                  Al Fuisan Street, Salalah Street Centre <br />
                                  Near Hamdan Exchange, Post Box Number 3010 <br />
                                  Tel : 23291012, Fax : +968 23202596, Mob : +968 99081618 <br />
                                  www.alfarhacargo.in
                                </h6>
                              </Col>

                              <Col md="1"></Col>

                              <Col md="5">
                                <img src={logoDark} alt="" style={{ width: "86%", marginTop: "-32px" }} />
                              </Col>
                            </Row>
                            <hr style={{ marginTop: "9px", marginBottom: "1px", height: "1.3px", color: "#0264bc" }} />
                          </Box>

                          <Row className="mt-3">
                            <Col md="6">
                              <h6 className="shipmnt-ship">Shipment Details</h6>

                              <table style={{ width: "100%" }}>
                                <tr>
                                  <th>Reference No.</th><th> : </th><th>ALF3256</th>
                                </tr>

                                <tr>
                                  <th>Shipment From</th><th> : </th><th>Oman</th><th>To</th><th> : </th><th>India</th>
                                </tr>

                                <tr>
                                  <th>Destination Port</th><th> : </th><th>Ernakulam</th>
                                </tr>

                              </table>

                            </Col>

                            <Col md="6">
                              <h6 className="shipmnt-ship">Shipment Date</h6>

                              <table style={{ width: "100%" }}>
                                <tr>
                                  <th>Date of Dispatch</th><th> : </th><th>13-09.2023</th>
                                </tr>

                                <tr>
                                  <th>Cargo Type</th><th> : </th><th>AIR</th>
                                </tr>
                              </table>
                            </Col>
                          </Row>


                          <Row className="mt-3">
                            <Col md="6">
                              <h6 className="shipmnt-ship">Carrier Details</h6>

                              <table style={{ width: "100%" }}>
                                <tr>
                                  <th>Carrier Name</th><th> : </th><th>OMANANIR</th>
                                </tr>

                                <tr>
                                  <th>Carrier No</th><th> : </th><th>321 456</th>
                                </tr>
                              </table>
                            </Col>

                            <Col md="6">
                              <h6 className="shipmnt-ship">Agent Details</h6>

                              <table style={{ width: "100%" }}>
                                <tr>
                                  <th>Name</th><th> : </th><th>ALFSLL</th>
                                </tr>

                                <tr>
                                  <th>Mobile</th><th> : </th><th>99081618</th>
                                </tr>

                                <tr>
                                  <th>Company</th><th> : </th><th>ALFSLL</th>
                                </tr>
                              </table>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="12" className="mt-3">
                              <table id="summeryItemsTable">
                                <tbody>
                                  <tr>
                                    <th>#</th>
                                    <th>Order No.</th>
                                    <th>Cargo Type</th>
                                    <th>Total Weight</th>
                                    <th>Total Amount</th>
                                  </tr>
                                </tbody>

                                <tbody>
                                  <tr>
                                    <td>1</td>
                                    <td>321 456</td>
                                    <td>AIR</td>
                                    <td>20</td>
                                    <td>300</td>
                                  </tr>
                                </tbody>
                              </table>
                            </Col>
                          </Row>

                          <Row>
                            <Col md="12" className="text-center mt-2"><h6 className="alfarha">OFFICE USE</h6></Col>

                            <Col md="6" className="mt-5">
                              Signature of Shipper
                            </Col>
                            <Col md="6" className="content1 mt-5">
                              Signature
                            </Col>
                          </Row>

                        </div>
                      </div>
                    </>
                  ) : null
                }
              </div>
            </div >
          </>
        )
      }

    </React.Fragment >
  );
}


export default NewShipment;