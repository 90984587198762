import {
    GET_SLIDER,
    GET_SLIDER_SUCCESS,
    GET_SLIDER_FAIL,
    ADD_SLIDER,
    ADD_SLIDER_SUCCESS,
    ADD_SLIDER_FAIL,
    UPDATE_SLIDER,
    UPDATE_SLIDER_SUCCESS,
    UPDATE_SLIDER_FAIL,
    DELETE_SLIDER,
    DELETE_SLIDER_SUCCESS,
    DELETE_SLIDER_FAIL,
  } from "./actionTypes";
  
  export const getAllSlider = (localbody_id) => ({
      type: GET_SLIDER,
      localbody_id:localbody_id
    });
    
    export const getAllSliderSuccess = (data) => ({
      type: GET_SLIDER_SUCCESS,
      payload: data,
    });
    
    export const getAllSliderFail = (error) => ({
      type: GET_SLIDER_FAIL,
      payload: error,
    });
    
    export const addSlider = (data) => ({
      type: ADD_SLIDER,
      payload: data,
    });
    
    export const addSliderSuccess = (data) => ({
      type: ADD_SLIDER_SUCCESS,
      payload: data,
    });
    
    export const addSliderFail = (error) => ({
      type: ADD_SLIDER_FAIL,
      payload: error,
    });
    
    export const updateSlider = (data) => ({
      type: UPDATE_SLIDER,
      payload: data,
    });
    
    export const updateSliderSuccess = (data) => ({
      type: UPDATE_SLIDER_SUCCESS,
      payload: data,
    });
    
    export const updateSliderFail = (error) => ({
      type: UPDATE_SLIDER_FAIL,
      payload: error,
    });
    
    export const deleteSlider = (data, auth_user) => ({
      type: DELETE_SLIDER,
      payload: data,
      auth_data: auth_user,
    });
    
    export const deleteSliderSuccess = (data) => ({
      type:DELETE_SLIDER_SUCCESS,
      payload: data,
    });
    
    export const deleteSliderFail = (error) => ({
      type: DELETE_SLIDER_FAIL,
      payload: error,
    });
    
    
    