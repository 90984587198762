import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import jwt_decode from "jwt-decode";
import { MDBDataTable } from "mdbreact";
import { Row, Col, Card, CardBody, Button, Label, Modal } from "reactstrap";
import Select from "react-select";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import PropTypes from "prop-types";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";
// Redux
import { connect } from "react-redux";
import { withRouter, useHistory } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { getLocalbody } from "../../../helpers/globalFunctions";
import "./style.scss";

import {
  addChartOfAcc,
  updateChartOfAcc,
  getAllChartOfAcc,
  deleteChartOfAcc,
} from "../../../store/actions";


const Chart = (props) => {
  const [chartOfAccList, setChartOfAccList] = useState([]);
  const [accountDetailType, setAccountDetailType] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState(null);
  const [chartOfAccIdTobeUpdated, setChartOfAccIdTobeUpdated] = useState(null);
  const [chartOfAccIdToBeDeleted, setChartOfAccIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);
  const [master, setMaster] = useState({
    localbody_id: getLocalbody(),
  });
  const [userId, setUserId] = useState(1);
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  const [searchData, setSearchData] = useState({ acc_type_id: '' });
  const [selectedAccountTypeSearch, setSelectedAccountTypeSearch] = useState("");
  const [acctypeGroup, setacctypeGroup] = useState([]);
  const dispatch = useDispatch();
  let history = useHistory();
  const formRef = useRef();

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const {
    chartOfAccounts,
    addingChartOfAccount,
    addChartOfAccountResponse,
    updateChartOfAccountResponse,
    error,
    deleteChartOfAccountResponse,
  } = useSelector((state) => state.chartOfAccounts);

  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };


  useEffect(() => {
    dispatch(getAllChartOfAcc(currentLocalbody));
    fetch_account_type();
    fetch_all_accounttypes();
  }, [currentLocalbody]);

  useEffect(() => {
    if (addChartOfAccountResponse.type === "success") {
      toastr.success(addChartOfAccountResponse.message);
      dispatch(getAllChartOfAcc(currentLocalbody));
    } else if (addChartOfAccountResponse.type === "failure") {
      toastr.error(error.data.message, addChartOfAccountResponse.message);
    }
  }, [addChartOfAccountResponse]);

  useEffect(() => {
    if (updateChartOfAccountResponse.type === "success") {
      setChartOfAccIdTobeUpdated(null);
      toastr.success(updateChartOfAccountResponse.message);
      dispatch(getAllChartOfAcc(currentLocalbody));
    } else if (updateChartOfAccountResponse.type === "failure") {
      toastr.error(error.data.message, updateChartOfAccountResponse.message);
    }
  }, [updateChartOfAccountResponse]);

  useEffect(() => {
    if (deleteChartOfAccountResponse.type === "success") {
      toastr.success(deleteChartOfAccountResponse.message);
      dispatch(getAllChartOfAcc(currentLocalbody));
    } else if (deleteChartOfAccountResponse.type === "failure") {
      toastr.error(error.data.message, deleteChartOfAccountResponse.message);
    }
  }, [deleteChartOfAccountResponse]);


  useEffect(() => {
    let chartOfAccountData = JSON.parse(JSON.stringify(chartOfAccounts));
    let data = [];
    chartOfAccountData.map((item, index) => {
      item.fname = "";
      if (item.acc_chart_added_by) {
        item.fname = item.acc_chart_added_by.username;
      }
      item.auth_userid = userId;
      item.acc_chart_balance = parseFloat(item.acc_chart_balance).toFixed(2);
      if (item.acc_detail_id) {
        item.acc_detail_name = item.acc_detail_id.acc_detail_name;
      }
      if (item.acc_detail_id?.acc_type_id) {
        item.acc_type_name = item.acc_detail_id.acc_type_id.acc_type_name;
      }
      item.action = (
        <div class="action">
          <i
            className="fas fa-eye"
            style={{
              fontSize: "1em",
              cursor: "pointer",
              marginLeft: "0.2em",
              marginRight: "0.5em",
            }}
            onClick={() => {
              history.push("/transaction-log/" + item._id)
              // window.location.assign("/transaction-log/" + item._id);
            }}
          ></i>

          <i
            className="far fa-edit"
            onClick={() => {
              toTop();
              preupdateChartOfAccount(item);
            }}
          ></i>

          <i
            className="far fa-trash-alt"
            onClick={() => {
              setChartOfAccIdToBeDeleted(item._id);
              setConfirmDeleteAlert(true);
            }}
          ></i>

        </div>
      );
      item.id = index + 1;

      data.push(item);
    });
    setChartOfAccList(data);

  }, [chartOfAccounts]);


  function fetch_all_accounttypes() {
    axios
      .get(`${API_URL}account_type/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var brand_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label: item.acc_type_name,
              value: item._id,
            };
          });

        setacctypeGroup([
          {
            options: brand_data,
          },
        ]);
      });
  }


  const handleSearchSelectChange = (selected, name) => {
    switch (name) {
      case "accountType":
        setSelectedAccountTypeSearch(selected);
        setSearchData({
          ...searchData,
          acc_type_id: selected.value,
        });

      default:
        break;
    }
  };

  // This function handles the search and updates the table data
  // const handleSearch = () => {
  //   let acc_type_id = searchData?.acc_type_id ? searchData.acc_type_id : "";
  //   dispatch(getAllChartOfAcc(currentLocalbody, acc_type_id));
  // };

  let preupdateChartOfAccount = (item) => {
    item.localbody_id = getLocalbody();
    let options = {
      label:
        item.acc_detail_id.acc_detail_name +
        "-" +
        item.acc_detail_id.acc_type_id.acc_type_name,
      value: item.acc_detail_id._id,
    };
    setSelectedAccountType(options);
    setChartOfAccIdTobeUpdated(item._id);
    setMaster(item);
  };


  function fetch_account_type() {
    axios
      .get(`${API_URL}account_detail/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        var account_type_data =
          res.data.data &&
          res.data.data.map((item) => {
            return {
              label:
                item.acc_detail_name + "-" + item.acc_type_id.acc_type_name,
              value: item._id,
            };
          });

        setAccountDetailType([
          {
            options: account_type_data,
          },
        ]);
      });
  }

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 100,
      },
      {
        label: "Account Head",
        field: "acc_type_name",
        sort: "asc",
        width: 400,
      },
      {
        label: "Account Category",
        field: "acc_detail_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Chart Account Name",
        field: "acc_chart_name",
        sort: "asc",
        width: 200,
      },
      {
        label: "Balance",
        field: "acc_chart_balance",
        sort: "asc",
        width: 200,
      },
      {
        label: "Action",
        field: "action",
        sort: "disabled",
        width: 100,
      },
    ],
    rows: chartOfAccList,
  };

  function handleSelectedAccountType(selected) {
    setSelectedAccountType(selected);
    setMaster({
      ...master,
      ["acc_detail_id"]: selected.value,
    });
  }

  let handleChangeInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setMaster({
      ...master,
      [name]: value,
    });
  };

  const handleValidSubmit = (event, values) => {
    if (selectedAccountType == null) {
      toastr.error("Account Category is required");
      return;
    }
    if (chartOfAccIdTobeUpdated) {
      axios
        .put(`${API_URL}chart_of_account`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Chart of Account updated successfully");
            formRef.current.reset();
            setSelectedAccountType(null);
            setMaster({
              ["auth_userid"]: userId,
              ["localbody_id"]: getLocalbody(),
            });
            dispatch(getAllChartOfAcc(currentLocalbody));
            setChartOfAccIdTobeUpdated(null);
          } else {
            toastr.error("Failed to update Chart of Account");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    } else {
      axios
        .post(`${API_URL}chart_of_account`, master, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          if (res.data.status === 200) {
            toastr.success("Chart of Account added successfully");
            formRef.current.reset();
            setSelectedAccountType(null);
            setMaster({
              ["auth_userid"]: userId,
              ["localbody_id"]: getLocalbody(),
            });
            dispatch(getAllChartOfAcc(currentLocalbody));
          } else {
            toastr.error("Failed to add Chart of Account");
            return;
          }
        })
        .catch((err) => {
          toastr.error(err.response.data.message);
          return;
        });
    }
    // chartOfAccIdTobeUpdated
    //   ? dispatch(updateChartOfAcc(master))
    //   : dispatch(addChartOfAcc(master));
  };


  const reset = () => {
    formRef.current.reset();
    setMaster({ ["auth_userid"]: userId, ["localbody_id"]: getLocalbody() });
    setSelectedAccountType(null);
    setChartOfAccIdTobeUpdated(null);
    setChartOfAccIdToBeDeleted(null);
  };


  // const resets = () => {
  //   setSelectedAccountTypeSearch(null);
  //   dispatch(getAllChartOfAcc(currentLocalbody));
  // };


  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmButtonText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(
                `${API_URL}chart_of_account` + "/" + chartOfAccIdToBeDeleted,
                {
                  headers: {
                    "x-access-token": accessToken,
                  },
                }
              )
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Chart of Account deleted successfully");
                  if (master && master?._id === chartOfAccIdToBeDeleted) {
                    formRef.current.reset();
                    setSelectedAccountType(null);
                    setMaster({
                      ["auth_userid"]: userId,
                      ["localbody_id"]: getLocalbody(),
                    });
                    setChartOfAccIdTobeUpdated(null);
                  }
                  dispatch(getAllChartOfAcc(currentLocalbody));
                } else {
                  toastr.error("Failed to delete Chart of Account");
                  return;
                }
              });
            // dispatch(deleteChartOfAcc(chartOfAccIdToBeDeleted, userId));
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}


      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Chart of Account" />

          <Row>
            <Col xl="12">
              {/* <Card>
                <CardBody> */}
              <AvForm
                ref={formRef}
                className="needs-validation"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <Row>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Account Category</Label>
                      <Select
                        name="customer_community_id"
                        value={selectedAccountType}
                        onChange={(value) => {
                          handleSelectedAccountType(value);
                        }}
                        options={accountDetailType}
                        classNamePrefix="select2-selection"
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">Chart Name</Label>
                      <AvField
                        name="acc_chart_name"
                        placeholder="Chart Name"
                        type="text"
                        errorMessage="Enter chart account name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        id="validationCustom05"
                        value={master.acc_chart_name}
                        onChange={handleChangeInput}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom05">Balance</Label>
                      <AvField
                        name="acc_chart_balance"
                        placeholder="Balance"
                        type="number"
                        errorMessage="Enter balance"
                        className="form-control"
                        min="0"
                        validate={{ required: { value: true } }}
                        id="validationCustom05"
                        value={master.acc_chart_balance}
                        onChange={handleChangeInput}
                      />
                    </div>
                  </Col>

                  <Col
                    md="1"
                    className="mt-4"
                    style={{ paddingTop: "5px" }}
                  >
                    <div>
                      {chartOfAccIdTobeUpdated ? (
                        <Button
                          color="primary"
                          type="submit"
                          disabled={addingChartOfAccount ? true : false}
                        >
                          {addingChartOfAccount ? "Updating" : "Update"}
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          type="submit"
                          disabled={addingChartOfAccount ? true : false}
                        >
                          {addingChartOfAccount ? "Adding" : "Submit"}
                        </Button>
                      )}
                    </div>
                    {/* <div className="mt-4">
                          <Button
                            color="primary"
                            type="submit"
                          >
                            Save
                          </Button>
                        </div> */}
                  </Col>

                  <Col md="1" style={{ paddingTop: "5px", marginLeft: "-9px" }}>
                    <div className="mt-4">
                      <Button
                        color="danger"
                        type="reset"
                        onClick={() => reset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </Col>

                </Row>
              </AvForm>
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>


          <Row>
            <Col className="col-12">

              {/* <Row>
                <Col md="2">
                  <div className="mb-3">
                    <Select
                      placeholder="Account Head"
                      name="accountType"
                      value={selectedAccountTypeSearch}
                      onChange={(selected) => {
                        handleSearchSelectChange(selected, "accountType");
                      }}
                      options={acctypeGroup}
                      classNamePrefix="select2-selection"
                    />
                  </div>
                </Col>

                <Col md="1">
                  <div className="mb-3">
                    <Button
                      color="primary"
                      type="submit"
                      onClick={() => handleSearch()}
                    >
                      Search
                    </Button>
                  </div>
                </Col>

                <Col md="1" style={{ marginLeft: "-9px" }}>
                  <div className="mb-3">
                    <Button
                      color="danger"
                      type="reset"
                      onClick={() => resets()}
                    >
                      Reset
                    </Button>
                  </div>
                </Col>

              </Row> */}

              <MDBDataTable
                id="table_chartof_account"
                responsive
                bordered
                data={data}
                searching={true}
                info={true}
                disableRetreatAfterSorting={true}
                entries={20}
              />
            </Col>
          </Row>

        </div>
      </div>
    </React.Fragment>
  );
};



export default Chart;
