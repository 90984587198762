import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Check from '@mui/icons-material/Check';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import InventoryIcon from '@mui/icons-material/Inventory';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { AvField, AvForm } from "availity-reactstrap-validation";
import Select from "react-select";
import { Row, Col, Button, Table, Label } from "reactstrap";
import { getLocalbody } from "../../../helpers/globalFunctions";
import axios from "axios";
import accessToken from "../../../helpers/jwt-token-access/accessToken";

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#784af4',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
  display: 'flex',
  height: 22,
  alignItems: 'center',
  ...(ownerState.active && {
    color: '#784af4',
  }),
  '& .QontoStepIcon-completedIcon': {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
  '& .QontoStepIcon-circle': {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
  zIndex: 1,
  color: '#fff',
  width: 35,
  height: 35,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.active && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  }),
  ...(ownerState.completed && {
    backgroundImage:
      'linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(235 101 23) 50%, rgb(235 101 23) 100%)',
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <PersonOutlineIcon />,
    2: <FactCheckIcon />,
    3: <InventoryIcon />,
    4: <ReceiptLongIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const steps = ['PERSONAL DETAILS', 'ITEM DETAILS', 'BOX DETAILS', 'SUMMERY'];

export default function CustomizedSteppers() {
  const [activeStep, setActiveStep] = useState(0);
  const custStyle = {
    control: (provided, state) => ({
      ...provided,
      boxShadow: "none",
      border: state.isFocused && "none",
    }),
    menu: (provided, state) => ({
      ...provided,
      border: "none",
      boxShadow: "none",
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isFocused && "rgb(235 101 23 / 30%)",
      color: state.isFocused && "black",
    }),
  }
  const formRefTab1 = useRef();
  const formRefTab2 = useRef()
  const formRefTab3 = useRef();
  const [errors, seterrors] = useState({
    country_from: "",
    country_to: "",
    cargo_type: "",
    payment_type: "",
  })
  /*************** STEP 1 FORM START *************/
  const [selectcargo, setselectcargo] = useState(null);
  const [cargooptions, setcargooptions] = useState([]);
  const [masterobjbasic, setmasterobjbasic] = useState({ branch_id: currentLocalbody, booking_type: 0 });
  const [ItemDetailsObject, setItemDetailsObject] = useState({ received_cash: 0, credit_balance: 0, vat_amount: 0, vat_percentage: 0, discount_amount: 0, credit_balance: 0 });
  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const [currentLocalbody, setCurrectLocalbody] = useState(getLocalbody());
  function handleSelectChange(selected, name, index = null) {
    switch (name) {
      case "cargo_type":
        setselectcargo(selected);
        setmasterobjbasic({ ...masterobjbasic, cargo_type: selected.value })
        setItemDetailsObject({ ...ItemDetailsObject, handling_rate: selected.handling_rate })
        break;


    }
  }
  function fetch_cargo_type() {
    axios
      .get(`${API_URL}cargoType/list`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var cargoop =
          res.data.data &&
          res.data.data.map((cargo) => {
            return {
              label: cargo?.cargo_sub_type,
              value: cargo?._id,
              handling_rate: cargo?.handling_rate,
            }
          })
        setcargooptions([{ options: cargoop }])
      })

  }
  const handleValidSubmitTab1 = () => {
  }
  useEffect(() => {
    fetch_cargo_type();
  }, [])
  return (
    <React.Fragment>
      <div style={{ textAlign: "center", display: 'flex', justifyContent: "center" }}>
        <Stack sx={{ width: '64%' }} spacing={4}>
          <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </div>

      <div className="page-content" style={{ paddingTop: "25px" }}>
        <div className="container-fluid">
          {
            activeStep == 0 ? (
              <>
                <AvForm
                  className="needs-validation"
                  ref={formRefTab1}
                  onValidSubmit={(e, v) => {
                    handleValidSubmitTab1(e, v)
                  }}

                >
                  <Row>
                    <Col md="3">
                      <div className="mb-3">
                        <Label>Cargo Type</Label>

                        <Select
                          styles={custStyle}
                          name="cargo_type"
                          value={selectcargo}
                          options={cargooptions}
                          placeholder="Cargo Type"
                          onChange={(selected) => {
                            handleSelectChange(selected, "cargo_type")
                          }}
                          classNamePrefix="select2-selection" />

                        <p className='text-danger'
                          style={{ fontSize: "11px" }}>
                          {selectcargo === null ? errors.cargo_type : ""}
                        </p>

                      </div>
                    </Col>
                  </Row>
                </AvForm>
              </>
            ) : null
          }
        </div>
      </div>
    </React.Fragment>

  );
}