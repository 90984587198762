import React, { useRef, useEffect, useState } from 'react';
import Select from 'react-select';
import { MDBDataTable } from "mdbreact";
import toastr from 'toastr';
import { Modal, } from "react-bootstrap";
import "./Order.scss"
import alfarha from "../../../assets/images/logo-dark.png"

import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem , Table} from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
// import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import axios from 'axios';
import accessToken from '../../../helpers/jwt-token-access/accessToken';
import { set } from 'lodash';
import { Link, withRouter } from "react-router-dom"


function Order() {

  const wrapperRef = useRef(null);
  const wrRef = useRef(null);
  const wraaRef = useRef(null);
  const wrrRef = useRef(null);

  useEffect(() => {
    handleTableData()
    fetch_all_cargo();
    fetch_all_fromop();
    //  load_names();


  }, [])





  const [orderObject, setorderObject] = useState({});
  const [cargooptions, setcargooptions] = useState([]);
  const [selectcargo, setselectcargo] = useState(null);
  const [fromoptions, setfromoptions] = useState([]);
  const [selectfromop, setselectfromop] = useState(null);
  const [tooptions, settooptions] = useState([]);
  const [selectToop, setselectToop] = useState(null);
  const [selectcashcredit, setselectcashcredit] = useState(null);
  const [nameOptions, setnameOptions] = useState([]);
  const [mobileOptions, setmobileOptions] = useState([]);
  const [receNameOP, setreceNameOP] = useState([]);
  const [receMobileOp, setreceMobileOp] = useState([]);
  const [display, setdisplay] = useState(false);
  const [displaymob, setdisplaymob] = useState(false);
  const [displayrecname, setdisplayrecname] = useState(false);
  const [displayreMob, setdisplayreMob] = useState(false);
  const [searchsenderName, setsearchsenderName] = useState("");
  const [searchsenderMobile, setsearchsenderMobile] = useState("");
  const [searchreceName, setsearchreceName] = useState("");
  const [searchreceMob, setsearchreceMob] = useState("");
  const [senderId, setsenderId] = useState("");
  const [senderwhatsappnum, setsenderwhatsappnum] = useState("");
  const [values, setvalues] = useState([
    {
      item_type: "",
      item_category: "",
      description: "",
      unit: "",
      quantity: "",
      rate: "",
      amount: "",

    },
  ])
  const [selectCargotypeSearch, setselectCargotypeSearch] = useState(null);
  const [selectFromCountrySearch, setselectFromCountrySearch] = useState(null);
  const [itemfortable, setitemfortable] = useState([]);
  const [oredrIdToBeDeleted, setoredrIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setconfirmDeleteAlert] = useState(null);





  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";


  useEffect(() => {
    document.addEventListener("mousedown", handleclickoutside);
    return () => {
      document.removeEventListener("mousedown", handleclickoutside);
    }
  },)
  const printInvoice = () => {
    window.print()
  }

  const handleSuggestion = (arg) => {
    setsearchsenderName(arg)

    setdisplay(false);
  }

  const handleSuggestionmob = (arg) => {
    setsearchsenderMobile(arg)
    setdisplaymob(false);

  }

  const handleSuggestionrecename = (arg) => {
    setsearchreceName(arg)
    setdisplayrecname(false);
  }


  const handleSuggestionrecemobile = (arg) => {
    setsearchreceMob(arg)
    setdisplayreMob(false);
  }

  const addMore = () => {
    setvalues([
      ...values, {
        item_type: "",
        item_category: "",
        description: "",
        unit: "",
        quantity: "",
        rate: "",
        amount: "",
      }
    ])
  }

  const handleRemove = (index) => {
    const list = [...values];
    list.splice(index, 1);
    let valueArray = [];

    list &&
      list.forEach((element) => {

      })




    setvalues(list);
  }


  const handleclickoutside = (event) => {
    const { current: wrap } = wrapperRef;
    if (wrap && !wrap.contains(event.target)) {
      setdisplay(false);
    }
    const { current: wr } = wrRef;
    if (wr && !wr.contains(event.target)) {
      setdisplaymob(false);
    }
    const { current: wrr } = wraaRef;
    if (wrr && !wrr.contains(event.target)) {
      setdisplayrecname(false);
    }

    const { current: wra } = wrrRef;
    if (wra && !wra.contains(event.target)) {
      setdisplayreMob(false);
    }
  }



  const fetch_all_cargo = () => {
    axios
      .get(`${API_URL}cargoType/option`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {

        var cargo_data =
          res.data.data &&
          res.data.data.map((cargo) => {
            return {
              label: cargo?.cargo_Type,
              value: cargo?._id
            }
          })
        setcargooptions([
          {
            options: cargo_data,
          }
        ])
      })
  }

  const fetch_all_fromop = () => {
    axios
      .get(`${API_URL}newStates/countries`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {

        var from_t0_data =
          res.data.data &&
          res.data.data.map((from) => {
            return {
              label: from?.name,
              value: from?.id,
            }
          })
        setfromoptions([
          {
            options: from_t0_data,
          }
        ])
        settooptions([
          {
            options: from_t0_data
          }
        ])
      })
  }

  function handleInpInput(e) {
    let name = e.target.name;
    let value = e.target.value;

    console.log("name=", name);
    console.log("value=", value);

    //sender mobile search
    if (name === "senderMobileNumber") {
      setsearchsenderMobile(value)
      axios
        .get(`${API_URL}customer/c/search?name=${""}&mobile=${value}`, {
          headers: {
            "x-access-token": accessToken,
          }
        })
        .then((res) => {
          let mbe = []
          let mbresult = res.data.data;
          mbresult.map((mob) => {
            console.log("itemmob=", mob);
            let arr = {
              label: mob?.mobile_number,
              value: mob?._id
            }

            mbe.push(arr)
          })
          setmobileOptions(mbe);


        })
    }


    // sender name search
    if (name === "senderName") {

      setsearchsenderName(value)


      axios
        .get(`${API_URL}customer/c/search?name=${value}&mobile=${""}`, {
          headers: {
            "x-access-token": accessToken,
          }
        })
        .then((res) => {
          console.log("sender", res);
          let nme = []

          let result = res.data.data
          result.map((item) => {
            console.log("senderdetailsnamesearch", item);

            let array = {
              label: item.name,
              value: item._id
            }

            nme.push(array)

          })

          setnameOptions(nme);


        })
    }

    //receivername
    if (name === "receiverName") {
      setsearchreceName(value)
      axios
        .get(`${API_URL}receiver/search?name=${value}&mobile=${""}`, {
          headers: {
            "x-access-token": accessToken
          }
        })
        .then((res) => {
          console.log(res);
          let rec = [];
          let rceresult = res.data.data;
          rceresult.map((rname) => {
            let rcarr = {
              label: rname?.name,
              value: rname?._id
            }
            rec.push(rcarr);
          })
          setreceNameOP(rec);
        })
    }

    //recei mobile
    if (name === "receiverMobileNumber") {
      setsearchreceMob(value);
      axios
        .get(`${API_URL}receiver/search?name=${""}&mobile=${value}`, {
          headers: {
            "x-access-token": accessToken,
          }
        })
        .then((res) => {
          let recmb = [];
          let reresult = res.data.data;
          console.log("result=", reresult);
          reresult.map((rmb) => {
            console.log("rmb=", rmb);
            let rcmob = {
              label: rmb?.mobile_number,
              value: rmb?._id
            }
            recmb.push(rcmob);
          })
          setreceMobileOp(recmb)
        })
    }


  }
  const handleSelecetchange = (selected, name) => {
    console.log("name", name);

  };

  function handleTableData(
    country = "",
    cargoType = "",
  ) {
    var url = `${API_URL}order/search?`

    url =
      url +
      "&cargoType" +
      cargoType +
      "&country=" +
      country

    axios
      .get(url, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var result = res.data.data;
        // console.log("filterdata", result);
        let orderdata = [];
        result.map((item, index) => {
          console.log("item=", item);
          item.id = index + 1




          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  // marginLeft: "0.4rem",
                  marginRight: "0.4rem",
                  marginTop: "0.4rem",
                }}

              ></i>
              <i className='uil-edit-alt'
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  marginLeft: "0.4rem",
                  marginRight: "0.4rem"
                }}
              ></i>
              <i className='uil-trash-alt'
                style={{ fontSize: "1rem", cursor: "pointer" }}
                onClick={() => {
                  setoredrIdToBeDeleted(item._id);
                  setconfirmDeleteAlert(true)
                }}

              ></i>
            </div>
          );




          orderdata.push(item)

        })
        setitemfortable(orderdata)
      })
  }



  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150
      },
      {
        label: "Date",
        field: "id",
        sort: "asc",
        width: 150
      },
      {
        label: "Id",
        field: "order_number",
        sort: "asc",
        width: 150
      },
      {
        label: "From",
        field: "country_from",
        sort: "asc",
        width: 150
      },
      {
        label: "To",
        field: "country_to",
        sort: "asc",
        width: 150
      },
      {
        label: "Type",
        field: "country_to",
        sort: "asc",
        width: 150
      },
      {
        label: "Consigner",
        field: "senderName",
        sort: "asc",
        width: 150
      },
      {
        label: "Mobile",
        field: "sendermobile",
        sort: "asc",
        width: 150
      },
      {
        label: "Total",
        field: "grand_total",
        sort: "asc",
        width: 150
      },
      {
        label: "Paid",
        field: "paid_amount",
        sort: "asc",
        width: 150
      },
      {
        label: "Balance",
        field: "balance",
        sort: "asc",
        width: 150
      },
      {
        label: "Status",
        field: "payment_status",
        sort: "asc",
        width: 150
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150
      },
    ],
    rows: itemfortable,
  }








  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}order/delete?_id=` + oredrIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken
                }
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("item deleted successfully");
                  handleTableData();
                }
                else {
                  toastr.error("faild to delete")
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setconfirmDeleteAlert(false);
          }}
          onCancel={() => setconfirmDeleteAlert(false)}
        >
          Are you sure want to delete
        </SweetAlert>
      ) : null}
      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumb title="Home" breadcrumbItem="Order" />
          <Row>
            <Col xl="12">
              <Card className='p-2'>
                <CardBody>
                  <AvForm
                    className="needs-validation"
                  >
                    <Row>
                      <Col md="3">
                        <div className='mb-3'>
                          <Label>Cargo Type</Label>

                          <Select
                            name="cargoType"
                            classNamePrefix="slect2-selection"
                            value={selectcargo}
                            options={cargooptions}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className='mb-3'>
                          <Label>Cash</Label>

                          <Select
                            name="cashType"
                            classNamePrefix="slect2-selection"
                            value={selectcashcredit}
                            options={[
                              { label: "Cash", value: 0 },
                              { label: "Credit", value: 1 }
                            ]}
                          />
                        </div>

                      </Col>
                      <Col md="3">
                        <div className='mb-3'>
                          <Label>From</Label>

                          <Select
                            name="countryFrom"
                            classNamePrefix="slect2-selection"
                            value={selectfromop}
                            options={fromoptions}
                          />
                        </div>
                      </Col>
                      <Col md="3">
                        <div className='mb-3'>
                          <Label>To</Label>

                          <Select
                            name="countryTo"
                            classNamePrefix="slect2-selection"
                            value={selectToop}
                            options={tooptions}

                          />
                        </div>
                      </Col>
                      <Row>
                        <Col md="6">
                          <h5> Consigner/Sender</h5>
                          <Row>
                            <Col md="4">
                              <div className='mb-4' ref={wrapperRef}   >
                                <Label>Name</Label>
                                <AvField
                                  name="senderName"
                                  placeholder="Name"
                                  type="text"
                                  onClick={() => setdisplay(!display)}
                                  className="form-control"
                                  onChange={handleInpInput}
                                  value={searchsenderName}
                                />
                                <div className='dropdownAuto'>
                                  {display && nameOptions.map((it) => (

                                    <div className='dropdownItem' onClick={() => handleSuggestion(it.label,)}>

                                      <span >
                                        {it?.label}
                                      </span>
                                    </div>
                                  ))}
                                </div>

                              </div>
                            </Col>
                            <Col md="4">
                              <div className='mb-4'>
                                <Label>ID No</Label>
                                <AvField
                                  name="idno"
                                  placeholder="ID.No"
                                  type="text"
                                  className="form-control"
                                  value={senderId}
                                  onChange={handleInpInput}



                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className='mb-4' ref={wrRef} >
                                <Label>Mobile No</Label>
                                <AvField
                                  name="senderMobileNumber"
                                  placeholder="Mobile.No"
                                  type="text"
                                  className="form-control"
                                  onChange={handleInpInput}
                                  onClick={() => setdisplaymob(!displaymob)}

                                  value={searchsenderMobile}
                                />
                                <div className='dropdownAuto'>
                                  {displaymob && mobileOptions.map((it) => (

                                    <div className='dropdownItem' onClick={() => handleSuggestionmob(it.label)} >

                                      {it?.label}

                                    </div>
                                  ))
                                  }
                                </div>
                              </div>
                            </Col>
                            <Col md="4">
                              <div className='mb-4'>
                                <Label>Whatssapp No</Label>
                                <AvField
                                  name="senderWhattsappNumber"
                                  placeholder="Whatssapp.No"
                                  className="form-control"
                                  type="text" />

                              </div>
                            </Col>
                          </Row>

                        </Col>
                        <Col md="6">
                          <h5>Consignee/Receiver</h5>
                          <Row>
                            <Col md="4">
                              <div className='mb-4' ref={wraaRef}>
                                <Label>Name</Label>
                                <AvField
                                  name="receiverName"
                                  placeholder="Name"
                                  className="form-control"
                                  type="text"
                                  onChange={handleInpInput}
                                  onClick={() => setdisplayrecname(!displayrecname)}
                                  value={searchreceName}
                                />
                                <div className='dropdownAuto'>
                                  {displayrecname && receNameOP.map((rcn) => (
                                    <div className='dropdownItem' onClick={() => handleSuggestionrecename(rcn.label)}>
                                      <span>
                                        {rcn.label}
                                      </span>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Col>
                            <Col md="4">
                              <div className='mb-4'>
                                <Label>ID No</Label>
                                <AvField
                                  name="id"
                                  placeholder="ID.NO"
                                  className="form-control"
                                  type="text" />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className='mb-4'>
                                <Label>House Name</Label>
                                <AvField
                                  name="receiverHouseName"
                                  placeholder="House Name"
                                  className="form-control"
                                  type="text" />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className='mb-4'>
                                <Label>Land Mark</Label>
                                <AvField
                                  name="receiverLandmark"
                                  placeholder="Land Mark"
                                  className="form-control"
                                  type="text" />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className='mb-4'>
                                <Label>Street</Label>
                                <AvField
                                  name="receiverStreet"
                                  placeholder="Street"
                                  className="form-control"
                                  type="text" />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className='mb-4'>
                                <Label>Post Office</Label>
                                <AvField
                                  name="receiverPostOffice"
                                  placeholder="Post Office"
                                  className="form-control"
                                  type="text" />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className='mb-4'>
                                <Label>Pin Code</Label>
                                <AvField
                                  name="receiverPinCode"
                                  placeholder="Pin Code"
                                  className="form-control"
                                  type="text" />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className='mb-4'>
                                <Label>District</Label>
                                <AvField
                                  name="receiverDistrict"
                                  placeholder="District"
                                  className="form-control"
                                  type="text" />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className='mb-4'>
                                <Label>State</Label>
                                <Select
                                  name="receiverState"
                                  classNamePrefix="slect2-selection"
                                />
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="mb-4" ref={wrrRef}>
                                <Label >Mobile No.</Label>
                                <AvField
                                  name="receiverMobileNumber"
                                  placeholder="Mobile No."
                                  type="tel"
                                  className="form-control"
                                  validate={{ required: { value: true } }}
                                  errorMessage="Enter valid 10 digit number"
                                  minLength={10}
                                  maxLength={10}
                                  onChange={handleInpInput}
                                  onClick={() => setdisplayreMob(!displayreMob)}
                                  value={searchreceMob}
                                />
                                <div className='dropdownAuto'>
                                  {displayreMob && receMobileOp.map((mob) => (
                                    <div className='dropdownItem' onClick={() => handleSuggestionrecemobile(mob.label)}>
                                      <span>
                                        {mob.label}
                                      </span>
                                    </div>
                                  ))
                                  }
                                </div>
                              </div>
                            </Col>
                            <Col md="4">
                              <div className="mb-4">
                                <Label >
                                  Landline No.
                                </Label>
                                <AvField
                                  name="receiverTelephoneNumber"
                                  placeholder="Landline No."
                                  type="phone"
                                  //errorMessage="Enter valid 11 digit number"
                                  className="form-control"
                                  // validate={{ required: { value: true } }}
                                  // id="validationCustom04"
                                  minLength={11}
                                  maxLength={11}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Col className='col-12'>
                        <h5>Item Details</h5>
                        <CardBody style={{ padding: "0px 0px 2px 0px" }}>
                          <Row>
                            <Col xl="12">
                              <Table
                                style={{ textAlign: "center" }}
                                className="table table-bordered dataTable">
                                <TableHead>
                                  <TableRow style={{ textAlign: "center" }}>
                                    <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontWeight: "500",
                                        fontSize: "12.819px",
                                        color: "#495057",
                                      }}>
                                      #
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontWeight: "500",
                                        fontSize: "12.819px",
                                        color: "#495057",
                                      }}>
                                      Item Type
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontWeight: "500",
                                        fontSize: "12.819px",
                                        color: "#495057",
                                      }}>
                                      Item Category
                                    </TableCell>

                                    <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontWeight: "500",
                                        fontSize: "12.819px",
                                        color: "#495057",
                                      }}>
                                      Description
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontWeight: "500",
                                        fontSize: "12.819px",
                                        color: "#495057",
                                      }}>
                                      Unit
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontWeight: "500",
                                        fontSize: "12.819px",
                                        color: "#495057",
                                      }}>
                                      Quantity
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontWeight: "500",
                                        fontSize: "12.819px",
                                        color: "#495057",
                                      }}>
                                      Rate
                                    </TableCell>
                                    <TableCell
                                      style={{
                                        width: "0px",
                                        textAlign: "center",
                                        fontWeight: "normal",
                                        paddingLeft: "0rem",
                                        paddingRight: "0rem",
                                        fontWeight: "500",
                                        fontSize: "12.819px",
                                        color: "#495057",
                                      }}>
                                      Amount
                                    </TableCell>
                                    {values.length !== 1 ? (
                                      <TableCell
                                        style={{
                                          width: "0px",
                                          textAlign: "center",
                                          fontWeight: "normal",
                                          paddingLeft: "0rem",
                                          paddingRight: "0rem",
                                        }}
                                      >
                                        Action
                                      </TableCell>
                                    ) : null}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {values &&
                                    values.map((item, index) => (
                                      <TableRow>
                                        <TableCell
                                          component="th"
                                          scope="row"
                                          style={{ textAlign: "center" }}>
                                          {index + 1}
                                        </TableCell>
                                        <TableCell>
                                          <Select
                                            name="item_type"
                                            placeholder="Item Type"
                                            options={[
                                              { label: "Item", value: 1 },
                                              { label: "Packing", value: 2 },
                                              { label: "Extra", value: 3 },
                                            ]}
                                            classNamePrefix="select2-selection"
                                          // onChange={(e)=>handleTableChange(e,index)}

                                          />
                                        </TableCell>
                                        <TableCell>
                                          <Select
                                            name="item_category"
                                            placeholder="Category"
                                          // onChange={(e)=>handleTableChange(e,index)}
                                          />
                                        </TableCell>
                                        <TableCell>
                                          <AvField
                                            name="description"
                                            type="text"
                                            placeholder="Description" />
                                        </TableCell>
                                        <TableCell>
                                          <AvField
                                            name="unit"
                                            type="number"
                                            placeholder="Unit" />
                                        </TableCell>
                                        <TableCell>
                                          <AvField
                                            name="quantity"
                                            type="number"
                                            placeholder="Quantity" />
                                        </TableCell>
                                        <TableCell>
                                          <AvField
                                            name="rate"
                                            type="number"
                                            placeholder="Rate" />
                                        </TableCell>
                                        <TableCell>
                                          <AvField
                                            name="amount"
                                            type="number"
                                            placeholder="Amount" />
                                        </TableCell>
                                        {values.length !== 1 && (
                                          <i
                                            className="uil uil-times"
                                            style={{
                                              fontSize: "20px",
                                              cursor: "pointer",
                                              paddingLeft: "5px",
                                              display: "flex",
                                              justifyContent: "center",
                                            }}
                                            onClick={() =>
                                              handleRemove(index)
                                            }
                                          ></i>
                                        )}

                                      </TableRow>
                                    ))

                                  }
                                </TableBody>
                              </Table>
                            </Col>
                          </Row>
                          <span
                            onClick={() => addMore()}
                            style={{ width: "190px", cursor: "pointer" }}

                          >{" "}
                            + Add New {" "}
                          </span>
                          <br />
                        </CardBody>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="5">
                        <h5 className='m-3'>Cargo Details</h5>
                        <Row>
                          <Col xl="12">
                            <Table
                              style={{ textAlign: "center" }}
                              className="table table-bordered dataTable">
                              <TableHead>
                                <TableRow style={{ textAlign: "center" }}>
                                  <TableCell
                                    style={{
                                      width: "0px",
                                      textAlign: "center",
                                      fontWeight: "normal",
                                      paddingLeft: "0rem",
                                      paddingRight: "0rem",
                                      fontWeight: "500",
                                      fontSize: "12.819px",
                                      color: "#495057",
                                    }}>
                                    #
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "0px",
                                      textAlign: "center",
                                      fontWeight: "normal",
                                      paddingLeft: "0rem",
                                      paddingRight: "0rem",
                                      fontWeight: "500",
                                      fontSize: "12.819px",
                                      color: "#495057",
                                    }}>
                                    No Of Boxes
                                  </TableCell>
                                  <TableCell
                                    style={{
                                      width: "0px",
                                      textAlign: "center",
                                      fontWeight: "normal",
                                      paddingLeft: "0rem",
                                      paddingRight: "0rem",
                                      fontWeight: "500",
                                      fontSize: "12.819px",
                                      color: "#495057",
                                    }}>
                                    Handling Rate
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                <TableRow>
                                  <TableCell
                                    component="th"
                                    scope="row"
                                    style={{
                                      textAlign: "center", fontSize: "12.819px",
                                      fontFamily: "IBM Plex Sans, sans-serif",
                                      color: "#495057", fontWeight: "500"
                                    }}>
                                    1
                                  </TableCell>
                                  <TableCell>
                                    <AvField
                                      name="boxnumber"
                                      placeholder="No Of Boxes"
                                      type="number"
                                      validate={{ required: { value: true } }}
                                      errorMessage="Enter No Of Boxes" />
                                  </TableCell>
                                  <TableCell>
                                    <AvField
                                      name="rate"
                                      placeholder="Rate" />
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Col>
                        </Row>
                      </Col>
                      <Col md="7">
                        <Row>
                          <table className='mt-5'>
                            <tr>
                              <td>Total Weight</td>
                              <td>
                                <AvField
                                  name="weight"
                                  type="text"
                                  placeholder="Total Weight"
                                />
                              </td>
                              <td>Handling Rate</td>
                              <td>
                                <AvField
                                  name="handrate"
                                  type="number"
                                  placeholder="Handling Rate"
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>Amount</td>
                              <td>
                                <AvField
                                  name="amount"
                                  type="number"
                                  placeholder="Amount" />
                              </td>
                              <td>Discount</td>
                              <td>
                                <AvField
                                  name="discount"
                                  type="text"
                                  placeholder="discount" />

                              </td>
                            </tr>
                            <tr>
                              <td>VAT%</td>
                              <td>
                                <AvField
                                  name="vat"
                                  placeholder="Vat %" />
                              </td>
                              <td>VAT Amount</td>
                              <td>
                                <AvField
                                  name="vatamount"
                                  placeholder="VAT Amount" />
                              </td>
                            </tr>
                            <tr>
                              <td>Total value</td>
                              <td>
                                <AvField
                                  name="totalvalue"
                                  placeholder="Total Value" />
                              </td>
                              <td>Received Cash</td>
                              <td>
                                <AvField
                                  name="receivedcash"
                                  placeholder="Received Cash" />
                              </td>
                            </tr>
                            <tr>
                              <td>Credit Balance</td>
                              <td>
                                <AvField
                                  name="creditbalance"
                                  placeholder="Credit Balance" />
                              </td>
                              <td><b>Grand Total</b></td>
                              <td>
                                <AvField
                                  name="grandtotal"
                                  placeholder="Grand Total" />
                              </td>
                            </tr>
                          </table>
                        </Row>
                      </Col>
                      <Col md="12" style={{ paddingTop: "4px" }}>
                        <div className='mt-4' style={{ display: 'flex', justifyContent: "end" }}>
                          <Button
                            className='mx-2'
                            color='primary'
                            type='Submit'>
                            Submit
                          </Button>
                          <Button
                            color='danger'
                            type='reset'>
                            Reset
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>


          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <Col className='col-12'>
                    <h5>Box Details</h5>
                  </Col>
                  <Row>
                    <Col xl="12">
                      <AvForm>
                        <Table style={{ textAlign: "center" }}
                          className="table table-bordered dataTable">
                          <TableHead>
                            <TableRow style={{ textAlign: "center" }}>
                              <TableCell
                                style={{
                                  width: "0px",
                                  textAlign: "center",
                                  fontWeight: "normal",
                                  paddingLeft: "0rem",
                                  paddingRight: "0rem",
                                  fontWeight: "500",
                                  fontSize: "12.819px",
                                  color: "#495057",
                                }}>
                                Box No
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "0px",
                                  textAlign: "center",
                                  fontWeight: "normal",
                                  paddingLeft: "0rem",
                                  paddingRight: "0rem",
                                  fontWeight: "500",
                                  fontSize: "12.819px",
                                  color: "#495057",
                                }}>
                                Weight
                              </TableCell>
                              <TableCell
                                style={{
                                  width: "0px",
                                  textAlign: "center",
                                  fontWeight: "normal",
                                  paddingLeft: "0rem",
                                  paddingRight: "0rem",
                                  fontWeight: "500",
                                  fontSize: "12.819px",
                                  color: "#495057",
                                }}>
                                Packed Items
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ textAlign: "center" }}>
                                35677
                              </TableCell>
                              <TableCell>
                                <AvField
                                  name="quantity"
                                  type="number"
                                  placeholder="Quantity" />
                              </TableCell>
                              <TableCell>
                                <AvField
                                  name="quantity"
                                  type="text"
                                  placeholder="Packed Items" />
                              </TableCell>

                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ textAlign: "center" }}>
                                65437
                              </TableCell>
                              <TableCell>
                                <AvField
                                  name="quantity"
                                  type="number"
                                  placeholder="Quantity" />
                              </TableCell>
                              <TableCell>
                                <AvField
                                  name="quantity"
                                  type="text"
                                  placeholder="Packed Items" />
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                component="th"
                                scope="row"
                                style={{ textAlign: "center" }}>
                                64747
                              </TableCell>
                              <TableCell>
                                <AvField
                                  name="quantity"
                                  type="number"
                                  placeholder="Quantity" />
                              </TableCell>
                              <TableCell>
                                <AvField
                                  name="quantity"
                                  type="text"
                                  placeholder="Packed Items" />
                              </TableCell>
                            </TableRow>
                          </TableBody>

                        </Table>
                      </AvForm>
                    </Col>
                  </Row>
                  <span
                    style={{ width: "190px", cursor: "pointer" }}>
                    + AddNew
                  </span>


                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <h5>Order Summery</h5>
              <Card>
                <CardBody>

                  <div className='invoice-title'>
                    <h4 className='float-end font-size-16'>
                      Order ID:34545
                      <p>OMN<i style={{ color: "darkorange" }} class="fa fa-ship mx-1"> </i>IND</p>
                    </h4>
                  </div>
                  <div className='mb-4'>
                    <img src={alfarha} height="40" />
                  </div>
                  <div className='text-muted'>
                    {/* <p className='mb-1'>Sreedarsh srv</p> */}
                    <h5>Al-Farha cargo oman</h5>
                    <p className='mb-1'><i className='uil uil-envelope-alt me-1'>alfarha@gmail.com</i> </p>
                    <p><i className='uil uil-phone me-1'></i>97676576575</p>
                  </div>

                  <hr className='my-4' />

                  <Row>
                    <Col sm="6">
                      <div className='text-muted'>
                        <h5 className='font-size-16 mb-3'>Billed TO:</h5>
                        <h5 className='font-size-15 mb-2'>Arjun srvinfo</h5>
                        <p className='mb-1'>4450 Fancher Drive Dallas, TX 75247</p>
                        <p className="mb-1">arjsrv@gmail.com</p>
                        <p>967848498</p>
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="text-muted text-sm-end">
                        <div>
                          <h5 className="font-size-16 mb-1">Invoice No:</h5>
                          <p>#1678588</p>
                        </div>
                        <div className="mt-4">
                          <h5 className="font-size-16 mb-1">Invoice Date:</h5>
                          <p>25-10-2022</p>
                        </div>
                        <div className="mt-4">
                          <h5 className="font-size-16 mb-1">Order No:</h5>
                          <p>#32456</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <div className='py-2'>
                    <h5 className='font-size-15'>Order Summary</h5>
                    <Table className='table-nowrap table-centered mb-0'>
                      <thead>
                        <tr>
                          <th style={{width:"70px"}}>ID</th>
                          <th>Cargo Type</th>
                          <th>Boxes</th>
                          <th>Weight</th>
                          <th>Price</th>
                          <th className='text-end' style={{width:"120px"}}>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                          <tr>
                             <td>23456</td>
                             <td>Air Cargo</td>
                             <td>5</td>
                             <td>150kg</td>
                             <td>250</td>
                             <td className='text-end'>1000</td>
                          </tr>
                          <tr>
                             <td>13476</td>
                             <td>Sea cargo</td>
                             <td>7</td>
                             <td>50kg</td>
                             <td>120</td>
                             <td  className='text-end'>1400</td>
                          </tr>
                          <tr>
                             <td>98765</td>
                             <td>Air Exp</td>
                             <td>4</td>
                             <td>100kg</td>
                             <td>250</td>
                             <td  className='text-end'>400</td>
                          </tr>

                          <tr>
                              <th colSpan="5" className="text-end">Sub Total</th>
                              <td className="text-end">2800</td>
                            </tr>
                            <tr>
                              <th colSpan="5" className="border-0 text-end">
                                Discount :</th>
                              <td className="border-0 text-end">-300 </td>
                            </tr>
                            <tr>
                              <th colSpan="5" className="border-0 text-end">
                                Shipping Charge :</th>
                              <td className="border-0 text-end">500</td>
                            </tr>
                            <tr>
                              <th colSpan="5" className="border-0 text-end">
                                Tax</th>
                              <td className="border-0 text-end">150</td>
                            </tr>
                            <tr>
                              <th colSpan="5" className="border-0 text-end">Total</th>
                              <td className="border-0 text-end"><h4 className="m-0">2450</h4></td>
                            </tr>
                          
                          
                      </tbody>
                    </Table>
                  </div>
                  <div className="d-print-none mt-4">
                        <div className="float-end">
                          <Link to="#" onClick={printInvoice} className="btn btn-success waves-effect waves-light me-1"><i className="fa fa-print"></i></Link>{" "}
                          <Link to="#" className="btn btn-primary w-md waves-effect waves-light">Send</Link>
                        </div>
                      </div>
                  


                </CardBody>
              </Card>
            </Col>
          </Row>


        </div>
      </div>
    </React.Fragment>
  )
}

export default Order



// window.onload=function(){
//   var hidediv=document.getElementById('senderdiv') ;
//   document.onclick=function(div){
//     if(div.target.id !== "senderdiv"){
//       setnameOptions([])
//     }
//     if(div.target.id !== "senderdiv2"){
//       setmobileOptions([])
//     }
//   }
// }