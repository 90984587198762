import React, { useRef } from 'react';
import {
    Row,
    Col,
} from "reactstrap";
import { Box } from "@mui/system";
import Barcode from 'react-barcode';
import moment from "moment";
import LogoDark from "../../assets/images/logo-large.png";
import img from "../../assets/images/img.png";
import img2 from "../../assets/images/img2.png";
export const PrintableComponentDomestic = React.forwardRef((props, ref) => {
    const SingleOrderList1 = props.data;
    const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
    const barcodeRef = useRef(null);
    return ( 
        <div ref={ref}>
            <div className="modal-content" style={{ display: "flex", justifyContent: "center" }}>
                <div className="ordersummary " style={{ width: "100%", padding: "32px" }}>
                    <Box className="summary text-center">
                        {/* <Row>
                    <h4 className="alfarha font-size-16">BOOKING NOTE # {SingleOrderList?.order_number}</h4>
                  </Row> */}
                        <Row>
                            <Col className="content md-8" md="6">
                                <h6 className="alfarha toCapitalize" style={{ fontFamily: 'Almarai' }}>{SingleOrderList1?.branch_id?.branch_name?.toUpperCase()} <br /> {SingleOrderList1?.branch_id?.address?.toUpperCase()} <br /> {SingleOrderList1?.branch_id?.state_id?.name?.toUpperCase()}, {SingleOrderList1?.branch_id?.country_id?.name?.toUpperCase()}</h6>
                                <Row>
                                    <Col md="12" style={{ fontFamily: 'Almarai' }}>
                                        <i class="fa fa-phone-alt icons"></i>
                                        {SingleOrderList1?.branch_id?.mobile_no}
                                    </Col>
                                    <Col md="12" style={{ fontFamily: 'Almarai' }}>
                                        <i class="fa fa-globe icons" aria-hidden="true"></i>
                                        {SingleOrderList1?.branch_id?.website}
                                    </Col>
                                    <Col md="12" style={{ fontFamily: 'Almarai' }}>
                                        <i class="fa fa-envelope icons"></i>
                                        {SingleOrderList1?.branch_id?.email_id}
                                    </Col>

                                </Row>
                            </Col>
                            <Col md="1"></Col>
                            <Col md="5">
                                <img
                                    src={LogoDark}
                                    style={{ width: "86%", marginTop: "-21px" }}
                                    alt=""
                                />

                            </Col>
                        </Row>
                        <hr style={{ marginTop: "9px", marginBottom: "1px", height: "1.3px", color: "#000" }} />
                    </Box>
                    <Row>
                        <Col md="12">
                            <table style={{ width: "100%" }}>
                                <tr>
                                    <td style={{ padding: "0px" }}><h4 className="alfarha font-size-16" style={{ fontFamily: 'Almarai' }}>INVOICE CUM BOOKING NOTE</h4></td>
                                    <td style={{ padding: "0px", textAlign: "right" }}><Barcode value={SingleOrderList1?.order_number} format="CODE128"
                                        displayValue={false}
                                        fontSize={12}
                                        width={1.5}
                                        height={30}
                                        ref={barcodeRef}
                                    /></td>
                                </tr>
                            </table>

                        </Col>

                    </Row>
                    <hr style={{ marginTop: "9px", marginBottom: "1px", height: "1.3px", color: "#000" }} />
                    <Row>
                        <Col md="12" className="text-center">
                            <h4 className="alfarha" style={{ fontSize: "2rem", fontFamily: "Almarai" }}>{(SingleOrderList1?.booking_type == 0) ? SingleOrderList1?.country_to?.name?.toUpperCase() : SingleOrderList1?.city_to?.name?.toUpperCase()}</h4>
                        </Col>
                    </Row>
                    <hr style={{ marginTop: "9px", marginBottom: "1px", height: "1.3px", color: "#000" }} />
                    <Row>
                        <Col md="6" className="alfarha" style={{ fontFamily: 'Almarai' }}>Booking ID : <b> {SingleOrderList1?.order_number}</b></Col>
                        <Col md="6" className="content1 alfarha" style={{ fontFamily: 'Almarai' }}>Date : <b>{moment(SingleOrderList1?.date).format("DD-MM-YYYY")}</b></Col>
                    </Row>
                    <hr className='hr-dashed' style={{ marginTop: "9px" }} />
                    <Row>
                        <Col>

                            <h6 className="alfarha" style={{ fontFamily: 'Almarai' }}>Consignor / Shipper Details :</h6>
                            <table id="senderAddTable">
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Name</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList1?.sender?.name?.toUpperCase()}</th>
                                </tr>

                                {SingleOrderList1?.sender?.id_number && (
                                    <tr>
                                        <th style={{ fontFamily: 'Almarai' }}>ID No</th><th> : </th><th className="toCapitalize">{SingleOrderList1?.sender?.id_number}</th>
                                    </tr>
                                )}
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Mobile No</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList1?.sender?.mobile_number}
                                        {
                                            SingleOrderList1?.sender?.whattsapp_number ? (
                                                <> / {SingleOrderList1?.sender?.whattsapp_number}</>
                                            ) : null
                                        }
                                    </th>
                                </tr>

                            </table>

                        </Col>
                        <Col className="mx-2" md-3>
                            <h6 className="alfarha" style={{ fontFamily: 'Almarai' }}>Consignee / Receiver Details :</h6>
                            <table id="receiverAddTable">
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Name</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList1?.receiver?.name?.toUpperCase()}</b></th>
                                </tr>
                                {/* <tr>
                                    <th>House Name</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.house_name?.toLowerCase()}</th>
                                </tr>
                                <tr>
                                    <th>Street</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toLowerCase() : "")}</th>
                                </tr>
                                <tr>
                                    <th>Post Office</th><th> : </th><th className="alfarha toCapitalize">{(SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toLowerCase() : "")}</th>
                                </tr> */}

                                {/* {SingleOrderList?.receiver?.address && ( */}
                                {/* <tr>
                                    <th>Address</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.house_name?.toUpperCase()},
                                        {(SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toUpperCase() : "")}, {(SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toUpperCase() : "")}</th>
                                </tr> */}

                                {/* {SingleOrderList?.receiver?.pin_code && (
                                    <tr>
                                        <th>Pincode</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.pin_code?.toUpperCase()}</th>
                                    </tr>
                                )}
                                <tr>
                                    <th>District</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.district?.toUpperCase()}</th>
                                </tr>
                                <tr>
                                    <th>State</th><th> : </th><th className="alfarha toCapitalize">{SingleOrderList?.receiver?.rece_state?.name?.toUpperCase()}</th>
                                </tr> */}
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Address</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList1?.receiver?.address?.toUpperCase()}</b></th>
                                </tr>
                                <tr>
                                    <th style={{ fontFamily: 'Almarai' }}>Mobile No</th><th> : </th><th className="alfarha toCapitalize"><b>{SingleOrderList1?.receiver?.mobile_number} {SingleOrderList1?.receiver?.telephone_number ? (
                                        <> / {SingleOrderList1?.receiver?.telephone_number}</>
                                    ) : null}</b></th>
                                </tr>
                                {/* <tr>
                        <th>Address</th><th> : </th><th className="toCapitalize" style={{ textAlign: "justify" }}>{SingleOrderList?.receiver?.house_name?.toLowerCase() + " " + (SingleOrderList?.receiver?.street ? SingleOrderList.receiver.street.toLowerCase() : "")}{" "}{(SingleOrderList?.receiver?.landmark ? SingleOrderList.receiver.landmark.toLowerCase() : "") + " " + (SingleOrderList?.receiver?.post_office ? SingleOrderList.receiver.post_office.toLowerCase() : "") + " " + SingleOrderList?.receiver?.pin_code}
                          <br />{SingleOrderList?.receiver?.district + ", " + SingleOrderList?.receiver?.rece_state?.name}
                        </th>
                      </tr> */}

                            </table>



                        </Col>

                    </Row>
                    <Row >
                        <Col className="mt-2">
                            {/* <h6 className="alfarha">ITEM DETAILS</h6> */}
                            <hr className='hr-dashed' style={{ marginTop: "9px" }} />

                            <table id="invoicetableid">
                                <tbody>
                                    <tr>
                                        <th className='SlNoColumn' style={{ fontFamily: 'Almarai' }}>Sl No</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Item Category</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Unit</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Qnty</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Rate</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Amount</th>
                                    </tr>
                                </tbody>
                                <tbody>
                                    {
                                        SingleOrderList1 &&
                                        SingleOrderList1?.item_details?.map((el, index) => { 
                                            return (
                                                <tr>
                                                    <td style={{ fontFamily: 'Almarai' }}>{index + 1}</td>
                                                    <td className="toCapitalize" style={{ fontFamily: 'Almarai' }}>{el?.item_category?.name?.toUpperCase()}</td>
                                                    <td style={{ fontFamily: 'Almarai' }}>{el?.unit?.toUpperCase()}</td>
                                                    <td style={{ fontFamily: 'Almarai' }}>{el?.quantity.toFixed(3)}</td>
                                                    <td style={{ fontFamily: 'Almarai' }}>{el?.rate.toFixed(3)}</td>
                                                    <td style={{ fontFamily: 'Almarai' }}>{el?.amount.toFixed(3)}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>

                            <table style={{ width: "100%" }}>
                                <tbody >
                                    <tr style={{ height: "100px" }}>
                                        <td colspan="6"></td>
                                    </tr>
                                    <tr className='dashed-border'>
                                        <th style={{ fontFamily: 'Almarai' }}>Total Cargo Value(OMR)</th>
                                        <th style={{ fontFamily: 'Almarai' }}> :{SingleOrderList1?.total_weight?.toFixed(3)}</th>
                                        <th></th>
                                        <th></th>
                                        <th></th>
                                        <th style={{ textAlign: "right", fontFamily: "Almarai" }}>{SingleOrderList1?.amount?.toFixed(3)}</th>
                                    </tr>
                                    <tr className='dashed-border'>
                                        {SingleOrderList1?.no_of_boxes ? (
                                            <th rowSpan="4" className='box-dsh-box' style={{ fontFamily: 'Almarai' }}>
                                                No of Boxes : <br />
                                                <span className="second-line-value">{SingleOrderList1?.no_of_boxes}</span>
                                            </th>
                                        ) : ''}

                                        <th rowSpan="4" style={{ fontFamily: 'Almarai' }}>Total Weight :
                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span className='second-line-value'>{SingleOrderList1?.total_weight?.toFixed(2)}</span>
                                        </th>
                                    </tr>
                                    {/* <tr >
                                        <th style={{ fontFamily: 'Almarai' }}>Handling Rate</th><th>: {SingleOrderList?.handling_rate ? SingleOrderList?.handling_rate?.toFixed(3) : "0.000"}</th>
                                        <th style={{ fontFamily: 'Almarai' }}>Handling Charge </th><th style={{ textAlign: "right" }}>: {SingleOrderList?.handling_rate_total ? SingleOrderList?.handling_rate_total?.toFixed(3) : "0.000"}</th>
                                    </tr> */}
                                    <tr className='dashed-border'>
                                        <th style={{ fontFamily: 'Almarai' }}>VAT %</th><th>: {SingleOrderList1?.vat_percentage ? SingleOrderList1?.vat_percentage?.toFixed(3) : "0.000"}</th>
                                        <th style={{ fontFamily: 'Almarai' }}>VAT </th><th style={{ textAlign: "right" }}>: {SingleOrderList1?.vat_amount ? SingleOrderList1?.vat_amount?.toFixed(3) : "0.000"}</th>
                                    </tr>
                                    <tr className='dashed-border'>
                                        <th style={{ fontFamily: 'Almarai' }}><b>Grand Total</b></th>
                                        <th colSpan={4} style={{ textAlign: "right", fontFamily: "Almarai" }}><b>{SingleOrderList1?.grand_total?.toFixed(3)}</b></th>
                                    </tr>
                                </tbody>

                            </table>
                            <hr className='hr-dashed' style={{ marginTop: "0px", margin: "0" }} />

                        </Col>
                    </Row>

                    {/* <Row style={{ height: "100px" }}>
                        <Col md="5">
                            <div>Expected Delivery: 18/08/2023</div>
                            <div>Cargo Packed By:
                                &nbsp;<input type='checkbox' />&nbsp;Company&nbsp;
                                <input type='checkbox' />&nbsp;Customer
                            </div>
                        </Col>

                        <Col md="4">
                            <div>
                                The provided delivery date is under normal circumstances,
                                and we <br />guarantee to deliver as per the terms & conditions.
                            </div>
                        </Col>

                        <Col md="3">
                            <div>
                                .ﺔــﻳدﺎﻌﻟا فوﺮــﻈﻟا ﻞــﻇ ﻲــﻓ مﺪــﻘﻤﻟا ﻢﻴﻠــﺴﺘﻟا ﺦــﻳرﺎ
                                مﺎﻜﺣﻷاو طوﺮﺸﻠﻟ ﺎًﻘﻓو ﻢﻴﻠﺴﺘﻟا ﻦﻤﻀﻧ ﻦﺤﻧ
                            </div>
                        </Col>
                    </Row> */}

                    <br />
                    <Row >
                        <Col md="12" >
                            <div>
                                <img src={img} alt="" style={{ width: "100%" }} />
                            </div>
                        </Col>
                    </Row>

                    <Row >
                        <Col md="6" className='mt-5' style={{ fontWeight: "700", fontFamily: 'Almarai' }}>
                            Signature of Shipper
                        </Col>
                        <Col md="6" className="content1 mt-5" style={{ fontWeight: "700", fontFamily: 'Almarai' }}>
                            Payment Received By
                        </Col>
                    </Row>

                </div>

            </div>
        </div>
    );
});