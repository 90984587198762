import {
    GET_FUND_TRANSFER,
    GET_FUND_TRANSFER_SUCCESS,
    GET_FUND_TRANSFER_FAIL,
    ADD_FUND_TRANSFER,
    ADD_FUND_TRANSFER_SUCCESS,
    ADD_FUND_TRANSFER_FAIL,
    GET_FUND_SOURCE,
    GET_FUND_SOURCE_SUCCESS,
    GET_FUND_SOURCE_FAIL
} from "./actionTypes";

export const getAllFundTransfer = (localbody_id) => ({
    type: GET_FUND_TRANSFER,
    localbody_id: localbody_id,
});

export const getAllFundTransferSuccess = (data) => ({
    type: GET_FUND_TRANSFER_SUCCESS,
    payload: data,
});

export const getAllFundTransferFail = (error) => ({
    type: GET_FUND_TRANSFER_FAIL,
    payload: error,
});

export const addFundTransfer = (data) => ({
    type: ADD_FUND_TRANSFER,
    payload: data,
  });
  
  export const addFundTransferSuccess = (data) => ({
    type: ADD_FUND_TRANSFER_SUCCESS,
    payload: data,
  });
  
  export const addFundTransferFail = (error) => ({
    type: ADD_FUND_TRANSFER_FAIL,
    payload: error,
  });

  export const getAllFundSource = (localbody_id) => ({
    type: GET_FUND_SOURCE,
    localbody_id: localbody_id,
});

export const getAllFundSourceSuccess = (data) => ({
    type: GET_FUND_SOURCE_SUCCESS,
    payload: data,
});

export const getAllFundSourceFail = (error) => ({
    type: GET_FUND_SOURCE_FAIL,
    payload: error,
});
  