import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

import {
  GET_POPUP,
  ADD_POPUP,
  UPDATE_POPUP,
  DELETE_POPUP,
} from "./actionTypes";

import {
  getAllPopupSuccess,
  getAllPopupFail,
  addPopupFail,
  addPopupSuccess,
  updatePopupSuccess,
  updatePopupFail,
  deletePopupSuccess,
  deletePopupFail,
} from "./actions";

//Include Both Helper File with needed methods
import {
  addPopup,
  getAllPopup,
  updatePopup,
  deletePopup,
} from "../../helpers/backend_helper";

function* fetchPopup({localbody_id: localbody_id}) {
  try {
    const response = yield call(getAllPopup,localbody_id);
    yield put(getAllPopupSuccess(response));
  } catch (error) {
    yield put(getAllPopupFail(error));
  }
}

function* onAddPopup({ payload: data }) {
  try {
    const response = yield call(addPopup, data);
    yield put(addPopupSuccess(response));
  } catch (error) {
    yield put(addPopupFail(error.response));
  }
}

function* onUpdatePopup({ payload: data }) {
  try {
    const response = yield call(updatePopup, data);
    yield put(updatePopupSuccess(response));
  } catch (error) {
    yield put(updatePopupFail(error.response));
  }
}

function* ondeletePopup({ payload: popupId, auth_data: auth_user }) {
  try {
    const response = yield call(deletePopup, popupId, auth_user);
    yield put(deletePopupSuccess(response));
  } catch (error) {
    yield put(deletePopupFail(error.response));
  }
}

function* popupSaga() {
  yield takeEvery(GET_POPUP, fetchPopup);
  yield takeEvery(ADD_POPUP, onAddPopup);
  yield takeEvery(UPDATE_POPUP, onUpdatePopup);
  yield takeEvery(DELETE_POPUP, ondeletePopup);
}

export default popupSaga;
