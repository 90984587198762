
import React, { useState, useEffect, useRef } from "react";
import { MDBDataTable } from "mdbreact";
import Select from "react-select";
import { getLocalbody } from "../../helpers/globalFunctions";
import { Modal } from "react-bootstrap";
import accessToken from "../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import SweetAlert from "react-bootstrap-sweetalert";
import $ from "jquery";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";
import broken from "../../assets/complaint_icon/broken.svg";
import damaged from "../../assets/complaint_icon/damaged.svg";
import missing from "../../assets/complaint_icon/missing.svg";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter, useHistory } from "react-router-dom";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import moment from "moment";
import "./complaints.scss";
import { Divider, Grid } from "@mui/material";
import toastr, { error } from "toastr";
import { ErrorSharp } from "@mui/icons-material";
import { result, startsWith, values } from "lodash";



const DatatableTables = () => {

  const [customer, setCustomer] = useState([]);
  const [customerOptions, setCustomerOptions] = useState([]);
  const [selectedSearchCustomer, setSelectedSearchCustomer] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const [cargoAgent, setCargoAgent] = useState([]);
  const [cargoAgentOptions, setCargoAgentOptions] = useState([]);
  const [selectedCargoAgent, setselectedCargoAgent] = useState(null);

  const [orderId, setOrderId] = useState([]);
  const [orderIdOptions, setOrderIdOptions] = useState([]);
  const [selectedSearchOrderId, setSelectedSearchOrderId] = useState(null);
  const [selectedOrderId, setSelectedOrderId] = useState(null);

  const [boxIdOptions, setBoxIdOptions] = useState([])
  const [boxId, setBoxId] = useState([]);
  const [selectedBoxId, setSelectedBoxId] = useState(null);
  const [selectedSearchBoxId, setSelectedSearchBoxId] = useState(null);

  const [complaintCategoryOptions, setComplaintCategoryOptions] = useState([]);
  const [selectedComplaintCategory, setSelectedComplaintCategory] = useState(null);

  const [selected, setSelected] = useState([]);
  const [uploadProgress, setUploadProgress] = useState();

  const [DatatableTab, setDatatableTab] = useState(null);
  const [openModal, setOpenModal] = React.useState(false);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  const [errors, setErrors] = useState({
    boxIdError: "",
    orderIdError: "",
    cargoAgentError: "",
    complaintcategoryError: "",
    customerError: "",
  });

  const [popupError, setPopupError] = useState({
    statusError: "",
  });
  const [selectedPopupComment, setselectedPopupComment] = useState([]);

  const [selectedgro, setselectedgro] = useState([]);
  const [popup, setpopup] = useState([]);
  const [popupData, setPopupData] = useState({});
  const [popupView, setPopupView] = useState(false);

  // const [selectedPopupStatus, setSelectedPopupStatus] = useState(null);
  const [complaintPopupTable, setComplaintPopupTable] = useState([]);
  const [popupStatusOptions, setpopStatusOptions] = useState([]);
  const [complnt, setComplnt] = useState([]);
  const [complaintId, setComplaintId] = useState([]);

  const [followUp, setFollowUp] = useState({});
  const [complaintData, setcomplaintData] = useState([]);
  const [selectedPopupStatus, setSelectedPopupStatus] = useState(null);

  // const [image, setImage] = useState(null);
  const [complaintObject, setcomplaintObject] = useState({});
  const [complaintForTable, setcomplaintForTable] = useState([]);

  const [staffComplaintIdToBeUpdated, setStaffComplaintIdToBeUpdated] = useState(null);
  const [staffComplaintIdToBeDeleted, setStaffComplaintIdToBeDeleted] = useState(null);
  const [confirmDeleteAlert, setConfirmDeleteAlert] = useState(null);

  const [searchObject, setSearchObject] = useState({});
  const [selectedComment, setSelectedComment] = useState(null);
  const [followUpObject, setfollowUpObject] = useState({});
  const [followUpTable, setFollowUpTable] = useState([]);

  useEffect(() => {

    handleTableData();
    handleFollowupTable();
    fetch_all_cargoagent();
    fetch_all_customers();
    fetch_complaint_category();
    fetch_all_order_id();
  }, []);


  function fetch_all_cargoagent() {
    axios
      .get(`${API_URL}cargoUser/agents/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        var agent_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: (el.firstName || '') + (el.lastName || ''),
              value: el._id,
            };
          });

        setCargoAgentOptions([{ options: agent_data }]);

      });
  }


  const fetch_all_customers = () => {
    axios
      .get(`${API_URL}customer/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        let customer_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.name,
              value: el._id,
            };

          });

        setCustomerOptions([
          {
            options: customer_data,
          },
        ])

      });
  };


  const fetch_complaint_category = () => {
    axios
      .get(`${API_URL}complaintCategory/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        var complaint_category_data =
          res.data.data &&
          res.data.data.map((el) => {

            return {
              label: el.category_name,
              value: el._id,
            };
          });

        setComplaintCategoryOptions([
          {
            options: complaint_category_data,
          },
        ]);
      });
  };


  const fetch_all_order_id = () => {
    axios
      .get(`${API_URL}order/options`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        var orderId_data =
          res.data.data &&
          res.data.data.map((el) => {

            return {
              label: el.order_number,
              value: el._id,
            };
          });

        setOrderIdOptions([
          {
            options: orderId_data,
          },
        ]);
      });
  };


  const fetch_all_box_id = (id) => {
    axios
      .get(`${API_URL}order/box_id?order_id=` + id, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {

        var boxId_data =
          res.data.data &&
          res.data.data.map((el) => {

            return {
              label: el.box_number,
              value: el._id
            }
          })
        setBoxIdOptions([{ options: boxId_data }])
      })
  }



  function handleTableData() {
    axios
      .get(`${API_URL}complaint/list`, {
        headers: {
          "x-access-token": accessToken,
        },
      })

      .then((res) => {
        var result = res.data.data;

        let complaintData = [];
        result.map((item, index) => {


          item.date = moment(item.date).format("DD-MM-YYYY");
          item.time = moment(item.time).format("hh:mm a");

          if (typeof item.image != "undefined" && item.image != "") {
            item.image = (
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img
                  src={`${API_URL}uploads/Addcomplaint_image/${item.image}`}
                  style={{ height: "25px", cursor: "pointer" }}
                  onClick={() => window.open(`${API_URL}uploads/Addcomplaint_image/${item.image}`, "_blank")}
                />
              </div>
            );
          }
          else {
            item.image = "";
          }


          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.2em",
                  marginRight: "0.5em",
                }}
                onClick={() => {
                  handleClickOpenModal(item);
                  setOpenModal(true)
                  setPopupView(true)
                }}
              ></i>
              <i
                className="far fa-edit"
                style={{
                  fontSize: "1em",
                  cursor: "pointer",
                  marginLeft: "0.1rem",
                  marginRight: "0.5rem",
                }}
                onClick={() => {
                  toTop();
                  preUpdateStaffComplaintId(item);
                }}
              ></i>
              <i
                className="far fa-trash-alt"
                style={{ fontSize: "1em", cursor: "pointer" }}
                onClick={() => {
                  setStaffComplaintIdToBeDeleted(item._id);
                  setConfirmDeleteAlert(true);
                }}
              ></i>
            </div>
          );

          item.id = index + 1;

          if (item?.updatedBy?.[0]) {
            let values = item?.updatedBy[0] || {};

            if (values?.lastName)

              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName
          } else {
            let values = item?.addedby[0] || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName
          }


          if (item?.[0]) {
            let values = item?.cargoAgent

            if (values?.lastName)

              item.cargoAgent = values.firstName + " " + values.lastName;
            else item.cargoAgent = values?.firstName
          } else {
            let values = item?.cargoAgent
            if (values?.lastName)
              item.cargoAgent = values.firstName + " " + values.lastName;
            else item.cargoAgent = values?.firstName
          }


          if (item?.[0]) {
            let values = item?.addedby

            if (values?.lastName)

              item.addedby = values.firstName + " " + values.lastName;
            else item.addedby = values?.firstName
          } else {
            let values = item?.addedby
            if (values?.lastName)
              item.addedby = values.firstName + " " + values.lastName;
            else item.addedby = values?.firstName
          }

          if (item.status === 0) {
            item.status1 = (
              <Button
                color="danger"
                size="sm"
                type="button"
                className="btn btn-sm"
                fontSize="0.8rem"
              >
                Pending
              </Button>
            )
          }
          else if (item.status === 1) {
            item.status1 = (
              <Button
                color="success"
                size="sm"
                type="button"
                className="btn btn-sm"
                fontSize="0.8rem"
              >
                Solved
              </Button>
            )
          }

          complaintData.push(item);
        });

        setcomplaintForTable(complaintData)
      }
      );
  }


  const toTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };


  const handleCloseModal = () => {
    setOpenModal(false);
  };


  let preUpdateStaffComplaintId = (item) => {
    setStaffComplaintIdToBeUpdated(item?._id)

    axios
      .get(`${API_URL}complaint/singlelist?complaint_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        let result = res.data.data;

        result &&
          result.map((item, index) => {

            let complaintCategory1 = {
              label: item?.complaintCategory?.category_name,
              value: item?.complaintCategory?._id,
            };
            setSelectedComplaintCategory(complaintCategory1)

            let customer1 = {
              label: item?.customer?.name,
              value: item?.customer?._id,
            };
            setSelectedCustomer(customer1)

            let cargoAgent1 = {
              label: (item?.cargoAgent?.firstName || '') + (item?.cargoAgent?.lastName || ''),
              value: item?.cargoAgent?._id,
            };
            setselectedCargoAgent(cargoAgent1)

            let orderId1 = {
              label: item?.orderId?.order_number,
              value: item?.orderId?._id,
            };
            setSelectedOrderId(orderId1);

            let boxId1 = {
              label: item?.boxId?.box_number,
              value: item?.boxId?._id,
            };
            setSelectedBoxId(boxId1);

            setcomplaintObject(item);

          })
      })

  };


  const handleClickOpenModal = (item) => {

    const ComplaintId = item._id;
    setComplnt(ComplaintId);

    axios
      .get(`${API_URL}complaint/singlelist?complaint_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        var result = res.data.data;

        result &&
          result.map((item, index) => {

            item.date = moment(item.date).format("DD-MM-YYYY");
            item.time = moment(item.time).format("hh:mm a");

            item?.addedby &&
              item?.addedby?.map((el, indx) => {

                item.addedby1 = el?.firstName + " " + el?.lastName;
              })


            setPopupData(item);
          })
      })

  };


  const reset = () => {
    formRef.current.reset();
    setSelectedBoxId("");
    handleTableData();
    setcomplaintObject({});
    setSelectedCustomer("");
    setselectedCargoAgent("");
    setSelectedOrderId("");
    setSelectedComplaintCategory("");
    setSelectedComment("");
    setErrors({ customerError: "", boxIdError: "", orderIdError: "", cargoAgentError: "", complaintcategoryError: '' })
    setStaffComplaintIdToBeUpdated(null);
  }


  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "orderId":
        setSelectedOrderId(selected);
        fetch_all_box_id(selected.value);
        setcomplaintObject({
          ...complaintObject,
          orderId: selected.value,
        });
        setErrors({
          ...errors,
          orderIdError: "",
        })
        break;

      case "boxId":
        setSelectedBoxId(selected);
        setcomplaintObject({
          ...complaintObject,
          boxId: selected.value,
        });
        setErrors({
          ...errors,
          boxIdError: "",
        })
        break;

      case "complaintCategory":
        setSelectedComplaintCategory(selected);
        setcomplaintObject({
          ...complaintObject,
          complaintCategory: selected.value,
        });
        setErrors({
          ...errors,
          complaintcategoryError: ""
        });
        break;

      case "cargoAgent":
        setselectedCargoAgent(selected);
        setcomplaintObject({
          ...complaintObject,
          cargoAgent: selected.value,
        });
        setErrors({
          ...errors,
          cargoAgentError: "",
        })
        break;

      case "customer":
        setSelectedCustomer(selected);
        setcomplaintObject({
          ...complaintObject,
          customer: selected.value,
        });
        setErrors({
          ...errors,
          customerError: "",
        })
        break;

      default:
        break;
    }
  };


  function handleChangeInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    setcomplaintObject({
      ...complaintObject,
      [name]: value,
    });

  }


  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("Addcomplaint_image", e.target.files[0]);
    axios
      .post(`${API_URL}complaint/complaintimage/`, fd, {
        headers: {
          "x-access-token": accessToken,
        },
        onUploadProgress: (data) => {
          setUploadProgress(Math.round((100 * data.loaded) / data.total));
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setcomplaintObject({
            ...complaintObject,
            [`image`]: response.data.file.filename,
          });
        } else {
          toastr.error(response.data.message);
        }
      });
  };


  const deleteComplaintImage = () => {
    setcomplaintObject({
      ...complaintObject,
      [`image`]: "",
    });
  };


  const handleValidSubmit = (item) => {
    let data = {
      _id: complaintObject?._id,
      cargoAgent: complaintObject?.cargoAgent,
      comments: complaintObject?.comments,
      complaintCategory: complaintObject?.complaintCategory,
      customer: complaintObject?.customer,
      image: complaintObject?.image,
      boxId: complaintObject?.boxId,
      orderId: complaintObject?.orderId,
      complaint_id: complaintObject?.complaint_id,
    }

    if (selectedCustomer === null || selectedOrderId === null || selectedBoxId === null ||
      selectedCargoAgent === null || selectedComplaintCategory === null) {
      let errorVal = errors;
      if (selectedCustomer === null)
        errorVal.customerError = "Please select customer"
      if (selectedOrderId === null)
        errorVal.orderIdError = "Please select orderId"
      if (selectedBoxId === null)
        errorVal.boxIdError = "Please select boxId"
      if (selectedCargoAgent === null)
        errorVal.cargoAgentError = "Please select cargoAgent"
      if (selectedComplaintCategory === null)
        errorVal.complaintcategoryError = "Please select category"
      setErrors({ ...errorVal })
    }
    else {
      if (staffComplaintIdToBeUpdated) {
        axios
          .put(`${API_URL}complaint/update/`, data, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {

            if (res.data.success === true) {
              toastr.success("Complaint updated successfully");
              formRef.current.reset();
              setSelectedCustomer(null);
              setselectedCargoAgent(null);
              setSelectedOrderId(null);
              setSelectedBoxId(null);
              setSelectedComplaintCategory(null);
              setStaffComplaintIdToBeUpdated(null);
              handleTableData();
              setcomplaintObject({});
              setErrors({
                ...errors,
                customerError: "",
                cargoAgentError: "",
                orderIdError: "",
                boxIdError: "",
                complaintcategoryError: ""
              });
            } else {
              toastr.error("Failed to update Complaint");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }
      else {
        axios
          .post(`${API_URL}complaint/`, complaintObject, {
            headers: {
              "x-access-token": accessToken,
            },
          })
          .then((res) => {


            if (res.data.success === true) {
              toastr.success("Complaint added sucessfully");
              formRef.current.reset();
              setSelectedCustomer(null);
              setselectedCargoAgent(null);
              setSelectedOrderId(null);
              setSelectedBoxId(null);
              setSelectedComplaintCategory(null);
              handleTableData();
              setcomplaintObject({});
              setErrors({ customerError: "", boxIdError: "", orderIdError: "", cargoAgentError: "", complaintcategoryError: '' })
            }
            else {
              toastr.error("Failed to add Complaint");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      }

    }
    // }

  };



  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Date",
      //   field: "date",
      //   sort: "asc",
      //   width: 150,
      // },
      {
        label: "Customer",
        field: "customer",
        sort: "asc",
        width: 150,
      },
      {
        label: "Order ID",
        field: "orderId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Box ID",
        field: "boxId",
        sort: "asc",
        width: 150,
      },
      {
        label: "Cargo Agent",
        field: "cargoAgent",
        sort: "asc",
        width: "150",
      },
      {
        label: "Category",
        field: "complaintCategory",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 150
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 200,
      },
    ],
    rows: complaintForTable,
  };


  function handleFollowupTable(complaint_id) {

    let data = {
      complaint_id: complnt
    }

    axios
      .get(`${API_URL}complaint/updatedcomplaint?complaint_id=` + complaint_id, {
        headers: {
          "x-access-token": accessToken,
        },
      }).then((res) => {

        let Data = [];
        let result = res.data.data;

        result &&
          result?.map((item, index) => {

            item.id = index + 1;
            item.date1 = moment(item.date).format("DD-MM-YYYY");
            // item.time = moment(item.date).format("hh:mm:ss a");
            item.staff = item.addedby.firstName + " " + item.addedby.lastName;

            if (item?.status === 0) {
              item.status1 = (
                <Button color="danger"
                  size="sm"
                  type="button"
                  className="btn btn-sm"
                  fontSize="0.8rem"
                >
                  Pending
                </Button>
              )
            }

            if (item?.status === 1) {
              item.status1 = (
                <Button color="success"
                  size="sm"
                  type="button"
                  className="btn btn-sm"
                  fontSize="0.8rem"
                >
                  Solved
                </Button>
              )
            }

            // Filter data for "submitted" status (assuming status value for "submitted" is 1)
            if (item?.status === 1) {
              Data.push(item);
            } else if (item?.status === 0) {
              Data.push(item);
            }
          })

        setFollowUpTable(Data);
      })
  }


  const complaintTableData = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "date1",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Time",
      //   field: "time",
      //   sort: "asc",
      //   width: 270,
      // },
      {
        label: "Comments",
        field: "comments",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status1",
        sort: "asc",
        width: 150,
      },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150,
      },
    ],
    rows: followUpTable,
  };


  function handleStatusChange(selected, name) {
    switch (name) {
      case "followup_status":
        setSelectedPopupStatus(selected);
        setfollowUpObject({
          ...followUpObject,
          followup_status: selected.value,
        });
        break;

      default:
        break;
    }
  }


  const handleSubmitPopup = () => {

    let data1 = {
      _id: complnt,
      status: followUpObject?.followup_status,
      comments: followUpObject?.followup_comment
    }

    axios
      .put(`${API_URL}complaint/viewAndUpdate`, data1, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {

        if (res.data.success === true) {
          toastr.success("Follow up submitted successfully");
          const complaint_id = res.data.data._id;
          handleCloseModal();
          setfollowUpObject({
            ...followUpObject,
            followup_comment: ""
          });
          setSelectedPopupStatus(null);
          handleFollowupTable(complaint_id);
          setPopupError({
            ...popupError,
            statusError: ""
          });
        } else {
          toastr.error("Failed to Submit Follow up");
          return;
        }
      })
      .catch((err) => {
        toastr.error(err.response.data.message);
        return;
      });
  };


  const handlePopupInputChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setfollowUpObject({ ...followUpObject, [name]: value });
  };


  return (
    <React.Fragment>
      {confirmDeleteAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}complaint` + "/" + staffComplaintIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                },
              })
              .then((res) => {
                if (res.data.success === true) {
                  toastr.success("Complaint deleted successfully");

                  // if (complaintObject && complaintObject?._id === staffComplaintIdToBeDeleted) {
                  formRef.current.reset();
                  setStaffComplaintIdToBeUpdated(null);
                  setcomplaintObject({});
                  setSelectedCustomer(null);
                  setSelectedComplaintCategory(null);
                  setselectedCargoAgent(null);
                  setSelectedOrderId(null);
                  setSelectedBoxId(null);
                  setErrors({ customerError: "", boxIdError: "", orderIdError: "", cargoAgentError: "", complaintcategoryError: '' })
                  // }
                  handleTableData();
                } else {
                  toastr.error("Failed to delete Complaint");
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setConfirmDeleteAlert(false);
          }}
          onCancel={() => setConfirmDeleteAlert(false)}
        >
          Are you sure you want to delete it?
        </SweetAlert>
      ) : null}

      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumbs title="Home" breadcrumbItem="Complaint" />
          <Row>
            <Col xl="12">
              <AvForm
                className="needs-validation"
                ref={formRef}
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <Row>
                  <Col md="3">
                    <div className="mb-3" >
                      <Label> Customer</Label>
                      <Select
                        name="customer"
                        placeholder='Select'
                        value={selectedCustomer}
                        options={customerOptions}
                        onChange={(selected) => {
                          handleSelectChange(selected, "customer");
                        }}
                        classNamePrefix="select2-selection"
                      />
                      <p className='text-danger'
                        style={{ fontSize: "11px" }}>
                        {selectedCustomer === null ? errors.customerError : ""}
                      </p>
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3" >
                      <Label>Order Id</Label>
                      <Select
                        name='orderId'
                        placeholder='Select'
                        value={selectedOrderId}
                        validate={{ required: { value: true } }}
                        onChange={(selected) => {
                          handleSelectChange(selected, 'orderId')
                        }}
                        options={orderIdOptions}
                        classNamePrefix="select2-selection"
                      />
                      <p className='text-danger'
                        style={{ fontSize: "11px" }}>
                        {selectedOrderId === null ? errors.orderIdError : ""}
                      </p>
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3" >
                      <Label>Box Id</Label>
                      <Select
                        name='boxId'
                        placeholder='Select'
                        value={selectedBoxId}
                        validate={{ required: { value: true } }}
                        onChange={(selected) => {
                          handleSelectChange(selected, 'boxId')
                        }}
                        options={boxIdOptions}
                        classNamePrefix="select2-selection"
                      />
                      <p className='text-danger'
                        style={{ fontSize: "11px" }}>
                        {selectedBoxId === null ? errors.boxIdError : ""}
                      </p>
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3" >
                      <Label>Complaint Category</Label>
                      <Select
                        name='complaintCategory'
                        placeholder='Select'
                        value={selectedComplaintCategory}
                        validate={{ required: { value: true } }}
                        onChange={(selected) => {
                          handleSelectChange(selected, 'complaintCategory')
                        }}
                        options={complaintCategoryOptions}
                        classNamePrefix="select2-selection"
                      />
                      <p className='text-danger'
                        style={{ fontSize: "11px" }}>
                        {selectedComplaintCategory === null ? errors.complaintcategoryError : ""}
                      </p>
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3" >
                      <Label>Cargo Agent</Label>
                      <Select
                        name='cargoAgent'
                        placeholder='Select'
                        value={selectedCargoAgent}
                        options={cargoAgentOptions}
                        validate={{ required: { value: true } }}
                        onChange={(selected) => {
                          handleSelectChange(selected, 'cargoAgent')
                        }}
                        classNamePrefix="select2-selection"
                      />
                      <p className='text-danger'
                        style={{ fontSize: "11px" }}>
                        {selectedCargoAgent === null ? errors.cargoAgentError : ""}
                      </p>
                    </div>
                  </Col>

                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Comments</Label>
                      <AvField
                        name="comments"
                        placeholder="Comments"
                        type="textarea"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        onChange={handleChangeInput}
                        value={complaintObject?.comments}
                      />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-0">
                      <Label htmlFor="validationCustom03">
                        Image
                      </Label>
                      {complaintObject?.image ? (
                        <div div className="img-wraps">

                          <img
                            alt=""
                            width="150"
                            height="150"
                            src={`${API_URL}/uploads/Addcomplaint_image/${complaintObject?.image}`}
                          />
                          <button
                            className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                            onClick={deleteComplaintImage}
                            style={{ width: "150px" }}
                          >
                            Delete
                          </button>
                        </div>
                      ) : (
                        <AvField
                          name="image"
                          type="file"
                          className="form-control"
                          id="validationCustom03"
                          onChange={uploadImage}
                          rows="1"
                        />
                      )}
                    </div>
                  </Col>

                  <Col md="3" style={{ paddingTop: "4px" }}>
                    <div className='mt-4'>
                      {staffComplaintIdToBeUpdated ? (
                        <Button className='mx-2'
                          color='primary'
                          type='update'
                        >
                          Update
                        </Button>
                      )
                        :
                        <Button className='mx-2'
                          color='primary'
                          type='submit'
                        >
                          Submit
                        </Button>
                      }
                      <Button
                        color='danger'
                        type='reset'
                        onClick={() => reset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </Col>
          </Row>

          <Row>
            <Col className='col-12'>
              <MDBDataTable
                responsive
                id="compnttbleId"
                bordered
                data={data}
                searching={true}
                info={true}
                disableRetreatAfterSorting={true}
                entries={10} />
            </Col>
          </Row>
        </div>

        <div>
          <Modal
            show={popupView}
            isOpen={popupView}
            toggle={() => setPopupView(!popupView)}
            size="xl"
            centered={true}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" style={{ fontSize: "21px" }}>Complaint Details</h5>
              <button
                type="button"
                onClick={() => {
                  setPopupView(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>

            <AvForm className="needs-validation">
              <div className="modal-body">
                <Row>
                  <Col md="12">
                    <Card>
                      {/* <CardBody> */}
                      <table
                        id="product_available_day"
                      // className="table table-bordered dataTable"
                      >
                        <tr>
                          <td >Date</td><td style={{ padding: "10px", textAlign: "left" }}>: {popupData?.date} </td>
                          <td >Time</td><td style={{ padding: "10px", textAlign: "left" }}>: {popupData?.time} </td>
                          <td >Customer</td><td style={{ padding: "10px", textAlign: "left" }}>: {popupData?.customer?.name} </td>
                        </tr>

                        <tr>
                          <td >Order ID</td><td>: {popupData?.orderId?.order_number}</td>
                          <td >Box ID</td><td >: {popupData?.boxId?.box_number}</td>
                          <td >Comments</td><td >: {popupData?.comments}</td>
                        </tr>

                        <tr>
                          <td >Category</td><td>: {popupData?.complaintCategory?.category_name}</td>
                          <td >Agent</td><td >: {popupData?.cargoAgent?.firstName} {popupData?.cargoAgent?.lastName}</td>
                          <td >Added By</td><td >: {popupData?.addedby1}</td>
                        </tr>

                        <tr>
                          <td >Image</td>
                          <td style={{ textAlign: "left" }}>:
                            {
                              (popupData?.image) ? (
                                <img
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    paddingLeft: "0px",
                                    cursor: "pointer"
                                  }}
                                  src={`${API_URL}uploads/Addcomplaint_image/${popupData?.image}`}></img>
                              ) : ""
                            }
                          </td>
                        </tr>

                      </table>
                      {/* </CardBody> */}
                    </Card>
                  </Col>
                </Row>
              </div>
            </AvForm>

            <div className="modal-header">
              <h5 className="modal-title mt-0" style={{ fontSize: "21px" }}>Complaint Update</h5>
            </div>

            <Row style={{ paddingLeft: "15px" }}>

              <Col className="col-12">
                <AvForm
                  className="needs-validation"
                  onValidSubmit={(e, v) => {
                    handleSubmitPopup(e, v);
                  }}
                >
                  <Card>
                    {/* <CardBody> */}
                    <Row style={{ paddingLeft: "15px" }}>

                      <Col md="2">
                        <div className="mb-3">
                          <Label>Status</Label>
                          <Select
                            name="followup_status"
                            value={selectedPopupStatus}
                            onChange={(selected) => {
                              handleStatusChange(selected, "followup_status");
                            }}
                            options={[
                              { label: "Pending", value: "0" }
                            ]}
                            classNamePrefix="select2-selection"
                            validate={{ required: { value: true } }}
                          ></Select>
                          <p
                            className="text-danger"
                            style={{ fontSize: "11px" }}
                          >
                            {selectedPopupStatus === null ? popupError.statusError : ""}
                          </p>
                        </div>
                      </Col>

                      <Col md="5">
                        <div className="mb-3">
                          <Label htmlFor="validationCustom02">Comments</Label>
                          <AvField
                            name="followup_comment"
                            placeholder="Comments"
                            type="textarea"
                            className="form-control"
                            id="validationCustom02"
                            onChange={handlePopupInputChange}
                            value={followUpObject?.followup_comment}
                          />
                        </div>
                      </Col>

                      <Col md="3" style={{ paddingTop: "4px" }}>
                        <Button style={{ marginTop: "40px" }}
                          color='primary'
                          type='submit'
                        >
                          Submit
                        </Button>

                      </Col>

                    </Row>
                    {/* </CardBody> */}
                  </Card>
                </AvForm>

                <Row
                  style={{ paddingLeft: "15px", paddingRight: "15px" }}
                  className="mt-2"
                >

                  <MDBDataTable
                    id="complaintPopupTableDataId"
                    responsive
                    bordered
                    data={complaintTableData}
                    paging={false}
                    searching={false}
                    entries={20}
                  />

                </Row>

              </Col>

            </Row>

          </Modal>

        </div>

      </div>

    </React.Fragment>

  )
}

export default DatatableTables;