import React from "react";
import { MDBDataTable } from "mdbreact";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Save from "@mui/icons-material/Save";
import Refresh from "@mui/icons-material/ResetTvRounded";
import InputLabel from "@mui/material/InputLabel";
import Box from "@material-ui/core/Box";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";

import {
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  Button,
  Label,
  CardHeader,
} from "reactstrap";

import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Breadcrumb
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import "./settings.scss";

const DatatableTables = () => {
  const [member, setMember] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleChange = (event) => {
    setMember(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const [language, setLanguage] = React.useState("");
  const [open1, setOpen1] = React.useState(false);
  const handleChange1 = (event) => {
    setLanguage(event.target.value);
  };

  const handleClose1 = () => {
    setOpen1(false);
  };

  const handleOpen1 = () => {
    setOpen1(true);
  };

  const [account, setAccount] = React.useState("");
  const [open2, setOpen2] = React.useState(false);
  const handleChange2 = (event) => {
    setAccount(event.target.value);
  };

  const handleClose2 = () => {
    setOpen2(false);
  };

  const handleOpen2 = () => {
    setOpen2(true);
  };

  const [date, setDate] = React.useState("");
  const [open3, setOpen3] = React.useState(false);
  const handleChange3 = (event) => {
    setDate(event.target.value);
  };

  const handleClose3 = () => {
    setOpen3(false);
  };

  const handleOpen3 = () => {
    setOpen3(true);
  };

  const [month, setMonth] = React.useState("");
  const [open4, setOpen4] = React.useState(false);

  const handleChange4 = (event) => {
    setMonth(event.target.value);
  };

  const handleClose4 = () => {
    setOpen4(false);
  };

  const handleOpen4 = () => {
    setOpen4(true);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Settings" />
          <Row>
            <Col xl="12">
              <Card>
                <CardBody>
                  <AvForm className="needs-validation">
                    {/*First row*/}
                    <Row>
                      <Col md="1">
                        <Label htmlFor="validationCustom05">Basic:</Label>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Select
                            name="weight_id"
                            label="members"
                            // value={}
                            // onChange={(selected) => {
                            //   handleSelectChange(selected);
                            // }}
                            options={[]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3" className="txt-fields">
                        <Label
                          htmlFor="validationCustom05"
                          style={{ fontWeight: "bold" }}
                        >
                          Customer:
                        </Label>
                      </Col>
                      <Col md="3">
                        <Label htmlFor="validationCustom05">Customer</Label>
                      </Col>
                    </Row>

                    {/*Second row*/}
                    <Row>
                      <Col md="1"></Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Select
                            name="weight_id"
                            label="members"
                            // value={}
                            // onChange={(selected) => {
                            //   handleSelectChange(selected);
                            // }}
                            options={[]}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                      </Col>
                      <Col md="3" className="txt-fields">
                        <Label
                          htmlFor="validationCustom05"
                          style={{ fontWeight: "bold" }}
                        >
                          Language:
                        </Label>
                      </Col>
                      <Col md="3">
                        <Label htmlFor="validationCustom05">English</Label>
                      </Col>
                    </Row>
                      {/*Third row*/}
                      <Row>
                        <Col md="1">
                          <Label htmlFor="validationCustom05">
                            Accountings:
                          </Label>
                        </Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Select
                              name="weight_id"
                              label="members"
                              // value={}
                              // onChange={(selected) => {
                              //   handleSelectChange(selected);
                              // }}
                              options={[]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3" className="txt-fields">
                          <Label
                            htmlFor="validationCustom05"
                            style={{ fontWeight: "bold" }}
                          >
                            Currency:
                          </Label>
                        </Col>
                        <Col md="3">
                          <Label htmlFor="validationCustom05">
                            INR - Rupee
                          </Label>
                        </Col>
                      </Row>

                      {/*Fourth row*/}
                      <Row>
                        <Col md="1"></Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Select
                              name="weight_id"
                              label="members"
                              // value={}
                              // onChange={(selected) => {
                              //   handleSelectChange(selected);
                              // }}
                              options={[]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3" className="txt-fields">
                          <Label
                            htmlFor="validationCustom05"
                            style={{ fontWeight: "bold" }}
                          >
                            Date Format:
                          </Label>
                        </Col>
                        <Col md="3">
                          <Label htmlFor="validationCustom05">DD-MM-YY</Label>
                        </Col>
                      </Row>

                      {/*Fifth row*/}
                      <Row>
                        <Col md="1"></Col>
                        <Col md="3">
                          <div className="mb-3">
                            <Select
                              name="weight_id"
                              label="members"
                              // value={}
                              // onChange={(selected) => {
                              //   handleSelectChange(selected);
                              // }}
                              options={[]}
                              classNamePrefix="select2-selection"
                            />
                          </div>
                        </Col>
                        <Col md="3" className="txt-fields">
                          <Label
                            htmlFor="validationCustom05"
                            style={{ fontWeight: "bold" }}
                          >
                            Account Month:
                          </Label>
                        </Col>
                        <Col md="3">
                          <Label htmlFor="validationCustom05">November</Label>
                        </Col>
                      </Row>

                      <Row>
                        <Col
                          md="3"
                          className="mt-4"
                          style={{ paddingTop: "4px" }}
                        >
                          <div className="mb-2" >
                            <Button
                              color="primary"
                              type="submit"
                              style={{ marginRight: "5%" }}
                            >
                              Submit
                            </Button>
                            <Button color="danger" type="submit">
                              Reset
                            </Button>
                          </div>
                        </Col>
                      </Row>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DatatableTables;
