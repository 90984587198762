export const GET_PACKAGES = "GET_PACKAGES";
export const GET_PACKAGES_SUCCESS = "GET_PACKAGES_SUCCESS";
export const GET_PACKAGES_FAIL = "GET_PACKAGES_FAIL";

export const ADD_PACKAGE = "ADD_PACKAGE";
export const ADD_PACKAGE_SUCCESS = "ADD_PACKAGE_SUCCESS";
export const ADD_PACKAGE_FAIL = "ADD_PACKAGE_FAIL";

export const UPDATE_PACKAGE = "UPDATE_PACKAGE";
export const UPDATE_PACKAGE_SUCCESS = "UPDATE_PACKAGE_SUCCESS";
export const UPDATE_PACKAGE_FAIL = "UPDATE_PACKAGE_FAIL";

export const DELETE_PACKAGE = "DELETE_PACKAGE";
export const DELETE_PACKAGE_SUCCESS = "DELETE_PACKAGE_SUCCESS";
export const DELETE_PACKAGE_FAIL = "DELETE_PACKAGE_FAIL";

export const UPDATE_PACKAGE_STATUS = "UPDATE_PACKAGE_STATUS";
export const UPDATE_PACKAGE_STATUS_SUCCESS = "UPDATE_PACKAGE_STATUS_SUCCESS";
export const UPDATE_PACKAGE_STATUS_FAIL = "UPDATE_PACKAGE_STATUS_FAIL";