import {
  GET_POPUP,
  GET_POPUP_SUCCESS,
  GET_POPUP_FAIL,
  ADD_POPUP,
  ADD_POPUP_SUCCESS,
  ADD_POPUP_FAIL,
  UPDATE_POPUP,
  UPDATE_POPUP_SUCCESS,
  UPDATE_POPUP_FAIL,
  DELETE_POPUP,
  DELETE_POPUP_SUCCESS,
  DELETE_POPUP_FAIL,
} from "./actionTypes";

export const getAllPopup = (localbody_id) => ({
    type: GET_POPUP,
    localbody_id:localbody_id
  });
  
  export const getAllPopupSuccess = (data) => ({
    type: GET_POPUP_SUCCESS,
    payload: data,
  });
  
  export const getAllPopupFail = (error) => ({
    type: GET_POPUP_FAIL,
    payload: error,
  });
  
  export const addPopup = (data) => ({
    type: ADD_POPUP,
    payload: data,
  });
  
  export const addPopupSuccess = (data) => ({
    type: ADD_POPUP_SUCCESS,
    payload: data,
  });
  
  export const addPopupFail = (error) => ({
    type: ADD_POPUP_FAIL,
    payload: error,
  });
  
  export const updatePopup = (data) => ({
    type: UPDATE_POPUP,
    payload: data,
  });
  
  export const updatePopupSuccess = (data) => ({
    type: UPDATE_POPUP_SUCCESS,
    payload: data,
  });
  
  export const updatePopupFail = (error) => ({
    type: UPDATE_POPUP_FAIL,
    payload: error,
  });
  
  export const deletePopup = (data, auth_user) => ({
    type: DELETE_POPUP,
    payload: data,
    auth_data: auth_user,
  });
  
  export const deletePopupSuccess = (data) => ({
    type:DELETE_POPUP_SUCCESS,
    payload: data,
  });
  
  export const deletePopupFail = (error) => ({
    type: DELETE_POPUP_FAIL,
    payload: error,
  });
  
  
  