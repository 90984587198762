
import React, { useState, useEffect, useRef } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Row, Col, Card, CardBody, Label, Button } from "reactstrap";
import $ from "jquery";
import moment from "moment";
import Select from "react-select";
import { CSVLink } from "react-csv";
import {
  getFirstday,
  getDate

} from "../../../helpers/globalFunctions";
import { useDispatch, useSelector } from "react-redux";
import { MDBDataTable } from "mdbreact";
import accessToken from "../../../helpers/jwt-token-access/accessToken";
import axios from "axios";
import { AvField, AvForm } from "availity-reactstrap-validation";

import "./branchwise.scss";
import { result } from "lodash";


const BranchReport = (props) => {
  const [searchData, setSearchData] = useState({
    from_date: getFirstday(new Date()),
    to_date: getDate(new Date()),
  });

  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branch, setBranch] = useState([]);

  const [country, setCountry] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";
  const formRef = useRef();

  const [dataToBeExported, setDataToBeExported] = useState([]);
  const [total, setTotal] = useState(0);
  const [details, setDetails] = useState([]);

  const [currentLocalbody, setCurrectLocalbody] = useState();

  const [fetchallbranch, setfetchallbranch] = useState([]);
  const [fetchallcountry, setfetchallcountry] = useState([]);
  const [popupData, setPopupData] = useState({});

  const [errors, seterrors] = useState({
    country: "",
    branch: "",
  })

  const [branchObject, setBranchObject] = useState({});
  const [branchTable, setbranchTable] = useState([]);
  const [branchData, setbranchData] = useState(null);

  const [item, setItem] = useState(null);
  const [order, setOrder] = useState([]);
  const [val, setval] = useState({});
  const [arr, setarr] = useState();



  useEffect(() => {
    handleTableData();
    fetch_all_branch();
    fetch_all_country();

  }, []);




  const fetch_all_branch = () => {
    axios
      .get(`${API_URL}reports/branch_wise`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log("res", branch);

        let branch_data =
          res.data.data &&
          res.data.data.map((el) => {
            return {
              label: el.branch_name,
              value: el._id,
            };

          });

        setBranchOptions([
          {
            options: branch_data,
          },
        ])

      });

  }



  const fetch_all_country = () => {
    axios
      .get(`${API_URL}newStates/countries`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        console.log(res);

        let country_data =
          res.data.data &&
          res.data.data.map((country) => {
            return {
              label: country.name,
              value: country.id,
            };

          });

        setCountryOptions([
          {
            options: country_data,
          },
        ])

      });

  }



  const handleSearchSelectChange = (selected, name) => {
    switch (name) {
      case "country":
        setSelectedCountry(selected);
        setBranchObject({
          ...branchObject,
          country: selected.value,
        });
        break;

      case "branch":
        setSelectedBranch(selected);
        setBranchObject({
          ...branchObject,
          branch: selected.value,
        });
        break;


      default:
        break;
    }

  }




  function handleTableData(
    from_date = "",
    to_date = "",
    country_id = "",
    branch_id = "",
    country_from = "",
    country_to = ""
  ) {

    axios
      .get(
        `${API_URL}reports/branch_wise`,
        {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          var result = res.data.data;
          console.log("res.data.data", result);
          let branchData = [];

          result.map((item,index) => {
            let val = {};
            val.rslt = item?.rslt;
  
            item.data &&
            item.data.map((el,index) => {

              if (index == 0) {
                val.d1 = el;
              }
  
              if (index == 1) {
                val.d2 = el;
              }
  
              if (index == 2) {
                val.d3 = el;
              }
  
              if (index == 0) {
                val.d4 = el;
              }
  
            })

            setOrder(result);

          })

        })

        var url =
        `${API_URL}reports/branch_wise?from_date=${from_date}&to_date=${to_date}&branch=${branch}&country_from=${country_from}&country_to=${country_to}`
  
        axios
        .get(url, {
          headers: {
            "x-access-token": accessToken,
          },
        })
        .then((res) => {
          let result = res.data.data;

          let dataToBeExported = [];
          result &&
          result.map((item,index) => {
            let exportItem = {};
            item.id = index+1;

            item.date = moment(item?._id).format("DD-MM-YYYY");

            //export data
            exportItem.id = item.id;
            exportItem.Branch = (item.branch_name);
            // exportItem.Air = item.air;
            exportItem.Air_Orders = item.data[0].total_order;
            exportItem.Air_Amount = item.data[0].total_amount;
            // exportItem.Sea = item.sea;
            exportItem.Sea_Orders = item.data[1].total_order;
            exportItem.Sea_Amount = item.data[1].total_amount;
            // exportItem.Road = item.road;
            exportItem.Rd_Orders = item.data[3].total_order;
            exportItem.Rd_Amount = item.data[3].total_amount;

            dataToBeExported.push(exportItem);
          });

          setDataToBeExported([
            ...dataToBeExported,
            {
              Branch:"Total",
              Air_Orders:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[0].total_order;
                return acc;
              }, 0),

            Air_Amount:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[0].total_amount;
                return acc;
              }, 0),

              Sea_Orders:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[1].total_order;
                return acc;
              }, 0),

            Sea_Amount:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[1].total_amount;
                return acc;
              }, 0),

              Rd_Orders:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[3].total_order;
                return acc;
              }, 0),

           Rd_Amount:
              result &&
              result.reduce((acc, curr) => {
                acc = acc + curr.data[3].total_amount;
                return acc;
              }, 0),
            }
          ]);
  
        });
  }

  console.log("ordr",order);


  let handleDate = (e) => {
    var date1 = $("#from_date").val();
    var date2 = $("#to_date").val();

    //date validation
    let country_id = searchData?.country_id ? searchData.country_id : "";
    let toDate = moment(date1);
    let fromDate = moment(date2);
    let result = fromDate.diff(toDate, "days");

    if (result + 1 <= 0) {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
        ["to_date"]: "",
      });
      date2 = "";
    } else {
      setSearchData({
        ...searchData,
        [e.target.name]: e.target.value,
      });
    }
    handleTableData(date1, date2, country_id);

  };




  const handleSelectChange = (selected) => {
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";

    setSelectedCountry(selected);
    setSearchData({
      ...searchData,
      ["country_id"]: selected.value,
    });
    handleTableData(date1, date2, selected.value);
  };




  const reset = () => {
    setSearchData({
      from_date: getFirstday(new Date()),
      to_date: getDate(new Date()),
    });
    setSelectedCountry(null);
    setSelectedBranch(null);
    handleTableData();
  };



  const handleSearch = () => {
    let country_id = searchData?.country_id ? searchData.country_id : "";
    let branch_id = searchData?.branch_id ? searchData.branch_id : "";
    let branch_name = searchData?.branch_name ? searchData.branch_name : "";
    let date1 = searchData?.from_date ? searchData.from_date : "";
    let date2 = searchData?.to_date ? searchData.to_date : "";
    handleTableData(date1, date2, country_id, branch_id, branch_name);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Home" breadcrumbItem="Branch wise Report" />
          <Row>
            <Col md="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">From</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="from_date"
                          name="from_date"
                          onChange={handleDate}
                          value={searchData?.from_date}
                        />
                      </div>
                    </Col>


                    <Col md="2">
                      <div className="mb-3">
                        <Label htmlFor="validationCustom05">To</Label>
                        <input
                          className="form-control"
                          type="date"
                          id="to_date"
                          name="to_date"
                          onChange={handleDate}
                          value={searchData?.to_date}
                          min={searchData?.from_date}
                        />
                      </div>
                    </Col>


                    <Col md="2">
                      <div className="mb-3">
                        <Label>Branch</Label>
                        <Select
                          name="branch_id"
                          value={selectedBranch}
                          options={branchOptions}
                          classNamePrefix="select2-selection"
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "branch");
                          }}
                        />
                      </div>
                    </Col>


                    <Col md="2">
                      <div className="mb-3">
                        <Label>Country</Label>
                        <Select
                          name="country_id"
                          value={selectedCountry}
                          classNamePrefix="select2-selection"
                          onChange={(selected) => {
                            handleSearchSelectChange(selected, "country");
                          }}
                          options={countryOptions}
                        />
                        <p className='text-danger'
                          style={{ fontSize: "11px" }}>
                          {selectedCountry === null ? errors.country : ""}
                        </p>
                      </div>
                    </Col>

                    <Col md="1" className="mt-4" style={{ paddingTop: "5px" }}>
                      <Button
                        color="primary"
                        type="submit"
                        onClick={() => handleSearch()}
                      >
                        Search
                      </Button>
                    </Col>

                    <Col md="1" className="mt-4" style={{ paddingTop: "5px" }}>
                      <Button color="success" type="submit">
                        <CSVLink
                          data={dataToBeExported}
                          filename={
                            "Branch_wise_shipment_report_" + getDate(new Date()) + ".xls"
                          }
                          style={{ color: "white" }}
                        >
                          Export
                        </CSVLink>
                      </Button>
                    </Col>

                    <Col md="1" className="mt-4" style={{ paddingTop: "5px" }}>
                      <Button color="danger" type="reset" onClick={() => reset()}>
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col className='col-12'>
              <Card>
                <CardBody>

                  <table className="table table-bordered" id="branchReport">
                    <thead>
                      <tr>
                        <th colspan="1"></th>
                        <th colspan="1" style={{ width: "300px" }}></th>
                        <th colspan="2" style={{ textAlign: "center" }}>Air</th>
                        <th colspan="2" style={{ textAlign: "center" }}>Sea</th>
                        <th colspan="2" style={{ textAlign: "center" }}>Road</th>
                      </tr>

                      <tr>
                        <th>#</th>
                        <th>Branch</th>
                        <th>Orders</th>
                        <th>Amount</th>
                        <th>Orders</th>
                        <th>Amount</th>
                        <th>Orders</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        order.map((item, index) => {
                          return (
                            <tr>
                              <td style={{ textAlign: "left" }}>{index + 1}</td>
                              <td style={{ textAlign: "left" }}>{item?.branch_name}</td>
                              <td style={{ textAlign: "left" }}>{item?.data[0].total_order}</td>
                              <td style={{ textAlign: "left" }}>{item?.data[0].total_amount}</td>
                              <td style={{ textAlign: "left" }}>{item?.data[1].total_order}</td>
                              <td style={{ textAlign: "left" }}>{item?.data[1].total_amount}</td>
                              <td style={{ textAlign: "left" }}>{item?.data[3].total_order}</td>
                              <td style={{ textAlign: "left" }}>{item?.data[3].total_amount}</td>
                            </tr>

                          )
                        })
                      }


                      <tr>
                        <td></td>

                        <td style={{ textAlign: "right" }}><b>Total</b></td>

                        <td style={{ fontWeight: "1000",textAlign: "left" }}>
                          {order &&
                              order.reduce((acc, curr) => {
                              acc = acc + curr.data[0].total_order;
                              return acc;
                            },0)
                          }
                        </td>

                        <td style={{ fontWeight: "1000",textAlign: "left" }}>
                          {order &&
                              order.reduce((acc, curr) => {
                              acc = acc + curr.data[0].total_amount;
                              return acc;
                            },0)
                          }
                        </td>

                        <td style={{ fontWeight: "1000",textAlign: "left" }}>
                          {order &&
                              order.reduce((acc, curr) => {
                              acc = acc + curr.data[1].total_order;
                              return acc;
                            },0)
                          }
                        </td>

                        <td style={{ fontWeight: "1000",textAlign: "left" }}>
                          {order &&
                              order.reduce((acc, curr) => {
                              acc = acc + curr.data[1].total_amount;
                              return acc;
                            },0)
                          }
                        </td>

                        <td style={{ fontWeight: "1000",textAlign: "left" }}>
                          {order &&
                              order.reduce((acc, curr) => {
                              acc = acc + curr.data[3].total_order;
                              return acc;
                            },0)
                          }
                        </td>

                        <td style={{ fontWeight: "1000",textAlign: "left" }}>
                          {order &&
                              order.reduce((acc, curr) => {
                              acc = acc + curr.data[3].total_amount;
                              return acc;
                            },0)
                          }
                        </td>

                      </tr>

                    </tbody>
                  </table>


                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  )
}



export default BranchReport;