import React, { useRef, useEffect, useState } from 'react';
import Select from 'react-select';
import { MDBDataTable } from "mdbreact";
import toastr from 'toastr';
import { Modal } from "react-bootstrap";

import SweetAlert from 'react-bootstrap-sweetalert';
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { Row, Col, Card, CardBody, Button, Label, NavItem } from "reactstrap";
import { AvField, AvForm } from "availity-reactstrap-validation";
import "./Addbranch.scss"
import axios from 'axios';
import accessToken from '../../../helpers/jwt-token-access/accessToken';


function AddcrBranch() {
  const [addbranch, setaddbranch] = useState({})
  const [countryOptions, setcountryOptions] = useState([]);
  const [selectCountry, setselectCountry] = useState(null)
  const [currencyOptions, setcurrencyOptions] = useState([]);
  const [selectCurrency, setselectCurrency] = useState(null);
  const [popupData, setpopupData] = useState({});
  const [popupView, setpopupView] = useState(false)

  const [timeOptions, settimeOptions] = useState([]);
  const [selecttime, setselecttime] = useState(null);
  const [stateOptions, setstateOptions] = useState([]);
  const [selectstate, setselectstate] = useState(null);
  const [branchIdToBeUpdated, setbranchIdToBeUpdated] = useState(null);
  const [itemForTable, setitemForTable] = useState([]);
  const [confirmDeletAlert, setconfirmDeletAlert] = useState(null)
  const [branchIdToBeDeleted, setbranchIdToBeDeleted] = useState(null)

  const [selectType, setSelectType] = useState(null);

  const [errors, seterrors] = useState({
    country: "",
    state: "",
    // time_zone: "",
    currency: "",
    type: ""
  });

  useEffect(() => {
    fetch_all_country();
    fetch_all_time();
    fetch_all_currency();
    handleTableData();

  }, [])



  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";

  const formRef = useRef();
  const customestyle = {
    height: "auto",
  };

  const fetch_all_country = () => {
    axios
      .get(`${API_URL}newStates/countries`, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {
        var country_data =
          res.data.data &&
          res.data.data.map((country) => {
            //fetch_all_state(country?.id)
            return {
              label: country?.name,
              value: country?._id,

            };


          })
        setcountryOptions([
          {
            options: country_data,
          },
        ]);
      });
  }

  const fetch_all_state = (item) => {
    axios
      .get(`${API_URL}newStates/list?country_id=` + item.value, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {
        var state_data =
          res.data.data &&
          res.data.data.map((st) => {
            return {
              label: st.name,
              value: st._id
            }
          })
        setstateOptions([{ options: state_data }])
      })
  }

  const fetch_all_time = () => {
    axios
      .get(`${API_URL}cargo_Branch/timezone`, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        // let time_data =
        //   res.data.data &&
        //   res.data.data.map((time) => {
        //     return {
        //       label: time?.label,
        //       value: time?.value,
        //     }
        //   })
        settimeOptions([
          {
            options: res.data.data
          }
        ]);
      });
  }

  const fetch_all_currency = () => {
    axios
      .get(`${API_URL}cargo_Branch/currencylist`, {
        headers: {
          "x-access-token": accessToken
        }
      })
      .then((res) => {
        let currency_data =
          res.data.data &&
          res.data.data.map((currency) => {


            return {
              label: currency?.currency_name,
              value: currency?._id,
            };
          })
        setcurrencyOptions([
          {
            options: currency_data

          },
        ]);
      });
  }
  const deleteScreenshot = () => {
    setaddbranch({
      ...addbranch, [`branch_logo`]: ""
    })
  }

  const Ontop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };



  let Preupdateitem = (item) => {

    setbranchIdToBeUpdated(item._id);

    let cty = item?.country_id;

    let ctyop = {
      label: cty?.name,
      value: cty?.id
    };
    setselectCountry(ctyop);

    let ste = item?.state_id
    let steop = {
      label: ste?.name,
      value: ste?.id
    };
    setselectstate(steop);

    let brnch = item.type;
    let typeValue = {
      label: brnch
    }
    setSelectType(typeValue);

    item.country = ctyop.value;
    item.state = steop.value;
    //  item.mobile_no=item.mobile_no.toString();
    //  item.landLine_no=item.landLine_no.toString()

    setaddbranch(item);
  }

  const reset = () => {
    formRef.current.reset();

    setaddbranch({});
    setaddbranch({ adress: "" })
    setbranchIdToBeUpdated(null);
    setselectCountry(null);
    setselectCurrency(null);
    setselectstate(null);
    setselecttime(null);
    setSelectType(null);

    seterrors({
      country: "",
      state: "",
      time_zone: "",
      currency: "",
      type: "",
    })


  }

  function handleInpInput(e) {
    let name = e.target.name;
    let value = e.target.value;
    setaddbranch({ ...addbranch, [name]: value });
  }

  // add edit list
  const handleValidSubmit = () => {

    if (selectCountry === null || selectType === null ||
      // selectCurrency === null ||
      selectstate === null
      //  || selecttime === null
    ) {
      let errorVal = errors;
      if (selectCountry === null)
        errorVal.country = "Please Select Country"
      // if (selectCurrency === null)
      //   errorVal.currency = "Please Select Currency"
      if (selectstate === null)
        errorVal.state = "Please Select State"
      if (selectType === null)
        errorVal.type = "Please Select Type"
      seterrors({
        ...errorVal,
      });
    }

    else {
      if (branchIdToBeUpdated) {
        axios
          .put(`${API_URL}cargo_Branch`, addbranch, {
            headers: {
              "x-access-token": accessToken
            }
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Branch Updated Successfully");
              formRef.current.reset();
              handleTableData();
              setaddbranch({});
              setbranchIdToBeUpdated(null);
              setselectCountry(null);
              setselectCurrency(null);
              setselectstate(null);
              setselecttime(null);
              setSelectType(null);
            }
            else {
              toastr.error("Faild to Update Branch")
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message)
            return;
          })
      }
      else {
        axios
          .post(`${API_URL}cargo_Branch`, addbranch, {
            headers: {
              "x-access-token": accessToken,
            }
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Branch Added Successfully")
              formRef.current.reset();
              handleTableData();
              setaddbranch({});
              setselectCountry(null);
              setselectCurrency(null);
              setselectstate(null);
              setselecttime(null);
              seterrors({
                country: "",
                state: "",
                time_zone: "",
                currency: "",
                type: ""

              })
            }
            else {
              toastr.error("Failed to Add Branch");
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      };
    }
  }


  const handleClickOpenForm = (item) => {

    axios
      .get(`${API_URL}cargo_Branch/data?_id=` + item._id, {
        headers: {
          "x-access-token": accessToken,
        },
      })
      .then((res) => {
        let result = res.data.data;
        let state2 = result?.state_id || {};
        result.state2 = state2?.name

        let country2 = result?.country_id || {};
        result.country2 = country2?.name

        // let cur = result?.currency || {};
        // result.cur = cur?.currency_name

        setpopupData(result);
        setpopupView(true);
      })
  }


  // table data
  function handleTableData() {
    axios
      .get(`${API_URL}cargo_Branch/list`, {
        headers: {
          "x-access-token": accessToken
        },
      })
      .then((res) => {
        var result = res.data.data

        let branchData = [];
        result.map((item, index) => {

          item.id = index + 1

          // item.state1 = item?.state[0]?.name
          // item.country1 = item?.country[0]?.name
          // item.country1 = (
          //   <div style={{ display: "flex", justifyContent: "center" }}>
          //     <img
          //     alt=''
          //     width="10px"
          //     height="10px"
          //      src={`${API_URL}uploads/branch_logo/${addbranch.branch_logo}`}></img>
          //   </div>
          // )


          if (item?.updatedby) {
            let values = item?.updatedby || {};

            if (values?.lsatName)

              item.staff = values.firstName + " " + values.lsatName;
            else item.staff = values?.firstName
          } else {
            let values = item?.addedby || {};
            if (values?.lastName)
              item.staff = values.firstName + " " + values.lastName;
            else item.staff = values?.firstName
          }



          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i
                className="fas fa-eye"
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  // marginLeft: "0.5rem",
                  marginRight: "0.4rem",
                  marginTop: "0.4rem",
                }}
                onClick={() => {
                  handleClickOpenForm(item);
                  setpopupView(true)
                }}
              ></i>
              <i className='uil-edit-alt'
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  marginLeft: "0.4rem",
                  marginRight: "0.4rem"
                }} onClick={() => {
                  Ontop();
                  Preupdateitem(item);
                }}
              ></i>
              <i className='uil-trash-alt'
                style={{ fontSize: "1rem", cursor: "pointer" }}
                onClick={() => {
                  setbranchIdToBeDeleted(item._id);
                  setconfirmDeletAlert(true);

                }}
              ></i>
            </div>
          );




          branchData.push(item)
        })
        setitemForTable(branchData)
      });
  }

  //select options
  const handleSelectChange = (selected, name) => {
    switch (name) {
      case "country":
        setselectCountry(selected);
        setselectstate(null);
        fetch_all_state(selected)
        setaddbranch({ ...addbranch, country_id: selected.value })
        break;

      case "state":
        setselectstate(selected);

        setaddbranch({ ...addbranch, state_id: selected.value })
        break;

      case "time_zone":
        setselecttime(selected);
        setaddbranch({ ...addbranch, time_zone: selected.value })
        break;

      case "currency":
        setselectCurrency(selected);
        setaddbranch({ ...addbranch, currency: selected.value })
        break;

      case "type":
        setSelectType(selected);
        setaddbranch({
          ...addbranch,
          type: selected.label,
        });
        break;
      default:
        break
    }
  }

  const uploadScreenshot = (e) => {
    const fd = new FormData();
    fd.append("branch_logo", e.target.files[0]);
    axios
      .post(`${API_URL}cargo_Branch/logo/`, fd, {
        headers: {
          "x-access-token": accessToken
        },
      })
      .then((response) => {
        if (response.data.status === "success") {
          setaddbranch({
            ...addbranch,
            [`branch_logo`]: response.data.file.filename
          })
          seterrors({
            ...errors,
            imageErrors: "",
          }); 
        }
        else {
          toastr.error(response.data.message);
        }
      });
  };


  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150
      },
      {
        label: "ID",
        field: "branchId",
        sort: "asc",
        width: 150
      },
      {
        label: "Branch/Warehouse",
        field: "branch_name",
        sort: "asc",
        width: 150
      },
      {
        label: "Code",
        field: "short_Code",
        sort: "asc",
        width: 150
      },
      {
        label: "Type",
        field: "type",
        sort: "asc",
        width: 150
      },
      {
        label: " Name",
        field: "contact_person",
        sort: "asc",
        width: 150
      },
      {
        label: "Mobile",
        field: "mobile_no",
        sort: "asc",
        width: 150
      },
      // {
      //   label: "State",
      //   field: "state1",
      //   sort: "asc",
      //   width: 150
      // },
      // {
      //   label: "Country",
      //   field: "country1",
      //   sort: "asc",
      //   width: 150
      // },
      {
        label: "Staff",
        field: "staff",
        sort: "asc",
        width: 150
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150
      },
    ],
    rows: itemForTable,
  }

  const customStyles = {
    lineHeight: "1.8",
  };

  return (

    <React.Fragment>
      {confirmDeletAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}cargo_Branch` + "/" + branchIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                }
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Item Deleted Successfully");
                  handleTableData();
                  setbranchIdToBeDeleted(null);
                  reset();
                  setaddbranch({})
                  setselectCountry(null);
                  setselectCurrency(null);
                  setselectstate(null);
                  setselecttime(null);
                  setbranchIdToBeUpdated(null);
                } else {
                  toastr.error("faild to delete")
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message);
                return;
              });
            setconfirmDeletAlert(false);
          }}
          onCancel={() => setconfirmDeletAlert(false)}
        >
          Are you sure want to delete it?
        </SweetAlert>
      ) : null}
      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setpopupView(!popupView)}
        size="xl"
        centered={true}>

        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Branch/Warehouse Details</h5>
          <button
            type='button'
            onClick={() => {
              setpopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className='modal-body'>
            <table
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Branch ID</td>
                <td>: {popupData?.branchId}</td>
                <td style={{ paddingLeft: "25px" }}>Type</td>
                <td style={{ textAlign: "left" }}>: {popupData?.type}</td>
                <td style={{ paddingLeft: "25px" }}> Name</td>
                <td style={{ textAlign: "left" }}>: {popupData?.branch_name}</td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Short Code</td>
                <td >: {popupData?.short_Code}</td>
                <td style={{ paddingLeft: "25px" }}>Contact Person</td>
                <td style={{ textAlign: "left" }}>: {popupData?.contact_person}</td>
                <td style={{ paddingLeft: "25px" }}>Designation</td>
                <td style={{ textAlign: "left" }}>: {popupData?.designation}</td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Landline No</td>
                <td>: {popupData?.landLine_no}</td>
                <td style={{ paddingLeft: "25px" }}>Mobile No</td>
                <td style={{ textAlign: "left" }}>: {popupData?.mobile_no}</td>
                <td style={{ paddingLeft: "25px" }}>Email ID</td>
                <td style={{ textAlign: "left" }}>: {popupData?.email_id}</td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Website</td>
                <td >: {popupData?.website}</td>
                <td style={{ paddingLeft: "25px" }}>Address</td>
                <td colSpan={3} style={{ textAlign: "left" }}>: {popupData?.address}</td>


              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Pincode</td>
                <td >: {popupData?.pin_code}</td>
                <td style={{ paddingLeft: "25px" }}>Latitude</td>
                <td style={{ textAlign: "left" }}>: {popupData?.latitude}</td>
                <td style={{ paddingLeft: "25px" }}>Longitude</td>
                <td style={{ textAlign: "left" }}>: {popupData?.longitude}</td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>State</td>
                <td >: {popupData?.state2}</td>
                <td style={{ paddingLeft: "25px" }}>Country</td>
                {/* <td style={{ textAlign: "left" }}>: {popupData?.country2}</td> */}

                <td>

                  :{popupData?.country_id?.name}<img src={`${API_URL}uploads/country_flag/${popupData?.country_id?.flag}`} width={"50px"}></img>


                </td>


                <td style={{ paddingLeft: "25px" }}>VAT</td>
                <td style={{ textAlign: "left" }}>: {popupData?.vat}</td>

              </tr>
              <tr hidden={!popupData?.branch_logo}>
                <td style={{ padding: "10px", textAlign: "left" }}>Branch Logo</td>
                <td style={{ textAlign: "left" }}>
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      paddingLeft: "0px",
                      cursor: "pointer"
                    }}
                    src={`${API_URL}uploads/branch_logo/${popupData?.branch_logo}`}></img>
                </td>


              </tr>
            </table>
          </div>
        </AvForm>
      </Modal>

      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumb title="Home" breadcrumbItem="Add Branch/Warehouse" />
          <Row>
            <Col xl="12">
              {/* <Card >
                <CardBody > */}
              <AvForm
                ref={formRef}
                className="needs-validation"
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
              >
                <Row>
                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Name</Label>
                      <AvField
                        style={customStyles}
                        name="branch_name"
                        placeholder="Branch/Warehouse Name"
                        type="text"
                        value={addbranch?.branch_name}
                        onChange={handleInpInput}
                        errorMessage="Enter Branch Name"
                        validate={{ required: { value: true } }}
                        className="form-control" />
                    </div>
                  </Col>


                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Short Code</Label>
                      <AvField
                        style={customStyles}
                        name="short_Code"
                        placeholder="Short Code"
                        type="text"
                        value={addbranch?.short_Code}
                        onChange={handleInpInput}
                        errorMessage="Enter Short Code"
                        validate={{ required: { value: true } }}
                        className="form-control" />
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3">
                      <Label>Type</Label>

                      <Select
                        name="type"
                        placeholder="Select Type"
                        options={[
                          { label: "Branch", value: 0 },
                          { label: "Warehouse", value: 1 },
                        ]}
                        classNamePrefix="select2-selection"
                        value={selectType}
                        onChange={(selected) => {
                          handleSelectChange(selected, "type")
                        }}
                      />
                      <p className='text-danger'
                        style={{ fontSize: "11px" }}>
                        {selectType === null ? errors.type : ""}
                      </p>
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3">
                      <Label >
                        Contact Person
                      </Label>
                      <AvField
                        style={customStyles}
                        name="contact_person"
                        placeholder="Contact Person"
                        type="text"
                        value={addbranch?.contact_person}
                        onChange={handleInpInput}
                        errorMessage="Enter Contact Person"
                        className="form-control"
                        validate={{ required: { value: true } }}
                      />
                    </div>
                  </Col>


                  <Col md="3">
                    <div className="mb-3">
                      <Label >
                        Designation
                      </Label>
                      <AvField
                        style={customStyles}
                        name="designation"
                        placeholder="Designation"
                        type="text"
                        value={addbranch?.designation}
                        onChange={handleInpInput}
                        errorMessage="Enter Designation"
                        className="form-control"
                        validate={{ required: { value: true } }}
                      />
                    </div>
                  </Col>


                  {/* <Col md="3">
                    <div className="mb-3">
                      <Label >Mobile No.</Label>
                      <AvField
                        style={customStyles}
                        name="mobile_no"
                        placeholder="Mobile No."
                        type="tel"
                        value={addbranch?.mobile_no}
                        onChange={handleInpInput}
                        //errorMessage="Enter valid 10 digit number"
                        className="form-control"
                        validate={{
                          required: { value: true, errorMessage: "Enter mobile number" },
                          pattern: {
                            value: /^[0-9]{10}$/,
                            errorMessage: "Enter a valid mobile number"
                          }
                        }}
                      />
                    </div>
                  </Col> */}

                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Mobile No.</Label>
                      <AvField
                        style={customStyles}
                        name="mobile_no"
                        placeholder="Mobile No."
                        type="phone"
                        value={addbranch?.mobile_no}
                        onChange={handleInpInput}
                        className="form-control"
                        validate={{
                          required: { value: true, errorMessage: "Enter mobile number" },
                          pattern: {
                            value: /^[0-9]{10}$/,
                            errorMessage: "Enter a valid mobile number"
                          }
                        }}
                      />
                    </div>
                  </Col>


                  <Col md="3">
                    <div className="mb-3">
                      <Label >
                        Landline No.
                      </Label>
                      <AvField
                        style={customStyles}
                        name="landLine_no"
                        placeholder="Landline No."
                        type="phone"
                        value={addbranch?.landLine_no}
                        onChange={handleInpInput}
                        //errorMessage="Enter valid 11 digit number"
                        className="form-control"
                        validate={{
                          required: { value: true, errorMessage: "Enter 11 digit landline number" },
                          pattern: {
                            value: /^[0-9]{11}$/,
                            errorMessage: "Enter a valid 11 digit landline number"
                          }
                        }}
                      />
                    </div>
                  </Col>


                  <Col md="3">
                    <div className="mb-3">
                      <Label >Email ID</Label>
                      <AvField
                        style={customStyles}
                        name="email_id"
                        placeholder="Email ID"
                        type="email"
                        value={addbranch?.email_id}
                        onChange={handleInpInput}
                        validate={{
                          required: { value: true, errorMessage: "Enter Email ID" },
                          pattern: {
                            value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                            errorMessage: "Enter a valid Email ID"
                          }
                        }}
                        // errorMessage=" Please provide a valid Email ID."
                        className="form-control"
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label >
                        Website
                      </Label>
                      <AvField
                        style={customStyles}
                        name="website"
                        placeholder="Website"
                        type="text"
                        value={addbranch?.website}
                        onChange={handleInpInput}
                        // errorMessage="Enter Website"
                        className="form-control"
                      // validate={{ required: { value: true } }}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label >Address</Label>
                      <AvField
                        style={customestyle}
                        name="address"
                        placeholder="Address"
                        type="textarea"
                        value={addbranch?.address}
                        onChange={handleInpInput}
                        errorMessage="Enter Your Address"
                        className="form-control"
                        validate={{ required: { value: true } }}

                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label>Country</Label>

                      <Select
                        name="country_id"
                        value={selectCountry}
                        placeholder="Select Country"
                        options={countryOptions}
                        classNamePrefix="select2-selection"
                        onChange={(selected) => {
                          handleSelectChange(selected, "country")
                        }}
                      />
                      <p className='text-danger'
                        style={{ fontSize: "11px" }}>
                        {selectCountry === null ? errors.country : ""}
                      </p>
                    </div>
                  </Col>

                  <Col md="3">
                    <div className="mb-3">
                      <Label>State</Label>

                      <Select
                        name="state_id"
                        value={selectstate}
                        placeholder="Select State"
                        options={stateOptions}
                        classNamePrefix="select2-selection"
                        onChange={(selected) => {
                          handleSelectChange(selected, "state")
                        }}
                      />
                      <p className='text-danger'
                        style={{ fontSize: "11px" }}>
                        {selectstate === null ? errors.state : ""}
                      </p>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label >Pincode</Label>
                      <AvField
                        style={customStyles}
                        name="pin_code"
                        placeholder="Pincode"
                        type="text"
                        value={addbranch?.pin_code}
                        onChange={handleInpInput}
                        // errorMessage="Please provide a Pincode."
                        className="form-control"
                        validate={{
                          required: { value: true, errorMessage: "Enter pincode" },
                          pattern: {
                            value: /^[0-9]{6}$/,
                            errorMessage: "Enter a valid pincode"
                          }
                        }}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label >
                        VAT
                      </Label>
                      <AvField
                        style={customStyles}
                        name="vat"
                        placeholder="VAT"
                        type="text"
                        value={addbranch?.vat}
                        onChange={handleInpInput}
                        errorMessage="Enter VAT"
                        className="form-control"
                        validate={{ required: { value: true } }}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">
                        Upload Photo
                      </Label>
                      {addbranch?.branch_logo ? (
                        <div div className="img-wraps">
                          <img
                            alt=""
                            width="150"
                            height="150"
                            src={`${API_URL}uploads/branch_logo/${addbranch.branch_logo}`}
                          />
                          <button
                            className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                            onClick={deleteScreenshot}
                            style={{ width: "150px" }}
                          >
                            Delete
                          </button>
                        </div>
                      ) : (
                        <AvField
                          style={customStyles}
                          name="image"
                          type="file"
                          className="form-control"

                          onChange={uploadScreenshot}
                          rows="1"
                        />
                      )}
                    </div>
                  </Col>
                  {/* <Col md="3">
                         <div className='mb-3'>
                           <Label>Branch Logo</Label>
                           
                          <AvField
                          name="branch_logo"
                          type="file"
                          className="form-control"
                          rows="1"
                          //onChange={uploadScreenshot}
                          /> 
                         </div>
                      </Col> */}
                  <Col md="3">
                    <div className="mb-3">
                      <Label >
                        Latitude
                      </Label>
                      <AvField
                        style={customStyles}
                        name="latitude"
                        placeholder="Latitude"
                        type="text"
                        value={addbranch?.latitude}
                        onChange={handleInpInput}
                        // errorMessage="Enter latitude"
                        className="form-control"
                      // validate={{ required: { value: true } }}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label >
                        Longitude
                      </Label>
                      <AvField
                        style={customStyles}
                        name="longitude"
                        placeholder="Longitude"
                        type="text"
                        value={addbranch?.longitude}
                        onChange={handleInpInput}
                        // errorMessage="Enter Longitude"
                        className="form-control"
                      // validate={{ required: { value: true } }}
                      />
                    </div>
                  </Col>
                  {/* <Col md="3">
                        <div className="mb-3">
                          <Label>Time Zone</Label>

                          <Select
                            name="time_zone"
                            placeholder="Select Time Zone"
                            options={timeOptions}
                            classNamePrefix="select2-selection"
                            value={selecttime}
                            onChange={(selected) => {
                              handleSelectChange(selected, "time_zone")
                            }}
                          />
                          <p className='text-danger'
                            style={{ fontSize: "11px" }}>
                            {selecttime === null ? errors.time_zone : ""}
                          </p>
                        </div>
                      </Col>
                      <Col md="3">
                        <div className="mb-3">
                          <Label>Currency</Label>

                          <Select
                            name="currency"
                            placeholder="Select Currency"
                            options={currencyOptions}
                            classNamePrefix="select2-selection"
                            value={selectCurrency}
                            onChange={(selected) => {
                              handleSelectChange(selected, "currency")
                            }}
                          />
                          <p className='text-danger'
                            style={{ fontSize: "11px" }}>
                            {selectCurrency === null ? errors.currency : ""}
                          </p>
                        </div>
                      </Col> */}
                  <Col md="3" style={{ paddingTop: "4px" }}>
                    <div className='mt-4'>
                      {branchIdToBeUpdated ? (
                        <Button className='mx-2'
                          color='primary'
                          type='Update'>
                          Update
                        </Button>
                      ) : <Button className='mx-2'
                        color='primary'
                        type='submit'>
                        Submit
                      </Button>}

                      <Button
                        color='danger'
                        type="reset"
                        onClick={() => reset()}
                      >

                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
          <Col className='col-12'>
            {/* <Card>
              <CardBody> */}
            <MDBDataTable
              responsive
              id="Addbranchtable"
              bordered
              data={data}
              entries={20} />
            {/* </CardBody>
             </Card> */}
          </Col>
        </div>
      </div>
    </React.Fragment>
  )
}

export default AddcrBranch;