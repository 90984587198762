export const GET_WASTEITEMS="GET_WASTEITEMS";
export const GET_WASTEITEMS_SUCCESS="GET_WASTEITEMS_SUCCESS";
export const GET_WASTEITEMS_FAIL="GET_WASTEITEMS_FAIL";

export const GET_WASTE_TYPES="GET_WASTE_TYPES";
export const GET_WASTE_TYPES_SUCCESS="GET_WASTE_TYPES_SUCCESS";
export const GET_WASTE_TYPES_FAIL="GET_WASTE_TYPES_FAIL";

export const GET_WASTE_CATEGORIES="GET_WASTE_CATEGORIES";
export const GET_WASTE_CATEGORIES_SUCCESS="GET_WASTE_CATEGORIES_SUCCESS";
export const GET_WASTE_CATEGORIES_FAIL="GET_WASTE_CATEGORIES_FAIL";


export const ADD_WASTE_ITEM= "ADD_WASTE_ITEM";
export const ADD_WASTE_ITEM_SUCCESS = "ADD_WASTE_ITEM_SUCCESS";
export const ADD_WASTE_ITEM_FAIL = "ADD_WASTE_ITEM_FAIL";

export const UPDATE_WASTE_ITEM= "UPDATE_WASTE_ITEM";
export const UPDATE_WASTE_ITEM_SUCCESS = "UPDATE_WASTE_ITEM_SUCCESS";
export const UPDATE_WASTE_ITEM_FAIL = "UPDATE_WASTE_ITEM_FAIL";

export const DELETE_WASTE_ITEM = "DELETE_WASTE_ITEM";
export const DELETE_WASTE_ITEM_SUCCESS = "DELETE_WASTE_ITEM_SUCCESS";
export const DELETE_WASTE_ITEM_FAIL = "DELETE_WASTE_ITEM_FAIL";

export const GET_WASTEITEMS_OPTIONS="GET_WASTEITEMS_OPTIONS";
export const GET_WASTEITEMS_OPTIONS_SUCCESS="GET_WASTEITEMS_OPTIONS_SUCCESS";
export const GET_WASTEITEMS_OPTIONS_FAIL="GET_WASTEITEMS_OPTIONS_FAIL";

