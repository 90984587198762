import React from 'react'
//import { Breadcrumb, Form } from 'reactstrap'
import { Row, Col, Card, CardBody, Button, Label, CardTitle } from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { MDBDataTable } from "mdbreact";
import Select from "react-select"
import Breadcrumb from '../../../components/Common/Breadcrumb';
import { useState, useEffect, useRef } from "react"
import axios from 'axios';
import toastr from 'toastr';
import SweetAlert from 'react-bootstrap-sweetalert';
import accessToken from '../../../helpers/jwt-token-access/accessToken';
import { Modal } from "react-bootstrap";
import "./ItemCategory.scss";
import { result } from 'lodash';



function ItemCategory() {


  useEffect(() => {
    handleTableData();
    fetch_all_country();
  }, []);


  const formRef = useRef();


  const reset = () => {
    formRef.current.reset();
    setselectedItem(null);
    setitemIdToBeUpdated(null);
    setitemIdToBeDeleted(null);
    seterrors({ itemType: "" })
    setitemObject(null);
    fetch_all_country()
  }


  const [errors, seterrors] = useState({ itemType: "" })
  const [itemIdToBeUpdated, setitemIdToBeUpdated] = useState(null)
  const [itemObject, setitemObject] = useState(null);
  const [selectedItem, setselectedItem] = useState(null);
  const [itemForTable, setitemForTable] = useState([]);
  const [confirmDeletAlert, setconfirmDeletAlert] = useState(null);
  const [itemIdToBeDeleted, setitemIdToBeDeleted] = useState(null);
  const [popupView, setpopupView] = useState(false)
  const [allbranchcoun, setallbranchcoun] = useState([]);
  const [values, setvalues] = useState([{
    rate: "",
    country: "",
  }])
  const [country, setCountry] = useState([])


  const API_URL = process.env.REACT_APP_APIURL || "http://localhost:3099/";


  function handleSelectChange(selected) {
    setitemObject({ ...itemObject, ["item_Type"]: selected.value });
    setselectedItem({ label: selected.label, value: selected.value });
    seterrors({ ...errors, itemType: "", })
  }


  const onTop = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }


  function handleInput(e) {
    let name = e.target.name;
    let value = e.target.value
    setitemObject({ ...itemObject, [name]: value });
  }


  const fetch_all_country = () => {
    axios
      .get(`${API_URL}country/list_branch_countries`, {
        headers: {
          "x-access-token": accessToken,
        }
      })
      .then((res) => {
        var result = res.data.data

        setallbranchcoun(result)
      })
  }


  let preupdateitem = (item) => {
    setitemIdToBeUpdated(item._id);
    setitemObject(item)
    let valuess = {};
    if (item.item_Type === 1) {
      valuess = {
        label: "Item",
        value: 1,
      }
    }
    else if (item.item_Type === 2) {
      valuess = {
        label: "Packing",
        value: 2,
      }
    }
    else if (item.item_Type === 3) {
      valuess = {
        label: "Extra",
        value: 3,
      }
    }
    else if (item.item_Type === 4) {
      valuess = {
        label: "Package",
        value: 4,
      }
    }
    else if (item.item_Type === 5) {
      valuess = {
        label: "Domestic",
        value: 5,
      }
    }

    setselectedItem(valuess)
  }


  //list
  function handleTableData() {
    axios
      .get(`${API_URL}itemCategory/list`, {
        headers: {
          "x-access-token": accessToken
        },
      })
      .then((res) => { 
        var result = res.data.data
        let packData = [];
        result.map((item, index) => {
          if (item?.image) {
            item.image1 = (
              <img src={API_URL + "uploads/item_category/" + item.image} height={50} width={50} />
            )
          }

          item.action = (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <i className="uil-edit-alt"
                style={{
                  fontSize: "1rem",
                  cursor: "pointer",
                  marginLeft: "0.4rem",
                  marginRight: "0.4rem"
                }}
                onClick={() => {
                  onTop();
                  preupdateitem(item)
                }}
              ></i>
              <i className="uil-trash-alt"
                style={{ fontSize: "1rem", cursor: "pointer" }}
                onClick={() => {
                  setitemIdToBeDeleted(item._id);
                  setconfirmDeletAlert(true)
                }}

              ></i>
            </div>
          );

          item.id = index + 1
          if (item.item_Type === 1) {
            item.item_type = "Item"
          }
          else if (item.item_Type === 2) {
            item.item_type = "Packing"
          }
          else if (item.item_Type === 3) {
            item.item_type = "Extra"
          }
          else if (item.item_Type === 4) {
            item.item_type = "Package"
          }
          else if (item.item_Type === 5) {
            item.item_type = "Domestic"
          }

          item.rate = parseFloat(
            item.rate
          ).toFixed(3);

          packData.push(item)


        });
        setitemForTable(packData);
      })

  }


  function handleInputrate(e, item, index) {
    let name = e.target.name
    let value = e.target.value;
    setitemObject({ ...itemObject, [name]: value })
  }


  const handleValidSubmit = (e, v) => {
    if (selectedItem === null) {
      let errorVal = errors;
      if (selectedItem === null)
        errorVal.itemType = "Please Select Item Type"
      seterrors({ ...errorVal })
    }

    else {
      if (itemIdToBeUpdated) {
        axios
          .put(`${API_URL}itemCategory`, itemObject, {
            headers: {
              "x-access-token": accessToken
            }
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Item Updated Successfully");
              formRef.current.reset();
              handleTableData();
              setitemObject(null);
              setitemIdToBeUpdated(null);
              setselectedItem(null);
              seterrors({ itemType: "" })
              fetch_all_country()
            }
            else {
              toastr.error("faild to update")
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          })
      }
      else {
        axios
          .post(`${API_URL}itemCategory`, itemObject, {
            headers: {
              "x-access-token": accessToken
            }
          })
          .then((res) => {
            if (res.data.status === 200) {
              toastr.success("Item Added Successfully")
              formRef.current.reset();
              setselectedItem(null);
              handleTableData();
              seterrors({ itemType: "" })
              reset();
              setitemObject(null);
              fetch_all_country();

            }
            else {
              toastr.error("Faild To Add Item")
              return;
            }
          })
          .catch((err) => {
            toastr.error(err.response.data.message);
            return;
          });
      };
    };
  };

  const data = {
    columns: [
      {
        label: "#",
        field: "id",
        sort: "asc",
        width: 150
      },
      {
        label: "ItemType",
        field: "item_type",
        sort: "asc",
        width: 150
      },

      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150
      },
      {
        label: "Image",
        field: "image1",
        sort: "asc",
        width: 150
      },
      {
        label: "Unit",
        field: "unit",
        sort: "asc",
        width: 150
      },
      {
        label: "Rate",
        field: "rate",
        sort: "asc",
        width: 150
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150
      },

    ],
    rows: itemForTable,
  }




  const customStyles = {
    lineHeight: "1.8",
  };
  const deleteImage = () => {
    setitemObject({
      ...itemObject, [`image`]: ""
    })
  }
  const uploadImage = (e) => {
    const fd = new FormData();
    fd.append("image", e.target.files[0]);
    axios
      .post(`${API_URL}itemCategory/upload/`, fd, {
        headers: {
          "x-access-token": accessToken
        },
      })
      .then((response) => {
        setitemObject({
          ...itemObject, [`image`]: response.data.file.filename
        })
      })
  }


  return (

    <React.Fragment>
      {confirmDeletAlert ? (
        <SweetAlert
          title=""
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            axios
              .delete(`${API_URL}itemCategory` + "/" + itemIdToBeDeleted, {
                headers: {
                  "x-access-token": accessToken,
                }
              })
              .then((res) => {
                if (res.data.status === 200) {
                  toastr.success("Item Deleted Successfully");
                  handleTableData();
                  setitemIdToBeDeleted(null);
                  setitemObject(null);
                  setitemIdToBeUpdated(null);
                  reset();
                }
                else {
                  toastr.error("faild to delete")
                  return;
                }
              })
              .catch((err) => {
                toastr.error(err.response.data.message)
                return;
              });
            setconfirmDeletAlert(false)
          }}
          onCancel={() => setconfirmDeletAlert(false)}>
          Are you sure want to delete it?
        </SweetAlert>
      ) : null}


      <Modal
        show={popupView}
        isOpen={popupView}
        toggle={() => setpopupView(!popupView)}
        size="xl"
        centered={true}>

        <div className='modal-header'>
          <h5 className='modal-title mt-0'>Item Type Details</h5>
          <button
            type='button'
            onClick={() => {
              setpopupView(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="close"
          ></button>
        </div>
        <AvForm className="needs-validation">
          <div className='modal-body'>
            <table
              className="table table-bordered dataTable"
            >
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Item Type</td>
                <td>: Item</td>
                <td style={{ paddingLeft: "25px" }}>Name</td>
                <td style={{ textAlign: "left" }}>: Normal India Air</td>
                <td style={{ paddingLeft: "25px" }}> Unit</td>
                <td style={{ textAlign: "left" }}>: Kg</td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Oman</td>
                <td >: 1.2</td>
                <td style={{ paddingLeft: "25px" }}>India</td>
                <td style={{ textAlign: "left" }}>: 300</td>
                <td style={{ paddingLeft: "25px" }}>Bangladesh</td>
                <td style={{ textAlign: "left" }}>: 360</td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Pakistan</td>
                <td>: 600</td>
                <td style={{ paddingLeft: "25px" }}>Nepal</td>
                <td style={{ textAlign: "left" }}>: 420</td>
                <td style={{ paddingLeft: "25px" }}>Philippines</td>
                <td style={{ textAlign: "left" }}>: 180</td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>Sudan</td>
                <td >: 1800</td>
                <td style={{ paddingLeft: "25px" }}>Indonesia</td>
                <td style={{ textAlign: "left" }}>: 49020</td>
                <td style={{ paddingLeft: "25px" }}>CreatedAt</td>
                <td style={{ textAlign: "left" }}>: 01-11-2022 2:30pm </td>

              </tr>
              <tr>
                <td style={{ padding: "10px", textAlign: "left" }}>AddedBy</td>
                <td >: Admin Cargo</td>
                <td style={{ paddingLeft: "25px" }}>UpdatedAt</td>
                <td style={{ textAlign: "left" }}>: 30-11-2022 11:25am</td>
                <td style={{ paddingLeft: "25px" }}>UpdatedBy</td>
                <td style={{ textAlign: "left" }}>: Admin Cargo</td>

              </tr>

            </table>
          </div>
        </AvForm>
      </Modal>

      <div className='page-content'>
        <div className='container-fluid'>
          <Breadcrumb title="Home" breadcrumbItem="Item Category" />

          <Row>
            <Col xl="12">
              {/* <Card>
                <CardBody> */}
              <AvForm
                ref={formRef}
                onValidSubmit={(e, v) => {
                  handleValidSubmit(e, v);
                }}
                className="needs-validation">
                <Row>
                  <Col md="2">
                    <div className="mb-3" >
                      <Label>Item Type</Label>
                      <Select
                        name='item_Type'
                        placeholder='Item Type'
                        value={selectedItem}
                        validate={{ required: { value: true } }}
                        onChange={(selected) => {
                          handleSelectChange(selected)
                        }}
                        options={[
                          { label: "Item", value: 1 },
                          { label: "Packing", value: 2 },
                          { label: "Extra", value: 3 },
                          { label: "Package", value: 4 },
                          { label: "Domestic", value: 5 },
                        ]}
                        classNamePrefix="form-selects select2-selection"

                      />
                      <p className='text-danger'
                        style={{ fontSize: "11px" }}>
                        {selectedItem === null ? errors.itemType : ""}
                      </p>
                    </div>
                  </Col>

                  <Col md="3">
                    <div className='mb-3'>
                      <Label>Name</Label>
                      <AvField
                        name="name"
                        type="text"
                        placeholder="Name"
                        errorMessage="Enter Name"
                        className="form-control"
                        validate={{ required: { value: true } }}
                        value={itemObject?.name}
                        onChange={handleInput}
                      />
                    </div>
                  </Col>

                  <Col md="2">
                    <div className='mb-3'>
                      <Label>Unit</Label>

                      <AvField
                        name="unit"
                        placeholder="Unit"
                        type="text"
                        errorMessage="Enter Unit"
                        validate={{ required: { value: true } }}
                        value={itemObject?.unit}
                        onChange={handleInput}
                      />

                    </div>
                  </Col>
                  <Col md="2">
                    <div className='mb-3'>
                      <Label>Rate</Label>
                      <AvField
                        name="rate"
                        placeholder="Rate"
                        type="number"
                        errorMessage="Enter Rate"
                        validate={{ required: { value: true } }}
                        value={itemObject?.rate}
                        onChange={handleInput}
                      />
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="mb-3">
                      <Label htmlFor="validationCustom03">
                        Upload Photo
                      </Label>
                      {itemObject?.image ? (
                        <div div className="img-wraps">
                          <img
                            alt=""
                            width="100"
                            height="100"
                            src={`${API_URL}uploads/item_category/${itemObject?.image}`}
                          />
                          <br /><br />
                          <button
                            className="btn btn-danger btn-sm btn-block waves-effect waves-light btn btn-danger"
                            onClick={deleteImage}
                            style={{ width: "100px" }}
                          >
                            Delete
                          </button>
                        </div>
                      ) : (
                        <AvField
                          style={customStyles}
                          name="image"
                          type="file"
                          className="form-control"

                          onChange={uploadImage}
                          rows="1"
                        />
                      )}
                    </div>
                  </Col>
                  <Col md="3">
                    <div>
                      {itemIdToBeUpdated ? (
                        <Button
                          style={{ marginRight: "5%" }}
                          color='primary'
                          type='Update'
                        >
                          Update
                        </Button>
                      ) : <Button
                        style={{ marginRight: "5%" }}
                        color='primary'
                        type='submit'
                      >
                        Submit
                      </Button>}




                      <Button
                        color='danger'
                        type='reset'
                        onClick={() => reset()}
                      >
                        Reset
                      </Button>
                    </div>
                  </Col>
                </Row>
              </AvForm>
            </Col>
            <Col md="12" className='mt-4'>
              {/* <Card>
                <CardBody> */}
              <MDBDataTable
                bordered
                responsive
                id="Itemtable"
                data={data}
                entries={20}

              />

              {/* </CardBody>
              </Card> */}
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ItemCategory 